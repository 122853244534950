// SOP-76 feedback points L2 (7) included By PCS Hire Request (Approvals dialog box)
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    Grid,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import deleteAlert from '../../component/HOC/deleteAlert';
import dayjs from 'dayjs';

const HireRequestApprovalsPage=
// To get handleApproveApprovalsPageClose & getMyPendingApprovalsList from catgories page
({
    handleApproveApprovalsPageClose,
    getMyPendingApprovalsList,
    getMyApprovedList,
    HireRequestId
}: {
    handleApproveApprovalsPageClose: (event: React.MouseEvent) => void,
    getMyPendingApprovalsList: (event: React.MouseEvent) => void,
    getMyApprovedList: (event: React.MouseEvent) => void,
    HireRequestId:any
})=>{

    // use state
    const [ApproveRemarksError, setApproveRemarksError]=useState(false)
    const [ApproveRemarksValue, setApproveRemarksValue]=useState('')
    const [ApproveRemarksErrorMessage, setApproveRemarksErrorMessage]=useState('')
    const [ApproveOptions, setApproveOptions] = useState<OptionType[]>([]);
    const [selectedApproveId, setSelectedApproveId] = useState<any | null>('');
    const [ApproveError, setApproveError]=useState(false)
    const [ApproveErrorMessage, setApproveErrorMessage]=useState('')

//    console.log('selected option is..',selectedApproveId)

    type OptionType = {
        name: string;
        value: string;
    };
    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });

    //  Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    let CompanyId = GlobalData.GlobalCompanyId
    const UserId = GlobalData.GlobalUserId;
    let EmployeeId = GlobalData.GlobalEmployeeId
  

    //  Handle Approve while typing Approve and validations
        const handleApproveRemarksChange = (event:any) => {
            setApproveRemarksValue(event.target.value);
            const trimmedApproveRemarksValue =  event.target.value
            if( trimmedApproveRemarksValue.length !== 255 ){
                setApproveRemarksError(false);
                setApproveRemarksErrorMessage("");
            }
        };

        // To render handle close function to close dialouge box
        const handleClose = (event: React.MouseEvent) => {
            if (handleApproveApprovalsPageClose && typeof handleApproveApprovalsPageClose === 'function') {
                handleApproveApprovalsPageClose(event);
            }
        };

    // to render getMyPendingApprovalsList function after approving request
        const handlegetMyPendingApprovalsList= (event: React.MouseEvent) => {
            if (getMyPendingApprovalsList && typeof getMyPendingApprovalsList === 'function') {
                getMyPendingApprovalsList(event);
            }
        };

    // to render getMyApprovedList function after approving request
        const handlegetMyApprovedList= (event: React.MouseEvent) => {
            if (getMyApprovedList && typeof getMyApprovedList === 'function') {
                getMyApprovedList(event);
            }
        };

    // To get Approve sts list to fill ApproveStatus drop down
    const GetApproveList = async () => { 
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/myapprovals/get_approve_status_list`);
            //console.log("Approve" ,res.data.data);
            let formattedApprove = res.data.data.map((item:any) => {
            const { sys_approve_status_id, sys_approve_status_name } = item;
            return {
                name: sys_approve_status_name,
                value: sys_approve_status_id,
            };
            });
            setApproveOptions(formattedApprove)
        } catch (err) {
            //console.log( "co",err);
        }
        };

    

    // Define a type for errors
        type Errors = {
        approveSts?:boolean;
        apprveRemarks?:boolean;
        };

    const ApproveHireRequest =(event:any)=>{

        event.preventDefault()
        let errors: Errors = {};

        if (selectedApproveId === '' || selectedApproveId === null || selectedApproveId === undefined) {
            errors.approveSts = true;
            setApproveErrorMessage("Approve Status is required")
            setApproveError(true)
        }
        
        const trimmedApproveRemarksValue = ApproveRemarksValue.trim()
        if( trimmedApproveRemarksValue.length >255 ){
            setApproveRemarksError(true);
            setApproveRemarksErrorMessage("Max 255 characters");
        }
        else {
            setApproveRemarksError(false);
            setApproveRemarksErrorMessage("");
        }

        let ApproveShortName;

        if(selectedApproveId === 2){
            ApproveShortName= 'A'
        }

        if(selectedApproveId === 3){
            ApproveShortName= 'R'
        }

        if(selectedApproveId === 4){
            ApproveShortName= 'H'
        }

      let RequestStatusIdValue;
       if(selectedApproveId === 2){
                RequestStatusIdValue= 3
            }

        if(selectedApproveId === 4){
            RequestStatusIdValue= 3
        }

        if(selectedApproveId === 3){
            RequestStatusIdValue= 2
        }

        if (Object.keys(errors).length > 0) {
            // If there are errors, update the state with all the errors
            setApproveError(errors.approveSts || false);
            setApproveRemarksError(errors.apprveRemarks || false);
            return;
        }

        const HireRequestApprovalPayload={
            SaveUpdateKey: "update",
            CompanyId: CompanyId,
            CreatedBy:UserId,
            RequestApproveStatus: ApproveShortName, 
            RequestApproveByEmployeeId:EmployeeId,
            RequestApproveRemarks: ApproveRemarksValue,
            RequestStatusId:RequestStatusIdValue

        }

        console.log("Hire Request ApproveApprovalPayload",HireRequestApprovalPayload)
        console.log("HireRequestId",HireRequestId)

        commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/myapprovals/my_approval_hire_request_update`, 
            {
                SaveUpdateKey: "update",
                CompanyId: CompanyId,
                RequestId:HireRequestId,
                RequestApproveStatus: ApproveShortName, 
                RequestApproveByEmployeeId:EmployeeId,
                RequestApproveRemarks: ApproveRemarksValue,
                RequestStatusId:RequestStatusIdValue
            
            }
        )
        .then((res) => {
          if (res.status === 200) {
              Success("Hire Request Approval Status Updated Successfully");
              handleClose(event);
              handlegetMyPendingApprovalsList(event);
              handlegetMyApprovedList(event);
          }
          }).catch((error) => {
              console.error("Error Hire Request Approval:", error);
          });
    }
 

    useEffect(() => {
        GetApproveList();
    }, []); 


    return(
        <Box>
            <Box bgcolor={'white'} borderRadius={3}
            sx={{
                width:{
                    lg:600,
                    md:600,
                    xs:350
            }}} >
                <Box>
                <Box p={3}>
                        <Typography variant='h1'
                        sx={{
                            fontSize:{
                                lg:'2rem',
                                md:'2rem',
                                xs:'1.5rem'
                            }
                        }}
                        >
                       Hire Request  Approve
                        </Typography>
                    </Box>
                    
                    <form noValidate>
                        <Box p={3}>
                            <Grid container >
                                <Grid item lg={12} md={12} xs={12} >
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={ApproveOptions as OptionType[]}
                                        filterOptions={filterOptions}
                                        getOptionLabel={(option: unknown) => (option as OptionType).name}
                                        value={ApproveOptions.find((option: OptionType) => option.value === selectedApproveId) || null}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setSelectedApproveId(newValue.value);
                                                setApproveError(false);
                                                setApproveErrorMessage("");
                                            } else {
                                                setSelectedApproveId('');
                                                setApproveError(true);
                                                setApproveErrorMessage("Approve is required");
                                            }
                                        }}
                                        
                                        renderInput={(params) => 
                                        <TextField {...params}
                                        error={ApproveError}
                                        autoFocus
                                        helperText={ApproveErrorMessage}
                                            required label="Approve Status" 
                                        variant="standard" />}
                                    />
                                </Grid>

                                <Grid item mt={3} lg={12} md={12} xs={12} >
                                            <TextField 
                                            id="outlined-basic" 
                                            label="Approve Remarks" 
                                            variant="outlined" 
                                            name='ApproveRemarks'
                                            fullWidth
                                            multiline
                                            rows={4}
                                            error={ApproveRemarksError}
                                            onChange={handleApproveRemarksChange}
                                            value={ApproveRemarksValue}
                                            helperText={ApproveRemarksErrorMessage}                                           
                                            />
                                        </Grid>
                            </Grid>
                        </Box>

                    <Box mt={5} ml={2} mr={2} mb={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Button
                                    sx={{
                                        background: 'white',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        // color: {accent},
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}

                                    onClick={handleClose} 
                                >
                                    Back
                                </Button>

                                <Button
                                    sx={{
                                        background: '#25425F',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        "&:hover":{
                                            backgroundColor:'#25425F',
                                            color:'white',
                                        },
                                        color: 'white',
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}
                                    type='submit'
                                    onClick={(event)=>{
                                        ApproveHireRequest(event);
                                    }}
                                >
                                    Save
                                </Button>
                                </Box>
                    </form>


                </Box>
            </Box>
        
        </Box>
    )
}
export default HireRequestApprovalsPage;

// EOF SOP-76 feedback points  L2 (7) Included By PCS - Hire Request (Approvals dialog box)