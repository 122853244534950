// SOP-61 Included By PCS

// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Success } from '../../atic-common-helpers/helpers/toast.helper';
import Swal from "sweetalert2";


// Page start
const AssignCategory = 
// To get handleAssignCategoryClose & getcategorylistsearch from category page
    ({
         handleAssignCategoryClose,
        // getcategorylistsearch
    }: {
         handleAssignCategoryClose: (event: React.MouseEvent) => void,
        // getcategorylistsearch: (event: React.MouseEvent) => void
    }) =>{

    // use navigate and use state declaration
    const navigate = useNavigate();
 
    const [CategoryListOptions, setCategoryOptions] = useState<OptionType[]>([]);
    const [selectedCategoryId, setSelectedCategoryid] = useState<string | null>(null);
    const [CategoryError, setCategoryError]=useState(false)
    const [CategoryErrorMessage, setCategoryErrorMessage]=useState('')

    const [DocumentTitle, setDocumentTitle] =  useState(''); //Initialize document Titlte
    const [DocumentId, setDocumentId] = useState('')

     const GlobalDataFromSession = sessionStorage.getItem("GlobalVariable") || '{}';
//     const DocumentIdFromSession = sessionStorage.getItem("DocumentId") || '';

    const DocumentIdFromSession:any = sessionStorage.getItem("DocumentId") ;
    const VersionNumberFromSession = sessionStorage.getItem("VersionNumber")

    const GlobalData = JSON.parse(GlobalDataFromSession);
    let CompanyId = GlobalData.GlobalCompanyId;

    //  console.log('setSelectedCategoryid',selectedCategoryId)

                type OptionType = {
                                name: string;
                                value: string;
                                employee_name:string;
                                delete:number;
                                edit:number;
                };

                const filterOptions = createFilterOptions<OptionType>({
                matchFrom: 'start',
                stringify: (option) => option.name,
                });

       
        // Category List Dropdown API
             const GetCategoryList = async () =>
                {
                      const CategoryListPayload ={
                         CompanyId:CompanyId
                                    }
                 
                     try {
                         const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/docpoint/get_docpoint_categories_list`,CategoryListPayload);
                        //  console.log("Category List" ,res.data.data);
                         let formattedCategoryList = res.data.data.map((item:any) => {
                         const { docpoint_categoryid, docpoint_categoryname } = item;
                         return {
                             name: docpoint_categoryname,
                             value: docpoint_categoryid,
                         };
                         });

                         
                         setCategoryOptions(formattedCategoryList)
                     } catch (err) {
                         console.log( "co",err);
                     }
                }



    // To render handle close function to close dialouge box
            const handleClose = (event: React.MouseEvent) => {
                if (handleAssignCategoryClose && typeof handleAssignCategoryClose === 'function') {
                                handleAssignCategoryClose(event);
                }
                                                            };


    // To add category

 // Define a type for errors
    type Errors = {
        category?:boolean;
                  };

    const HandleAddCategory = async (event:any)=>
    {
        event.preventDefault();
        let errors: Errors = {};
          if (selectedCategoryId===''||selectedCategoryId===null ||selectedCategoryId===undefined ) {
                errors.category = true;
                setCategoryError(true);
                setCategoryErrorMessage("Please select an category.");
                return;
                   }
            setCategoryError(false); // Reset error if validation passes

    
        if (Object.keys(errors).length > 0) {
            // If there are errors, update the state with all the errors
            setCategoryError(errors.category || false);
            return;
            }
    
        const todayDate = new Date();
        const Year = todayDate.getFullYear();
        const Month = (todayDate.getMonth() + 1).toString().padStart(2, '0');
        const Day = todayDate.getDate().toString().padStart(2, '0');
        const FormattedDate = `${Year}-${Month}-${Day}`;
       
    
        const AddCategoryPayload = {
                                SaveUpdateKey: "update",
                                DocumentId:parseInt(DocumentId, 10),
                                CategoryId: selectedCategoryId,
                                CompanyId: CompanyId,
                                   }
    
         console.log("Add category payload", AddCategoryPayload);
    
        Swal.fire({
            html: '<span style="font-size: 1.3rem;">Are you sure to save ? </span>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            cancelButtonColor: '#d33',
            confirmButtonColor:"#25425f",
            allowOutsideClick: false,
            })
        .then((result) =>
        {
                if (result.isConfirmed)
                {
                    commonService
                    .postService(`${process.env.REACT_APP_BACKEND_URL}/docpoint/docpoint_document_assign_category`, AddCategoryPayload)
                    .then((res) => {
                    if (res.status === 200) {
                        Success("Category assigned successfully");
                        handleClose(event);
                        window.location.reload(); // Refresh the page
                     }
                    }).catch((error) => {
                        console.error("Error:", error);
                    });
                } 
                else  
                {
                return
                }
       });
    }
    
//To get Category Value if document is already categorized for CompanyId
        const ViewCategoryName = async ()=>
            {

                const DocumentIdFromSession:any = sessionStorage.getItem("DocumentId") ;

                const payload={
                                CompanyId:CompanyId,
                                DocumentId:DocumentIdFromSession,  
                                //SOP-69 Included By PCS[02/08/2024- To include Version nUmber]
                                VersionNumber:VersionNumberFromSession
                             }
              
                
                commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/docpoint/getDocumentDetails_by_id`,payload )
                .then((res: any) => 
                { 
                //  console.log("response",res.data.data)
               
                // Getting values for companyid and binding them to respective fields
                const CategoryData = res.data.data[0]
                //  console.log("CategoryData",CategoryData)


                let formattedCategoryId =res.data.data.map((item:any) => {
                const { document_docpoint_categoryid, docpoint_categoryname } = item;
                return {
                                name: docpoint_categoryname,
                                value: document_docpoint_categoryid,
                         };
                });
                //  console.log("formattedEmployeeRoleId",formattedCategoryId[0].value)
                setSelectedCategoryid(formattedCategoryId[0].value)
            
                }).catch((error) =>  
                { 
                //Failed(error.response.message);
                });

            }

    // UseEffect Declaration
        useEffect(() => {
            GetCategoryList();
           // getDocumentsList();
             const DocumentTitleFromSession: any=sessionStorage.getItem("DocumentTitleValue")
            setDocumentTitle(DocumentTitleFromSession)
            setDocumentId(DocumentIdFromSession);
            ViewCategoryName();
        }, [DocumentIdFromSession]);

 
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
     };


    return(
        <>
            <Box>
                <Box display={'flex'} justifyContent={'center'}>
                    <Box bgcolor={'white'} borderRadius={3}
                    sx={{
                        width:{
                            lg:900,
                            md:600,
                            xs:700
        
                        }}}
                    >
                    <Box p={3}>
                        <Typography variant='h2'>Assign Category</Typography>
                        <Typography mt={2} variant='h4'>Document Title  :  { DocumentTitle}
                        </Typography>
                    </Box>
                        <Box p={3}>
                            <form  noValidate>
                                <Box>
                                <Autocomplete
                                                        id="combo-box-demo"
                                                        options={CategoryListOptions as OptionType[]}
                                                        filterOptions={filterOptions}
                                                        getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                        value={CategoryListOptions.find((option: OptionType) => option.value === selectedCategoryId) || null}
                                                        onChange={(event, newValue) => {
                                                            if (newValue) {
                                                                setSelectedCategoryid(newValue.value);
                                                                setCategoryError(false);
                                                                setCategoryErrorMessage("");
                                                            } else {
                                                                setSelectedCategoryid('');
                                                                setCategoryError(true);
                                                                //Modified By PCS [02/08/2024]
                                                                // setCategoryErrorMessage("Please select an employee.");
                                                                   setCategoryErrorMessage("Please select a category");
                                                            }
                                                        }}
                                                        sx={{ml: 1.5 }}
                                                        renderInput={(params) => 
                                                        <TextField {...params}
                                                        error={CategoryError}
                                                        helperText={CategoryErrorMessage}
                                                        required label="Category" 
                                                        variant="standard" />}
                                                  />
                                </Box>
                            <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Button
                                    sx={{
                                        background: 'white',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}

                                    onClick={handleClose} 
                                >
                                    Back
                                </Button>

                                <Button
                                    sx={{
                                        background: '#25425F',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        "&:hover":{
                                            backgroundColor:'#25425F',
                                            color:'white',
                                        },
                                        color: 'white',
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}
                                    type='submit'
                                    onClick={(event)=>{
                                        HandleAddCategory(event);
                                       
                                        
                                    }}
                                >
                                    Save
                                </Button>
                                </Box>
                            </form>
                        </Box>

                    </Box>
                </Box>
            </Box>
        </>
    )

}

export default AssignCategory;

// EOF SOP-61