// SOP-20 Included By PCS- Completed

// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Success } from '../../atic-common-helpers/helpers/toast.helper';

//SOP-20 -Included By PCS Testing Feedback Corrections [to avoid multiple submission]
import Swal from "sweetalert2";
// EOF SOP-20 -Included By PCS Testing Feedback Corrections

// Page start
const AddDepartmentPage = 
// To get handleAddDepartmentClose & getdepartmentlistsearch from department page
    ({
        handleAddDepartmentClose,
        getdepartmentlistsearch
    }: {
        handleAddDepartmentClose: (event: React.MouseEvent) => void,
        getdepartmentlistsearch: (event: React.MouseEvent) => void
    }) =>{

    // use navigate and use state declaration
    const navigate = useNavigate();

    const [DepartmentError, setDepartmentError]=useState(false)
    const [DepartmentValue, setDepartmentValue]=useState('')
    const [DepartmentErrorMessage, setDepartmentErrorMessage]=useState('')
    const [CompanyId, setCompanyId] =useState('')
    const [CreatedBy, setCreatedBy] =useState('')
    
    //SOP-20 Included By PCS[Testing feedback corrections- to avoid duplicate Department]
    const [DepartmentValueEndTrim, setDepartmentValueEndTrim]=useState('')
    //EOF SOP-20 Included By PCS[Testing feedback corrections- to avoid duplicate Department]

    //  Handle Department while typing department and validations
    const handleDepartmentChange = (event:any) => {
        const trimmedDepartmentValue = event.target.value.trim();
        setDepartmentValue(event.target.value);

        //SOP-20 Included By PCS[Testing feedback corrections - to avoid duplicate Department]
        setDepartmentValueEndTrim(trimmedDepartmentValue);
        //EOF SOP-20 Included By PCS[Testing feedback corrections]

        if (trimmedDepartmentValue === '') {
            setDepartmentError(true);
            setDepartmentErrorMessage("Department is required");
        } 
        else if( trimmedDepartmentValue.length >225 ){
            setDepartmentError(true);
            setDepartmentErrorMessage("Max 255 characters");
        }
        else {
            setDepartmentError(false);
            setDepartmentErrorMessage("");
        }
    };

    // Exist Department name API
    const GetDepartmentNameExist = async () => {   
        
        const  ExistDepartmentNamePayload = {
            //SOP-20 Modified By PCS[Testing feedback corrections-to avoid duplicate department]
            //ExistDepartmentName:DepartmentValue,
              ExistDepartmentName:DepartmentValueEndTrim,
            //EOF SOP-20 Modified By PCS[Testing feedback corrections]
            DepartmentId:null,
            CompanyId:CompanyId
        }
        try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/get_department_name_exists`,ExistDepartmentNamePayload);
        //console.log(res.data.data);
        return res.data.data || [];
        } catch (err) {
        console.log( "co",err); 
        }
    };



    // To render handle close function to close dialouge box
    const handleClose = (event: React.MouseEvent) => {
        if (handleAddDepartmentClose && typeof handleAddDepartmentClose === 'function') {
            handleAddDepartmentClose(event);
        }
    };

    // to render getdepartmentlistsearch function after closing dialouge box
    const handlegetdepartmentlistsearch= (event: React.MouseEvent) => {
        if (getdepartmentlistsearch && typeof getdepartmentlistsearch === 'function') {
            getdepartmentlistsearch(event);
        }
    };



    // To add department

     // Define a type for errors
     type Errors = {
        department?:boolean;
    };
    const HandleAddDepartment = async (event:any)=>{
        event.preventDefault();
    
        let errors: Errors = {};

        const DepartmentNameList = await GetDepartmentNameExist();
        if (DepartmentNameList.length > 0) {
            setDepartmentError(true);
            setDepartmentErrorMessage("Department is already exists");
            errors.department = true;
            return;
        }
    
        const trimmedDepartmentValue = DepartmentValue.trim();
        if (trimmedDepartmentValue === '') {
            errors.department = true;
            setDepartmentErrorMessage("Department is required");
        } else if (trimmedDepartmentValue.length > 225) {
            errors.department = true;
            setDepartmentErrorMessage("Max 255 characters");
        }
    
        if (Object.keys(errors).length > 0) {
            // If there are errors, update the state with all the errors
            setDepartmentError(errors.department || false);
            return;
        }
    
        const todayDate = new Date();
        const Year = todayDate.getFullYear();
        const Month = (todayDate.getMonth() + 1).toString().padStart(2, '0');
        const Day = todayDate.getDate().toString().padStart(2, '0');
        const FormattedDate = `${Year}-${Month}-${Day}`;
        //console.log("FormattedDate",FormattedDate)
    
        const AddDepartmentPayload = {
            SaveUpdateKey: "create",
            DepartmentName: DepartmentValue,
            DepartmentId: null,
            CompanyId: CompanyId,
            Active: "Y",
            CreatedDateTime: null,
            CreatedBy: CreatedBy,
            IsRemove: null
        }
    
        //console.log("AddPL", AddDepartmentPayload);
    //SOP-20 Included By PCS[Testing feedack corrections- to avoid multiple toaster msg]

        Swal.fire({
            html: '<span style="font-size: 1.3rem;">Are you sure to save ? </span>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            cancelButtonColor: '#d33',
            confirmButtonColor:"#25425f",
            allowOutsideClick: false,
            })
        .then((result) =>
        {
        if (result.isConfirmed)
        {

    //EOF SOP-20 Included By PCS[Testing feedack corrections - to avoid multiple toaster msg]  

        commonService
      .postService(`${process.env.REACT_APP_BACKEND_URL}/master/department_create_update_delete`, AddDepartmentPayload)
      .then((res) => {
        if (res.status === 200) {
            Success("Department created successfully");
            handleClose(event);
            handlegetdepartmentlistsearch(event);
        }
        }).catch((error) => {
            console.error("Error:", error);
        });

    //SOP-20 Included By PCS[Testing feedack corrections- to avoid multiple toaster msg]

        } 
        else  
        {
        return
        }
    });

    //EOF SOP-20 Included By PCS[Testing feedack corrections-to avoid multiple toaster msg]  

    }
    

    // UseEffect Declaration
    useEffect(() => {
        const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
        const GlobalData = JSON.parse(GlobalDataFromSession);
        //console.log("GlobalData",GlobalData)
        setCompanyId(GlobalData.GlobalCompanyId)
        setCreatedBy(GlobalData.GlobalUserId)
      }, []);

  //SOP-20 Testing feedback corrections[To restrict form submission only on button]

 const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

 //SOP-20 Testing feedback corrections[To restrict form submit only on button]

    return(
        <>
            <Box>
                <Box display={'flex'} justifyContent={'center'}>
                    <Box bgcolor={'white'} borderRadius={3}
                    sx={{
                        width:{
                            lg:900,
                            md:600,
                            xs:700
        
                        }}}
                    >
                    <Box p={3}>
                        <Typography variant='h1'>
                        Add Department
                        </Typography>
                    </Box>
                        <Box p={3}>
                            <form  noValidate>
                                <Box>
                                <TextField
                                    onKeyDown={handleKeyDown} //SOP-20 [Testing feedback correction- to restrict submission only on button]
                                    type='text'
                                    //SOP-20 Inlcuded By PCS[Testing feedback Correction- to set auto focus]
                                     autoFocus
                                     //EOF SOP-20 Included By PCS [Testing feedback Correction- to set autofocus]
                                    variant="standard"
                                    label='Department'
                                    name='Department'
                                    style={{ margin: '0 10px' }}
                                    fullWidth
                                    required
                                    error={DepartmentError}
                                    onChange={handleDepartmentChange}
                                    value={DepartmentValue}
                                    helperText={DepartmentErrorMessage}
                                /> 
                                </Box>
                            <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Button
                                    sx={{
                                        background: 'white',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        // color: {accent},
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}

                                    onClick={handleClose} 
                                >
                                    Back
                                </Button>

                                <Button
                                    sx={{
                                        background: '#25425F',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        "&:hover":{
                                            backgroundColor:'#25425F',
                                            color:'white',
                                        },
                                        color: 'white',
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}
                                    type='submit'
                                    onClick={(event)=>{
                                        HandleAddDepartment(event);
                                    }}
                                >
                                    Save
                                </Button>
                                </Box>
                            </form>
                        </Box>

                    </Box>
                </Box>
            </Box>
        </>
    )

}

export default AddDepartmentPage;

// EOF SOP-20