// SOP-22 Included by PCS[to set email configuration]
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,    
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControl,
    FormHelperText,
    useTheme,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import deleteAlert from '../../component/HOC/deleteAlert';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FcInfo } from "react-icons/fc";
import { FaUserGear } from "react-icons/fa6";
import { BsBuildingsFill } from "react-icons/bs";
import { IoMdPhotos } from "react-icons/io";
import Swal from "sweetalert2";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { BiSolidPurchaseTag } from "react-icons/bi";
import { GrSystem } from "react-icons/gr";
import { PiMedalFill } from "react-icons/pi";
import { LiaEditSolid } from "react-icons/lia";


const EmailConfigurationCompany =()=>{

    // Use navigate
     const navigate = useNavigate();

   // Use State declaration 
   
    const [HostNameError, setHostNameError]=useState(false) 
    const [HostNameValue, setHostNameValue]=useState('') 
    const [HostNameErrorMessage, setHostNameErrorMessage]=useState('')

    const [PortNumberError, setPortNumberError]=useState(false) 
    const [PortNumberValue, setPortNumberValue]=useState('')
    const [PortNumberErrorMessage, setPortNumberErrorMessage]=useState('')

    const [SenderEmailError, setSenderEmailError]=useState(false) 
    const [SenderEmailValue, setSenderEmailValue]=useState('') 
    const [SenderEmailErrorMessage, setSenderEmailErrorMessage]=useState('') 

    const [PasswordError, setPasswordError]=useState(false)  
    const [PasswordValue, setPasswordValue] = useState('')  
    const [PasswordErrorMessage, setPasswordErrorMessage]=useState('')  

    const [SSLEnabled, setSSLEnabled] = useState(true);
    
    //value is set True  for 'create' & false  for 'update'
    const [SaveOperationIsCreate, setSaveOperationIsCreate]=useState(true)  
    
    console.log('setSaveOperationIsCreate',SaveOperationIsCreate)
   
    //Toggle function for SSL Enabled 
    const handleToggleActive = () => {
        setSSLEnabled((prevIsActive) => !prevIsActive);
                                      };

   
    // Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    let CompanyId = GlobalData.GlobalCompanyId
    //console.log("CompanyId",CompanyId)

    // To handle Host name 
    const handleHostNameChange =( event:any)=>
    {
      setHostNameValue(event.target.value);
    }

    // To handle Port number 
    const handlePortNumberChange =( event:any)=>
    {
      setPortNumberValue(event.target.value);
    }


    // To handle Sender Email 
    const handleSenderEmailChange =( event:any)=>
    {
     setSenderEmailValue(event.target.value);
    }

    // To handle Password 
   const handlePasswordChange =( event:any)=>
    {
     setPasswordValue(event.target.value);
    }

  // Define a type for errors
    type Errors = {
        HostName?:boolean;
        PortNumber?:boolean;
        SenderEmail?:boolean;
        Password?:boolean;
                 };

    const CreateEmailConfigurationCompany =async (event:any)=>
  {
        event.preventDefault();

        let errors: Errors = {};
    //Host Name
       const trimmedHostNameValue = HostNameValue.trim();
        if (trimmedHostNameValue === '') {
             errors.HostName=true
             setHostNameErrorMessage("Host name is required");
        } 
        else if( trimmedHostNameValue.length >255 ){
            errors.HostName=true
            setHostNameErrorMessage("Max 255 characters");
        }
       
    //Port Number 
       const trimmedPortNumberValue = PortNumberValue.trim();
       if (trimmedPortNumberValue === '') {
            errors.PortNumber=true
            setPortNumberErrorMessage("Port Number is required");
         } 
         else if( trimmedPortNumberValue.length >5 ){
            errors.PortNumber=true
            setPortNumberErrorMessage("Max 5 characters");
        }

    //Sender Email  
        const trimmedSenderEmailValue = SenderEmailValue.trim();
        if (trimmedSenderEmailValue === '') {
            errors.SenderEmail=true
            setSenderEmailErrorMessage("Sender Email  is required");
        } 
         else if( trimmedSenderEmailValue.length >255 ){
            errors.SenderEmail=true
            setSenderEmailErrorMessage("Max 255 characters");
        }
        else if( !(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(trimmedSenderEmailValue) ){
            errors.SenderEmail=true
            setSenderEmailErrorMessage("Invalid Email");
        }

    //Password     
        const trimmedPasswordValue = PasswordValue.trim();
        if (trimmedPasswordValue === '') {
            errors.Password=true
            setPasswordErrorMessage("Password is required");
        } 
         else if( trimmedPasswordValue.length >255 ){
            errors.Password=true
            setPasswordErrorMessage("Max 255 characters");
        }
      
       
   
        if (Object.keys(errors).length > 0) {
            // If there are errors, update the state with all the errors
            setHostNameError(errors.HostName || false);
            setPortNumberError(errors.PortNumber || false);
            setSenderEmailError(errors.SenderEmail|| false);
            setPasswordError(errors.Password || false);
            
            console.log("errors",errors)
            return;
        }

        const CreateEmailConfigurationPayload={
            SaveUpdateKey:SaveOperationIsCreate?'create':'update',
            CompanyId:CompanyId,
            HostName:HostNameValue,
            PortNumber:PortNumberValue,
            SenderEmail:SenderEmailValue,
            Password:PasswordValue,
            SSLEnable:SSLEnabled?'Y':'N',
            IsRemove:null
        }

      console.log("CreateEmailConfigurationPayload",CreateEmailConfigurationPayload)

    commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/emailconfigurationCompany/emailconfigurationCompany_create_update_delete`, CreateEmailConfigurationPayload)
        .then((res) => {
          if (res.status === 200) {
              Success("Email Configuration set successfully");
              navigate('/backoffice')
          }
          }).catch((error) => {
            Failed("Email Configuration set failed");
              //console.error("Error:", error);
          });


  }

// UseEffect declaration
    useEffect(() => {

//To get emailconfiguration for CompanyId

 commonService
         .postService(`${process.env.REACT_APP_BACKEND_URL}/emailconfigurationCompany/get_emailconfiguration_company_list_by_id`, {
            CompanyId:CompanyId
         })
         .then((res: any) => 
         { 
           console.log("res",res.data.total_records)
           //Setting setSaveOperationIsCreate to false[To update]
           if(res.data.total_records>0){
               setSaveOperationIsCreate(false)
           }
           //Setting setSaveOperationIsCreate to true [To Create]
           else {
            setSaveOperationIsCreate(true)
                }

// Getting values for companyid and binding them to respective fields
            const EmailConfigurationCompanyData = res.data.data[0]
            console.log("EmailConfigurationCompanyData",EmailConfigurationCompanyData)
            setHostNameValue(EmailConfigurationCompanyData.company_mailhostname)
            setPortNumberValue(EmailConfigurationCompanyData.company_mailportnumber)
            setSenderEmailValue(EmailConfigurationCompanyData.company_mailusername)
            setPasswordValue(EmailConfigurationCompanyData.company_mailpassword)

            if(EmailConfigurationCompanyData.company_mailsslenabled === 'Y')
            {
              setSSLEnabled(true)
            }
            else
            {
              setSSLEnabled(false)
            }
         }).catch((error) =>  
         { 
          //Failed(error.response.message);
         });
  
     }, []);

//Use Effect ends here

    return(
        <>
        <MainContainerWrapper
        headingText='Email Configuration'>
            <Box>
            <form noValidate>
                <Box>
                <Accordion defaultExpanded sx={{
                            borderRadius:'30px',
                        }}>
                            <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            > 
                            
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                   <FcInfo height={'1rem'} width={'1rem'} fontSize={'1.5rem'} /> 
                                <Typography fontSize={'1rem'} ml={2}>Email Configuration</Typography>  
                                </Box>
                             </AccordionSummary> 
                            <AccordionDetails>
                            <Box >
                            {/* Grid first row */}
                                        <Grid container mb={5} spacing={5} display={'flex'} justifyContent={'center'} >

                                           
                                            <Grid item lg={3} md={12} xs={12} >
                                                <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Email Host'
                                                    name='EmailHost'
                                                    fullWidth
                                                    required
                                                    error={HostNameError}
                                                    onChange={handleHostNameChange}
                                                    value={HostNameValue}
                                                    helperText={HostNameErrorMessage}
                                                /> 
                                            </Grid>

                                          
                                           <Grid item lg={3} md={12} xs={12} >
                                            <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Port Number'
                                                    name='PortNumber'
                                                    fullWidth
                                                    required
                                                    error={PortNumberError}
                                                    onChange={handlePortNumberChange}
                                                    value={PortNumberValue}
                                                    helperText={PortNumberErrorMessage}
                                                /> 
                                            </Grid>

                                            <Grid item lg={3} md={12} xs={12} >
                                            <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Sender Email'
                                                    name='Sender Email'
                                                    fullWidth
                                                    required
                                                    error={SenderEmailError}
                                                    onChange={handleSenderEmailChange}
                                                    value={SenderEmailValue}
                                                    helperText={SenderEmailErrorMessage}
                                                /> 
                                            </Grid>

                                            <Grid item lg={3} md={12} xs={12} >
                                            <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Password'
                                                    name='Password'
                                                    fullWidth
                                                    required
                                                    error={PasswordError}
                                                    onChange={handlePasswordChange}
                                                    value={PasswordValue}
                                                    helperText={PasswordErrorMessage}
                                                /> 

                                            </Grid>
                                        </Grid>
                            {/* Grid First row ends*/}
                            {/* Grid Second row */}
                                       <Grid container mb={5} spacing={5} >
                                            <Grid item lg={3} md={3} xs={3} > 
                                            <Typography className="label_design">
                                            <label style={{fontSize:'0.7rem'}}>SSL Enabled</label>
                                            </Typography>
                                            <label className="switchToggle">
                                            <input
                                                type="checkbox"
                                                checked={SSLEnabled}
                                                onChange={handleToggleActive} // Call the toggle function
                                            />
                                            <span className="sliderToggle round"></span>
                                            </label>
                                                                              
                                          </Grid> 
                                        </Grid>     
                            {/* Grid Second row ends */}
                                       
                            </Box>
                            </AccordionDetails>
                </Accordion>
                </Box>
                {/* End of General Information Accordian */}

               
                <Box>                    
                    <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Button
                                        sx={{
                                            background: 'white',
                                            border: 'none',
                                            borderRadius: '5px',
                                            padding:{
                                                lg: "0 70px",
                                                md: "0 70",
                                                xs:'0 50px'
                    
                                            },
                                            height: "3rem",
                                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                        }}

                                        onClick={()=>{
                                            navigate('/backoffice')
                                        }} 
                                    >
                                        Back
                                    </Button>
                                   
                                <Button
                                        sx={{
                                            background: '#25425F',
                                            border: 'none',
                                            borderRadius: '5px',
                                            padding:{
                                                lg: "0 70px",
                                                md: "0 70",
                                                xs:'0 50px'
                                            },
                                            "&:hover":{
                                                backgroundColor:'#25425F',
                                                color:'white',
                                            },
                                            color: 'white',
                                            height: "3rem",
                                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                        }}
                                        type='submit'
                                        onClick={(event)=>{
                                           CreateEmailConfigurationCompany(event);
                                            
                                        }}
                                    >
                                        Save
                                    </Button>

                     
                    </Box>
                </Box>

            </form>
                
            </Box>
        </MainContainerWrapper>
        
        </>
    )

}

export default EmailConfigurationCompany;

// EOF SOP-22