// Included by sanjana SOP-56 to add hierarchy flow setp 1
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import axios from "axios";
import { useNavigate } from "react-router-dom";
  import { Success ,Failed } from '../../atic-common-helpers/helpers/toast.helper';
import MainContainerWrapper from '../../atic-common-helpers/layout/wrappers/MainContainerWrapper';
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import Swal from 'sweetalert2';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import HierarchyDragDropGrid from './HierarchyGrid';
import HierarchyTree from './PreviewHierarchyChart';




const AddHierarchyChartPage = () =>{

    // Use navigate
    const navigate = useNavigate();

    // useState
    let  [order, setOrder] = React.useState<"desc" | "asc">("desc");
    let  [orderBy, setOrderBy] = React.useState("");
    let  [tableData, setTableData] = React.useState([]);
    const [page, setPage] = React.useState<number>(0);
    const [count, setCount] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
    const [HierarchyChartopen, setHierarchyChartOpen] = React.useState(false);

    const handleClose = () => {
      setHierarchyChartOpen(false);
    };
    
    // Head cell of designation
    const DesignationHeadCell = [

        {
        id: "designationname",
        numeric: false,
        disablePadding: false,
        label: "Designation",
        isSort: true,
        isBold: true,
    },
    {
        id: "CheckBox",
        numeric: false,
        disablePadding: false,
        label: "Actions",
        isSort: false,
    },
    ];
        
        
    
    const [cells, setCells] = useState<any>(DesignationHeadCell);
    //  Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    let CompanyId = GlobalData.GlobalCompanyId

    //  To get designation list search API  
    const Getdesignationlistsearch = () => 
    {
    
    // Initializing Variable 
        let DesignationName = null;
        let bActiveState = ""; 
    
    for (const item of cells)
    {
        if (item.id === "designationname") {
        DesignationName = item.value;
        }
    
        if (item.id === "active") {
        bActiveState = item.value;
        }
    }

        commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/master/get_designation_list_search`, {
        SearchDesignationName:DesignationName,
        SearchDesignationActive:  bActiveState,
        SortColumnName: orderBy,
        SortColumnDirection: order,
        StartIndex: page + 1,
        PageSize: rowsPerPage,
        CompanyId:CompanyId,
        })
        .then((res: any) => 
        {
        ////console.log("res",res)
        const formattedTableData= res.data.data.map((item:any)=>{
        return {...item,
        id:item.designationid
    }
        }) 
        ////console.log("formattedTableData",formattedTableData)
        
        setTableData(formattedTableData);
        if(res.data.data[0]===undefined)
        {
            setCount(0);
        }
        else
        {
            setCount(res.data.data[0].total_count);
        }
        })
    .catch((error : any) => 
        {
        if (error.response.status === 404)
        {
            setTableData([]);
        }
        Failed(error.response.message);
        });
    };

    // To get selected designations
    const handleActionOnCheckedRows = (checkedIds: string[][]) => {
        const designationIds = checkedIds[0];
        const designationNames = checkedIds[1];
        ////console.log("designationNames",designationNames)
    
        if (designationIds.length === 0 && designationNames.length === 0) {
            Swal.fire({
                html: '<span style="font-size: 1.3rem;">Minimum 2 designations must be selected</span>',
                confirmButtonText: 'OK',
            });
        } 

        else if (designationIds.length === 1 && designationNames.length === 1) {
            Swal.fire({
                html: '<span style="font-size: 1.3rem;">Minimum 2 designations must be selected</span>',
                confirmButtonText: 'OK',
            });
        }
        else {
            sessionStorage.setItem("selectedTableDataDesignationName", JSON.stringify(designationNames))
            sessionStorage.setItem("selectedTableDataDesignationId", JSON.stringify(designationIds))
            navigate("/add_hierarchy_page_step2")
        }
    };
 
       
    // Sort Handler
    const handleRequestSort = (event: any, property: any) =>
    {
    const isAsc = orderBy === property && order === "asc";
    order = isAsc ? "desc" : "asc";
    setOrder(order);
    orderBy = property;
    setOrderBy(orderBy);
    };

    // Action function of table icons
    const actionFunction = (id: string, actionText: string) => 
        {
            if (actionText === "CheckBox"){
            };
        };
        
    
    // Search Handler of table
    const handleSearch = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const search = [...cells];
        const index = cells.findIndex((c: any) => c.id === e.target.name);
        //////console.log("e.target.name",e.target.name)
        if (index !== -1) {
            search[index].value = e.target.value;
        }
        //////console.log("search",search)
        setCells(search);
        handleChangePage(setPage,0);
        Getdesignationlistsearch();
    };


    // Pagination Handler of table
    const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    };

    // Rows Per page Handler
    const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
        ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    };
    


    useEffect(() => {
        Getdesignationlistsearch();
        }, [rowsPerPage, page, orderBy, order]); 
        
        

    return(
        <>
            <MainContainerWrapper headingText='Create Hierarchy'>
            <Box bgcolor={'white'}>
                <Box p={3} display={'flex'} justifyContent={'center'} alignItems={'center'} >
                    <Typography variant='h3'>
                        Select the designation(s) to create a hierarchy of organization chart
                    </Typography>                    
                </Box>

                <Box mt={1}
                //  display={'flex'} justifyContent={'center'}
                  > 
                    <Box 
                    // width={700} 
                    >
                        <EnhancedTable
                        headCells={DesignationHeadCell}
                        rows={tableData}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleSearch={handleSearch}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        handleRequestSort={handleRequestSort}
                        order={order}
                        orderBy={orderBy}
                        actionFunction={actionFunction}
                        isView={false}
                        isDelete={false}
                        isEdit={false}
                        total={count}
                        isSearch={true}
                        actionOnCheckedRows={handleActionOnCheckedRows}
                        />

                    </Box>
                </Box>
                <Dialog
                open={HierarchyChartopen} fullScreen >
                    <Box>
                        <Typography variant='h1' mt={1} display={'flex'} justifyContent={'center'} > HIERARCHY </Typography>
                        <Box mt={2} display={'flex'} justifyContent={'center'} >
                            <HierarchyTree/>
                        </Box>

                        <Button
                        sx={{
                            mb:3,
                            ml:3,
                            background: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            padding:{
                                lg: "0 70px",
                                md: "0 70",
                                xs:'0 50px'
    
                            },
                            height: "3rem",
                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                        }}

                        onClick={handleClose}>
                            Back
                        </Button>
                    </Box>                    
                </Dialog>
            </Box>
            <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Button
                                        sx={{
                                            background: 'white',
                                            border: 'none',
                                            borderRadius: '5px',
                                            padding:{
                                                lg: "0 70px",
                                                md: "0 70",
                                                xs:'0 50px'
                    
                                            },
                                            height: "3rem",
                                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                        }}

                                        onClick={()=>{
                                            navigate('/backoffice')
                                            sessionStorage.removeItem("HierarchyDataToStoreInSession")
                                        }} 
                                    >
                                        Back
                                    </Button>
                    </Box>
            </MainContainerWrapper>
        </>

    )

}

export default AddHierarchyChartPage;


// End of SOP-56