//SOP-38 - TS
//Asset Management -Dashboard - Ticketing

//Assignee pie chart
import { PieChart} from '@mui/x-charts/PieChart';
import { useState, useEffect } from "react";
import { commonService } from "../../atic-common-helpers/helpers/common.service";

// interface for the Assignee count data
interface PieValueType {
id: number;
value: number;
label: string;
}

const  Assignee=()=> {
  const [CompanyId, setCompanyId] =useState('')
  const [CreatedBy, setCreatedBy] =useState('')
  const [Assignee, setAssignee] = useState<PieValueType[]>([]);
  // console.log("Assignee",Assignee)
  
  useEffect(() => 
  {
      const Assignee = async () => {
        try {
        //Global data
        const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
        const GlobalData = JSON.parse(GlobalDataFromSession);
        setCompanyId(GlobalData.GlobalCompanyId);
        setCreatedBy(GlobalData.GlobalUserId);
  
        //Assignee Payload
        const AssigneePayload = {
          companyid: GlobalData.GlobalCompanyId,
        };
  
        //API
        const response = await commonService
          .postService(`${process.env.REACT_APP_BACKEND_URL}/dashboardController/getTicketAssigneeCount`, AssigneePayload);
          // console.log("response",response)
          // checks array 
          if (Array.isArray(response.data.data)) {
            const data: PieValueType[] = response.data.data.map((item: any, index: number) => ({
              id: index,
              value: item._ticket_allocateto, 
              //SOP-77 feedback points  Modified By PCS [to include count]
              // label: item._ticket_allocatetoname 
              label: `${item._ticket_allocatetoname} (${item._ticket_allocateto})` // Adding count in brackets
              //EOF SOP-77 feedback points  Modified By PCS [to include count]
            }));
            // console.log("Transformed Data:", data); 
          setAssignee(data);
        } else {
          console.error("Response data", response.data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    Assignee();
  }, []);

 return (
  <PieChart
  series={[
    {
      data: Assignee
    }
  ]}
     width={400}
     height={200}

     sx={{
       "& text": {
         fontSize: '10px',          
      }
    }}
   />
  )}
export default Assignee





