import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
//include tool tip for consultant time settings
import { Button, InputBase, Stack, TextField, Typography, Tooltip, Checkbox } from "@mui/material";
import Switch, { SwitchProps } from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import "./selectedTable.scss";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { styled, useTheme } from "@mui/material/styles";
import filterIcon from "../../../atic-common-helpers/asset/images/filtericon.svg";

import ManageHistoryIcon from '@mui/icons-material/ManageHistory';

function EnhancedTableHead(props: EnhancedTableHeadTypes) {
  const {
    order,
    orderBy,
    rowCount,
    onRequestSort,
    headCells,
    handleSearch,
    isSearch,
  } = props;

  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };
 

 return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: any, index: number) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'left'}
            align={headCell.id === "action" ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className="tableheading"
            sx={{ border: "0" }}
          >
            {headCell?.isSort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}{" "}
                <img
                  src={filterIcon}
                  alt="filterimage"
                  className="filterimage"
                />
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <Box sx={{ position: "relative" }}>
                <span> {headCell.label}</span>
              </Box>
            )}

            {headCell.id === "action"
            // Commented by sanjana SOP-15 to set search text for active 
            //  || headCell.id === "active"
            // End of SOP-15
            || headCell.id === "Rights"

            // Included by sanjana SOP-56 to skip search box for check box
            || headCell.id === "CheckBox"
            // End of SOP-56
            
             ? (
              ""
            ) : (
              <TextField
                value={headCell.value}
                size="small"
                name={headCell.id}
                defaultValue={headCell.defaultValue}
                onChange={handleSearch}
                sx={{
                  //backgroundColor: "#f0f2f2",
                  borderRadius: "7px",
                  display: isSearch ? "block" : "none",
                }}
                placeholder="Search"
              ></TextField>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

type EnhancedTableHeadTypes = {
  onRequestSort: (_: any, __: any) => void;
  handleSearch?: any;
  order: "asc" | "desc";
  orderBy: string;
  rowCount: number;
  headCells: any[];
  isSearch: boolean;
};

type EnhancedTableTypes = {
  page: number;
  rowsPerPage: number;
  handleChangePage: (_: any, __: any) => void;
  handleChangeRowsPerPage: (_: any) => void;
  headCells: any[];
  handleRequestSort: (_: any, __: any) => void;
  rows: any[];
  order: "asc" | "desc";
  orderBy: string;
  total: number;
  actionFunction?: (_: string, __: string) => void;
  handleSearch?: any;
  isConsultantTime?:boolean;
  isEdit?: boolean;
  isView?: boolean;
  isActive?: boolean;
  isDelete?: boolean;
  isSearch?: boolean;
  setActive?: (_: string, __: boolean) => void;
  HandleRowClick?:(rowData: any) => void;
  // HandleRowClick?:(rowData: any, event: any) => void;
  // Included by sanjana SOP-56 to pass selected check box data
  actionOnCheckedRows?:(checkedIds: any) => void;
  // End of SOP-56
  title?:any;
  HoverColor?:any
};

const defaultHandleRowClick = (rowData: any) => {
};

// Included by sanjana SOP-56 to set default value
const defaultactionOnCheckedRows = (checkedIds: any) => {
};
// End of SOP-56



export default function EnhancedTable(props: EnhancedTableTypes) {
  const {
    page = 0,
    rowsPerPage = 10,
    total,
    handleChangePage,
    handleChangeRowsPerPage = () => {},
    headCells = [],
    handleRequestSort = () => {},
    rows = [],
    order,
    orderBy,
    actionFunction,
    isConsultantTime = false,
    isEdit = false,
    isView = false,
    isDelete = false,
    isActive = false,
    handleSearch,
    isSearch = false,
    HandleRowClick= defaultHandleRowClick,
    setActive,
    title,
    // Included by sanjana SOP-56 to pass default vale so that other page cannot affect
    actionOnCheckedRows = defaultactionOnCheckedRows,
    // End of SOP-56
    HoverColor,
  } = props;

  const IOSSwitch = styled((props: SwitchProps, checked: any) => (
    // <Switch
    //   focusVisibleClassName=".Mui-focusVisible"
    //   disableRipple
    //   {...props}
    // />
    <Switch
      checked={checked}
      {...props}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      // onChange={onchange}
      // inputProps={{ "aria-label": "controlled" }}
      sx={{ color: "#283891" }}
    />
  ))(({ theme }) => ({
    width: 35,
    height: 18,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark"
              ? "#2ECA45"
              : theme.palette.primary.buttonColor,
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33CF4D",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 14,
      height: 14,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#28389106",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
      "&:hover": {
        backgroundColor: HoverColor || '',
        },
  }));

  const visibleRows = React.useMemo(() => rows, [rows]);

    // Included by sanjana SOP-56 use state
    const [checkedState, setCheckedState] = React.useState<{ [key: string]: boolean }>({});
    // Define a type for the checked data
    type CheckedData = { name: string, id: any };

    // Use the type when initializing the state
    const [checkedNameAndId, setCheckedNameAndId] = React.useState<CheckedData[]>([]);

    const handleCheckboxChange = (rowName: string, rowId: any) => {
      const newCheckedData = { name: rowName, id: rowId };
    
      // Toggle the checked state
      setCheckedState((prevState) => ({
        ...prevState,
        [rowName]: !prevState[rowName],
      }));
    
      // Add or remove the item from the checkedNameAndId array
      setCheckedNameAndId((prevState) => {
        if (prevState.some(item => item.name === rowName)) {
          // If the item is already in the array, remove it
          return prevState.filter(item => item.name !== rowName);
        } else {
          // If the item is not in the array, add it
          return [...prevState, newCheckedData];
        }
      });
    };
    
    // To ger selected data
    const getCheckedIds = () => {
      // Filter the array for checked items and map to retrieve the ids
      return [checkedNameAndId.filter(item => checkedState[item.name]).map(item => item.id),
      checkedNameAndId.filter(item => checkedState[item.name]).map(item => item.name)          
    ] ;
    };

    const showCheckedRowsButton = headCells.some(
      (headCell) => headCell.id === "CheckBox"
    );
  
    // To pass data to respective page
    const handleActionOnCheckedRows = () => {
      const checkedIds = getCheckedIds();
      // console.log("checkedIds",checkedIds)
      actionOnCheckedRows(checkedIds);
    };

    // End of SOP-56
  
  const EnhancedTableCell = (row: any, head: any, i: number) => {
    const theme = useTheme();
    if (row[head]) {
      return (
        <Typography variant="subtitle1" fontWeight="bold">
          {row[head.id]}
        </Typography>
      );
    }
    switch (head.id) {

      case "action":
        return (
          <Stack flexDirection="row" justifyContent="space-evenly">

            {isConsultantTime && (
              //included tooltip
              <Tooltip title='Consultant Time Settings' disableFocusListener>
              <IconButton
                aria-label="more"
                id="long-button"
                sx={{ py: 0 }}
                aria-haspopup="true"
                disabled={!row.IsActive}
                onClick={() =>
                  actionFunction && actionFunction(row.id, "consultantTime")
                }
              >
          
                <ManageHistoryIcon
                    // sx={{ color: theme.palette.primary.buttonColor, p: 0 }}
                    sx={{
                      color: row.IsActive ? theme.palette.primary.buttonColor : 'gainsboro',
                      p: 0,
                    }}
                  />

                </IconButton>
                </Tooltip>
            )}
            {isView && (
              <Tooltip title='View' disableFocusListener>
              <IconButton
                aria-label="more"
                id="long-button"
                sx={{ py: 0 }}
                aria-haspopup="true"
                onClick={() =>
                  actionFunction && actionFunction(row.id, "view")
                }
              >
                <RemoveRedEyeOutlinedIcon
                  sx={{ color: theme.palette.primary.buttonColor, p: 0 }}
                />
              </IconButton>
              </Tooltip>                            
            )}
            {isEdit && (
            <Tooltip title='Edit' disableFocusListener>              
              <IconButton
                aria-label="more"
                id="long-button"
                aria-haspopup="true"
                sx={{ py: 0 }}
                // sx={{p:0}}
                onClick={() => actionFunction && actionFunction(row.id, "edit")}
              >
                <EditOutlinedIcon
                  sx={{ color: theme.palette.primary.buttonColor, p: 0 }}
                />
              </IconButton>
              </Tooltip>              
            )}

            {isDelete && (
              <Tooltip title='Delete'>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-haspopup="true"
                sx={{ py: 0 }}
                onClick={() =>
                  actionFunction && actionFunction(row.id, "delete")
                }
              >
                <DeleteOutlineIcon
                  sx={{ color: theme.palette.primary.buttonColor, p: 0 }}
                />
              </IconButton>
              </Tooltip>
            )}
            {
              isActive &&
              (       <IOSSwitch
                checked={row.is_active}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setActive && setActive(row.id, event.target.checked)
                }
              />       )
            }
          </Stack>
        );

        // Included by sanjana SOP-29 to add Approve button for action 
        case "Approve":
        return <Button onClick={()=>{
          actionFunction && actionFunction(row.id, "Approve")
        }} 
        sx={{
          backgroundColor:'#25425F',
          color:'white',
          "&:hover": {
            backgroundColor: "#25425F",
            color:'white',
            }
        }}
        >Approve</Button>;

        // End of SOP-29

        // Included by sanjana SOP-34 to add Allocate button for action 
        
        case "Allocate":
          return <Button onClick={()=>{
            actionFunction && actionFunction(row.id, "Allocate")
          }} 
          sx={{
            backgroundColor:'#25425F',
            color:'white',
            "&:hover": {
              backgroundColor: "#25425F",
              color:'white',
              }
          }}
           //SOP-75 feedback points L1 modified by PCS [rename 'allocate' to 'assign']
              // >Allocate</Button>;
               >Assign</Button>;
           //EOF SOP-75 feedback points L1 modified by PCS

        // Included by sanjana SOP-37 to add Open button for action 
        case "Open":
          return <Button onClick={()=>{
            actionFunction && actionFunction(row.id, "Open")
          }} 
          sx={{
            backgroundColor:'#25425F',
            color:'white',
            "&:hover": {
              backgroundColor: "#25425F",
              color:'white',
              }
          }}
          >Open</Button>;
          // End of SOP-37

          // Included by sanjana SOP-56 to add check box for action 
          case "CheckBox":
            return <Box>
              <Checkbox
                checked={checkedState[row.designationname] || false}
                onChange={
                  () =>{
                    handleCheckboxChange(row.designationname,row.id)
                  } 
                }
              />
            </Box>
            
            ;
            // End of SOP-56

        // Modified by sanjana SOP-15 to rename active insted IsActive 
        // case "IsActive":
        case "active":
          // End of SOP-15
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              fontWeight: "200",
            }}
          >
            {/* Modified by sanjana SOP-15  to set Active / Inactive if the active table data is 'Y' */}
            {/* {row[head.id] ? "Active" : "Inactive" } */}
            {row[head.id] === 'Y' ? 'Active' : 'Inactive'}
            {/* End of SOP-15 */}
    
          </div>
        );

        // Included by sanjana SOP-19 To add case for isactive and isallocated
        case "isactive":
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              fontWeight: "200",
            }}
          >
            {row[head.id] === 'Y' ? 'Active' : 'Inactive'}   
          </div>
        );

        case "isallocated":
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              fontWeight: "200",
            }}
          >
            {row[head.id] === 'Y' ? 'Allocated' : 'Not Allocated'}   
          </div>
        );
        // End of SOP-19

        // Included by sanjana SOP-26 to add is cancelled 
        case "iscancelled":
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                fontWeight: "200",
              }}
            >
              {row[head.id] === 'Y' ? 'Yes' : 'No'}   
            </div>
          );
          // End of SOP-26


      default:
        return row[head.id];
    
    }
 
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer
          className="table-container"
          sx={{ maxHeight: 690, overflowX: "scroll" }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 550 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              handleSearch={handleSearch}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length ?? 0}
              headCells={headCells}
              isSearch={isSearch}
            />
            <TableBody>
              {visibleRows.map((row: any, index: number) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <StyledTableRow
                    // onClick={(event) => {}}
                    // onDoubleClick={() => HandleRowClick(row)}
                    onClick={() => HandleRowClick(row)}
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    sx={{ cursor: "pointer" }}
                  >
                    {headCells.map((head: any, i: number) => (
                      //  (SOP-66) HR - Hire Request - Mapping with Employee Enrollment - included ToolTip - TS
                      <Tooltip title={title}>
                        <TableCell
                        key={`table-cell-td-${i}${row.id}`}
                        align="left"
                        sx={{ border: "2", p: "16px 5px 16px 10px",
                        // Modified by sanjana SOP-28 to reduce padding size
                        // padding:1
                         padding:0.5
                        //  end of SOP-28
                         }}

                      >
                        {EnhancedTableCell(row, head, i)}
                        </TableCell>
                        {/* //  (SOP-66) HR - Hire Request - Mapping with Employee Enrollment - included ToolTip - TS  - end*/}

                     </Tooltip>
                    ))}
                  </StyledTableRow>
                );
              })}
              {!visibleRows?.length && (
                <TableRow
                  style={{
                    height: 60,
                  }}
                >
                  <TableCell colSpan={headCells.length} align="center">
                    No records found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {/* Included by sanjana SOP-56 to set next button  */}
          {showCheckedRowsButton && (
            <Box display={'flex'} justifyContent={'flex-end'} >
                {/* <Button onClick={handleActionOnCheckedRows}>OK</Button> */}
                <Button
                    sx={{
                        background: '#25425F',
                        border: 'none',
                        borderRadius: '5px',
                        mr:5,
                        padding:{
                            lg: "0 70px",
                            md: "0 70",
                            xs:'0 50px'

                        },
                        "&:hover":{
                            backgroundColor:'#25425F',
                            color:'white',
                        },
                        color: 'white',
                        height: "3rem",
                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                    }}
                    type='submit'
                    onClick={()=>{
                      handleActionOnCheckedRows();
                    }}
                >
                    Next
                </Button>
            </Box>
          )}
          {/* End of SOP-56 */}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
