
// commented by TS - SOP-49 - start
// Asset Management-Ticketing-Create Page

// // Included by sanjana SOP-26 to create raise a ticket page
// // Imports
// import { useState,useRef,useEffect } from 'react'; // Import useState hook
// import React from 'react';
// import {
//     Box,
//     Button,
//     TextField,
//     InputAdornment,
//     Avatar,
//     useMediaQuery,
//     Menu,
//     MenuItem,
//     Grid,
//     Paper,
//     Divider,
//     Tooltip,
//     Dialog,
//     TextareaAutosize,
//     FormHelperText,
// } from '@mui/material';
// import Typography from '@mui/material/Typography';
// import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
// import { commonService } from '../../atic-common-helpers/helpers/common.service';
// import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
// import deleteAlert from '../../component/HOC/deleteAlert';
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
// import FormLabel from '@mui/material/FormLabel';



// const RaiseATicketPage  =
// ({
//     handleAddTicketClose,
//     getTicketlistsearch,
//     // Included by sanjana SOP-28 to get getTicketTypeRequestList from home page
//     getTicketTypeRequestList
//     // End of SOP-28
// }: {
//     handleAddTicketClose: (event: React.MouseEvent) => void,
//     getTicketlistsearch: (event: React.MouseEvent) => void,
//     getTicketTypeRequestList: (event: React.MouseEvent) => void
// })=>{



//     // const [IncidentValue, setIncidentValue]=useState('')
//     // const [RequestValue, setRequestValue]=useState('')
//     const [TicketTypeValue, setTicketTypeValue]=useState('1')
//     const [TicketTypeError, setTicketTypeError]=useState(false)
//     const [TicketTypeErrorMessage, setTicketTypeErrorMessage]=useState('')
//     const [IssuediscriptionError, setIssuediscriptionError]=useState(false)
//     const [IssuediscriptionValue, setIssuediscriptionValue]=useState('')
//     const [IssuediscriptionErrorMessage, setIssuediscriptionErrorMessage]=useState('')
//     const [IssueTypeOptions, setIssueTypeOptions] = useState<OptionType[]>([]);
//     const [selectedIssueTypeId, setSelectedIssueTypeId] = useState<string | null>('');
//     const [IssueTypeError, setIssueTypeError]=useState(false)
//     const [IssueTypeErrorMessage, setIssueTypeErrorMessage]=useState('')

//     type OptionType = {
//         name: string;
//         value: string;
//     };
//     const filterOptions = createFilterOptions<OptionType>({
//         matchFrom: 'start',
//         stringify: (option) => option.name,
//     });


//     // To render handle close function to close dialouge box
//     const handleClose = (event: React.MouseEvent) => {
//         if (handleAddTicketClose && typeof handleAddTicketClose === 'function') {
//             handleAddTicketClose(event);
//         }
//     };

//     // to render getTicketlistsearch function after closing dialouge box
//     const handlegetTicketlistsearch= (event: React.MouseEvent) => {
//         if (getTicketlistsearch && typeof getTicketlistsearch === 'function') {
//             getTicketlistsearch(event);
//         }
//     };

//     // Included by sanjana SOP-28 to render getTicketTypeRequestList function after closing dialouge box
//     const handlegetTicketTypeRequestList= (event: React.MouseEvent) => {
//         if (getTicketTypeRequestList && typeof getTicketTypeRequestList === 'function') {
//             getTicketTypeRequestList(event);
//         }
//     };
//     // End of SOP-28

//      //  Handle IssueType while typing IssueType and validations
//      const handleTicketTypeChange = (event:any) => {
//         setTicketTypeValue(event.target.value);
//     };

//     //  Global data from session storage
//     const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
//     const GlobalData = JSON.parse(GlobalDataFromSession);
//     //console.log("GlobalData",GlobalData)
//     let CompanyId = GlobalData.GlobalCompanyId
//     const UserId = GlobalData.GlobalUserId;


//     const GetIssueTypeList = async () => {
//     try {
//         const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getIssueTypeList`);
//         //console.log("IssueType" ,res.data.data);
//         let formattedIssueType = res.data.data.map((item:any) => {
//         const { sys_issue_type_id, sys_issue_type_name } = item;
//         return {
//             name: sys_issue_type_name,
//             value: sys_issue_type_id,
//         };
//         });
//         setIssueTypeOptions(formattedIssueType)
//     } catch (err) {
//         //console.log( "co",err);
//     }
//     };

//         //  Handle IssueType while typing IssueType and validations
//         const handleIssuediscriptionChange = (event:any) => {
//             const trimmedIssuediscriptionValue = event.target.value.trim();
//             setIssuediscriptionValue(event.target.value);
//             if( trimmedIssuediscriptionValue.length >225 ){
//                 setIssuediscriptionError(true);
//                 setIssuediscriptionErrorMessage("Max 255 characters");
//             }
//             else {
//                 setIssuediscriptionError(false);
//                 setIssuediscriptionErrorMessage("");
//             }
//         };

//         // Define a type for errors
//         type Errors = {
//             issueType?:boolean;
//             issueDiscription?:boolean;
//             ticketType?:boolean;
//         };
//         const AddRiseATicket =(event:any)=>{

//             event.preventDefault();

//             let errors: Errors = {};

//             const trimmedIssuediscriptionValue = IssuediscriptionValue.trim();
//             if( trimmedIssuediscriptionValue.length >225 ){
//                 errors.issueDiscription=true;
//                 setIssuediscriptionErrorMessage("Max 255 characters");
//             }

//             if(selectedIssueTypeId === null || selectedIssueTypeId === '' || selectedIssueTypeId === undefined ){
//                 errors.issueType=true;
//                 setIssueTypeErrorMessage("Issue Type is required")
//             }

//             if( TicketTypeValue === null || TicketTypeValue === '' || TicketTypeValue === undefined ){
//                 errors.ticketType = true;
//                 setTicketTypeErrorMessage("Please select a ticket type");
//             }

//             if (Object.keys(errors).length > 0) {
//                 // If there are errors, update the state with all the errors
//                 setIssueTypeError(errors.issueType || false);
//                 setIssuediscriptionError(errors.issueDiscription || false);
//                 setTicketTypeError(errors.ticketType || false)
//                 return;
//             }

//             const RaiseATicketPayload = {
//                 SaveUpdateKey:'create',
//                 CompanyId:CompanyId,
//                 TicketingId:null,
//                 TicketingIssueTypeId:selectedIssueTypeId,
//                 TicketingIssueDiscription:IssuediscriptionValue,
//                 TicketingRaisedBy:UserId,
//                 CreatedBy:UserId,
//                 TicketType:parseInt(TicketTypeValue)
//             }

//             // console.log("RaiseATicketPayload",RaiseATicketPayload)
//             commonService
//             .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/ticketing_create_update_cancel`, RaiseATicketPayload)
//             .then((res) => {
//               if (res.status === 200) {
//                   Success("Issue Raised Successfully");
//                   handleClose(event);
//                   handlegetTicketlistsearch(event);
//                 //   Inclueded by sanjana SOP-28  to render handlegetTicketTypeRequestList function after creating a request
//                 handlegetTicketTypeRequestList(event);
//                 // End of SOP-28
//               }
//               }).catch((error) => {
//                   console.error("Error:", error);
//               });
//         }
    

//         useEffect(() => {
//             GetIssueTypeList();
//         }, []);
        
    

//     return(
//         <Box>
//             <Box>

//             {/* SOP-45 - TS -Alignment Issue- start */}
//                 {/* Home Page - Raise a Ticket - Popup */}
//                 {/* <Box display={'flex'} justifyContent={'center'}> */}
//                 <Box display={'flex'} >
//             {/* SOP-45 - TS - end */}
                    
//                     <Box bgcolor={'white'} borderRadius={3}
//                     sx={{
//                         width:{
//                             lg:900,
//                             md:600,
//                             xs:700
        
//                         }}}
//                     >
//                     <Box p={3}>
//                         <Typography variant='h1'
//                         sx={{
//                             fontSize:{
//                                 lg:'2rem',
//                                 md:'2rem',
//                                 xs:'1.5rem'
//                             }
//                         }}
//                         >
//                         Raise A Ticket
//                         </Typography>
//                     </Box>
//                         <Box p={3}>
//                             <form  noValidate>
//                                 <Box>
//                                     <Grid container spacing={2} display={'flex'} justifyContent={'space-evenly'}>

//                                         <Grid item lg={12} md={12} xs={12}>
//                                         <FormControl>
//                                         <FormLabel id="demo-controlled-radio-buttons-group">Ticket Type</FormLabel>
//                                         <RadioGroup
//                                             aria-labelledby="demo-controlled-radio-buttons-group"
//                                             name="controlled-radio-buttons-group"
//                                             value={TicketTypeValue}
//                                             onChange={handleTicketTypeChange}
//                                         >
//                                             <Box display={'flex'}>
//                                             <FormControlLabel value="1" control={<Radio />} label="Incident" />
//                                             <FormControlLabel value="2" control={<Radio />} label="Request" />
//                                             </Box>

//                                         </RadioGroup>
//                                         {TicketTypeError && <FormHelperText sx={{
//                                             color:'red',
//                                         }}>{TicketTypeErrorMessage}</FormHelperText>}
//                                         </FormControl>
//                                         </Grid>

//                                         <Grid item lg={12} md={12} xs={12} >
//                                         <Autocomplete
//                                                 id="combo-box-demo"
//                                                 options={IssueTypeOptions as OptionType[]}
//                                                 filterOptions={filterOptions}
//                                                 getOptionLabel={(option: unknown) => (option as OptionType).name}
//                                                 value={IssueTypeOptions.find((option: OptionType) => option.value === selectedIssueTypeId) || null}
//                                                 onChange={(event, newValue) => {
//                                                     if (newValue) {
//                                                         setSelectedIssueTypeId(newValue.value);
//                                                         setIssueTypeError(false);
//                                                         setIssueTypeErrorMessage("");
//                                                     } else {
//                                                         setSelectedIssueTypeId('');
//                                                         setIssueTypeError(true);
//                                                         setIssueTypeErrorMessage("Issue Type is required");
//                                                     }
//                                                 }}
//                                                 // sx={{ width: 235, ml: 1 }}
//                                                 renderInput={(params) =>
//                                                 <TextField {...params}
//                                                 error={IssueTypeError}
//                                                 // Included by sanjana SOP-28 to set auto focuss for 1st field
//                                                 autoFocus
//                                                 // End of SOP-28
//                                                 helperText={IssueTypeErrorMessage}
//                                                 required label="Issue Type"
//                                                 variant="standard" />}
//                                             />
//                                         </Grid>

//                                         <Grid item mt={2} lg={12} md={12} xs={12} >
//                                             {/* <TextField
//                                                 type='text'
//                                                 variant="outlined"
//                                                 label='Issue Description'
//                                                 name='Issuediscription'
//                                                 // sx={{ margin: '0 10px' }}
//                                                 fullWidth
//                                                 multiline
//                                                 error={IssuediscriptionError}
//                                                 onChange={handleIssuediscriptionChange}
//                                                 value={IssuediscriptionValue}
//                                                 helperText={IssuediscriptionErrorMessage}
//                                             /> */}
//                                             <TextField
//                                             id="outlined-basic"
//                                             label="Issue Description"
//                                             variant="outlined"
//                                             name='Issuediscription'
//                                             fullWidth
//                                             multiline
//                                             rows={4}
//                                             error={IssuediscriptionError}
//                                             onChange={handleIssuediscriptionChange}
//                                             value={IssuediscriptionValue}
//                                             helperText={IssuediscriptionErrorMessage}
//                                             />
//                                         </Grid>
//                                     </Grid>
                                    

//                                 </Box>
//                             <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
//                                 <Button
//                                     sx={{
//                                         background: 'white',
//                                         border: 'none',
//                                         borderRadius: '5px',
//                                         padding:{
//                                             lg: "0 70px",
//                                             md: "0 70",
//                                             xs:'0 50px'
                
//                                         },
//                                         // color: {accent},
//                                         height: "3rem",
//                                         boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
//                                     }}

//                                     onClick={handleClose}
//                                 >
//                                     Back
//                                 </Button>

//                                 <Button
//                                     sx={{
//                                         background: '#25425F',
//                                         border: 'none',
//                                         borderRadius: '5px',
//                                         "&:hover":{
//                                             backgroundColor:'#25425F',
//                                             color:'white',
//                                         },
//                                         padding:{
//                                             lg: "0 70px",
//                                             md: "0 70",
//                                             xs:'0 50px'
                
//                                         },
//                                         color: 'white',
//                                         height: "3rem",
//                                         boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
//                                     }}
//                                     type='submit'
//                                     onClick={(event)=>{
//                                         AddRiseATicket(event);
//                                     }}
//                                 >
//                                     Save
//                                 </Button>
//                                 </Box>
//                             </form>
//                         </Box>

//                     </Box>
//                 </Box>
//             </Box>
//         </Box>
//     )

// }

// export default RaiseATicketPage;

// // End of SOP-26


// commented by TS - SOP-49 - end







// SOP-49 - TS - start
// Asset Management-Ticketing-Create Page

import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    Grid,
    FormHelperText,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { Dayjs } from 'dayjs';

const RaiseATicketPage = () => {
        
    const navigate = useNavigate();

    // useState for ticketDate
    const [ticketDateValue, setTicketDateValue] = useState<Dayjs | null>(dayjs());
    console.log("ticketDateValue",ticketDateValue)

    // useState for Issuediscription
    const [IssuediscriptionError, setIssuediscriptionError]=useState(false)
    const [IssuediscriptionValue, setIssuediscriptionValue]=useState('')
    const [IssuediscriptionErrorMessage, setIssuediscriptionErrorMessage] = useState('')
     
    // useState for Categories
    const [IssueTypeOptions, setIssueTypeOptions] = useState<OptionType[]>([]);
    const [selectedIssueTypeId, setSelectedIssueTypeId] = useState<string | null>('');   
    const [IssueTypeError, setIssueTypeError]=useState(false)
    const [IssueTypeErrorMessage, setIssueTypeErrorMessage] = useState('')

    // useState for Sub Categories
    const [IssueSubTypeOptions, setIssueSubTypeOptions] = useState<OptionType[]>([]);
    const [selectedIssueSubTypeId, setSelectedIssueSubTypeId] = useState<string | null>('');
    const [IssueTypeSubError, setIssueTypeSubError]=useState(false)
    const [IssueTypeSubErrorMessage, setIssueTypeSubErrorMessage]=useState('')
    
    // useState for Contact
    const [ContactTypeOptions, setContactTypeOptions] = useState<OptionType[]>([]);
    const [selectedContactTypeId, setSelectedContactTypeId] = useState<string | null>('');
    const [ContactTypeError, setContactTypeError]=useState(false)
    const [ContactTypeErrorMessage, setContactTypeErrorMessage]=useState('')
      
    // useState for Priority
    const [PriorityTypeOptions, setPriorityTypeOptions] = useState<OptionType[]>([]);
    const [selectedPriorityTypeId, setSelectedPriorityTypeId] = useState<string | null>('');
    const [PriorityTypeError, setPriorityTypeError]=useState(false)
    const [PriorityTypeErrorMessage, setPriorityTypeErrorMessage] = useState('')
     
    // useState for Employee
    const [EmployeeOptions, setEmployeeOptions] = useState<OptionType[]>([]);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | null>('');
    const [EmployeeError, setEmployeeError]=useState(false)
    const [EmployeeErrorMessage, setEmployeeErrorMessage] = useState('')
    
    // useState for Impact
    const [ImpactOptions, setImpactOptions] = useState<OptionType[]>([]);
    const [selectedImpactId, setSelectedImpactId] = useState<string | null>('');
    const [ImpactTypeError, setImpactTypeError]=useState(false)
    const [ImpactTypeErrorMessage, setImpactTypeErrorMessage] = useState('')

    // useState for ShortDiscription
    const [ShortDiscriptionError, setShortDiscriptionError]=useState(false)
    const [ShortDiscriptionValue, setShortDiscriptionValue]=useState('')
    const [ShortDiscriptionErrorMessage, setShortDiscriptionErrorMessage] = useState('')
     
    // useState for Discription
    const [DiscriptionError, setDiscriptionError]=useState(false)
    const [DiscriptionValue, setDiscriptionValue] = useState('')
    const [DiscriptionErrorMessage, setDiscriptionErrorMessage] = useState('') 
    
    // useState for Location
    const [LocationError, setLocationError]=useState(false)
    const [LocationValue, setLocationValue] = useState('')
    const [LocationErrorMessage, setLocationErrorMessage] = useState('') 
    
    // useState for TicketDueDate
    const [TicketDueDateError, setTicketDueDateError]=useState(false)
    const [TicketDueDateValue, setTicketDueDateValue] = useState<dayjs.Dayjs | null>(null);
    const [TicketDueDateErrorMessage, setTicketDueDateErrorMessage] = useState('')
    
    // useState for Attachment
    const [attachment, setAttachment] = useState<File | null>(null);
    const [attachmentBase64, setAttachmentBase64] = useState<string | null>(null);
        
        // console.log("selectedIssueTypeId", selectedIssueTypeId)
        // console.log("selectedIssueSubTypeId", selectedIssueSubTypeId)
        // console.log("IssueSubTypeOptions",IssueSubTypeOptions)
          
    type OptionType = {
        name: string;
        value: string;
    };
    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });
        
    //  Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    //console.log("GlobalData",GlobalData)
    const CompanyId = GlobalData.GlobalCompanyId;
    const UserId = GlobalData.GlobalUserId;
    const UserName = GlobalData.GlobalUserName;

    console.log("UserId",UserId)

    //IssueType List
    const GetIssueTypeList = async () => { 
    try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getIssueTypeList`);
        //console.log("IssueType" ,res.data.data);
        let formattedIssueType = res.data.data.map((item:any) => {
        const { sys_issue_type_id, sys_issue_type_name } = item;
        return {
            name: sys_issue_type_name,
            value: sys_issue_type_id,
        };
        });
        setIssueTypeOptions(formattedIssueType)
    } catch (err) {
        //console.log( "co",err);
    }
    };

    // IssueSubType List
    const GetIssueSubTypeList = async (sys_issue_type_id: string) => { 
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getIssueSubTypeList`, { issuetypeid: sys_issue_type_id });
        //console.log("IssueSubType" ,res.data.data);
        let formattedIssueSubType = res.data.data.map((item:any) => {
        const { sys_issue_subtype_id, sys_issue_subtype_name } = item;
        return {
            name: sys_issue_subtype_name,
            value: sys_issue_subtype_id,
        };
        });
        setIssueSubTypeOptions(formattedIssueSubType)
    } catch (err) {
        //console.log( "co",err);
    }
    };

    // ContactType List
    const GetContactTypeList = async () => { 
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getContactTypeList`);
            //console.log("ContactType" ,res.data.data);
            let formattedContactType = res.data.data.map((item:any) => {
            const { sys_contact_type_id, sys_contact_type_name } = item;
            return {
                name: sys_contact_type_name,
                value: sys_contact_type_id,
            };
            });
            setContactTypeOptions(formattedContactType)
        } catch (err) {
            //console.log( "co",err);
        }
    };
        
    // PriorityType List
    const GetPriorityTypeList = async () => { 
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getPriorityList`);
            //console.log("PriorityType" ,res.data.data);
            let formattedPriorityType = res.data.data.map((item:any) => {
            const { sys_priority_id, sys_priority_name } = item;
            return {
                name: sys_priority_name,
                value: sys_priority_id,
            };
            });
            setPriorityTypeOptions(formattedPriorityType)
        } catch (err) {
            //console.log( "co",err);
        }
    };

    // Employee List
    const GetEmployeeList = async () => {
        const EmployeeListPayload = {
            CompanyId: CompanyId,
            active: "Y"
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getEmployeeList`, EmployeeListPayload);
            // console.log("formattedEmployeeList",res)
            let formattedEmployeeList = res.data.data.map((item: any) => {
             
                const { employee_id, employee_name } = item;
                return {
                    name: employee_name,
                    value: employee_id,
                };
            });
            setEmployeeOptions(formattedEmployeeList);

            //getting employee drop dwon based on username
            const currentUser = formattedEmployeeList.find((emp: OptionType) => emp.name === UserName);
            // console.log("currentUser",currentUser)
            if (currentUser) {
                // setSelectedEmployeeId(currentUser.name);
                setSelectedEmployeeId(currentUser.value);
            }
        } catch (err) {
            console.error(err);
        }
    };

    // Impact List
    const GetImpactList = async () => { 
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getImpactList`);
            //console.log("IssueType" ,res.data.data);
            let formattedPriorityType = res.data.data.map((item:any) => {
            const { sys_impact_id, sys_impact_name } = item;
            return {
                name: sys_impact_name,
                value: sys_impact_id,
            };
            });
            setImpactOptions(formattedPriorityType)
        } catch (err) {
            //console.log( "co",err);
        }
    };

        //  Handle IssueType while typing IssueType and validations
        const handleIssuediscriptionChange = (event:any) => {
            const trimmedIssuediscriptionValue = event.target.value.trim();
            setIssuediscriptionValue(event.target.value);
            if( trimmedIssuediscriptionValue.length >225 ){
                setIssuediscriptionError(true);
                setIssuediscriptionErrorMessage("Max 255 characters");
            }
            else {
                setIssuediscriptionError(false);
                setIssuediscriptionErrorMessage("");
            }
        };

    // To handle ShortDiscription validation
    const handleShortDiscriptionChange =( event:any)=>{
        const trimmedShortDiscriptionValue = event.target.value.trim();
            setShortDiscriptionValue(event.target.value);
            if (trimmedShortDiscriptionValue === '') {
                setShortDiscriptionError(true);
                setShortDiscriptionErrorMessage("Short Discription is required");
            } 
            else if( trimmedShortDiscriptionValue.length >225 ){
                setShortDiscriptionError(true);
                setShortDiscriptionErrorMessage("Max 255 characters");
            }
            else {
                setShortDiscriptionError(false);
                setShortDiscriptionErrorMessage("");
            }
        }
    
    // To handle DiscriptionChange validation
    const handleDiscriptionChange =( event:any)=>{
        const trimmedDiscriptionValue = event.target.value.trim();
        setDiscriptionValue(event.target.value);
            if( trimmedDiscriptionValue.length >1000 ){
            setDiscriptionError(true);
            setDiscriptionErrorMessage("Max 1000 characters");
        }
        else {
            setDiscriptionError(false);
            setDiscriptionErrorMessage("");
        }
    }

    // To handle LocationChange validation
    const handleLocationChange =( event:any)=>{
        const trimmedLocationValue = event.target.value.trim();
        setLocationValue(event.target.value);
            if( trimmedLocationValue.length >225 ){
            setLocationError(true);
            setLocationErrorMessage("Max 255 characters");
        }
        else {
            setLocationError(false);
            setLocationErrorMessage("");
        }
    }

    // To handle TicketDueDateChange validation
    const handleTicketDueDateChange =(date:any)=>{
        setTicketDueDateValue(date);
            if (!date) {
                setTicketDueDateError(true)
                setTicketDueDateErrorMessage("Due Date is required");
            }
            else {
                setTicketDueDateError(false);
                setTicketDueDateErrorMessage("")
            }
    }
       
    // To handle AttachmentChange
    const handleAttachmentChange = (event: any) => {
        const file = event.target.files[0];
        setAttachment(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setAttachmentBase64(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

        // Define a type for errors
        type Errors = {
            issueType?:boolean;
            issueDiscription?: boolean;
            issueSubType?: boolean;
            constactType?: boolean;
            priorityType?: boolean;
            employee?: boolean;
            impact?: boolean;
            shortDiscription?: boolean;
            discription?: boolean;
            location?: boolean;
            ticketDueDate?: boolean;
        };

        const AddRiseATicket =(event:any)=>{
            event.preventDefault();

            console.log('AddRiseATicket called')

            let errors: Errors = {};

            // Location Error
            const trimmedLocationValue = LocationValue.trim();
            if( trimmedLocationValue.length >225 ){
               errors.discription=true
               setLocationErrorMessage("Max 255 characters");
            }

            // Discription Error
            const trimmedDiscriptionValue = DiscriptionValue.trim();
            if( trimmedDiscriptionValue.length >225 ){
               errors.discription=true
               setDiscriptionErrorMessage("Max 255 characters");
            }
            
            // Short Discription Error
            const trimmedshortdiscriptionValue = ShortDiscriptionValue.trim();
            if (trimmedshortdiscriptionValue === '') {
                errors.shortDiscription = true
                setShortDiscriptionError(true);
                setShortDiscriptionErrorMessage("Short Discription is required");
            } 
            else if( trimmedshortdiscriptionValue.length >225 ){
                errors.shortDiscription=true
                setShortDiscriptionError(true);
                setShortDiscriptionErrorMessage("Max 255 characters");
            }

            // Issue Discription Error
            const trimmedIssuediscriptionValue = IssuediscriptionValue.trim();
            if( trimmedIssuediscriptionValue.length >225 ){
                errors.issueDiscription=true;
                setIssuediscriptionErrorMessage("Max 255 characters");
            }

            // TicketDueDate Error
        // SOP-78 Commented By PCS [remove Ticket Due date]
            // let trimmedTicketDueDateValue;
            // if (TicketDueDateValue === null || TicketDueDateValue === undefined) {
            //     errors.ticketDueDate = true;
            //     setTicketDueDateErrorMessage("Due Date is required");
            // }
            // else{
            //     const dateString = TicketDueDateValue.format(); // Assuming your `Dayjs` object has a `format()` method
            //     trimmedTicketDueDateValue = dateString.trim();
                
            //     if (trimmedTicketDueDateValue === "") {
            //         errors.ticketDueDate = true;
            //         setTicketDueDateErrorMessage("Due Date is required");
            //     } 
            //     else if(trimmedTicketDueDateValue.length < 25){
            //         errors.ticketDueDate = true;
            //         setTicketDueDateErrorMessage("Please check Due Date");
            //     }
            //     else {
            //         setTicketDueDateErrorMessage("");
            //     }
            // }
        //EOF SOP-78 Commented By PCS [remove Ticket Due date]
        //SOP-78 Commented By PCS [remove Ticket Due date]
            // Formated TicketDueDate
            // let FormatedTicketDueDate;
            // if (trimmedTicketDueDateValue) {
            //     const trimmedDate = new Date(trimmedTicketDueDateValue);
            //     const year = trimmedDate.getFullYear();
            //     const month = String(trimmedDate.getMonth() + 1).padStart(2, '0');
            //     const date = String(trimmedDate.getDate()).padStart(2, '0');
            //     FormatedTicketDueDate = `${year}-${month}-${date}`;
            // }
        //EOF SOP-78 Commented By PCS [remove Ticket Due date]
    
            if(selectedEmployeeId === null || selectedEmployeeId === '' || selectedEmployeeId === undefined ){
                errors.employee=true;
                setEmployeeErrorMessage("Employee is required")
            }
            if(selectedIssueTypeId === null || selectedIssueTypeId === '' || selectedIssueTypeId === undefined ){
                errors.issueType=true;
                setIssueTypeErrorMessage("Category is required")
            }
            if( selectedIssueSubTypeId === null || selectedIssueSubTypeId === '' || selectedIssueSubTypeId === undefined ){
                errors.issueSubType = true;
                setIssueTypeSubErrorMessage("Sub Category is required");
            }
            if( selectedContactTypeId === null || selectedContactTypeId === '' || selectedContactTypeId === undefined ){
                errors.constactType = true;
                setContactTypeErrorMessage("Contact Type is required");
            }
            if( selectedPriorityTypeId === null || selectedPriorityTypeId === '' || selectedPriorityTypeId === undefined ){
                errors.priorityType = true;
                setPriorityTypeErrorMessage("Priority is required");
            }
            if( selectedImpactId === null || selectedImpactId === '' || selectedImpactId === undefined ){
                errors.impact = true;
                setImpactTypeErrorMessage("Impact is required");
            }
             if (Object.keys(errors).length > 0) {
                // If there are errors, update the state with all the errors
                setIssueTypeError(errors.issueType || false);
                setIssuediscriptionError(errors.issueDiscription || false);
                setIssueTypeSubError(errors.issueSubType || false);
                setContactTypeError(errors.constactType || false);
                setPriorityTypeError(errors.priorityType || false);
                setDiscriptionError(errors.discription || false);
                setLocationError(errors.location || false);
                // setTicketDueDateError(errors.ticketDueDate || false);//SOP-78 Commented By PCS [remove Ticket Due date]
                setImpactTypeError(errors.impact || false);
                setEmployeeError(errors.employee || false);
            return;
            }

            // RaiseATicket Payload
            const RaiseATicketPayload = {
                SaveUpdateKey:'create',
                CompanyId:CompanyId,
                TicketingId:null,
                TicketingIssueTypeId: selectedIssueTypeId,
                
                //SOP-55 - TS modified TicketingIssueDiscription - start
                // Asset Management-Ticketing-View and Edit Page
                    // TicketingIssueDiscription:IssuediscriptionValue,
                    TicketingIssueDiscription: DiscriptionValue,
                //SOP-55 - TS modified TicketingIssueDiscription - end
                    
                TicketingRaisedBy:UserId,
                CreatedBy:UserId,
                ContactType:selectedContactTypeId,
                //SOP-78 Modified By PCS [remove Ticket Due date]
                // TicketDueDate:FormatedTicketDueDate,
                TicketDueDate:null,
                 //EOF SOP-78 Modified By PCS [remove Ticket Due date]
                Employee:selectedEmployeeId,
                Impact:selectedImpactId,
                TicketingIssueSubTypeId:selectedIssueSubTypeId,
                Priority:selectedPriorityTypeId,
                TicketingIssueShortDiscription: ShortDiscriptionValue,
                Attachment: attachmentBase64,
                location:LocationValue,
            }
             console.log("RaiseATicketPayload",RaiseATicketPayload)
            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/ticketing_create_update_cancel`, RaiseATicketPayload)
            .then((res) => {
              if (res.status === 200) {
                  Success("Issue Raised Successfully");                 
                    navigate('/home')
              }
              }).catch((error) => {
                  console.error("Error:", error);
              });
        }
    
        useEffect(() => {
            GetIssueTypeList();
            GetContactTypeList();
            GetPriorityTypeList();
            GetEmployeeList();
            GetImpactList();

            setSelectedEmployeeId(UserId);  //binding  value for Employee
        }, []); 

        useEffect(() => {
            if (selectedIssueTypeId) {
                GetIssueSubTypeList(selectedIssueTypeId);
            }
        }, [selectedIssueTypeId]);
    
    return(
        <Box> 
            <Box>          
                <Box display={'flex'} >
                    <Box bgcolor={'white'} borderRadius={3}
                        sx={{
                            width:{
                                lg:2000,
                                md:850,
                                xs:500
                            }}}
                    >
                    <Box p={3}>
                        <Typography variant='h1'
                            sx={{
                                fontSize:{
                                    lg:'2rem',
                                    md:'2rem',
                                    xs:'1.5rem'
                                }
                            }}
                        >
                            Raise A Ticket
                        </Typography>
                    </Box>
                        <Box p={3}>
                            <form  noValidate>
                                <Box>
                                    <Grid container spacing={3.5} mb={4} display={'flex'} justifyContent={'space-evenly'}>
                                        <Grid item lg={4} md={12} xs={12}>
                                                <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Number'
                                                    name='Number'
                                                    fullWidth
                                                    disabled
                                                /> 
                                        </Grid>
                                        <Grid item lg={4} md={12} xs={12} >
                                            <FormControl fullWidth>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            label="Ticket Date"
                                                            value={ticketDateValue}
                                                            onChange={(newValue: Dayjs | null) => {
                                                            console.log("New date value:", newValue); 
                                                            setTicketDateValue(newValue);
                                                        }}
                                                            format="DD-MM-YYYY"
                                                            disabled
                                                            slotProps={{
                                                                textField: {
                                                                }
                                                            }}
                                                        />                                                        
                                                        <FormHelperText
                                                            error
                                                            id="standard-weight-helper-text-dob-login"
                                                        >
                                                        </FormHelperText>
                                                </LocalizationProvider>
                                            </FormControl> 
                                        </Grid>
                                        <Grid item lg={4} md={12} xs={12} >
                                            <Box>
                                                {/* //SOP-78 Commented By PCS [to remove Ticket Due date field] */}
                                                {/* <FormControl
                                                    fullWidth
                                                >
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            label="Ticket Due Date"
                                                            value={TicketDueDateValue ? dayjs(TicketDueDateValue) : null}
                                                            onChange={handleTicketDueDateChange}
                                                            format="DD-MM-YYYY"
                                                            minDate={dayjs()} // Disable past dates
                                                            autoFocus
                                                            slotProps={{
                                                                textField: {
                                                                    error: Boolean(TicketDueDateError),
                                                                    required: true
                                                                }
                                                            }}
                                                        />                                                        
                                                        {TicketDueDateError && (
                                                            <FormHelperText
                                                                error
                                                                id="standard-weight-helper-text-dob-login"
                                                            >
                                                                {TicketDueDateErrorMessage}
                                                            </FormHelperText>
                                                        )}      
                                                    </LocalizationProvider>
                                                </FormControl>    */}
                                                 {/* //EOF SOP-78 Commented By PCS [to remove Ticket Due date field] */} 
                                            </Box> 
                                        </Grid>
                                    </Grid>

                                <Grid container spacing={3.5} mb={4} display={'flex'} justifyContent={'space-evenly'}>
                                    <Grid item lg={4} md={12} xs={12} >
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={ContactTypeOptions as OptionType[]}
                                                    filterOptions={filterOptions}
                                                    getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                    value={ContactTypeOptions.find((option: OptionType) => option.value === selectedContactTypeId) || null}
                                                    onChange={(event, newValue) => {
                                                        if (newValue) {
                                                            setSelectedContactTypeId(newValue.value);
                                                            setContactTypeError(false);
                                                            setContactTypeErrorMessage("");
                                                        } else {
                                                            setSelectedContactTypeId('');
                                                            setContactTypeError(true);
                                                            setContactTypeErrorMessage("Contact is required");
                                                        }
                                                    }}
                                                    renderInput={(params) => 
                                                    <TextField {...params}
                                                        error={ContactTypeError}
                                                       
                                                        helperText={ContactTypeErrorMessage}
                                                        required label="Contact Type" 
                                                        variant="standard"
                                                    />}
                                                />
                                        </Grid>
                                        <Grid item lg={4} md={12} xs={12}>
                                        <Autocomplete
                                                id="combo-box-demo"
                                                options={EmployeeOptions as OptionType[]}
                                                filterOptions={filterOptions}
                                                getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                value={EmployeeOptions.find((option: OptionType) => option.value === selectedEmployeeId) || null}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        setSelectedEmployeeId(newValue.value);
                                                        setEmployeeError(false);
                                                        setEmployeeErrorMessage("");
                                                    }
                                                    else {
                                                        setSelectedEmployeeId('');
                                                        setEmployeeError(true);
                                                        setEmployeeErrorMessage("Employee is required");
                                                    }
                                                }}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        error={EmployeeError}
                                                        helperText={EmployeeErrorMessage}
                                                        required label="Employee"
                                                        variant="standard"
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item lg={4} md={12} xs={12}>
                                        <Autocomplete
                                                id="combo-box-demo"
                                                options={ImpactOptions as OptionType[]}
                                                filterOptions={filterOptions}
                                                getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                value={ImpactOptions.find((option: OptionType) => option.value === selectedImpactId) || null}
                                                onChange={(_event, newValue) => {
                                                    if (newValue) {
                                                        setSelectedImpactId(newValue.value);
                                                        setImpactTypeError(false);
                                                        setImpactTypeErrorMessage("");
                                                    }
                                                    else {
                                                        setSelectedImpactId("");
                                                        setImpactTypeError(true);
                                                        setImpactTypeErrorMessage("Impact is required");
                                                    }
                                                }}
                                                renderInput={(params) => 
                                                    <TextField {...params}
                                                        error={ImpactTypeError}
                                                        helperText={ImpactTypeErrorMessage}
                                                        required label="Impact" 
                                                        variant="standard"
                                                    />}
                                                />
                                            </Grid>
                                    </Grid>

                                    <Grid container spacing={3.5} mb={4}display={'flex'} justifyContent={'space-evenly'}>
                                        <Grid item lg={4} md={12} xs={12} >
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={PriorityTypeOptions as OptionType[]}
                                                filterOptions={filterOptions}
                                                getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                value={PriorityTypeOptions.find((option: OptionType) => option.value === selectedPriorityTypeId) || null}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        setSelectedPriorityTypeId(newValue.value);
                                                        setPriorityTypeError(false);
                                                        setPriorityTypeErrorMessage("");
                                                    } else {
                                                        setSelectedPriorityTypeId('');
                                                        setPriorityTypeError(true);
                                                        setPriorityTypeErrorMessage("Priority is required");
                                                    }
                                                }}
                                                renderInput={(params) => 
                                                <TextField {...params}
                                                    error={PriorityTypeError}
                                                    helperText={PriorityTypeErrorMessage}
                                                    required label="Priority" 
                                                    variant="standard"
                                                />}
                                            />
                                        </Grid>
                                        <Grid item lg={4} md={12} xs={12} >
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={IssueTypeOptions as OptionType[]}
                                                filterOptions={filterOptions}
                                                getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                value={IssueTypeOptions.find((option: OptionType) => option.value === selectedIssueTypeId) || null}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        setSelectedIssueTypeId(newValue.value);
                                                        setIssueTypeError(false);
                                                        setIssueTypeErrorMessage("");
                                                    } else {
                                                        setSelectedIssueTypeId('');
                                                        setIssueTypeError(true);
                                                        setIssueTypeErrorMessage("Category is required");
                                                    }
                                                }}
                                                renderInput={(params) => 
                                                <TextField {...params}
                                                    error={IssueTypeError}
                                                    helperText={IssueTypeErrorMessage}
                                                    required label="Category" 
                                                    variant="standard"
                                                />}
                                            />
                                        </Grid>
                                        <Grid item lg={4} md={12} xs={12} >                                           
                                            <Autocomplete
                                                id='Sub Category'
                                                options={IssueSubTypeOptions}
                                                filterOptions={filterOptions}
                                                getOptionLabel={(option) => option.name}
                                                value={IssueSubTypeOptions.find(option => option.value === selectedIssueSubTypeId) || null}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        setSelectedIssueSubTypeId(newValue.value);
                                                        setIssueTypeSubError(false);
                                                        setIssueTypeSubErrorMessage("");
                                                    } else {
                                                        setSelectedIssueSubTypeId("");
                                                        setIssueTypeSubError(true);
                                                        setIssueTypeSubErrorMessage("Sub Category is required");
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        error={IssueTypeSubError}
                                                        helperText={IssueTypeSubErrorMessage}
                                                        required
                                                        label="Sub Category"
                                                        variant="standard"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item mt={2} lg={12} md={12} xs={12} >
                                        <TextField 
                                            id="outlined-basic" 
                                            label="Short Description" 
                                            variant="outlined" 
                                            name='ShortDescription'
                                            required
                                            fullWidth
                                            multiline
                                            rows={3}
                                            error={ShortDiscriptionError}
                                            onChange={handleShortDiscriptionChange}
                                            value={ShortDiscriptionValue}
                                            helperText={ShortDiscriptionErrorMessage}
                                        />
                                    </Grid>
                                    <Grid item mt={2} lg={12} md={12} xs={12} >
                                        <TextField 
                                            id="outlined-basic" 
                                            label="Description" 
                                            variant="outlined" 
                                            name='Description'
                                            fullWidth
                                            multiline
                                            rows={10}
                                            error={DiscriptionError}
                                            onChange={handleDiscriptionChange}
                                            value={DiscriptionValue}
                                            helperText={DiscriptionErrorMessage}
                                        />
                                    </Grid>
                                    <Grid container spacing={3.5} mb={4}display={'flex'} justifyContent={'space-evenly'}>
                                        <Grid item mt={2} lg={4} md={12} xs={12} >
                                            <TextField 
                                                label="Location" 
                                                variant="standard" 
                                                name='Issuediscription'
                                                fullWidth
                                                error={LocationError}
                                                onChange={handleLocationChange}
                                                value={LocationValue}
                                                helperText={LocationErrorMessage}
                                            />
                                        </Grid>
                                        <Grid item mt={2} lg={4} md={12} xs={12}>   </Grid>
                                        <Grid item mt={2} lg={4} md={12} xs={12}>   </Grid>
                                    </Grid>
                                    <Grid item mt={2} lg={2} md={12} xs={12}>                 
                                        <Button
                                            variant="contained"
                                            component="label"
                                            startIcon={<CloudUploadIcon />}
                                        >
                                            Upload Attachment
                                            <input
                                                type="file"
                                                hidden
                                                onChange={handleAttachmentChange}
                                            />
                                        </Button>
                                            {attachment && <Typography variant="body2" mt={1}>{attachment.name}</Typography>}
                                     </Grid>
                                </Box>
                                <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Button
                                        sx={{
                                            background: 'white',
                                            border: 'none',
                                            borderRadius: '5px',
                                            padding:{
                                                lg: "0 70px",
                                                md: "0 70",
                                                xs:'0 50px'
                    
                                            },
                                            // color: {accent},
                                            height: "3rem",
                                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                        }}
                                        onClick={()=>{
                                            navigate('/home')
                                        }} 
                                    >
                                        Back
                                    </Button>

                                    <Button
                                        sx={{
                                            background: '#25425F',
                                            border: 'none',
                                            borderRadius: '5px',
                                            "&:hover":{
                                                backgroundColor:'#25425F',
                                                color:'white',
                                            },
                                            padding:{
                                                lg: "0 70px",
                                                md: "0 70",
                                                xs:'0 50px'
                    
                                            },
                                            color: 'white',
                                            height: "3rem",
                                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                        }}
                                        type='submit'
                                        onClick={(event)=>{
                                            AddRiseATicket(event);
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </Box>
                </Box>
            </Box>        
        </Box>
    )
}
export default RaiseATicketPage;

// // SOP-49 - TS - end
// Asset Management-Ticketing-Create Page