// Included by sanjana SOP-44 to create forgot password dialogue box content
//SOP-32 - Ts - start
//User Management - Forget Password
import { useState, useRef, useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Success } from '../../atic-common-helpers/helpers/toast.helper';
import { commonService } from '../../atic-common-helpers/helpers/common.service';

   const ForgotPassword = ({ handleForgotPasswordClose }: { handleForgotPasswordClose: () => void }) => {

    // use navigate declaration
    const navigate = useNavigate();

    const [EmailError, setEmailError]=useState(false)
    const [EmailValue, setEmailValue]=useState('')
    const [EmailErrorMessage, setEmailErrorMessage] = useState('')  
    
    //close for forgot password pop   
    const handleClose = () => {

        if (handleForgotPasswordClose && typeof handleForgotPasswordClose === 'function') {
            handleForgotPasswordClose(); // Call the provided function
            //console.log("handleSignUpClose && typeof handleSignUpClose === 'function'",handleSignUpClose && typeof handleSignUpClose === 'function')
        }
    };

    // Handle email while typing data
    const handleEmailChange = (event:any) => {
        const trimmedEmailValue = event.target.value.trim();
        setEmailValue(event.target.value);
        if (trimmedEmailValue === '') {
            setEmailError(true);
            setEmailErrorMessage("Email is required");
        } 
        else if( trimmedEmailValue.length >255 ){
            setEmailError(true);
            setEmailErrorMessage("Max 255 characters");
        }
        else if( !(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(trimmedEmailValue) ){
            setEmailError(true);
            setEmailErrorMessage("Invalid Email");
        }
        else {
            setEmailError(false);
            setEmailErrorMessage("");
        }
      };

    // To get company user details API as per the email 
    const ToGetCompanyUserDetails = async ()=>{

    const  CompanyUserDetailsPayload = {
        UserName:EmailValue,
        Active:"Y"
    }
    // console.log("CompanyUserDetailsPayload",CompanyUserDetailsPayload)
    try {
    const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/company/getCompanyUserDetails`,CompanyUserDetailsPayload);
    // console.log("res",res.data.data);
    return res.data.data[0] || [];
    } catch (err) {
    console.log( "co",err); 
    } 
    }

    // Login button function 
    const handleSubmit = async (event :any)=>{
        event.preventDefault();

        let errors: any = {};

        const CompanyDetails = await ToGetCompanyUserDetails();
        // console.log("CompanyDetails",CompanyDetails)

        if(CompanyDetails.length === 0){
            errors.email = true;
            setEmailErrorMessage("User dose not exist");
        }

        const trimmedEmailValue = EmailValue.trim();
        if (trimmedEmailValue === '') {
            errors.email = true;
            setEmailErrorMessage("Email is required");
        }
        else if( !(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(trimmedEmailValue) ){
            errors.email = true;
            setEmailErrorMessage("Invalid Email");
        }
        else if( trimmedEmailValue.length >255 ){
            errors.email = true;
            setEmailErrorMessage("Max 255 characters");
        }

        if (Object.keys(errors).length > 0) {
            setEmailError(errors.email || false);
            return;
        }
        
        const UpdatePasswordPayload = {           
            registeredemail:EmailValue,
        }
    
        console.log("UpdatePasswordPayload", UpdatePasswordPayload);
        Success("Reset link has been sent to your registered email id");
        handleClose();
      commonService
      .postService(`${process.env.REACT_APP_BACKEND_URL}/company/ForgotPasswordEmail`, UpdatePasswordPayload)
    }

    return(
        <Box p={3}>
            <form noValidate onSubmit={handleSubmit}>
            <Box mt={2} sx={{
                ml:{
                    lg:4,
                }
            }}>
                <Box display={'flex'} alignItems={'left'} justifyContent={'left'}>
                    <Typography fontSize={'1.3rem'}>
                        Forgot Your Password ?
                    </Typography>
                </Box>
                {/* Included by sanjana SOP-NEW-44 */}
                <Box mt={1} display={'flex'} alignItems={'left'} justifyContent={'left'}>
                    <Typography fontSize={'0.8rem'} color={'gray'} >
                        Password reset link will be sent to your registered email. Please enter your email
                    </Typography>
                </Box>
                {/* End of SOP-NEW-44 */}
                <Box>
                    <TextField
                        type='text'
                        variant="standard"
                        label='Email'
                        name='Email'
                        sx={{ margin: '0 10px', 
                        width:{
                            lg:'30rem',
                            xs: '30rem',
                            marginTop:'40px',
                        } }}
                        required
                        value={EmailValue}
                        error={EmailError}
                        helperText={EmailErrorMessage}
                        onChange={handleEmailChange}
                        autoFocus  // Add autoFocus prop here
                    />                            
                </Box>
            </Box>  
            <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Button
                style={{
                    background: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    padding: "0 70px",
                    // color: {accent},
                    height: "3rem",
                    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                }}
                onClick={handleClose} 
            >
                Back
            </Button>
            <Button
            style={{
                background: '#4169e1',
                border: 'none',
                borderRadius: '5px',
                padding: "0 70px",
                color: 'white',
                height: "3rem",
                boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
            }}
            type='submit'
            >
                Submit
            </Button>     
            </Box>
            </form>
        </Box>
    )
}
export default ForgotPassword;
// End of SOP-44