// Included by sanjana SOP-56 to add hierarchy flow setp 1
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
  import { Success ,Failed } from '../../atic-common-helpers/helpers/toast.helper';
import MainContainerWrapper from '../../atic-common-helpers/layout/wrappers/MainContainerWrapper';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import Swal from 'sweetalert2';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import HierarchyDragDropGrid from './HierarchyGrid';
import HierarchyTree from './PreviewHierarchyChart';




const AddHierarchyChartPageStep2 = () =>{

    // Use navigate
    const navigate = useNavigate();

    type OptionType = {
        name: string;
        value: string;
    };
    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });

    // useState
    const [DesignationOptions, setDesignationOptions] = useState<OptionType[]>([]);
    const [selectedDesignationId, setSelectedDesignationId] = useState<string | null>('');
    const [selectedDesignationName, setSelectedDesignationName] = useState<string | null>('');
    const [DesignationError, setDesignationError]=useState(false)
    const [DesignationErrorMessage, setDesignationErrorMessage]=useState('')
    const [showHierarchyGrid, setShowHierarchyGrid] = useState(false);
    const [TopPositionDisable, setTopPositionDisable] = React.useState(false);

    const SelectedTableDataDesignationIdFromSession = sessionStorage.getItem("selectedTableDataDesignationId");
    const SelectedTableDataDesignationId = SelectedTableDataDesignationIdFromSession ? JSON.parse(SelectedTableDataDesignationIdFromSession) : [];

    const selectedTableDataDesignationNameFromSession = sessionStorage.getItem("selectedTableDataDesignationName");
    const selectedTableDataDesignationName = selectedTableDataDesignationNameFromSession ? JSON.parse(selectedTableDataDesignationNameFromSession) : [];

    //  Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    ////console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId
    let UserId = GlobalData.GlobalUserId
    ////console.log("CompanyId",CompanyId)


       const GetDesignationList = async () =>{

        const SelectedDesignations = SelectedTableDataDesignationId.map((id:any, index:any) => ({
            designationid: id,
            designationname: selectedTableDataDesignationName[index]
          }));
        
          let formattedDesignationList = SelectedDesignations.map((item: any) => ({
            name: item.designationname,
            value: item.designationid,
          }));
          
          setDesignationOptions(formattedDesignationList);

        };

        // Define a type for errors
        type Errors = {
            Designation?:boolean;
            };

        const HandleNextBtn = (event:any) =>{

            event.preventDefault();
            let errors: Errors = {};

            if (selectedDesignationId === '' || selectedDesignationId === null || selectedDesignationId === undefined) {
                errors.Designation = true;
                setDesignationErrorMessage("Designation is required")
                setShowHierarchyGrid(false)
                if (Object.keys(errors).length > 0) {
                    // If there are errors, update the state with all the errors
                    setDesignationError(errors.Designation || false);
                    return;
                }
            }
            else{
                setShowHierarchyGrid(true);
                setTopPositionDisable(true)
    
            }
        }

        const getFilteredDesignations = (selectedDesignationId: any) => {
            //console.log("selectedTableDataDesignationName", selectedTableDataDesignationName);
            //console.log("SelectedTableDataDesignationId", SelectedTableDataDesignationId);
        
            // Ensure the lengths of both arrays are the same
            if (SelectedTableDataDesignationId.length !== selectedTableDataDesignationName.length) {
                //console.error("The lengths of SelectedTableDataDesignationId and selectedTableDataDesignationName do not match.");
                return [];
            }
        
            const FilteredDesignations = SelectedTableDataDesignationId
                .map((id:any, index:any) => ({
                    id, // keep original id for comparison
                    designationid: parseInt(id),
                    content: selectedTableDataDesignationName[index],
                    reported_to_which_designationid: null,
                    companyid: CompanyId,
                    createdby: UserId,
                    top_designationid: selectedDesignationId,
                    _saveupdatekey: 'create'
                }))
                // .filter(item => item.id !== selectedDesignationId && item.content !== selectedDesignationName);
                .filter((item: { id: any; content: any; }) => item.id !== selectedDesignationId && item.content !== selectedDesignationName);


            //console.log("FilteredDesignations", FilteredDesignations);
        
            return FilteredDesignations;
        };
        
        useEffect(() => {

            GetDesignationList();
            }, []); 
             
        

    return(
        <>
            <MainContainerWrapper headingText='Create Hierarchy'>
                <Box bgcolor={'white'}   >
                    <Box>
                        <Box p={3}>
                            <Box ml={3} mb={3} >
                                <Box >
                                    <Typography fontSize={'1rem'} fontWeight={'bold'} >
                                    Select the top position of your company
                                    </Typography>
                                </Box>
                                <Box mt={1} width={700} display={'flex'} justifyContent={'space-between'} >
                                    <Box width={300} >
                                    <Autocomplete
                                    id="combo-box-demo"
                                    fullWidth
                                    disabled={TopPositionDisable}
                                    options={DesignationOptions as OptionType[]}
                                    filterOptions={filterOptions}
                                    getOptionLabel={(option: unknown) => (option as OptionType).name}
                                    value={DesignationOptions.find((option: OptionType) => option.value === selectedDesignationId) || null}
                                    onChange={(event, newValue) => {
                                    if (newValue) {
                                            setSelectedDesignationId(newValue.value);
                                            setSelectedDesignationName(newValue.name)
                                            setDesignationError(false);
                                            setDesignationErrorMessage("");
                                            sessionStorage.setItem("TopDesignation",JSON.stringify(newValue.name))
                                            //console.log("selectedDesignationName",selectedDesignationName)
                                        } else {
                                            setSelectedDesignationId('');
                                            setDesignationError(true);
                                            setDesignationErrorMessage("Designation is required");
                                        }
                                    }}
                                    // sx={{ width: 235, ml: 1 }}
                                    renderInput={(params) => 
                                    <TextField {...params}
                                    error={DesignationError}
                                    helperText={DesignationErrorMessage}
                                        required label="Designation" 
                                        variant="standard" />}
                                                />
                                    </Box>
                                    <Box mr={3} >
                                    <Button
                                            sx={{
                                                background: '#25425F',
                                                border: 'none',
                                                borderRadius: '5px',
                                                padding:{
                                                    lg: "0 70px",
                                                    md: "0 70",
                                                    xs:'0 50px'
                                                },
                                                "&:hover":{
                                                    backgroundColor:'#25425F',
                                                    color:'white',
                                                },
                                                color: 'white',
                                                height: "3rem",
                                                boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                            }}
                                            type='submit'
                                            onClick={(event)=>{
                                                HandleNextBtn(event)
                                                
                                            }}
                                        >
                                            Next 
                                        </Button>
                                    </Box>
                                </Box>


                                <Box mt={5} display={'flex'} justifyContent={'center'} >
                                    {showHierarchyGrid && 
                                    <Box> 
                                    <HierarchyDragDropGrid rows={getFilteredDesignations(selectedDesignationId)} />
                                    </Box>                                    
                                }
                                </Box>
                            </Box>
                        </Box>                        
                    </Box>

                </Box>

            <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Button
                                        sx={{
                                            background: 'white',
                                            border: 'none',
                                            borderRadius: '5px',
                                            padding:{
                                                lg: "0 70px",
                                                md: "0 70",
                                                xs:'0 50px'
                    
                                            },
                                            height: "3rem",
                                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                        }}

                                        onClick={()=>{
                                            navigate('/add_hierarchy_page_step1')
                                        }} 
                                    >
                                        Back
                                    </Button>
                    </Box>
            </MainContainerWrapper>
        </>

    )

}

export default AddHierarchyChartPageStep2;


// End of SOP-56