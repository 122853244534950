// Included by sanjana SOP-26 to create Add Ticketing Page
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import deleteAlert from '../../component/HOC/deleteAlert';
import RaiseATicketPage from './RaiseATicket';


 // include by TS SOP-55 - start (View and Edit Page)
// Asset Management-Ticketing-View and Edit Page */}
    // import EditRaiseATicketPage from './EditRaiseATicket';
    // import ViewRaiseATicketPage from './ViewRaiseATicket';
// include by TS SOP-55 - end
import Swal from 'sweetalert2';



const MyTicketsPage =()=>{

        // to declare navigate
        const navigate = useNavigate();

        // useState
        let  [order, setOrder] = React.useState<"desc" | "asc">("desc");
        let  [orderBy, setOrderBy] = React.useState("");
        let  [tableData, setTableData] = React.useState([]);
        const [page, setPage] = React.useState<number>(0);
        const [count, setCount] = useState<number>(0);
        const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
        const [AddTicketOpen, setAddTicketOpen] = React.useState(false);
        const [EditTicketOpen, setEditTicketOpen] = React.useState(false);
        const [EditTicketId, setEditTicketId]= useState('')
        const [ViewTicketOpen, setViewTicketOpen] = React.useState(false);
        const [ViewTicketId, setViewTicketId]= useState('')
        
        // To set action type for edit ad delete
        let actionType;
      
        
        //  To open add dialouge box
       const handleAddTicketOpen = () => {
        setAddTicketOpen(true);
        };
    
        // To close add dialouge box
        const handleAddTicketClose = () => {
        setAddTicketOpen(false);
        };

        // To open edit dialouge box
        const handleEditTicketOpen = (id:any) => {
            setEditTicketOpen(true);
            //console.log("id",id)
             setEditTicketId(id)
         };
    
        // To close edit dialouge box
        const handleEditTicketClose = () => {
        setEditTicketOpen(false);
        };

        // To open View dialouge box
        const handleViewTicketOpen = (id:any) => {
            setViewTicketOpen(true);
            //console.log("id",id)
                setViewTicketId(id)
            };
    
        // To close View dialouge box
        const handleViewTicketClose = () => {
        setViewTicketOpen(false);
        };
               
    
    
        // Head cell of Ticket
        const TicketingHeadCell = [

            {
                id: "ticket_number",
                numeric: false,
                disablePadding: false,
                label: "Ticket Number",
                isSort: true,
                isBold: true,
            },
            {
            id: "sys_issue_type_name",
            numeric: false,
            disablePadding: false,
            label: "Issue Type",
            isSort: true,
            isBold: true,
            },
            {
                id: "ticket_issuedescription",
                numeric: false,
                disablePadding: false,
                label: "Issue Description",
                isSort: true,
                isBold: true,
                },
            {
            id: "iscancelled",
            numeric: false,
            disablePadding: false,
            label: "Cancel Status",
            isSort: true,
            isBold: true,
            },
            {
            id: "action",
            numeric: false,
            disablePadding: false,
            label: "Actions",
            isSort: false,
            },
            ];


        const [cells, setCells] = useState<any>(TicketingHeadCell);

        //  Global data from session storage
        const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
        const GlobalData = JSON.parse(GlobalDataFromSession);
        //console.log("GlobalData",GlobalData)
        let CompanyId = GlobalData.GlobalCompanyId
        const UserId = GlobalData.GlobalUserId;

        //  To get Ticket list search API  
        const getTicketinglistsearch = () => 
        {
        
        // Initializing Variable 
            let TicketingNumber = null;
            let IssueType = null;
            let IssueDiscription = null;
            let IsCancelled =  null;
        
        for (const item of cells)
        {
            if (item.id === "ticket_number") {
            TicketingNumber = item.value;
            }

            if (item.id === "sys_issue_type_name") {
                IssueType = item.value;
            }

            if (item.id === "ticket_issuedescription") {
                IssueDiscription = item.value;
            }

            if (item.id === "iscancelled") {
                IsCancelled = item.value;
            }

        }
    
            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/get_ticket_list_search`, {
                TicketingNumber:TicketingNumber,
                IssueType:IssueType,
                IssueDiscription:IssueDiscription,
                IsCancelled:IsCancelled,
                SortColumnName: orderBy,
                SortColumnDirection: order,
                StartIndex: page + 1,
                PageSize: rowsPerPage,
                CompanyId:CompanyId,
                CreatedBy:UserId
            })
            .then((res: any) => 
            {
                //console.log("res",res)
            const formattedTableData= res.data.data.map((item:any)=>{
            return {...item,
                id:item.ticketid
            }
            }) 
            //console.log("formattedTableData",formattedTableData)
            
            setTableData(formattedTableData);
            if(res.data.data[0]===undefined)
            {
                setCount(0);
            }
            else
            {
                setCount(res.data.data[0].total_count);
            }
            })
        .catch((error) => 
            {
            if (error.response.status === 404)
            {
                setTableData([]);
            }
            Failed(error.response.message);
            });
        };

    // To get categories details as per the id 
    const GetTickteListById =(id:any,actionType:any)=>{
        commonService
         .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getTicketListById`, {
            TicketId:id,
            CreatedBy: UserId,
            CompanyId:CompanyId
         })
         .then((res: any) => 
         { 
            //console.log("res",res)
            const TicketData = res.data.data[0]
            //console.log("TicketData",TicketData)
            //console.log("actionType",actionType)

            if(actionType === "delete" && TicketData.ticket_allocatestatus === "Y"){
                //console.log("actionType D",actionType)
                Swal.fire({
                    html: '<span style="font-size: 1.3rem;">Ticket is already allocated, You cannot cancel</span>',
                    confirmButtonText: 'OK',
                    });
            }
            else if(actionType === "delete" && TicketData.ticket_allocatestatus === "N"){
                handleDeleteFleet(id)
            }

            // for - user should edit the ticket if the ticket is already allocated
            if(actionType === "edit" && TicketData.ticket_allocatestatus === "Y"){
                //console.log("actionType E",actionType)
                Swal.fire({
                    html: '<span style="font-size: 1.3rem;">Ticket is already allocated, You cannot edit</span>',
                    confirmButtonText: 'OK',
                    });
            }
            else if(actionType === "edit" && TicketData.ticket_allocatestatus === "N"){
                handleEditTicketOpen(id)
            }
            
         }).catch((error) =>  
         { 
          Failed(error.response.message);
         });
    }
    
        
        // Sort Handler
        const handleRequestSort = (event: any, property: any) =>
        {
        const isAsc = orderBy === property && order === "asc";
        order = isAsc ? "desc" : "asc";
        setOrder(order);
        orderBy = property;
        setOrderBy(orderBy);
        };
    
        // Handle Delete
        const handleDeleteFleet = (id: string) => {
            deleteAlert({
            title: "Delete",
            message: "Are you sure?",
            confirmButtonText: "Yes",
            onConfirmed: DeleteTicket,
            data: id,
            });
        };
    
        const handleChangeList = () => {
        if (page === 0) getTicketinglistsearch();
        else setPage(0);
        };
    
        const DeleteTicket = (id: string) =>{
    
           commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/ticketing_create_update_cancel`, {
            CompanyId:CompanyId,
            TicketingId:id,
            IsCancelled:"Y",
            TicketAllocatedSts:null
        })
        .then((res) => {
        if (res.status === 200) {
            Success("Issue Cancelled Successfully");
            handleChangeList();
            getTicketinglistsearch();
        }
        }).catch((error) => {
            console.error("Error:", error);
        });
        }
    
    
        // Action function of table icons
        const actionFunction = (id: string, actionText: string) => 
        {
            if (actionText === "edit"){
                actionType ='edit'
                //console.log("actionType E",actionType)
                GetTickteListById(id, actionType)
            }

            if (actionText === "view"){
                handleViewTicketOpen(id);
            }

            if (actionText === "delete"){
                actionType = 'delete'
                //console.log("actionType D",actionType)
                GetTickteListById(id, actionType)
            } 
        };
    
    
        // Search Handler of table
        const handleSearch = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const search = [...cells];
            const index = cells.findIndex((c: any) => c.id === e.target.name);
            //console.log("e.target.name",e.target.name)
            if (index !== -1) {
                search[index].value = e.target.value;
            }
            //console.log("search",search)
            setCells(search);
            handleChangePage(setPage,0);
            getTicketinglistsearch();
        };
    
    
        // Pagination Handler of table
        const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        };
    
        // Rows Per page Handler
        const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
            ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        };
    
    
        useEffect(() => {
            getTicketinglistsearch();
        }, [rowsPerPage, page, orderBy, order]); 
        




    return(
        <>
        <MainContainerWrapper headingText='My Tickets'
        handleClick={() =>{
            // navigate('/add_employee')
            handleAddTicketOpen();
        }}
        buttonText="Raise a Ticket"
        isSearch={false}
        isCreateButton={true}
        buttonIcon={<span>&#43;</span>}
        >
        <Box>
            <Box bgcolor={'white'}>
            <Box>
            <EnhancedTable
                headCells={TicketingHeadCell}
                rows={tableData}
                rowsPerPage={rowsPerPage}
                page={page}
                handleSearch={handleSearch}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                actionFunction={actionFunction}
                isView={true}
                isDelete={true}
                isEdit={true}
                total={count}
                isSearch={true}
                />
            </Box>

        </Box>

                    



    {/* SOP-55 - TS - start (View, edit ,add dialog box commented)*/}
    {/* // Asset Management-Ticketing-View and Edit Page */}
        {/* Add Ticket dialog box */}
        {/* <Box>
            <Dialog  open={AddTicketOpen}  >
                <RaiseATicketPage handleAddTicketClose={handleAddTicketClose}
                getTicketlistsearch={getTicketinglistsearch} />
            </Dialog>
        </Box> */}
        {/* End of Add Ticket dialog box */}

        {/* Edit Ticket dialog box */}
        {/* <Box> */}
            {/* <Dialog  open={EditTicketOpen}  > */}
                {/* <EditRaiseATicketPage handleEditTicketClose={handleEditTicketClose}
                getTicketlistsearch={getTicketinglistsearch} TicketId={EditTicketId}  /> */}
            {/* </Dialog> */}
        {/* </Box> */}
        {/* End of Edit Ticket */}

                   
        {/* View Ticket dialog box */}
        {/* <Box>
                <Dialog  open={ViewTicketOpen}  >
                    <ViewRaiseATicketPage handleViewTicketClose={handleViewTicketClose}
                    handleEditTicketOpen={handleEditTicketOpen} TicketId={ViewTicketId}  />
                </Dialog>
            </Box> */}
            {/* End of View Ticket */}

    {/* SOP-55 - TS - end*/}
    {/* // Asset Management-Ticketing-View and Edit Page */}
                    

          </Box>
          <Box mt={5} display={'flex'} justifyContent={'flex-start'}>
          <Button
              sx={{
                  background: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  padding:{
                      lg: "0 70px",
                      md: "0 70",
                      xs:'0 50px'

                  },
                  // color: {accent},
                  height: "3rem",
                  boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
              }}

              onClick={()=>{
                navigate('/home')
              }} 
          >
              Back
          </Button>

          </Box>

        </MainContainerWrapper>

        </>
    )

}

export default MyTicketsPage;

// End of SOP-26