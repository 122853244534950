// Included by sanjana SOP-15
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import AddCategoriesPage from './AddCategories';
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import EditCategoriesPage from './EditCategories';
import deleteAlert from '../../component/HOC/deleteAlert';





const Categories =()=>{


      // Use navigate
      const navigate = useNavigate();

       // useState
       let  [order, setOrder] = React.useState<"desc" | "asc">("desc");
       let  [orderBy, setOrderBy] = React.useState("");
       let  [tableData, setTableData] = React.useState([]);
       const [page, setPage] = React.useState<number>(0);
       const [count, setCount] = useState<number>(0);
       const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
       const [AddCategoriesOpen, setAddCategoriesOpen] = React.useState(false);
       const [EditCategoriesOpen, setEditCategoriesOpen] = React.useState(false);
       const [EditCategoryId, setEditCategoryId]= useState('')
      //  let initialActivestatus = "Active"


      //  To open add dialouge box
       const handleAddCategoriesOpen = () => {
        setAddCategoriesOpen(true);
        };
    
        // To close add dialouge box
        const handleAddCategoriesClose = () => {
        setAddCategoriesOpen(false);
        };

        // To open edit dialouge box
        const handleEditCategoriesOpen = (id:any) => {
            setEditCategoriesOpen(true);
            //console.log("id",id)
          setEditCategoryId(id)
         };
    
        // To close edit dialouge box
        const handleEditCategoriesClose = () => {
        setEditCategoriesOpen(false);
        };





        // Head cell of categories
    const CategoriesHeadCell = [
        
        {
         id: "categoryname",
         numeric: false,
         disablePadding: false,
         label: "Categories",
         isSort: true,
         isBold: true,
       },
       {
        id: "active",
        numeric: false,
        disablePadding: false,
        label: "Active",
        isSort: true,
        isBold: true,
        // defaultValue:initialActivestatus
      },
      {
        id: "action",
        numeric: false,
        disablePadding: false,
        label: "Actions",
        isSort: false,
      },
       ];



       const [cells, setCells] = useState<any>(CategoriesHeadCell);

      //  Global data from session storage
       const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
       const GlobalData = JSON.parse(GlobalDataFromSession);
       //console.log("GlobalData",GlobalData)
       let CompanyId = GlobalData.GlobalCompanyId
       //console.log("CompanyId",CompanyId)

      //  To get categories list search API  
       const getcategorieslistsearch = () => 
       {
     
     // Initializing Variable 
         let CategoryName = null;
         let bActiveState = ""; 
     
       for (const item of cells)
       {
          if (item.id === "categoryname") {
           CategoryName = item.value;
         }
        
         if (item.id === "active") {
            bActiveState = item.value;
           //console.log("item.value",item.value)
           //console.log("bActiveState",bActiveState)
         }
       }

      //  if(bActiveState === ""){
      //   initialActivestatus = ""
      //  }

         commonService
         .postService(`${process.env.REACT_APP_BACKEND_URL}/master/get_categories_list_search`, {
            SearchCategoryName:CategoryName,
            SearchCategoryActive:  bActiveState,
            SortColumnName: orderBy,
            SortColumnDirection: order,
            StartIndex: page + 1,
            PageSize: rowsPerPage,
            CompanyId:CompanyId,
         })
         .then((res: any) => 
         {
            //console.log("res",res)
         const formattedTableData= res.data.data.map((item:any)=>{
         return {...item,
            id:item.categoryid
        }
           }) 
           //console.log("formattedTableData",formattedTableData)
           
         setTableData(formattedTableData);
         if(res.data.data[0]===undefined)
           {
             setCount(0);
           }
           else
           {
             setCount(res.data.data[0].total_count);
           }
         })
       .catch((error) => 
         {
           if (error.response.status === 404)
           {
             setTableData([]);
           }
          Failed(error.response.message);
         });
       };

       
    // Sort Handler
    const handleRequestSort = (event: any, property: any) =>
    {
    const isAsc = orderBy === property && order === "asc";
    order = isAsc ? "desc" : "asc";
    setOrder(order);
    orderBy = property;
    setOrderBy(orderBy);
    };

    // Handle Delete
    const handleDeleteFleet = (id: string) => {
        deleteAlert({
          title: "Delete",
          message: "Are you sure?",
          confirmButtonText: "Yes",
          onConfirmed: DeleteCategory,
          data: id,
        });
      };

  const handleChangeList = () => {
    if (page === 0) getcategorieslistsearch();
    else setPage(0);
  };

  const DeleteCategory = (id: string) =>{

    commonService
      .postService(`${process.env.REACT_APP_BACKEND_URL}/master/categories_create_update_delete`, 
      {
        SaveUpdateKey: null,
        CategoryName: null,
        CategoryId: id,
        CompanyId: CompanyId,
        Active: null,
        CreatedDateTime: null,
        CreatedBy: null,
        IsRemove: 'Y'
      })
      .then((res: any) => {
        if (res.status === 200) {
            Success("Category Deleted Successfully");
            handleChangeList();
            getcategorieslistsearch();
        }
      })
      .catch((err) => {
        // Modified by sanjana SOP-52 to add failed message content
        // Failed("");
        Failed("Reference information is found in the transaction table");
        // End of SOP-52
      });
   }


    // Action function of table icons
    const actionFunction = (id: string, actionText: string) => 
    {
        if (actionText === "edit"){
            handleEditCategoriesOpen(id);
        };
        if (actionText === "delete") handleDeleteFleet(id)
    };
  

    // Search Handler of table
    const handleSearch = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const search = [...cells];
        const index = cells.findIndex((c: any) => c.id === e.target.name);
        //console.log("e.target.name",e.target.name)
        if (index !== -1) {
            search[index].value = e.target.value;
        }
        //console.log("search",search)
        setCells(search);
        handleChangePage(setPage,0);
        getcategorieslistsearch();
    };


    // Pagination Handler of table
    const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    };

    // Rows Per page Handler
    const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
        ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    };


    useEffect(() => {
        getcategorieslistsearch();
      }, [rowsPerPage, page, orderBy, order]); 
     



// Design start
    return(
        <>
        <MainContainerWrapper 
        headingText="Categories"
        handleClick={() =>{
            handleAddCategoriesOpen()
        }}
        // handleSearch={handleSearch}
        buttonText="Add Category"
        isSearch={false}
        // search={search}
        isCreateButton={true}
        buttonIcon={<span>&#43;</span>}
        >
            <Box>
            <Box bgcolor={'white'}>
            <Box>
            <EnhancedTable
                headCells={CategoriesHeadCell}
                rows={tableData}
                rowsPerPage={rowsPerPage}
                page={page}
                handleSearch={handleSearch}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                actionFunction={actionFunction}
                isView={false}
                isDelete={true}
                isEdit={true}
                total={count}
                isSearch={true}
                />
            </Box>

        </Box>
          {/* Add Categories dialog box */}
            <Box>
                <Dialog  open={AddCategoriesOpen}  >
                    <AddCategoriesPage handleAddCategoriesClose={handleAddCategoriesClose}
                    getcategorieslistsearch={getcategorieslistsearch} />
                </Dialog>
            </Box>
            {/* End of Add categories */}

            {/* Edit Categories dialog box */}
            <Box>
                <Dialog  open={EditCategoriesOpen}  >
                    <EditCategoriesPage handleEditCategoriesClose={handleEditCategoriesClose}
                    getcategorieslistsearch={getcategorieslistsearch} CategoryId={EditCategoryId}  />
                </Dialog>
            </Box>
            {/* End of Edit categories */}
          </Box>
          <Box mt={5} display={'flex'} justifyContent={'flex-start'}>
          <Button
              sx={{
                  background: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  padding:{
                      lg: "0 70px",
                      md: "0 70",
                      xs:'0 50px'

                  },
                  // color: {accent},
                  height: "3rem",
                  boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
              }}

              onClick={()=>{
                navigate('/backoffice')
              }} 
          >
              Back
          </Button>

          </Box>
        </MainContainerWrapper>
        </>
    )
// Design end
}

export default Categories;


// End of SOP-15
