import { forwardRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
// project imports
import { MENU_OPEN, SET_MENU } from "../../../../../../features/theme/actions";
import { useLocation } from "react-router-dom";
import { KeyboardArrowDown } from "@mui/icons-material";
// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }: NavItemTypes) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const customization = useSelector((state: any) => state.customization);
  const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));
  const [openSubmenus, setOpenSubmenus] = useState<string[]>([]);


  const isOpen = (submenuId: string) => {
    return openSubmenus.includes(submenuId);
  };

  const Icon = item.icon;
  // const itemIcon = <Icon sx={{ fontSize: "28px" }} />;
  const itemIcon = <Icon sx={{ fontSize: "24px" }} />;

  let itemTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  let listItemProps = {
    component: forwardRef((props: any, ref: React.ForwardedRef<unknown>) => (
      <Link ref={ref} {...props} to={item.url} target={itemTarget} />
    )),
  };
  if (item?.external) {
    listItemProps = {
      component: forwardRef((props: any, ref: React.ForwardedRef<unknown>) => (
        <Link ref={ref} {...props} to={item.url} target={itemTarget} />
      )),
    };
  }

  const itemHandler = (id: any) => {
    if (hasSubmenus) {
      const submenuId = item.submenus[0].id; // Assuming only one submenu per main item
      setOpenSubmenus((prevOpenSubmenus) =>
        prevOpenSubmenus.includes(submenuId)
          ? prevOpenSubmenus.filter((submenu) => submenu !== submenuId)
          : [...prevOpenSubmenus, submenuId]
      );
    }
  
    dispatch({ type: MENU_OPEN, id });
    if (matchesSM) dispatch({ type: SET_MENU, opened: false });
  };
  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split("/")
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch({ type: MENU_OPEN, id: item.id });
    }
    // eslint-disable-next-line
  }, []);
  const location = useLocation();
  const hasSubmenus = item.submenus && item.submenus.length > 0;
  return (
    <div style={{width: '100%'}}>
    <ListItemButton 
      {...listItemProps}
      disabled={item.disabled}
      sx={{
        borderRadius: `${customization.borderRadius}px`,
        width: "100%",
        backgroundColor: level > 1 ? "transparent !important" : "inherit",
        mb: "5px",     
        display: "flex",
        alignItems: "center",
      }}
      selected={`${item.url}` === location.pathname}
      onClick={() => itemHandler(item.id)}
    >
      <ListItemIcon
        className="side-menu-icon-list"
        sx={{ my: "auto", fontSize:'24px' }}
      >
        {itemIcon}
      </ListItemIcon>
      <ListItemText
        sx={{ marginLeft: "1rem" }}
        primary={
          <Typography
            sx={{ fontSize: "0.9rem" }}
            // variant={
            //   customization.isOpen.findIndex((id: any) => id === item.id) > -1
            //     ? "h1"
            //     : "body1"
            // }
            color="inherit"
          >         
            {item.title}
            
          </Typography>
          
        }
      />
      {hasSubmenus ? (<KeyboardArrowDown/>): ('')}
    </ListItemButton>
      {hasSubmenus &&
        item.submenus.map((submenu: any) => (
          <Collapse key={submenu.id} in={isOpen(submenu.id)}>
            <List>
              {submenu.options.map((option: any) => (
                <ListItemButton
                  key={option.id}
                  component={Link}
                  to={option.url}
                >
                  <ListItemIcon
                    className="side-menu-icon-list"
                    sx={{ my: "auto" }}
                  >
                    <option.icon />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ marginLeft: "1rem" }}
                    primary={
                      <Typography
                        variant={
                          customization.isOpen.findIndex((id: any) => id === item.id) > -1
                            ? "h6"
                            : "body1"
                        }
                        color="inherit"
                      >
                        {option.title}
                      </Typography>
                    }
                  />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        ))}
  </div>
);
};

type NavItemTypes = {
  item: any;
  level: number;
};

export default NavItem;
