// included by TEENA - SOP-67
import { useState,useRef,useEffect,useCallback } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    
    Grid,
  
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControl,
    FormHelperText,
    useTheme,
    Container,
    Stack,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import CryptoJS from "crypto-js";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FcInfo } from "react-icons/fc";
import { FaUserGear } from "react-icons/fa6";
import { BsBuildingsFill } from "react-icons/bs";
import { IoMdPhotos } from "react-icons/io";
import Swal from "sweetalert2";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";




const EmployeeTerminationPage =()=>{

    // Use navigate
    const navigate = useNavigate();

    const {id} = useParams();
    const EmployeeId = id

    // usestate declaration
    const [EmployeeNameError, setEmployeeNameError]=useState(false)
    const [EmployeeNameValue, setEmployeeNameValue]=useState('')
    const [EmployeeNameErrorMessage, setEmployeeNameErrorMessage]=useState('')
    const [EmployeeEmailError, setEmployeeEmailError]=useState(false)
    const [EmployeeEmailValue, setEmployeeEmailValue]=useState('')
    const [EmployeeEmailErrorMessage, setEmployeeEmailErrorMessage]=useState('')
    const [EmployeeMobileError, setEmployeeMobileError]=useState(false)
    const [EmployeeMobileValue, setEmployeeMobileValue]=useState('')
    const [EmployeeMobileErrorMessage, setEmployeeMobileErrorMessage]=useState('')
    const [EmployeePasswordError, setEmployeePasswordError]=useState(false)
    const [EmployeePasswordValue, setEmployeePasswordValue]=useState('')
    const [EmployeePasswordErrorMessage, setEmployeePasswordErrorMessage]=useState('')
    const [EmployeeRoleOptions, setEmployeeRoleOptions] = useState<OptionType[]>([]);
    const [selectedEmployeeRoleId, setSelectedEmployeeRoleId] = useState<string | null>('');
    const [EmployeeRoleError, setEmployeeRoleError]=useState(false)
    const [EmployeeRoleErrorMessage, setEmployeeRoleErrorMessage]=useState('')
    const [isActive, setIsActive] = useState(true);
    const [EmployeeReportToWhomListOptions, setEmployeeReportToWhomListOptions] = useState<OptionType[]>([]);
    const [selectedEmployeeReportToWhomId, setSelectedEmployeeReportToWhomId] = useState<string | null>('');
    const [EmployeeReportToWhomError, setEmployeeReportToWhomError]=useState(false)
    const [EmployeeReportToWhomErrorMessage, setEmployeeReportToWhomErrorMessage]=useState('')
    const [EmployeeDateOfJoiningError, setEmployeeDateOfJoiningError]=useState(false)
    const [EmployeeDateOfJoiningValue, setEmployeeDateOfJoiningValue] = useState<dayjs.Dayjs | null>(null);
    const [EmployeeDateOfJoiningErrorMessage, setEmployeeDateOfJoiningErrorMessage]=useState('')
    const [EmployeeDateOfRelievingError, setEmployeeDateOfRelievingError]=useState(false)
    const [EmployeeDateOfRelievingValue, setEmployeeDateOfRelievingValue]=useState<dayjs.Dayjs | null>(null);
    const [EmployeeDateOfRelievingErrorMessage, setEmployeeDateOfRelievingErrorMessage]=useState('')

    
    const [EmployeeDesignationOptions, setEmployeeDesignationOptions] = useState<OptionType[]>([]);
    const [selectedEmployeeDesignationId, setSelectedEmployeeDesignationId] = useState<string | null>('');
    const [EmployeeDesignationError, setEmployeeDesignationError]=useState(false)
    const [EmployeeDesignationErrorMessage, setEmployeeDesignationErrorMessage]=useState('')

    // EOF SOP-31
    // SOP-31 Included By PCS [Included Department drop down] 

    const [EmployeeDepartmentOptions, setEmployeeDepartmentOptions] = useState<OptionType[]>([]);
    const [selectedEmployeeDepartmentId, setSelectedEmployeeDepartmentId] = useState<string | null>('');
    const [EmployeeDepartmentError, setEmployeeDepartmentError]=useState(false)
    const [EmployeeDepartmentErrorMessage, setEmployeeDepartmentErrorMessage]=useState('')

    //EOF SOP- 31 


    const [EmployeeWorkLocationError, setEmployeeWorkLocationError]=useState(false)
    const [EmployeeWorkLocationValue, setEmployeeWorkLocationValue]=useState('')
    const [EmployeeWorkLocationErrorMessage, setEmployeeWorkLocationErrorMessage]=useState('')
    const [EmployeeHandOveredStatusError, setEmployeeHandOveredStatusError]=useState(false)
    const [EmployeeHandOveredStatusValue, setEmployeeHandOveredStatusValue]=useState('')
    const [EmployeeHandOveredStatusErrorMessage, setEmployeeHandOveredStatusErrorMessage]=useState('')
    const [EmployeeNoError, setEmployeeNoError]=useState(false)
    const [EmployeeNoValue, setEmployeeNoValue]=useState('')
    const [EmployeeNoErrorMessage, setEmployeeNoErrorMessage]=useState('')
    const [EmployeeReasonOfRelievingError, setEmployeeReasonOfRelievingError]=useState(false)
    const [EmployeeReasonOfRelievingValue, setEmployeeReasonOfRelievingValue]=useState('')
    const [EmployeeReasonOfRelievingErrorMessage, setEmployeeReasonOfRelievingErrorMessage]=useState('')
    const [imageSrclink, setImageSrclink] = useState<string>('');
    const [imageUrl, setImageUrl] = useState<any>(null);
    const [key, setKey] = useState(0);
    const [EmployeeRoleEnable, setEmployeeRoleEnable]=useState(false)
    const [EmployeeUserId, setEmployeeUserId]=useState()
    const [EnableClearBtn, setEnableClearBtn] = useState(false);
    const [EmployeeRelievingDetailsEnable, setEmployeeRelievingDetailsEnable]=useState(false)


  // State for checkboxes
  const [isITChecked, setIsITChecked] = useState(false);
  const [isFinanceChecked, setIsFinanceChecked] = useState(false);
  const [isManagerChecked, setIsManagerChecked] = useState(false);
  const [isHRChecked, setIsHRChecked] = useState(false);
    
    const [LastDateError, setLastDateError]=useState(false)
    const [LastDateValue, setLastDateValue] = useState<dayjs.Dayjs | null>(null);
    const [LastDateErrorMessage, setLastDateErrorMessage] = useState('')
        
    const [ITRemarksError, setITRemarksError] = useState(false)
    const [ITRemarksValue, setITRemarksValue]=useState('')
    const [ITRemarksErrorMessage, setITRemarksErrorMessage] = useState('')
    
    const [FinanceRemarksError, setFinanceRemarksError] = useState(false)
    const [FinanceRemarksValue, setFinanceRemarksValue]=useState('')
    const [FinanceRemarksErrorMessage, setFinanceRemarksErrorMessage] = useState('')

    const [ManagerRemarksError, setManagerRemarksError] = useState(false)
    const [ManagerRemarksValue, setManagerRemarksValue]=useState('')
    const [ManagerRemarksErrorMessage, setManagerRemarksErrorMessage] = useState('')

    const [HRRemarksError, setHRRemarksError] = useState(false)
    const [HRRemarksValue, setHRRemarksValue]=useState('')
    const [HRRemarksErrorMessage, setHRRemarksErrorMessage] = useState('')
    
    
        // Global data from session storage
        const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
        const GlobalData = JSON.parse(GlobalDataFromSession);
        ////console.log("GlobalData",GlobalData)
        let CompanyId = GlobalData.GlobalCompanyId;
        let UserId = GlobalData.GlobalUserId;

    type OptionType = {
        name: string;
        value: string;
    };
    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });


    const handleLastDateChange =(date:any)=>{
        setLastDateValue(date);
    }

    const handleItRemarksChange =( event:any)=>{
        const trimmedOtherRemarksValue = event.target.value.trim();
        setITRemarksValue(event.target.value);
        if( trimmedOtherRemarksValue.length >225 ){
            setITRemarksError(true);
            setITRemarksErrorMessage("Max 255 characters");
        }
        else {
            setITRemarksError(false);
            setITRemarksErrorMessage("");
        }
    }


    const handleFinanceRemarksChange =( event:any)=>{
        const trimmedOtherRemarksValue = event.target.value.trim();
        setFinanceRemarksValue(event.target.value);
        if( trimmedOtherRemarksValue.length >225 ){
            setFinanceRemarksError(true);
            setFinanceRemarksErrorMessage("Max 255 characters");
        }
        else {
            setFinanceRemarksError(false);
            setFinanceRemarksErrorMessage("");
        }
    }


    const handleManagerRemarksChange =( event:any)=>{
        const trimmedOtherRemarksValue = event.target.value.trim();
        setManagerRemarksValue(event.target.value);
        if( trimmedOtherRemarksValue.length >225 ){
            setManagerRemarksError(true);
            setManagerRemarksErrorMessage("Max 255 characters");
        }
        else {
            setManagerRemarksError(false);
            setManagerRemarksErrorMessage("");
        }
    }

    const handleHRRemarksChange =( event:any)=>{
        const trimmedOtherRemarksValue = event.target.value.trim();
        setHRRemarksValue(event.target.value);
        if( trimmedOtherRemarksValue.length >225 ){
            setHRRemarksError(true);
            setHRRemarksErrorMessage("Max 255 characters");
        }
        else {
            setHRRemarksError(false);
            setHRRemarksErrorMessage("");
        }
    }

    const handleEmployeeNameChange =(event:any)=>{
        const trimmedEmployeeNameValue = event.target.value.trim();
        setEmployeeNameValue(event.target.value);
        if (trimmedEmployeeNameValue === '') {
            setEmployeeNameError(true);
            setEmployeeNameErrorMessage("Employee name is required");
        } 
        else if( trimmedEmployeeNameValue.length >255 ){
            setEmployeeNameError(true);
            setEmployeeNameErrorMessage("Max 255 characters");
        }
        else {
            setEmployeeNameError(false);
            setEmployeeNameErrorMessage("");
        }

    }

    const handleEmployeeEmailChange =(event:any)=>{
        const trimmedEmployeeEmailValue = event.target.value.trim();
        setEmployeeEmailValue(event.target.value);
        if (trimmedEmployeeEmailValue === '') {
            setEmployeeEmailError(true);
            setEmployeeEmailErrorMessage("Email is required");
        } 
        else if( trimmedEmployeeEmailValue.length >255 ){
            setEmployeeEmailError(true);
            setEmployeeEmailErrorMessage("Max 255 characters");
        }
        else if( !(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(trimmedEmployeeEmailValue) ){
            setEmployeeEmailError(true);
            setEmployeeEmailErrorMessage("Invalid Email");
        }
        else {
            setEmployeeEmailError(false);
            setEmployeeEmailErrorMessage("");
        }

    }

    const handleEmployeeMobileChange =(event:any)=>{
        const trimmedEmployeeMobileValue = event.target.value.trim();
        setEmployeeMobileValue(event.target.value);
        if (trimmedEmployeeMobileValue === '') {
            setEmployeeMobileError(true);
            setEmployeeMobileErrorMessage("Mobile number is required");
        } 
        else if( trimmedEmployeeMobileValue.length >15 ){
            setEmployeeMobileError(true);
            setEmployeeMobileErrorMessage("Invalid mobile number");
        }
       
          else if( !/^[0-9 +]+$/.test(trimmedEmployeeMobileValue) ){
            setEmployeeMobileError(true);
            setEmployeeMobileErrorMessage("Numbers only");
        }
        else {
            setEmployeeMobileError(false);
            setEmployeeMobileErrorMessage("");
        }
    }


    const handleEmployeePasswordChange =(event:any)=>{
        const trimmedEmployeePasswordValue = event.target.value.trim();
        setEmployeePasswordValue(event.target.value);


          //SOP-17 Included By PCS Testing feedback corrections [to set strong password]
          const hasUpperCase = /[A-Z]/.test(trimmedEmployeePasswordValue);
          const hasDigit = /\d/.test(trimmedEmployeePasswordValue);
          const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(trimmedEmployeePasswordValue);
          //EOF SOP 17 Inlcuded By PCS  [to set strong password]

        if (trimmedEmployeePasswordValue === '') {
            setEmployeePasswordError(true);
            setEmployeePasswordErrorMessage("Password is required");
        } 

        
        else if (event.target.value.includes(' ')) {
            setEmployeePasswordError(true);
            setEmployeePasswordErrorMessage("Spaces are not allowed");
        }


        else if( trimmedEmployeePasswordValue.length >12 ){
            setEmployeePasswordError(true);
            setEmployeePasswordErrorMessage("Max 12 characters");
        }

    
        else if (!hasUpperCase || !hasDigit || !hasSpecialChar ) {
            setEmployeePasswordError(true);
            setEmployeePasswordErrorMessage("Password must contain at least one uppercase letter, one digit, and one special character");
        }
   

        else {
            setEmployeePasswordError(false);
            setEmployeePasswordErrorMessage("");
            }
    }


    const handleDateOfJoiningChange = (date:any) => {
        setEmployeeDateOfJoiningValue(date)
        
        if (!date) {
            setEmployeeDateOfJoiningError(true);

            setEmployeeDateOfJoiningErrorMessage("Date of Joining is required");
        }
        else {
            setEmployeeDateOfJoiningError(false);
            setEmployeeDateOfJoiningErrorMessage("");
        }
    };


    const handleDateOfRelievingChange = (date: any) => {
        setEmployeeDateOfRelievingValue(date)

           if (!date) {
            setEmployeeDateOfRelievingError(true);
            setEmployeeDateOfRelievingErrorMessage("Date of Relieving is required");
        }
        else {
            setEmployeeDateOfRelievingError(false);
            setEmployeeDateOfRelievingErrorMessage("");
        }

    }
        const handleEmployeeWorkLocationChange = (event: any) => {
            const trimmedEmployeeWorkLocationValue = event.target.value.trim();
            //console.log("trimmedEmployeeWorkLocationValue",trimmedEmployeeWorkLocationValue)
            setEmployeeWorkLocationValue(event.target.value);
            if (trimmedEmployeeWorkLocationValue === '') {
                setEmployeeWorkLocationError(true);
                setEmployeeWorkLocationErrorMessage("Work Location is required");
            }
            else if (trimmedEmployeeWorkLocationValue.length > 255) {
                setEmployeeWorkLocationError(true);
                setEmployeeWorkLocationErrorMessage("Max 255 characters");
            }
            else {
                setEmployeeWorkLocationError(false);
                setEmployeeWorkLocationErrorMessage("");
            }

        }

        const handleEmployeeHandOveredStatusChange = (event: any) => {
            const trimmedEmployeeHandOveredStatusValue = event.target.value.trim();
            setEmployeeHandOveredStatusValue(event.target.value);

            if (trimmedEmployeeHandOveredStatusValue.length > 255) {
                setEmployeeHandOveredStatusError(true);
                setEmployeeHandOveredStatusErrorMessage("Max 255 characters");
            }
            else {
                setEmployeeWorkLocationError(false);
                setEmployeeWorkLocationErrorMessage("");
            }

        }

        const handleEmployeeNoChange = (event: any) => {
            const trimmedEmployeeNoValue = event.target.value.trim();
            setEmployeeNoValue(event.target.value);
            if (trimmedEmployeeNoValue.length > 255) {
                setEmployeeNoError(true);
                setEmployeeNoErrorMessage("Max 255 characters");
            }
            else {
                setEmployeeNoError(false);
                setEmployeeNoErrorMessage("");
            }

        }


        // User role Dropdown API
        const GetUserRoleList = async () => {
            try {
                const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/getUserRoleList`,);
                //console.log("User Role" ,res.data.data);
                let formattedUserRole = res.data.data.map((item: any) => {
                    const { user_role_id, user_role_name } = item;
                    return {
                        name: user_role_name,
                        value: user_role_id,
                    };
                });
                setEmployeeRoleOptions(formattedUserRole)
            } catch (err) {
                //console.log( "co",err);
            }
        };


        // User role Dropdown API
        const GetEmployeeReportToWhomList = async () => {

            const EmployeeLisPayload = {
                CompanyId: CompanyId,
                active: "Y"
            }
            //console.log("EmployeeLisPayload",EmployeeLisPayload)

            try {
                const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/employee/getEmployeeList`, EmployeeLisPayload);
                //console.log("Employee List" ,res.data.data);
                let formattedEmployeeList = res.data.data.map((item: any) => {
                    const { employee_id, employee_name } = item;
                    return {
                        name: employee_name,
                        value: employee_id,
                    };
                });
                setEmployeeReportToWhomListOptions(formattedEmployeeList)
            } catch (err) {
                //console.log( "co",err);
            }
        };

        // SOP-31 Included By PCS [Designation drop down]
        const GetDesignationList = async () => {
            const DesignationListPayload = {
                CompanyId: CompanyId,
            }
            try {
                const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/getDesignationList`, DesignationListPayload);
                //console.log("Designation List" ,res.data.data);
                let formattedDesignationList = res.data.data.map((item: any) => {
                    const { designationid, designationname } = item;
                    return {
                        name: designationname,
                        value: designationid,
                    };
                });
                setEmployeeDesignationOptions(formattedDesignationList)
            }
            catch (err) {
                //console.log( "co",err);
            }
        };

        //EOF SOP-31

        // SOP-31 Included By PCS [Department drop down]
        const GetDepartmentList = async () => {
            const DepartmentListPayload = {
                CompanyId: CompanyId,
            }

            //console.log("DepartmentListPayload",DepartmentListPayload)

            try {
                const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/getDepartmentList`, DepartmentListPayload);
                //console.log("Department List" ,res.data.data);
                let formattedDepartmentList = res.data.data.map((item: any) => {
                    const { departmentid, departmentname } = item;
                    return {
                        name: departmentname,
                        value: departmentid,
                    };
                });
                setEmployeeDepartmentOptions(formattedDepartmentList)
            }
            catch (err) {
                //console.log( "co",err);
            }
        };

        //EOF SOP-31 

        // To get categories details as per the id
        const GetEmployeeListById = () => {
            //console.log("EmployeeId",EmployeeId)
            commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/employee/getEmployeeById`, {
                    EmployeeId: EmployeeId,
                    CompanyId: CompanyId
                })
                .then((res: any) => {
                    // console.log("response edit..",res.data)
                    const Employeedata = res.data.data[0]
                    //console.log("Employeedata",Employeedata)
                    if (Employeedata.active === 'Y') {
                        setIsActive(true)
                        setEmployeeRelievingDetailsEnable(false)
                    }
                    else {
                        setIsActive(false)
                        setEmployeeRelievingDetailsEnable(true)
                    }
                    let formattedEmployeeRoleId = res.data.data.map((item: any) => {
                        const { employee_role_id, employee_role_name } = item;
                        return {
                            name: employee_role_name,
                            value: employee_role_id,
                        };
                    });
                    //console.log("formattedEmployeeRoleId",formattedEmployeeRoleId[0].value)
                    setSelectedEmployeeRoleId(formattedEmployeeRoleId[0].value)

        
                    let formattedDesignationList = res.data.data.map((item: any) => {
                        //console.log('item',item)
                        const { designation_id, designationname } = item;
                        return {
                            name: designationname,
                            value: designation_id,
                        };
                    });
                    //console.log("formattedDesignationList",formattedDesignationList[0].value)
                    setSelectedEmployeeDesignationId(formattedDesignationList[0].value)

      
                    let formattedDepartmentList = res.data.data.map((item: any) => {
                        //console.log('item',item)
                        const { department_id, departmentname } = item;
                        return {
                            name: departmentname,
                            value: department_id,
                        };
                    });
                    //console.log("formattedDepartmentList",formattedDepartmentList[0].value)
                    setSelectedEmployeeDepartmentId(formattedDepartmentList[0].value)

       
                    let formattedEmployeeReportedToWhom = res.data.data.map((item: any) => {
                        const { reported_to_whom_id, reported_to_whom_name } = item;
                        return {
                            name: reported_to_whom_name,
                            value: reported_to_whom_id,
                        };
                    });
                    //console.log("formattedEmployeeReportedToWhom",formattedEmployeeReportedToWhom[0].value)
                    setSelectedEmployeeReportToWhomId(formattedEmployeeReportedToWhom[0].value)
                    setEmployeeNameValue(Employeedata.employee_name);
                    setEmployeeEmailValue(Employeedata.employee_email);
                    setEmployeeMobileValue(Employeedata.employee_mobile);
                    setEmployeeDateOfJoiningValue(Employeedata.date_of_joining);
                    setEmployeeDateOfRelievingValue(Employeedata.date_of_relieving);
                    // setEmployeeDesignationValue(Employeedata.designation); //SOP-31 Text field removed
                    setEmployeeWorkLocationValue(Employeedata.work_location);
                    setEmployeeHandOveredStatusValue(Employeedata.hand_overed_sts);
                    setEmployeeNoValue(Employeedata.employee_number);
                    setEmployeeReasonOfRelievingValue(Employeedata.reason_for_relieving);
                    setImageUrl(Employeedata.photo_url)
                    
                    if (Employeedata.photo_url === null || Employeedata.photo_url === "" || Employeedata.photo_url === undefined) {
                        setEnableClearBtn(false)
                    }
                    else {
                        setEnableClearBtn(true)
                    }
                    // setEmployeeRoleId(Employeedata.employee_role_id)
                    let EmployeeRoleId = Employeedata.employee_role_id;
                    //console.log("Employeedata.employee_role_id",Employeedata.employee_role_id)
                    //console.log("EmployeeRoleId",EmployeeRoleId)
                    if (EmployeeRoleId === 1) {
                        setEmployeeRoleEnable(false)
                        setEmployeeRoleError(false)
                        setEmployeeRoleErrorMessage('')
                    }
                    else {
                        setEmployeeRoleEnable(true)
                    }
                    setEmployeeUserId(Employeedata.userid)


                    // To decrypt password to check valid 
                    const secretPass = "XkhZG4fW2t2W";
                    let decryptedData;
                    if (Employeedata.employee_password && typeof Employeedata.employee_password === 'string' && Employeedata.employee_password.trim() !== '') {
                        const bytes = CryptoJS.AES.decrypt(Employeedata.employee_password, secretPass);
                        decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                        setEmployeePasswordValue(decryptedData);
                    }
                    //console.log("decryptedData",decryptedData)

                }).catch((error) => {
                    console.log("error", error)
                    Failed(error.response.message);
                });
        }

        // Exist Employee Email API
        const GetCompanyExistEmail = async () => {
        
            const ExistEmailPayload = {
                ExistEmail: EmployeeEmailValue,
                UserId: EmployeeUserId
            }
            //console.log("ExistEmailPayload",ExistEmailPayload)
            try {
                const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/company/getCompanyEmailExists`, ExistEmailPayload);
                //console.log(res.data.data);
                return res.data.data || [];
            } catch (err) {
                console.log("co", err);
            }
        };



        // To update employee
        // Define a type for errors
        type Errors = {
            employeeName?: boolean;
            employeeEmail?: boolean;
            employeeMobile?: boolean;
            employeePassword?: boolean;
            employeeRole?: boolean;
            employeeReportToWhom?: boolean;
            employeeDateOfJoining?: boolean;
            employeeDateOfRelieving?: boolean;
            employeeDesignation?: boolean;
            employeeDepartment?: boolean;//SOP-31 [Department Included]
            employeeWorkLocation?: boolean;
            employeeHandOveredSts?: boolean;
            employeeNo?: boolean;
            employeeReasonOfRelieving?: boolean;
            ItRemarks?:boolean;
            FinanceRemarks?: boolean;
            ManagerRemarks?: boolean;
            HrRemarks?:  boolean;
        };

        // To encrypt password
        const secretPass = "XkhZG4fW2t2W";

        const encryptData = async () => {
            try {
                const data = CryptoJS.AES.encrypt(JSON.stringify(EmployeePasswordValue), secretPass).toString();
                return data
            } catch (error) {
                console.error("Error encrypting data:", error);
            }
        };

    const UpdateEmployee = async (event: any) => {
        event.preventDefault();
        let errors: Errors = {};

        const ExistEmployeeEmailList = await GetCompanyExistEmail();
        //console.log("ExistEmployeeEmailList",ExistEmployeeEmailList)
        if (ExistEmployeeEmailList.length > 0) {
            setEmployeeEmailError(true);
            setEmployeeEmailErrorMessage("Email is already exists");
            errors.employeeEmail = true;
            return;
        }

        // Encrypt the data
        const GetEncryptedData = await encryptData()
        //console.log("GetEncryptedData",GetEncryptedData)

        const trimmedEmployeeNameValue = EmployeeNameValue.trim();
        if (trimmedEmployeeNameValue === '') {
            errors.employeeName = true
            setEmployeeNameError(true);
            setEmployeeNameErrorMessage("Employee name is required");
        }
        else if (trimmedEmployeeNameValue.length > 255) {
            errors.employeeName = true
            setEmployeeNameError(true);
            setEmployeeNameErrorMessage("Max 255 characters");
        }
    
    
        const trimmedEmployeeEmailValue = EmployeeEmailValue.trim();
        if (trimmedEmployeeEmailValue === '') {
            errors.employeeEmail = true
            setEmployeeEmailError(true);
            setEmployeeEmailErrorMessage("Email is required");
        }
        else if (trimmedEmployeeEmailValue.length > 255) {
            errors.employeeEmail = true
            setEmployeeEmailError(true);
            setEmployeeEmailErrorMessage("Max 255 characters");
        }
        else if (!(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(trimmedEmployeeEmailValue)) {
            setEmployeeEmailError(true);
            setEmployeeEmailErrorMessage("Invalid Email");
        }
    
        const trimmedEmployeeMobileValue = EmployeeMobileValue.trim();
        if (trimmedEmployeeMobileValue === '') {
            errors.employeeMobile = true
            setEmployeeMobileError(true);
            setEmployeeMobileErrorMessage("Mobile number is required");
        }
        else if (trimmedEmployeeMobileValue.length > 15) {
            errors.employeeMobile = true
            setEmployeeMobileError(true);
            setEmployeeMobileErrorMessage("Invalid mobile number");
        }
        else if (!/^[0-9]+$/.test(trimmedEmployeeMobileValue)) {
            errors.employeeMobile = true
            setEmployeeMobileError(true);
            setEmployeeMobileErrorMessage("Numbers only");
        }
    
        const trimmedEmployeePasswordValue = EmployeePasswordValue.trim();
        if (trimmedEmployeePasswordValue === '') {
            errors.employeePassword = true
            setEmployeePasswordError(true);
            setEmployeePasswordErrorMessage("Password is required");
        }
        else if (trimmedEmployeePasswordValue.length > 12) {
            errors.employeePassword = true
            setEmployeePasswordError(true);
            setEmployeePasswordErrorMessage("Max 12 characters");
        }
    
        if (selectedEmployeeRoleId === '' || selectedEmployeeRoleId === null || selectedEmployeeRoleId === undefined) {
            errors.employeeRole = true;
            setEmployeeRoleErrorMessage("Role is required")
        }

        if (selectedEmployeeReportToWhomId === '' || selectedEmployeeReportToWhomId === null || selectedEmployeeReportToWhomId === undefined) {
            errors.employeeReportToWhom = true;
            //setEmployeeReportToWhomErrorMessage("Report To Whom is required")
            setEmployeeReportToWhomErrorMessage("Reporting To Whom is required")
        }
    
        let trimmedEmployeeDateOfJoiningValue;
        if (EmployeeDateOfJoiningValue === null || EmployeeDateOfJoiningValue === undefined) {
            // Handle the case when EmployeeDateOfJoiningValue is null or undefined
            errors.employeeDateOfJoining = true;
            //setEmployeeDateOfJoiningErrorMessage("Date Of Joining is required");
            setEmployeeDateOfJoiningErrorMessage("Date of Joining is required");

        } else {
            let dateString;
            if (typeof EmployeeDateOfJoiningValue.format === 'function') {
                dateString = EmployeeDateOfJoiningValue.format(); // Assuming your `Dayjs` object has a `format()` method
            } else {
                dateString = EmployeeDateOfJoiningValue.toString(); // Convert to string or handle differently based on what EmployeeDateOfJoiningValue is
            }
            trimmedEmployeeDateOfJoiningValue = dateString.trim();
            //console.log("trimmedEmployeeDateOfJoiningValue.l", trimmedEmployeeDateOfJoiningValue.length);

            if (trimmedEmployeeDateOfJoiningValue === "") {
                errors.employeeDateOfJoining = true;
                //setEmployeeDateOfJoiningErrorMessage("Date Of Joining is required");
                setEmployeeDateOfJoiningErrorMessage("Date of Joining is required");

            } else if (trimmedEmployeeDateOfJoiningValue.length < 24) {
                errors.employeeDateOfJoining = true;
                setEmployeeDateOfJoiningErrorMessage("Please check Date Of Joining");
            } else {
                setEmployeeDateOfJoiningErrorMessage("");
            }
        }
    
        let FormatedJoiningDate;
        if (trimmedEmployeeDateOfJoiningValue) {
            const trimmedDate = new Date(trimmedEmployeeDateOfJoiningValue);
            const year = trimmedDate.getFullYear();
            const month = String(trimmedDate.getMonth() + 1).padStart(2, '0');
            const date = String(trimmedDate.getDate()).padStart(2, '0');
            FormatedJoiningDate = `${year}-${month}-${date}`;
            // Now you can use the formattedDate variable where needed
        } else {
            // nothing
        }


        let trimmedEmployeeDateOfRelievingValue;
        if (EmployeeDateOfRelievingValue === null || EmployeeDateOfRelievingValue === undefined) {
            // Handle the case when EmployeeDateOfRelievingValue is null or undefined
            // errors.employeeDateOfRelieving = true;
            // setEmployeeDateOfRelievingErrorMessage("Date Of Relieving is required");
        } else {
            let dateString;
            if (typeof EmployeeDateOfRelievingValue.format === 'function') {
                dateString = EmployeeDateOfRelievingValue.format(); // Assuming your `Dayjs` object has a `format()` method
            } else {
                dateString = EmployeeDateOfRelievingValue.toString(); // Convert to string or handle differently based on what EmployeeDateOfRelievingValue is
            }

            const trimmedITRemarksValue = ITRemarksValue.trim();
            if( trimmedITRemarksValue.length >225 ){
               errors.ItRemarks=true
               setITRemarksErrorMessage("Max 255 characters");
            }
    
            const trimmedFinanceRemarksValue = FinanceRemarksValue.trim();
            if( trimmedFinanceRemarksValue.length >225 ){
               errors.FinanceRemarks=true
               setFinanceRemarksErrorMessage("Max 255 characters");
            }
    
            const trimmedManagerRemarksValue = ManagerRemarksValue.trim();
            if( trimmedManagerRemarksValue.length >225 ){
               errors.ManagerRemarks=true
               setManagerRemarksErrorMessage("Max 255 characters");
            }
    
            const trimmedHRRemarksValue = HRRemarksValue.trim();
            if( trimmedHRRemarksValue.length >225 ){
               errors.HrRemarks=true
               setHRRemarksErrorMessage("Max 255 characters");
            }
    
    


            trimmedEmployeeDateOfRelievingValue = dateString.trim();
            //console.log("trimmedEmployeeDateOfRelievingValue.l", trimmedEmployeeDateOfRelievingValue.length);

            if (trimmedEmployeeDateOfRelievingValue === "") {
                errors.employeeDateOfRelieving = true;
                //SOP-17 Modified By PCS[Testing feedback Corrections]
                //setEmployeeDateOfRelievingErrorMessage("Date Of Relieving is required");
                setEmployeeDateOfRelievingErrorMessage("Date of Relieving is required");


            } else if (trimmedEmployeeDateOfRelievingValue.length < 24) {
                errors.employeeDateOfRelieving = true;
                setEmployeeDateOfRelievingErrorMessage("Please check Date Of Relieving");
            } else {
                setEmployeeDateOfRelievingErrorMessage("");
            }
        }

              
        let FormatedRelievingDate;
        if (trimmedEmployeeDateOfRelievingValue) {
            const trimmedDate = new Date(trimmedEmployeeDateOfRelievingValue);
            const year = trimmedDate.getFullYear();
            const month = String(trimmedDate.getMonth() + 1).padStart(2, '0');
            const date = String(trimmedDate.getDate()).padStart(2, '0');
            FormatedRelievingDate = `${year}-${month}-${date}`;
            // Now you can use the formattedDate variable where needed
        } else {
            // nothing
        }
        //console.log("trimmedEmployeeDateOfRelievingValue  NEw",trimmedEmployeeDateOfRelievingValue)
        //console.log("isActive new",isActive)

        if (isActive === false && (trimmedEmployeeDateOfRelievingValue === "" || trimmedEmployeeDateOfRelievingValue === undefined || trimmedEmployeeDateOfRelievingValue === null)) {
            //console.log("trimmedEmployeeDateOfRelievingValue",trimmedEmployeeDateOfRelievingValue)
            setEmployeeRelievingDetailsEnable(true)
            errors.employeeDateOfRelieving = true;
            //setEmployeeDateOfRelievingErrorMessage("Date Of Relieving is required");
            setEmployeeDateOfRelievingErrorMessage("Date of Relieving is required");

            //console.log("isActive 2",isActive)
        }

          
        if (selectedEmployeeDesignationId === '' || selectedEmployeeDesignationId === null || selectedEmployeeDesignationId === undefined) {
            errors.employeeDesignation = true;
            setEmployeeDesignationErrorMessage("Designation is required")
        }
        
        if (selectedEmployeeDepartmentId === '' || selectedEmployeeDepartmentId === null || selectedEmployeeDepartmentId === undefined) {
            errors.employeeDepartment = true;
            setEmployeeDepartmentErrorMessage("Department is required")
        }
           
    
        const trimmedEmployeeWorkLocationValue = EmployeeWorkLocationValue.trim();
        if (trimmedEmployeeWorkLocationValue === '') {
            errors.employeeWorkLocation = true;
            setEmployeeWorkLocationErrorMessage("Work Location is required");
        }
        else if (trimmedEmployeeWorkLocationValue.length > 255) {
            errors.employeeWorkLocation = true;
            setEmployeeWorkLocationErrorMessage("Max 255 characters");
        }
        else {
            setEmployeeDesignationError(false);
            setEmployeeDesignationErrorMessage("");
        }
    
        let trimmedEmployeeHandOveredStatusValue;
        if (EmployeeHandOveredStatusValue !== null) {
            trimmedEmployeeHandOveredStatusValue = EmployeeHandOveredStatusValue.trim();
            if (trimmedEmployeeHandOveredStatusValue.length > 255) {
                errors.employeeHandOveredSts = true;
                setEmployeeHandOveredStatusErrorMessage("Max 255 characters");
            }
            else {
                setEmployeeHandOveredStatusError(false);
                setEmployeeHandOveredStatusErrorMessage("");
            }
        }
             
    
        let trimmedEmployeeNoValue;
        if (EmployeeNoValue !== null) {
            trimmedEmployeeNoValue = EmployeeNoValue.trim();
            if (trimmedEmployeeNoValue.length > 255) {
                errors.employeeNo = true;
                setEmployeeNoErrorMessage("Max 255 characters");
            }
            else {
                setEmployeeNoError(false);
                setEmployeeNoErrorMessage("");
            }
        }


        let trimmedEmployeeReasonOfRelievingValue;

        if (EmployeeReasonOfRelievingValue !== null) {
            trimmedEmployeeReasonOfRelievingValue = EmployeeReasonOfRelievingValue.trim();
            if (trimmedEmployeeReasonOfRelievingValue.length > 255) {
                errors.employeeReasonOfRelieving = true;
                //console.log("trimmedEmployeeReasonOfRelievingValue.length",trimmedEmployeeReasonOfRelievingValue.length)
                setEmployeeReasonOfRelievingErrorMessage("Max 255 characters");
            }
            else {
                setEmployeeReasonOfRelievingError(false);
                setEmployeeReasonOfRelievingErrorMessage("");
            }
        }
   


        let trimmedLastDateValue
        if(LastDateValue !== null ){
            const dateString = LastDateValue.format(); // Assuming your `Dayjs` object has a `format()` method
            trimmedLastDateValue = dateString.trim();
             //console.log("trimmedLastDateValue",trimmedLastDateValue.length)
        }
        let FormatedLastDate;
        if (trimmedLastDateValue) {
            const trimmedDate = new Date(trimmedLastDateValue);
            const year = trimmedDate.getFullYear();
            const month = String(trimmedDate.getMonth() + 1).padStart(2, '0');
            const date = String(trimmedDate.getDate()).padStart(2, '0');
            FormatedLastDate = `${year}-${month}-${date}`;
            // Now you can use the formattedDate variable where needed
        }

    
        if (Object.keys(errors).length > 0) {
            // If there are errors, update the state with all the errors
            setEmployeeNameError(errors.employeeName || false);
            setEmployeeEmailError(errors.employeeEmail || false);
            setEmployeePasswordError(errors.employeePassword || false);
            setEmployeeMobileError(errors.employeeMobile || false);
            setEmployeeRoleError(errors.employeeRole || false);
            setEmployeeReportToWhomError(errors.employeeReportToWhom || false);
            setEmployeeDateOfJoiningError(errors.employeeDateOfJoining || false)
            setEmployeeDateOfRelievingError(errors.employeeDateOfRelieving || false)
            setEmployeeDesignationError(errors.employeeDesignation || false)
            setEmployeeDepartmentError(errors.employeeDesignation || false)//SOP-31 Included By PCS [Department included]
            setEmployeeWorkLocationError(errors.employeeWorkLocation || false)
            setEmployeeHandOveredStatusError(errors.employeeHandOveredSts || false)
            setEmployeeNoError(errors.employeeNo || false)
            setEmployeeReasonOfRelievingError(errors.employeeReasonOfRelieving || false)
            return;
        }

        let FormatedIsActive;
        if (isActive === true) {
            FormatedIsActive = 'Y'
        }
        else {
            FormatedIsActive = 'N'
        }



     

        const UpdateEmployeePayload = {

                // "SaveUpdateKey": "create",
                // // "TerminationId":1,
                // "CompanyId":CompanyId,
                // "EmployeeId":EmployeeId,
                // "LastDate":"2024-07-24",
                // "It":"N",
                // "Finance":"Y",
                // "Manager":"Y",
                // "HR":"Y",
                // "CreatedBy":112,
                // "TerminationId":0,
                "SaveUpdateKey": "create" ,
      
                // "TerminationId":1,
                "CompanyId": CompanyId,
                "EmployeeId": EmployeeId,
                "LastDate": FormatedLastDate,
                // "LastDate": "2024-07-24",
                "ItApproved": isITChecked ? 'Y' : 'N',
                "FinanceApproved": isFinanceChecked ? 'Y' : 'N',
                "ManagerApproved": isManagerChecked ? 'Y' : 'N',
                "HRApproved": isHRChecked ? 'Y' : 'N',
                "FinanceRemarks":FinanceRemarksValue,
                "ManagerRemarks":ManagerRemarksValue,
                 "HRRemarks":HRRemarksValue,
                "ItRemarks":ITRemarksValue,
                "CreatedBy": UserId,    
                // "LastModifiedBy": UserId
    
            }
    
           console.log("UpdateEmployeePayload",UpdateEmployeePayload)

            Swal.fire({
                html: '<span style="font-size: 1.3rem;">Are you sure to save ? </span>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                cancelButtonColor: '#d33',
                confirmButtonColor: "#25425f",
                allowOutsideClick: false,
            })
                .then((result) => {
                    if (result.isConfirmed) {

                        commonService
                            .postService(`${process.env.REACT_APP_BACKEND_URL}/HREmployeeTermination/employee_termination_create_update`, UpdateEmployeePayload)
                            .then((res) => {
                                if (res.status === 200) {
                                    //Success("Employee created successfully");
                                    Success("Termination updated");

                                    navigate('/HRDashBoard')
                                }
                            }).catch((error) => {
                                console.error("Error:", error);
                            });
    
                    }
                    else {
                        return
                    }
                });
        }












        //     const UpdateEmployeePayload = {
        //         SaveUpdateKey: 'update',
        //         EmployeeId: EmployeeId,
        //         EmployeeName: EmployeeNameValue,
        //         EmployeeMail: EmployeeEmailValue,
        //         EmployeeMobile: EmployeeMobileValue,
        //         EmployeePassword: GetEncryptedData,
        //         EmployeeRole: selectedEmployeeRoleId,
        //         CompanyId: CompanyId,
        //         Active: FormatedIsActive,
        //         CreatedBy: null,
        //         LastModifiedBy: UserId,
        //         IsRemove: null,
        //         ReportToWhom: selectedEmployeeReportToWhomId,
        //         DateOfJoining: FormatedJoiningDate,
        //         DateOfRelieving: FormatedRelievingDate,
        //         DesignationId: selectedEmployeeDesignationId,
        //         DepartmentId: selectedEmployeeDepartmentId,
        //         PhotoUrl: imageUrl,
        //         WorkLocation: EmployeeWorkLocationValue,
        //         HandOveredSts: EmployeeHandOveredStatusValue,
        //         EmployeeNo: EmployeeNoValue,
        //         ReasonOfRelieving: EmployeeReasonOfRelievingValue,
                
              
                
    
        //     }
    
        //     //console.log("UpdateEmployeePayload",UpdateEmployeePayload)

        //     Swal.fire({
        //         html: '<span style="font-size: 1.3rem;">Are you sure to save ? </span>',
        //         showCancelButton: true,
        //         confirmButtonText: 'Yes',
        //         cancelButtonText: 'No',
        //         cancelButtonColor: '#d33',
        //         confirmButtonColor: "#25425f",
        //         allowOutsideClick: false,
        //     })
        //         .then((result) => {
        //             if (result.isConfirmed) {

        //                 commonService
        //                     .postService(`${process.env.REACT_APP_BACKEND_URL}/employee/employee_create_update_delete`, UpdateEmployeePayload)
        //                     .then((res) => {
        //                         if (res.status === 200) {
        //                             //Success("Employee created successfully");
        //                             Success("Employee updated successfully");

        //                             navigate('/employee')
        //                         }
        //                     }).catch((error) => {
        //                         console.error("Error:", error);
        //                     });
    
        //             }
        //             else {
        //                 return
        //             }
        //         });
        // }



    // }

        // UseEffect declaration
        useEffect(() => {
            GetEmployeeListById();
            GetUserRoleList();
            GetEmployeeReportToWhomList();
            GetDesignationList();
            GetDepartmentList();
            
        }, []);
 

        return (
            <>
                <MainContainerWrapper headingText='Employee Termination'>
                    <Box >
                        <form noValidate>
                            <Box>
                                <Box>
                                    <Box>
                                        {/* Employee Information Accordian */}
                                        <Accordion sx={{
                                            borderRadius: '30px',
                                        }}>
                                            <AccordionSummary
                                                expandIcon={<ArrowDropDownIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                            
                                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                    <FcInfo height={'1rem'} width={'1rem'} fontSize={'1.5rem'} />
                                                    <Typography fontSize={'1rem'} ml={2}> Employee Information</Typography>
                                                </Box>
                            
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ marginBottom: 5 }}>
                                                <Box>
                                                    <Box>
                                                        <Box>
                                                            <Grid container spacing={3} display={'flex'} justifyContent={'center'}>
                                                                <Grid item lg={3.5} md={12} xs={12} >
                                                                    <Box>
                                                                        <TextField
                                                                            type='text'
                                                                            variant="standard"
                                                                            label='Employee Name'
                                                                            name='Employee'
                                                                            style={{ margin: '0 10px' }}
                                                                            fullWidth
                                                                            required
                                                                            error={EmployeeNameError}
                                                                            onChange={handleEmployeeNameChange}
                                                                            value={EmployeeNameValue}
                                                                            helperText={EmployeeNameErrorMessage}
                                                                            disabled
                                                                        />
                                                                    </Box>
                                                                </Grid>

                                                                <Grid item lg={3.5} md={12} xs={12} >
                                                                    <Box>
                                                                        <TextField
                                                                            type='text'
                                                                            variant="standard"
                                                                            label='Email'
                                                                            name='email'
                                                                            style={{ margin: '0 10px' }}
                                                                            fullWidth
                                                                            required
                                                                            error={EmployeeEmailError}
                                                                            onChange={handleEmployeeEmailChange}
                                                                            value={EmployeeEmailValue}
                                                                            helperText={EmployeeEmailErrorMessage}
                                                                            disabled
                                                                        />
                                                                    </Box>
                                                
                                                                </Grid>

                                                                <Grid item lg={3.5} md={12} xs={12} >
                                                                    <Box>
                                                                        <TextField
                                                                            type='text'
                                                                            variant="standard"
                                                                            label='Mobile Number'
                                                                            name='Employee'
                                                                            style={{ margin: '0 10px' }}
                                                                            fullWidth
                                                                            required
                                                                            error={EmployeeMobileError}
                                                                            onChange={handleEmployeeMobileChange}
                                                                            value={EmployeeMobileValue}
                                                                            helperText={EmployeeMobileErrorMessage}
                                                                            inputProps={{
                                                                                maxLength: 15
                                                                            }}
                                                                            disabled
                                                                        />
                                                                    </Box>
                                                                </Grid>

                                                                {/* <Grid item lg={5} md={12} xs={12} >
                                                                    <Box>
                                                                        <TextField
                                                                            type='password'
                                                                            variant="standard"
                                                                            label='Password'
                                                                            name='Employee'
                                                                            style={{ margin: '0 10px' }}
                                                                            fullWidth
                                                                            required
                                                                            error={EmployeePasswordError}
                                                                            onChange={handleEmployeePasswordChange}
                                                                            value={EmployeePasswordValue}
                                                                            helperText={EmployeePasswordErrorMessage}
                                                                            inputProps={{
                                                                                maxLength: 12
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                </Grid> */}

                                                            </Grid>
                                                        </Box>
                                                    </Box>
                                                </Box>

                                            </AccordionDetails>

                                        </Accordion>
                                        {/* End of Employee Information Accordian */}
                                    </Box>

                                    <Box mt={1}>
                                        {/* Employee Role Accordian */}
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ArrowDropDownIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                <Typography fontSize={'1rem'}>
                                
                                                </Typography>
                                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                    <FaUserGear height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} />
                                                    <Typography fontSize={'1rem'} ml={2}>Employee Role</Typography>
                                                </Box>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ marginBottom: 5 }}>
                                                <Box>
                                                    <Box>
                                                        <Box>
                                                            <Grid container spacing={3} display={'flex'} justifyContent={'center'}>
                                                                {EmployeeRoleEnable ?
                                                                    <Grid item lg={5.5} md={12} xs={12} >
                                                                        <Box>
                                                                            <Autocomplete
                                                                                id="combo-box-demo"
                                                                                options={EmployeeRoleOptions as OptionType[]}
                                                                                filterOptions={filterOptions}
                                                                                getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                                                value={EmployeeRoleOptions.find((option: OptionType) => option.value === selectedEmployeeRoleId) || null}
                                                                                onChange={(event, newValue) => {
                                                                                    if (newValue) {
                                                                                        setSelectedEmployeeRoleId(newValue.value);
                                                                                        setEmployeeRoleError(false);
                                                                                        setEmployeeRoleErrorMessage("");
                                                                                    } else {
                                                                                        setSelectedEmployeeRoleId('');
                                                                                        setEmployeeRoleError(true);
                                                                                        setEmployeeRoleErrorMessage("Role is required");
                                                                                    }
                                                                                }}
                                                                                sx={{ ml: 1.5 }}
                                                                                renderInput={(params) =>
                                                                                <TextField {...params}
                                                                                    error={EmployeeRoleError}
                                                                                    helperText={EmployeeRoleErrorMessage}
                                                                                    required label="Role"
                                                                                    variant="standard" />}
                                                                                    disabled
                                                                                />
                                                                        </Box>
                                                                    </Grid>
                                                                    : null}

                                                                <Grid item lg={5.5} md={12} xs={12} >
                                                                    <Box>
                                                                        <Autocomplete
                                                                            id="combo-box-demo"
                                                                            options={EmployeeReportToWhomListOptions as OptionType[]}
                                                                            filterOptions={filterOptions}
                                                                            getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                                            value={EmployeeReportToWhomListOptions.find((option: OptionType) => option.value === selectedEmployeeReportToWhomId) || null}
                                                                            onChange={(event, newValue) => {
                                                                                if (newValue) {
                                                                                    setSelectedEmployeeReportToWhomId(newValue.value);
                                                                                    setEmployeeReportToWhomError(false);
                                                                                    setEmployeeReportToWhomErrorMessage("");
                                                                                } else {
                                                                                    setSelectedEmployeeReportToWhomId('');
                                                                                    setEmployeeReportToWhomError(true);
                                                                                    setEmployeeReportToWhomErrorMessage("Reported to whom is required");
                                                                                }
                                                                            }}
                                                                            sx={{ ml: 1.5 }}
                                                                            renderInput={(params) =>
                                                                                <TextField {...params}
                                                                                    error={EmployeeReportToWhomError}
                                                                                    helperText={EmployeeReportToWhomErrorMessage}
                                                                                    required label="Reporting to whom"
                                                                                    variant="standard" />}
                                                                                    disabled
                                                                        />
                                                                    </Box>
                                                
                                                                </Grid>

                                                            </Grid>
                                                        </Box>
                                                    </Box>
                                                </Box>

                                            </AccordionDetails>

                                        </Accordion>
                                        {/* End of Employee Role Accordian */}

                                    </Box>
                                    <Box mt={1}>
                                        {/* Joining Details Accordian */}
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ArrowDropDownIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                    <BsBuildingsFill height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} />
                                                    <Typography fontSize={'1rem'} ml={2}> Joining Details</Typography>
                                                </Box>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ marginBottom: 5 }}>
                                                <Box >
                                                    <Box>
                                                        <Box>
                                                            <Grid container spacing={3} display={'flex'} justifyContent={'space-evenly'}>
                                                                <Grid item lg={5} md={12} xs={12} >
                                                                    <Box>
                                                                        <FormControl fullWidth>
                                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                <DatePicker
                                                                                    disabled
                                                                                    label="Date of Joining"
                                                                                    value={EmployeeDateOfJoiningValue ? dayjs(EmployeeDateOfJoiningValue) : null}
                                                                                    onChange={handleDateOfJoiningChange}
                                                                                    format="DD-MM-YYYY"
                                                                                    slotProps={{
                                                                                        textField: {
                                                                                            error: Boolean(EmployeeDateOfJoiningError),
                                                                                            //SOP-17 Inlcuded By PCS[Testing feedback Corrections- to set mandatory]
                                                                                            required: true
                                                                                        }
                                                                                    }}
                                                                                />

                                                                                {EmployeeDateOfJoiningError && (
                                                                                    <FormHelperText
                                                                                        error
                                                                                        id="standard-weight-helper-text-dob-login"
                                                                                    >
                                                                                        {EmployeeDateOfJoiningErrorMessage}
                                                                                    </FormHelperText>
                                                                                )}
                                                                                
                                                                            </LocalizationProvider>
                                                                        </FormControl>

                                                                    </Box>
                                                                </Grid>

                                   
                                                                <Grid item lg={2.3} md={12} xs={12} >
                                                                    <Box >
                                                                        <Autocomplete
                                                                            id="combo-box-demo"
                                                                            options={EmployeeDesignationOptions as OptionType[]}
                                                                            filterOptions={filterOptions}
                                                                            getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                                            value={EmployeeDesignationOptions.find((option: OptionType) => option.value === selectedEmployeeDesignationId) || null}
                                                                            onChange={(event, newValue) => {
                                                                                if (newValue) {
                                                                
                                                                                    setSelectedEmployeeDesignationId(newValue.value);
                                                                                    setEmployeeDesignationError(false);
                                                                                    setEmployeeDesignationErrorMessage("");
                                                                                } else {
                                                                                    setSelectedEmployeeDesignationId('');
                                                                                    setEmployeeDesignationError(true);
                                                                                    setEmployeeDesignationErrorMessage("Designation is required");
                                                                                }
                                                                            }}
                                                        
                                                                            renderInput={(params) =>
                                                                                <TextField {...params}
                                                                                    error={EmployeeDesignationError}
                                                                                    helperText={EmployeeDesignationErrorMessage}
                                                                                    required label="Designation"
                                                                                    variant="standard" />}
                                                                                    disabled
                                                                        />
                                                                    </Box>
                                                                </Grid>

                                  
                                                                <Grid item lg={2.1} md={12} xs={12} >
                                                                    <Box>
                                                                        <Autocomplete
                                                                            id="combo-box-demo"
                                                                            options={EmployeeDepartmentOptions as OptionType[]}
                                                                            filterOptions={filterOptions}
                                                                            getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                                            value={EmployeeDepartmentOptions.find((option: OptionType) => option.value === selectedEmployeeDepartmentId) || null}
                                                                            onChange={(event, newValue) => {
                                                                                if (newValue) {
                                                                                    setSelectedEmployeeDepartmentId(newValue.value);
                                                                                    setEmployeeDepartmentError(false);
                                                                                    setEmployeeDepartmentErrorMessage("");
                                                                                } else {
                                                                                    setSelectedEmployeeDepartmentId('');
                                                                                    setEmployeeDepartmentError(true);
                                                                                    setEmployeeDepartmentErrorMessage("Department is required");
                                                                                }
                                                                            }}
                                                        
                                                                            renderInput={(params) =>
                                                                                <TextField {...params}
                                                                                    error={EmployeeDepartmentError}
                                                                                    helperText={EmployeeDepartmentErrorMessage}
                                                                                    required label="Department"
                                                                                    variant="standard" />}
                                                                                    disabled
                                                                        />
                                                                    </Box>
                                                                </Grid>
                    
                                                                            
                                                                <Grid item lg={5} md={12} xs={12} >
                                                                    <Box>
                                                                        <TextField
                                                                            type='text'
                                                                            variant="standard"
                                                                            label='Work Location'
                                                                            name='workLocation'
                                                                            // style={{ margin: '0 10px' }}
                                                                            fullWidth
                                                                            required
                                                                            error={EmployeeWorkLocationError}
                                                                            onChange={handleEmployeeWorkLocationChange}
                                                                            value={EmployeeWorkLocationValue || ''}
                                                                            helperText={EmployeeWorkLocationErrorMessage}
                                                                            disabled
                                                                        />
                                                                    </Box>
                                                                </Grid>

                                                                <Grid item lg={5} md={12} xs={12} >
                                                                    <Box>
                                                                        <TextField
                                                                            type='text'
                                                                            variant="standard"
                                                                            label='Employee Number'
                                                                            name='EmployeeNo'
                                                                            // style={{ margin: '0 10px' }}
                                                                            fullWidth
                                                                            required={false}
                                                                            error={EmployeeNoError}
                                                                            onChange={handleEmployeeNoChange}
                                                                            value={EmployeeNoValue}
                                                                            helperText={EmployeeNoErrorMessage}
                                                                            disabled
                                                                        />
                                                                    </Box>
                                                                </Grid>

                                                            </Grid>
                                                        </Box>
                                                    </Box>
                                                </Box>

                                            </AccordionDetails>

                                        </Accordion>
                                        {/* End of Joining Details Accordian */}
                                    </Box>

                                    <Box mt={1}>
                                        {/* Joining Details Accordian */}
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ArrowDropDownIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                    <BsBuildingsFill height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} />
                                                    <Typography fontSize={'1rem'} ml={2}> Termination Details</Typography>
                                                </Box>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ marginBottom: 5 }}>
                                                <Box  sx={{
                                        ml:{
                                            xl:12,
                                            lg:10,
                                            md:0,
                                            xs:0
                                        }
                                    }}>
                                                  
                                                            <Grid container mb={5} spacing={5} >
                                                                <Grid item lg={4} md={12} xs={12}>
                                                                    <Box>
                                                                        <FormControl >
                                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                <DatePicker
                                                                                    // disabled
                                                                                    label="Last Date"
                                                                                    value={LastDateValue ? dayjs(LastDateValue) : null}
                                                                                    onChange={handleLastDateChange}
                                                                                    format="DD-MM-YYYY"
                                                                                    slotProps={{
                                                                                        textField: {
                                                                                            error: Boolean(LastDateError),
                                                                                            required: false
                                                                                        }
                                                                                    }}
                                                                                />

                                                                                {LastDateError && (
                                                                                    <FormHelperText
                                                                                        error
                                                                                        id="standard-weight-helper-text-dob-login"
                                                                                    >
                                                                                        {LastDateErrorMessage}
                                                                                    </FormHelperText>
                                                                                )}
                                                                                
                                                                            </LocalizationProvider>
                                                                        </FormControl>

                                                                    </Box>
                                                                </Grid>
                                                                <Grid item lg={1} md={12} xs={12} >
                                                                <Box>
                                                                    {/* <FormControlLabel
                                                                        sx={{ 
                                                                            fontSize:'3rem',
                                                                            color:'#2196F3' }}
                                                                        label="IT"
                                                                        onChange={(e)=>{
                                                                            // WarrantycheckBoxState(e)
                                                                        }}
                                                                        control={<Checkbox  
                                                                            sx={{color:'#2196F3',
                                                                            '&.Mui-checked': {
                                                                                color: '#2196F3',
                                                                            },
                                                                        }}/>}
                                                                    />    */}
                                                                

                                                                <TextField
                                                        type='text'
                                                        variant="standard"
                                                        label='IT Remarks'
                                                        name='IT Remarks'
                                                        fullWidth
                                                        multiline
                                                        onChange={handleItRemarksChange}
                                                        value={ITRemarksValue}
                                                        helperText={ITRemarksErrorMessage}
                                                        error={ITRemarksError}
                                                    /> 
                                                                   <FormControlLabel
                                                            sx={{ fontSize: '3rem', color: '#2196F3' }}
                                                            label="IT"
                                                            control={
                                                                <Checkbox
                                                                    checked={isITChecked}
                                                                    onChange={(e) => setIsITChecked(e.target.checked)}
                                                                    sx={{
                                                                        color: '#2196F3',
                                                                        '&.Mui-checked': {
                                                                            color: '#2196F3',
                                                                        },
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                                </Box> 
                                                                </Grid>
                                                                <Grid item lg={1.5} md={12} xs={12} >
                                                                <Box>
                                                                    {/* <FormControlLabel
                                                                        sx={{ 
                                                                            fontSize:'3rem',
                                                                            color:'#2196F3' }}
                                                                        label="Finance"
                                                                        onChange={(e)=>{
                                                                            // WarrantycheckBoxState(e)
                                                                        }}
                                                                        control={<Checkbox  
                                                                            sx={{color:'#2196F3',
                                                                            '&.Mui-checked': {
                                                                                color: '#2196F3',
                                                                            },
                                                                        }}/>}
                                                                    />    */}
                                                                

                                                                <TextField
                                                        type='text'
                                                        variant="standard"
                                                        label='Finance Remarks'
                                                        name='Finance Remarks'
                                                        fullWidth
                                                        multiline
                                                        onChange={handleFinanceRemarksChange}
                                                        value={FinanceRemarksValue}
                                                        helperText={FinanceRemarksErrorMessage}
                                                        error={FinanceRemarksError}
                                                    /> 
                                                                <FormControlLabel
                                                            sx={{ fontSize: '3rem', color: '#2196F3' }}
                                                            label="Finance"
                                                            control={
                                                                <Checkbox
                                                                    checked={isFinanceChecked}
                                                                    onChange={(e) => setIsFinanceChecked(e.target.checked)}
                                                                    sx={{
                                                                        color: '#2196F3',
                                                                        '&.Mui-checked': {
                                                                            color: '#2196F3',
                                                                        },
                                                                    }}
                                                                />
                                                            }
                                                        />

                                                                </Box> 
                                                                </Grid>
                                                                <Grid item lg={1.5} md={12} xs={12} >
                                                                <Box>
                                                                    {/* <FormControlLabel
                                                                        sx={{ 
                                                                            fontSize:'3rem',
                                                                            color:'#2196F3' }}
                                                                        label="Manager"
                                                                        onChange={(e)=>{
                                                                            // WarrantycheckBoxState(e)
                                                                        }}
                                                                        control={<Checkbox  
                                                                            sx={{color:'#2196F3',
                                                                            '&.Mui-checked': {
                                                                                color: '#2196F3',
                                                                            },
                                                                        }}/>}
                                                                    />    */}
                                                                         <TextField
                                                        type='text'
                                                        variant="standard"
                                                        label='Manager Remarks'
                                                        name='Manager Remarks'
                                                        fullWidth
                                                        multiline
                                                        onChange={handleManagerRemarksChange}
                                                        value={ManagerRemarksValue}
                                                        helperText={ManagerRemarksErrorMessage}
                                                        error={ManagerRemarksError}
                                                    />
                                                                 <FormControlLabel
                                                            sx={{ fontSize: '3rem', color: '#2196F3' }}
                                                            label="Manager"
                                                            control={
                                                                <Checkbox
                                                                    checked={isManagerChecked}
                                                                    onChange={(e) => setIsManagerChecked(e.target.checked)}
                                                                    sx={{
                                                                        color: '#2196F3',
                                                                        '&.Mui-checked': {
                                                                            color: '#2196F3',
                                                                        },
                                                                    }}
                                                                />
                                                            }
                                                        />


                                                                </Box> 
                                                                </Grid>
                                                                <Grid item lg={1.5} md={12} xs={12} >
                                                                <Box>
                                                                    {/* <FormControlLabel
                                                                        sx={{ 
                                                                            fontSize:'3rem',
                                                                            color:'#2196F3' }}
                                                                        label="HR"
                                                                        onChange={(e)=>{
                                                                            // WarrantycheckBoxState(e)
                                                                        }}
                                                                        control={<Checkbox  
                                                                            sx={{color:'#2196F3',
                                                                            '&.Mui-checked': {
                                                                                color: '#2196F3',
                                                                            },
                                                                        }}/>}
                                                                    />    */}
                                                                
                                                                <TextField
                                                        type='text'
                                                        variant="standard"
                                                        label='HR Remarks'
                                                        name='HR Remarks'
                                                        fullWidth
                                                        multiline
                                                        onChange={handleHRRemarksChange}
                                                        value={HRRemarksValue}
                                                        helperText={HRRemarksErrorMessage}
                                                        error={HRRemarksError}
                                                    /> 

                                                                <FormControlLabel
                                                            sx={{ fontSize: '3rem', color: '#2196F3' }}
                                                            label="HR"
                                                            control={
                                                                <Checkbox
                                                                    checked={isHRChecked}
                                                                    onChange={(e) => setIsHRChecked(e.target.checked)}
                                                                    sx={{
                                                                        color: '#2196F3',
                                                                        '&.Mui-checked': {
                                                                            color: '#2196F3',
                                                                        },
                                                                    }}
                                                                />
                                                            }
                                                        />

                                                                </Box> 
                                                                </Grid>
                                                            </Grid>
                                                   
                                                </Box>

                                            </AccordionDetails>

                                        </Accordion>
                                        {/* End of Joining Details Accordian */}
                                    </Box>
                                </Box>
                                <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Button
                                        sx={{
                                            background: 'white',
                                            border: 'none',
                                            borderRadius: '5px',
                                            padding: {
                                                lg: "0 70px",
                                                md: "0 70",
                                                xs: '0 50px'
                    
                                            },
                                            height: "3rem",
                                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                        }}

                                        onClick={() => {
                                            navigate('/HRDashBoard')
                                        }}
                                    >
                                        Back
                                    </Button>

                                    <Button
                                        sx={{
                                            background: '#25425F',
                                            border: 'none',
                                            borderRadius: '5px',
                                            padding: {
                                                lg: "0 70px",
                                                md: "0 70",
                                                xs: '0 50px'
                                            },
                                            "&:hover": {
                                                backgroundColor: '#25425F',
                                                color: 'white',
                                            },
                                            color: 'white',
                                            height: "3rem",
                                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                        }}
                                        type='submit'
                                        onClick={(event) => {
                                            UpdateEmployee(event);
                                        }}
                                    >
                                  
                                        Update
                                  
                                    </Button>
                                </Box>
                            </Box>
                        </form>
                    </Box>
                </MainContainerWrapper>
            </>
        )

    }

export default EmployeeTerminationPage;
