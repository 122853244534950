import {
  Autocomplete,
  Box,
  FormHelperText,
  // IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers";

export const InputField = ({
    autoFocus,
  fieldName,
  values,
  errors,
  handleChange,
  name,
  isRequired = true,
  type = "text",
  disabled = false,
  placeholder,
  Icon,
  adorament,
  position,
  handleIconClick,
  length,
  minRow = 0,
  multiline = false,
  label,
  BackgroundColor="",
  InputLabelProps,
  // size=""
}: InputFieldtypes) => {
  const theme = useTheme();
  return (
    <>
      <Box>
        {fieldName && (
          <Typography
            fontWeight={400}
            fontSize={"1rem"}
            color={theme.palette.text.dark}
          >
            {fieldName}
            {isRequired && <span style={{ color: "red" }}>*</span>}
          </Typography>
        )}
        <TextField
         autoFocus={autoFocus}
          InputLabelProps={InputLabelProps}
          variant="outlined"
          value={values || ""}
          name={name}
          onChange={handleChange}
          id="outlined-basic"
          sx={{
            width: "100%",
            //backgroundColor: theme.palette.background.paper,
             marginTop:"10px",
            backgroundColor:BackgroundColor||theme.palette.background.paper,
          }}
          // placeholder={placeholder ? placeholder : `${fieldName}`}
          inputProps={{
            maxLength: length,
          }}
          multiline={multiline}
          minRows={minRow}
          InputProps={
            adorament
              ? {
                  [adorament]: (
                    <InputAdornment
                      position={position ? position : "start"}
                      disablePointerEvents={handleIconClick ? false : true}
                    >
                      {/* <IconButton onClick={handleIconClick}> */}
                        <Icon  onClick={handleIconClick}  / >
                      {/* </IconButton> */}
                    </InputAdornment>
                  ),
                }
              : undefined
          }
          disabled={disabled}
          error={Boolean(errors)}
          type={type}
          label={label}
          required={isRequired}
          placeholder={placeholder}
          // size={size}
        />
      </Box>
      {errors && (
        <FormHelperText
          error
          id="standard-weight-helper-text-email-login"
          sx={{ mt: 0.3, p: 0 }}
        >
          {errors}
        </FormHelperText>
      )}
    </>
  );
};
type InputFieldtypes = {
  autoFocus?:any;
  fieldName?: string;
  values: string | number | null | undefined;
  handleChange?: (_: any) => void;
  errors?: any;
  name?: string;
  type?: string;
  isRequired?: boolean;
  disabled?: boolean;
  placeholder?: string;
  adorament?: "endAdornment" | "startAdornment";
  position?: "start" | "end";
  Icon?: any;
  handleIconClick?: any;
  length?: number;
  multiline?: boolean;
  minRow?: number;
  label?:string;
  InputLabelProps?:any
  // size?:""
  BackgroundColor? :string;
};



export const SelectField = ({
  fieldName,
  values,
  errors,
  handleChange,
  name,
  options,
  disabled = false,
  isRequired = true,
  placeholder = "",
}: SelectFieldtypes) => {
  const theme = useTheme();
  return (
    <>
      <Box>
        <Typography
          py="10px"
          fontWeight={400}
          fontSize={"1rem"}
          color={theme.palette.text.dark}
        >
          {fieldName}
          {isRequired && <span style={{ color: "red" }}>*</span>}
        </Typography>
        <TextField
          variant="outlined"
          id="filled-select-currency"
          name={name}
          select
          required={isRequired}
          onChange={handleChange}
          error={Boolean(errors)}
          sx={{ width: "100%" }}
          value={values || ""}
          disabled={disabled}
          InputLabelProps={{
            shrink: false, // Display placeholder
          }}
          label={!values ? placeholder : ""}
        >
          {!!options.length &&
            options.map((item: any) => (
              <MenuItem value={item.value} key={item.value}>
                {item.name}
              </MenuItem>
            ))}
        </TextField>
      </Box>
      {errors && (
        <FormHelperText error id="standard-weight-helper-text-email-login">
          {errors}
        </FormHelperText>
      )}
    </>
  );
};

type SelectFieldtypes = {
  fieldName: string;
  values: string | number | null | undefined;
  handleChange: (_: any) => void;
  errors: any;
  name: string;
  options: { value: string | number; name: string }[];
  disabled?: boolean;
  isRequired?: boolean;
  placeholder?: string;
};

// export const InputDatePicker = ({
//   fieldName,
//   values,
//   errors,
//   handleChange,
//   name,
//   isRequired = true,
//   disabled = false,
//   placeholder,
//   format,
// }: datePickerFiledType) => {
//   const theme = useTheme();

//   return (
//     <>
//       <Box>
//         {fieldName && (
//           <Typography
//             py="10px"
//             fontWeight={400}
//             fontSize={"1rem"}
//             color={theme.palette.text.dark}
//           >
//             {fieldName}
//             {isRequired && <span style={{ color: "red" }}>*</span>}
//           </Typography>
//         )}
//         <DatePicker
//           value={values || ""}
//           onChange={handleChange}
//           sx={{ width: "100%" }}
//           disabled={disabled}
//           slotProps={{
//             textField: {
//               name: name,
//               // helperText: errors,
//               error: Boolean(errors),
//             },
//           }}
//           format={format || "DD/MM/YYYY"}
//         />
//       </Box>
//       {errors && <FormHelperText error>{errors}</FormHelperText>}
//     </>
//   );
// };

// type datePickerFiledType = {
//   fieldName: string;
//   values: string | number | null | undefined;
//   handleChange?: (_: any) => void;
//   errors?: any;
//   name?: string;
//   isRequired?: boolean;
//   disabled?: boolean;
//   placeholder?: string;
//   format?: string;
// };

export const AutoCompleteField = ({
  fieldName,
  values,
  errors,
  handleChange,
  name,
  options,
  isRequired = true,
  disabled = false,
  label,
 
  
}: AutoCompleteFieldtypes) => {
  const theme = useTheme();
  return (
    <>
      <Box>
        <Typography
         
          fontWeight={400}
          fontSize={"1rem"}
          color={theme.palette.text.dark}
        >
        
        </Typography>
        <Autocomplete 
          ListboxProps={{ style: { maxHeight: 120 } }}
          value={values}
          // onChange={handleChange}
          sx={{
            width: "100%",
            paddingTop:"10px"
          
          }}
          onChange={(event: any, newValue: any) => {
            handleChange(newValue);
          }}
          id={name}
          className="auto_complete_default_height"
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              {option.name}
            </Box>
          )}
          options={options}
          renderInput={(params) => (
            <TextField
            label={label}
              {...params}
              error={Boolean(errors)}
              placeholder={fieldName}
              variant="outlined"
              required={isRequired}
            />
          )}
          disabled={disabled}
        />
      </Box>
      {errors && <FormHelperText error>{errors}</FormHelperText>}
    </>
  );
};

type AutoCompleteFieldtypes = {
  fieldName?: string;
  values: { value: string | number; name: string } | null;
  handleChange: (_: any) => void;
  errors: any;
  name: string;
  options: { value: string | number; name: string }[];
  isRequired?: boolean;
  
    disabled?: boolean;
  label:string
};



