// material-ui
import { Typography } from "@mui/material";
import menuItems from "../../../../../container/layout/menu-items";
// project imports
import NavGroup from "./NavGroup";

// ==============================|| SIDEBAR MENU LIST ||============================== //

// Included by sanjana SOP-30
interface MenuItem {
  id: string;
  type: string;
}
// End of SOP-30

const MenuList = () => {

  // Included by sanjana SOP-30
  //  Global data from session storage
  const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
  const GlobalData = JSON.parse(GlobalDataFromSession);
  console.log("GlobalData",GlobalData)
  let UserRole = GlobalData.GlobalUserRole
//To add menus items for side menu as pet the user
  let sideMenuAsPerTheUser: MenuItem[] = [];

  // Commented by sanjana SOP-52 to set dynamic menu
  // switch (UserRole) {
  //   case "Super Admin":
  //     sideMenuAsPerTheUser = menuItems.admin;
  //     break;
  //   case "Manager":
  //     sideMenuAsPerTheUser = menuItems.manager;
  //     break;
  //   case "IT Admin":
  //     sideMenuAsPerTheUser = menuItems.itadmin;
  //     break;
  //   case "End User":
  //     sideMenuAsPerTheUser = menuItems.enduser;
  //     break;
  //   default:
  //     break;
  // }
  // End of SOP-52

  // End of SOP-30


  // Commented by sanjan
  // const menuList = menuItems.admin;
  // Modified by sanjana SOP-30
  // const navItems = menuList.map((item: any) => {

  // Modified by sanjana SOP-52 to set dynamic menus 
  const menuList = menuItems.usermenu;
  console.log("usermenu",menuItems.usermenu)
  // const navItems = sideMenuAsPerTheUser.map((item: any) => {
    const navItems = menuList.map((item: any) => {
  // End of SOP-52
    // End of SOP-30
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};
export default MenuList

