// SOP-69 Included By PCS

import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    TextField,
    Grid,
   Divider,
    
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import deleteAlert from '../../component/HOC/deleteAlert';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import { CiFolderOn } from "react-icons/ci";
import { PiFolderOpenThin } from "react-icons/pi";

//Interface to define type of objects
    // [Document list]
       interface Document {
                documentid: number;
                document_title_name: string;
                docpoint_categoryname?: string;
                        }

    // [category list]
        interface Category {
                docpoint_categoryid: number;
                docpoint_categoryname: string;
                           }

        interface DocumentsByCategory {
                [categoryId: string]: Document[];
                                    }

    const DocPoint =()=> {

                // Use navigate
                const navigate = useNavigate();

                // Global data from session storage
                const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
                const GlobalData = JSON.parse(GlobalDataFromSession);
                let CompanyId = GlobalData.GlobalCompanyId
         
            //Initializing values
                const [DocumentsList, setDocumentsList] = useState<DocumentsByCategory>({});//Document List in First Grid
                const [FilteredDocumentsList, setFilteredDocumentsList] = useState<DocumentsByCategory>({});// To display filtered documents in first grid
                const [SearchValue, setSearchValue] = useState<string>('');//To seacrh the document by name[second grid]
                const [Category, setCategory] = useState<Category[]>([]); // To display categories in second grid

             //  To manage visibility of second grid content
               
             const [showDocumentDetailsContent, setshowDocumentDetailsContent] = useState(false);  
            //Initial values for Document details in second grid..
               
                const [DocumentValue, setDocumentValue] =  useState(0); //To set Document value
                const [DocumentName, setDocumentName] =  useState(''); 
                const [DocumentId, setDocumentId] =  useState(''); 
                const [VersionNumber, setVersionNumber] =  useState(''); 
                
                const [DateCreated, setDateCreated] =  useState(''); 
                const [Author, setAuthor] =  useState(''); 
                const [LastModified, setLastModified] = useState(''); 
                const [CategoryName, setCategoryName] = useState(null); 

             //To high light selected category
            
                const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
                const [isAllDocumentsSelected, setIsAllDocumentsSelected] = useState(false); // to select all documents
           
    //TO get documents based on selected category..

                const handleCategoryClick = (categoryId: any, categoryName: any) => {
                    setSearchValue('')//to remove from search field
                    setSelectedCategory(categoryId);
                    DocumentsForCategory(categoryId, categoryName);
                    sessionStorage.setItem("SelectedCategoryFromSession", categoryId); //setting session memory
                    sessionStorage.removeItem("SearchValueFromSession");//clearing session memory
                         };

            //To set values in session storage
                sessionStorage.setItem("DocumentTitleValue",DocumentName)
 
            //To get actual date(adding utc)
                const reverseDate = (dateStr:any) =>
                {
                    let date = new Date(dateStr);
                        date.setHours(date.getHours() + 5);
                        date.setMinutes(date.getMinutes() + 30);
                        const year = date.getUTCFullYear();
                        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
                        const day = String(date.getUTCDate()).padStart(2, '0');
                     // Construct the final string
                        return `${day}-${month}-${year} `;
                };

 
       // Category  List  API- to display in second grid
           const getCategoryList = async () => 
              {
                const CategoryPayload ={ CompanyId:CompanyId }
                try {
                        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/docpoint/get_docpoint_categories_list`,CategoryPayload);
                        setCategory(res.data.data)
                     } 
                catch (err) {
                    // console.log( "category error",err);
                             }
            };
    // EOF Category  List  API- to display in second grid

   // Document Details  API -
        const getDocumentDetailsById = async (documentid:any,versionNumber:any) => 
            {
                const Payload ={
                    DocumentId:documentid,
                    CompanyId:CompanyId,
                     //Included By PCS on 02/08/2024
                     VersionNumber:versionNumber
                                }
            //    console.log('po',Payload)
                  try {
                        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/docpoint/getDocumentDetails_by_id`,Payload);
                        const DocumentDataById=res.data.data[0];
                           setDocumentName(DocumentDataById.document_title_name)
                            setVersionNumber(DocumentDataById.document_version_number)//To get version number and use it to retrieve document
                            setDocumentId(DocumentDataById.documentid)
                            setAuthor(DocumentDataById.employee_name)
                            setCategoryName(DocumentDataById.docpoint_categoryname)

                                let createddateResult= DocumentDataById.createddatetime?reverseDate(DocumentDataById.createddatetime):''
                                setDateCreated(createddateResult)
                                                
                                let lastmodifieddateResult = DocumentDataById.lastmodifieddate?reverseDate(DocumentDataById.lastmodifieddate):''
                                setLastModified(lastmodifieddateResult)
                      } 
                    catch (err) {
                               console.log( "document details error",err);
                                }
                             
            };



    // Documents  List  API
        const getDocumentsList =()=>
         {
            const DocumentsPayload ={ CompanyId:CompanyId }
             commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/docpoint/get_docpoint_documents_list`, DocumentsPayload)  
                .then((res: any) => 
                { 
               
                const documentsByCategory = res.data.data.reduce((acc:any, document:any) => {
                const categoryName = document.docpoint_categoryname || '<unspecified category>';
                    if (!acc[categoryName]) {
                      acc[categoryName] = [];
                      }
                    acc[categoryName].push(document);
                    return acc;
                  }, {});
                setDocumentsList(documentsByCategory);
               
                }).catch((error) =>   
                { 
                    Failed(error.response.message);
                });
         }   

         
  // Display documents based on selected category

  const DocumentsForCategory = async (categoryId: number, categoryName: string) =>
     {
         const DocumentsPayload = { CompanyId: CompanyId, CategoryId: categoryId };
         try {
             const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/docpoint/getListOfDocumentsByCategoryId`, DocumentsPayload);
             const documentsByCategory = res.data.data.reduce((acc: DocumentsByCategory, document: Document) =>
                {
                        const category = document.docpoint_categoryname ||  '<unspecified category>';
                        if (!acc[category]) {
                        acc[category] = [];
                                        }
                        acc[category].push(document);
                        return acc;
                }, {});
               
                setDocumentsList(documentsByCategory);
            } 
        catch (error) {
             //Failed(response.message);
             }
    };

 useEffect(() => {

    const storedSearchValue :any= sessionStorage.getItem("SearchValueFromSession");
    const storedCategoryId:any = sessionStorage.getItem("SelectedCategoryFromSession");


    if ( storedSearchValue!=null) {
        // console.log('has search value')
         setSearchValue(storedSearchValue);
        //    console.log('Before removing SearchValueFromSession:', sessionStorage.getItem("SearchValueFromSession"));
           sessionStorage.removeItem("SearchValueFromSession"); //removing session memory after displaying records
        //    console.log('After removing SearchValueFromSession:', sessionStorage.getItem("SearchValueFromSession"));
                                    }
    
 
    if (storedCategoryId) {
        // console.log('has category value')
        setSelectedCategory(parseInt(storedCategoryId));
        DocumentsForCategory(parseInt(storedCategoryId), '');
        sessionStorage.removeItem("SelectedCategoryFromSession");//removing session memory after displaying records
                         } 

    else {
          getDocumentsList();
          }

    getCategoryList();
    
    
}, []);


useEffect(() => {
     if (SearchValue.trim() !== '') {
    
           const storedSearchValue :any= sessionStorage.getItem("SearchValueFromSession");
            getDocumentsList();
            sessionStorage.removeItem("SelectedCategoryFromSession");//to remove from memory
            setSelectedCategory(null)
            setIsAllDocumentsSelected(true);
            handleSearchChange({ target: { value: SearchValue } } as React.ChangeEvent<HTMLInputElement>);
             
    }
         getCategoryList();

    }, [DocumentsList]);
     

    // Handler for document name search 
        const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        {
            sessionStorage.removeItem("SelectedCategoryFromSession");//on search selection removing category selection
            const searchValue = event.target.value;
             setSearchValue(searchValue);
  
                 
            if (searchValue.trim() === '') {
                // Reset to show all documents when search is cleared
                getDocumentsList();
                setFilteredDocumentsList({});
                 }
            else
                {
                    // Filter documents based on search value

                    const filteredData = Object.keys(DocumentsList).reduce((acc, categoryName) => {
                        const filteredDocuments = DocumentsList[categoryName].filter((doc: Document) =>
                            doc.document_title_name.toLowerCase().includes(searchValue.toLowerCase())
                        );
                        if (filteredDocuments.length > 0) {
                            acc[categoryName] = filteredDocuments;
                        }
                        return acc;
                    }, {} as DocumentsByCategory);
        

                    setFilteredDocumentsList(filteredData);
       
                };
        }


        //To high light selected document
       const [selectedDocument, setSelectedDocument] = useState(null);

       const handleClick = (documentId:any) => {
                if (selectedDocument === documentId) {
                setSelectedDocument(null); // Deselect if already selected
                } 
                else {
                setSelectedDocument(documentId); // Select the clicked document
                }
                                            };     
                                            
                                            
//When 'All documents' folder is selected..
        const handleAllDocumentsClick = () => {
            setSearchValue('');
            setSelectedCategory(null);
            setIsAllDocumentsSelected(true);
            getDocumentsList(); // Reset to show all documents
            sessionStorage.removeItem("SelectedCategoryFromSession");//category value is cleared
            sessionStorage.removeItem("SearchValueFromSession");//search value is cleared
                                             };
                                

   return(
      <>
      <Grid container mt={1} spacing={3} display={'flex'} justifyContent={'center'} >
     {/* 1st half of grid starts */}                  
        <Grid item lg={8} md={12} xs={12} 
        sx={{ marginLeft:'2px', border: '2px solid gray',borderRadius: 1  }}>
        <Grid sx={{}}>
            <MainContainerWrapper 
            headingText='DocPoint'
            handleClick={() =>{}}
            buttonText=""
            isSearch={false}
            isCreateButton={false}>

            <Divider sx={{mt:3, border:'0.1px solid grey',backgroundColor:'white'}}/>
        <Box  sx={{ 
                justifyContent:"flex-start",
                maxHeight: '800px',  
                overflowY: 'auto' }} >

      {SearchValue.trim() === '' ? (
        Object.keys(DocumentsList).length === 0 ? (
            <Grid>
            <Typography sx={{overflow:'hidden'}} align="center"mt={2} variant='h3'  color='gray'>No Documents found</Typography>
            </Grid>
        ) : (
        Object.keys(DocumentsList).map((categoryName: any) => (
            <Grid item xs={12} key={categoryName} sx={{ marginBottom: '20px' }}>
                <Box key={categoryName}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 'bold', marginTop: '1px' }}>
                        {categoryName}
                    </Typography>
                    <Divider sx={{ border: '1px solid grey', mt: 1, mb: 1, width: '25%' }} />
                    <Box sx={{ flexWrap: 'wrap', textAlign: 'left' }}>
                        {DocumentsList[categoryName].length === 0 ? (
                            <Typography variant="body1" align="center"></Typography>
                        ) : (
                            DocumentsList[categoryName].map((documents: any) => (
                                <Box
                                    key={documents.documentid}
                                    p={1}
                                    sx={{
                                        width: '300px',
                                        margin: '1px',
                                        textAlign: 'left',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '0.9rem',
                                            color: 'blue',
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                            backgroundColor: selectedDocument === documents.documentid ? 'rgba(0,0,0,0.1)' : '', // Highlight color condition
                                        }}
                                        onClick={() => {
                                           
                                            setDocumentValue(documents.documentid);
                                            sessionStorage.setItem("DocumentId", documents.documentid);
                                            sessionStorage.setItem("VersionNumber", documents.document_version_number);
                                            sessionStorage.setItem("DocumentTitleValue", documents.document_title_name);
                                            getDocumentDetailsById(documents.documentid, documents.document_version_number);
                                            handleClick(documents.documentid);
                                            navigate(`/view_knowledgebase`);

                                        }}
                                    >
                                        {documents.document_title_name}
                                    </Typography>
                                </Box>
                            ))
                        )}
                    </Box>
                </Box>
            </Grid>
        ))
    )
  ) : ( 

                Object.keys(FilteredDocumentsList).map((categoryName:any) => (

                <Grid item xs={12} key={categoryName} sx={{ marginBottom: '20px' }}>
                    <Box key={categoryName}>
                        <Typography sx={{ fontSize: '18px', fontWeight: 'bold', marginTop: '1px' }}>
                        {categoryName}
                        </Typography>
                        <Divider sx={{ border: '1px solid grey', mt: 1, mb: 1 ,width:'25%'}} />
                        <Box  sx={{ textAlign:'left' }}>
                            {FilteredDocumentsList[categoryName].map((documents:any) => (
                                <Box
                                        key={documents.documentid}
                                        p={1}
                                        sx={{
                                        width: '300px',
                                        margin: '1px',
                                        textAlign: 'left',
                                        }}
                                >
                                    {/* <Tooltip title={`Double click to view document`} arrow> */}
                                    <Typography
                                        sx={{
                                            fontSize: '0.9rem',
                                            color: 'blue',
                                            textDecoration:'underline',
                                            cursor:'pointer',
                                            backgroundColor: selectedDocument === documents.documentid ? 'rgba(0,0,0,0.1)' : '', // Highlight color condition
                                        }}
                                        onClick={() => {
                                           
                                            setDocumentValue(documents.documentid)
                                            sessionStorage.setItem("DocumentId",documents.documentid)
                                            sessionStorage.setItem("VersionNumber",documents.document_version_number)
                                            sessionStorage.setItem("DocumentTitleValue",documents.document_title_name)
                                            getDocumentDetailsById(documents.documentid,documents.document_version_number);
                                            sessionStorage.setItem("SearchValueFromSession", SearchValue);
                                            handleClick(documents.documentid)
                                             navigate(`/view_knowledgebase`)
                                            }}
                                       
                                    > 
                                       {documents.document_title_name}
                                    </Typography>
                                    {/* </Tooltip> */}
                                </Box>
                         ))}
                        </Box>
                    </Box>
                </Grid>
            ))

        )} 
        </Box>
    </MainContainerWrapper>
    </Grid>
     </Grid>
         
{/* 2nd half of grid starts */}

        <Grid item lg={3} md={12} xs={12} sx={{ border: '2px solid gray', borderRadius: 1 }}>
                <Box mt={1}>
                   <Box display={'flex'}>
                        <TextField
                            type='text'
                            variant="standard"
                            label='Search'
                            name='Search'
                            style={{ width: '80%' }}
                            onChange={(e:any)=>{
                                setSearchValue('')
                                setSelectedCategory(null)
                                getDocumentsList();
                                handleSearchChange(e)
                               }}
                           
                            value={SearchValue}
                        />
                   </Box>
                </Box>

                <Divider sx={{mt:4, border:'0.1px solid grey',marginRight:'2px'}}/> 
     {/* Second grid visible true  */}
        <Grid item xs={12}  sx={{ marginBottom: '20px'}}>

                {!showDocumentDetailsContent && (
                   <Grid >
                    <Box display={'flex'} justifyContent={'center'} mt={1} alignItems={'center'}>
                           <Typography fontSize={'1rem'} >List of category(s)</Typography>
                    </Box>
                    <Box sx={{display:'flex',cursor:'pointer',marginTop:'5px' }} onClick={() => {
                  
                         handleAllDocumentsClick();
                        }}>
                        <span style={{ color: 'blue', marginLeft: '1px', height: '2.5rem', width: '2.5rem'}}>
                        {isAllDocumentsSelected ? (
                            <PiFolderOpenThin height={'100%'} width={'100%'} fontSize={'2rem'} />
                        ) : (
                            <CiFolderOn height={'100%'} width={'100%'} fontSize={'2rem'} />
                        )}
                        </span>
                        <Typography variant="body1" sx={{mt:1 }}>All Documents</Typography>
                    </Box>
                    </Grid>
                    
                     )}
             {/* //Document Details not visible  */}
                    {!showDocumentDetailsContent && (
                        <Grid sx={{
                                maxHeight: '800px', overflowY: 'auto'}}>
                         <Box >
                          {Category.map((category, index) => (
                            <Box
                                // key={index}
                                key={category.docpoint_categoryid}
                                sx={{
                                    
                                    cursor: 'pointer',
                                    width: '80%',
                                    marginBottom: '-10px',
                                    display:'flex',
                                    backgroundColor: selectedCategory === category.docpoint_categoryid ? 'rgba(0,0,0,0.1)' : '', // Highlight color condition

                                }}
                             onClick={() => {
                                setIsAllDocumentsSelected(false)
                                handleCategoryClick(category.docpoint_categoryid, category.docpoint_categoryname)
                                         }}

                            >
                        <span style={{ color: 'blue', marginLeft: '1px', height: '2.5rem', width: '2.5rem', marginTop: '10px' }}>
                                {selectedCategory === category.docpoint_categoryid ? (
                                <PiFolderOpenThin size={24} />
                                ) : (
                                    <CiFolderOn size={24} />
                                )}
                        </span>
                        <Typography variant="body1" sx={{ mt: 2 }}>{category.docpoint_categoryname}</Typography>
                         </Box>


                    ))}
                </Box>
                </Grid>
                 )} 
                  </Grid>

                {showDocumentDetailsContent  && (
                <Box >
                <Box>

        </Box>
                 </Box>
        
          )} 
        </Grid>
       
{/* 2nd half of grid ends */}
   </Grid> 
{/* Main grid ends  */}
    </>)}
  export default DocPoint;

  // EOF SOP-69 Included By PCS