// included by sanjana SOP-25 to create request an asset page
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
    TablePagination,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import { BiSolidCategory } from "react-icons/bi";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchIcon from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import Popover from '@mui/material/Popover';
import './RequestAnAsste.scss'
import AssetImg from '../../asset/images/Goods.png'
import AddRequestAnAssetPage from './AddRequestAnAsset';






const RequetAnAssetPage = () => {

    // Use navigate
    const navigate = useNavigate();

    // Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    //console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId
    let CreatedBy = GlobalData.GlobalUserId
    //console.log("CompanyId",CompanyId)


    const [CategoryOptions, setCategoryOptions] = useState<OptionType[]>([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>('');
    const [ SearchValue, setSearchValue] = useState('');
    const [ AssetData, setAssetData] = useState([]);
    const [AssetId, setAssetId] = useState('');

    // Dialouge box
    const [AddRequestOpen, setAddRequestOpen] = React.useState(false);

    // To open request an asset box
    const handleClickAddRequestOpen = (Assetid:any) => {
      setAddRequestOpen(true);
      setAssetId(Assetid)
    };
  
    // To close request an asset box
    const handleAddRequestClose = () => {
      setAddRequestOpen(false);
    };


    // to set autocomplete drop down vale and name as string
    type OptionType = {
        name: string;
        value: string;
    };
    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });


    // To get search value while typing in search text field
    const handleSearchChange =( event:any)=>{
        setSearchValue(event.target.value);
    }



       // To get category list to fill category dropdown
        const GetCatgoryList = async () => {
            const CategoryPayload ={
                CompanyId:CompanyId
            }
            //console.log("CategoryPayload",CategoryPayload)
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/getCategoryList`,CategoryPayload);
            let formattedCategory = res.data.data.map((item:any) => {
            const { categoryid, categoryname } = item;
            return {
                name: categoryname,
                value: categoryid,
            };
            });
            setCategoryOptions(formattedCategory)
        } catch (err) {
            //console.log( "co",err);
        }
        };

    // To get categories details as per the id
    const getRequestAnAssetList =(event:any)=>{
        event.preventDefault();
        //console.log("selectedCategoryId",selectedCategoryId)
        //console.log("CompanyId",CompanyId)
        const payload ={
            CategoryId:selectedCategoryId || null,
            CompanyId:CompanyId,
            AssetName:SearchValue,
        }
        //console.log("payload",payload)


        commonService
         .postService(`${process.env.REACT_APP_BACKEND_URL}/requestanasset/getRequestAnAssetList`, {
            CategoryId:selectedCategoryId || null,
            CompanyId:CompanyId,
            AssetName:SearchValue,
         })  
         .then((res: any) => 
         { 
            ////console.log("res",res)
            const RequestAnAsetData = res.data.data
            //console.log("RequestAnAsetData",RequestAnAsetData)
            setAssetData(res.data.data)
            
         }).catch((error) =>   
         { 
          Failed(error.response.message);
         });
    }  


        // UseEffect declaration
    useEffect(() => {
        GetCatgoryList();
        // GetSubCatgoryList();
        // getRequestAnAssetList();
      }, []);



    return(
        <>
        <MainContainerWrapper headingText='Request an asset'>
            <Box display={'flex'} justifyContent={'center'} >
                <Box bgcolor={'white'} 
                sx={{
                    width:{
                        xl:'70%',
                        lg:'90%',
                        md:'100%',
                        xs:'100%',

                    }
                }}
                padding={1.5} borderRadius={10}>
                    <Box 
                    bgcolor={'#E6F0FF'} 
                    boxShadow={"rgba(0, 0, 0, 0.16) 0px 1px 4px"}
                    sx={{
                        height:{
                            lg:60,
                            md:70
                        }
                    }}
                    height={60} borderRadius={10} display={'flex'} justifyContent={'center'} alignItems={'center'} >
                        <form noValidate style={{width:'100%'}}>
                        <Grid container display={'flex'} justifyContent={'space-around'}>
                            <Grid item lg={3} md={4} xs={4} ml={2} >
                                <Box >
                                <Autocomplete
                                    id="combo-box-demo"
                                    fullWidth
                                    options={CategoryOptions as OptionType[]}
                                    filterOptions={filterOptions}
                                    getOptionLabel={(option: unknown) => (option as OptionType).name}
                                    value={CategoryOptions.find((option: OptionType) => option.value === selectedCategoryId) || null}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            setSelectedCategoryId(newValue.value);
                                        } else {
                                            setSelectedCategoryId('');
                                        }
                                    }}
                                    // sx={{ width: 235, ml: 1 }}
                                    renderInput={(params) => 
                                    <TextField {...params}
                                        label="Category" 
                                        variant="standard" />}
                                />                            
                                </Box>
                            </Grid>

                            <Grid item lg={7} md={4} xs={4}  >
                                <Box  
                                >
                                    <Box display={'flex'}>
                                    <TextField
                                        type='text'
                                        variant="standard"
                                        label='Search'
                                        name='Search'
                                        fullWidth
                                        onChange={handleSearchChange}
                                        value={SearchValue}
                                    />
                                    <Box ml={3}>
                                        <IconButton
                                        sx={{
                                            mt:1,
                                            display:'flex',
                                            justifyContent:'center',
                                            alignItems:'center',
                                            bgcolor:'#0079FF',
                                            ":hover":{
                                                color:'#0079FF',
                                                bgcolor:'white'
                                            }
                                        }}
                                        type='submit'
                                        onClick={(event)=>{
                                            getRequestAnAssetList(event);
                                        }}
                                        >
                                        <SearchIcon sx={{
                                            fontSize:'1.5rem',
                                            display:'flex',
                                            justifyContent:'center',
                                            alignItems:'center',
                                            cursor:'pointer',
                                            color:'white',
                                            ":hover":{
                                                color:'#0079FF',
                                            }
                                        }}
                                        />
                                        </IconButton>
                                    </Box>
                                    </Box>

                                    

                                </Box>                            
                            </Grid>
                        </Grid>                            
                        </form>
                        
                    </Box>
                </Box>
            </Box>
            <Box mt={5} display={'flex'} justifyContent={'center'} >
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }} >
            {AssetData.map((AssetData) => {
                return (
                <Box className="flip-box" p={1}  
                onClick={() => {                    
                    handleClickAddRequestOpen(AssetData["assetid"]);
                }}
                sx={{cursor:'pointer'}}
                >
                    <Box className="flip-box-inner" >
                        <Box className='flip-box-front' borderRadius={3}> 
                        <Box style={{width:'200px', height:'200px'}}>
                            <img
                                style={{
                                    borderRadius: "50%",
                                    height: "50px",
                                    width: "50px",
                                    textAlign: "center",
                                    marginTop:'1rem',
                                    marginRight:'1.5rem'
                                }}
                                src={AssetImg}  
                                alt="" 
                                />
                                <Typography sx={{ fontSize: '1rem',color:'black',mt:1,
                                marginRight:'1.5rem',
                                // fontWeight:'900' 
                                }} >
                                {AssetData["assetname"]}
                                </Typography>
                                <Typography sx={{ fontSize: '1rem',color:'#0079FF',mt:1,
                                marginRight:'1.5rem',
                                // fontWeight:'900' 
                                }} >
                                {AssetData["categoryname"]}
                                </Typography>
                        </Box>
                            
                        </Box>

                        <Box className="flip-box-back" borderRadius={3}>
                            <Typography sx={{ fontSize: 14,mt:1,color:'#25425f'}} >
                               Model Number
                            </Typography>
                            <Typography sx={{ fontSize: 14,color:'#25425f', fontWeight:'bold'}} >
                               {AssetData["modelnumber"]}
                            </Typography>
                            <Typography sx={{ fontSize: 14,mt:2,color:'#25425f'}} >
                               Serial Number
                            </Typography>
                            <Typography sx={{ fontSize: 14,color:'#25425f', fontWeight:'bold'}} >
                               {AssetData["serialnumber"]}
                            </Typography>
                            <Typography sx={{ fontSize: 14,mt:2,color:'#25425f'}} >
                                Manufacturer
                            </Typography>
                            <Typography sx={{ fontSize: 14,color:'#25425f', fontWeight:'bold'}} >
                               {AssetData["manufacturer"]}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                );
            })}        
            </Box>

            </Box>

            <Box display={'flex'} justifyContent={'center'} >
                    <Dialog
                    open={AddRequestOpen}
                    >
                        <Box>
                            <AddRequestAnAssetPage handleAddRequestClose={handleAddRequestClose}
                            AssetId={AssetId}/>
                            
                        </Box>

                    </Dialog>
                </Box>

                {/* Included by sanjana SOP-28 to add back button  */}
                <Box mt={5} display={'flex'} justifyContent={'flex-start'}>
                                    <Button
                                        sx={{
                                            background: 'white',
                                            border: 'none',
                                            borderRadius: '5px',
                                            padding:{
                                                lg: "0 70px",
                                                md: "0 70",
                                                xs:'0 50px'
                    
                                            },
                                            // color: {accent},
                                            height: "3rem",
                                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                        }}

                                        onClick={()=>{
                                            navigate('/home')
                                        }} 
                                    >
                                        Back
                                    </Button>
                    </Box>
                {/* End of SOP-28  */}

        </MainContainerWrapper>        
        </>
    )

}

export default RequetAnAssetPage;

// End of SOP-25