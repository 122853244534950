// SOP-61 Included By PCS

// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
     Grid,
    Paper,
    Divider,
    Checkbox,
    FormControlLabel,
    Tooltip,
    FormControl,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Success } from '../../atic-common-helpers/helpers/toast.helper';
import Swal from "sweetalert2";


// Page start
const AddManageAccess = 
// To get handleAddUserAccessClose & getdocumentlistsearch from department page
    ({
        handleAddUserAccessClose,
        getdocumentlistsearch
    }: {
        handleAddUserAccessClose: (event: React.MouseEvent) => void,
        getdocumentlistsearch: (event: React.MouseEvent) => void
    }) =>{

    // use navigate and use state declaration
    const navigate = useNavigate();


    const [UserIdListOptions, setUserIdListOptions] = useState<OptionType[]>([]);
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const [UserIdError, setUserIdError]=useState(false)
    const [UserIdErrorMessage, setUserIdErrorMessage]=useState('')

    const [checkboxError, setCheckboxError] = useState(false);
    const [DocumentId, setDocumentId] = useState('');
       
    const GlobalDataFromSession = sessionStorage.getItem("GlobalVariable") || '{}';
    const DocumentIdFromSession = sessionStorage.getItem("DocumentId") || '';

    const GlobalData = JSON.parse(GlobalDataFromSession);
    let CompanyId = GlobalData.GlobalCompanyId;
  
    const [editChecked, setEditChecked] = useState<boolean>(false);
    const [deleteChecked, setDeleteChecked] = useState<boolean>(false);


      // Exist UserId for documentid and companyid
      const GetUserIdExists = async () => {   
        
        const  ExistUserIdPayload = {

            UserId:selectedUserId,
            DocumentId: parseInt(DocumentId, 10),
            DocUserrightsId:null,
            CompanyId:CompanyId
                                     }

        // console.log('ExistUserIdPayload',ExistUserIdPayload)
                try {
                const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/docpoint/get_doc_userrights_name_exists`,ExistUserIdPayload);
                // console.log('the data',res.data.data);
                return res.data.data || [];
                } catch (err) {
                console.log( "co",err); 
                }
                                            };

     // To render handle close function to close dialouge box
        const handleClose = (event: React.MouseEvent) => {
            if (handleAddUserAccessClose && typeof handleAddUserAccessClose === 'function') {
                handleAddUserAccessClose(event);
            }
        };

    // to render getdocumentlistsearch function after closing dialouge box
            const handlegetdocumentlistsearch= (event: React.MouseEvent) => {
                if (getdocumentlistsearch && typeof getdocumentlistsearch === 'function') {
                    getdocumentlistsearch(event);
                }
            };
    
        type OptionType = {
            name: string;
            value: string;
            employee_name:string;
            delete:number;
            edit:number;
        };

    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });


        // User role Dropdown API
    const GetUserList = async () =>
       {
             const UsersListPayload ={
                CompanyId:CompanyId,
                active:'Y'
                                     }
    
            try {
                const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/docpoint/getUsersList`,UsersListPayload);
                // console.log("Users List" ,res.data.data);
                let formattedUserList = res.data.data.map((item:any) => {
                const { userid, fullname } = item;
                return {
                    name: fullname,
                    value: userid,
                };
                });
                 setUserIdListOptions(formattedUserList)
            } catch (err) {
                console.log( "co",err);
            }
            };

            type Errors = {
                userId?:boolean;
               
            };

            const handleAdd = async (event:any)=> {
                
                event.preventDefault();
                let errors: Errors = {};
                

                const UserIdList = await GetUserIdExists();
                if (UserIdList.length > 0) {
                    errors.userId = true;
                    setUserIdError(true);
                    setUserIdErrorMessage("User rights already exists for the document");
                    return;
                }
         
                if (selectedUserId===''||selectedUserId===null ||selectedUserId===undefined ) {
                    errors.userId = true;
                    setUserIdError(true);
                    setUserIdErrorMessage("Please select an employee.");
                    return;
                }
                   setUserIdError(false); // Reset error if validation passes

                   if (!editChecked && !deleteChecked) {
                    setCheckboxError(true); // Show error for checkboxes
                       return;
                       }

                   setCheckboxError(false); // Reset checkbox error
                
             

                if (Object.keys(errors).length > 0) {
                    // If there are errors, update the state with all the errors
                    setUserIdError(errors.userId || false);
                 
                    return;
                }
                const AddUserRightsPayload = {
                    SaveUpdateKey:'create',
                    DocumentId: parseInt(DocumentId, 10), 
                    CompanyId:CompanyId,
                    UserId:selectedUserId,
                    EditValue:editChecked?1:0,
                    DeleteValue:deleteChecked?1:0,
                    IsRemove: null
                   
                                          }
        //  console.log("Payload", AddUserRightsPayload);

       
            Swal.fire({
                html: '<span style="font-size: 1.3rem;">Are you sure to save ? </span>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                cancelButtonColor: '#d33',
                confirmButtonColor:"#25425f",
                allowOutsideClick: false,
                })
                .then((result) =>
                {
                if (result.isConfirmed)
                {
                    
                    commonService
                    .postService(`${process.env.REACT_APP_BACKEND_URL}/docpoint/manageaccess_userrights_create_update_delete`, AddUserRightsPayload)
                    .then((res) => {
                    if (res.status === 200) {
                        Success("User Rights created successfully");
                        handleClose(event);
                        handlegetdocumentlistsearch(event);
                    }
                    }).catch((error) => {
                        console.error("Error:", error);
                    });

                    } 
                    else  
                    {
                    return
                    }
                    });

        }
   
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === 'Enter') {
                        event.preventDefault();
                    }
                };      

     

        const handleCheckboxChange = (type: 'edit' | 'delete', checked: boolean) => {
            if (type === 'edit') {
                setEditChecked(checked);
            } else {
                setDeleteChecked(checked);
            }

            if (checked) {
                setCheckboxError(false); // Clear checkbox error if any checkbox is selected
            }
        };
  

      // UseEffect Declaration
      useEffect(() => {
        GetUserList();
        setDocumentId(DocumentIdFromSession);
        
      }, [DocumentIdFromSession]);

    return(
        <>
            <Box>
                <Box display={'flex'} justifyContent={'center'}>
                    <Box bgcolor={'white'} borderRadius={3}
                    sx={{
                        overflow: 'hidden', // Add this line
                        width:{
                            lg:900,
                            md:600,
                            xs:700
        
                        }}}
                    >
                    <Box p={3}>
                        <Typography variant='h2'>
                        Add User rights
                        </Typography>
                    </Box>
                    <form  noValidate>
                        <Box p={3}>
                            
                            <Grid container spacing={2} display={'flex'} justifyContent={'space-evenly'}>
                                        <Grid item lg={4} md={6} xs={12} >
                                        <Autocomplete
                                                        id="combo-box-demo"
                                                        options={UserIdListOptions as OptionType[]}
                                                        filterOptions={filterOptions}
                                                        getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                        value={UserIdListOptions.find((option: OptionType) => option.value === selectedUserId) || null}
                                                        onChange={(event, newValue) => {
                                                            if (newValue) {
                                                                setSelectedUserId(newValue.value);
                                                                setUserIdError(false);
                                                                setUserIdErrorMessage("");
                                                            } else {
                                                                setSelectedUserId('');
                                                                setUserIdError(true);
                                                                setUserIdErrorMessage("Please select an employee.");
                                                            }
                                                        }}
                                                        sx={{ml: 1.5 }}
                                                        renderInput={(params) => 
                                                        <TextField {...params}
                                                        error={UserIdError}
                                                        helperText={UserIdErrorMessage}
                                                        required label="User Name" 
                                                        variant="standard" />}
                                                  />
                                        </Grid>
                                        <Grid item lg={3} md={6} xs={12} mt={1}>
                                        <FormControl error={checkboxError} sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <FormControlLabel 
                                                control={
                                                    <Checkbox
                                                        checked={editChecked}
                                                        onChange={(e) => handleCheckboxChange('edit', e.target.checked)}
                                                        
                                                       
                                                    />
                                                }
                                                label="Edit"
                                            />
                                             {checkboxError && !deleteChecked && (
                                                <Typography sx={{ color: 'red', fontSize: '12px', ml: 1 }}> Select access.</Typography>
                                            )}
                                            </FormControl>
                                        </Grid>

                                        <Grid item lg={3} md={6} xs={6} mt={1}>
                                        <FormControl error={checkboxError} sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={deleteChecked}
                                                        onChange={(e) => handleCheckboxChange('delete', e.target.checked)}
                                                       
                                                       
                                                    />
                                                }
                                                label="Delete"
                                                
                                            />
                                             </FormControl> 
                                        </Grid>
                                    </Grid>
                                    </Box>
                            <Box mt={4} display={'flex'} mb={1} alignItems={'center'} justifyContent={'space-between'}>
                             <Button
                                    sx={{
                                        marginLeft:'15px',
                                        background: 'white',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70px",
                                            xs:'0 50px'
                
                                        },
                                        
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}

                                     onClick={handleClose} 
                                   
                                >
                                    Back
                                </Button>
                              
                                <Button
                                    sx={{
                                        marginRight:'15px',
                                        background: '#25425F',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70px",
                                            xs:'0 50px'
                
                                        },
                                        "&:hover":{
                                            backgroundColor:'#25425F',
                                            color:'white',
                                        },
                                        color: 'white',
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}
                                    type='submit'
                                    onClick={(event)=>{
                                        handleAdd(event);
                                    }}
                                >
                                    Save
                                </Button>
                             
                                </Box>
                                
                            </form>
                        </Box>

                    </Box>
                </Box>
            {/* </Box> */}
        </>
    )

}

export default AddManageAccess;

// EOF SOP-61 Included By PCS