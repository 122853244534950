// Included by sanjana SOP-15

// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Success } from '../../atic-common-helpers/helpers/toast.helper';

//SOP-15 -Included By PCS Testing Feedback Corrections [to avoid multiple submission]
import Swal from "sweetalert2";
// EOF SOP-15 -Included By PCS Testing Feedback Corrections 

// Page start
const AddCategoriesPage = 
// To get handleAddCategoriesClose & getcategorieslistsearch from catgories page
    ({
        handleAddCategoriesClose,
        getcategorieslistsearch
    }: {
        handleAddCategoriesClose: (event: React.MouseEvent) => void,
        getcategorieslistsearch: (event: React.MouseEvent) => void
    }) =>{

    // use navigate and use state declaration
    const navigate = useNavigate();

    const [CategoriesError, setCategoriesError]=useState(false)
    const [CategoriesValue, setCategoriesValue]=useState('')
    const [CategoriesErrorMessage, setCategoriesErrorMessage]=useState('')
    const [CompanyId, setCompanyId] =useState('')
    const [CreatedBy, setCreatedBy] =useState('')

     //SOP-15 Included By PCS[Testing feedback corrections- to avoid duplicate category name]
     const [CategoriesValueEndTrim, setCategoriesValueEndTrim]=useState('')
     //EOF SOP-15 Included By PCS[Testing feedback corrections- to avoid duplicate category name]

    //  Handle Category while typing category and validations
    const handleCategoriesChange = (event:any) => {
         const trimmedCategoriesValue = event.target.value.trim();
        setCategoriesValue(event.target.value);

        //SOP-15 Included By PCS[Testing feedback corrections - to avoid duplicate category name]
        setCategoriesValueEndTrim(trimmedCategoriesValue);
        //EOF SOP-15 Included By PCS[Testing feedback corrections]


        if (trimmedCategoriesValue === '') {
            setCategoriesError(true);
            setCategoriesErrorMessage("Category is required");
        } 
        else if( trimmedCategoriesValue.length >225 ){
            setCategoriesError(true);
            setCategoriesErrorMessage("Max 255 characters");
        }
        else {
            setCategoriesError(false);
            setCategoriesErrorMessage("");
        }
    };

    // Exist Category name API
    const GetCategoryNameExist = async () => {   
        
        const  ExistCategoryNamePayload = {

             //SOP-15 Modified By PCS[Testing feedback corrections-to avoid duplicate category]
             // ExistCategoryName:CategoriesValue,
               ExistCategoryName:CategoriesValueEndTrim,
             //EOF SOP-15 Modified By PCS[Testing feedback corrections-to avoid duplicate category]
            CategoryId:null,
            CompanyId:CompanyId
        }
        try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/get_category_name_exists`,ExistCategoryNamePayload);
        //console.log(res.data.data);
        return res.data.data || [];
        } catch (err) {
        console.log( "co",err); 
        }
    };



    // To render handle close function to close dialouge box
    const handleClose = (event: React.MouseEvent) => {
        if (handleAddCategoriesClose && typeof handleAddCategoriesClose === 'function') {
            handleAddCategoriesClose(event);
        }
    };

    // to render getcategorieslistsearch function after closing dialouge box
    const handlegetcategorieslistsearch= (event: React.MouseEvent) => {
        if (getcategorieslistsearch && typeof getcategorieslistsearch === 'function') {
            getcategorieslistsearch(event);
        }
    };

    // To add categories

     // Define a type for errors
     type Errors = {
        categories?:boolean;
    };
    const HandleAddCategories = async (event:any)=>{
         event.preventDefault();
    
        let errors: Errors = {};

        const CategoryNameList = await GetCategoryNameExist();
        if (CategoryNameList.length > 0) {
            setCategoriesError(true);
            setCategoriesErrorMessage("Category is already exists");
            errors.categories = true;
            return;
        }
    
        const trimmedCategoriesValue = CategoriesValue.trim();
        if (trimmedCategoriesValue === '') {
            errors.categories = true;
            setCategoriesErrorMessage("Category is required");
        } else if (trimmedCategoriesValue.length > 225) {
            errors.categories = true;
            setCategoriesErrorMessage("Max 255 characters");
        }
    
        if (Object.keys(errors).length > 0) {
            // If there are errors, update the state with all the errors
            setCategoriesError(errors.categories || false);
            return;
        }
    
        const todayDate = new Date();
        const Year = todayDate.getFullYear();
        const Month = (todayDate.getMonth() + 1).toString().padStart(2, '0');
        const Day = todayDate.getDate().toString().padStart(2, '0');
        const FormattedDate = `${Year}-${Month}-${Day}`;
       // console.log("FormattedDate",FormattedDate)
    
        const AddCategoriesPayload = {
            SaveUpdateKey: "create",
            CategoryName: CategoriesValue,
            CategoryId: null,
            CompanyId: CompanyId,
            Active: "Y",
            CreatedDateTime: null,
            CreatedBy: CreatedBy,
            IsRemove: null
        }
    
        //console.log("AddPL", AddCategoriesPayload);

        //SOP-15 Included By PCS[Testing feedack corrections- to avoid multiple toaster msg]
          
          Swal.fire({
                html: '<span style="font-size: 1.3rem;">Are you sure to save ? </span>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                cancelButtonColor: '#d33',
                confirmButtonColor:"#25425f",
                allowOutsideClick: false,
                })
                .then((result) =>
                {
                if (result.isConfirmed)
                {

        //EOF SOP-15 Included By PCS[Testing feedack corrections - to avoid multiple toaster msg]  

        commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/master/categories_create_update_delete`, AddCategoriesPayload)
        .then((res) => {
        if (res.status === 200) {
            Success("Category created successfully");
            handleClose(event);
            handlegetcategorieslistsearch(event);
        }
        }).catch((error) => {
            console.error("Error:", error);
        });


        //SOP-15 Included By PCS[Testing feedack corrections- to avoid multiple toaster msg]

                } 
                else  
                {
                return
                }
            });

        //EOF SOP-15 Included By PCS[Testing feedack corrections-to avoid multiple toaster msg]  
    }
   

    // UseEffect Declaration
    useEffect(() => {
  
        const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
        const GlobalData = JSON.parse(GlobalDataFromSession);
        //console.log("GlobalData",GlobalData)
        setCompanyId(GlobalData.GlobalCompanyId)
        setCreatedBy(GlobalData.GlobalUserId)
      }, []);
     

//SOP-15 Testing feedback corrections[To restrict form submission only on button]

 const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

//SOP-15 Testing feedback corrections[To restrict form submit only on button]

    return(
        <>
         <Box>
                <Box display={'flex'} justifyContent={'center'}>
                    <Box bgcolor={'white'} borderRadius={3}
                    sx={{
                        width:{
                            lg:900,
                            md:600,
                            xs:700
        
                        }}}
                    >
                    <Box p={3}>
                        <Typography variant='h1'>
                        Add Category
                        </Typography>
                    </Box>
                        <Box p={3}>
                            <form  
                           
                            noValidate>
                                <Box>
                             
                                <TextField

                                    onKeyDown={handleKeyDown} //SOP-15 [Testing feedback correction- to restrict submission only on button]
                                    //SOP-15 Inlcuded By PCS[Testing feedback Correction- to set auto focus]
                                    autoFocus
                                    //EOF SOP-15 Included By PCS [Testing feedback Correction- to set autofocus]
                                    type='text'
                                    variant="standard"
                                    label='Category'
                                    name='Categories'
                                    style={{ margin: '0 10px' }}
                                    fullWidth
                                    required
                                    error={CategoriesError}
                                    onChange={handleCategoriesChange}
                                    value={CategoriesValue}
                                    helperText={CategoriesErrorMessage}
                                /> 
                              
                                </Box>
                               
                            <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Button
                                    sx={{
                                        background: 'white',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        // color: {accent},
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}

                                    onClick={handleClose} 
                                >
                                    Back
                                </Button>

                                <Button
                                    sx={{
                                        background: '#25425F',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        "&:hover":{
                                            backgroundColor:'#25425F',
                                            color:'white',
                                        },
                                        color: 'white',
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}
                                     type='submit'

                                    onClick={(event)=>{
                                        HandleAddCategories(event);
                                    }}
                                >
                                    Save
                                </Button>
                                </Box>
                            </form>
                        </Box>

                    </Box>
                </Box>
            </Box>
           
        </>
    )

}

export default AddCategoriesPage;

// End of SOP-15