//SOP-38 - TS
//Asset Management -Dashboard - Ticketing

//Tickets Status Page
import { PieChart} from '@mui/x-charts/PieChart';
import  { useState, useEffect } from "react";
import { commonService } from "../../atic-common-helpers/helpers/common.service";



const  TicketsStatus=()=> {
   const [CompanyId, setCompanyId] =useState('')
  const [CreatedBy, setCreatedBy] =useState('')
  const [TicketsStatusY, setTicketsStatusY] = useState(0);
  const [TicketsStatusN, setTicketsStatusN] = useState(0);
    // console.log("TicketsStatusY", TicketsStatusY)
    // console.log("TicketsStatusN",TicketsStatusN)




  useEffect(() => 
    {
      const TicketsStatus = async () => {
        try {
        //Global data
        const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
        const GlobalData = JSON.parse(GlobalDataFromSession);
        setCompanyId(GlobalData.GlobalCompanyId);
        setCreatedBy(GlobalData.GlobalUserId);
  
        // TicketsStatus Payload
        const TicketsStatusPayload = {
          companyid: GlobalData.GlobalCompanyId,
        };
  
        // API    
        const response = await commonService
          .postService(`${process.env.REACT_APP_BACKEND_URL}/dashboardController/getTicketStatusCount`, TicketsStatusPayload);
        
          console.log("TicketsStatus",response)
          const TicketsStatusY = response.data.data[0]._ticket_completionstatus_y;
          setTicketsStatusY(TicketsStatusY);
          const TicketsStatusN = response.data.data[0]._ticket_completionstatus_n;
          setTicketsStatusN(TicketsStatusN);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    TicketsStatus();
  }, []);


 return (
  <PieChart
  series={[
            {
            data: [

                  //SOP-77 feedback points  Modified By PCS [Including Count]
                    // { id: 0, value: TicketsStatusY,label: 'COMPLETED' },
                    // { id: 1, value: TicketsStatusN, label: 'PENDING' },

                    { id: 0, value: TicketsStatusY, label: `COMPLETED (${TicketsStatusY})` },
                    { id: 1, value: TicketsStatusN, label: `PENDING (${TicketsStatusN})` },
                  //EOF SOP-77 feedback points  Modified By PCS
            ],
    }
  ]}
     width={400}
     height={200}

     sx={{
       "& text": {
         fontSize: '10px',          
      },
      padding: 0.80,  // SOP-77 feedback points Included by PCS [Adjust padding ]
    }}
   />

  )}
export default TicketsStatus





