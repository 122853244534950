// Included by sanjana SOP-34 to create Allocate ticket page
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import deleteAlert from '../../component/HOC/deleteAlert';
import dayjs from 'dayjs';

const AllocateTicketPage = // To get handleAddClose & getPendingTicketsList from catgories page
({
    handleAddClose,
    getPendingTicketsList,
    getAllocatedTicketsList,
    TicketId
}: {
    handleAddClose: (event: React.MouseEvent) => void,
    getPendingTicketsList: (event: React.MouseEvent) => void,
    getAllocatedTicketsList: (event: React.MouseEvent) => void,
    TicketId:any
})=>{

    // use state
    const [AllocationsRemarksError, setAllocationsRemarksError]=useState(false)
    const [AllocationsRemarksValue, setAllocationsRemarksValue]=useState('')
    const [AllocationsRemarksErrorMessage, setAllocationsRemarksErrorMessage]=useState('')
    const [EmployeeOptions, setEmployeeOptions] = useState<OptionType[]>([]);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState<any | null>('');
    const [EmployeeError, setEmployeeError]=useState(false)
    const [EmployeeErrorMessage, setEmployeeErrorMessage]=useState('')

    type OptionType = {
        name: string;
        value: string;
    };
    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });

    //  Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    //console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId
    const UserId = GlobalData.GlobalUserId;

    //  Handle Allocations while typing Allocations and validations
    const handleAllocationsRemarksChange = (event:any) => {
        setAllocationsRemarksValue(event.target.value);
        const trimmedAllocationsRemarksValue =  event.target.value
        if( trimmedAllocationsRemarksValue.length !== 255 ){
            setAllocationsRemarksError(false);
            setAllocationsRemarksErrorMessage("");
        }
    };

    // To render handle close function to close dialouge box
    const handleClose = (event: React.MouseEvent) => {
        if (handleAddClose && typeof handleAddClose === 'function') {
            handleAddClose(event);
        }
    };

    // to render getPendingTicketsList function after approving request
    const handlegetPendingTicketsList= (event: React.MouseEvent) => {
        if (getPendingTicketsList && typeof getPendingTicketsList === 'function') {
            getPendingTicketsList(event);
        }
    };

    // to render getAllocatedTicketsList function after approving request
    const handlegetAllocatedTicketsList= (event: React.MouseEvent) => {
        if (getAllocatedTicketsList && typeof getAllocatedTicketsList === 'function') {
            getAllocatedTicketsList(event);
        }
    };

    // Employee Dropdown API
    const GetEmployeeList = async () => {

        const EmployeeListPayload ={
            CompanyId:CompanyId,
            active:"Y"
        }

        //console.log("EmployeeListPayload",EmployeeListPayload)

        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/employee/getEmployeeList`,EmployeeListPayload);
            //console.log("Employee List" ,res.data.data);
            let formattedEmployeeList = res.data.data.map((item:any) => {
            const { employee_id, employee_name } = item;
            return {
                name: employee_name,
                value: employee_id,
            };
            });
            setEmployeeOptions(formattedEmployeeList)
        } catch (err) {
            //console.log( "co",err);
        }
        };


    // To Allocations approval

    // Define a type for errors
    type Errors = {
    AllocationsSts?:boolean;
    apprveRemarks?:boolean;
    };

    const AllocateTicket =(event:any)=>{

        event.preventDefault()
        let errors: Errors = {};

        if (selectedEmployeeId === '' || selectedEmployeeId === null || selectedEmployeeId === undefined) {
            errors.AllocationsSts = true;
            setEmployeeErrorMessage("Employee required")
            setEmployeeError(true)
        }
        
        const trimmedAllocationsRemarksValue = AllocationsRemarksValue.trim()
        if( trimmedAllocationsRemarksValue.length >255 ){
            errors.apprveRemarks = true
            setAllocationsRemarksError(true);
            setAllocationsRemarksErrorMessage("Max 255 characters");
        }
        else {
            setAllocationsRemarksError(false);
            setAllocationsRemarksErrorMessage("");
        }


        if (Object.keys(errors).length > 0) {
            // If there are errors, update the state with all the errors
            setEmployeeError(errors.AllocationsSts || false);
            setAllocationsRemarksError(errors.apprveRemarks || false);
            return;
        }

        const AllocateTicketPayload={
            SaveUpdateKey: "update",
            CompanyId: CompanyId,
            TicketId: TicketId,
            TicketAllocateStatus:'Y',
            TicketAllocateTo:selectedEmployeeId,
            TicketAllocateBy:UserId,
            TicketAllocateRemarks:AllocationsRemarksValue
        }

        //console.log("AllocateTicketPayload",AllocateTicketPayload)

        commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/ticket_request_update`, 
        AllocateTicketPayload
        )
        .then((res) => {
          if (res.status === 200) {
              Success("Ticket Allocation Updated Successfully");
              handleClose(event);
              handlegetPendingTicketsList(event);
              handlegetAllocatedTicketsList(event);
          }
          }).catch((error) => {
              console.error("Error:", error);
          });
    }
 

    useEffect(() => {
        GetEmployeeList();
    }, []); 

    return(
        <Box>
            <Box>
            <Box bgcolor={'white'} borderRadius={3}
            sx={{
                width:{
                    lg:600,
                    md:600,
                    xs:350
            }}} >
                <Box>
                <Box p={3}>
                        <Typography variant='h1'
                        sx={{
                            fontSize:{
                                lg:'2rem',
                                md:'2rem',
                                xs:'1.5rem'
                            }
                        }}
                        >
                        Allocate
                        </Typography>
                    </Box>
                    
                    <form noValidate>
                        <Box p={3}>
                            <Grid container >
                                <Grid item lg={12} md={12} xs={12} >
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={EmployeeOptions as OptionType[]}
                                        filterOptions={filterOptions}
                                        getOptionLabel={(option: unknown) => (option as OptionType).name}
                                        value={EmployeeOptions.find((option: OptionType) => option.value === selectedEmployeeId) || null}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setSelectedEmployeeId(newValue.value);
                                                setEmployeeError(false);
                                                setEmployeeErrorMessage("");
                                            } else {
                                                setSelectedEmployeeId('');
                                                setEmployeeError(true);
                                                setEmployeeErrorMessage("Employee is required");
                                            }
                                        }}
                                        // sx={{ width: 235, ml: 1 }}
                                        renderInput={(params) => 
                                        <TextField {...params}
                                        error={EmployeeError}
                                        autoFocus
                                        helperText={EmployeeErrorMessage}
                                            required label="Employee" 
                                        variant="standard" />}
                                    />
                                </Grid>

                                <Grid item mt={3} lg={12} md={12} xs={12} >
                                            <TextField 
                                            id="outlined-basic" 
                                            label="Allocations Remarks" 
                                            variant="outlined" 
                                            name='AllocationsRemarks'
                                            fullWidth
                                            multiline
                                            rows={4}
                                            error={AllocationsRemarksError}
                                            onChange={handleAllocationsRemarksChange}
                                            value={AllocationsRemarksValue}
                                            helperText={AllocationsRemarksErrorMessage}                                           
                                            />
                                        </Grid>
                            </Grid>
                        </Box>

                    <Box mt={5} ml={2} mr={2} mb={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Button
                                    sx={{
                                        background: 'white',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        // color: {accent},
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}

                                    onClick={handleClose} 
                                >
                                    Back
                                </Button>

                                <Button
                                    sx={{
                                        background: '#25425F',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        "&:hover":{
                                            backgroundColor:'#25425F',
                                            color:'white',
                                        },
                                        color: 'white',
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}
                                    type='submit'
                                    onClick={(event)=>{
                                        AllocateTicket(event);
                                    }}
                                >
                                    Save
                                </Button>
                                </Box>
                    </form>


                </Box>
            </Box>
            </Box>
        
        </Box>
    )

}

export default AllocateTicketPage;

// End of SOP-34