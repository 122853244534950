// Included by sanjana SOP-56 to create hierarchy grid page
import React, { useState, useEffect } from 'react';
import './HierarchyGrid.scss';
import {
  Box,
  Button,
  TextField,
  Autocomplete,
  Dialog,
  Typography,
} from '@mui/material';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { createFilterOptions } from '@mui/material/Autocomplete';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Success } from '../../atic-common-helpers/helpers/toast.helper';
import PreviewHierarchyChart from './PreviewHierarchyChart';

// To set variable type for row data
interface Row {
  designationid: number;
  content: string;
  reported_to_which_designationid: string | null;
  companyid: number | null;
  createdby: number | null;
  top_designationid: number | null;
  _saveupdatekey: string | null;
}

interface HierarchyGridProps {
  rows: Row[];
}




const HierarchyGrid: React.FC<HierarchyGridProps> = ({ rows }) => {
  const [grids, setGrids] = useState<Row[][]>(() => {
    return [rows.map((row, index) => ({ ...row, display_orderid: index + 1, reported_to_which_designationid: null }))];
  });

  // useState
  const [DesignationOptions, setDesignationOptions] = useState<OptionType[]>([]);
  const [DesignationErrors, setDesignationErrors] = useState<boolean[]>([]);
  const [DesignationErrorMessages, setDesignationErrorMessages] = useState<string[]>([]);
  const [HierarchyChartopen, setHierarchyChartOpen] = React.useState(false);


  // To get selected designation id from session
  const SelectedTableDataDesignationId = Array.isArray(JSON.parse(sessionStorage.getItem("selectedTableDataDesignationId") || '[]')) ? 
    JSON.parse(sessionStorage.getItem("selectedTableDataDesignationId") || '[]') : [];
  //to get selected designation name from session
  const selectedTableDataDesignationName = Array.isArray(JSON.parse(sessionStorage.getItem("selectedTableDataDesignationName") || '[]')) ? 
    JSON.parse(sessionStorage.getItem("selectedTableDataDesignationName") || '[]') : [];

  // use navigate
  const navigate = useNavigate();

  const handleClickHierarchyChartOpen = () => {
    setHierarchyChartOpen(true);
  };
  const handleClose = () => {
    setHierarchyChartOpen(false);
  };

  type OptionType = {
    name: string;
    value: string;
  };

  const filterOptions = createFilterOptions<OptionType>({
    matchFrom: 'start',
    stringify: (option) => option.name,
  });


  const GetDesignationList = async () => {    

    const SelectedDesignations = SelectedTableDataDesignationId.map((id:any, index:any) => ({
      designationid: id,
      designationname: selectedTableDataDesignationName[index]
    }));
  
    let formattedDesignationList = SelectedDesignations.map((item: any) => ({
      name: item.designationname,
      value: item.designationid,
    }));
    
    setDesignationOptions(formattedDesignationList);
  };

  useEffect(() => {
    GetDesignationList();
  }, []);


  // Validations for designations
  const validateHierarchy = () => {
    const newDesignationErrors = grids[0].map((row) => {
      const reportedToId = row.reported_to_which_designationid ? parseInt(row.reported_to_which_designationid, 10) : null;
      const SameDesignation = row.designationid === reportedToId;
      const AlreadySelectedDesignation = grids[0].some(
        (otherRow) =>
          otherRow.designationid === reportedToId &&
          otherRow.reported_to_which_designationid &&
          parseInt(otherRow.reported_to_which_designationid, 10) === row.designationid
      );
      return SameDesignation || AlreadySelectedDesignation || reportedToId === null;
    });
  
    setDesignationErrors(newDesignationErrors);
    setDesignationErrorMessages(
      newDesignationErrors.map((hasError, index) => {
        const row = grids[0][index];
        const reportedToId = row.reported_to_which_designationid ? parseInt(row.reported_to_which_designationid, 10) : null;
        if (row.designationid === reportedToId) {
          return "Designation cannot report to itself";
        } else if (
          grids[0].some(
            (otherRow) =>
              otherRow.designationid === reportedToId &&
              otherRow.reported_to_which_designationid &&
              parseInt(otherRow.reported_to_which_designationid, 10) === row.designationid
          )
        ) {
          return "Selection already exist";
        } else if (reportedToId === null) {
          return "Designation is required";
        }
        return "";
      })
    );
  
    return !newDesignationErrors.some((error) => error);
  };
  

  const handleOkClick = () => {

    if (!validateHierarchy()) {
      return;
    }

    const newDesignationErrors = grids[0].map((row) => row.reported_to_which_designationid === null);
    setDesignationErrors(newDesignationErrors);
    setDesignationErrorMessages(newDesignationErrors.map((hasError) => (hasError ? "Designation is required" : "")));

    if (newDesignationErrors.some((error) => error)) {
      return;
    }

    const HierarchyDataToStoreInSession = grids[0].map((row) => ({
      content: row.content,
      _top_designationid: row.top_designationid,
      _designationid: row.designationid,
      _reported_to_which_designationid: row.reported_to_which_designationid,
    }));
    sessionStorage.setItem("HierarchyDataToStoreInSession", JSON.stringify(HierarchyDataToStoreInSession))
    handleClickHierarchyChartOpen()
  };


  // To create hierarchy
  const HandleSave = () => {
    const allOrderedGridsPayload: any[] = [];
  
    // Iterate through each grid in grids[0]
    grids[0].forEach((row) => {
      // Prepare payload for each row
      const orderedGridsPayload = {
        _saveupdatekey: row._saveupdatekey,
        top_designationid: row.top_designationid,
        designationid: row.designationid,
        reported_to_which_designationid: row.reported_to_which_designationid,
        companyid: row.companyid,
        createdby: row.createdby,
      };
  
      // Add to the array that will be sent for saving
      allOrderedGridsPayload.push(orderedGridsPayload);
    });
  
  
    // Promise.all to wait for all save requests to complete
    Promise.all(
      allOrderedGridsPayload.map((payload) =>
        commonService.postService(`${process.env.REACT_APP_BACKEND_URL}/hrhierarchy/hr_hierarchy_create_update`, payload)
      )
    )
      .then((responses) => {
        // Check if all responses are successful
        const allSuccess = responses.every((res) => res.status === 200);
        if (allSuccess) {
          Success("Hierarchy created successfully");
          navigate('/backoffice');
        }
      })
      .catch((error) => {
        //console.error("Error:", error);
      });
  };
  
  
  // handle change function of designation
  const handleAutocompleteChange = (gridIndex: number, rowIndex: number, newValue: OptionType | null) => {
    setGrids((prevGrids) => {
      const updatedGrids = [...prevGrids];
      const updatedGrid = updatedGrids[gridIndex].map((row, index) => 
        index === rowIndex ? { ...row, reported_to_which_designationid: newValue ? newValue.value : null } : row
      );
      updatedGrids[gridIndex] = updatedGrid;
      return updatedGrids;
    });
  
    
    setDesignationErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[rowIndex] = newValue === null;
      return updatedErrors;
    });
  
    setDesignationErrorMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];
      updatedMessages[rowIndex] = newValue === null ? "Designation is required" : "";
      return updatedMessages;
    });
  
    if (newValue) {
      //console.log(`Selected designation for grid ${gridIndex}, row ${rowIndex}: ${newValue.name} (${newValue.value})`);
    } else {
      //console.log(`Cleared designation for grid ${gridIndex}, row ${rowIndex}`);
    }
  };
  

  return (
    <Box className="HierarchyGrid">
      <Box style={{ display: 'flex'}}>
        {grids.map((rows, gridIndex) => (
          <Box display={'flex'} justifyContent={'center'}> 
            <Box> 
            <Box key={gridIndex} >
              <Typography variant='h1' display={'flex'} justifyContent={'center'} >
                Designation(s)
              </Typography>
              <Box className="grid-container"> 
              {rows.map((row) => (  
                <Box className="grid-row" >
                  {row.content}
              </Box> ))}
              </Box>
              {/* <Grid
                rows={rows}
                onDragStart={handleDragStart}
                onDragOver={(event, rowIndex) => {
                  setTargetGridIndex(gridIndex);
                  handleDragOver(event, rowIndex);
                }}
                onDrop={() => handleDrop(gridIndex)}
                placeholderIndex={targetGridIndex === gridIndex ? placeholderIndex : null}
              /> */}
            </Box>              
            </Box>

            <Box> 
              <Typography variant='h1' display={'flex'} justifyContent={'center'} >
                  Reporting to
                </Typography>
              <Box width={500} className="grid-container-DD">
                {rows.map((row, rowIndex) => (
                  <Autocomplete
                    className="grid-row-DD"
                    key={row.designationid}
                    id={`autocomplete-${gridIndex}-${rowIndex}`}
                    options={DesignationOptions}
                    filterOptions={filterOptions}
                    getOptionLabel={(option) => option.name}
                    value={DesignationOptions.find((option) => option.value === row.reported_to_which_designationid) || null}
                    onChange={(event, newValue) => handleAutocompleteChange(gridIndex, rowIndex, newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={DesignationErrors[rowIndex]}
                        helperText={DesignationErrorMessages[rowIndex]}
                        required
                        label="Designation"
                        variant="standard"
                      />
                    )}
                    sx={{
                      bgcolor: 'plum'
                    }}
                  />

                ))}
              </Box>                
            </Box>


 
          </Box>
        ))}
      </Box>

      <Box display={'flex'} justifyContent={'flex-end'} >

        <Button variant="contained" 
        sx={{
          background: '#25425F',
          border: 'none',
          borderRadius: '5px',
          padding:{
              lg: "0 70px",
              md: "0 70px",
              xs:'0 50px'
          },
          "&:hover":{
              backgroundColor:'#25425F',
              color:'white',
          },
          color: 'white',
          height: "3rem",
          boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
      }}
        onClick={handleOkClick}>Next</Button>

      </Box>

      <Box>
        <Dialog
            open={HierarchyChartopen} fullScreen >
                <Box >
                    <Typography variant='h1' mt={5} display={'flex'} justifyContent={'center'} > HIERARCHY </Typography>
                    <Box mt={2} >
                        <PreviewHierarchyChart/>
                    </Box>

                    <Box display={'flex'} justifyContent={'space-between'} >
                      <Button
                      sx={{
                          mb:3,
                          ml:3,
                          background: 'white',
                          border: 'none',
                          borderRadius: '5px',
                          padding:{
                              lg: "0 70px",
                              md: "0 70",
                              xs:'0 50px'

                          },
                          height: "3rem",
                          boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                      }}

                      onClick={handleClose}>
                          Back
                      </Button>

                      <Button variant="contained" 
                        sx={{
                          background: '#25425F',
                          border: 'none',
                          borderRadius: '5px',
                          mr:3,
                          padding:{
                              lg: "0 70px",
                              md: "0 70px",
                              xs:'0 50px'
                          },
                          "&:hover":{
                              backgroundColor:'#25425F',
                              color:'white',
                          },
                          color: 'white',
                          height: "3rem",
                          boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                      }}
                        onClick={HandleSave}
                        >Save</Button>                    
                    </Box>


                </Box>                    
        </Dialog>        
      </Box>

    </Box>
  );
};

export default HierarchyGrid;

// End of SOP-56