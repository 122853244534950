import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

type userType= {
  userDetails: any;
}
const initialState = {
  userDetails: localStorage.getItem('avt-auth-user')? JSON.parse(localStorage.getItem('avt-auth-user') || '') : null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetails: (state:userType , action: PayloadAction<any>) => {
      state.userDetails = action.payload;
    },
    clearUserDetails: (state: userType) => {
      state.userDetails = null;
    },

    getUserDetails: (state: userType)=>{
      return state.userDetails
    }
  }
});

export const { clearUserDetails, setUserDetails } = userSlice.actions;

export const userDetails = (state: any) => state.user.userDetails;


export default userSlice.reducer;
