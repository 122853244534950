// material-ui
import { useTheme } from '@mui/material/styles';
import {  Box,  Drawer,  ListItemButton, ListItemIcon, ListItemText, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import MenuList from '../Sidebar/MenuList'
import { drawerWidth } from '../../../../features/theme/constant';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import './sidemenu.scss';


import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { userDetails } from '../../../../features/auth/userSlice';
import { useDispatch } from 'react-redux';
import LogoSection from '../LogoSection';


// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }: SidebarTypes) => {
  
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const userDetail = useSelector(userDetails);
    const navigate=useNavigate();
  
    const profileCard = (
        <Stack alignItems="center"mt={1} justifyContent="space-evenly" direction="column" spacing={2} sx={{ height: '150px', px: '10px', }} >
        <Box display={'flex'} alignItems='flex-start' justifyContent= 'space-between' flexDirection={'column'}  sx={{ px: 2,  width: '100%',  paddingBottom: '10px', borderBottom: '1.06818px solid rgba(0, 0, 0, 0.12)' }}>
        <Box display={'flex'} alignItems='center' sx={{ width: '100%', marginBottom: '5px' }} justifyContent= 'space-around' >
                <div>
                    <p className='profile-name'> 
                    {/* {userDetail?.firstname} {userDetail?.lastname} */} Name
                     </p>
                </div>
            </Box>
            <Tooltip title="admin@gmail.com" placement="right-start">
            <p className='profile-mail'> 
            {/* {userDetail?.email}  */} 
            email
            </p>
            </Tooltip>
            </Box>
        <ListItemButton
            sx={{
                width: '100%',
                mt: '1',
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1.06818px solid rgba(0, 0, 0, 0.12)',
                '&:hover': {
                    background: 'none'
                }
            }}
            style={{marginTop: '5px'}}          
        >
            <ListItemIcon className='side-menu-icon-list' sx={{ my: 'auto', marginLeft: 'rem'}}>{<SettingsOutlinedIcon  sx={{fontSize:"1.7rem", cursor: 'pointer' }}/> }</ListItemIcon>
            <ListItemText
            sx={{ marginLeft: '1rem'}}
                primary={
                    <Typography variant={'h5'} color="inherit">
                       Settings
                    </Typography>
                }
            />
        </ListItemButton>
              <ListItemButton
            sx={{
                width: '100%',
                mt: '0px',
                display: 'flex',
                alignItems: 'center',
                '&:hover': {
                    background: 'none'
                }
            }}
            style={{marginTop: '5px'}}
        >
            <ListItemIcon className='side-menu-icon-list' sx={{ my: 'auto', marginLeft: '2rem'}}>
            </ListItemIcon>
            <ListItemText
            sx={{ marginLeft: '1rem'}}
                primary={
                    <Typography variant={'h5'} color="inherit">
                       Logout
                    </Typography>
                }
            />
        </ListItemButton>
        </Stack>
    )
    const drawer = (
        <>
          
            <BrowserView>
                <PerfectScrollbar
                    component="div"
                    style={{
                        height: '100%',
                    }}
                >
                    
                    <MenuList />
                </PerfectScrollbar>
                {/* {profileCard} */}
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 2 }}   style={{
                        paddingTop: '20px'
                    }}>
                        
                    <MenuList />
                </Box>
            </MobileView>
        </>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" bgcolor={'plum'} sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
                <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    
                    '& .MuiDrawer-paper': {
                        
                        width: drawerWidth,
                        background: theme.palette.background.default,
                        color: "red",
                        borderRight: 'none',
                        // boxShadow: '-3px 0px 5px 2px rgb(0 0 0 / 28%)',
                        [theme.breakpoints.up('md')]: {
                            top: '90px'
                        }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

type SidebarTypes = {
    drawerOpen: boolean;
    drawerToggle: () => void;
    window: Window;
};

export default Sidebar;
