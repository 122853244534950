// SOP-61 Included By PCS
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
   TablePagination,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import deleteAlert from '../../component/HOC/deleteAlert';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import { IoIosAdd } from "react-icons/io";
import { CiFolderOn } from "react-icons/ci";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaRegFolderOpen } from "react-icons/fa";
import { TiFolderOpen } from "react-icons/ti";
import { PiFolderOpenThin } from "react-icons/pi";
import { MdEditDocument } from "react-icons/md";
import { CiRead } from "react-icons/ci";


import moment from 'moment';
import { RiDeleteBin6Line } from "react-icons/ri";
import { GrHistory } from "react-icons/gr";
import { TbLockAccess } from "react-icons/tb";
import { MdCategory } from "react-icons/md";
import { borderColor } from '@mui/system';
import { Link } from "react-router-dom";

import { Link as MuiLink } from '@mui/material';

import CreateCategories from './CreateCategories';
import AddDocumentTitle from './AddDocumentTitle'; 
import ManageAccess from './ManageAccess';
import AssignCategory from './AssignCategory';
import ActivityHistory from './ActivityHistory'


interface Document {
    documentid: number;
    document_title_name: string;
    docpoint_categoryname?: string;
     }

// Interface to define type of objects[category list]
interface Category {
    docpoint_categoryid: number;
    docpoint_categoryname: string;
       }

interface DocumentsByCategory {
    [categoryId: string]: Document[];
           }

const DocPoint =()=> {

   // Use navigate
     const navigate = useNavigate();

  // Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
        const GlobalData = JSON.parse(GlobalDataFromSession);
        let CompanyId = GlobalData.GlobalCompanyId
        let UserId = GlobalData.GlobalUserId
    
  //Initializing values
      const [DocumentsList, setDocumentsList] = useState<DocumentsByCategory>({});//Document List in First Grid
      //console.log('list of documents',DocumentsList)
  
  const [FilteredDocumentsList, setFilteredDocumentsList] = useState<DocumentsByCategory>({});// To display filtered documents in first grid
        //  console.log('FilteredDocumentsList',FilteredDocumentsList)

  const [SearchValue, setSearchValue] = useState<string>('');//to seacrh the document by name[second grid]
  const [Category, setCategory] = useState<Category[]>([]); // To display categories in second grid
        // console.log('SearchValue',SearchValue)

  //To open dialogue boxes for addcategories , add document title name , manage access, assign actegory..
  const [AddDocpointCategoriesOpen, setAddDocpointCategoriesOpen] = React.useState(false); //To open add category box
  const [AddDocumentTitleOpen, setAddDocumentTitleOpen] = React.useState(false); //To open add title box
  const [AddManageAccessOpen, setAddManageAccessOpen] = React.useState(false); //To open add manage access box
  const [AssignCategoryOpen, setAssignCategoryOpen] = React.useState(false); //To open assign category

  const [ActivityHistoryOpen, setActivityHistoryOpen] = React.useState(false); //To open Activity history  

  //  To manage visibility of second grid content
  const [showDocumentDetailsContent, setshowDocumentDetailsContent] = useState(false);  
        // console.log('showDocumentDetailsContent',showDocumentDetailsContent)

  //Initial values for Document details in second grid..
     const [DocumentValue, setDocumentValue] =  useState(0); //To set Document value
     const [DocumentName, setDocumentName] =  useState(''); 
     const [DocumentId, setDocumentId] =  useState(''); 
     const [VersionNumber, setVersionNumber] =  useState(''); 
     
     const [DateCreated, setDateCreated] =  useState(''); 
     const [Author, setAuthor] =  useState(''); 
     const [LastModified, setLastModified] = useState(''); 
     const [CategoryName, setCategoryName] = useState(null); 


     const [IsDeleteRights, setIsDeleteRights] = React.useState(false); //Delete rights
     const [IsEditRights, setIsEditRights] = React.useState(false); //Delete rights
     

   //To high light selected category
    //  const [selectedCategory, setSelectedCategory] = useState(null);//to highlight selected category
        //console.log('selected id',selectedCategory)
        const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
    const [isAllDocumentsSelected, setIsAllDocumentsSelected] = useState(false); // to select all documents


         const handleCategoryClick = (categoryId: any, categoryName: any) => {
            setSearchValue('')//to remove from search field
            setSelectedCategory(categoryId);
            DocumentsForCategory(categoryId, categoryName);

            //Included By PCS[02/08/2024] when category is selected 
            sessionStorage.setItem("SelectedCategoryFromSession", categoryId);
            sessionStorage.removeItem("SearchValueFromSession");//search value is cleared
       };

//To set values in session storage
     sessionStorage.setItem("DocumentTitleValue",DocumentName)
     sessionStorage.setItem("DocumentId",DocumentId)
     sessionStorage.setItem("VersionNumber",VersionNumber)


    //  console.log('vr',sessionStorage.setItem("VersionNumber",VersionNumber))
     
     const VersionNumberFromSession = sessionStorage.getItem("VersionNumber")


//To get actual date(adding utc)

     
     const reverseDate = (dateStr:any) => 
    {
       let date = new Date(dateStr);

        date.setHours(date.getHours() + 5);
        date.setMinutes(date.getMinutes() + 30);
        
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getUTCDate()).padStart(2, '0');
        // Construct the final string
        return `${day}-${month}-${year} `;
    };

  //To handle second grid [display document details/ display category list - based on click]   
     let displayVariable:any;
        const checkingInside = (eve:any) =>
            {
                if(eve>0){
                     displayVariable =true;
                        }
            }
        const checkingOutside = (eve:any) =>
           {
                if(eve>0){
                    displayVariable =true;
                      }
                if(displayVariable==true){
                   
                    setshowDocumentDetailsContent(true)
                                         }
                else{
                  
                    setshowDocumentDetailsContent(false)
                    displayVariable=false
                    setSelectedDocument(null);//To remove highlight from selected document
                   }
           } 
  //EOF To handle second grid [display document details/ display category list - based on click]  
      
   //  To open add category dialogue box
        const handleAddDocpointCategoriesOpen = () => {
            setAddDocpointCategoriesOpen(true);
        };

    // To close add category dialogue box
        const handleAddDocpointCategoriesClose = () => {
            setAddDocpointCategoriesOpen(false);
            getCategoryList();
        };


    //  To open add title dialogue box
    const handleAddDocumentTitleOpen = () => {
        setAddDocumentTitleOpen(true);
        };

    // To close add title  dialogue box
        const handleAddDocumentTitleClose = () => {
            setAddDocumentTitleOpen(false);
         };
        

    //  To open manage access dialogue box
        const handleAddManageAccessOpen = () => {
            setAddManageAccessOpen(true);
            };

    // To close manage access  dialogue box
            const handleAddManageAccessClose = () => {
                setAddManageAccessOpen(false);
            };

            
    //  To open assign category dialogue box
        const handleAssignCategoryOpen = () => {
            setAssignCategoryOpen(true);
            };

    // To close assign category dialogue box
            const handleAssignCategoryClose = () => {
                setAssignCategoryOpen(false);
            };

    //  To open  activity/ Revision history dialogue box
            const handleActivityHistoryOpen = () => {
                setActivityHistoryOpen(true);
                };

    // To close activity/ Revision history  dialogue box
            const handleActivityHistoryClose = () => {
                setActivityHistoryOpen(false);
            };
    

//To get User rights - to set delete functionality enable/disable


    const getDocumentUserRightsByUserId = async (documentid:any) => 
        {

            // console.log('user rights enabled')
            const UserRightsPayload ={
                UserRightsId:UserId,
                DocumentId:documentid,
                CompanyId:CompanyId 
                                    }


            //   console.log('payw',UserRightsPayload)
            try {
                    const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/docpoint/getDocumentUserRightsByUserId`,UserRightsPayload);
                     const deleteRights=res.data.data[0].deletevalue
                     const editRights =res.data.data[0].editvalue

                      setIsDeleteRights(deleteRights==1?true:false)
                      setIsEditRights(editRights==1?true:false)

                } 
            catch (err)
             {
                console.log( "error user rights",err);
             }
        };



    // Category  List  API- to display in second grid
        const getCategoryList = async () => 
            {
                const CategoryPayload ={ CompanyId:CompanyId }
                try {
                        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/docpoint/get_docpoint_categories_list`,CategoryPayload);
                        setCategory(res.data.data)
                    } 
                catch (err) {
                    console.log( "category list error",err);
                        }
            };


   // Document Details  API - to dispaly in second  grid
        const getDocumentDetailsById = async (documentid:any,versionNumber:any) => 
            {

                // console.log('vers',versionNumber)
                const Payload ={
                    DocumentId:documentid,
                    CompanyId:CompanyId,
                    //Included By PCS on 02/08/2024
                     VersionNumber:versionNumber
                                }
                                
                    try {
                        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/docpoint/getDocumentDetails_by_id`,Payload);
                        //  console.log("D" ,res.data.data[0]);

                        const DocumentDataById=res.data.data[0];

                            setDocumentName(DocumentDataById.document_title_name)
                            setVersionNumber(DocumentDataById.document_version_number)//To get version number
                            setDocumentId(DocumentDataById.documentid)
                            setAuthor(DocumentDataById.employee_name)
                            setCategoryName(DocumentDataById.docpoint_categoryname)

        let createddateResult= DocumentDataById.createddatetime?reverseDate(DocumentDataById.createddatetime):''
           setDateCreated(createddateResult)
                       
        let lastmodifieddateResult = DocumentDataById.lastmodifieddate?reverseDate(DocumentDataById.lastmodifieddate):''
           setLastModified(lastmodifieddateResult)
                     } 
                   catch (err) {
                   console.log( "co",err);
                       }
            };

    // Documents  List  API
        const getDocumentsList =()=>
         {
           const DocumentsPayload ={ CompanyId:CompanyId }
           commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/docpoint/get_docpoint_documents_list`, DocumentsPayload)  
                .then((res: any) => 
                { 
                 const DocumentsList = res.data.data
                // console.log('read',DocumentsList)
                const documentsByCategory = res.data.data.reduce((acc:any, document:any) => {
                    // const categoryName = document.docpoint_categoryname || 'Others';
                    const categoryName = document.docpoint_categoryname || '<unspecified category>';
                    if (!acc[categoryName]) {
                      acc[categoryName] = [];
                    }
                    acc[categoryName].push(document);
                    return acc;
                  }, {});

                //   console.log('GRouping',documentsByCategory)
                   setDocumentsList(documentsByCategory);

                }).catch((error) =>   
                { 
                    Failed(error.response.message);
                    console.log(error);
                });
         }   

         
// Display documents based on selected category

  const DocumentsForCategory = async (categoryId: number, categoryName: string) =>
     {
        const DocumentsPayload = { CompanyId: CompanyId, CategoryId: categoryId };
        
        try {
             const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/docpoint/getListOfDocumentsByCategoryId`, DocumentsPayload);
             const documentsByCategory = res.data.data.reduce((acc: DocumentsByCategory, document: Document) => {
             const category = document.docpoint_categoryname ||  '<unspecified category>';
           
                if (!acc[category]) {
                    acc[category] = [];
                }
                acc[category].push(document);
                return acc;
            }, {});

        setDocumentsList(documentsByCategory);
           } 
        catch (error) {
            console.log('err',error)
       
             }
    };


          //To high light selected document
          const [selectedDocument, setSelectedDocument] = useState(null);

          const handleClick = (documentId:any) => {
           if (selectedDocument === documentId) {
               setSelectedDocument(null); // Deselect if already selected
           } else {
               setSelectedDocument(documentId); // Select the clicked document
           }
                                               };      


     useEffect(() => {
                            const storedSearchValue :any= sessionStorage.getItem("SearchValueFromSession");
                            const storedCategoryId:any = sessionStorage.getItem("SelectedCategoryFromSession");
                        
                            // console.log('1',storedSearchValue)
                            // console.log('2',storedCategoryId)
                        
                        
                            if ( storedSearchValue!=null) {
                                // console.log('has search value')
                                 setSearchValue(storedSearchValue);
                                //    console.log('Before removing SearchValueFromSession:', sessionStorage.getItem("SearchValueFromSession"));
                                   sessionStorage.removeItem("SearchValueFromSession");
                                //    console.log('After removing SearchValueFromSession:', sessionStorage.getItem("SearchValueFromSession"));
                             
                            }
                            
                         
                            if (storedCategoryId) {
                                // console.log('has category value')
                                setSelectedCategory(parseInt(storedCategoryId));
                                DocumentsForCategory(parseInt(storedCategoryId), '');
                                sessionStorage.removeItem("SelectedCategoryFromSession"); //removing session memory after displaying records
                                
                            } 
                            else {
                                getDocumentsList();
                                
                                }
                        
                            getCategoryList();
                           
                        }, []);
                       
      useEffect(() =>
         {
            if (SearchValue.trim() !== '') {
            
                const storedSearchValue :any= sessionStorage.getItem("SearchValueFromSession");
                    getDocumentsList();
                    sessionStorage.removeItem("SelectedCategoryFromSession");//to remove from memory
                    setSelectedCategory(null)
                    setIsAllDocumentsSelected(true);
                    handleSearchChange({ target: { value: SearchValue } } as React.ChangeEvent<HTMLInputElement>);
                    
            }
                 getCategoryList();
                        
           }, [DocumentsList]);


    // Handler for document name search 
        const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        {
             //SOP-69 Inlcuded by PCS [02/08/2024]

            sessionStorage.removeItem("SelectedCategoryFromSession");//to remove from memory
            const searchValue = event.target.value;
            setSearchValue(searchValue);

        // Inlcuded by pcs[02/08/2024]
        // sessionStorage.removeItem("SelectedCategoryFromSession");//to remove from memory

            if (searchValue.trim() === '') {
                // Reset to show all documents when search is cleared
                getDocumentsList();
                setFilteredDocumentsList({});
              }
               else {

                const filteredData = Object.keys(DocumentsList).reduce((acc, categoryName) => {
                    const filteredDocuments = DocumentsList[categoryName].filter((doc: Document) =>
                        doc.document_title_name.toLowerCase().includes(searchValue.toLowerCase())
                    );
                    if (filteredDocuments.length > 0) {
                        acc[categoryName] = filteredDocuments;
                    }
                    return acc;
                }, {} as DocumentsByCategory);
            
                setFilteredDocumentsList(filteredData);

                };
        };

                                            
        // Handle Delete Document
      
            const getDocumentDeleted = async () => {
                deleteAlert({
                    
                    title: `${DocumentName}`,
                    message: "Are you sure to Delete?",
                    confirmButtonText: "Yes",
                    onConfirmed: DeleteDocument,
                              });
             };
        

            const DeleteDocument = () =>{

                const deletePayload={
                  DocumentId: DocumentId,
                  CompanyId: CompanyId,
                  }
              
                // console.log('dp',deletePayload)
                  commonService
                  .postService(`${process.env.REACT_APP_BACKEND_URL}/docpoint/docpoint_document_delete`, deletePayload)
                  .then((res: any) => {
                  if (res.status === 200) {
                      Success("Document Deleted Successfully");
                      getDocumentsList(); // Reset to show all documents
                   }
                  })
                  .catch((err) => {
                  console.log('err Delete doc',err)
              
                  });
              }
        const handleAllDocumentsClick = () => {
       
        setIsAllDocumentsSelected(true);
   
        };

      return(
      <>
      {/* //SOP-77 feedback points Included By PCS */}
         <h1 style={{marginLeft:'1.5%'}}>DocPoint</h1> 
       {/* //EOF SOP-77 feedback points Included By PCS */}
            <Grid container mt={1} spacing={3} display={'flex'} justifyContent={'center'} >
            {/* 1st half of grid starts */}                  
                <Grid item lg={8} md={12} xs={12} sx={{marginLeft:'2px', border: '2px solid gray', borderRadius: 1 }} 
                    onClick={() => {
                        checkingOutside (null) 
                        }}
         >
        <Grid sx={{}}>
            <MainContainerWrapper 
           //SOP-77 feedback points Modified By PCS [moving heading to the top]
            // headingText='DocPoint'
               headingText=''
           //EOF SOP-77 feedback points Modified By PCS [moving heading to the top]
            
            //SOP-76 feedback points L2 (5) Included By PCS [moving 'create' next to 'search']
                // handleClick={() =>{
                //     handleAddDocumentTitleOpen();
                    
                // }}
                // buttonText="Create Document"
                // isCreateButton={true}
            //EOF SOP-76 feedback points L2 (5)  Included By PCS [moving 'create'  next to 'search']
            isSearch={false}
            
            >
            {/* //SOP-77 feedback points commented By PCS [removing Divider] */}
            {/* <Divider sx={{mt:3, border:'0.1px solid grey'}}/> */}
            {/* //EOF SOP-77 feedback points commented By PCS */}

            <Box 
             onClick={() => {
                checkingOutside (null) 
                              }}
                sx={{ 
                justifyContent:"flex-start",
                maxHeight: '700px',  
                overflowY: 'auto'  }}
                >


            {/* Displaying DocumentsList or FilteredDocumentsList based on search */}
 {SearchValue.trim() === '' ? (  
     Object.keys(DocumentsList).length === 0 ? (
        <Grid>
        <Typography sx={{overflow:'hidden'}} align="center"mt={2} variant='h3'  color='gray'>No Documents found</Typography>
        </Grid>
    ) : (

Object.keys(DocumentsList).map((categoryName:any) => (

<Grid item xs={12} key={categoryName} sx={{ marginBottom: '20px' }}>

    <Box key={categoryName}>
      <Typography sx={{ fontSize: '18px', fontWeight: 'bold', marginTop: '1px' }}>
      {categoryName}
      </Typography>
      <Divider sx={{ border: '1px solid grey', mt: 1, mb: 1 ,width:'25%'}} />
      <Box  sx={{ flexWrap: 'wrap',textAlign:'left' }}>
      
        {DocumentsList[categoryName].map((documents:any) => (
          <Box
            key={documents.documentid}
            p={1}
            sx={{
               width: '300px',
              margin: '1px',
              textAlign: 'left',
          

            }}>
           
            <Typography
                sx={{
                    
                    fontSize: '0.9rem',
                    color: 'blue',
                    textDecoration:'underline',
                    cursor:'pointer',
                     backgroundColor: selectedDocument === documents.documentid ? 'rgba(0,0,0,0.1)' : '', // Highlight color condition
                }}
                onClick={() => {
                        checkingInside (documents.documentid) 
                        setDocumentValue(documents.documentid)
                        getDocumentDetailsById(documents.documentid,documents.document_version_number);
                        // console.log('title is',documents.document_title_name)
                        setshowDocumentDetailsContent(true) 
                        handleClick(documents.documentid)
                        getDocumentUserRightsByUserId(documents.documentid);
                       }}
                   >
                  {documents.document_title_name}
            </Typography>
          </Box>
         
        ))}
      </Box>
    </Box>
    </Grid>
))
)
              ) : ( 

Object.keys(FilteredDocumentsList).map((categoryName:any) => (

<Grid item xs={12} key={categoryName} sx={{ marginBottom: '20px' }}>
<Box key={categoryName}>
      <Typography sx={{ fontSize: '18px', fontWeight: 'bold', marginTop: '1px'}}>
      {categoryName}
      </Typography>
      <Divider sx={{ border: '1px solid grey', mt: 1, mb: 1 ,width:'25%'}} />
      <Box  sx={{ textAlign:'left' }}>
        {FilteredDocumentsList[categoryName].map((documents:any) => (
          <Box
            key={documents.documentid}
            p={1}
            sx={{
              width: '300px',
              margin: '1px',
              textAlign: 'left',
              color:'red'

            }}>
            <Typography
                sx={{
                    
                    fontSize: '0.9rem',
                    color: 'blue',
                  textDecoration:'underline',
                    cursor:'pointer',
                   
                    backgroundColor: selectedDocument === documents.documentid ? 'rgba(0,0,0,0.1)' : '', // Highlight color condition
                }}
                onClick={() => {
                        checkingInside (documents.documentid) 
                        setDocumentValue(documents.documentid)
                        getDocumentDetailsById(documents.documentid,documents.document_version_number);
                        sessionStorage.setItem("SearchValueFromSession", SearchValue);//Included By PCS
                        setshowDocumentDetailsContent(true) 
                        handleClick(documents.documentid)
                        }}
                   >
            
              {documents.document_title_name}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
    </Grid>
))

 )} 
            </Box>
           
            </MainContainerWrapper>
        </Grid>
      
    </Grid>
         
{/* 2nd half of grid starts */}

        <Grid item lg={3} md={12} xs={12} sx={{ border: '2px solid gray', borderRadius: 1 }}>
            <Box mt={1}>
            <Box sx={{display:'flex'}} display={'flex'}>
                <TextField
                    type='text'
                    variant="standard"
                    label='Search'
                    name='Search'
                    //SOP-76 feedback points L2 (5) modified by PCS [to accomodate 'create' button]
                    // style={{ width: '80%' }}
                       style={{ width: '50%' }}
                       multiline
                    //EOF SOP-76 feedback points L2 (5)  modified by PCS [to accomodate 'create' button ]
                    //SOP-69 Modified By PCS[02/08/2024]
                    // onChange={handleSearchChange}
                      onChange={(e:any)=>{
                        setSearchValue('')
                        setSelectedCategory(null)
                        getDocumentsList();
                        handleSearchChange(e)
                       }}
                    value={SearchValue}
                  />

                   {/* //SOP-76 feedback points L2 (5)  Included By PCS [moving 'create' document]  */}
                   <Button
                        sx={{
                            background: '#25425F',
                            border: 'none',
                            borderRadius: '5px',
                            marginLeft:'15px',
                            "&:hover":{
                                backgroundColor:'#25425F',
                                color:'white',
                            },
                            color: 'white',
                             height: "3rem",
                          
                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                        }}
                        type='submit'
                        onClick={(event)=>{
                            handleAddDocumentTitleOpen();
                        }}
                       >
                     Create Document
                   </Button>
                  {/* //EOF SOP-76 feedback points L2 (5)  Included By PCS moving create document */}
                </Box>
             
                </Box>

                <Divider sx={{mt:4, border:'0.1px solid grey',marginRight:'2px'}}/> 
     {/* Second grid visible true  */}
     <Grid item xs={12}  sx={{ marginBottom: '20px'}}>
                {!showDocumentDetailsContent && (
                   
                    <Grid >
                    <Box display={'flex'} justifyContent={'center'}  alignItems={'center'}>
                           <Typography fontSize={'1rem'} >List of category(s)</Typography>
                             <span style={{ color: 'blue',cursor:'pointer', marginLeft: '0.1px', height: '3rem', width: '3rem', marginTop: '10px' }}>
                               <IoIosAdd height={'100%'} width={'100%'} fontSize={'2rem'}  
                                 onClick={()=>{
                                 handleAddDocpointCategoriesOpen()
                               }}/>
                             </span>
                    </Box>
                    <Box sx={{display:'flex',cursor:'pointer',marginTop:'5px' }} onClick={() => {
                         setSearchValue('')
                         setSelectedCategory(null);
                         getDocumentsList(); // Reset to show all documents
                         handleAllDocumentsClick();
                        }}>
                        <span style={{ color: 'blue', marginLeft: '1px', height: '2.5rem', width: '2.5rem'}}>
                        {/* <CiFolderOn height={'100%'} width={'100%'} fontSize={'2rem'} /> */}
                        {isAllDocumentsSelected ? (
                            <PiFolderOpenThin height={'100%'} width={'100%'} fontSize={'2rem'} />
                        ) : (
                            <CiFolderOn height={'100%'} width={'100%'} fontSize={'2rem'} />
                        )}
                        </span>
                        
                        <Typography variant="body1" sx={{mt:1 , }}>All Documents</Typography>
                    </Box>
                    </Grid>
            
            
            )}
                {!showDocumentDetailsContent && (
                   <Grid sx={{maxHeight: '600px', overflowY: 'auto'}}>
                    <Box >
                    {Category.map((category, index) => (
                        <Box
                            key={index}
                            // p={-1}
                            // onClick={() => alert(`Clicked ${category}`)}
                            sx={{
                               
                                cursor: 'pointer',
                                width: '80%',
                                marginBottom: '-10px',
                                display:'flex',
                               backgroundColor: selectedCategory === category.docpoint_categoryid ? 'rgba(0,0,0,0.1)' : '', // Highlight color condition

                            }}
                             onClick={() => {
                                setIsAllDocumentsSelected(false)
                                handleCategoryClick(category.docpoint_categoryid, category.docpoint_categoryname)
                                         }}

                        >
                        <span style={{ color: 'blue', marginLeft: '1px', height: '2.5rem', width: '2.5rem', marginTop: '10px' }}>
                         {/* <PiFolderOpenThin height={'100%'} width={'100%'} fontSize={'2rem'} /> */}
                         {selectedCategory === category.docpoint_categoryid ? (
                            <PiFolderOpenThin size={24} />
                                ) : (
                                    <CiFolderOn size={24} />
                                )}
                        </span>
                        <Typography variant="body1" sx={{ mt: 2 }}>{category.docpoint_categoryname}</Typography>
                        </Box>
                    ))}
                </Box>
                </Grid>
                 )} 
                  </Grid>

{/* Second grid visible false */}


{showDocumentDetailsContent  && (
        <Box >
            <Grid container mt={1} spacing={1} display={'flex'} >
                <Grid item lg={6} md={12} xs={12} ><Typography textAlign={'right'} >Document Name :</Typography></Grid>
                <Grid item lg={5} md={12} xs={12} ><Typography >{DocumentName}</Typography></Grid>
            </Grid>

            <Grid container mt={1} spacing={1} display={'flex'} >
                <Grid item lg={6} md={12} xs={12} ><Typography textAlign={'right'}  >Date Created :</Typography></Grid>
                <Grid item lg={5} md={12} xs={12} ><Typography >{DateCreated}</Typography></Grid>
            </Grid>

            <Grid container mt={1} spacing={1} display={'flex'} >
                <Grid item lg={6} md={12} xs={12} ><Typography  textAlign={'right'} >Author:</Typography></Grid>
                <Grid item lg={5} md={12} xs={12} ><Typography >{Author}</Typography></Grid>
            </Grid>

            <Grid container mt={1} spacing={1} display={'flex'} >
                <Grid item lg={6} md={12} xs={12} > <Typography  textAlign={'right'} >Last Modified:</Typography></Grid>
                <Grid item lg={5} md={12} xs={12} ><Typography >{LastModified}</Typography></Grid>
            </Grid>

            <Grid container mt={1} spacing={1} display={'flex'} >
                <Grid item lg={6} md={12} xs={12} ><Typography  textAlign={'right'} >Category:</Typography></Grid>
                <Grid item lg={5} md={12} xs={12} ><Typography >{CategoryName}</Typography></Grid>
            </Grid>

            <Divider sx={{ mt: 3, borderTop: '2px dashed #3498db' ,marginRight:'8px'}}/>

 <Box>

 <Grid container mt={1} spacing={1} display={'flex'} >
   
    <Grid item lg={10} md={12} xs={12} sx={{display:'inline-flex'}}> 
      <TbLockAccess style={{ width: '20px', height: '27px' }} />
        <MuiLink 
         onClick={() =>{handleAddManageAccessOpen()}}
        href="#" sx={{marginTop:'4px', marginLeft: '4%',height: '27px', color: '#25425F',fontSize: '14px',textDecoration: 'underline' }}>
         Manage Access
        </MuiLink>
     </Grid>

     <Grid item lg={10} md={12} xs={12} sx={{display:'inline-flex'}}> 
      <GrHistory style={{ width: '20px', height: '27px' }} />
        <MuiLink
        onClick={() =>{handleActivityHistoryOpen()}} 
        href="#" sx={{marginTop:'4px', marginLeft: '4%', color: '#25425F', fontSize: '14px',textDecoration: 'underline' }}>
        Activity / Revision History
        </MuiLink>
     </Grid>

     <Grid item lg={10} md={12} xs={12} sx={{display:'inline-flex'}} > 
      <MdCategory style={{ width: '20px', height: '27px' }} />
        <MuiLink  
        onClick={() =>{handleAssignCategoryOpen()}} 
         href="#" sx={{marginTop:'4px', marginLeft: '4%', color: '#25425F', fontSize: '14px',textDecoration: 'underline' }}>
        Assign Category
        </MuiLink>
     </Grid>

     <Grid  item lg={10} md={12} xs={12} sx={{display:'inline-flex',cursor: IsDeleteRights ? 'pointer' : 'not-allowed'}}> 
      <RiDeleteBin6Line style={{ width: '20px', height: '27px',color: IsDeleteRights ? '#000' : '#ccc',
          cursor: IsDeleteRights ? 'pointer' : 'not-allowed'}} />
        <MuiLink href="#" 
           onClick={() =>{getDocumentDeleted()}} 
             sx={{marginTop:'4px', marginLeft: '4%', 
                 fontSize: '14px',textDecoration: 'underline' ,
                 pointerEvents: IsDeleteRights ? 'auto' : 'none',
                 color: IsDeleteRights ? '#25425F' : '#ccc'
                    }}>
        Delete
        </MuiLink>
     </Grid>

     <Grid  item lg={10} md={12} xs={12} sx={{display:'inline-flex',cursor: IsEditRights ? 'pointer' : 'not-allowed'}}> 
      <MdEditDocument style={{ width: '20px', height: '27px',color: IsEditRights ? '#000' : '#ccc',
          cursor: IsEditRights ? 'pointer' : 'not-allowed'}} />
        <MuiLink href="#" 
           onClick={() =>{navigate(`/edit_document`)}} 
             sx={{marginTop:'4px', marginLeft: '4%', 
                 fontSize: '14px',textDecoration: 'underline' ,
                 pointerEvents: IsEditRights ? 'auto' : 'none',
                 color: IsEditRights ? '#25425F' : '#ccc'
                    }}>
        Edit
        </MuiLink>
     </Grid>

     <Grid  item lg={10} md={12} xs={12} sx={{display:'inline-flex',cursor: 'pointer'}}> 
      <CiRead  style={{ width: '20px', height: '27px',color: '#000',
          cursor: 'pointer' }} />
        <MuiLink href="#" 
           onClick={() =>{navigate(`/view_document`)}} 
             sx={{marginTop:'4px', marginLeft: '4%', 
                 fontSize: '14px',textDecoration: 'underline' ,
                 pointerEvents: 'auto' ,
                 color:  '#25425F' 
                    }}>
        View
        </MuiLink>
     </Grid>



</Grid>

 </Box>
        </Box>
        
          )} 

        </Grid>
       
{/* 2nd half of grid ends */}
   </Grid> 
{/* Main grid ends  */}

            {/* Add Category dialog box */}
                <Box>
                    <Dialog  open={AddDocpointCategoriesOpen}  >
                    <CreateCategories handleAddDocpointCategoriesClose={handleAddDocpointCategoriesClose}/>
                    </Dialog>
                </Box>
            {/* End of Add category */}    

             {/* Add Document title dialog box */}
             <Box>
                    <Dialog  open={AddDocumentTitleOpen}  >
                    <AddDocumentTitle handleAddDocumentTitleClose={handleAddDocumentTitleClose}/>
                    </Dialog>
                </Box>
            {/* End of Add Document title */} 

            {/*  Manage access dialog box */}
            <Box>
                    <Dialog  open={AddManageAccessOpen}  >
                    <ManageAccess handleAddAccessClose={handleAddManageAccessClose}/>
                    </Dialog>
                </Box>
            {/* End of Manage access */} 

            {/*  Assign Category dialog box */}
            <Box>
                    <Dialog  open={AssignCategoryOpen}  >
                    <AssignCategory handleAssignCategoryClose={handleAssignCategoryClose}/>
                    </Dialog>
                </Box>
            {/* End of assign cateory  */} 

            {/*  Activity / Revision History  dialog box */}
            <Box>
                    <Dialog  open={ActivityHistoryOpen}  >
                    <ActivityHistory handleActivityHistoryClose={handleActivityHistoryClose}/>
                    </Dialog>
                </Box>
            {/* End of  Activity / Revision History    */} 

    </>)}
  export default DocPoint;

  // EOF SOP-61 Included By PCS