// Included by sanjana SOP-56
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Tree, TreeNode } from "react-organizational-chart";
import { FaUser } from "react-icons/fa";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { commonService } from "../../atic-common-helpers/helpers/common.service";
import { Failed } from "../../atic-common-helpers/helpers/toast.helper";

// To set style for node
const StyledNode = styled.div`
  padding:0.5px;
  border-radius: 5px;
  display: inline-block;
  border: 1px solid ${(props) => props.color || "#4169e1"};
  background-color: ${(props) => props.color || "#4169e1"};
  color: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`;

// to set random color for tree node
const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// To build hierarchy as per the parent and child
const buildHierarchy = (data) => {
  //console.log("buildHierarchy", data);

  const map = {};
  const roots = [];

  // Step 1: Create a map for each node
  data.forEach((node) => {
    map[node.designationid] = { ...node, children: [] };
    //console.log("map after adding node", map);
  });

  // Step 2: Link children to their parents and find root nodes
  data.forEach((node) => {
    //console.log("node", node);
    if (!node.reported_to_which_designationid || !map[node.reported_to_which_designationid]) {
      roots.push(map[node.designationid]);
      //console.log("roots after adding node", roots);
    } else {
      if (map[node.reported_to_which_designationid]) {
        map[node.reported_to_which_designationid].children.push(map[node.designationid]);
        //console.log("map after linking child", map);
      }
    }
  });

  //console.log("Final roots", roots);
  return roots;
};

// To render tree node for parent & child
const renderTreeNodes = (nodes) => {
  return nodes.map((node) => {
    if (node.designationname) { // Check if designationname is valid
      if (node.children.length > 0) {
        return (
          <TreeNode
            key={node.designationid}
            label={
              <StyledNode color={getRandomColor()}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FaUser style={{ fontSize: "1.2rem" }} />
                  <h4>{`${node.designationname}`}</h4>
                </div>
              </StyledNode>
            }
          >
            {renderTreeNodes(node.children)}
          </TreeNode>
        );
      } else {
        return (
          <TreeNode
            key={node.designationid}
            label={
              <StyledNode color={getRandomColor()}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FaUser style={{ fontSize: "1.2rem" }} />
                  <h4>{`${node.designationname}`}</h4>
                </div>
              </StyledNode>
            }
          />
        );
      }
    } else {
      //console.warn(`Node with id ${node.designationid} has no designation name.`);
      return null; // Return null for nodes without a valid designation name
    }
  });
};

// To set tree structure
const StyledTree = ({ data }) => {
  const [hierarchy, setHierarchy] = useState([]);

  useEffect(() => {
    if (data) {
      const transformedData = buildHierarchy(data);
      setHierarchy(transformedData);
    }
  }, [data]);

  if (!hierarchy || hierarchy.length === 0) {
    return <div style={{
      display:'flex', justifyContent:'center', fontSize:'1.5rem', padding:25, fontWeight:'bolder', color:'red'
    }} >Hierarchy is not configured</div>;
  }

  //console.log("hierarchy",hierarchy)
  return (
    <TreeWrapper>
      <Tree
        lineWidth={"2px"}
        lineColor={"#25425f"}
        lineBorderRadius={"5px"}
        label={
          <StyledNode color={getRandomColor()}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaUser style={{ fontSize: "1.2rem" }} />
              <h4>{`${hierarchy[0].top_designationname}`}</h4>
            </div>
          </StyledNode>
        }
      >
        {renderTreeNodes(hierarchy)}
      </Tree>
    </TreeWrapper>
  );
};

const TreeWrapper = styled.div`
  max-width: 100%;
  overflow-x: auto;
  padding: 20px;
`;

const ViewHierarchyChart = () => {

  const [HierarchyData,setHierarchyData] = useState();

    // Global data from session storage
    const GlobalDataFromSession = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    let CompanyId = GlobalData.GlobalCompanyId;

    // Use navigate
    const navigate = useNavigate();



    // To get Hierarchy details as per the id
    const GetHierarchyListById = () => {
      commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/hrhierarchy/get_hr_hierarchy_list_by_id`, {
          CompanyId: CompanyId,
        })
        .then((res) => {
          const FormatedHierarchyData = res.data.data;
          setHierarchyData(FormatedHierarchyData);
          //console.log("FormatedHierarchyData",FormatedHierarchyData)
        })
        .catch((error) => {
          Failed(error.response.message);
        });
    };
  
    // UseEffect declaration
    useEffect(() => {
      GetHierarchyListById();
    }, []);

//   return <StyledTree data={GlobalHierarchyData} />;

  return(
    <>
        <MainContainerWrapper headingText="Hierarchy" >
            <Box bgcolor={'white'}>
            <StyledTree data={HierarchyData}/>
            </Box>

            <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Button
                    sx={{
                        background: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        padding:{
                            lg: "0 70px",
                            md: "0 70",
                            xs:'0 50px'

                        },
                        height: "3rem",
                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                    }}

                    onClick={() => {
                              
                      // SOP-66 (commented and included by TS)
                      // navigate('/add_employee')
                      window.history.back();
                    }} 
                >
                    Back
                </Button>
             </Box>

        </MainContainerWrapper>
    </>
  )
};

export default ViewHierarchyChart;
// End of SOP-56