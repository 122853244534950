// Included by sanjana SOP-19 to creta epage for asset master
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import deleteAlert from '../../component/HOC/deleteAlert';
import Swal from 'sweetalert2';


//================================= Asset ============================// 

const AssetPage =()=> {

    // to declare navigate
    const navigate = useNavigate();

    // useState
    let  [order, setOrder] = React.useState<"desc" | "asc">("desc");
    let  [orderBy, setOrderBy] = React.useState("");
    let  [tableData, setTableData] = React.useState([]);
    const [page, setPage] = React.useState<number>(0);
    const [count, setCount] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
    // Included by sanjana SOP-52 to set count
    let AssetRequestCount: any;
    // End of SOP-52
           


            // Head cell of categories
            const CategoriesHeadCell = [
        
                {
                 id: "categoryname",
                 numeric: false,
                 disablePadding: false,
                 label: "Categories",
                 isSort: true,
                 isBold: true,
               },
               {
                id: "subcategoryname",
                numeric: false,
                disablePadding: false,
                label: "Sub-Categories",
                isSort: true,
                isBold: true,
              },
              {
                id: "assetname",
                numeric: false,
                disablePadding: false,
                label: "Asset",
                isSort: true,
                isBold: true,
              },
              {
                id: "serialnumber",
                numeric: false,
                disablePadding: false,
                label: "Serial Number",
                isSort: true,
                isBold: true,
              },
              {
                id: "location",
                numeric: false,
                disablePadding: false,
                label: "Location",
                isSort: true,
                isBold: true,
              },
              {
                id: "isallocated",
                numeric: false,
                disablePadding: false,
                label: "Allocated",
                isSort: true,
                isBold: true,
              },
              //SOP-74 Included By PCS [Inlcuding 'used By' column in Grid]
              {
                id: "employee_name",
                numeric: false,
                disablePadding: false,
                label: "Used By",
                isSort: true,
                isBold: true,
              },
              //EOF SOP-74 Included By PCS [Inlcuding 'used By' column in Grid]
               {
                id: "isactive",
                numeric: false,
                disablePadding: false,
                label: "Active",
                isSort: true,
                isBold: true,
                // defaultValue:initialActivestatus
              },
              {
                id: "action",
                numeric: false,
                disablePadding: false,
                label: "Actions",
                isSort: false,
              },
               ];
        
        
        
            const [cells, setCells] = useState<any>(CategoriesHeadCell);
    
          //  Global data from session storage
            const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
            const GlobalData = JSON.parse(GlobalDataFromSession);
            //console.log("GlobalData",GlobalData)
            let CompanyId = GlobalData.GlobalCompanyId
            //console.log("CompanyId",CompanyId)
    
              //  To get categories list search API  
               const getassetlistsearch = () => 
               {
             
             // Initializing Variable 
                 let CategoryName = null;
                 let SubCategoryName = null;
                 let AssetName = null;
                 let SerialNo = null;
                 let Location = null;
                 let IsAllocated = null;
                 let bActiveState = ""; 
                 let AllocatedTo = null;//SOP-74 Inclded By PCS [Testing feedback corrections]
             
               for (const item of cells)
               {
                  if (item.id === "categoryname") {
                   CategoryName = item.value;
                 }

                 if (item.id === "subcategoryname") {
                    SubCategoryName = item.value;
                  }

                  if (item.id === "assetname") {
                    AssetName = item.value;
                  }

                  if (item.id === "serialnumber") {
                    SerialNo = item.value;
                  }

                  if (item.id === "location") {
                    Location = item.value;
                  }

                  if (item.id === "isallocated") {
                    IsAllocated = item.value;
                  }
                 //SOP-74 Inclded By PCS [Testing feedback corrections]
                   if (item.id === "employee_name") {
                    AllocatedTo = item.value;
                  }
                 //EOF SOP-74 Inclded By PCS [Testing feedback corrections]
             
                  //SOP-19 Included By PCS[Testing feedback corrections- to make search functionality work in Active column ]
                  // if (item.id === "active") {
                  if (item.id === "isactive") {
                  //EOF SOP-19 Included By PCS Testing feedback corrections

                    bActiveState = item.value;
                 }
               }
        
              //  if(bActiveState === ""){
              //   initialActivestatus = ""
              //  }
        
                 commonService
                 .postService(`${process.env.REACT_APP_BACKEND_URL}/master/get_asset_list_search`, {
                    SearchCategoryName:CategoryName,
                    SearchSubCategoryName:SubCategoryName,
                    SearchAssetName:AssetName,
                    SearchSerialNumber:SerialNo,
                    SearchLocation: Location,
                    SearchIsallocated: IsAllocated,
                    SearchAllocatedTo:AllocatedTo,//SOP-74 Included By PCS [Testing feedback corrections]
                    SearchAssetActive: bActiveState,
                    SortColumnName: orderBy,
                    SortColumnDirection: order,
                    StartIndex: page + 1,
                    PageSize: rowsPerPage,
                    CompanyId:CompanyId,
                 })
                 .then((res: any) => 
                 {
                    console.log("res 14785",res)
                 const formattedTableData= res.data.data.map((item:any)=>{
                 return {...item,
                    id:item.assetid
                }
                   }) 
                   //console.log("formattedTableData",formattedTableData)
                   
                 setTableData(formattedTableData);
                 if(res.data.data[0]===undefined)
                   {
                     setCount(0);
                   }
                   else
                   {
                     setCount(res.data.data[0].total_count);
                   }
                 })
               .catch((error) => 
                 {
                   if (error.response.status === 404)
                   {
                     setTableData([]);
                   }
                  Failed(error.response.message);
                 });
               };
        
               
            // Sort Handler
            const handleRequestSort = (event: any, property: any) =>
            {
            const isAsc = orderBy === property && order === "asc";
            order = isAsc ? "desc" : "asc";
            setOrder(order);
            orderBy = property;
            setOrderBy(orderBy);
            };
        
            // Handle Delete
            const handleDeleteFleet = (id: string) => {



                deleteAlert({
                  title: "Delete",
                  message: "Are you sure?",
                  confirmButtonText: "Yes",
                  onConfirmed: DeleteCategory,
                  data: id,
                });
              };
        
            const handleChangeList = () => {
              if (page === 0) getassetlistsearch();
              else setPage(0);
            };


            const DeleteCategory = (id: string) =>{

            // Included by sanjana SOP-52 to add failed message content
            if(AssetRequestCount > 0){
              Failed("Reference information is found in the transaction table");
            }
            else{
              const DeleteAssetPayload={
                AsetId:id,
                CompanyId:CompanyId,
                IsRemove:'Y'
            }
    
            //console.log("DeleteAssetPayload",DeleteAssetPayload)
    
            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/master/asset_create_update_delete`, DeleteAssetPayload)
            .then((res) => {
              if (res.status === 200) {
                  Success("Asset Deleted successfully");
                  handleChangeList();
                  getassetlistsearch();
              }
              }).catch((error) => {
                  console.error("Error:", error);
              });              
            }
            // End of SOP-42
          

            }
        
        
            // Action function of table icons
            const actionFunction = (id: string, actionText: string) => 
            {
                if (actionText === "edit"){
                    navigate(`/edit_asset/${id}`)
                };
                if (actionText === "delete"){
                  // Included by sanjana SOP-52 to call get_asset_request_count API
                  GetAssetRequestCount(id)
                  // End of SOP-52
                  handleDeleteFleet(id)
                  

                } 
            };
          
        
            // Search Handler of table
            const handleSearch = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                const search = [...cells];
                const index = cells.findIndex((c: any) => c.id === e.target.name);
                //console.log("e.target.name",e.target.name)
                if (index !== -1) {
                    search[index].value = e.target.value;
                }
                //console.log("search",search)
                setCells(search);
                handleChangePage(setPage,0);
                getassetlistsearch();
            };
        
        
            // Pagination Handler of table
            const handleChangePage = (event: unknown, newPage: number) => {
            setPage(newPage);
            };
        
            // Rows Per page Handler
            const handleChangeRowsPerPage = (
            event: React.ChangeEvent<HTMLInputElement>
                ) => {
            setRowsPerPage(+event.target.value);
            setPage(0);
            };

            // Included by sanjana SOP-52 To get Asser request count details as per the asset id
            const GetAssetRequestCount =(AssetId:any)=>{
              commonService
              .postService(`${process.env.REACT_APP_BACKEND_URL}/requestanasset/get_asset_request_count`, {
                          AssetId:AssetId,
                          CompanyId:CompanyId
              })
              .then((res: any) => 
              { 
                  //console.log("res",res)
                  const AssetRequestCountFromResponse = res.data.data[0]
                  //console.log("AssetRequestCount",AssetRequestCountFromResponse)
                  AssetRequestCount = AssetRequestCountFromResponse.asset_count
                  //console.log("AssetRequestCount",AssetRequestCount)
              }).catch((error) =>  
              { 
                Failed(error.response.message);
              });
          }
          // End of SOP-52
        
        
            useEffect(() => {
                getassetlistsearch();
              }, [rowsPerPage, page, orderBy, order]); 
             

    return(
        <>
        <MainContainerWrapper 
        headingText='Assets'
        handleClick={() =>{
            navigate('/add_asset')
        }}
        buttonText="Add Asset"
        isSearch={false}
        isCreateButton={true}
        buttonIcon={<span>&#43;</span>}
        
        >
            <Box>
            <Box bgcolor={'white'}>
            <Box>
            <EnhancedTable
                headCells={CategoriesHeadCell}
                rows={tableData}
                rowsPerPage={rowsPerPage}
                page={page}
                handleSearch={handleSearch}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                actionFunction={actionFunction}
                isView={false}
                isDelete={true}
                isEdit={true}
                total={count}
                isSearch={true}
                />
            </Box>

        </Box>
          </Box>
          <Box mt={5} display={'flex'} justifyContent={'flex-start'}>
          <Button
              sx={{
                  background: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  padding:{
                      lg: "0 70px",
                      md: "0 70",
                      xs:'0 50px'

                  },
                  // color: {accent},
                  height: "3rem",
                  boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
              }}

              onClick={()=>{
                // Modifien by sanjana SOP-25 to change path of back button
                // navigate('/backoffice')
                // navigate('/home')
                // End of SOP-25


                {/* //SOP-75 feedback points L1 modified by PCS [changing nav link]*/}
                     // navigate('/home')
                     navigate('/backoffice')

                {/* //EOF SOP-75 feedback points L1 modified by PCS [changing nav link]*/}

              }} 
          >
              Back
          </Button>

          </Box>

        </MainContainerWrapper>
        
        </>
    )

}
export default AssetPage;

// End of SOP-19