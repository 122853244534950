// SOP-61 Included By PCS

// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Success } from '../../atic-common-helpers/helpers/toast.helper';
import Swal from "sweetalert2";


// Page start
const CreateCategories = 
// To get handleAddDocpointCategoriesClose & getcategorylistsearch from category page
    ({
        handleAddDocpointCategoriesClose,
        // getcategorylistsearch
    }: {
        handleAddDocpointCategoriesClose: (event: React.MouseEvent) => void,
        // getcategorylistsearch: (event: React.MouseEvent) => void
    }) =>{

    // use navigate and use state declaration
    const navigate = useNavigate();

    const [CategoryError, setCategoryError]=useState(false)
    const [CategoryValue, setCategoryValue]=useState('')
    const [CategoryErrorMessage, setCategoryErrorMessage]=useState('')
    const [CompanyId, setCompanyId] =useState('')
    const [CreatedBy, setCreatedBy] =useState('')
    const [CategoryValueEndTrim, setCategoryValueEndTrim]=useState('')
 

    //  Handle Category while typing Category and validations
    const handleCategoryChange = (event:any) => {
        const trimmedCategoryValue = event.target.value.trim();
            setCategoryValue(event.target.value);
            setCategoryValueEndTrim(trimmedCategoryValue);
        

        if (trimmedCategoryValue === '') {
            setCategoryError(true);
            setCategoryErrorMessage("Category is required");
        } 
        else if( trimmedCategoryValue.length >255 ){
            setCategoryError(true);
            setCategoryErrorMessage("Max 255 characters");
         }
        else {
            setCategoryError(false);
            setCategoryErrorMessage("");
           }
                                                   };

    // Exist Category name API
    const GetCategoryNameExist = async () => {   
        
        const  ExistCategoryNamePayload = {
         
            ExistDocPointCategoryName:CategoryValueEndTrim,
            DocPointCategoryId:null,
            CompanyId:CompanyId
                                          }

        // console.log('payload',ExistCategoryNamePayload)
        try {
           const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/docpoint/get_docpoint_categories_name_exists`,ExistCategoryNamePayload);
           return res.data.data || [];
          } 
        catch (err) {
        console.log( "co",err); 
                     }
                                           };


    // To render handle close function to close dialouge box
            const handleClose = (event: React.MouseEvent) => {
                if (handleAddDocpointCategoriesClose && typeof handleAddDocpointCategoriesClose === 'function') {
                    handleAddDocpointCategoriesClose(event);
                }
                                                            };

    // to render getcategorylistsearch function after closing dialouge box
            // const handlegetcategorylistsearch= (event: React.MouseEvent) => {
            //     if (getcategorylistsearch && typeof getcategorylistsearch === 'function') {
            //         getcategorylistsearch(event);
            //     }
            //                                                                   };

    // To add category

 // Define a type for errors
    type Errors = {
        category?:boolean;
              };
    const HandleAddCategory = async (event:any)=>
    {
        event.preventDefault();
    
        let errors: Errors = {};

        const CategoryNameList = await GetCategoryNameExist();
        if (CategoryNameList.length > 0) {
            setCategoryError(true);
            setCategoryErrorMessage("Category already exists");
            errors.category = true;
            return;
        }
    
        const trimmedCategoryValue = CategoryValue.trim();
        if (trimmedCategoryValue === '') {
            errors.category = true;
            setCategoryErrorMessage("Category is required");
        } else if (trimmedCategoryValue.length > 255) {
            errors.category = true;
            setCategoryErrorMessage("Max 255 characters");
        }
    
        if (Object.keys(errors).length > 0) {
            // If there are errors, update the state with all the errors
            setCategoryError(errors.category || false);
            return;
        }
    
        const todayDate = new Date();
        const Year = todayDate.getFullYear();
        const Month = (todayDate.getMonth() + 1).toString().padStart(2, '0');
        const Day = todayDate.getDate().toString().padStart(2, '0');
        const FormattedDate = `${Year}-${Month}-${Day}`;
       
    
        const AddCategoryPayload = {
            SaveUpdateKey: "create",
            DocPointCategoryName: CategoryValue,
            DocPointCategoryId: null,
            CompanyId: CompanyId,
            Active: "Y",
            CreatedDateTime: null,
            CreatedBy: CreatedBy,
            IsRemove: null
                                   }
    
        // console.log("AddPL", AddCategoryPayload);
    
        Swal.fire({
            html: '<span style="font-size: 1.3rem;">Are you sure to save ? </span>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            cancelButtonColor: '#d33',
            confirmButtonColor:"#25425f",
            allowOutsideClick: false,
            })
        .then((result) =>
        {
                if (result.isConfirmed)
                {
                    commonService
                    .postService(`${process.env.REACT_APP_BACKEND_URL}/docpoint/docpoint_categories_create_update_delete`, AddCategoryPayload)
                    .then((res) => {
                    if (res.status === 200) {
                        Success("Category created successfully");
                        handleClose(event);
                        
                    }
                    }).catch((error) => {
                        console.error("Error:", error);
                    });
                } 
                else  
                {
                return
                }
       });
    }
    

    // UseEffect Declaration
        useEffect(() => {
            const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
            const GlobalData = JSON.parse(GlobalDataFromSession);
                setCompanyId(GlobalData.GlobalCompanyId)
                setCreatedBy(GlobalData.GlobalUserId)
        }, []);

 
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
     };


    return(
        <>
            <Box>
                <Box display={'flex'} justifyContent={'center'}>
                    <Box bgcolor={'white'} borderRadius={3}
                    sx={{
                        width:{
                            lg:900,
                            md:600,
                            xs:700
        
                        }}}
                    >
                    <Box p={3}>
                        <Typography variant='h1'>
                        New Category
                        </Typography>
                    </Box>
                        <Box p={3}>
                            <form  noValidate>
                                <Box>
                                <TextField
                                    onKeyDown={handleKeyDown} 
                                    type='text'
                                    autoFocus
                                    variant="standard"
                                    label='Category Name'
                                    name='Category'
                                    style={{ margin: '0 10px' }}
                                    fullWidth
                                    required
                                    error={CategoryError}
                                    onChange={handleCategoryChange}
                                    value={CategoryValue}
                                    helperText={CategoryErrorMessage}
                                /> 
                                </Box>
                            <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Button
                                    sx={{
                                        background: 'white',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}

                                    onClick={handleClose} 
                                >
                                    Back
                                </Button>

                                <Button
                                    sx={{
                                        background: '#25425F',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        "&:hover":{
                                            backgroundColor:'#25425F',
                                            color:'white',
                                        },
                                        color: 'white',
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}
                                    type='submit'
                                    onClick={(event)=>{
                                        HandleAddCategory(event);
                                    }}
                                >
                                    Create
                                </Button>
                                </Box>
                            </form>
                        </Box>

                    </Box>
                </Box>
            </Box>
        </>
    )

}

export default CreateCategories;

// EOF SOP-61