// (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager (Page created by TS )
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    Grid,   
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControl,
    FormHelperText,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FcInfo } from "react-icons/fc";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { PiBriefcaseBold } from "react-icons/pi";
import { PiUserListFill } from "react-icons/pi";

const AddHRNewHireRequest =()=>{

    // Use navigate
    const navigate = useNavigate();
    
    // Manager
    const [ManagerOptions, setManagerOptions] = useState<OptionType[]>([]);
    const [selectedManagerId, setselectedManagerId] = useState<string | null>('');
    const [ManagerError, setManagerError]=useState(false)
    const [ManagerErrorMessage, setManagerErrorMessage]=useState('')  

    // Supervisor
    const [SupervisorOptions, setSupervisorOptions] = useState<OptionType[]>([]);
    const [selectedSupervisorId, setselectedSupervisorId] = useState<string | null>('');
    const [SupervisorError, setSupervisorError]=useState(false)
    const [SupervisorErrorMessage, setSupervisorErrorMessage]=useState('')  

    // EmploymentTypeList usestate   
    const [EmploymentTypeListOptions, setEmploymentTypeListOptions] = useState<OptionType[]>([]);
    const [selectedEmploymentId, setselectedEmploymentId] = useState<string | null>('');
    const [EmploymentError, setEmploymentError]=useState(false)
    const [EmploymentErrorMessage, setEmploymentErrorMessage] = useState('')  
    
    // ContractTypeList usestate
    const [ContractTypeListOptions, setContractTypeListOptions] = useState<OptionType[]>([]);
    const [selectedContractTypeId, setselectedContractTypeId] = useState<string | null>('');
    const [ContractTypeError, setContractTypeError]=useState(false)
    const [ContractTypeErrorMessage, setContractTypeErrorMessage] = useState('') 
//
    // Department usestate
    const [DepartmentOptions, setDepartmentOptions] = useState<OptionType[]>([]);
    const [selectedDepartmentId, setselectedDepartmentId] = useState<string | null>('');
    const [DepartmentError, setDepartmentError]=useState(false)
    const [DepartmentErrorMessage, setDepartmentErrorMessage] = useState('') 

    // RequestStatus usestate
    const [RequestStatusOptions, setRequestStatusOptions] = useState<OptionType[]>([]);
    const [selectedRequestStatusId, setselectedRequestStatusId] = useState<string | null>('');
    const [RequestStatusError, setRequestStatusError]=useState(false)
    const [RequestStatusErrorMessage, setRequestStatusErrorMessage] = useState('') 

    // DateOfHireReques usestate
    const [DateOfHireRequestError, setDateOfHireRequestError]=useState(false)
    const [DateOfHireRequestValue, setDateOfHireRequestValue] = useState<dayjs.Dayjs | null>(null);
    const [DateOfHireRequestErrorMessage, setDateOfHireRequestErrorMessage]=useState('')

    // WithInHowManyDaysRequestToBeFilled usestate
    const [WithInHowManyDaysRequestToBeFilledError, setWithInHowManyDaysRequestToBeFilledError] = useState(false)
    const [WithInHowManyDaysRequestToBeFilledValue, setWithInHowManyDaysRequestToBeFilledValue]=useState('')
    const [WithInHowManyDaysRequestToBeFilledErrorMessage, setWithInHowManyDaysRequestToBeFilledErrorMessage] = useState('')
 
    // HowManyPositionsAreRequested usestate
    const [HowManyPositionsAreRequestedError, setHowManyPositionsAreRequestedError] = useState(false)
    const [HowManyPositionsAreRequestedValue, setHowManyPositionsAreRequestedValue]=useState('')
    const [HowManyPositionsAreRequestedErrorMessage, setHowManyPositionsAreRequestedErrorMessage] = useState('')
 
    // DivisionAndFunction usestate
    const [DivisionAndFunctionNameError, setDivisionAndFunctionNameError] = useState(false)
    const [DivisionAndFunctionNameValue, setDivisionAndFunctionNameValue]=useState('')
    const [DivisionAndFunctionNameErrorMessage, setDivisionAndFunctionNameErrorMessage] = useState('')

    // Location usestate
    const [LocationError, setLocationError] = useState(false)
    const [LocationValue, setLocationValue]=useState('')
    const [LocationErrorMessage, setLocationErrorMessage] = useState('')
    
    // JobCodeAndTitle usestate
    const [JobCodeAndTitleError, setJobCodeAndTitleError] = useState(false)
    const [JobCodeAndTitleValue, setJobCodeAndTitleValue]=useState('')
    const [JobCodeAndTitleErrorMessage, setJobCodeAndTitleErrorMessage] = useState('')

    // Justification usestate
    const [JustificationError, setJustificationError] = useState(false)
    const [JustificationValue, setJustificationValue]=useState('')
    const [JustificationErrorMessage, setJustificationErrorMessage] = useState('')

    // OverviewAndRequestedJobProfile usestate
    const [OverviewAndRequestedJobProfileError, setOverviewAndRequestedJobProfileError] = useState(false)
    const [OverviewAndRequestedJobProfileValue, setOverviewAndRequestedJobProfileValue]=useState('')
    const [OverviewAndRequestedJobProfileErrorMessage, setOverviewAndRequestedJobProfileErrorMessage] = useState('')

    // MinimumQualification usestate
    const [MinimumqualificationNeedError, setMinimumqualificationNeedError] = useState(false)
    const [MinimumqualificationNeedValue, setMinimumqualificationNeedValue]=useState('')
    const [MinimumqualificationNeedErrorMessage, setMinimumqualificationNeedErrorMessage] = useState('')
 
    // PreferredQualification usestate
    const [PreferredQualificationNeedError, setPreferredQualificationNeedError] = useState(false)
    const [PreferredQualificationNeedValue, setPreferredQualificationNeedValue]=useState('')
    const [PreferredQualificationNeedErrorMessage, setPreferredQualificationNeedErrorMessage] = useState('')

    // Keywords usestate
    const [KeywordsError, setKeywordsError] = useState(false)
    const [KeywordsValue, setKeywordsValue]=useState('')
    const [KeywordsErrorMessage, setKeywordsErrorMessage] = useState('')
 
    // OtherRemarks usestate
    const [OtherRemarksError, setOtherRemarksError] = useState(false)
    const [OtherRemarksValue, setOtherRemarksValue]=useState('')
    const [OtherRemarksErrorMessage, setOtherRemarksErrorMessage] = useState('')


    type OptionType = {
        name: string;
        value: string;
    };

    // drop down filter option
    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });


    // Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
   // console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId
    let CreatedBy = GlobalData.GlobalUserId 

    const handleDateOfHireRequest = (date:any) => {
        setDateOfHireRequestValue(date)
        
        if (!date) {
            setDateOfHireRequestError(true);
            setDateOfHireRequestErrorMessage("Date of Joining is required");

        }
        else {
            setDateOfHireRequestError(false);
            setDateOfHireRequestErrorMessage("");
        }
    };


      // To handle WithInHowManyDaysRequestToBeFilled  and validation
      const handleWithInHowManyDaysRequestToBeFilledChange =( event:any)=>{
        const trimmedWithInHowManyDaysRequestToBeFilledValue = event.target.value.trim();
        setWithInHowManyDaysRequestToBeFilledValue(event.target.value);
        if (trimmedWithInHowManyDaysRequestToBeFilledValue === '') {
            setWithInHowManyDaysRequestToBeFilledError(true);
            setWithInHowManyDaysRequestToBeFilledErrorMessage("With in How many days, request to be filled is required");
        } 
        else if( trimmedWithInHowManyDaysRequestToBeFilledValue.length >225 ){
            setWithInHowManyDaysRequestToBeFilledError(true);
            setWithInHowManyDaysRequestToBeFilledErrorMessage("Max 255 number");
        }
        else if (!/^[0-9 +]+$/.test(trimmedWithInHowManyDaysRequestToBeFilledValue)) {
            setWithInHowManyDaysRequestToBeFilledError(true);
            setWithInHowManyDaysRequestToBeFilledErrorMessage("Numbers only");
        }
        else {
            setWithInHowManyDaysRequestToBeFilledError(false);
            setWithInHowManyDaysRequestToBeFilledErrorMessage("");
        }
    }

    // To handle HowManyPositionsAreRequested and validation
      const handleHowManyPositionsAreRequestedChange =( event:any)=>{
        const trimmedWithInHowManyDaysRequestToBeFilledValue = event.target.value.trim();
        setHowManyPositionsAreRequestedValue(event.target.value);
        if (trimmedWithInHowManyDaysRequestToBeFilledValue === '') {
           setHowManyPositionsAreRequestedError(true);
           setHowManyPositionsAreRequestedErrorMessage("How many positions are requested is required");
        } 
        else if( trimmedWithInHowManyDaysRequestToBeFilledValue.length >225 ){
            setHowManyPositionsAreRequestedError(true);
            setHowManyPositionsAreRequestedErrorMessage("Invalid number");
        }
        else if (!/^[0-9 +]+$/.test(trimmedWithInHowManyDaysRequestToBeFilledValue)) {
            setHowManyPositionsAreRequestedError(true);
            setHowManyPositionsAreRequestedErrorMessage("Numbers only");
        }
        else {
            setHowManyPositionsAreRequestedError(false);
            setHowManyPositionsAreRequestedErrorMessage("");
        }
    }

    // handleKeywords and validations
    const handleKeywordsChange =( event:any)=>{
        const trimmedKeywordsValue = event.target.value.trim();
        setKeywordsValue(event.target.value);
        if (trimmedKeywordsValue === '') {
            setKeywordsError(true);
            setKeywordsErrorMessage("Keywords is required");
        } 
        else if( trimmedKeywordsValue.length >225 ){
            setKeywordsError(true);
            setKeywordsErrorMessage("Max 255 characters");
        }
        else {
            setKeywordsError(false);
            setKeywordsErrorMessage("");
        }
    }

     // handle MinimumqualificationNeed and validations
    const handleMinimumqualificationNeedChange =( event:any)=>{
        const trimmedMinimumqualificationNeedValue = event.target.value.trim();
       setMinimumqualificationNeedValue(event.target.value);
        if (trimmedMinimumqualificationNeedValue === '') {
           setMinimumqualificationNeedError(true);
           setMinimumqualificationNeedErrorMessage("Minimum qualification Need is required");
        } 
        else if(trimmedMinimumqualificationNeedValue.length >225 ){
            setMinimumqualificationNeedError(true);
            setMinimumqualificationNeedErrorMessage("Max 255 characters");
        }
         else {
            setMinimumqualificationNeedError(false);
            setMinimumqualificationNeedErrorMessage("");
        }
    }

    // handle DivisionAndFunction and validations
    const handleDivisionAndFunctionChange =( event:any)=>{
        const trimmedDivisionAndFunctionValue = event.target.value.trim();
        setDivisionAndFunctionNameValue(event.target.value);
        if( trimmedDivisionAndFunctionValue.length >225 ){
            setDivisionAndFunctionNameError(true);
            setDivisionAndFunctionNameErrorMessage("Max 255 characters");
        }
        else {
            setDivisionAndFunctionNameError(false);
            setDivisionAndFunctionNameErrorMessage("");
        }
    }

    // handle Location and validations
    const handleLocationChange =( event:any)=>{
        const trimmedLocationValue = event.target.value.trim();
        setLocationValue(event.target.value);
        if( trimmedLocationValue.length >225 ){
            setLocationError(true);
            setLocationErrorMessage("Max 255 characters");
        }
        else {
            setLocationError(false);
            setLocationErrorMessage("");
        }
    }

    // handle OtherRemarks and validations
    const handleOtherRemarksChange =( event:any)=>{
        const trimmedOtherRemarksValue = event.target.value.trim();
        setOtherRemarksValue(event.target.value);
        if( trimmedOtherRemarksValue.length >225 ){
            setOtherRemarksError(true);
           setOtherRemarksErrorMessage("Max 255 characters");
        }
        else {
           setOtherRemarksError(false);
           setOtherRemarksErrorMessage("");
        }
    }

    // handle JobCodeAndTitle and validations
    const handleJobCodeAndTitleChange =( event:any)=>{
        const trimmedJobCodeAndTitleValue = event.target.value.trim();
        setJobCodeAndTitleValue(event.target.value);
        if (trimmedJobCodeAndTitleValue === '') {
            setJobCodeAndTitleError(true);
            setJobCodeAndTitleErrorMessage("Job Code Title and Job is required");
        } 
        else if( trimmedJobCodeAndTitleValue.length >225 ){
            setJobCodeAndTitleError(true);
            setJobCodeAndTitleErrorMessage("Max 255 characters");
        }
        else {
            setJobCodeAndTitleError(false);
            setJobCodeAndTitleErrorMessage("");
        }
    }

    // handle Justification and validations
    const handleJustificationChange =( event:any)=>{
        const trimmedLocationValue = event.target.value.trim();
        setJustificationValue(event.target.value);
        if( trimmedLocationValue.length >225 ){
            setJustificationError(true);
            setJustificationErrorMessage("Max 255 characters");
        }
        else {
            setJustificationError(false);
            setJustificationErrorMessage("");
        }
    }

    // handle PrefferedQualification and validations
    const handlePrefferedQualificationChange =( event:any)=>{
        const trimmedPrefferedQualificationValue = event.target.value.trim();
        setPreferredQualificationNeedValue(event.target.value);
        if( trimmedPrefferedQualificationValue.length >225 ){
            setPreferredQualificationNeedError(true);
            setPreferredQualificationNeedErrorMessage("Max 255 characters");
        }
        else {
            setPreferredQualificationNeedError(false);
            setPreferredQualificationNeedErrorMessage("");
        }
    }

    // handle OverviewAndRequestedJobProfile and validations
    const handleOverviewAndRequestedJobProfileChange =( event:any)=>{
        const trimmedLocationValue = event.target.value.trim();
        setOverviewAndRequestedJobProfileValue(event.target.value);
        if( trimmedLocationValue.length >225 ){
            setOverviewAndRequestedJobProfileError(true);
            setOverviewAndRequestedJobProfileErrorMessage("Max 255 characters");
        }
        else {
            setOverviewAndRequestedJobProfileError(false);
            setOverviewAndRequestedJobProfileErrorMessage("");
        }
    }
    
// Employee List API
const GetEmployeeList = async () => {
    const EmployeeListPayload = {
        CompanyId: CompanyId,
        active: "Y"
    }
    try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/getEmployeeList`, EmployeeListPayload);
        // console.log("formattedEmployeeList",res)
        let formattedEmployeeList = res.data.data.map((item: any) => {
         
            const { employee_id, employee_name } = item;
            return {
                name: employee_name,
                value: employee_id,
            };
        });
        setManagerOptions(formattedEmployeeList);
        setSupervisorOptions(formattedEmployeeList);
      
    } catch (err) {
        console.error(err);
    }
};

// RequestStatusList API
// const GetHRHireRequestStatusList = async () => {
//     try {
//         const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/getHRHireRequestStatusList`);
//         //console.log("IssueType" ,res.data.data);
//         let formattedPriorityType = res.data.data.map((item:any) => {
//         const { sys_hr_hirerequest_status_id, sys_hr_hirerequest_status_name } = item;
//         return {
//             name: sys_hr_hirerequest_status_name,
//             value: sys_hr_hirerequest_status_id,
//         };
//         });
//         setRequestStatusOptions(formattedPriorityType)
        
//     } catch (err) {
//         //console.log( "co",err);
//     }
// };

const GetHRHireRequestStatusList = async () => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/getHRHireRequestStatusList`);
        //console.log("IssueType" ,res.data.data);
        let formattedPriorityType = res.data.data.map((item: any) => {
            const { sys_hr_hirerequest_status_id, sys_hr_hirerequest_status_name } = item;
            return {
                name: sys_hr_hirerequest_status_name,
                value: sys_hr_hirerequest_status_id,
            };
        });
        setRequestStatusOptions(formattedPriorityType);

        //  Opened as the default value
        const openedStatus = formattedPriorityType.find((option: OptionType) => option.name.toLowerCase() === 'Opened ');
            if (openedStatus) {
                setselectedRequestStatusId(openedStatus.value);
            }

    } catch (err) {
        //console.log( "co",err);
    }
}; 

    
// HireRequest EmploymentTypeList API
const GetHRHireRequestEmploymentTypeList = async () => { 
    try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/getHRHireRequestEmploymentTypeList`);
        console.log("GetHRHireRequestEmploymentTypeList" ,res.data.data);
        let formattedPriorityType = res.data.data.map((item:any) => {
        const { sys_hr_employment_type_id, sys_hr_employment_type_name } = item;
        return {
            name: sys_hr_employment_type_name,
            value: sys_hr_employment_type_id,
        };
        });
        setEmploymentTypeListOptions(formattedPriorityType)
    } catch (err) {
        //console.log( "co",err);
    }
};
    
// ContractType List API
const GetHRHireRequestContractTypeList = async () => { 
    try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/getHRHireRequestContractTypeList`);
        console.log("getHRHireRequestContractTypeList" ,res.data.data);
        let formattedContractType = res.data.data.map((item:any) => {
        const { sys_hr_contract_type_id, sys_hr_contract_type_name } = item;
        return {
            name: sys_hr_contract_type_name,
            value: sys_hr_contract_type_id,
        };
        });
        setContractTypeListOptions(formattedContractType)
    } catch (err) {
        //console.log( "co",err);
    }
    };
    

// DepartmentList API
const GetDepartmentList = async () => { 
    const EmployeeListPayload = {
        CompanyId: CompanyId
    }
    try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/getDepartmentList`, EmployeeListPayload);
        // console.log("formattedEmployeeList",res)
        let formattedEmployeeList = res.data.data.map((item: any) => {
            const { departmentid, departmentname } = item;
            return {
                name: departmentname,
                value: departmentid,
            };
        });
        setDepartmentOptions(formattedEmployeeList);
    } catch (err) {
        console.error(err);
    }
};

   // Define a type for errors
    type Errors = {
        ManagerId?: boolean;
        Supervisor?: boolean;
        EmploymentType?: boolean;
        Department?: boolean;
        CurrentRequestStatus?: boolean;
        DateOfHireRequest?: boolean;
        WithInHowManyDaysRequestToBeFilled?: boolean;
        HowManyPositionsAreRequested?: boolean;
        DivisionAndFunction?: boolean;
        Location?: boolean;
        JobCodeAndTitle?: boolean;
        Justification?: boolean;
        OverviewAndRequestedJobProfile?: boolean;
        PreferredQualification?: boolean;
        MinimumQualification?: boolean;
        Keywords?: boolean;
        OtherRemarks?: boolean;
        ContractType?: boolean;
        };

    const CreateHRHireRequest =async (event:any)=>{
        event.preventDefault();

        let errors: Errors = {};

        let trimmedDateOfHireRequest;
        if (DateOfHireRequestValue === null || DateOfHireRequestValue === undefined) {
            errors.DateOfHireRequest = true;
            setDateOfHireRequestErrorMessage("Date of Hire Request is required");

        } else {
            const dateString = DateOfHireRequestValue.format(); 
            trimmedDateOfHireRequest = dateString.trim();

            if (trimmedDateOfHireRequest === "") {
                errors.DateOfHireRequest = true;
                setDateOfHireRequestErrorMessage("Date of Hire Request is required");
            } 
            else if(trimmedDateOfHireRequest.length < 25){
                errors.DateOfHireRequest = true;
                setDateOfHireRequestErrorMessage("Please check Date Of Hire Request");
            }
            else {
                setDateOfHireRequestErrorMessage("");
            }
        }

        let FormatedDateOfHireRequest;
        if (trimmedDateOfHireRequest) {
            const trimmedDate = new Date(trimmedDateOfHireRequest);
            const year = trimmedDate.getFullYear();
            const month = String(trimmedDate.getMonth() + 1).padStart(2, '0');
            const date = String(trimmedDate.getDate()).padStart(2, '0');
            FormatedDateOfHireRequest = `${year}-${month}-${date}`;
           } else {
        }
        
        const trimmedWithInHowManyDaysRequestToBeFilledValue = WithInHowManyDaysRequestToBeFilledValue.trim();
        if (trimmedWithInHowManyDaysRequestToBeFilledValue === '') {
            errors.WithInHowManyDaysRequestToBeFilled=true
            setWithInHowManyDaysRequestToBeFilledError(true);
            setWithInHowManyDaysRequestToBeFilledErrorMessage("With in How many days, request to be filled is required");
        } 
        else if( trimmedWithInHowManyDaysRequestToBeFilledValue.length >225 ){
            errors.WithInHowManyDaysRequestToBeFilled=true
            setWithInHowManyDaysRequestToBeFilledError(true);
            // setWithInHowManyDaysRequestToBeFilledErrorMessage("Max 255 characters");
            setWithInHowManyDaysRequestToBeFilledErrorMessage("Invalid number");
        }
        else if( !/^[0-9]+$/.test(trimmedWithInHowManyDaysRequestToBeFilledValue) ){
            errors.WithInHowManyDaysRequestToBeFilled=true
            setWithInHowManyDaysRequestToBeFilledError(true);
            setWithInHowManyDaysRequestToBeFilledErrorMessage("Numbers only");
        }

        const trimmedWithJobCodeAndTitleValue = JobCodeAndTitleValue.trim();
        if (trimmedWithJobCodeAndTitleValue === '') {
            errors.JobCodeAndTitle=true
            setJobCodeAndTitleError(true);
            setJobCodeAndTitleErrorMessage("Job Code And Title & Job is required");
        } 
        else if( trimmedWithJobCodeAndTitleValue.length >225 ){
            errors.JobCodeAndTitle=true
            setJobCodeAndTitleError(true);
            setJobCodeAndTitleErrorMessage("Max 255 characters");
        }
       

        const trimmedHowManyPositionsAreRequestedValue = HowManyPositionsAreRequestedValue.trim();
        if (trimmedHowManyPositionsAreRequestedValue === '') {
            errors.HowManyPositionsAreRequested=true
            setHowManyPositionsAreRequestedError(true);
            setHowManyPositionsAreRequestedErrorMessage("How many positions are requested is required");
        } 
        else if( trimmedHowManyPositionsAreRequestedValue.length >225 ){
            errors.HowManyPositionsAreRequested=true
            setHowManyPositionsAreRequestedError(true);
            // setWithInHowManyDaysRequestToBeFilledErrorMessage("Max 255 characters");
            setHowManyPositionsAreRequestedErrorMessage("Invalid number");
        }
        else if( !/^[0-9]+$/.test(trimmedHowManyPositionsAreRequestedValue) ){
            errors.HowManyPositionsAreRequested=true
            setHowManyPositionsAreRequestedError(true);
            setHowManyPositionsAreRequestedErrorMessage("Numbers only");
        }


        const trimmedKeywordsValue = KeywordsValue.trim();
        if (trimmedKeywordsValue === '') {
            errors.Keywords=true
            setKeywordsError(true);
            setKeywordsErrorMessage("Keywords is required");
        } 
        else if(trimmedKeywordsValue.length >225 ){
            errors.Keywords=true
            setKeywordsError(true);
            setKeywordsErrorMessage("Max 255 characters");
        }

        const trimmedMinimumqualificationNeedErrorValue = MinimumqualificationNeedValue.trim();
        if (trimmedMinimumqualificationNeedErrorValue === '') {
            errors.MinimumQualification=true
            setMinimumqualificationNeedError(true);
            setMinimumqualificationNeedErrorMessage("Minimum qualification Need is required");
        } 
        else if(trimmedMinimumqualificationNeedErrorValue.length >225 ){
            errors.MinimumQualification=true
            setMinimumqualificationNeedError(true);
            setMinimumqualificationNeedErrorMessage("Max 255 characters");
        }

        const trimmedPreferredqualificationNeedErrorValue = MinimumqualificationNeedValue.trim();
        if( trimmedPreferredqualificationNeedErrorValue.length >225 ){
            errors.PreferredQualification=true
            setPreferredQualificationNeedError(true);
            setPreferredQualificationNeedErrorMessage("Max 255 characters");
        } 
     
        const trimmedDivisionAndFunctionNameValue = DivisionAndFunctionNameValue.trim();
        if( trimmedDivisionAndFunctionNameValue.length >225 ){
           errors.DivisionAndFunction=true
           setDivisionAndFunctionNameErrorMessage("Max 255 characters");
        }
        
        const trimmedLocationValue = LocationValue.trim();
        if( trimmedLocationValue.length >225 ){
           errors.Location=true
           setLocationErrorMessage("Max 255 characters");
        }

        const trimmedOtherRemarksValue = OtherRemarksValue.trim();
        if( trimmedOtherRemarksValue.length >225 ){
           errors.OtherRemarks=true
           setOtherRemarksErrorMessage("Max 255 characters");
        }

        const trimmedJustificationValue = JustificationValue.trim();
        if( trimmedJustificationValue.length >225 ){
           errors.Justification=true
           setJustificationErrorMessage("Max 255 characters");
        }

        const trimmedPreferredQualificationValue = PreferredQualificationNeedValue.trim();
        if(trimmedPreferredQualificationValue.length >225 ){
           errors.Justification=true
           setPreferredQualificationNeedErrorMessage("Max 255 characters");
        }

          const trimmedOverviewAndRequestedJobProfileValue = OverviewAndRequestedJobProfileValue.trim();
        if( trimmedOverviewAndRequestedJobProfileValue.length >225 ){
           errors.Justification=true
           setOverviewAndRequestedJobProfileErrorMessage("Max 255 characters");
        }

        if( selectedManagerId === null || selectedManagerId === '' || selectedManagerId === undefined ){
            errors.ManagerId = true;
            setManagerErrorMessage("Name of the Hiring Manager is required");
        }
        if( selectedSupervisorId === null || selectedSupervisorId === '' || selectedSupervisorId === undefined ){
            errors.Supervisor = true;
           setSupervisorErrorMessage("Name of the Hiring Superviser is required");
        }
        if(selectedEmploymentId=== null || selectedEmploymentId === '' || selectedEmploymentId === undefined ){
            errors.EmploymentType = true;
          setEmploymentErrorMessage("Type of Employment Required is required");
        }
        if(selectedContractTypeId=== null || selectedContractTypeId === '' || selectedContractTypeId === undefined ){
            errors.ContractType = true;
            setContractTypeErrorMessage("Contract Type & Job is required");
        }
         if(selectedDepartmentId=== null || selectedDepartmentId === '' || selectedDepartmentId === undefined ){
            errors.Department = true;
            setDepartmentErrorMessage("Which Department is requested for Hire is required");
        }
        if(selectedRequestStatusId=== null || selectedRequestStatusId === '' || selectedRequestStatusId === undefined ){
            errors.CurrentRequestStatus = true;
            setRequestStatusErrorMessage("Current Status of Hire Request is required");
        }
            if (Object.keys(errors).length > 0) {
            // If there are errors, update the state with all the errors

                setManagerError(errors.ManagerId || false);
                setSupervisorError(errors.Supervisor || false);
                setEmploymentError(errors.EmploymentType || false);
                setContractTypeError(errors.ContractType || false);
                setDepartmentError(errors.Department || false);
                setRequestStatusError(errors.CurrentRequestStatus || false);
                setDateOfHireRequestError(errors.DateOfHireRequest || false);
                setWithInHowManyDaysRequestToBeFilledError(errors.WithInHowManyDaysRequestToBeFilled || false);
                setHowManyPositionsAreRequestedError(errors.HowManyPositionsAreRequested || false);
                setDivisionAndFunctionNameError(errors.DivisionAndFunction || false);
                setLocationError(errors.Location || false);
                setJobCodeAndTitleError(errors.JobCodeAndTitle || false);
                setJustificationError(errors.Justification || false);
                setOverviewAndRequestedJobProfileError(errors.OverviewAndRequestedJobProfile || false);
                setPreferredQualificationNeedError(errors.PreferredQualification || false);
                setKeywordsError(errors.Keywords || false);
                setOtherRemarksError(errors.OtherRemarks || false);
                // console.log("errors", errors)
            return;
        }

        const CreateHRHireRequestPayload={
            "SaveUpdateKey":"create",
            "CompanyId":CompanyId,
            "HirerequestEmploymentTypeId":selectedEmploymentId,
            "HirerequestStartDate": FormatedDateOfHireRequest,
            "HirerequestDuration":WithInHowManyDaysRequestToBeFilledValue,
            "HirerequestTotalOpenPosition":HowManyPositionsAreRequestedValue,
            "HirerequestTotalFilledPosition":0,
            "HirerequestTotalPendingPosition":0,
            "HirerequestMangerId":selectedManagerId,
            "HirerequestSupervisorId":selectedSupervisorId,
            "HirerequestDepartmentId":selectedDepartmentId,
            "HirerequestDivisionFunction":DivisionAndFunctionNameValue,
            "HirerequestLocation":LocationValue,
            "HirerequestJobcodeTitle":JobCodeAndTitleValue,
            "HirerequestJobStatus":selectedContractTypeId,
            "HirerequestJustification":JustificationValue,
            "HirerequestJobOverView":OverviewAndRequestedJobProfileValue,
            "HirerequestMinimumQualifiaction":MinimumqualificationNeedValue,
            "HirerequestPreferredQualifiaction":PreferredQualificationNeedValue,
            "HirerequestKeywords":KeywordsValue,
            "HirerequestOthers":OtherRemarksValue,
            "HirerequestStatusId":selectedRequestStatusId,
            "CreatedBy":CreatedBy
        }
   
        {
            console.log("CreateHRHireRequestPayload",CreateHRHireRequestPayload)
            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/hr_new_hire_request_create_update_cancel`, CreateHRHireRequestPayload)
            .then((res) => {
            if (res.status === 200) {
                Success("New HireRequest created successfully");
                navigate('/home')
            }
            }).catch((error) => {
                console.error("Error:", error);
            });
        }
    }

    // UseEffect declaration
    useEffect(() => {
        GetEmployeeList();
        GetHRHireRequestStatusList();
        GetHRHireRequestEmploymentTypeList();
        GetDepartmentList();
        GetHRHireRequestContractTypeList();
      }, []);

    return(
        <>
        <MainContainerWrapper
        headingText='Add HR New Hire Request'>
            <Box>
                <form noValidate>      
                {/* Request Information Accordian - start*/}
                <Box>
                    <Accordion defaultExpanded sx={{
                            borderRadius:'30px',
                        }}>
                            <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <FcInfo height={'1rem'} width={'1rem'} fontSize={'1.5rem'} /> 
                                   <Typography fontSize={'1rem'} ml={2}>Request Information</Typography> 
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                    <Box  sx={{
                                        ml:{
                                            xl:12,
                                            lg:5,
                                            md:0,
                                            xs:0
                                        },
                                         mr:{
                                            xl:12,
                                            lg:5,
                                            md:0,
                                            xs:0
                                        }
                                    }}  >
                                        <Grid container  mb={5} spacing={5} display={'flex'} >
                                            <Grid item lg={6} md={6} xs={12} >
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    fullWidth
                                                    options={EmploymentTypeListOptions as OptionType[]}
                                                    filterOptions={filterOptions}
                                                    getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                    value={EmploymentTypeListOptions.find((option: OptionType) => option.value === selectedEmploymentId) || null}
                                                    onChange={(event, newValue) => {
                                                        if (newValue) {
                                                            setselectedEmploymentId(newValue.value);
                                                            setEmploymentError(false);
                                                            setEmploymentErrorMessage("");
                                                        } else {
                                                            setselectedEmploymentId('');
                                                            setEmploymentError(true);
                                                            setEmploymentErrorMessage("Type of Employment Required is required");
                                                        }
                                                    }}
                                                    renderInput={(params) => 
                                                    <TextField {...params}
                                                        error={EmploymentError}
                                                        helperText={EmploymentErrorMessage}
                                                        required label="Type of Employment Required" 
                                                        variant="standard" />}
                                                    />
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={12} >
                                                <FormControl fullWidth>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            label="Date of Hire Request"
                                                            value={DateOfHireRequestValue ? dayjs(DateOfHireRequestValue) : null}
                                                            onChange={handleDateOfHireRequest}
                                                            format="DD-MM-YYYY"
                                                            slotProps={{
                                                                textField: {
                                                                    error: Boolean(DateOfHireRequestError),
                                                                    required: true
                                                                }
                                                            }}
                                                        />                                                        
                                                            {DateOfHireRequestError && (
                                                            <FormHelperText
                                                                required
                                                                error
                                                                id="standard-weight-helper-text-dob-login"
                                                            >
                                                                {DateOfHireRequestErrorMessage}
                                                            </FormHelperText>
                                                            )}
                                                    </LocalizationProvider>
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid container mb={5} spacing={5} display={'flex'} justifyContent={'center'}>                                   
                                            <Grid item lg={6} md={6} xs={12} >
                                                <TextField
                                                    type='text'
                                                    variant="standard"
                                                    required label='With in How many days, request to be filled?'
                                                    name='With in How many days, request to be filled?'
                                                    fullWidth
                                                    error={WithInHowManyDaysRequestToBeFilledError}
                                                    onChange={handleWithInHowManyDaysRequestToBeFilledChange}
                                                    value={WithInHowManyDaysRequestToBeFilledValue}
                                                    helperText={WithInHowManyDaysRequestToBeFilledErrorMessage}
                                                /> 
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={12} >
                                                <TextField
                                                    type='text'
                                                    variant="standard"
                                                    required label='How many positions are required?'
                                                    name='How many positions are required?'
                                                    fullWidth
                                                    error={HowManyPositionsAreRequestedError}
                                                    onChange={handleHowManyPositionsAreRequestedChange}
                                                    value={HowManyPositionsAreRequestedValue}
                                                    helperText={HowManyPositionsAreRequestedErrorMessage}
                                                /> 
                                            </Grid>
                                        </Grid>                             
                                    </Box>
                                </AccordionDetails>
                    </Accordion>
                </Box>
                {/* End of   {/* Request Information Accordian */} 

                {/* Hiring Department Information Accordian */}
                <Box mt={1} >
                <Accordion defaultExpanded sx={{
                            borderRadius:'30px',
                        }}>
                            <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <PiUserListFill height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
                                    <Typography fontSize={'1rem'} ml={2}>Hiring Department Information</Typography> 
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                    <Box sx={{
                                         ml:{
                                            xl:12,
                                            lg:5,
                                            md:0,
                                            xs:0
                                        },
                                         mr:{
                                            xl:12,
                                            lg:5,
                                            md:0,
                                            xs:0
                                        }
                                    }} >
                                    <Grid container mb={5} spacing={5} display={'flex'}>
                                        <Grid item lg={6} md={6} xs={12} >
                                            <Autocomplete
                                                id="combo-box-demo"
                                                fullWidth
                                                options={ManagerOptions as OptionType[]}
                                                filterOptions={filterOptions}
                                                getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                value={ManagerOptions.find((option: OptionType) => option.value === selectedManagerId) || null}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        setselectedManagerId(newValue.value);
                                                        setManagerError(false);
                                                        setManagerErrorMessage("");
                                                    } else {
                                                        setselectedManagerId('');
                                                        setManagerError(true);
                                                        setManagerErrorMessage("Name of the Hiring Manager is required");
                                                    }
                                                }}
                                                renderInput={(params) => 
                                                <TextField {...params}
                                                    error={ManagerError}
                                                    helperText={ManagerErrorMessage}
                                                    required label="Name of the Hiring Manager" 
                                                    variant="standard" />}
                                            />
                                        </Grid>
                                            <Grid item lg={6} md={6} xs={12} >
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    fullWidth
                                                    options={SupervisorOptions as OptionType[]}
                                                    filterOptions={filterOptions}
                                                    getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                    value={SupervisorOptions.find((option: OptionType) => option.value === selectedSupervisorId) || null}
                                                    onChange={(event, newValue) => {
                                                        if (newValue) {
                                                            setselectedSupervisorId(newValue.value);
                                                            setSupervisorError(false);
                                                            setSupervisorErrorMessage("");
                                                        } else {
                                                            setselectedSupervisorId('');
                                                            setSupervisorError(true);
                                                            setSupervisorErrorMessage("Supervisor is required");
                                                        }
                                                    }}
                                                    renderInput={(params) => 
                                                    <TextField {...params}
                                                        error={SupervisorError}
                                                        helperText={SupervisorErrorMessage}
                                                        required label="Name of the Hiring Supervisor" 
                                                        variant="standard" />}
                                                    />
                                                </Grid>
                                            </Grid>
                                        <Grid container mb={5} spacing={5} display={'flex'}>
                                            <Grid item lg={6} md={6} xs={12} >
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    fullWidth
                                                    options={DepartmentOptions as OptionType[]}
                                                    filterOptions={filterOptions}
                                                    getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                    value={DepartmentOptions.find((option: OptionType) => option.value === selectedDepartmentId) || null}
                                                    onChange={(event, newValue) => {
                                                        if (newValue) {
                                                            setselectedDepartmentId(newValue.value);
                                                            setDepartmentError(false);
                                                            setDepartmentErrorMessage("");
                                                        } else {
                                                            setselectedDepartmentId('');
                                                            setDepartmentError(true);
                                                            setDepartmentErrorMessage("Which Department is requested for Hire is required");
                                                        }
                                                    }}
                                                    // sx={{ width: 235, ml: 1 }}
                                                    renderInput={(params) => 
                                                    <TextField {...params}
                                                    error={DepartmentError}
                                                    helperText={DepartmentErrorMessage}
                                                    required label="Which Department is requested for Hire? " 
                                                    variant="standard" />}
                                                />
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={12} >
                                                <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Division/Function'
                                                    name='Price'
                                                    fullWidth
                                                    error={DivisionAndFunctionNameError}
                                                    onChange={handleDivisionAndFunctionChange}
                                                    value={DivisionAndFunctionNameValue || ''}
                                                    helperText={DivisionAndFunctionNameErrorMessage}
                                                /> 
                                            </Grid>
                                        </Grid>

                                        <Grid container mb={5} spacing={5} display={'flex'}>
                                            <Grid item lg={6} md={6} xs={12} >
                                                <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Which Location is needed for hire?'
                                                    name='Which Location is needed for hire?'
                                                    fullWidth
                                                    error={LocationError}
                                                    onChange={handleLocationChange}
                                                    value={LocationValue || ''}
                                                    helperText={LocationErrorMessage}
                                                /> 
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </AccordionDetails>
                    </Accordion>
                </Box>
                {/* End of Hiring Department Information Accordian */}

                {/* Job Information Accordian */}
                <Box mt={1}>
                <Accordion defaultExpanded sx={{
                            borderRadius:'30px',
                        }}>
                            <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <PiBriefcaseBold  height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
                                    <Typography fontSize={'1rem'} ml={2}>Job Information</Typography> 
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                    <Box sx={{
                                         ml:{
                                            xl:12,
                                            lg:5,
                                            md:0,
                                            xs:0
                                        },
                                         mr:{
                                            xl:12,
                                            lg:5,
                                            md:0,
                                            xs:0
                                        }
                                    }}>
                                    <Grid container mb={5} spacing={5} display={'flex'}>
                                        <Grid item lg={6} md={6} xs={12}  >
                                        <TextField
                                            type='text'
                                            variant="standard"
                                            required label='Job Code/ Title & Job'
                                            name='Job Code/ Title & Job'
                                            fullWidth
                                            error={JobCodeAndTitleError}
                                            onChange={handleJobCodeAndTitleChange}
                                            value={JobCodeAndTitleValue || ''}
                                            helperText={JobCodeAndTitleErrorMessage}
                                        /> 
                                        </Grid>
                                        <Grid item lg={6} md={6} xs={12} >
                                            <Autocomplete
                                                id="combo-box-demo"
                                                fullWidth
                                                options={ContractTypeListOptions as OptionType[]}
                                                filterOptions={filterOptions}
                                                getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                value={ContractTypeListOptions.find((option: OptionType) => option.value === selectedContractTypeId) || null}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        setselectedContractTypeId(newValue.value);
                                                        setContractTypeError(false);
                                                        setContractTypeErrorMessage("");
                                                    } else {
                                                        setselectedContractTypeId('');
                                                        setContractTypeError(true);
                                                        setContractTypeErrorMessage("Contract Type & Job is required");
                                                    }
                                                }}
                                                // sx={{ width: 235, ml: 1 }}
                                                renderInput={(params) => 
                                                <TextField {...params}
                                                error={ContractTypeError}
                                                helperText={ContractTypeErrorMessage}
                                                    required label="Contract Type & Job" 
                                                    variant="standard" />}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container mb={5} spacing={5} >
                                        <Grid item lg={12} md={12} xs={12}  >
                                            <TextField
                                                type='text'
                                                variant="filled"
                                                label='Justification'
                                                name='Justification'
                                                fullWidth
                                                multiline
                                                maxRows={10}
                                                error={JustificationError}
                                                // sx={{height:'50px'}}
                                                onChange={handleJustificationChange}
                                                value={JustificationValue}
                                                helperText={JustificationErrorMessage}
                                            /> 
                                        </Grid>
                                    </Grid>
                                        <Grid container mb={5} spacing={5} >
                                            <Grid item lg={12} md={12} xs={12} >
                                                <TextField
                                                    type='text'
                                                  variant="filled"
                                                    label='Overview & Requested Job Profile'
                                                    name='Overview & Requested Job Profile'
                                                    fullWidth
                                                    multiline
                                                    maxRows={10}
                                                    error={OverviewAndRequestedJobProfileError}
                                                    onChange={handleOverviewAndRequestedJobProfileChange}
                                                    value={OverviewAndRequestedJobProfileValue}
                                                    helperText={OverviewAndRequestedJobProfileErrorMessage}
                                                /> 
                                            </Grid>
                                        </Grid>
                                        <Grid container mb={5} spacing={5} display={'flex'}>
                                            <Grid item lg={6} md={6} xs={12} >
                                                <TextField
                                                    type='text'
                                                    variant="standard"
                                                    required label='Minimum qualification need'
                                                    name='Minimum qualification need'
                                                    fullWidth
                                                    // multiline
                                                    onChange={handleMinimumqualificationNeedChange}
                                                    value={MinimumqualificationNeedValue}
                                                    error={MinimumqualificationNeedError}
                                                    helperText={MinimumqualificationNeedErrorMessage}
                                                /> 
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={12} >
                                                <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Preferred qualification need'
                                                    name='Preferred qualification need'
                                                    fullWidth
                                                    // multiline
                                                    onChange={handlePrefferedQualificationChange}
                                                    value={PreferredQualificationNeedValue}
                                                    error={PreferredQualificationNeedError}
                                                    helperText={PreferredQualificationNeedErrorMessage}
                                                /> 
                                            </Grid>
                                        </Grid>

                                        <Grid container mb={5} spacing={5} display={'flex'} >
                                            <Grid item lg={6} md={6} xs={12} >
                                                <TextField
                                                    type='text'
                                                    variant="standard"
                                                    required label='Keywords'
                                                    name='Keywords'
                                                    fullWidth
                                                    onChange={handleKeywordsChange}
                                                    value={KeywordsValue}
                                                    error={KeywordsError}
                                                    helperText={KeywordsErrorMessage}
                                                /> 
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={12} >
                                                <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Other Remarks'
                                                    name='Other Remarks'
                                                    fullWidth
                                                    // multiline
                                                    onChange={handleOtherRemarksChange}
                                                    value={OtherRemarksValue}
                                                    helperText={OtherRemarksErrorMessage}
                                                    error={OtherRemarksError}
                                                /> 
                                            </Grid>
                                        </Grid>
                                        <Grid container mb={5} spacing={5}  display={'flex'} >
                                            <Grid item lg={6} md={6} xs={12} >                                               
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    fullWidth
                                                    options={RequestStatusOptions as OptionType[]}
                                                    filterOptions={filterOptions}
                                                    getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                    value={RequestStatusOptions.find((option: OptionType) => option.value === selectedRequestStatusId) || null}
                                                    onChange={(event, newValue) => {
                                                        if (newValue) {
                                                            setselectedRequestStatusId(newValue.value);
                                                            setRequestStatusError(false);
                                                            setRequestStatusErrorMessage("");
                                                        } else {
                                                            setselectedRequestStatusId('');
                                                            setRequestStatusError(true);
                                                            setRequestStatusErrorMessage("Current Status of Hire Request is required");
                                                        }
                                                    }}
                                                    renderInput={(params) =>
                                                        <TextField {...params}
                                                            error={RequestStatusError}
                                                            helperText={RequestStatusErrorMessage}
                                                            required label="Current Status of Hire Request"
                                                            variant="standard" />}
                                                />

                                            </Grid>
                                        </Grid>
                                    </Box>
                                </AccordionDetails>
                    </Accordion>
                </Box>
                {/* End of Job Information Accordian */} 

               {/* Button  start */}
                <Box>                    
                    <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Button
                            sx={{
                                background: 'white',
                                border: 'none',
                                borderRadius: '5px',
                                padding:{
                                    lg: "0 70px",
                                    md: "0 70",
                                    xs:'0 50px'
        
                                },
                                height: "3rem",
                                boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                            }}
                            onClick={()=>{
                                    navigate('/home')
                            }} 
                        >
                            Back
                        </Button>

                        <Button
                            sx={{
                                background: '#25425F',
                                border: 'none',
                                borderRadius: '5px',
                                padding:{
                                    lg: "0 70px",
                                    md: "0 70",
                                    xs:'0 50px'
                                },
                                "&:hover":{
                                    backgroundColor:'#25425F',
                                    color:'white',
                                },
                                color: 'white',
                                height: "3rem",
                                boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                            }}
                            type='submit'
                            onClick={(event)=>{
                                CreateHRHireRequest(event);
                            }}
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
                {/* Button  end */}

            </form>
            </Box>
        </MainContainerWrapper>
        </>
    )
}
export default AddHRNewHireRequest;
