// SOP-21 Included By PCS
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import './EditDesignation.scss'

//SOP-21 -Included By PCS Testing Feedback Corrections 
import Swal from "sweetalert2";
// EOF SOP-21 -Included By PCS Testing Feedback Corrections 

// Page start
const EditDesignationPage = 
    // To get handleEditDesignationClose & getdesignationlistsearch from designation page
    ({
        handleEditDesignationClose,
        getdesignationlistsearch,
        DesignationId
    }: {
        handleEditDesignationClose: (event: React.MouseEvent) => void,
        getdesignationlistsearch: (event: React.MouseEvent) => void,
        DesignationId: any;
    }) =>{

    //  use state declaration
    const [DesignationError, setDesignationError]=useState(false)
    const [DesignationValue, setDesignationValue]=useState('')
    const [DesignationErrorMessage, setDesignationErrorMessage]=useState('')
    const [isActive, setIsActive] = useState(true);

    //SOP-21 Included By PCS[Testing feedback corrections- to avoid duplicate designation]
    const [DesignationValueEndTrim, setDesignationValueEndTrim]=useState('')
    //EOF SOP-21 Included By PCS [Testing feedback corrections- to avoid duplicate designation]

    const handleToggleActive = () => {
        setIsActive((prevIsActive) => !prevIsActive);
    };
  //  Handle Designation while typing designation and validations
    const handleDesignationChange = (event:any) => {
        const trimmedDesignationValue = event.target.value.trim();
        setDesignationValue(event.target.value);

        //SOP-21 Included By PCS[Testing feedback corrections- to avoid duplicate designation]
        setDesignationValueEndTrim(trimmedDesignationValue);
        //EOF SOP-21 Included By PCS[Testing feedback corrections]

      if (trimmedDesignationValue === '') {
            setDesignationError(true);
            setDesignationErrorMessage("DesignationId is required");
        } 
        else if( trimmedDesignationValue.length >225 ){
            setDesignationError(true);
            setDesignationErrorMessage("Max 255 characters");
        }
        else {
            setDesignationError(false);
            setDesignationErrorMessage("");
        }
    };


    // To render handle close function to close dialouge box
    const handleClose = (event: React.MouseEvent) => {
        if (handleEditDesignationClose && typeof handleEditDesignationClose === 'function') {
            handleEditDesignationClose(event);
        }
    };

    // to render getdesignationlistsearch function after closing dialouge box
    const handlegetdesignationlistsearch= (event: React.MouseEvent) => {
        if (getdesignationlistsearch && typeof getdesignationlistsearch === 'function') {
            getdesignationlistsearch(event);
        }
    };

    // Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    //console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId
    let CreatedBy = GlobalData.GlobalUserId
    //console.log("CompanyId",CompanyId)


    // To get designation details as per the id
    const GetDesignationListById =()=>{
        commonService
         .postService(`${process.env.REACT_APP_BACKEND_URL}/master/get_designation_list_by_id`, {
            DesignationId:DesignationId,
            CompanyId:CompanyId
         })
         .then((res: any) => 
         { 
            // console.log("res",res)
            const DesignationData = res.data.data[0]
            //console.log("DesignationData",DesignationData)
            setDesignationValue(DesignationData.designationname)
            if(DesignationData.active === 'Y'){
                setIsActive(true)
            }
            else{
                setIsActive(false)
            }
         }).catch((error) =>  
         { 
          Failed(error.response.message);
         });
      }

        // Exist Designation name API
        const GetDesignationNameExist = async () => {   
        
            const  ExistDesignationNamePayload = {
                
                //SOP-21 Modified By PCS[Testing feedback corrections-to avoid duplicate designation]
                // ExistDesignationName:DesignationValue,
                   ExistDesignationName:DesignationValueEndTrim,
                //EOF SOP-21 Modified By PCS[Testing feedback corrections]
                   DesignationId:DesignationId,
                   CompanyId:CompanyId
            }
            try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/get_designation_name_exists`,ExistDesignationNamePayload);
            //console.log(res.data.data);
            return res.data.data || [];
            } catch (err) {
            console.log( "co",err); 
            }
        };

    // To Update designation

     // Define a type for errors
     type Errors = {
        designation?:boolean;
    };
    const HandleUpdateDesignation = async (event:any)=>{
        event.preventDefault();
    
        let errors: Errors = {};

        const DesignationNameList = await GetDesignationNameExist();
        if (DesignationNameList.length > 0) {
            setDesignationError(true);
            setDesignationErrorMessage("Designation is already exists");
            errors.designation = true;
            return;
        }
    
        const trimmedDesignationValue = DesignationValue.trim();
        if (trimmedDesignationValue === '') {
            errors.designation = true;
            setDesignationErrorMessage("Designation is required");
        } else if (trimmedDesignationValue.length > 225) {
            errors.designation = true;
            setDesignationErrorMessage("Max 255 characters");
        }
    
        if (Object.keys(errors).length > 0) {
            // If there are errors, update the state with all the errors
            setDesignationError(errors.designation || false);
            return;
        }

        let EditActive ;
    
        if( isActive === true){
           EditActive = "Y"
        }
        else{
            EditActive='N'
        }
    
        const UpdateDesignationPayload = {
            SaveUpdateKey: "update",
            DesignationName: DesignationValue,
            DesignationId: DesignationId,
            CompanyId: CompanyId,
            Active: EditActive,
            LastModifiedBy: CreatedBy,
            IsRemove: null
        }
    
        //console.log("AddPL", UpdateDesignationPayload);
    

    //SOP-21 Included By PCS[Testing feedack corrections- to avoid multiple toaster msg]

           Swal.fire({
            html: '<span style="font-size: 1.3rem;">Are you sure to save ? </span>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            cancelButtonColor: '#d33',
            confirmButtonColor:"#25425f",
            allowOutsideClick: false,
            })
        .then((result) =>
        {
        if (result.isConfirmed)
        {

    //EOF SOP-21 Included By PCS[Testing feedack corrections] 

            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/master/designation_create_update_delete`, UpdateDesignationPayload)
            .then((res) => {
                if (res.status === 200) {
                    Success("Designation Updated successfully");
                    handleClose(event);
                    handlegetdesignationlistsearch(event);
                }
                }).catch((error) => {
                    console.error("Error:", error);
                });

    //SOP-21 Included By PCS[Testing feedack corrections- to avoid multiple toaster msg]

        } 
        else  
        {
        return
        }
        });

    //EOF SOP-21 Included By PCS[Testing feedack corrections] 

    }
    
    // UseEffect declaration
    useEffect(() => {
        GetDesignationListById();
      }, []); 
 

 //SOP-21 Testing feedback corrections[To restrict form submission only on button]

 const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

 //SOP-21 Testing feedback corrections[To restrict form submit only on button]


// Design start
    return(
        <Box>
            <Box>
            <Box display={'flex'} justifyContent={'center'}>
                    <Box bgcolor={'white'} borderRadius={3}
                    sx={{
                        width:{
                            lg:900,
                            md:600,
                            xs:700
        
                        }}}
                    >
                    <Box p={3}>
                        <Typography variant='h1'>
                        Edit Designation
                        </Typography>
                    </Box>
                        <Box p={3}>
                            <form  noValidate>
                                <Grid container spacing={2}>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <Box>
                                            <TextField
                                                onKeyDown={handleKeyDown} //SOP-21 [Testing feedback correction- to restrict submission only on button]
                                                type='text'
                                                //SOP-21 Inlcuded By PCS[Testing feedback Correction- to set auto focus]
                                                autoFocus
                                                //EOF SOP-21 [Testing feedback Correction- to set autofocus]
                                                variant="standard"
                                                label='Designation'
                                                name='Designation'
                                                style={{ margin: '0 10px' }}
                                                fullWidth
                                                required
                                                error={DesignationError}
                                                onChange={handleDesignationChange}
                                                value={DesignationValue}
                                                helperText={DesignationErrorMessage}
                                            /> 
                                            </Box>
                                    </Grid>
                                    {/* //SOP-21 Commented By PCS [Testing feedback corrections- to change toggle alignment]*/}
                                    {/* <Grid item lg={4} md={6} xs={12}>
                                        <Box  display={'flex'} justifyContent={'center'}>
                                            <Box>
                                            <Typography className="label_design">
                                            <label style={{fontSize:'0.7rem'}}>Active State</label>
                                            </Typography>
                                            <label className="switchToggle">
                                            <input
                                                type="checkbox"
                                                checked={isActive}
                                                onChange={handleToggleActive} // Call the toggle function
                                            />
                                            <span className="sliderToggle round"></span>
                                            </label>
                                            </Box> 
                                        </Box>                                       
                                        </Grid> */}
                                    {/* //SOP-21 Commented By PCS [Testing feedback corrections- to change toggle alignment]*/}
                                </Grid>
                                

                            {/*//SOP-21 Included By PCS [Testing feedback corrections- to change toggle alignment]*/}
                                <Box mt={2} ml={1}>
                                       <Typography className="label_design" sx={{mt:1.5}}>
                                        <label style={{fontSize:'0.7rem'}}>Active State</label>
                                        </Typography>
                                        <label className="switchToggle">
                                        <input
                                            type="checkbox"
                                            checked={isActive}
                                            onChange={handleToggleActive} // Call the toggle function
                                        />
                                        <span className="sliderToggle round"></span>
                                        </label>
                                </Box> 
                               {/*//SOP-21 Included By PCS [Testing feedback corrections - to change toggle alignment]*/}


                            <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Button
                                    sx={{
                                        background: 'white',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        // color: {accent},
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}

                                    onClick={handleClose} 
                                >
                                    Back
                                </Button>

                                <Button
                                    sx={{
                                        background: '#25425F',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                                        },
                                        "&:hover":{
                                            backgroundColor:'#25425F',
                                            color:'white',
                                        },
                                        color: 'white',
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}
                                    type='submit'
                                    onClick={(event)=>{
                                        HandleUpdateDesignation(event);
                                    }}
                                >
                                {/* //SOP-21 Modified By PCS[Testing feedback Correction] */}
                                {/* Save */}
                                    Update
                                {/* //EOF SOP-21 Modified By PCS[Testing feedback Correction] */}
                                </Button>
                                </Box>
                            </form>
                        </Box>

                    </Box>
                </Box>
            </Box>
        </Box>
    )
// Design end

}
// Page end

export default EditDesignationPage;
// EOF SOP-21