import Swal from "sweetalert2";

type deletealertProps = {

    onConfirmed?: Function,
    title?: string,
    message?: string,
    confirmButtonText?:string,
    data?: any
}

const deleteAlert = ({ onConfirmed,title,message,confirmButtonText, data=null }: deletealertProps) => {
    return (
        Swal.fire({
            title:title,
            text:message,
            icon:'warning',
            confirmButtonText:confirmButtonText,
            cancelButtonText: "No",
            confirmButtonColor:"#203852",
            showCancelButton: true,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed  && onConfirmed) {
                onConfirmed(data);
            }
        }
        )
    )
}

export default deleteAlert;
