import { useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import "./App.css";
import NavigationScroll from "./container/layout/NavigationScroll";
import RoutePath from "./routes";
import themes from "./asset/themes";
import { setAxiosDefauls } from "./helpers/interceptor";

function App() {
  const customization = useSelector((state: any) => state.customization);
  setAxiosDefauls()

  return (
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline />
          <NavigationScroll>
            <RoutePath />
          </NavigationScroll>
        </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
