//SOP-38 - TS
//Asset Management -Dashboard - Ticketing

//AMC Renewal count Page
import { PieChart} from '@mui/x-charts/PieChart';
import  { useState, useEffect } from "react";
import { commonService } from "../../atic-common-helpers/helpers/common.service";

const  AMCRenewal=()=> {
   const [CompanyId, setCompanyId] =useState('')
  const [CreatedBy, setCreatedBy] =useState('')
  const [AMCRenewalY, setAMCRenewalY] = useState(0);
  const [AMCRenewalN, setAMCRenewalN] = useState(0);
    // console.log("AMCRenewalY", AMCRenewalY)
    // console.log("AMCRenewalN",AMCRenewalN)
  useEffect(() => 
    {
      const AMCRenewal = async () => {
        try {
        //Global data
        const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
        const GlobalData = JSON.parse(GlobalDataFromSession);
        setCompanyId(GlobalData.GlobalCompanyId);
        setCreatedBy(GlobalData.GlobalUserId);
  
        // AMCRenewal Payload
        const AMCRenewalPayload = {
          companyid: GlobalData.GlobalCompanyId,
        };
  
        // API    
        const response = await commonService
          .postService(`${process.env.REACT_APP_BACKEND_URL}/dashboardController/getAMCRenewalCount`, AMCRenewalPayload);
        // console.log("AMCRenewal",response)
        const AMCRenewalY = response.data.data[0]._is_under_amc_y;
        setAMCRenewalY(AMCRenewalY);
        const AMCRenewalN = response.data.data[0]._is_under_amc_n;
        setAMCRenewalN(AMCRenewalN);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    AMCRenewal();
  }, []);




 return (
  <PieChart
  series={[
            {
            data: [

              //SOP-77 feedback points Modified By PCS [to have count]
                    // { id: 0, value: AMCRenewalY,label:'RENEWED' },
                    // { id: 1, value: AMCRenewalN, label: 'NOT RENEWED' },

                    { id: 0, value: AMCRenewalY,label:`RENEWED (${AMCRenewalY})` },
                    { id: 1, value: AMCRenewalN, label: `NOT RENEWED (${AMCRenewalN})` },

              //EOF SOP-77 feedback points  Modified By PCS
            ],
            
         
    }
  ]}
     width={400}
     height={200}

     sx={{
       "& text": {
         fontSize: '10px',          
      },
      padding:1.50,  // SOP-77 feedback points Included by PCS [Adjust padding ]
    }}
 
   />

  )}
export default AMCRenewal





