//SOP-55 - TS commented - start
// Asset Management - Ticketing - View and Edit Page
// // Included by sanjana SOP-26 to create raise a ticket page
// // Imports
// import { useState,useRef,useEffect } from 'react'; // Import useState hook
// import React from 'react';
// import {
//     Box,
//     Button,
//     TextField,
//     InputAdornment,
//     Avatar,
//     useMediaQuery,
//     Menu,
//     MenuItem,
//     Grid,
//     Paper,
//     Divider,
//     Tooltip,
//     Dialog,
//     FormControl,
//     FormLabel,
//     RadioGroup,
//     FormControlLabel,
//     Radio,
// } from '@mui/material';
// import Typography from '@mui/material/Typography';
// import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
// import { commonService } from '../../atic-common-helpers/helpers/common.service';
// import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
// import deleteAlert from '../../component/HOC/deleteAlert';




// const EditRaiseATicketPage  = () => {
// // ({
// //     handleEditTicketClose,
// //     getTicketlistsearch,
// //     getTicketTypeRequestList,
// //     TicketId
// // }: {
// //     handleEditTicketClose: (event: React.MouseEvent) => void,
// //     getTicketlistsearch: (event: React.MouseEvent) => void,
// //     getTicketTypeRequestList: (event: React.MouseEvent) => void,
// //     TicketId:any
// // })=>{


//     // Use state declaration
//     const [IssuediscriptionError, setIssuediscriptionError]=useState(false)
//     const [IssuediscriptionValue, setIssuediscriptionValue]=useState('')
//     const [IssuediscriptionErrorMessage, setIssuediscriptionErrorMessage]=useState('')
//     const [IssueTypeOptions, setIssueTypeOptions] = useState<OptionType[]>([]);
//     const [selectedIssueTypeId, setSelectedIssueTypeId] = useState<string | null>('');
//     const [IssueTypeError, setIssueTypeError]=useState(false)
//     const [IssueTypeErrorMessage, setIssueTypeErrorMessage]=useState('')
//     // Included by sanjana SOP-28  usestate
//     const [TicketTypeValue, setTicketTypeValue]=useState('1')
//     // End of SOP-28

//     type OptionType = {
//         name: string;
//         value: string;
//     };
//     const filterOptions = createFilterOptions<OptionType>({
//         matchFrom: 'start',
//         stringify: (option) => option.name,
//     });


//     // To render handle close function to close dialouge box
//     // const handleClose = (event: React.MouseEvent) => {
//     //     if (handleEditTicketClose && typeof handleEditTicketClose === 'function') {
//     //         handleEditTicketClose(event);
//     //     }
//     // };

//     // // to render getTicketlistsearch function after closing dialouge box
//     // const handlegetTicketlistsearch= (event: React.MouseEvent) => {
//     //     if (getTicketlistsearch && typeof getTicketlistsearch === 'function') {
//     //         getTicketlistsearch(event);
//     //     }
//     // };

//     // // to render getTicketlistsearch function after closing dialouge box
//     // const handlegetTicketTypeRequestList= (event: React.MouseEvent) => {
//     //     if (getTicketTypeRequestList && typeof getTicketTypeRequestList === 'function') {
//     //         getTicketTypeRequestList(event);
//     //     }
//     // };

//     // Included by sanjana SOP-28 Handle IssueType while typing IssueType and validations
//          const handleTicketTypeChange = (event:any) => {
//             setTicketTypeValue(event.target.value);
//         };
//     // End of SOP-28


//     //  Global data from session storage
//     const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
//     const GlobalData = JSON.parse(GlobalDataFromSession);
//     //console.log("GlobalData",GlobalData)
//     let CompanyId = GlobalData.GlobalCompanyId
//     const UserId = GlobalData.GlobalUserId;

//     // To get issue type list to fill the issue type filed
//     const GetIssueTypeList = async () => {
//     try {
//         const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getIssueTypeList`);
//         //console.log("IssueType" ,res.data.data);
//         let formattedIssueType = res.data.data.map((item:any) => {
//         const { sys_issue_type_id, sys_issue_type_name } = item;
//         return {
//             name: sys_issue_type_name,
//             value: sys_issue_type_id,
//         };
//         });
//         setIssueTypeOptions(formattedIssueType)
//     } catch (err) {
//         //console.log( "co",err);
//     }
//     };

//     //  Handle IssueType while typing IssueType and validations
//     const handleIssuediscriptionChange = (event:any) => {
//         const trimmedIssuediscriptionValue = event.target.value.trim();
//         setIssuediscriptionValue(event.target.value);
//         if( trimmedIssuediscriptionValue.length >225 ){
//             setIssuediscriptionError(true);
//             setIssuediscriptionErrorMessage("Max 255 characters");
//         }
//         else {
//             setIssuediscriptionError(false);
//             setIssuediscriptionErrorMessage("");
//         }
//     };

//     // To get categories details as per the id
//     const GetTickteListById =()=>{
//         commonService
//          .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getTicketListById`, {
//             // TicketId:TicketId,
//             CreatedBy: UserId,
//             CompanyId:CompanyId
//          })
//          .then((res: any) =>
//          {
//             //console.log("res",res)
//             const TicketData = res.data.data[0]
//             //console.log("TicketData",TicketData)
//             setIssuediscriptionValue(TicketData.ticket_issuedescription)
//             // Included by sanjana SOP-28 to pass ticket_type value for TicketTypeValue
//             setTicketTypeValue(TicketData.ticket_type)
//             // End of SOP-28
//             let formattedIssueType = res.data.data.map((item:any) => {
//                 const { ticket_issuetype, sys_issue_type_name } = item;
//                 return {
//                     name: sys_issue_type_name,
//                     value: ticket_issuetype,
//                 };
//                 });
//                 ////console.log("formattedIssueType",formattedIssueType[0].value)
//                 setSelectedIssueTypeId(formattedIssueType[0].value)
//          }).catch((error) =>
//          {
//           Failed(error.response.message);
//          });
//     }

//        // Define a type for errors
//        type Errors = {
//         issueType?:boolean;
//         issueDiscription?:boolean;
//     };

//     // To save updated data
//         const EditRiseATicket =(event:any)=>{

//             event.preventDefault();

//             let errors: Errors = {};

//             const trimmedIssuediscriptionValue = IssuediscriptionValue.trim();
//             if( trimmedIssuediscriptionValue.length >225 ){
//                 errors.issueDiscription=true;
//                 setIssuediscriptionErrorMessage("Max 255 characters");
//             }

//             if(selectedIssueTypeId === null || selectedIssueTypeId === '' || selectedIssueTypeId === undefined ){
//                 errors.issueType=true;
//                 setIssueTypeErrorMessage("Issue Type is required")
//             }

//             if (Object.keys(errors).length > 0) {
//                 // If there are errors, update the state with all the errors
//                 setIssueTypeError(errors.issueType || false);
//                 setIssuediscriptionError(errors.issueDiscription || false);
//                 return;
//             }


//             const RaiseATicketPayload = {
//                 SaveUpdateKey:'update',
//                 CompanyId:CompanyId,
//                 // TicketingId:TicketId,
//                 TicketingIssueTypeId:selectedIssueTypeId,
//                 TicketingIssueDiscription:IssuediscriptionValue,
//                 TicketingRaisedBy:UserId,
//                 CreatedBy:UserId,
//                 // Included by sanjana SOP-28 to pass ticket type value to payload
//                 TicketType:parseInt(TicketTypeValue)
//                 // End of SOP-28
//             }

//             // console.log("RaiseATicketPayload",RaiseATicketPayload)
//             commonService
//             .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/ticketing_create_update_cancel`, RaiseATicketPayload)
//             .then((res) => {
//               if (res.status === 200) {
//                   Success("Issue Updated Successfully");
//                 //   handleClose(event);
//                 //   handlegetTicketlistsearch(event);
//                 //   handlegetTicketTypeRequestList(event);
//               }
//               }).catch((error) => {
//                   console.error("Error:", error);
//               });
//         }
    

//         useEffect(() => {
//             GetIssueTypeList();
//             GetTickteListById();
//         }, []);
        
    

//     return(
//         <Box>
//             <Box>

//             {/* SOP-45 - TS -Alignment Issue- start */}
//                 {/* Home Page - Raise a Ticket - Popup */}
//                 {/* <Box display={'flex'} justifyContent={'center'}> */}
//                 <Box display={'flex'} >
//             {/* SOP-45 - TS - end */}
                    
//                     <Box bgcolor={'white'} borderRadius={3}
//                     sx={{
//                         width:{
//                             lg:900,
//                             md:600,
//                             xs:700
        
//                         }}}
//                     >
//                     <Box p={3}>
//                         <Typography variant='h1'
//                         sx={{
//                             fontSize:{
//                                 lg:'2rem',
//                                 md:'2rem',
//                                 xs:'1.5rem'
//                             }
//                         }}
//                         >
//                         Edit Ticket
//                         </Typography>
//                     </Box>
//                         <Box p={3}>
//                             <form  noValidate>
//                                 <Box>
//                                     <Grid container spacing={2} display={'flex'} justifyContent={'space-evenly'}>
//                                     {/* Included by sanjana SOP-28 to add radio button for edit raise a ticket*/}
//                                         <Grid item lg={12} md={12} xs={12}>
//                                         <FormControl>
//                                         <FormLabel id="demo-controlled-radio-buttons-group">Ticket Type</FormLabel>
//                                         <RadioGroup
//                                             aria-labelledby="demo-controlled-radio-buttons-group"
//                                             name="controlled-radio-buttons-group"
//                                             value={TicketTypeValue}
//                                             onChange={handleTicketTypeChange}
//                                         >
//                                             <Box display={'flex'}>
//                                             <FormControlLabel value="1" control={<Radio />}  label="Incident" />
//                                             <FormControlLabel value="2" control={<Radio />}  label="Request" />
//                                             </Box>

//                                         </RadioGroup>
//                                         </FormControl>
//                                         </Grid>
//                                     {/* End of SOP-28 */}
//                                         <Grid item lg={12} md={12} xs={12} >
//                                         <Autocomplete
//                                                 id="combo-box-demo"
//                                                 options={IssueTypeOptions as OptionType[]}
//                                                 filterOptions={filterOptions}
//                                                 getOptionLabel={(option: unknown) => (option as OptionType).name}
//                                                 value={IssueTypeOptions.find((option: OptionType) => option.value === selectedIssueTypeId) || null}
//                                                 onChange={(event, newValue) => {
//                                                     if (newValue) {
//                                                         setSelectedIssueTypeId(newValue.value);
//                                                         setIssueTypeError(false);
//                                                         setIssueTypeErrorMessage("");
//                                                     } else {
//                                                         setSelectedIssueTypeId('');
//                                                         setIssueTypeError(true);
//                                                         setIssueTypeErrorMessage("Issue Type is required");
//                                                     }
//                                                 }}
//                                                 // sx={{ width: 235, ml: 1 }}
//                                                 renderInput={(params) =>
//                                                 <TextField {...params}
//                                                 error={IssueTypeError}
//                                                 helperText={IssueTypeErrorMessage}
//                                                     required label="Issue Type"
//                                                     variant="standard" />}
//                                             />
//                                         </Grid>
//                                         <Grid item lg={12} md={12} xs={12} >
//                                             {/* Modified by sanjana SOP-28 to add new description field */}
//                                             {/* <TextField
//                                                 type='text'
//                                                 variant="standard"
//                                                 label='Issue Description'
//                                                 name='Issuediscription'
//                                                 style={{ margin: '0 10px' }}
//                                                 fullWidth
//                                                 multiline
//                                                 error={IssuediscriptionError}
//                                                 onChange={handleIssuediscriptionChange}
//                                                 value={IssuediscriptionValue}
//                                                 helperText={IssuediscriptionErrorMessage}
//                                             />  */}
//                                             <TextField
//                                             id="outlined-basic"
//                                             label="Issue Description"
//                                             variant="outlined"
//                                             name='Issuediscription'
//                                             fullWidth
//                                             multiline
//                                             rows={4}
//                                             error={IssuediscriptionError}
//                                             onChange={handleIssuediscriptionChange}
//                                             value={IssuediscriptionValue}
//                                             helperText={IssuediscriptionErrorMessage}
//                                             />
//                                             {/* End of SOP-28 */}
//                                         </Grid>
//                                     </Grid>
                                    

//                                 </Box>
//                             <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
//                                 <Button
//                                     sx={{
//                                         background: 'white',
//                                         border: 'none',
//                                         borderRadius: '5px',
//                                         padding:{
//                                             lg: "0 70px",
//                                             md: "0 70",
//                                             xs:'0 50px'
                
//                                         },
//                                         // color: {accent},
//                                         height: "3rem",
//                                         boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
//                                     }}

//                                     // onClick={handleClose}
//                                 >
//                                     Back
//                                 </Button>

//                                 <Button
//                                     sx={{
//                                         background: '#25425F',
//                                         border: 'none',
//                                         borderRadius: '5px',
//                                         "&:hover":{
//                                             backgroundColor:'#25425F',
//                                             color:'white',
//                                         },
//                                         padding:{
//                                             lg: "0 70px",
//                                             md: "0 70",
//                                             xs:'0 50px'
                
//                                         },
//                                         color: 'white',
//                                         height: "3rem",
//                                         boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
//                                     }}
//                                     type='submit'
//                                     onClick={(event)=>{
//                                         EditRiseATicket(event);
//                                     }}
//                                 >
//                                     Update
//                                 </Button>
//                                 </Box>
//                             </form>
//                         </Box>

//                     </Box>
//                 </Box>
//             </Box>
//         </Box>
//     )

// }

// export default EditRaiseATicketPage;

// // End of SOP-26
//SOP-55 - TS commented - end
// Asset Management - Ticketing - View and Edit Page




//SOP-55 - TS included  - start
// Asset Management - Ticketing - View and Edit Page
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
    FormControl,
    FormHelperText,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate , useParams } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import deleteAlert from '../../component/HOC/deleteAlert';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { Dayjs } from 'dayjs';
import { green } from '@mui/material/colors';

// Edit page
const EditRaiseATicketPage  = () => {

    // Use state declaration
    const [IssuediscriptionErrorMessage, setIssuediscriptionErrorMessage]=useState('')
  
    const { id } = useParams();
    const navigate = useNavigate();
    const [ticketDateValue, setTicketDateValue] = useState<Dayjs | null>(dayjs());
    // console.log("ticketDateValue",ticketDateValue)

    const [IssuediscriptionError, setIssuediscriptionError]=useState(false)
    const [IssuediscriptionValue, setIssuediscriptionValue]=useState('')

    // IssueType  useState
    const [IssueTypeOptions, setIssueTypeOptions] = useState<OptionType[]>([]);
    const [selectedIssueTypeId, setSelectedIssueTypeId] = useState<string | null>('');   
    const [IssueTypeError, setIssueTypeError]=useState(false)
    const [IssueTypeErrorMessage, setIssueTypeErrorMessage] = useState('')

    // IssueSubType  useState
    const [IssueSubTypeOptions, setIssueSubTypeOptions] = useState<OptionType[]>([]);
    const [selectedIssueSubTypeId, setSelectedIssueSubTypeId] = useState<string | null>('');
    // console.log("selectedIssueSubTypeIds", IssueSubTypeOptions)
    const [IssueTypeSubError, setIssueTypeSubError]=useState(false)
    const [IssueTypeSubErrorMessage, setIssueTypeSubErrorMessage]=useState('')

   // ContactType  useState
    const [ContactTypeOptions, setContactTypeOptions] = useState<OptionType[]>([]);
    const [selectedContactTypeId, setSelectedContactTypeId] = useState<string | null>('');
    // console.log("selectedContactTypeId", selectedContactTypeId)
    const [ContactTypeError, setContactTypeError]=useState(false)
    const [ContactTypeErrorMessage, setContactTypeErrorMessage] = useState('')
    
    // PriorityType useState
    const [PriorityTypeOptions, setPriorityTypeOptions] = useState<OptionType[]>([]);
    const [selectedPriorityTypeId, setSelectedPriorityTypeId] = useState<string | null>('');
    // console.log("selectedPriorityTypeId",selectedPriorityTypeId)
    const [PriorityTypeError, setPriorityTypeError]=useState(false)
    const [PriorityTypeErrorMessage, setPriorityTypeErrorMessage] = useState('')
   
    // Employee useState
    const [EmployeeOptions, setEmployeeOptions] = useState<OptionType[]>([]);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | null>('');
    // console.log("selectedEmployeeId",selectedEmployeeId)
    const [EmployeeError, setEmployeeError]=useState(false)
    const [EmployeeErrorMessage, setEmployeeErrorMessage] = useState('')

    // Impact useState
    const [ImpactOptions, setImpactOptions] = useState<OptionType[]>([]);
    const [selectedImpactId, setSelectedImpactId] = useState<string | null>('');
    // console.log("selectedImpactId", selectedImpactId)
    const [ImpactTypeError, setImpactTypeError]=useState(false)
    const [ImpactTypeErrorMessage, setImpactTypeErrorMessage] = useState('')
// console.log("ImpactOptions",ImpactOptions)

    // ShortDiscription useState
    const [ShortDiscriptionError, setShortDiscriptionError]=useState(false)
    const [ShortDiscriptionValue, setShortDiscriptionValue] = useState('');
    const [ShortDiscriptionErrorMessage, setShortDiscriptionErrorMessage] = useState('')

    // Discription useState
    const [DiscriptionError, setDiscriptionError]=useState(false)
    const [DiscriptionValue, setDiscriptionValue] = useState('')
    const [DiscriptionErrorMessage, setDiscriptionErrorMessage] = useState('') 

    // useState for Location
    const [LocationError, setLocationError]=useState(false)
    const [LocationValue, setLocationValue] = useState('')
    const [LocationErrorMessage, setLocationErrorMessage] = useState('') 

    // Ticket usestate
    const [TicketIdValue, setTicketIdValue] = useState('')
    const [TicketDueDateValue, setTicketDueDateValue] = useState<dayjs.Dayjs | null>(null);

    // TicketDueDate usestate
    // console.log("TicketDueDateValue",TicketDueDateValue)
    const [TicketDueDateError, setTicketDueDateError]=useState(false)
    const [TicketDueDateErrorMessage, setTicketDueDateErrorMessage] = useState('')
    const [TicketstartDateValue, setTicketstartDateValue] = useState<dayjs.Dayjs | null>(null);
    // console.log("TicketstartDateValue",TicketstartDateValue)

     //SOP-72 Included By PCS
     const [TicketClosedDate, setTicketClosedDate] = useState<dayjs.Dayjs | null>(null);
     const [TicketStatusValue, setTicketStatusValue] = useState('')
    //EOF SOP-72 Included By PCS

    

    // attachment usestate
    const [attachment, setAttachment] = useState<File | null>(null);
    const [attachmentBase64, setAttachmentBase64] = useState<string | null>(null);

    //SOP-72 Included By PCS [closure information]
    const [ClosureInformationOptions, setClosureInformationOptions] =useState<OptionType[]>([]);
    const [selectedClosureInformationId, setselectedClosureInformationId] = useState<string | null>('');
   //EOF SOP-72 Included By PCS



    // SOP-57 - TS - start
    // Asset Management    - Ticketing - Activity screen

    // SOP-57 - TS - end

    // console.log("selectedIssueTypeId", selectedIssueTypeId)
    // console.log("selectedIssueSubTypeId", selectedIssueSubTypeId)
    // console.log("IssueSubTypeOptions", IssueSubTypeOptions)
    // console.log("attachment",attachment)
    // console.log("attachmentBase64",attachmentBase64)


    //SOP-71 Included By PCS  [to initialize vendor Ticket ID]  
        const [VendorTicketIDValue, setVendorTicketIDValue] = useState('');
    //EOF SOP-71 Included By PCS

    //SOP-71 Included By PCS  [to initialize variables for activity details]
        const [CreatedDetails, setCreatedDetails] = useState('')
        const [AllocatedDetails, setAllocatedDetails] = useState('')
        const [AllocateRemarks, setAllocateRemarks] = useState('')
        const [OpenStatus, setOpenStatus] = useState('')
        const [CurrentStatus, setCurrentStatus] = useState('')
        const [ServiceRemarks, setServiceRemarks] = useState('')
        const [ClosedDetails, setClosedDetails] = useState('')
    //EOF SOP-71 Included By PCS

    //SOP-71 Included By PCS [to display avatar ]
    const shouldDisplayAvatar = (variable:any) => !!variable; // Returns true if variable has a value


    type OptionType = {
        name: string;
        value: string;

    };
    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });

    //  Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    //console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId
    const UserId = GlobalData.GlobalUserId;

    // To get issue type list to fill the issue type filed 
    const GetIssueTypeList = async () => { 
    try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getIssueTypeList`);
        //console.log("IssueType" ,res.data.data);
        let formattedIssueType = res.data.data.map((item:any) => {
        const { sys_issue_type_id, sys_issue_type_name } = item;
        return {
            name: sys_issue_type_name,
            value: sys_issue_type_id,
        };
        });
        setIssueTypeOptions(formattedIssueType)
    } catch (err) {
        //console.log( "co",err);
    }
    };


    // IssueSubType List
    const GetIssueSubTypeList = async (issueTypeId: string) => { 

    try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getIssueSubTypeList`, { issuetypeid: issueTypeId });
        //console.log("IssueSubType" ,res.data.data);
        let formattedIssueSubType = res.data.data.map((item:any) => {
            const { sys_issue_subtype_id, sys_issue_subtype_name } = item;
            // console.log("SubType",item)
        return {
            name: sys_issue_subtype_name,
            value: sys_issue_subtype_id,
        };
        });
        // console.log("1s",formattedIssueSubType)
        setIssueSubTypeOptions(formattedIssueSubType)
    } catch (err) {
        //console.log( "co",err);
    }
};

    // ContactType List
    const GetContactTypeList = async () => { 
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getContactTypeList`);
            //console.log("IssueType" ,res.data.data);
            let formattedContactType = res.data.data.map((item:any) => {
            const { sys_contact_type_id, sys_contact_type_name } = item;
            return {
                name: sys_contact_type_name,
                value: sys_contact_type_id,
            };
            });
            setContactTypeOptions(formattedContactType)
        } catch (err) {
            //console.log( "co",err);
        }
    };
    
    // PriorityType List
    const GetPriorityTypeList = async () => { 
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getPriorityList`);
            //console.log("IssueType" ,res.data.data);
            let formattedPriorityType = res.data.data.map((item:any) => {
            const { sys_priority_id, sys_priority_name } = item;
            return {
                name: sys_priority_name,
                value: sys_priority_id,
            };
            });
            setPriorityTypeOptions(formattedPriorityType)
        } catch (err) {
            //console.log( "co",err);
        }
    };

    // Employee List
    const GetEmployeeList = async () => { 
        const EmployeeLisPayload ={
            CompanyId:CompanyId,
            active:"Y"
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getEmployeeList`,EmployeeLisPayload);
            //console.log("IssueType" ,res.data.data);
            let formattedEmployeeType = res.data.data.map((item:any) => {
                const { employee_id, employee_name } = item;
                // console.log("item",item)
            return {
                name: employee_name,
                value: employee_id,
            };
            });
            setEmployeeOptions(formattedEmployeeType)
        } catch (err) {
            //console.log( "co",err);
        }
    };

    // Impact List
    const GetImpactList = async () => { 
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getImpactList`);
            // console.log("IssueType", res.data.data);
            
            let formattedImpactType = res.data.data.map((item: any) => {
              
                const { sys_impact_id, sys_impact_name } = item;
                // console.log("formattedImpactType",item)
            return {
                name: sys_impact_name,
                value: sys_impact_id,
                };
               
            });
            setImpactOptions(formattedImpactType)
        } catch (err) {
            //console.log( "co",err);
        }
    };

    // SOP-57 -TS - start
    // Asset Management    - Ticketing - Activity screen - start
        // ClosureInformation API
        const GetClosureInformationList = async () => { 
            try {
                //SOP-72_A Modified By PCS[16/08/2024]
                // const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getClosureInformationList`);
                const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/get_closure_information_list`);
                //EOF SOP-72_A Modified By PCS[16/08/2024] 
                
                // console.log("ClosureInformationList" ,res);
                let formattedClosureInformationType = res.data.data.map((item: any) => {
                    const { sys_ticket_closure_information_id, sys_ticket_closure_information_name } = item;
                    // console.log("item1", item)
                    // console.log("formattedClosureInformationType" ,formattedClosureInformationType);
                return {
                    name: sys_ticket_closure_information_name,
                    value: sys_ticket_closure_information_id,
                    };
                  
                });
                setClosureInformationOptions(formattedClosureInformationType)
            } catch (err) {
                //console.log( "co",err);
            }
        };
  
    // SOP-57 -TS - end

    // To get categories details as per the id 
    const GetTickteListById =()=>{
        commonService
         .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getTicketListById`, {
            TicketId:id,
            CreatedBy: UserId,
            CompanyId:CompanyId
         })
         .then((res: any) => 
         { 
            // console.log("res",res)
             const TicketData = res.data.data[0]
           

             // Extracting only the date from TicketDueDateValue
             const ticketDueDateTime = new Date(TicketData.ticket_due_date);
             const formattedDueDate = dayjs(ticketDueDateTime).format('YYYY-MM-DD');
             //  console.log("formattedDueDate",formattedDueDate)
             
            setShortDiscriptionValue(TicketData.ticket_issueshortdescription)
            setDiscriptionValue(TicketData.ticket_issuedescription)
            setLocationValue(TicketData.location)
            setTicketDueDateValue(TicketData.ticket_due_date)
            setTicketIdValue(TicketData.ticket_number)
            setTicketstartDateValue(TicketData.ticket_date)
             // setAttachment(TicketData.attachment)
             setAttachmentBase64(TicketData.attachment);


             setVendorTicketIDValue(TicketData.vendor_ticket_id) // SOP-71 Inlcuded By PCS
             setTicketClosedDate(TicketData.service_completedate) //SOP-72 Included By PCS
             setTicketStatusValue(TicketData.sys_completion_status_name) // SOP-72 Inlcuded By PCS


             if (TicketData.attachment) {
                 // Convert base64 string to file object
                 const byteString = atob(TicketData.attachment.split(',')[1]);
                 const mimeString = TicketData.attachment.split(',')[0].split(':')[1].split(';')[0];
                 const ab = new ArrayBuffer(byteString.length);
                 const ia = new Uint8Array(ab);
                 for (let i = 0; i < byteString.length; i++) {
                     ia[i] = byteString.charCodeAt(i);
                 }
                const newFile = new File([ab], "", { type: mimeString });
                        setAttachment(newFile);
             }

            //  formattedIssueType
            let formattedIssueType = res.data.data.map((item:any) => {
                const { ticket_issuetype, sys_issue_type_name } = item;
                return {
                    name: sys_issue_type_name,
                    value: ticket_issuetype,
                };
                });
             setSelectedIssueTypeId(formattedIssueType[0].value)
            
            //  formattedIssueSubType 
             let formattedIssueSubType = res.data.data.map((item: any) => {
                const { ticket_issuesubtype, sys_issue_subtype_name} = item;
                // console.log("item new",item)
               return {
                   name: sys_issue_subtype_name,
                   value: ticket_issuesubtype,
               };
            });
            // console.log("formattedIssueSubType......",formattedIssueSubType)
               setSelectedIssueSubTypeId(formattedIssueSubType[0].value) 
            
            //  formattedContactType
            let formattedContactType = res.data.data.map((item:any) => {
               const { sys_contact_type_id, sys_contact_type_name } = item;
           return {
               name: sys_contact_type_name,
               value: sys_contact_type_id,
           };
               });
               setSelectedContactTypeId(formattedContactType[0].value) 
            
            //  formattedPriorityType
            let formattedPriorityType = res.data.data.map((item:any) => {
                const { sys_priority_id, sys_priority_name } = item;
                // console.log("item",item)
                    return {
                        name: sys_priority_name,
                        value: sys_priority_id,
                    };
               });
            setSelectedPriorityTypeId(formattedPriorityType[0].value) 
            
            //  formattedEmployee
            let formattedEmployee = res.data.data.map((item: any) => {
                const { employee_id, employee_name } = item;
                    return {
                        name: employee_name,
                        value: employee_id,
                    };
               });
               setSelectedEmployeeId(formattedEmployee[0].value) 

            //  formattedImpactType
            let formattedImpactType = res.data.data.map((item:any) => {
              const { sys_impact_id, sys_impact_name } = item;
                return {
                    name: sys_impact_name,
                    value: sys_impact_id,
                };
               });
            setSelectedImpactId(formattedImpactType[0].value) 

            //SOP-72 Included by PCS [Closure Information from ticket]
                let formattedClosureInformation = res.data.data.map((item:any) => {
               
                    const { closure_information, closure_information_name } = item;
                   return {
                    name: closure_information_name,
                    value: closure_information,
                      };
                });
                setselectedClosureInformationId(formattedClosureInformation[0].value)

           //EOF SOP-72 Included by PCS

//SOP-72 Commented below closure information

            // SOP-57 - TS - start
            //  Asset Management    - Ticketing - Activity screen
            //  formatted ClosureInformationType
            //   let formattedClosureInformationType = res.data.data.map((item:any) => {
            //     const { sys_ticket_closure_information_id, sys_ticket_closure_information_name } = item;
            //     return {
            //      name: sys_ticket_closure_information_name,
            //      value: sys_ticket_closure_information_id,
            //     };
            //     });
                // setSelectedClosureInformation(formattedClosureInformationType[0].value) 
              // SOP-57 - TS - end

//SOP-72 Commented above closure information
             
         }).catch((error) =>  
         { 
          Failed(error.response.message);
         });
    }


//SOP-71 To get Ticket Activity details by TicketId..
      const GetTicketActivityDetails =()=>
        {
             const payload={
                CompanyId:CompanyId,
                TicketId:id,
                        }
            // console.log('ActivityDetails Payload',payload)

            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getTicketActivityDetails`, payload)
            .then((res: any) => 
            { 
                // console.log("res activity details",res)
                const TicketData = res.data.data[0]
                // console.log("Activity Details ",TicketData)

                setCreatedDetails(TicketData.CreatedDetails)
                setAllocatedDetails(TicketData.AllocatedDetails)
                setAllocateRemarks(TicketData.AllocateRemarks)
                setOpenStatus(TicketData.OpenStatus)
                setCurrentStatus(TicketData.CurrentStatus)
                setServiceRemarks(TicketData.ServiceRemarks)
                setClosedDetails(TicketData.ClosingDetails)
               
            }).catch((error) =>  
            { 
            Failed(error.response.message);
            });
        }
//EOF SOP-71 To get Ticket Activity details by TicketId..


    // To handle TicketDueDateChange validation
   const handleTicketDueDateChange =(date:any)=>{
    setTicketDueDateValue(date);
        if (!date) {
            setTicketDueDateError(true)
            setTicketDueDateErrorMessage("Due Date is required");
        }
        else {
            setTicketDueDateError(false);
            setTicketDueDateErrorMessage("")
        }
    }

    // handleAttachmentChange
    const handleAttachmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] || null;
        setAttachment(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setAttachmentBase64(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    // To handle ShortDiscription validation
    const handleShortDiscriptionChange =( event:any)=>{
        const trimmedShortDiscriptionValue = event.target.value.trim();
        setShortDiscriptionValue(event.target.value);
        
        if (trimmedShortDiscriptionValue === '') {
            setShortDiscriptionError(true);
            setShortDiscriptionErrorMessage("Short Discription is required");
        } 
        else if( trimmedShortDiscriptionValue.length >225 ){
            setShortDiscriptionError(true);
            setShortDiscriptionErrorMessage("Max 255 characters");
        }
        else {
            setShortDiscriptionError(false);
            setShortDiscriptionErrorMessage("");
        }
        }

    // To handle DiscriptionChange validation
    const handleDiscriptionChange =( event:any)=>{
        const trimmedDiscriptionValue = event.target.value.trim();
        setDiscriptionValue(event.target.value);
        if( trimmedDiscriptionValue.length >1000 ){
            setDiscriptionError(true);
            setDiscriptionErrorMessage("Max 1000 characters");
        }
        else {
            setDiscriptionError(false);
            setDiscriptionErrorMessage("");
        }
    
    }

 // To handle LocationChange validation
    const handleLocationChange = (event: any) => {
        const trimmedLocationValue = event.target.value.trim();
        setLocationValue(event.target.value);
            if( trimmedLocationValue.length >225 ){
            setLocationError(true);
            setLocationErrorMessage("Max 255 characters");
        }
        else {
            setLocationError(false);
            setLocationErrorMessage("");
        }
     
    }
       // Define a type for errors
       type Errors = {
           issueType?:boolean;
           issueDiscription?: boolean;
           issueSubType?: boolean;
           constactType?: boolean;
           priorityType?: boolean;
           employee?: boolean;
           impact?: boolean;
           shortDiscription?: boolean;
           discription?: boolean;
           location?: boolean;
           ticketDueDate?: boolean;

           //  SOP-55 - TS -Start
           //  Asset Management    - Ticketing - Activity screen
           closureInformation?: boolean;   //closure Information Errors
    };

    // To save updated data
    const EditRiseATicket = (event: any) => {
    //    console.log("EditRiseATicket called")
        event.preventDefault();

        let errors: Errors = {};

        // Location Error
        const trimmedLocationValue = LocationValue.trim();
        if (trimmedLocationValue.length > 225) {
            errors.discription = true
            setLocationErrorMessage("Max 255 characters");
        }  

        // Discription Error
        const trimmedDiscriptionValue = DiscriptionValue.trim();
        if (trimmedDiscriptionValue.length > 225) {
            errors.discription = true
            setDiscriptionErrorMessage("Max 255 characters");
        }

        // Short Discription Error
        const trimmedshortdiscriptionValue = ShortDiscriptionValue.trim();
        if (trimmedshortdiscriptionValue === '') {
            errors.shortDiscription = true
            setShortDiscriptionError(true);
            setShortDiscriptionErrorMessage("Short Discription is required");
        }
        else if (trimmedshortdiscriptionValue.length > 225) {
            errors.shortDiscription = true
            setShortDiscriptionError(true);
            setShortDiscriptionErrorMessage("Max 255 characters");
        }

        // Issue Discription Error
        const trimmedIssuediscriptionValue = IssuediscriptionValue.trim();
        if (trimmedIssuediscriptionValue.length > 225) {
            // errors.issueDiscription = true;
            setIssuediscriptionError(true);
            setIssuediscriptionErrorMessage("Max 255 characters");
        }
        else {
            setIssuediscriptionError(false);
            setIssuediscriptionErrorMessage("");
        }

        // TicketDueDate Error
        
    //SOP-78 Commented By PCS [remove Ticket Due date]
        // let trimmedTicketDueDateValue;
        // if (TicketDueDateValue === null || TicketDueDateValue === undefined) {
        //     errors.ticketDueDate = true;
        //     setTicketDueDateErrorMessage("Due Date is required");
        // }
        // else {
        //     let dateString;
        //     if (typeof TicketDueDateValue.format === 'function') {
        //         dateString = TicketDueDateValue.format(); // Assuming your `Dayjs` object has a `format()` method
        //     } else {
        //         dateString = TicketDueDateValue.toString(); // Convert to string or handle differently based on what EmployeeDateOfJoiningValue is
        //     }
        //     trimmedTicketDueDateValue = dateString.trim();
        //     //console.log("trimmedEmployeeDateOfJoiningValue.l", trimmedEmployeeDateOfJoiningValue.length);

        //     if (trimmedTicketDueDateValue === "") {
        //         errors.ticketDueDate = true;
        //         setTicketDueDateErrorMessage("Due Date is required");
        //     } else if (trimmedTicketDueDateValue.length < 24) {
        //         errors.ticketDueDate = true;
        //         setTicketDueDateErrorMessage("Please check Due Date");
        //     } else {
        //         setTicketDueDateErrorMessage("");
        //     }
        // }
    //EOF SOP-78 Commented By PCS [remove Ticket Due date]
    //SOP-78 Commented By PCS [remove Ticket Due date]
        // Formated TicketDueDate
        // let FormatedTicketDueDate;
        //     if (trimmedTicketDueDateValue) {
        //         const trimmedDate = new Date(trimmedTicketDueDateValue);
        //         const year = trimmedDate.getFullYear();
        //         const month = String(trimmedDate.getMonth() + 1).padStart(2, '0');
        //         const date = String(trimmedDate.getDate()).padStart(2, '0');
        //         FormatedTicketDueDate = `${year}-${month}-${date}`;
        //     }
            // console.log("FormatedTicketDueDate",FormatedTicketDueDate)
    //EOF SOP-78 Commented By PCS [remove Ticket Due date]

            if(selectedEmployeeId === null || selectedEmployeeId === '' || selectedEmployeeId === undefined ){
                errors.employee=true;
                setEmployeeErrorMessage("Employee is required")
            }
            if(selectedIssueTypeId === null || selectedIssueTypeId === '' || selectedIssueTypeId === undefined ){
                errors.issueType=true;
                setIssueTypeErrorMessage("Category is required")
            }
            if( selectedIssueSubTypeId === null || selectedIssueSubTypeId === '' || selectedIssueSubTypeId === undefined ){
                errors.issueSubType = true;
                setIssueTypeSubErrorMessage("Sub Category is required");
            }
            if( selectedContactTypeId === null || selectedContactTypeId === '' || selectedContactTypeId === undefined ){
                errors.constactType = true;
                setContactTypeErrorMessage("Contact Type is required");
            }
            if( selectedPriorityTypeId === null || selectedPriorityTypeId === '' || selectedPriorityTypeId === undefined ){
                errors.priorityType = true;
                setPriorityTypeErrorMessage("Priority is required");
            }
            if( selectedImpactId === null || selectedImpactId === '' || selectedImpactId === undefined ){
                errors.impact = true;
                setImpactTypeErrorMessage("Impact is required");
            }
            // if( selectedClosureInformation === null || selectedClosureInformation === '' || selectedClosureInformation === undefined ){
            //     errors.closureInformation = true;
            //     setImpactTypeErrorMessage("ClosureInformation is required");
        // }
        
             if (Object.keys(errors).length > 0) {
                // If there are errors, update the state with all the errors
                setIssueTypeError(errors.issueType || false);
                setIssuediscriptionError(errors.issueDiscription || false);
                setIssueTypeSubError(errors.issueSubType || false);
                setContactTypeError(errors.constactType || false);
                setPriorityTypeError(errors.priorityType || false);
                setDiscriptionError(errors.discription || false);
                setLocationError(errors.location || false);
                // setTicketDueDateError(errors.ticketDueDate || false);//SOP-78 Commented By PCS [remove Ticket Due date]
                setImpactTypeError(errors.impact || false);
                 setEmployeeError(errors.employee || false);

                 //  SOP-57 - TS
                //  Asset Management    - Ticketing - Activity screen
                //  setClosureInformationError(errors.closureInformation || false);
            return;
            }


        const RaiseATicketPayload = {
            SaveUpdateKey: 'update',
            CompanyId: CompanyId,
            TicketingId:id,
            TicketingIssueTypeId: selectedIssueTypeId,
            TicketingIssueDiscription:DiscriptionValue,
            TicketingRaisedBy: UserId,
            CreatedBy: UserId,
            ContactType:selectedContactTypeId,
            //SOP-8 Modified By PCS [remove Ticket Due date]
            // TicketDueDate:FormatedTicketDueDate,
            TicketDueDate:null,
            //EOF SOP-78 Modified By PCS [remove Ticket Due date]

            Employee:selectedEmployeeId,
            Impact:selectedImpactId,
            TicketingIssueSubTypeId:selectedIssueSubTypeId,
            Priority:selectedPriorityTypeId,
            TicketingIssueShortDiscription: ShortDiscriptionValue,
            Attachment: attachmentBase64,
            location: LocationValue,
            // selectedClosureInformation
        }

         console.log("RaiseATicketPayload",RaiseATicketPayload)
        commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/ticketing_create_update_cancel`, RaiseATicketPayload)
            .then((res) => {
                if (res.status === 200) {
                    Success("Issue Updated Successfully");
                    navigate('/home')
                }
            }).catch((error) => {
                console.error("Error:", error);
            });    
    }
        useEffect(() => {
            GetIssueTypeList();
            GetTickteListById();
            GetContactTypeList();
            GetPriorityTypeList();
            GetEmployeeList();
            GetImpactList();
            GetIssueTypeList();
            GetTickteListById();

            // SOP-57 - TS
            // Asset Management    - Ticketing - Activity screen
            GetClosureInformationList();  //GetClosureInformation List 

            GetTicketActivityDetails(); //SOP-71 Included by PCS
        }, []); 
           
        useEffect(() => {
            if (selectedIssueTypeId) {
                GetIssueSubTypeList(selectedIssueTypeId);
            }
        }, [selectedIssueTypeId]);



//SOP-71 Included By PCS [to randomly generate color for the first letter of the text]

        function stringToColor(string: string) {
            let hash = 0;
            let i;

            for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
            }
            // console.log('hash',hash)
        
            let color = '#';
        
            for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
            }

            // console.log('colorssss',color)
           return color;
        }
        

        function stringAvatar(name: string) {
            return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name[0]}`, // Use only the first letter of the name
            };
        }


//SOP-71 Included By PCS



    return(
        <Box> 
            <Box>
                <Box display={'flex'}  justifyContent={'space-evenly'}>                   
                    <Box bgcolor={'white'} borderRadius={3}
                        sx={{
                            width:{
                                lg:1150,
                                md:600,
                                xs:700
            
                            }}}
                    >
                    <Box p={3}>
                        <Typography variant='h1'
                        sx={{
                            fontSize:{
                                lg:'2rem',
                                md:'2rem',
                                xs:'1.5rem'
                            }
                        }}
                        >
                        Edit Ticket
                        </Typography>
                    </Box>
                        <Box p={3}>
                            <form  noValidate>
                                <Box>
                                <Grid container spacing={3.5} mb={4} display={'flex'} justifyContent={'space-evenly'}>
                                        <Grid item lg={4} md={12} xs={12}>
                                                <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Number'
                                                    name='Number'
                                                    fullWidth
                                                    disabled
                                                    value={TicketIdValue}
                                                /> 
                                        </Grid>
                                        <Grid item lg={4} md={12} xs={12} >
                                            <FormControl fullWidth>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            label="Ticket Date"
                                                            value={TicketstartDateValue ? dayjs(TicketstartDateValue) : null}
                                                            onChange={(newValue: Dayjs | null) => {
                                                            // console.log("New date value:", newValue); 
                                                            setTicketDateValue(newValue);
                                                        }}
                                                            format="DD-MM-YYYY"
                                                            disabled
                                                            slotProps={{
                                                                textField: {
                                                                }
                                                            }}
                                                        />                                                        
                                                        <FormHelperText
                                                            error
                                                            id="standard-weight-helper-text-dob-login"
                                                        >
                                                        </FormHelperText>
                                                </LocalizationProvider>
                                            </FormControl> 
                                        </Grid>
                                        <Grid item lg={4} md={12} xs={12} >
                                            <Box>
                                           {/* //SOP-78  Commented By PCS [remove ticket due date] */}
                                                {/* <FormControl
                                                    fullWidth
                                                >
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            label="Ticket Due Date"
                                                            value={TicketDueDateValue ? dayjs(TicketDueDateValue) : null}
                                                            onChange={handleTicketDueDateChange}
                                                            format="DD-MM-YYYY"
                                                            minDate={dayjs()} // Disable past dates
                                                            autoFocus
                                                            slotProps={{
                                                                textField: {
                                                                    error: Boolean(TicketDueDateError),
                                                                    required: true
                                                                }
                                                            }}
                                                        />                                                        
                                                        {TicketDueDateError && (
                                                            <FormHelperText
                                                                error
                                                                id="standard-weight-helper-text-dob-login"
                                                            >
                                                                {TicketDueDateErrorMessage}
                                                            </FormHelperText>
                                                        )}      
                                                    </LocalizationProvider>
                                                </FormControl> */}
                                            {/* //EOF SOP-78 Commented By PCS [remove ticket due date]*/}
                                            </Box> 
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3.5} mb={4} display={'flex'} justifyContent={'space-evenly'}>
                                        <Grid item lg={4} md={12} xs={12} >
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={ContactTypeOptions as OptionType[]}
                                                filterOptions={filterOptions}
                                                getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                value={ContactTypeOptions.find((option: OptionType) => option.value === selectedContactTypeId) || null}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        setSelectedContactTypeId(newValue.value);
                                                        setContactTypeError(false);
                                                        setContactTypeErrorMessage("");
                                                    } else {
                                                        setSelectedContactTypeId('');
                                                        setContactTypeError(true);
                                                        setContactTypeErrorMessage("Contact is required");
                                                    }
                                                }}
                                                renderInput={(params) => 
                                                <TextField {...params}
                                                    error={ContactTypeError}
                                                    
                                                    helperText={ContactTypeErrorMessage}
                                                    required label="Contact Type" 
                                                    variant="standard"
                                                />}
                                            />
                                        </Grid>
                                        <Grid item lg={4} md={12} xs={12}>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={EmployeeOptions as OptionType[]}
                                                filterOptions={filterOptions}
                                                getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                value={EmployeeOptions.find((option: OptionType) => option.value === selectedEmployeeId) || null}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        setSelectedEmployeeId(newValue.value);
                                                        setEmployeeError(false);
                                                        setEmployeeErrorMessage("");
                                                    }
                                                    else {
                                                        setSelectedEmployeeId('');
                                                        setEmployeeError(true);
                                                        setEmployeeErrorMessage("Employee is required");
                                                    }
                                                }}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        error={EmployeeError}
                                                        helperText={EmployeeErrorMessage}
                                                        required label="Employee"
                                                        variant="standard"
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item lg={4} md={12} xs={12}>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={ImpactOptions as OptionType[]}
                                                filterOptions={filterOptions}
                                                getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                value={ImpactOptions.find((option: OptionType) => option.value === selectedImpactId) || null}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        setSelectedImpactId(newValue.value);
                                                        setImpactTypeError(false);
                                                        setImpactTypeErrorMessage("");
                                                    }
                                                    else {
                                                        setSelectedImpactId("");
                                                        setImpactTypeError(true);
                                                        setImpactTypeErrorMessage("Impact is required");
                                                    }
                                                }}
                                                renderInput={(params) => 
                                                    <TextField {...params}
                                                        error={ImpactTypeError}
                                                        helperText={ImpactTypeErrorMessage}
                                                        required label="Impact" 
                                                        variant="standard"
                                                    />}
                                                />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3.5} mb={4}display={'flex'} justifyContent={'space-evenly'}>
                                        <Grid item lg={4} md={12} xs={12} >
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={PriorityTypeOptions as OptionType[]}
                                                filterOptions={filterOptions}
                                                getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                value={PriorityTypeOptions.find((option: OptionType) => option.value === selectedPriorityTypeId) || null}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        setSelectedPriorityTypeId(newValue.value);
                                                        setPriorityTypeError(false);
                                                        setPriorityTypeErrorMessage("");
                                                    } else {
                                                        setSelectedPriorityTypeId('');
                                                        setPriorityTypeError(true);
                                                        setPriorityTypeErrorMessage("Priority is required");
                                                    }
                                                }}
                                                renderInput={(params) => 
                                                <TextField {...params}
                                                    error={PriorityTypeError}
                                                    helperText={PriorityTypeErrorMessage}
                                                    required label="Priority" 
                                                    variant="standard"
                                                />}
                                            />
                                        </Grid>
                                        <Grid item lg={4} md={12} xs={12} >
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={IssueTypeOptions as OptionType[]}
                                                filterOptions={filterOptions}
                                                getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                value={IssueTypeOptions.find((option: OptionType) => option.value === selectedIssueTypeId) || null}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        setSelectedIssueTypeId(newValue.value);
                                                        setIssueTypeError(false);
                                                        setIssueTypeErrorMessage("");
                                                        setSelectedIssueSubTypeId('');
                                                    } else {
                                                        setSelectedIssueTypeId('');
                                                        setIssueTypeError(true);
                                                        setIssueTypeErrorMessage("Category is required");
                                                    }
                                                }}
                                                renderInput={(params) => 
                                                <TextField {...params}
                                                    error={IssueTypeError}
                                                    helperText={IssueTypeErrorMessage}
                                                    required label="Category" 
                                                    variant="standard"
                                                />}
                                            />
                                        </Grid>
                                        <Grid item lg={4} md={12} xs={12} >                                           
                                            <Autocomplete
                                                id='Sub Category'
                                                options={IssueSubTypeOptions as OptionType[]}
                                                filterOptions={filterOptions}
                                                getOptionLabel={(option) => option.name}
                                                value={IssueSubTypeOptions.find(option => option.value === selectedIssueSubTypeId) || null}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        setSelectedIssueSubTypeId(newValue.value);
                                                        setIssueTypeSubError(false);
                                                        setIssueTypeSubErrorMessage("");
                                                    } else {
                                                        setSelectedIssueSubTypeId('');
                                                        setIssueTypeSubError(true);
                                                        setIssueTypeSubErrorMessage("Sub Category is required");
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        error={IssueTypeSubError}
                                                        helperText={IssueTypeSubErrorMessage}
                                                        required
                                                        label="Sub Category"
                                                        variant="standard"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item mt={2} lg={12} md={12} xs={12} >
                                        <TextField 
                                            id="outlined-basic" 
                                            label="Short Description" 
                                            variant="outlined" 
                                            name='ShortDescription'
                                            required
                                            fullWidth
                                            multiline
                                            rows={3}
                                            error={ShortDiscriptionError}
                                            onChange={handleShortDiscriptionChange}
                                            value={ShortDiscriptionValue}
                                            helperText={ShortDiscriptionErrorMessage}
                                        />
                                    </Grid>
                                    <Grid item mt={2} lg={12} md={12} xs={12} >
                                        <TextField 
                                            id="outlined-basic" 
                                            label="Description" 
                                            variant="outlined" 
                                            name='Description'
                                            fullWidth
                                            multiline
                                            rows={10}
                                            error={DiscriptionError}
                                            onChange={handleDiscriptionChange}
                                            value={DiscriptionValue}
                                            helperText={DiscriptionErrorMessage}
                                        />
                                    </Grid>
                                    <Grid container spacing={3.5} mb={4}display={'flex'} justifyContent={'space-evenly'}>
                                        <Grid item mt={2} lg={4} md={12} xs={12} >
                                            <TextField 
                                                label="Location" 
                                                variant="standard" 
                                                name='Issuediscription'
                                                fullWidth
                                                error={LocationError}
                                                onChange={handleLocationChange}
                                                value={LocationValue}
                                                helperText={LocationErrorMessage}
                                            />
                                        </Grid>
                                        <Grid item mt={2} lg={4} md={12} xs={12}>   </Grid>
                                        <Grid item mt={2} lg={4} md={12} xs={12}>   </Grid>
                                    </Grid>
                                   
                                    <Grid item xs={12}>
                                         <input
                                            type="file"
                                            onChange={handleAttachmentChange}
                                            style={{
                                                backgroundColor: "DodgerBlue",
                                                color: "white",
                                                padding: "10px 20px",
                                                border: "none",
                                                borderRadius: "5px",
                                                cursor: "pointer"}}
                                        />
                                        {attachment && (
                                            <Typography>{attachment.name}</Typography>
                                            )}
                                        {attachmentBase64 && (
                                            <a href={attachmentBase64} download="attachment">Download/View Attachment</a>
                                        )}
                                    </Grid>
                                </Box>
                            <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Button
                                    sx={{
                                        background: 'white',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                                        },
                                        // color: {accent},
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}
                                        onClick={()=>{
                                            navigate('/home')
                                        }} 
                                >
                                    Back
                                </Button>

                                <Button
                                    sx={{
                                        background: '#25425F',
                                        border: 'none',
                                        borderRadius: '5px',
                                        "&:hover":{
                                            backgroundColor:'#25425F',
                                            color:'white',
                                        },
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        color: 'white',
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}
                                    type='submit'
                                    onClick={(event)=>{
                                        EditRiseATicket(event);
                                    }}
                                >
                                    Update
                                </Button>
                                </Box>
                            </form>
                        </Box>

                    </Box>

                    {/* SOP-57 - TS  Asset Management    - Ticketing - Activity screen  - start*/}

                    {/* Activity screen - start */}
                    <Box bgcolor={'white'} borderRadius={3}
                    sx={{
                        width:{
                            lg:600,
                            md:650,
                            xs:500
                        }
                    }}
                    >
                    <Box p={3}>
                            <Typography variant='h1'
                                sx={{
                                    fontSize:{
                                        lg:'2rem',
                                        md:'2rem',
                                        xs:'1.5rem'
                                    }
                                }}
                            >
                                Activity
                            </Typography>
                        </Box>
                        <Box p={3}>
                            <form  noValidate>
                                <Box>
                                    {/* <Grid container spacing={3.5} mb={4}  justifyContent={'space-evenly'}> */}
                                        <Grid item lg={5} md={12} xs={12} mb={2.5}>
                                            <TextField
                                                type='text'
                                                variant="standard"
                                                label='Status'
                                                name='Status'
                                                fullWidth
                                                
                                                //SOP-72 Included By PCS
                                                disabled
                                                value={TicketStatusValue || ''}
                                                // defaultValue={"NEW"}
                                                //SOP-72 Included By PCS
                                            /> 
                                        </Grid>
                                        <Grid item lg={5} md={12} xs={12} mb={2.2} >
                                            <FormControl fullWidth>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            label="Opened"
                                                            value={TicketstartDateValue ? dayjs(TicketstartDateValue) : null}
                                                            disabled
                                                            onChange={(newValue: Dayjs | null) => {
                                                            // console.log("New date value:", newValue); 
                                                            setTicketDateValue(newValue);
                                                        }}
                                                            format="DD-MM-YYYY"
                                                            slotProps={{
                                                                textField: {
                                                                }
                                                            }}
                                                        />                                                        
                                                        <FormHelperText
                                                            error
                                                            id="standard-weight-helper-text-dob-login"
                                                        >
                                                        </FormHelperText>
                                                </LocalizationProvider>
                                            </FormControl> 
                                        </Grid>

                                        <Grid item lg={5} md={12} xs={12} mb={2.5}>
                                            <Box>
                                                <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Vendor Ticket ID'
                                                    name='Vendor Ticket ID'
                                                    // style={{ margin: '0 10px' }}
                                                    fullWidth
                                                    //SOP-71 Included By PCS[Included vendor ticket id]
                                                    disabled
                                                    value={VendorTicketIDValue || ''}
                                                    //EOF SOP-71 Included By PCS[Included vendor ticket id]
                                                 /> 
                                            </Box>
                                        </Grid>
                                        <Grid item lg={5} md={12} xs={12} mb={2.5} >
                                            <Box>
                                                <FormControl
                                                    fullWidth
                                                >
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            label="Closed On"
                                                            //SOP-72 Included By PCS
                                                             value={TicketClosedDate ? dayjs(TicketClosedDate) : null}
                                                             //EOF SOP-72 Included By PCS
                                                             
                                                             format="DD-MM-YYYY"
                                                            disabled
                                                            // minDate={dayjs()} // Disable past dates
                                                            slotProps={{
                                                                textField: {
                                                                    error: Boolean(TicketDueDateError),
                                                                    required: true
                                                                }
                                                            }}
                                                        />                                                        
                                                        {TicketDueDateError && (
                                                            <FormHelperText
                                                                error
                                                                id="standard-weight-helper-text-dob-login"
                                                            >
                                                                {TicketDueDateErrorMessage}
                                                            </FormHelperText>
                                                        )}     
                                                    </LocalizationProvider>
                                                </FormControl>                                                    
                                            </Box> 
                                        </Grid>
                                    <Grid item lg={4} md={12} xs={12} mb={2.5}>
                                        {/* //SOP-72 Included By PCS */}
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={ClosureInformationOptions as OptionType[]}
                                            filterOptions={filterOptions}
                                            disabled
                                            getOptionLabel={(option: unknown) => (option as OptionType).name}

                                            value={ClosureInformationOptions.find((option: OptionType) => option.value === selectedClosureInformationId) || null}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setselectedClosureInformationId(newValue.value);
                                                } else {
                                                    setselectedClosureInformationId('');
                                                }
                                            }}
                                            renderInput={(params) => 
                                                <TextField {...params}
                                                required label="Closure Information" 
                                                variant="standard"
                                            />}
                                        />
                                        {/* //EOF SOP-72 Included By PCS */}
                                        </Grid>
        {/* //SOP-71 Inlcuded By PCS */}
        <Grid item lg={12} md={12} xs={12} >
                        {/* <Typography sx={{color:'#3498db'}} variant='h3'>Activity </Typography> */}

                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>

                        {shouldDisplayAvatar(CreatedDetails) && (
                            <div style={{ display: 'flex', marginTop: '17px', alignItems: 'center' }}>
                            <Avatar style={{ width: 41, height: 41}} {...stringAvatar(CreatedDetails)} />
                            <Typography sx={{ fontSize: '16px', marginLeft: '8px' }}>
                                {CreatedDetails}
                            </Typography>
                            </div>
                        )}

                        {shouldDisplayAvatar(AllocatedDetails) && (
                        <div style={{display:'flex',marginTop:'12px'}}>
                            <Avatar  style={{ width: 41, height: 41 }}   {...stringAvatar(AllocatedDetails)} />
                            <Typography sx={{fontSize:'16px',marginLeft:'8px'}}  >{AllocatedDetails}</Typography>
                            </div>
                        )}
                    
                       <Typography  sx={{color:'gray',marginLeft:'48px',fontSize:'15px'}} mt={0.75} >{AllocateRemarks}</Typography>
                    
                        {shouldDisplayAvatar(OpenStatus) && (
                            <div style={{display:'flex',marginTop:'12px'}}>
                            <Avatar  style={{ width: 41, height: 41 }}  {...stringAvatar(OpenStatus)} />
                            <Typography sx={{fontSize:'16px',marginLeft:'8px',marginTop:'7px'}}  >{OpenStatus}</Typography>
                            </div>
                        )}

                        <Typography sx={{fontSize:'16px',marginLeft:'52px'}} mt={1} >{CurrentStatus}</Typography> 
                        <Typography sx={{color:'gray',marginLeft:'58px',fontSize:'15px'}} mt={0.75} >{ServiceRemarks}</Typography> 
                            
                        {shouldDisplayAvatar(ClosedDetails) && (  
                            <div style={{display:'flex',marginTop:'12px'}}>
                            <Avatar  style={{ width: 41, height: 41}}  {...stringAvatar(ClosedDetails)} />
                            <Typography sx={{fontSize:'16px',marginLeft:'8px',marginTop:'8px'}}>{ClosedDetails}</Typography> 
                            </div>
                        )}
                </div>
            </Grid>
        {/* //EOF  SOP-71 Inlcuded By PCS */}
                                </Box>
                            </form>
                        </Box>
                    </Box>
                    {/* Activity screen - end */}
                    {/* SOP-57 - TS  Asset Management    - Ticketing - Activity screen  - end*/}

                
                </Box>
            </Box>        
        </Box>
    )
}
export default EditRaiseATicketPage;

// SOP-55 - TS - Edit page - end