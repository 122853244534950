// Imports
import {
    Box,
    Grid,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import MainContainerWrapper from '../../atic-common-helpers/layout/wrappers/MainContainerWrapper';
//SOP-36 - TS
////Dashboard - Asset - Metrics
import PieChartCategories from "../../component/charts/PieChartCategories"; //Categories count PieChart 
import PieChartAMCRenewal from "../../component/charts/PieChartAMCRenewal"; //AMCRenewal count PieChart 

//SOP-38 - TS 
//Asset Management - Dashboard - Ticketing
import PieChartTicketsAssignee from "../../component/charts/PieChartTicketsAssignee"; //Assignee count PieChart 
import PieChartTicketsByAssignee from "../../component/charts/PieChartTicketsByAssignee"; //ByAssignee count PieChart 
import PieChartTicketsStatus from "../../component/charts/PieChartTicketsStatus"; //TicketsStatus count PieChart 

//DashBoard Page
const DashBoardPage = ( ) =>{
    return(
        <>
        <MainContainerWrapper headingText={'Dashboard'}>
                {/* SOP-36 -TS
                {/* Dashboard - Asset - Metrics */}
                 <Box >
                <Box   mt={5} 
            sx={{   
                    display:{
                        lg:'flex',
                        xs:'flex',
                        md:'flex',
                    },
                    justifyContent:{
                       
                        lg:'center',
                        xs:'center',
                        md:'center',
     
                    },
                }}>
                <Grid container spacing={3} >
                    <Grid item lg={6} md={12} xs={12}>
                        <Grid container>
                            <Grid item lg={12} md={12} xs={12} >
                                {/* <Box bgcolor={'white'} p={2} mt={0}> */}
                                <Box bgcolor={'white'} p={2} mt={0}>
                                    <Typography variant='h3'>
                                     Categories
                                    </Typography>
                                    <PieChartCategories/> {/* SOP-36 - TS  TicketsAssignee piechart*/}  
                                </Box>                                
                            </Grid>
                            <Grid item lg={12} md={12} xs={12}>
                                <Box bgcolor={'white'} p={2} mt={3}>
                                    <Typography variant='h3'>
                                    Ticket's Assignee
                                    </Typography>
                                    <PieChartTicketsAssignee/>  {/*SOP-38 - TS  TicketsAssignee piechart*/}       
                                </Box>                                
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={6} md={12} xs={12}>
                        <Grid container>
                            <Grid item lg={12} md={12} xs={12}>
                                <Box bgcolor={'white'}  p={2} >
                                    <Typography variant='h3'>
                                        {/* //SOP-77 feedback points modified by PCS [changed the displaying order] */}
                                            {/* Ticket's By Assignee */}
                                               AMC Renewal
                                        {/* //EOF SOP-77 feedback points modified by PCS [changed the displaying order] */}
                                    </Typography>
                                        {/* //SOP-77 feedback points modified by PCS [changed the displaying order] */}
                                            {/* <PieChartTicketsByAssignee/> {/*SOP-38 - TS  TicketsByAssignee piechart*/}  
                                            <PieChartAMCRenewal/> {/* SOP-36 - TS  AMC Renewal piechart*/}   
                                        {/* //EOF SOP-77 feedback points modified by PCS [changed the displaying order] */}

                                </Box>
                            </Grid>

                            <Grid item lg={12} md={12} xs={12}>
                                <Box bgcolor={'white'} mt={3} p={2} >
                                    <Typography variant='h3'>
                                       Ticket's Status
                                    </Typography>
                                    <PieChartTicketsStatus/>  {/*SOP-38 - TS  Status piechart*/}
                                </Box> 
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={6} md={12} xs={12}>
                        <Grid container>
                            <Grid item lg={12} md={12} xs={12} >
                                <Box bgcolor={'white'} p={2} mt={0}>
                                    <Typography variant='h3'>
                                     {/* AMC Renewal */}
                                    </Typography>
                                    {/* <PieChartAMCRenewal/> {/* SOP-36 - TS  AMC Renewal piechart*/}   

                                        <Typography variant='h3'>
                                        {/* //SOP-77 feedback points modified by PCS [changed the displaying order] */}
                                            {/* AMC Renewal */}
                                            Ticket's By Assignee 
                                        {/* //EOF SOP-77 feedback points modified by PCS [changed the displaying order] */}
                                        </Typography>
                                        {/* //SOP-77 feedback points modified by PCS [changed the displaying order] */}
                                            {/* <PieChartAMCRenewal/> {/* SOP-36 - TS  AMC Renewal piechart*/}  
                                            <PieChartTicketsByAssignee/> {/*SOP-38 - TS  TicketsByAssignee piechart*/}  
                                        {/* //EOF SOP-77 feedback points modified by PCS [changed the displaying order] */}

                                </Box>                                
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
                </Box> 
                </Box>
                {/* SOP-36 -TS */}
        </MainContainerWrapper>
        </>
    )
}
export default DashBoardPage;