
// SOP-61 - Included By PCS 

import { useState,useRef,useEffect ,useCallback } from 'react'; // Import useState hook
import React from 'react';

import { EditorState, ContentState, convertToRaw,RawDraftContentState  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html'; //TO convert to html format

    import {
        Box, 
        Button,
        Grid,
        Paper,
        Dialog,
        Accordion,
        AccordionSummary,
        AccordionDetails,
        FormControl,
        FormHelperText,
    
    } from '@mui/material';
    import Typography from '@mui/material/Typography';
    import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
    import axios from "axios";
    import MainContainerWrapper from '../../atic-common-helpers/layout/wrappers/MainContainerWrapper';
    import { useNavigate } from "react-router-dom";
    import { commonService } from '../../atic-common-helpers/helpers/common.service';
    import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
    import CryptoJS from 'crypto-js';
    import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
    import { MdContentPaste } from "react-icons/md";
    import { useParams } from 'react-router-dom';

 const CreateDraft =()=>{

    const { title } = useParams<{ title?: string }>(); // Ensure title is optional and can be undefined
    // console.log('title is',title)
    const navigate = useNavigate();
   
 
    //To save the typed  content
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [displayedContent, setDisplayedContent] = useState<string>('');

    //To Format the content  
        const handleDisplayContent = (event:any) => {
                // console.log('click1')
                const contentState = editorState.getCurrentContent();
                const rawContent = convertToRaw(contentState);
                const htmlContent = draftToHtml(rawContent);
                setDisplayedContent(htmlContent); // Store HTML content in state
                // console.log('3',contentState)
                // console.log('4',rawContent)
                // console.log('5',htmlContent)
                CreateDocPoint(event,htmlContent)
                                            };
                // console.log('1',editorState)
                // console.log('2',displayedContent)
    //EOF To save the typed  content   


    // To get employee name by employeeid
        const getEmployeeById = async () => {

            const EmployeeLisPayload ={
                EmployeeId:EmployeeId,
                CompanyId:CompanyId
              }
            try {
                const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/employee/getEmployeeById`,EmployeeLisPayload);
                    let EmployeeName = res.data.data[0].employee_name
                    // console.log("Employee Name" ,EmployeeName);
                } 
            catch (err) {
                console.log( "co",err);
                        }
            };
   // EOF To get employee name by employeeid


    // Define type for errors
    type Errors = {
        DocumentTitle?:boolean;
        };

    // CReating document API
    const CreateDocPoint =async (event:any,content:any)=>
        {
            //   console.log(' the content...',content)
              event.preventDefault();
              let errors: Errors = {};

    //Document Title
         if (Object.keys(errors).length > 0) {
            // If there are errors, update the state with all the errors
            
             console.log("errors",errors)
               return;
                                          }

    //    console.log('content is',displayedContent)    

            const PayloadCreateDocPoint={

                SaveUpdateKey:'create',
                CompanyId:CompanyId,
                DocumentId:null,
                SequenceNumber:null,
                TitleName:title,
                VersionNumber:'',
                EffectiveFrom:null,
                CategoryId:null,
                AuthorId:UserId,
                isCancelled:null,
                CreatedBy:UserId,
                CreatedDateTime:null,
                lastModifiedBy:null,
                lastModifiedDate:null,
                Content:content
            }
        //    console.log("CreateDocPoint",PayloadCreateDocPoint)
      
          commonService
              .postService(`${process.env.REACT_APP_BACKEND_URL}/docpoint/docpoint_document_create_update_delete`, PayloadCreateDocPoint)
              .then((res) => {
                if (res.status === 200) {
                    Success("Document created successfully");
                    navigate('/docpoint')
                }
                }).catch((error) => {
                  Failed("Document creation failed ");
                    console.error("Error:", error);
                });
     
        }
   
    //  Global data from session storage
            const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
            const GlobalData = JSON.parse(GlobalDataFromSession);
            console.log("GlobalData",GlobalData)
            let CompanyId = GlobalData.GlobalCompanyId
            const UserId = GlobalData.GlobalUserId;
            let EmployeeId = GlobalData.GlobalEmployeeId
            let UserName = GlobalData.GlobalUserName
            //    console.log('user',UserId)
            //    console.log('employee',EmployeeId)

   useEffect(() => {

        getEmployeeById();
       }, []); 


//To display created  Date

        const todayDate = new Date();
        const Year = todayDate.getFullYear();
        const Month = (todayDate.getMonth() + 1).toString().padStart(2, '0');
        const Day = todayDate.getDate().toString().padStart(2, '0');
        const FormattedDate = `${Day}-${Month}-${Year}`;

    return(
        // <MainContainerWrapper headingText={title?title :''}>
        <MainContainerWrapper headingText={title ? `${title} / ${FormattedDate} /${UserName} ` : `${FormattedDate} /${UserName}`}>
        <Box>
        <form noValidate>
        <Box>
{/*Main grid starts */}        
        <Grid container spacing={3} display={'flex'} justifyContent={'center'}>
                                 
{/* grid starts */}
        <Grid item lg={11} md={12} xs={12} >
                    {/* Content RTE Accordian */}
                        <Box mt={1}>
                            
                            <Accordion defaultExpanded sx={{
                                    borderRadius:'30px',
                            }}>
                                <AccordionSummary
                                    expandIcon={<ArrowDropDownIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <MdContentPaste  height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
                                    <Typography fontSize={'1rem'} ml={2}>Content</Typography> 
                                </Box>
                                </AccordionSummary>
                                <AccordionDetails sx={{marginBottom:5}}>
                                <Box>
                                    {/* //Rte design starts */}
                                        <Box style={{ width: '95%',marginLeft:'3%',marginTop:'1%',height:'90%'}}>
                                                <Typography variant="h4">Write something:</Typography>
                                                <Editor
                                                    editorState={editorState}
                                                    onEditorStateChange={setEditorState}
                                                    editorStyle={{ backgroundColor: '#f0f0f0',marginLeft:'4px',height:'725px'}}
                                                />
                                                {displayedContent && (
                                                <div></div>
                                                )}
                                        </Box>  
                                        {/* //Rte design starts */}
                                </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    {/* EOF Content RTE Accordian */}
        </Grid>
{/*  grid ends */}
        </Grid>
{/*Main grid starts */}    
                    
        <Box>                    
            <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Button
                        sx={{
                            background: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            padding:{
                                lg: "0 70px",
                                md: "0 70",
                                xs:'0 50px'
    
                            },
                            height: "3rem",
                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                        }}

                        onClick={()=>{
                            navigate('/docpoint')
                        }} 
                    >
                     Back
                    </Button>

                    <Button
                        sx={{
                            background: '#25425F',
                            border: 'none',
                            borderRadius: '5px',
                            padding:{
                                lg: "0 70px",
                                md: "0 70",
                                xs:'0 50px'
                            },
                            "&:hover":{
                                backgroundColor:'#25425F',
                                color:'white',
                            },
                            color: 'white',
                            height: "3rem",
                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                        }}
                        type='submit'
                        onClick={(event)=>{
                           handleDisplayContent(event);
                         }}
                    >
                    Save
                    </Button>
            </Box>
        </Box>

    </Box>
    </form>
    </Box>
</MainContainerWrapper>)}



export default CreateDraft;   
{/* SOP-61 - PCS */}