// SOP-78 Included By PCS [to renew company's plan]
import { useState, useEffect, useCallback, useRef } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Divider,
    Button,
    Tooltip,

} from '@mui/material';
import { styled, alpha, useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Success ,Failed } from '../../atic-common-helpers/helpers/toast.helper';
import CryptoJS from "crypto-js";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import OtpInput from 'react-otp-input';
import useRazorpay from 'react-razorpay';
import { useNavigate } from "react-router-dom";


    const PricingSignUpPage = ({ handleSignUpClose })=>{

    const GlobalCompanyNameSession = sessionStorage.getItem("GlobalCompanyNameSession");
    // console.log('Name ',GlobalCompanyNameSession)

   //Sales Team Email
    const GlobalSalesteamEmailsession = sessionStorage.getItem("SalesTeamEmail");
    // console.log('SalesteamEmailsession ',GlobalSalesteamEmailsession)
 
 
    //Global data from Session..
            const GlobalDataFromSession = sessionStorage.getItem("GlobalVariable");
            const GlobalData = JSON.parse(GlobalDataFromSession);
        
            // let CompanyId = GlobalData.GlobalCompanyId;
            // let UserId = GlobalData.GlobalUserId;
            // let GlobalEmail = GlobalData.GlobalUserEmail

            const navigate = useNavigate();

            //Decrypting Company details from session..
            const CompanyIdFromSession = sessionStorage.getItem("SessionCompanyId");
            const UserIdFromSession = sessionStorage.getItem("SessionUserId");
            const GlobalEmailFromSession= sessionStorage.getItem("SessionEmail");

            //To decrypt data from session..
              //Secret pass Used to encrypt and decrypt
             const secretPass = "XkhZG4fW2t2W";   
                let decryptedCompanyIdFromSession 
                if (CompanyIdFromSession && typeof CompanyIdFromSession === 'string' && CompanyIdFromSession.trim() !== '') {
                    const bytes = CryptoJS.AES.decrypt(CompanyIdFromSession, secretPass);
                    // console.log("bytes",bytes)
                    decryptedCompanyIdFromSession = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                } 

                let decryptedUSerIdFromSession; 
                if (UserIdFromSession && typeof UserIdFromSession === 'string' && UserIdFromSession.trim() !== '') {
                    const bytes = CryptoJS.AES.decrypt(UserIdFromSession, secretPass);
                    // console.log("bytes",bytes)
                    decryptedUSerIdFromSession = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                } 

                let decryptedEmailFromSession; 
                if (GlobalEmailFromSession && typeof GlobalEmailFromSession === 'string' && GlobalEmailFromSession.trim() !== '') {
                    const bytes = CryptoJS.AES.decrypt(GlobalEmailFromSession, secretPass);
                    // console.log("bytes",bytes)
                    decryptedEmailFromSession = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                } 

                

                let decryptedSalesEmailFromSession; 
                if (GlobalSalesteamEmailsession && typeof GlobalSalesteamEmailsession === 'string' && GlobalSalesteamEmailsession.trim() !== '') {
                    const bytes = CryptoJS.AES.decrypt(GlobalSalesteamEmailsession, secretPass);
                    decryptedSalesEmailFromSession = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                } 



    //Use State ..
            const [selectedPlanName, setSelectedPlanName] =  useState('')
            const [PlanError, setPlanError]=useState(false)
            const [PlanErrorMessage, setPlanErrorMessage]=useState('')

            const[CurrencyValue, setCurrencyValue]=useState('')
            const [CurrencyError, setCurrencyError]=useState(false)
            const [CurrencyErrorMessage, setCurrencyErrorMessage]=useState('')

            const [PaymentScheduleError, setPaymentScheduleError]=useState(false)
            const [PaymentScheduleErrorMessage, setPaymentScheduleErrorMessage]=useState('')
            const[PaymentScheduleValue, setPaymentScheduleValue]=useState('')

            const [RayzorPayopen, setRayzorPayOpen] = React.useState(false);
            const [signUpOTPOpen , setsignUpOTPOpen]= useState(false)
            const [OTPValue, setOTPValue] = useState('');
            const [timer, setTimer] = useState("00:00:00");
            const Ref = useRef(null);

            //Use State to bind current plan details from DB..
            const[CurrentValidityDate, setCurrentValidityDate] = useState('')
            const[RegisteredMobile, setRegisteredMobile] = useState('')
            const[RegistrationDate, setRegistrationDate] = useState('')
            const[CurrentPlanName, setCurrentPlanName] = useState('')
            const[UserName, setUserName] = useState('')
            const[UserMobileNo, setUserMobileNo] = useState('')
            const[UserCompanyName, setUserCompanyName] = useState('')
            const[UserEmail, setUserEmail] = useState('')
            const[EmployeeId, setEmployeeId] = useState('')
            const [ PlanIdFromUSerData , setPlanIdFromUSerData]= useState('')
            const [ AllowedAssetCount , setAllowedAssetCount]= useState('')
            const [ResendDisabel, setResendDisabel] = useState(true)
            const GlobalOTPFromSession = sessionStorage.getItem("GlobalOTP");
            const [adminEmail, setAdminEmail]=useState('')// Initializing sales team email

            
   
    // To Open and Close OTP dialouge box  
                const handleSignUpOTPOpen = () => {
                    setsignUpOTPOpen(true)
                };
            
                const handleSignUpOTPClose = () => {
                    setsignUpOTPOpen(false)
                };
    // EOF To Open and Close dialouge box [ Sign Up]      
               
    // To  Close razor pay box  
                const handleRayzorPayClose = () => {
                setRayzorPayOpen(false);
                };
    // EOF To  Close razor pay box  
    // Drop down values
                const currencyOptions =[
                    { name: 'INR', value:'INR' },
                    { name: 'USD', value:'USD'},
                ]

                const paymentScheduleOptions =[
                    { name: 'Monthly', value:'Monthly'},
                    { name: 'Yearly', value:'Yearly'},
                ]

                const planOptions =[
                    { name: 'Standard', value:'Standard'},
                    { name: 'Enterprise', value:'Enterprise'},
                ]
    //EOF  Drop down values

    //[Closing the renew plan dialogue box]
                const handleClose = (event) => {
                    if (handleSignUpClose && typeof handleSignUpClose === 'function') {
                        handleSignUpClose(event);
                    }
                };



    // [to get Intopz sales email]

      const getIntopzSalesEmail = () => 
            {
                commonService
                    .postService(`${process.env.REACT_APP_BACKEND_URL}/intopzadmin/get_intopz_admin_email`)
                    .then((res) => {
                
                    
                        if (res.status === 200) {

                            setAdminEmail(res.data.data[0].admin_email)
                              let salesEmail =res.data.data[0].admin_email
                            const datasalesEmail = CryptoJS.AES.encrypt(JSON.stringify(salesEmail),secretPass).toString();
                            sessionStorage.setItem("SalesTeamEmail",datasalesEmail) 


                                                }
                                    })
                    .catch((error) => {
                            console.error("Error:", error);
                                    });
    }

    //To get admin email on page load





    //Formatting Date..
                function formatDateToDDMMYYYY(date) {
                // Extract day, month, and year
                const day = date.getDate();
                const month = date.getMonth() + 1; // Months are 0-based, so add 1
                const year = date.getFullYear();
                
                // Pad day and month with leading zeros if necessary
                const dayStr = day.toString().padStart(2, '0');
                const monthStr = month.toString().padStart(2, '0');
                
                // Format 
                 return `${year}-${monthStr}-${dayStr}`;
                                                  }

    // Getting asset count from DB for the selected PlanId..
           const planDetailsFunction =()=>
            {
                // console.log('setAllowedAssetCount called')
                const PlanDetailsInformation = {
                      Planid:PlanIdFromUSerData ,
                     };

                    // console.log('Plan Id value',PlanIdFromUSerData)
                    commonService
                    .postService(`${process.env.REACT_APP_BACKEND_URL}/plan/list_plan_by_id`, PlanDetailsInformation)
                    .then((res) => {
                        if (res.status === 200) {
                            // console.log('plan resposne',res.data.data[0].allowednumberofassets)
                            const AllowedAsset =res.data.data[0].allowednumberofassets
                            setAllowedAssetCount(AllowedAsset)
                        }
                    }).catch((error) => {
                        console.error("Error:", error);
                    });
            }
   // EOF Getting asset count 
   // To get existing company user details - API as per the email 
            const ToGetCompanyUserDetails = async (UserEmail)=>
            {
                     const  CompanyUserDetailsPayload = {
                        UserName:UserEmail,
                        Active:"Y"
                        }
                    // console.log("CompanyUserDetailsPayload",CompanyUserDetailsPayload)
                    try {
                        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/company/getCompanyUserDetails`,CompanyUserDetailsPayload);
                        const UserData = res.data.data[0]
                        // console.log('UserData from DB',UserData)
                        // console.log('Existing Valid Date',formatDateToDDMMYYYY(new Date(UserData.validtilldate)))

                        let currentValidityDatefromDB =new Date(UserData.validtilldate)//to solve UTC issue 
                         let FormattedCurrentValidityDate =formatDateToDDMMYYYY(currentValidityDatefromDB)//to format date

                        setAllowedAssetCount(UserData.numberofassets)
                        setPlanIdFromUSerData(UserData.planid)
                        setCurrentValidityDate(FormattedCurrentValidityDate)
                        setCurrentPlanName(UserData.planname)
                        setSelectedPlanName(UserData.planname=='Free'?'':UserData.planname);
                        setCurrencyValue(UserData.currency);
                        setPaymentScheduleValue(UserData.paymentschedule);
                        setUserName(UserData.username)
                        setUserMobileNo(UserData.employee_mobile)
                        setUserEmail(UserData.usernameemail)
                        setUserCompanyName(UserData.companyname)
                        setRegisteredMobile(UserData.registeredmobile)
                        setRegistrationDate(UserData.registrationdate)
                        sessionStorage.setItem('GlobalCompanyNameSession',UserData.companyname)
                        sessionStorage.setItem('GlobalUserNameSession',UserData.username)
                       
                        setEmployeeId(UserData.employee_id)
                         return res.data.data[0] || [];
                    } 
                    catch (err) 
                    {
                        console.log( "co",err); 
                    } 

            }
        
    //To send Plan upgradation email to sales team and customer
    const sendPlanRenewalEmail =(CompanyPayload)=>
                    {

                        const PlanUpdationInformation = {
                                CompanyName:GlobalCompanyNameSession,
                                PlanId:CompanyPayload.PlanId,
                                ValidTillDate: CompanyPayload.ValidTillDate,
                                RegisterdEmail:decryptedEmailFromSession,
                                salesTeamEmail:decryptedSalesEmailFromSession
                                    };

                             
                            //to send email to company
                                commonService
                                    .postService(`${process.env.REACT_APP_BACKEND_URL}/company/toSendPlanRenewalEmailtoCompany`, PlanUpdationInformation)
                                    .then((res) => {
                                        if (res.status === 200) {
                                            Success("Plan renewal email sent Successfully")
                                                                }
                                                    })
                                    .catch((error) => {
                                            console.error("Error while sending Plan renewal email:", error);
                                                    });

                            //to send email to sales team
                                commonService
                                    .postService(`${process.env.REACT_APP_BACKEND_URL}/company/toSendPlanRenewalEmailtoSalesteam`, PlanUpdationInformation)
                                    .then((res) => {
                                        if (res.status === 200) {
                                        
                                                                }
                                                    })
                                    .catch((error) => {
                                            console.error("Error while sending Plan renewal email:", error);
                                                    });
                        }
    //EOF to send Plan upgradation to sales team and company]

       
    //To decrypt the OTP ...
                // const secretPass = "XkhZG4fW2t2W"; 
                let decryptedDataOTP;
                if (GlobalOTPFromSession && typeof GlobalOTPFromSession === 'string' && GlobalOTPFromSession.trim() !== '')
                {
                    const bytes = CryptoJS.AES.decrypt(GlobalOTPFromSession, secretPass);
                    decryptedDataOTP = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                }  
      
                // console.log(decryptedDataOTP)
    //To update the company with new plan deatils aftre submittign OTP
    //SOP-79 Modified By PCS [to validate OTP on enter button]
                // const UpdateCompanydetails= async()=>
                const UpdateCompanydetails= async(event)=>
    //EOF SOP-79 Modified By PCS [to validate OTP on enter button]          
                   {
                    event.preventDefault();//SOP-79 Included  By PCS
                    let APIValidation = true;
                    // console.log('UpdateCompanydetails 1')

                      // To get Registered date
                        const ToGetDate = new Date(); 
                        const ToGetYear = ToGetDate.getFullYear();
                        const ToGetMonth = (ToGetDate.getMonth() + 1).toString().padStart(2, '0');
                        const ToGetDay = ToGetDate.getDate().toString().padStart(2, '0');
                        const FormatedRegisteredDate = `${ToGetYear}-${ToGetMonth}-${ToGetDay}`;
                        const [year, month, day] = FormatedRegisteredDate.split("-").map(Number);

                        //Today's data
                         const registeredDate = new Date(year, month - 1, day);  
                      
                    let ValidTillDate;

                        // console.log('CurrentValidityDate ',CurrentValidityDate)
                        // console.log('FormatedRegisteredDate',FormatedRegisteredDate)
                       
  
    //Condition 1 :If validity expired..

                if (CurrentValidityDate < FormatedRegisteredDate)
                    {
                       
                    //Monthly
                        if(PaymentScheduleValue === 'Monthly'){

                            // console.log('Validity expired monthly')
                            const ToGetDate = new Date(FormatedRegisteredDate); 
                           
                            ToGetDate.setDate(ToGetDate.getDate() + 30); // Add 30 days
                            // Format the new date
                            const this_year = ToGetDate.getFullYear();
                            const this_month = (ToGetDate.getMonth() + 1).toString().padStart(2, '0');
                            const this_day = ToGetDate.getDate().toString().padStart(2, '0');
                            const newDateString = `${this_year}-${this_month}-${this_day}`;
                            // console.log('After adding 30 ',newDateString)
                            ValidTillDate = newDateString
                            // console.log('valid  expired monthly',ValidTillDate)
                                                            }
                    //Yearly
                        else if(PaymentScheduleValue === 'Yearly'){
                            // console.log('Validity expired yearly')
                            // Check if the current year is a leap year
                            const isLeapYear = (year) => (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
                            const ToGetDate = new Date(FormatedRegisteredDate); 
                            // Get the current year
                            const currentYear = ToGetDate.getFullYear();

                            // Calculate the number of days to add (365 or 366)
                            const daysToAdd = isLeapYear(currentYear) ? 366 : 365;

                            // Add days to the registeredDate
                            ToGetDate.setDate(ToGetDate.getDate() + daysToAdd); // Add 30 days

                            // Get the year, month, and day after adding days
                            const YearlyValidTillYear = ToGetDate.getFullYear();
                            const YearlyValidTillMonth = (ToGetDate.getMonth() + 1).toString().padStart(2, '0');
                            const YearlyValidTillDay = ToGetDate.getDate().toString().padStart(2, '0');
                            ValidTillDate = `${YearlyValidTillYear}-${YearlyValidTillMonth}-${YearlyValidTillDay}`;
                            // console.log('valid after expiry yearly',ValidTillDate)

                                                 }
                    }
             //EOF Condition 1 :If validity expired..

             // Condition 2 :If validity not expired..
                else {
                           
                            // console.log('Validity not expired monthly')
                            //Monthly
                            if(PaymentScheduleValue === 'Monthly'){

                                // console.log('Validity not expired monthly')

                                const date = new Date(CurrentValidityDate);
                                // console.log('current Validity..',date)
                                date.setDate(date.getDate() + 30); // Add 30 days

                                // Format the new date
                                const this_year = date.getFullYear();
                                const this_month = (date.getMonth() + 1).toString().padStart(2, '0');
                                const this_day = date.getDate().toString().padStart(2, '0');
                                const newDateString = `${this_year}-${this_month}-${this_day}`;
                                // console.log('After adding 30 ',newDateString)
                                    ValidTillDate =newDateString
                                // console.log('valid not expired monthly',ValidTillDate)

                                                            }
                            //Yearly
                            else if(PaymentScheduleValue === 'Yearly'){

                            // console.log('Validity not expired yearly')
                            const date = new Date(CurrentValidityDate);//Current Validity date

                            
                            // Check if the current year is a leap year
                            const isLeapYear = (year) => (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
                            // Get the  year
                                const ValidityYear = date.getFullYear();
                            // Calculate the number of days to add (365 or 366)
                                const daysToAdd = isLeapYear(ValidityYear) ? 366 : 365;
                            // Add days to the registeredDate
                                date.setDate(date.getDate() + daysToAdd);
    
                            // Get the year, month, and day after adding days
                            const YearlyValidTillYear = date.getFullYear();
                            const YearlyValidTillMonth = (date.getMonth() + 1).toString().padStart(2, '0');
                            const YearlyValidTillDay = date.getDate().toString().padStart(2, '0');
                            ValidTillDate = `${YearlyValidTillYear}-${YearlyValidTillMonth}-${YearlyValidTillDay}`;
                            // console.log("Validity not expired yearly ",ValidTillDate)

                                                              }
                   
                }
                // console.log('ValidTillDate outside',ValidTillDate)

                    if(decryptedDataOTP !== OTPValue){
                                Failed("Invalid OTP");
                                APIValidation= false  
                            }


                    const CompanyPayload = {
                        SaveUpdateKey: 'update',
                        CompanyId:  decryptedCompanyIdFromSession,
                        PlanId:selectedPlanName=='Standard'?2:3,
                        Currency: CurrencyValue,
                        PaymentSchedule: PaymentScheduleValue,
                        NumberOfAssets:AllowedAssetCount,
                        RegistredDate: RegistrationDate,
                        ValidTillDate: ValidTillDate,
                                };

                //    console.log('CompanyPayload',CompanyPayload)
                        if(APIValidation){
                                handleSignUpOTPClose();
                                setOTPValue('')
                                 handlePayment(CompanyPayload);
                                 }
               }
        

        //Razor Pay starts..
        const razorpayValidationRef = useRef(false);
        const [Razorpay] = useRazorpay()
        const handlePayment = useCallback((CompanyPayload) => {
             
            //const order = async createOrder(params),
            const sREACT_APP_RAZORPAY_KEY = `${process.env.REACT_APP_RAZORPAY_KEY}`;
            const options = {
              key: sREACT_APP_RAZORPAY_KEY,
              amount: "100",
              currency: CompanyPayload.Currency,
              name: UserCompanyName,
              description: "Smartopz - IT Managed Services",
              //image: "https://example.com/your_logo",
               // order_id: order.id,
              handler: function (response) {
                //   console.log("response.razorpay_payment_id",response.razorpay_payment_id);//Success..... 
                  if( response.razorpay_payment_id !== null || response.razorpay_payment_id !== '' || response.razorpay_payment_id !== undefined){
                   
                 
                   AfterPaymentUpdateCompanyPlanAPI(CompanyPayload);//Only after Razor pay success this function should  be called..
                   
                  } 
              },
              prefill: {
                name: UserCompanyName,
                email: decryptedEmailFromSession,
                contact:RegisteredMobile,
              },
              notes: {
                address: GlobalCompanyNameSession,
              },
              theme: {
                color: "#3399cc",
              },
            };
            const rzpay = new Razorpay(options);
            // console.log("rzpay",rzpay)
            rzpay.on("payment.failed", function (response) {
            //   console.log("response.error.description",response.error.description);
              razorpayValidationRef.current = false
              setRayzorPayOpen(true)
            });         
            rzpay.open();
          }, [Razorpay]);
        
      
       //EOF Razor Pay ..

       // to Update Company Plan after payment ..
            const AfterPaymentUpdateCompanyPlanAPI = async (CompanyPayload)=>
            {

             // console.log('payload values are..',CompanyPayload)
                            commonService
                                .postService(`${process.env.REACT_APP_BACKEND_URL}/company/renew_company_plan`, CompanyPayload)
                                .then((res) => {
                                    if (res.status === 200) {
                                        
                                        handleClose();
                                        handleSignUpOTPClose();
                                        Success("Company Plan Updated Successfully");
                                        sendPlanRenewalEmail(CompanyPayload);//to send plan updation email
                                        Reset()
                                        setOTPValue('')
                                        sessionStorage.removeItem('GlobalOTP');
                                        // navigate('/profile')
                                         window.location.reload();
                                        
                                    }
                                }).catch((error) => {
                                    console.error("Error:", error);
                                });
            
            }
    //EOF to Update Company Plan after payment .    

    // To resend OTP for the respective mail

        const ResendOTP =()=>
        {

            let digits = '0123456789'; 
            let FormatedOTP = ''; 
            let len = digits.length 
            for (let i = 0; i < 4; i++) { 
                FormatedOTP += digits[Math.floor(Math.random() * len)]; 
                                        } 
        
            
            let OTP = FormatedOTP
            const dataOTP = CryptoJS.AES.encrypt(JSON.stringify(FormatedOTP),secretPass).toString();
            sessionStorage.setItem("GlobalOTP",dataOTP) 
        
            const OTPPayload ={
                     RegisterdEmail:decryptedEmailFromSession,
                     OTP:OTP 
                }

            //  console.log("OTPPayload",OTPPayload.OTP)
            commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/company/OTPMail`, OTPPayload)
                .then((res) => {
                    if (res.status === 200) {
                    }
                }).catch((error) => {
                    console.error("Error:", error);
                });
        }

       //Clicking renew plan form ..updatePlan starts..
                const updatePlan = async (event) => 
                    {
                            // console.log(' plan renew form called')
                            event.preventDefault();
                            planDetailsFunction();
                            let errors = {};

                        if (selectedPlanName === '' || selectedPlanName === null || selectedPlanName === undefined) {
                             errors.plan = true;
                            setPlanErrorMessage("Plan  is required")
                        }

                        if (CurrencyValue === '' || CurrencyValue === null || CurrencyValue === undefined) {
                            errors.currency = true;
                            setCurrencyErrorMessage("Currency is required")
                        }

                        if ( PaymentScheduleValue === '' || PaymentScheduleValue === null || PaymentScheduleValue === undefined) {
                            errors.paymentSchedule = true;
                            setPaymentScheduleErrorMessage("Payment Schedule is required")
                        }
                        if (Object.keys(errors).length > 0) {
                            // If there are errors, update the state with all the errors
                            
                            setPlanError(errors.plan || false);
                            setCurrencyError(errors.currency || false);
                            setPaymentScheduleError(errors.paymentSchedule || false);
                        return;
                        }

                        handleSignUpOTPOpen();


                        //OTP Generation..
                        // Declare a digits variable  which stores all digits  
                            let digits = '0123456789'; 
                            let FormatedOTP = ''; 
                            let len = digits.length 
                            for (let i = 0; i < 4; i++) { 
                                FormatedOTP += digits[Math.floor(Math.random() * len)]; 
                            } 
                
                        let OTP = FormatedOTP
                        // setFormattedOTPValue(FormatedOTP)
                        const dataOTP = CryptoJS.AES.encrypt(JSON.stringify(FormatedOTP),secretPass).toString();
                        // console.log("Encrypted OTP data:", dataOTP);
                        sessionStorage.setItem("GlobalOTP",dataOTP) 
                

                        const OTPPayload = {
                            RegisterdEmail:decryptedEmailFromSession,
                            OTP:OTP
                                            }

                        // console.log(OTPPayload.OTP)
                        commonService
                            .postService(`${process.env.REACT_APP_BACKEND_URL}/company/OTPMail`, OTPPayload)
                            .then((res) => {
                                if (res.status === 200) {
                                }
                            }).catch((error) => {
                                console.error("Error:", error);
                                            });
                    };
        //EOF Clicking renew plan form ..updatePlan starts..

        // to set timer
                const getTimeRemaining = (endTime) => {
                    const total = endTime.getTime() - new Date().getTime();
                    const seconds = Math.floor((total / 1000) % 60);
                    const minutes = Math.floor((total / 1000 / 60) % 60);
                    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
                    return { total, hours, minutes, seconds };
                };
    
    
                const startTimer = (e) => {
                    let { total, hours, minutes, seconds } =
                        getTimeRemaining(e);
                    if (total >= 0) {
                        // update the timer
                        // check if less than 10 then we need to
                        // add '0' at the beginning of the variable
                        setTimer(
                            (hours > 9 ? hours : "0" + hours) +
                            ":" +
                            (minutes > 9
                                ? minutes
                                : "0" + minutes) +
                            ":" +
                            (seconds > 9 ? seconds : "0" + seconds)
                        );
                    }
                    else {
                        // Time is up
                        setTimer("00:00:00"); // Set timer to 00:00:00 or any message you want to display
                        clearInterval(Ref.current); // Stop the timer
                        sessionStorage.removeItem("GlobalOTP")// [to remove otp from session after 5 mins]
                        setResendDisabel(false)
                    }
                };
    
                const FormatedTimer = (e) => {
                    // If you adjust it you should also need to
                    // adjust the Endtime formula we are about
                    // to code next
                    setTimer(`00:0${process.env.REACT_APP_SAMRTOPZ_OTP_TIMER}:00`);
                
                    // If you try to remove this line the
                    // updating of timer Variable will be
                    // after 1000ms or 1sec
                    if (Ref.current) clearInterval(Ref.current);
                    const id = setInterval(() => {
                        startTimer(e);
                    }, 1000);
                    Ref.current = id;
                };
    
                const getDeadTime = () => {
                    let deadline = new Date();
                
                    // Get the OTP timer from environment variables
                    const otpTimerString = process.env.REACT_APP_SAMRTOPZ_OTP_TIMER;
                
                    // Use a default value if otpTimerString is undefined
                    const otpTimer = parseInt(otpTimerString || "5", 10);
                
                    // Set the deadline 
                    deadline.setMinutes(deadline.getMinutes() + otpTimer);
                    return deadline;
                };
 

                const Reset = () => {
                    // Stop the timer
                    clearInterval(Ref.current);
                    // Reset the timer display
                    setTimer('00:00:00');
                                };

               

                    useEffect(() => {
                       FormatedTimer(getDeadTime());
                       ToGetCompanyUserDetails(decryptedEmailFromSession);
                       getIntopzSalesEmail();
                         
                    }, []);
                

    return(
        <Box>
            <Box p={3}>
                <form noValidate>
                    <Grid  container>
                            {/* //Company Name */}
                            <Grid item lg={12} md={12} xs={12}>
                                <Typography sx={{
                                                fontSize:{
                                                    lg:'1.3rem',
                                                    md:'1.3rem',
                                                    xs:'1.2rem'
                                                }
                                                }}>
                                    Company Name : {UserCompanyName}
                                   
                                </Typography>
                            </Grid>
                    </Grid>
                    <Grid mt={3} container display={'flex'} justifyContent={'space-between'}>
                            {/* //Person Name */}
                            <Grid item lg={12} md={12} xs={12}>
                            <Typography sx={{
                                                fontSize:{
                                                    lg:'1.3rem',
                                                    md:'1.3rem',
                                                    xs:'1.2rem'
                                                }
                                                }}>
                                    User Name  : {UserName}
                                    
                                </Typography> 
                            </Grid>
                    </Grid>
                    <Grid mt={3} container display={'flex'} justifyContent={'space-between'}>
                            {/* //Person Name */}
                            <Grid item lg={12} md={12} xs={12} display={'flex'}>
                            {/* <Typography variant='h3'sx={{color:'gray',marginTop:'1px'}}>Current Plan  :</Typography> */}
                            <Typography sx={{
                                                fontSize:{
                                                    lg:'1.3rem',
                                                    md:'1.3rem',
                                                    xs:'1.2rem'
                                                },marginLeft:'5px'
                                                }}>
                                  Current Plan : {CurrentPlanName}
                                    
                                </Typography> 
                            </Grid>
                    </Grid>
                    
                    <Grid mt={3} container display={'flex'} justifyContent={'space-between'}>
                   
                        <Grid  item lg={5} md={5} xs={5}> 
                            {/* //Plan  Name */}
                           
                                    <Autocomplete
                                    id="combo-box-demo"
                                    options={planOptions}
                                    // filterOptions={filterOptions}
                                    getOptionLabel={(option) => option.name}
                                    value={planOptions.find((option) => option.value === selectedPlanName) || null}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                             setSelectedPlanName(newValue.value);
                                            
                                             setPlanIdFromUSerData(newValue.value==='Standard'?2:3)
                                             setPlanError(false);
                                             setPlanErrorMessage("");
                                        } else {
                                            setSelectedPlanName(null);
                                            setPlanError(true);
                                            setPlanErrorMessage("Plan  is required");
                                        }
                                    }}
                                    sx={{ width: 235, ml: 1 }}
                                    disabled={CurrentPlanName === 'Enterprise'}
                                    renderInput={(params) => 
                                    <TextField {...params}
                                    error={PlanError}
                                    helperText={PlanErrorMessage}
                                        required label="Plan" 
                                        variant="standard" />}
                                    />
                            </Grid>
                         
                   
                            {/* //Currency  Type */}
                            <Grid item md={5} lg={5} xs={5}>
                                   
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={currencyOptions}
                                            sx={{width:235}}
                                            value={currencyOptions.find(option => option.value === CurrencyValue) || null}
                                            getOptionLabel={(option) => option.name}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setCurrencyValue(newValue.value);
                                                    setCurrencyError(false);
                                                    setCurrencyErrorMessage("");
                                                } else {
                                                    setCurrencyValue('');
                                                    setCurrencyError(true);
                                                    setCurrencyErrorMessage("Currency is required");
                                                }
                                            }}
                                         
                                            renderInput={(params) => <TextField {...params}
                                            required label="Currency" 
                                            error={CurrencyError}
                                            helperText={CurrencyErrorMessage}
                                            variant="standard" />}
                                        />
                                   
                            </Grid>
                    </Grid>
                    <Grid mt={3} container display={'flex'} justifyContent={'space-between'}>
                            {/* //Payment Schedule */}
                            <Grid item lg={12} md={12} xs={12}>
                                    
                                         <Autocomplete
                                            id="combo-box-demo"
                                            options={paymentScheduleOptions}
                                            // value={'Free'}
                                            getOptionLabel={(option) => option.name}
                                            value={paymentScheduleOptions.find(option => option.value === PaymentScheduleValue) || null}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setPaymentScheduleValue(newValue.value);
                                                    setPaymentScheduleError(false);
                                                    setPaymentScheduleErrorMessage("");
                                                } else {
                                                    setPaymentScheduleValue('');
                                                    setPaymentScheduleError(true);
                                                    setPaymentScheduleErrorMessage("Payment Schedule is required");
                                                }
                                            }}
                                            sx={{width:235, ml:1}}
                                            renderInput={(params) => <TextField {...params} 
                                            required label="Payment Schedule" 
                                            error={PaymentScheduleError}
                                            helperText={PaymentScheduleErrorMessage}
                                            variant="standard" />}
                                          />
                                    
                            </Grid>
                    </Grid>
                    <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Button
                            style={{
                                background: 'white',
                                border: 'none',
                                borderRadius: '5px',
                                padding: "0 70px",
                                height: "3rem",
                                boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                            }}
                            onClick={handleClose} 
                        >
                            Back
                        </Button>
                        {CurrentPlanName=== 'Standard'&& (
                        <Button
                            style={{
                                background: '#4169e1',
                                border: 'none',
                                borderRadius: '5px',
                                padding: "0 70px",
                                color: 'white',
                                height: "3rem",
                                boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                            }}
                            type='submit'
                            onClick={(event)=>{
                                updatePlan(event);
                                FormatedTimer(getDeadTime());
                               
                            }}
                        >
                            {/* //Submit Button text changes based on plan selection */}
                            {CurrentPlanName === 'Standard' && selectedPlanName === 'Enterprise'? 'Upgrade Plan':
                            CurrentPlanName === 'Standard' && selectedPlanName === 'Standard'? 'Renew Plan'
                            : 'Renew Plan'} 
                           {/* Upgrade Plan */}
                        </Button>)}

                        {CurrentPlanName=== 'Free'&& (
                        <Button
                            style={{
                                background: '#4169e1',
                                border: 'none',
                                borderRadius: '5px',
                                padding: "0 70px",
                                color: 'white',
                                height: "3rem",
                                boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                            }}
                            type='submit'
                            onClick={(event)=>{
                                updatePlan(event);
                                FormatedTimer(getDeadTime());
                                
                            }}
                        >
                            Buy Plan
                        </Button>)}

                        {CurrentPlanName=== 'Enterprise'&& (
                        <Button
                            style={{
                                background: '#4169e1',
                                border: 'none',
                                borderRadius: '5px',
                                padding: "0 70px",
                                color: 'white',
                                height: "3rem",
                                boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                            }}
                            type='submit'
                            onClick={(event)=>{
                                updatePlan(event);
                                FormatedTimer(getDeadTime());
                                
                            }}
                        >
                            Renew Plan
                        </Button>)}

                      
                    </Box>

                </form>
                    {/* Razor Payment */}
                        <Dialog open={RayzorPayopen}>
                            <Box width={300} height={'auto'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <Box>
                                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                        <Box mt={2}>
                                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                <Typography mt={1} fontSize={'1.5rem'}>
                                                Unable to register!
                                                </Typography>                                                 
                                            </Box>
                                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                <Typography fontSize={'1.5rem'}>
                                                Retry again
                                                </Typography>                                                 
                                            </Box>
                                        </Box>
                                    </Box>

                                <Box mt={3} mb={3} display={'flex'} justifyContent={'center'} >
                                    <Button onClick={handleRayzorPayClose}
                                    style={{
                                        width:100,
                                        background: '#4169e1',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding: "0 70px",
                                        color: 'white',
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}
                                    >
                                        <Typography fontSize={'1.3rem'}>
                                            Ok
                                        </Typography>
                                    </Button>
                                </Box>


                                </Box>
                            </Box>
                        </Dialog>
                   
                    {/*  to get signup page in dialogue box */}
                    <Box>
                        <Dialog  open={signUpOTPOpen} fullWidth>
                            {/* //SOP-79 Modified By PCS [to validate OTP on enter button- enclosed OTP in form tag] */}
                              <form>
                                    <Box bgcolor={'white'} 
                                            sx={{
                                                width:{
                                                    lg:600,
                                                    md:600,
                                                    xs:388
                                                }
                                            }}
                                            display={ 'flex'} justifyContent={'center'}>
                                                <Box mb={5}>
                                                    <Box sx={{
                                                width:{
                                                    lg:600,
                                                    md:600,
                                                    xs:388
                                                }
                                            }} bgcolor={'#4169e1'} display={ 'flex'} justifyContent={'center'}>
                                                        <Typography  variant='h4' color={'white'} mt={2} mb={1} >
                                                            OTP Verification
                                                        </Typography>
                                                    </Box>
                                                    <Box mt={5}>
                                                        <Typography fontSize={'1.3rem'} display={ 'flex'} justifyContent={'center'}>
                                                            OTP has been sent to 
                                                        </Typography>
                                                        <Typography fontSize={'1.3rem'} display={ 'flex'} justifyContent={'center'}>
                                                            {UserEmail} ( Valid for 5 minutes )
                                                        </Typography>
                                                    </Box>
                                                    <Box mt={5} display={'flex'} justifyContent={'center'}>
                                                        <OtpInput
                                                        value={OTPValue}
                                                        onChange={setOTPValue}
                                                        numInputs={4}
                                                        renderSeparator={<span style={{marginLeft:25}}>   </span>}
                                                        inputStyle={{ 
                                                            width: '40px', 
                                                            height: '60px', 
                                                            fontSize: '20px',
                                                            borderRadius:10,
                                                            border:'2px solid #4169e1'
                                                        }}
                                                        renderInput={(props) => <input {...props} />}
                                                        />
                                                    </Box>

                                                    <Box mt={5} display={ 'flex'} justifyContent={'center'}>
                                                        <Typography ml={1} fontSize={'1.3rem'} display={ 'flex'} justifyContent={'center'}>
                                                            Don't receive the OTP?
                                                        </Typography>
                                                        <Button 
                                                        // mb={1} fontSize={'1.3rem'} display={ 'flex'} justifyContent={'center'}
                                                        onClick={()=>{
                                                            // generateOTP()
                                                            setResendDisabel(true)
                                                            ResendOTP();
                                                            FormatedTimer(getDeadTime());
                                                        }} 
                                                        disabled={ResendDisabel}
                                                        sx={{
                                                            
                                                            cursor:'pointer',
                                                            color:'#4169e1',
                                                            fontSize:'1.3rem',
                                                            ":hover":{
                                                                bgcolor:'white'
                                                            }
                                                        }}
                                                        >
                                                            RESEND
                                                        </Button>
                                                    </Box>

                                                    <Box mt={5} display={ 'flex'} justifyContent={'center'}>
                                                        <Typography fontSize={'1.3rem'} display={ 'flex'} justifyContent={'center'}>
                                                            {timer}
                                                        </Typography>
                                                    </Box>    
                                                    
                                                    <Box mt={5} display={ 'flex'} justifyContent={'center'} >
                                                    <Button
                                                        style={{
                                                            background: '#4169e1',
                                                            border: 'none',
                                                            borderRadius: '5px',
                                                            padding: "0 70px",
                                                            color: 'white',
                                                            height: "3rem",
                                                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                                        }}
                                                        //SOP-79 Modified By PCS [to validate OTP on enter button] 
                                                          //type='submit'
                                                           type='submit'
                                                        //EOF SOP-79 Modified By PCS [to validate OTP on enter button] 
                                                        onClick={(event)=>{
                                                        //SOP-79 modified By PCS
                                                        // UpdateCompanydetails();
                                                         UpdateCompanydetails(event);


                                                        }}
                                                    >
                                                        Submit 
                                                    </Button>
                                                    </Box>

                                                </Box>

                                    </Box>
                                    </form>
                    {/* //SOP-79 Modified By PCS [to validate OTP on enter button- enclosed OTP in form tag] */}
                        </Dialog>
                    </Box>
       
            </Box>
        </Box>
        
    )}


export default PricingSignUpPage;

//EOF SOP-78 Included By PCS



