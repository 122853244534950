import admin from "./admin";
import enduser from "./enduser";
import itadmin from "./itadmin";
import manager from "./manager";
import usermenu from "./usermenu";

const menuItems = {
  admin: [admin],
  // Included by sanjana SOP-30 to add other user menuitems
  enduser:[enduser],
  manager:[manager],
  itadmin:[itadmin],
  // End of SOP-30
  // Included by sanjana SOP-52 to add user menuitems
  usermenu:[usermenu]
  // End of SOP-52
};

export default menuItems;
