//SOP-38 - TS
//Asset Management -Dashboard - Ticketing
//By Assignee page

import { PieChart} from '@mui/x-charts/PieChart';
import { useState, useEffect } from "react";
import { commonService } from "../../atic-common-helpers/helpers/common.service";

// interface for the ByAssignee count data
interface PieValueType {
id: number;
value: number;
label: string;
}

const  ByAssignee=()=> {
   const [CompanyId, setCompanyId] =useState('')
  const [CreatedBy, setCreatedBy] =useState('')
  const [ByAssignee, setByAssignee] = useState<PieValueType[]>([]);
  // console.log("ByAssignee",ByAssignee)
  
  useEffect(() => 
  {
      const ByAssignee = async () => {
        try {
        //Global data
        const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
        const GlobalData = JSON.parse(GlobalDataFromSession);
        setCompanyId(GlobalData.GlobalCompanyId);
        setCreatedBy(GlobalData.GlobalUserId);
  
        // ByAssigneePayload
        const ByAssigneePayload = {
            companyid: GlobalData.GlobalCompanyId,
        };
  
        //API   
        const response = await commonService
          .postService(`${process.env.REACT_APP_BACKEND_URL}/dashboardController/getTicketByAssigneeCount`, ByAssigneePayload);
          // console.log("getTicketByAssigneeCount",response)
        // checks array 
        if (Array.isArray(response.data.data)) {
            const data: PieValueType[] = response.data.data.map((item: any, index: number) => ({
              id: index,
              value: item._ticket_allocateby, 
              //SOP-77 feedback points  Modified By PCS [to include count]
              // label: item._ticket_allocatebyname 
              label: `${item._ticket_allocatebyname} (${item._ticket_allocateby})` // Adding count in brackets
               //EOF SOP-77 feedback points  Modified By PCS [to include count]
            }));
            // console.log("Data:", data); 

          setByAssignee(data);
        } else {
          console.error("ByAssigneePayload", response.data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    ByAssignee();
  }, []);

 return (
  <PieChart
  series={[
    {
      data: ByAssignee
    }
  ]}
     width={400}
     height={200}

     sx={{
       "& text": {
         fontSize: '10px',          
      }
    }}
   />

  )}
export default ByAssignee





