import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

const Footer = () => {

    const [currentDateTime, setCurrentDateTime] = useState(new Date());

    const formattedDate = currentDateTime.toLocaleString("en-US", {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    useEffect(() => {
        var timer = setInterval(() => {
          setCurrentDateTime(new Date());
        }, 1000);
        //it destroy the timer once we go out from this page
        return function cleanUp() {
          clearInterval(timer);
        };
      });
  return (
    <Box sx={{padding:"20px",display:"flex",direction:"row",justifyContent:"space-between"}}>
    <Box>

    {/* //SOP-75  feedback points L1 modified  by PCS */}
    {/* <a href="http://www.avanttec.net/" target="_blank"  rel="noopener noreferrer"> Developed By Avanttec</a> */}
        <a href="https://SMARTOPZ.COM/" target="_blank"  rel="noopener noreferrer"> Powered By SMARTOPZ</a>
    {/* //EOF SOP-75 feedback points L1 modified  by PCS  */}

    </Box>
    <Box>
    {formattedDate}

    </Box>
  </Box>
  )
}

export default Footer