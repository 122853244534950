//Included by sanjana SOP-30 to add side menus for IT admin 
import GridViewIcon from "@mui/icons-material/GridView";
import PeopleIcon from '@mui/icons-material/People';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BloodtypeIcon from '@mui/icons-material/Bloodtype';
import WcIcon from '@mui/icons-material/Wc';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import WheelchairPickupIcon from '@mui/icons-material/WheelchairPickup';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { BarChart, Business } from "@mui/icons-material";
import HomeIcon from '@mui/icons-material/Home';
import { PiTicketBold } from "react-icons/pi";
import { HiMiniUserCircle } from "react-icons/hi2";
import { BiSolidLayer } from "react-icons/bi";
import { MdDashboard } from "react-icons/md";
import { IoHome } from "react-icons/io5";
import { FaUsers } from "react-icons/fa";
import { ImOffice } from "react-icons/im";
import { PiCheckCircleFill } from "react-icons/pi";
// ==============================|| DASHBOARD MENU ITEMS ||============================== //
const itadmin = {
  id: "dashboard",
  title: "Dashboard",
  type: "group",
  children: [
    { 
      id: "default",
      title: "Home",
      type: "item",
      url: "/home",
      icon: IoHome,
      breadcrumbs: false,
    },
    {
      id: "default",
      title: "Dashboard",
      type: "item",
      url: "/dashboard",
      icon: MdDashboard,
      breadcrumbs: false,
    }
    ,{
      id: "default",
      title: "Allocations",
      type: "item",
      icon: PiCheckCircleFill,
      breadcrumbs: false,
      url: "/allocations",
    }

    // Included by sanjana SOP-34 to add ticketing in side menu for it admin
    ,{
      id: "default",
      title: "Ticketing",
      type: "item",
      icon: PiTicketBold,
      breadcrumbs: false,
      url: "/ticketlist",
    }
    // End of SOP-34

    // Included by sanjana SOP-37 to add Services in side menu for it admin
    ,{
      id: "default",
      title: "Ticketing Services",
      type: "item",
      icon: PiTicketBold,
      breadcrumbs: false,
      url: "/ticketing_services",
    }
    // End of SOP-37

  
  ],

};

export default itadmin;


// end of SOP-30