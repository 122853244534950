// Included by sanjana SOP-16 Add sub categories page

// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Success } from '../../atic-common-helpers/helpers/toast.helper';


//SOP-16 -Included By PCS Testing Feedback Corrections [to avoid multiple submission]
import Swal from "sweetalert2";
// EOF SOP-16 -Included By PCS Testing Feedback Corrections 

// Page start
const AddSubCategoriesPage = 
// To get handleAddSubCategoriesClose & getSubcategorieslistsearch from catgories page
    ({
        handleAddSubCategoriesClose,
        getSubcategorieslistsearch
    }: {
        handleAddSubCategoriesClose: (event: React.MouseEvent) => void,
        getSubcategorieslistsearch: (event: React.MouseEvent) => void
    }) =>{

    // use navigate and use state declaration
    const navigate = useNavigate();

    const [SubCategoriesError, setSubCategoriesError]=useState(false)
    const [SubCategoriesValue, setSubCategoriesValue]=useState('')
    const [SubCategoriesErrorMessage, setSubCategoriesErrorMessage]=useState('')
    const [CategoryOptions, setCategoryOptions] = useState<OptionType[]>([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>('');
    const [CategoryError, setCategoryError]=useState(false)
    const [CategoryErrorMessage, setCategoryErrorMessage]=useState('')

    //SOP-16 Included By PCS[Testing feedback corrections- to avoid duplicate sub category]
    const [SubCategoriesValueEndTrim, setSubCategoriesValueEndTrim]=useState('')
    //EOF SOP-16 Included By PCS[Testing feedback corrections- to avoid duplicate sub category]


    type OptionType = {
        name: string;
        value: string;
    };
    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });

    // Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    //console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId
    let CreatedBy = GlobalData.GlobalUserId
    //console.log("CompanyId",CompanyId)


    const GetCatgoryList = async () => {

        const CategoryPayload ={
            CompanyId:CompanyId
        }

        //console.log("CategoryPayload",CategoryPayload)
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/getCategoryList`,CategoryPayload);
        //console.log("Country" ,res.data.data);
        let formattedCountry = res.data.data.map((item:any) => {
        const { categoryid, categoryname } = item;
        return {
            name: categoryname,
            value: categoryid,
        };
        });
        setCategoryOptions(formattedCountry)
    } catch (err) {
        //console.log( "co",err);
    }
    };

    //  Handle Category while typing category and validations
    const handleSubCategoriesChange = (event:any) => {
        const trimmedSubCategoriesValue = event.target.value.trim();
           setSubCategoriesValue(event.target.value);
        //SOP-16 Included By PCS[Testing feedback corrections - to avoid duplicate sub category]
           setSubCategoriesValueEndTrim(trimmedSubCategoriesValue);
        //EOF SOP-16 Included By PCS[Testing feedback corrections]
        

        
        if (trimmedSubCategoriesValue === '') {
            setSubCategoriesError(true);
            setSubCategoriesErrorMessage("Sub-Categories is required");
        } 
        else if( trimmedSubCategoriesValue.length >225 ){
            setSubCategoriesError(true);
            setSubCategoriesErrorMessage("Max 255 characters");
        }

        else {
            setSubCategoriesError(false);
            setSubCategoriesErrorMessage("");
        }
    };



    // To render handle close function to close dialouge box
    const handleClose = (event: React.MouseEvent) => {
        if (handleAddSubCategoriesClose && typeof handleAddSubCategoriesClose === 'function') {
            handleAddSubCategoriesClose(event);
        }
    };

    // to render getSubcategorieslistsearch function after closing dialouge box
    const handlegetSubcategorieslistsearch= (event: React.MouseEvent) => {
        if (getSubcategorieslistsearch && typeof getSubcategorieslistsearch === 'function') {
            getSubcategorieslistsearch(event);
        }
    };


    // Exist SubCategory name API
    const GetSubCategoryNameExist = async () => {   
        
        const  ExistCategoryNamePayload = {
            //SOP-16 Modified By PCS[Testing feedback corrections-to avoid duplicate sub category]
            // ExistSubCategoryName:SubCategoriesValue,
            ExistSubCategoryName:SubCategoriesValueEndTrim,
            //EOF SOP-16 Included By PCS[Testing feedback corrections]
            SubCategoryId:null,
            CategoryId:selectedCategoryId,
            CompanyId:CompanyId
        }
        console.log("ExistCategoryNamePayload",ExistCategoryNamePayload)
        try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/get_sub_category_name_exists`,ExistCategoryNamePayload);
        //console.log("res.data.data",res.data.data);
        return res.data.data || [];
        } catch (err) {
        console.log( "co",err); 
        }
    };



    // To add categories

     // Define a type for errors
     type Errors = {
        category?:boolean;
        subcategory?:boolean;
    };
    const HandleAddSubCategories = async (event:any)=>{
        event.preventDefault();
    
        let errors: Errors = {};

        if (selectedCategoryId === '' || selectedCategoryId === null || selectedCategoryId === undefined) {
            errors.category = true;
            //SOP-16 Modified By PCS[Testing feedback Correction]
            //setCategoryErrorMessage("Country is required")
              setCategoryErrorMessage("Category is required")
            //EOF SOP-16 Modified By PCS[Testing feedback Correction]

            setCategoryError(true)
        }


        const trimmedSubCategoriesValue = SubCategoriesValue.trim();

        if (trimmedSubCategoriesValue === '') {
            setSubCategoriesError(true)
            //SOP-16 Modified By PCS[Testing feedback Correction]
            // setSubCategoriesErrorMessage("Sub-Categories is required");
               setSubCategoriesErrorMessage("Sub-Category is required");
            //EOF SOP-16 Modified By PCS[Testing feedback Correction]

            errors.subcategory = true;

        } else if (trimmedSubCategoriesValue.length > 225) {
            setSubCategoriesErrorMessage("Max 255 characters");
            errors.subcategory = true;

        }

        const CategoryNameList = await GetSubCategoryNameExist();

       
        if (CategoryNameList.length > 0) {
            setSubCategoriesError(true);
            setSubCategoriesErrorMessage("Sub-Category is already exists");
            errors.subcategory = true;
            return
        }
    
        if (Object.keys(errors).length > 0) {
            // If there are errors, update the state with all the errors
            setSubCategoriesError(errors.subcategory || false);
            setCategoryError(errors.category || false)
            return;
        }
    
        const AddSubCategoriesPayload = {
            SaveUpdateKey:'create',
            SubCategoryId:null,
            SubCategoryName:SubCategoriesValue,
            CategoryId:selectedCategoryId,
            CompanyId:CompanyId,
            Active:null,
            CreatedBy:CreatedBy,
            IsRemove:null
        }
    
        //console.log("AddPL", AddSubCategoriesPayload);

       //SOP-16 Included By PCS[Testing feedack corrections- to avoid multiple toaster msg]

            Swal.fire({
                html: '<span style="font-size: 1.3rem;">Are you sure to save ? </span>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                cancelButtonColor: '#d33',
                confirmButtonColor:"#25425f",
                allowOutsideClick: false,
                })
            .then((result) =>
            {
            if (result.isConfirmed)
            {

    //EOF SOP-16 Included By PCS[Testing feedack corrections - to avoid multiple toaster msg]  

        commonService
      .postService(`${process.env.REACT_APP_BACKEND_URL}/master/subcategories_create_update_delete`, AddSubCategoriesPayload)
      .then((res) => {
        if (res.status === 200) {
            Success("Sub-Category created successfully");
            handleClose(event);
            handlegetSubcategorieslistsearch(event);
        }
        }).catch((error) => {
            console.error("Error:", error);
        });

    //SOP-16 Included By PCS[Testing feedack corrections- to avoid multiple toaster msg]

                } 
                else  
                {
                return
                }
            });

    //EOF SOP-16 Included By PCS[Testing feedack corrections-to avoid multiple toaster msg]  


    }
    

    // UseEffect Declaration
    useEffect(() => {
        GetCatgoryList();
      }, []);



    return(
        <>
            <Box>
                <Box display={'flex'} justifyContent={'center'}>
                    <Box bgcolor={'white'} borderRadius={3}
                    sx={{
                        width:{
                            lg:900,
                            md:600,
                            xs:700
        
                        }}}
                    >
                    <Box p={3}>
                        <Typography variant='h1'
                        sx={{
                            fontSize:{
                                lg:'2rem',
                                md:'2rem',
                                xs:'1.5rem'
                            }
                        }}
                        >
                        Add Sub-Category
                        </Typography>
                    </Box>
                        <Box p={3}>
                            <form 
                         
                            noValidate>
                                <Box>
                                    <Grid container spacing={2} display={'flex'} justifyContent={'space-evenly'}>
                                        <Grid item lg={6} md={6} xs={12} >
                                        <Autocomplete
                                                
                                                id="combo-box-demo"
                                                options={CategoryOptions as OptionType[]}
                                                filterOptions={filterOptions}
                                                getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                value={CategoryOptions.find((option: OptionType) => option.value === selectedCategoryId) || null}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        setSelectedCategoryId(newValue.value);
                                                        setCategoryError(false);
                                                        setCategoryErrorMessage("");
                                                    } else {
                                                        setSelectedCategoryId('');
                                                        setCategoryError(true);
                                                        setCategoryErrorMessage("Category is required");
                                                    }
                                                }}
                                                // sx={{ width: 235, ml: 1 }}
                                                renderInput={(params) => 
                                                <TextField {...params}
                                                //SOP-16 Inlcuded By PCS[Testing feedback Correction- to set auto focus]
                                                autoFocus
                                                //EOF SOP-16 Included By PCS [Testing feedback Correction- to set autofocus]
                                                error={CategoryError}
                                                helperText={CategoryErrorMessage}
                                                    required label="Category" 
                                                    variant="standard" />}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} xs={12} >
                                            <TextField
                                                type='text'
                                                variant="standard"
                                                //SOP-16 Modified By PCS[Testing feedback Correction]
                                                // label='Sub Category'
                                                label='Sub-Category'
                                                //EOF SOP-16 Modified By PCS[Testing feedback Correction]
                                                name='SubCategories'
                                                style={{ margin: '0 10px' }}
                                                fullWidth
                                                required
                                                error={SubCategoriesError}
                                                onChange={handleSubCategoriesChange}
                                                value={SubCategoriesValue}
                                                helperText={SubCategoriesErrorMessage}
                                            /> 
                                        </Grid>
                                    </Grid>
                                    

                                </Box>
                            <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Button
                                    sx={{
                                        background: 'white',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        // color: {accent},
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}

                                    onClick={handleClose} 
                                >
                                    Back
                                </Button>

                                <Button
                                    sx={{
                                        background: '#25425F',
                                        border: 'none',
                                        borderRadius: '5px',
                                        "&:hover":{
                                            backgroundColor:'#25425F',
                                            color:'white',
                                        },
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        color: 'white',
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}
                                        //SOP-16 Modified By PCS[Testing feedback Correction- to restrict form submission only on 'save' button]
                                        //type='submit'
                                        type='button'
                                        //EOF Modified By PCS [Testing feedback Correction- to restrict form submission only on 'save' button]
                                    onClick={(event)=>{
                                        HandleAddSubCategories(event);
                                    }}
                                   
                                >
                                    Save
                                </Button>
                                </Box>
                            </form>
                        </Box>

                    </Box>
                </Box>
            </Box>
        </>
    )

}

export default AddSubCategoriesPage;

// End of SOP-16