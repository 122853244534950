// SOP-61 Included By PCS
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Checkbox,
    FormControlLabel,

    FormControl,
} from '@mui/material';
    import Typography from '@mui/material/Typography';
    import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
    import axios from "axios";
    import { useNavigate } from "react-router-dom";
    import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
    import { commonService } from '../../atic-common-helpers/helpers/common.service';
    import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
    import Swal from "sweetalert2";


// Page start
const EditManagesAccess = 
    // To get handleEditAccessClose & getdocumentlistsearch from document page
    ({
        handleEditAccessClose,
        getdocumentlistsearch,
        EditUserId
    }: {
        handleEditAccessClose: (event: React.MouseEvent) => void,
        getdocumentlistsearch: (event: React.MouseEvent) => void,
        EditUserId: any;
    }) =>{

   
   //UseState for USerName
    const [UserIdListOptions, setUserIdListOptions] = useState<OptionType[]>([]);
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const [UserIdError, setUserIdError]=useState(false)
    const [UserIdErrorMessage, setUserIdErrorMessage]=useState('')

    const [checkboxError, setCheckboxError] = useState(false);
    const [CheckboxErrorMessage, setCheckboxErrorMessage] = useState('');

    const GlobalDataFromSession = sessionStorage.getItem("GlobalVariable") || '{}';
    const DocumentIdFromSession = sessionStorage.getItem("DocumentId") || '';

  //Initializing Paylaod Values 
    const GlobalData = JSON.parse(GlobalDataFromSession);
    let CompanyId = GlobalData.GlobalCompanyId;
    const [DocumentId, setDocumentId] = useState();
    const [editChecked, setEditChecked] = useState<boolean>(false);
    const [deleteChecked, setDeleteChecked] = useState<boolean>(false);
    const [DocuserRightsId, setDocuserRightsId] = useState();

        type OptionType = {
            name: string;
            value: string;
            employee_name:string;
            delete:number;
            edit:number;
            };


        const filterOptions = createFilterOptions<OptionType>({
            matchFrom: 'start',
            stringify: (option) => option.name,
        });

     // User Name Dropdown API
     const GetUserList = async () =>
         {
            const UsersListPayload ={
                    CompanyId:CompanyId,
                    active:'Y'
                }

                try {
                    const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/docpoint/getUsersList`,UsersListPayload);
                    // console.log("Users List" ,res.data.data);
                    let formattedUserList = res.data.data.map((item:any) => {
                    const { userid, fullname } = item;
                    return {
                        name: fullname,
                        value: userid,
                    };
                    });
                    setUserIdListOptions(formattedUserList)
                } catch (err) {
                    console.log( "co",err);
                }
        };


    // To render handle close function to close dialouge box
        const handleClose = (event: React.MouseEvent) => {
            if (handleEditAccessClose && typeof handleEditAccessClose === 'function') {
                handleEditAccessClose(event);
            }
    };


    // to render getdocumentlistsearch function after closing dialouge box
        const handlegetdocumentlistsearch= (event: React.MouseEvent) => {
            if (getdocumentlistsearch && typeof getdocumentlistsearch === 'function') {
                getdocumentlistsearch(event);
            }
    };


  // To get userrights details as per the id
  const GetUserRightsyListById =()=>{
   
        commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/docpoint/getUserRightsById`, {
            UserRightsId:EditUserId,
            CompanyId:CompanyId
        })
     .then((res: any) => 
     { 
       
        const UserData = res.data.data[0]
        // console.log("UserData",UserData)
         setSelectedUserId(UserData.userid);
         setEditChecked(UserData.editvalue === 1);
         setDeleteChecked(UserData.deletevalue === 1);
         setDocumentId(UserData.documentid)
         setDocuserRightsId(UserData.document_userrights_id)


        }).catch((error) =>  
        { 
        Failed(error.response.message);
        });
}

  

    // To Update Document USer rights

     // Define a type for errors
     type Errors = {
        userId?:boolean;
                  };

    const HandleUpdateUserRights = async (event:any)=>{
        event.preventDefault();
    
        let errors: Errors = {};

    
        if (selectedUserId===''||selectedUserId===null ||selectedUserId===undefined ) {
            errors.userId = true;
            setUserIdError(true);
            setUserIdErrorMessage("Please select an employee.");
            return;
             }
           setUserIdError(false); // Reset error if validation passes

        if (!editChecked && !deleteChecked) {
        setCheckboxError(true); // Show error for checkboxes
            return;
            }

        setCheckboxError(false); // Reset checkbox error
    
        if (Object.keys(errors).length > 0) {
            setUserIdError(errors.userId || false);
            return;
        }

      
    
        const UpdateUserRightsPayload = {
            SaveUpdateKey: "update",
            DocumentId:DocumentId,
            CompanyId:CompanyId ,
            UserId: selectedUserId,
            EditValue: editChecked?1:0,
            DeleteValue: deleteChecked?1:0,
            IsRemove: null,
            UserRightsId:DocuserRightsId,
 
                                  }
    
        // console.log("Update", UpdateUserRightsPayload);
         Swal.fire({
                html: '<span style="font-size: 1.3rem;">Are you sure to save ? </span>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                cancelButtonColor: '#d33',
                confirmButtonColor:"#25425f",
                allowOutsideClick: false,
                })
            .then((result) =>
            {
            if (result.isConfirmed)
            {
             commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/docpoint/manageaccess_userrights_create_update_delete`, UpdateUserRightsPayload)
            .then((res) => {
                if (res.status === 200) {
                    Success("Document User rights Updated successfully");
                    handleClose(event);
                    handlegetdocumentlistsearch(event);
                }
                }).catch((error) => {
                    console.error("Error:", error);
                });
            } 
            else  
            {
            return
            }
            });

    }
    
    // UseEffect declaration
    useEffect(() => {
         GetUserList();
        GetUserRightsyListById();
       
      }, [DocumentIdFromSession]); 


    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                event.preventDefault();
            }
        };

    const handleCheckboxChange = (type: 'edit' | 'delete', checked: boolean) => {
        if (type === 'edit') {
        setEditChecked(checked);
        } else {
        setDeleteChecked(checked);
        }

        if (checked) {
        setCheckboxError(false); // Clear checkbox error if any checkbox is selected
        }
        };

// Design start
    return(
        <>
        <Box>
            <Box display={'flex'} justifyContent={'center'}>
                <Box bgcolor={'white'} borderRadius={3}
                sx={{
                    overflow: 'hidden', // Add this line
                    width:{
                        lg:900,
                        md:600,
                        xs:700
    
                    }}}
                >
                <Box p={3}>
                    <Typography variant='h2'>
                    Edit User rights
                    </Typography>
                </Box>
                <form  noValidate>
                    <Box p={3}>
                        
                        <Grid container spacing={2} display={'flex'} justifyContent={'space-evenly'}>
                                    <Grid item lg={4} md={6} xs={12} >
                                    <Autocomplete
                                                    id="combo-box-demo"
                                                    disabled
                                                    options={UserIdListOptions as OptionType[]}
                                                    filterOptions={filterOptions}
                                                    getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                    value={UserIdListOptions.find((option: OptionType) => option.value === selectedUserId) || null}
                                                    onChange={(event, newValue) => {
                                                        if (newValue) {
                                                            setSelectedUserId(newValue.value);
                                                            setUserIdError(false);
                                                            setUserIdErrorMessage("");
                                                        } else {
                                                            setSelectedUserId('');
                                                            setUserIdError(true);
                                                            setUserIdErrorMessage("Please select an employee.");
                                                        }
                                                    }}
                                                    sx={{ml: 1.5 }}
                                                    renderInput={(params) => 
                                                    <TextField {...params}
                                                    error={UserIdError}
                                                    helperText={UserIdErrorMessage}
                                                    required label="User Name" 
                                                    variant="standard" />}
                                              />
                                    </Grid>
                                    <Grid item lg={3} md={6} xs={12} mt={1}>
                                    <FormControl error={checkboxError} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <FormControlLabel 
                                            control={
                                                <Checkbox
                                                    checked={editChecked}
                                                    onChange={(e) => handleCheckboxChange('edit', e.target.checked)}
                                                    
                                                   
                                                />
                                            }
                                            label="Edit"
                                        />
                                         {checkboxError && !deleteChecked && (
                                            <Typography sx={{ color: 'red', fontSize: '12px', ml: 1 }}> Select access.</Typography>
                                        )}
                                        </FormControl>
                                    </Grid>

                                    <Grid item lg={3} md={6} xs={6} mt={1}>
                                    <FormControl error={checkboxError} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={deleteChecked}
                                                    onChange={(e) => handleCheckboxChange('delete', e.target.checked)}
                                                 />
                                            }
                                            label="Delete"
                                            
                                        />
                                         </FormControl> 
                                    </Grid>
                                </Grid>
                                </Box>
                        <Box mt={4} display={'flex'} mb={1} alignItems={'center'} justifyContent={'space-between'}>
                         <Button
                                sx={{
                                    marginLeft:'15px',
                                    background: 'white',
                                    border: 'none',
                                    borderRadius: '5px',
                                    padding:{
                                        lg: "0 70px",
                                        md: "0 70px",
                                        xs:'0 50px'
            
                                    },
                                    
                                    height: "3rem",
                                    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                }}

                                 onClick={handleClose} 
                               
                            >
                                Back
                            </Button>
                          
                            <Button
                                sx={{
                                    marginRight:'15px',
                                    background: '#25425F',
                                    border: 'none',
                                    borderRadius: '5px',
                                    padding:{
                                        lg: "0 70px",
                                        md: "0 70px",
                                        xs:'0 50px'
            
                                    },
                                    "&:hover":{
                                        backgroundColor:'#25425F',
                                        color:'white',
                                    },
                                    color: 'white',
                                    height: "3rem",
                                    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                }}
                                type='submit'
                                onClick={(event)=>{
                                    HandleUpdateUserRights(event);
                                }}
                            >
                                Update
                            </Button>
                         
                            </Box>
                            
                        </form>
                    </Box>

                </Box>
            </Box>
        {/* </Box> */}
    </>
    )
// Design end

}
// Page end

export default EditManagesAccess;
// EOF SOP-61