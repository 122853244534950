import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import MainContainerWrapper from '../../atic-common-helpers/layout/wrappers/MainContainerWrapper';
import requestAsset from '../../asset/images/requestAsset1.png';
import raiseTicket from '../../asset/images/raiseTicket.png'
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { useNavigate } from "react-router-dom";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed } from '../../atic-common-helpers/helpers/toast.helper';

// (SOP-64) Asset Management - Home Page - New hire Request - TS 
import HR from '../../asset/images/HR employee.jpg'



// Included by sanjana SOP-26 imports

//SOP-49 - TS - commented - start
// Asset Management-Ticketing-Create Page
// import RaiseATicketPage from '../Ticketing/RaiseATicket';
//SOP-49 - TS - commented - end

import dayjs from 'dayjs';

  // SOP-55 - TS commented
// import EditRaiseATicketPage from '../Ticketing/EditRaiseATicket';
// import ViewRaiseATicketPage from '../Ticketing/ViewRaiseATicket';
// End of SOP-26



// (SOP-64) Asset Management - Home Page - New hire Request - TS - start
// Define a type for menu details
interface MenuDetails {
    menuname: string;
    menuid: number;
}
// (SOP-64) Asset Management - Home Page - New hire Request - TS - end



const HomePage =()=>{


    // useState
    // Modified by sanjana SOP-28 to rename usestate data as per the table
    // let  [order, setOrder] = React.useState<"desc" | "asc">("desc");
    // let  [orderBy, setOrderBy] = React.useState("");
    // let  [tableData, setTableData] = React.useState([]);
    // const [page, setPage] = React.useState<number>(0);
    // const [count, setCount] = useState<number>(0);
    // const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
    let  [AssetRequestorder, setAssetRequestOrder] = React.useState<"desc" | "asc">("desc");
    let  [AssetRequestorderBy, setAssetRequestOrderBy] = React.useState("");
    let  [AssetRequesttableData, setAssetRequestTableData] = React.useState([]);
    const [AssetRequestpage, setAssetRequestPage] = React.useState<number>(0);
    const [AssetRequestcount, setAssetRequestCount] = useState<number>(0);
    const [AssetRequestrowsPerPage, setAssetRequestsetRowsPerPage] = React.useState<number>(5);

    // Included by sanjana SOP-33 useState
    let  [UserAssetorder, setUserAssetOrder] = React.useState<"desc" | "asc">("desc");
    let  [UserAssetorderBy, setUserAssetOrderBy] = React.useState("");
    let  [UserAssettableData, setUserAssetTableData] = React.useState([]);
    const [UserAssetpage, setUserAssetPage] = React.useState<number>(0);
    const [UserAssetcount, setUserAssetCount] = useState<number>(0);
    const [UserAssetrowsPerPage, setUserAssetsetRowsPerPage] = React.useState<number>(5);
    // End of SOP-33
    
    let  [TicketTypeIncidentorder, setTicketTypeIncidentOrder] = React.useState<"desc" | "asc">("desc");
    let  [TicketTypeIncidentorderBy, setTicketTypeIncidentOrderBy] = React.useState("");
    let  [TicketTypeIncidenttableData, setTicketTypeIncidentTableData] = React.useState([]);
    const [TicketTypeIncidentpage, setTicketTypeIncidentPage] = React.useState<number>(0);
    const [TicketTypeIncidentcount, setTicketTypeIncidentCount] = useState<number>(0);
    const [TicketTypeIncidentrowsPerPage, setTicketTypeIncidentsetRowsPerPage] = React.useState<number>(5);
    
    let  [TicketTypeRequestorder, setTicketTypeRequestOrder] = React.useState<"desc" | "asc">("desc");
    let  [TicketTypeRequestorderBy, setTicketTypeRequestOrderBy] = React.useState("");
    let  [TicketTypeRequesttableData, setTicketTypeRequestTableData] = React.useState([]);
    const [TicketTypeRequestpage, setTicketTypeRequestPage] = React.useState<number>(0);
    const [TicketTypeRequestcount, setTicketTypeRequestCount] = useState<number>(0);
    const [TicketTypeRequestrowsPerPage, setTicketTypeRequestsetRowsPerPage] = React.useState<number>(5);
    // End of SOP-28

    //SOP-49 - TS - commented - start
    // Asset Management-Ticketing-Create Page
    // const [AddTicketOpen, setAddTicketOpen] = React.useState(false);
    //SOP-49 - TS - commented - end

    // SOP-55 - TS commented (screen pop dialogue is no need ) - start
    // Asset Management-Ticketing-View and Edit Page
        // const [EditTicketOpen, setEditTicketOpen] = React.useState(false);
        // const [EditTicketId, setEditTicketId]= useState('')
        // const [ViewTicketOpen, setViewTicketOpen] = React.useState(false);
        // const [ViewTicketId, setViewTicketId] = useState('')
    // SOP-55 - TS commented - end



    // (SOP-64) Asset Management - Home Page - New hire Request - TS
    const [isHRRequestVisible, setHRRequestVisible] = useState(false); // Visible state
    // console.log("isHRRequestVisible", isHRRequestVisible)
    // (SOP-64) Asset Management - Home Page - New hire Request - TS



    // {/* (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - HR Grid - start */}- TS - start 
    // (useState declaration for grid)
    let  [HRHireRequestorder, setHRHireRequestOrder] = React.useState<"desc" | "asc">("desc");
    let  [HRHireRequestorderBy, setHRHireRequestOrderBy] = React.useState("");
    let  [HRHireRequesttableData, setHRHireRequestTableData] = React.useState([]);
    const [HRHireRequestpage, setHRHireRequestPage] = React.useState<number>(0);
    const [HRHireRequestcount, setHRHireRequestCount] = useState<number>(0);
    const [HRHireRequestrowsPerPage, setHRHireRequestsetRowsPerPage] = React.useState<number>(5);
    // (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - TS - end


    const navigate = useNavigate();

        // To set action type for edit ad delete
        let actionType;
      
        //SOP-49 - TS - commented - start
        // Asset Management-Ticketing-Create Page
            //  To open add dialouge box
        //    const handleAddTicketOpen = () => {
        //     setAddTicketOpen(true);
        //     };
        
        //     // To close add dialouge box
        //     const handleAddTicketClose = () => {
        //     setAddTicketOpen(false);
        //     };
        //SOP-49 - TS - commented - end

    
    // SOP-55 - TS commented (screen pop dialogue is no need) - start
    // Asset Management-Ticketing-View and Edit Page
            // To open edit dialouge box
            // const handleEditTicketOpen = (id:any) => {
            //     setEditTicketOpen(true);
            //     //console.log("id",id)
            //      setEditTicketId(id)
            //  };
        
            // // To close edit dialouge box
            // const handleEditTicketClose = () => {
            // setEditTicketOpen(false);
            // };
    
            // // To open View dialouge box
            // const handleViewTicketOpen = (id:any) => {
            //     setViewTicketOpen(true);
            //     //console.log("id",id)
            //         setViewTicketId(id)
            //     };
        
            // // To close View dialouge box
            // const handleViewTicketClose = () => {
            // setViewTicketOpen(false);
            // };
      // SOP-55 - TS commented (screen pop dialogue is no need) - end

        // Head cell of My Asset Request
        const AssetRequestHeadCell = [
            {
                id: "request_number",
                numeric: false,
                disablePadding: true,
                label: "Request #",
                isSort: true,
                isBold: true,
            },
            {
                id: "assetname",
                numeric: false,
                disablePadding: true,
                label: "Asset Name",
                isSort: true,
                isBold: true,
            },
            {
                id: "request_date",
                numeric: false,
                disablePadding: false,
                label: "Request Date",
                isSort: true,
                isBold: true,
                dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
            },
            {
                id: "request_remarks",
                numeric: false,
                disablePadding: false,
                label: "Remarks",
                isSort: true,
                isBold: true,
            },
            
            ];

        // Included by sanjana SOP-33 head cell of my property

        // Head cell of My Property
        const MyPropertyHeadCell = [
            {
                id: "assetname",
                numeric: false,
                disablePadding: true,
                label: "Asset Name",
                isSort: true,
                isBold: true,
            },
            {
            id: "modelnumber",
            numeric: false,
            disablePadding: false,
            label: "Model number",
            isSort: true,
            isBold: true,
            },

            {
            id: "serialnumber",
            numeric: false,
            disablePadding: false,
            label: "Serial number",
            isSort: true,
            isBold: true,
            },
            {
            id: "manufacturer",
            numeric: false,
            disablePadding: false,
            label: "Manufacturer",
            isSort: true,
            isBold: true,
            },
            
            ];

        // End of SOP-33
        
        // Head cell of My Asset Request
        const TicketTypeIncidentHeadCell = [
            {
                id: "ticket_number",
                numeric: false,
                disablePadding: true,
                label: "Ticket #",
                isSort: true,
                isBold: true,
            },
            {
                id: "ticket_date",
                numeric: false,
                disablePadding: true,
                label: "Ticket Date",
                isSort: true,
                isBold: true,
                dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
            },
            {
            id: "sys_issue_type_name",
            numeric: false,
            disablePadding: false,
            label: "Issue Type",
            isSort: true,
            isBold: true,
            },
            // {
            // id: "ticket_issuedescription",
            // numeric: false,
            // disablePadding: false,
            // label: "Issue Description",
            // isSort: true,
            // isBold: true,
            // },
            

            //     {
            // id: "ticket_issuetype",
            // numeric: false,
            // disablePadding: false,
            // label: "Issue Type",
            // isSort: true,
            // isBold: true,
            // },
            {
            id: "ticket_issueshortdescription",
            numeric: false,
            disablePadding: false,
            label: "Issue ShortDescription",
            isSort: true,
            isBold: true,
            },
            {
                id: "action",
                numeric: false,
                disablePadding: false,
                label: "Actions",
                isSort: false,
              },
            
            ];

        // Head cell of My Asset Request
        const TicketTypeRequestHeadCell = [
            {
                id: "ticket_number",
                numeric: false,
                disablePadding: true,
                label: "Ticket #",
                isSort: true,
                isBold: true,
            },
            {
                id: "ticket_date",
                numeric: false,
                disablePadding: true,
                label: "Ticket Date",
                isSort: true,
                isBold: true,
                dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
            },
            {
            id: "sys_issue_type_name",
            numeric: false,
            disablePadding: false,
            label: "Issue Type",
            isSort: true,
            isBold: true,
            },
            {
            id: "ticket_issuedescription",
            numeric: false,
            disablePadding: false,
            label: "Issue Description",
            isSort: true,
            isBold: true,
            },
            {
            id: "action",
            numeric: false,
            disablePadding: false,
            label: "Actions",
            isSort: false,
              },
            
            ];  

    
    
    // (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - start
    // HRHireRequestHeadCell
    const HRHireRequestHeadCell = [
        // {
        //     id: "hirerequestid",
        //     numeric: false,
        //     disablePadding: true,
        //     label: "HireRequest Id #",
        //     isSort: true,
        //     isBold: true,
        // },
        {
            id: "hirerequest_hiring_jobcode_title",
            numeric: false,
            disablePadding: true,
            label: "Job Profile",
            isSort: true,
            isBold: true,
        },
        {
            id: "hirerequest_total_open_position",
            numeric: false,
            disablePadding: true,
            label: "Total Position",
            isSort: true,
            isBold: true,
        },
        {
            id: "departmentname",
            numeric: false,
            disablePadding: true,
            label: "Department",
            isSort: true,
            isBold: true,
        },
        {
            id: "employee_name",
            numeric: false,
            disablePadding: true,
            label: "Hiring Manager",
            isSort: true,
            isBold: true,
        },
        {
            id: "sys_hr_employment_type_name",
            numeric: false,
            disablePadding: true,
            label: "Type of Employment",
            isSort: true,
            isBold: true,
        },
        {
            id: "sys_hr_hirerequest_status_name",
            numeric: false,
            disablePadding: true,
            label: "Current Status",
            isSort: true,
            isBold: true,
        },
        {
            id: "action",
            numeric: false,
            disablePadding: false,
            label: "Actions",
            isSort: false,
          },
        ];
    
        // (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - end
    
        
    //  Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    //console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId
    const UserId = GlobalData.GlobalUserId;
    // Included by sanjana SOP-40 to get Employee id
    let EmployeeId = GlobalData.GlobalEmployeeId
    // End of SOP-40

    // Included by sanjana SOP-26 To get Ticket list search API 
    // Modified by sanjana SOP-28 to get request an asset API 
    const getAssetRequestList = () => 
    {       
        commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/requestanasset/get_request_and_asset_list_for_employee`, {
            SortColumnName: AssetRequestorderBy,
            SortColumnDirection: AssetRequestorder,
            StartIndex: AssetRequestpage + 1,
            PageSize: AssetRequestrowsPerPage,
            CompanyId:CompanyId,
            // Modified by sanjana SOP-40 to set employee id insted of user id
            // CreatedBy:UserId,
            // Modified by sanjana SOP-58 to set userid id insted of employee id
            CreatedBy:UserId,
            // CreatedBy:EmployeeId,
            // End of SOP-58
            
            // End of SOP-40
        })
        .then((res: any) => 
        {
            console.log("res321",res)
          
        const formattedTableData= res.data.data.map((item:any)=>{
        return {...item,
            id:item.ticketid,
            request_date: item.request_date ? dayjs(item.request_date).format("DD-MM-YYYY") : "",
        }
        }) 
        //console.log("formattedTableData",formattedTableData)
        
        setAssetRequestTableData(formattedTableData);
        if(res.data.data[0]===undefined)
        {
            setAssetRequestCount(0);
        }
        else
        {
            setAssetRequestCount(res.data.data[0].total_count);
        }
        })
    .catch((error) => 
        {
        if (error.response.status === 404)
        {
            setAssetRequestTableData([]);
        }
        Failed(error.response.message);
        });
    };
    // End of SOP-28
    // End of SOP-26

    // Included by sanjana SOP-33 to get user list API 
    const getUserAssetList = () => 
        {       
            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/requestanasset/get_user_asset_list`, {
                SortColumnName: UserAssetorderBy,
                SortColumnDirection: UserAssetorder,
                StartIndex: UserAssetpage + 1,
                PageSize: UserAssetrowsPerPage,
                CompanyId:CompanyId,
                Userid:UserId
            })
            .then((res: any) => 
            {
               
            const formattedTableData= res.data.data.map((item:any)=>{
            return {...item,
                request_date: item.request_date ? dayjs(item.request_date).format("DD-MM-YYYY") : "",
            }
            }) 
           console.log("formattedTableData UL",formattedTableData)
            
            setUserAssetTableData(formattedTableData);
            if(res.data.data[0]===undefined)
            {
                setUserAssetCount(0);
            }
            else
            {
                setUserAssetCount(res.data.data[0].total_count);
            }
            })
            .catch((error) => 
            {
            if (error.response.status === 404)
            {
                setUserAssetTableData([]);
            }
            Failed(error.response.message);
            });
        };
    // End of SOP-33

    // Modified by sanjana SOP-28 to get ticket list as pet the ticket type = Incident
    const getTicketTypeIncidentList = () => 
        {   
            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/get_ticket_list_for_employee`, {
                SortColumnName: TicketTypeIncidentorderBy,
                SortColumnDirection: TicketTypeIncidentorder,
                StartIndex: TicketTypeIncidentpage + 1,
                PageSize: TicketTypeIncidentrowsPerPage,
                CompanyId:CompanyId,
                CreatedBy:UserId,
                // // ticket type 1 for Incident
                // // TicketType:1
                // IssueType:1,
                // IssueShortDescription:'tested by sivasuri'
                // "SortColumnName":TicketTypeIncidentorderBy,
                // "SortColumnDirection":null,
                // "StartIndex":1,
                //   "PageSize":10,
                //   "CompanyId":65,
                //   "CreatedBy":109
            })
            .then((res: any) => 
            {
                console.log("res",res)
            const formattedTableData= res.data.data.map((item:any)=>{
            return {...item,
                id:item.ticketid,
                ticket_date: item.ticket_date ? dayjs(item.ticket_date).format("DD-MM-YYYY") : "",
            }
            }) 
            //console.log("formattedTableData",formattedTableData)
            
            setTicketTypeIncidentTableData(formattedTableData);
            if(res.data.data[0]===undefined)
            {
                setTicketTypeIncidentCount(0);
            }
            else
            {
                setTicketTypeIncidentCount(res.data.data[0].total_count);
            }
            })
        .catch((error) => 
            {
            if (error.response.status === 404)
            {
                setTicketTypeIncidentTableData([]);
            }
            Failed(error.response.message);
            });
        };
        // End of SOP-28


        // Modified by sanjana SOP-28 to get ticket list as pet the ticket type = Request
    const getTicketTypeRequestList = () => 
        {  
            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/get_ticket_list_for_employee`, {
                SortColumnName: TicketTypeRequestorderBy,
                SortColumnDirection: TicketTypeRequestorder,
                StartIndex: TicketTypeRequestpage + 1,
                PageSize: TicketTypeRequestrowsPerPage,
                CompanyId:CompanyId,
                CreatedBy:UserId,
                TicketType:2
            })
            .then((res: any) => 
            {
                //console.log("res",res)
            const formattedTableData= res.data.data.map((item:any)=>{
            return {...item,
                id:item.ticketid,
                ticket_date: item.ticket_date ? dayjs(item.ticket_date).format("DD-MM-YYYY") : "",
            }
            }) 
            //console.log("formattedTableData",formattedTableData)
            
            setTicketTypeRequestTableData(formattedTableData);
            if(res.data.data[0]===undefined)
            {
                setTicketTypeRequestCount(0);
            }
            else
            {
                setTicketTypeRequestCount(res.data.data[0].total_count);
            }
            })
        .catch((error) => 
            {
            if (error.response.status === 404)
            {
                setTicketTypeRequestTableData([]);
            }
            Failed(error.response.message);
            });
        };
        // End of SOP-28   
    
    
    // (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - start
    //  getHRHireRequestList API 
    const getHRHireRequestList = () => 
        {       
            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/get_hr_hire_requestlist`, {
                SortColumnName: HRHireRequestorderBy,
                SortColumnDirection: HRHireRequestorder,
                StartIndex: HRHireRequestpage + 1,
                PageSize: HRHireRequestrowsPerPage,
                CompanyId:CompanyId,
                CreatedBy:UserId,
            })
            .then((res: any) => 
            {
                // console.log("HRHireReques",res)
            const formattedTableData= res.data.data.map((item:any)=>{
            return {...item,
                hirerequest_start_date: item.hirerequest_start_date ? dayjs(item.hirerequest_start_date).format("DD-MM-YYYY") : "",
            }
            }) 
            // console.log("formattedTableData111",formattedTableData)
            
            setHRHireRequestTableData(formattedTableData);
            if(res.data.data[0]===undefined)
            {
                setHRHireRequestCount(0);
            }
            else
            {
                setHRHireRequestCount(res.data.data[0].total_count);
            }
            })
        .catch((error) => 
            {
            if (error.response.status === 404)
            {
                setHRHireRequestTableData([]);
            }
            Failed(error.response.message);
            });
    };
  
    // handle Sort
    const handleHRHireRequestIncidentRequestSort = (event: any, property: any) =>
        {
        const isAsc = HRHireRequestorderBy === property && HRHireRequestorder === "asc";
        HRHireRequestorder = isAsc ? "desc" : "asc";
        setHRHireRequestOrder(HRHireRequestorder);
        HRHireRequestorderBy = property;
        setHRHireRequestOrderBy(HRHireRequestorderBy);
    };
    
    // RequestactionFunction
    const HRHireRequestactionFunction = (id: string, actionText: string) => 
        {
            // if (actionText === "view") navigate(`/view_nationality/${id}`);
            // if (actionText === "edit") navigate(`/edit_nationality/${id}`);
        };

    // Pagination Handler of table
    const HRHireRequesthandleChangePage = (event: unknown, newPage: number) => {
        setHRHireRequestPage(newPage);
        };

    // Rows Per page Handler
    const HRHireRequesthandleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
        ) => {
            setHRHireRequestsetRowsPerPage(+event.target.value);
            setHRHireRequestPage(0);
    };
    
    // (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - end
    // Sort, Action function,Pagination, Per page Handler - end

    
    // Sort Handler
    const handleAssetRequestIncidentRequestSort = (event: any, property: any) =>
    {
    const isAsc = AssetRequestorderBy === property && AssetRequestorder === "asc";
    AssetRequestorder = isAsc ? "desc" : "asc";
    setAssetRequestOrder(AssetRequestorder);
    AssetRequestorderBy = property;
    setAssetRequestOrderBy(AssetRequestorderBy);
    };


    // Action function of table icons
    const AssetRequestactionFunction = (id: string, actionText: string) => 
    {
        if (actionText === "view") navigate(`/view_nationality/${id}`);
        // if (actionText === "edit") navigate(`/edit_nationality/${id}`);
    };


    // Pagination Handler of table
    const AssetRequesthandleChangePage = (event: unknown, newPage: number) => {
        setAssetRequestPage(newPage);
    };

    // Rows Per page Handler
    const AssetRequesthandleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
        ) => {
            setAssetRequestsetRowsPerPage(+event.target.value);
            setAssetRequestPage(0);
    };

    // Included by sanjana SOP-33 
        // Sort Handler
        const handleUserAssetSort = (event: any, property: any) =>
        {
        const isAsc = UserAssetorderBy === property && UserAssetorder === "asc";
        UserAssetorder = isAsc ? "desc" : "asc";
        setUserAssetOrder(UserAssetorder);
        UserAssetorderBy = property;
        setUserAssetOrderBy(UserAssetorderBy);
        };
        
        
        // Action function of table icons
        const UserAssetactionFunction = (id: string, actionText: string) => 
        {
            // if (actionText === "view") navigate(`/view_nationality/${id}`);
            // if (actionText === "edit") navigate(`/edit_nationality/${id}`);
        };
        
        
        // Pagination Handler of table
        const UserAssethandleChangePage = (event: unknown, newPage: number) => {
            setUserAssetPage(newPage);
        };
        
        // Rows Per page Handler
        const UserAssethandleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
            ) => {
                setUserAssetsetRowsPerPage(+event.target.value);
                setUserAssetPage(0);
        };
        // End of SOP-33


    // Sort Handler
    const handleTicketTypeIncidentRequestSort = (event: any, property: any) =>
        {
        const isAsc = TicketTypeIncidentorderBy === property && TicketTypeIncidentorder === "asc";
        TicketTypeIncidentorder = isAsc ? "desc" : "asc";
        setTicketTypeIncidentOrder(TicketTypeIncidentorder);
        TicketTypeIncidentorderBy = property;
        setTicketTypeIncidentOrderBy(TicketTypeIncidentorderBy);
        };
    
    // Action function of table icons
    const TicketTypeIncidentactionFunction = (id: string, actionText: string) => 
    {
        if (actionText === "view"){
            // SOP-55 - TS commented and included (navigation) - start
            // Asset Management-Ticketing-View and Edit Page
                // handleViewTicketOpen(id);
                navigate(`/View_raise_a_ticket_page/${id}`)
            // SOP-55 - TS commented and included (navigation) - end
        }
    };
    

    // Pagination Handler of table
    const TicketTypeIncidenthandleChangePage = (event: unknown, newPage: number) => {
        setTicketTypeIncidentPage(newPage);
    };

    // Rows Per page Handler
    const TicketTypeIncidenthandleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
        ) => {
            setTicketTypeIncidentsetRowsPerPage(+event.target.value);
            setTicketTypeIncidentPage(0);
    };


    // Sort Handler
    const handleTicketTypeRequestRequestSort = (event: any, property: any) =>
        {
        const isAsc = TicketTypeRequestorderBy === property && TicketTypeRequestorder === "asc";
        TicketTypeRequestorder = isAsc ? "desc" : "asc";
        setTicketTypeRequestOrder(TicketTypeRequestorder);
        TicketTypeRequestorderBy = property;
        setTicketTypeRequestOrderBy(TicketTypeRequestorderBy);
        };
      
    // Action function of table icons
    const TicketTypeRequestactionFunction = (id: string, actionText: string) => 
    {
        if (actionText === "view") {

            // SOP-55 - TS commented and included (navigation) - start
            // Asset Management-Ticketing-View and Edit Page
                // handleViewTicketOpen(id);
                navigate(`/edit_raise_a_ticket_page/${id}`)
            // SOP-55 - TS commented and included (navigation) - end
        }
        // if (actionText === "edit") navigate(`/edit_nationality/${id}`);
    };
    

    // Pagination Handler of table
    const TicketTypeRequesthandleChangePage = (event: unknown, newPage: number) => {
        setTicketTypeRequestPage(newPage);
    };

    // Rows Per page Handler
    const TicketTypeRequesthandleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
        ) => {
            setTicketTypeRequestsetRowsPerPage(+event.target.value);
            setTicketTypeRequestPage(0);
    };

// (SOP-64) Asset Management - Home Page - New hire Request - TS -  start

    //user rights from session storage 
    useEffect(() => {
        // Read from session storage
        const employeeMenuDetails = sessionStorage.getItem('EmployeeMenuDetails');
        if (employeeMenuDetails) {
          try {
            const menuDetailsArray: MenuDetails[] = JSON.parse(employeeMenuDetails);
                // console.log("menuDetailsArray", menuDetailsArray)
              
            // check if any item in the array matches the condition
            const isHRHierarchyMenuPresent = menuDetailsArray.some((menuDetails: MenuDetails) =>
              menuDetails.menuname === 'HR Work Space' && menuDetails.menuid === 11
              );
            //   console.log("isHRHierarchyMenuPresent",isHRHierarchyMenuPresent)
            setHRRequestVisible(isHRHierarchyMenuPresent);
          }
          catch (error) {
            // console.error('error from session storage', error);
            setHRRequestVisible(false);
          }
        } else {
          setHRRequestVisible(false);
        }
      }, []);

    // (SOP-64) Asset Management - Home Page - New hire Request- TS - end


    useEffect(() => {
        getAssetRequestList();
        getTicketTypeIncidentList();
        getTicketTypeRequestList();
        // Included by sanjana SOP-33
        getUserAssetList();
        // End of SOP-33
      
        getHRHireRequestList();  //  (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - API 

    }, [AssetRequestrowsPerPage,AssetRequestpage,AssetRequestorderBy,AssetRequestorder,
        TicketTypeIncidentrowsPerPage,TicketTypeIncidentpage, TicketTypeIncidentorderBy,TicketTypeIncidentorder,TicketTypeRequestrowsPerPage,TicketTypeRequestpage, TicketTypeRequestorderBy, TicketTypeRequestorder,
        // Included by sanjana SOP-33 to declare functions
        UserAssetrowsPerPage,UserAssetpage,UserAssetorderBy,UserAssetorder
        // End of SOP-33


        //  (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager
        ,HRHireRequestorder,HRHireRequestorderBy,HRHireRequestpage,HRHireRequestrowsPerPage
    ]); 
    

    return(
        <MainContainerWrapper headingText=''> 
            <Box >
                <Box display={'flex'} justifyContent={'center'}>
                    
                    {/* (SOP-64) Asset Management - Home Page - New hire Request- TS (alignment) */}
                    {/* <Box bgcolor={'white'} width={600} borderRadius={3} > */}
                    {/* //SOP-75 feedback points L1 modified by  PCS */}
                        {/* <Box bgcolor={'white'} width={800} borderRadius={3} > */}
                            <Box bgcolor={'white'} width={1000} borderRadius={3} >
                    {/* //EOF SOP-75  feedback points L1 modified by  PCS */}
                    {/* (SOP-64) Asset Management - Home Page - New hire Request - TS (alignment)*/}
                        
                    <Box sx={{
                        display:{
                            lg:'flex',
                            xs:'flex',
                            md:'flex',
                        },
                        justifyContent:{
                            lg:'center',
                            xs:'center',
                            md:'center',
                        },
                    }}>
                    <Typography 
                    sx={{
                        fontSize:{
                            lg:'1.5rem',
                            xs:'1rem'
                    }, 
                    }}
                    color={'black'} mt={5}>
                        {/* Modified by sanjana SOP-28 */}
                        {/* How can we Help you today? */}
                        How can we help you today?
                        {/* End of SOP-28 */}
                    </Typography>
                </Box>  

                <Box mb={3}>
                    <Grid container
                        mt={3}  mb={3}  
                        sx={{
                        display:{
                            lg:'flex',
                            xs:'flex',
                            md:'flex',
                        },
                        justifyContent:{
                            lg:'center',
                            xs:'center',
                            md:'center',
                        }}}>
                    <Grid item 
                        //  (SOP-64) Asset Management - Home Page - New hire Request - TS (alignment)
                        // lg={6} md={6} 
                          //SOP-75 feedback points L1 included By PCS [modifying grid space]
                           // lg={4} md={4} 
                              lg={3} md={3} 
                          //EOF SOP-75 feedback points L1 included By PCS [modifying grid space]
                        sx={{
                            display:{
                                lg:'flex',
                                xs:'flex',
                                md:'flex',
                            },
                            justifyContent:{
                                lg:'center',
                                xs:'center',
                                md:'center',
                            }}}>
                            <Box >
                                <Box  
                                    sx={{
                                    display:{
                                        lg:'flex',
                                        xs:'flex',
                                        md:'flex',
                                    },
                                    justifyContent:{
                                        lg:'center',
                                        xs:'center',
                                        md:'center',
                                    },
                                }}>
                                    <img src={requestAsset} alt='requestAsset' height={65} width={65} />
                                </Box>
                                <Box mt={3} 
                                    sx={{
                                    display:{
                                        lg:'flex',
                                        xs:'flex',
                                        md:'flex',
                                    },
                                    justifyContent:{
                                        lg:'center',
                                        xs:'center',
                                        md:'center',
                                    },
                                    }}>
                                    <Typography fontSize={'1.5rem'} color={'#4CB18F'} sx={{cursor:'pointer'}}
                                    // Included by sanjana SOP-25 to add link of request an asset page
                                    onClick={()=>{
                                        navigate("/request_an_asset")
                                    }}
                                    // End of SOP-25
                                    >
                                       <u>  Request an asset</u> 
                                    </Typography>                                
                                </Box>

                                <Box mt={3}  
                                    sx={{
                                    display:{
                                        lg:'flex',
                                        xs:'flex',
                                        md:'flex',
                                    },
                                    justifyContent:{
                                        lg:'center',
                                        xs:'center',
                                        md:'center',
                                    },
                                }}>
                                    <Typography fontSize={'1rem'} color={'#4CB18F'}>
                                    Request a hardware
                                    </Typography>
                                </Box>
                                <Box  
                                    sx={{
                                    display:{
                                        lg:'flex',
                                        xs:'flex',
                                        md:'flex',
                                    },
                                    justifyContent:{
                                        lg:'center',
                                        xs:'center',
                                        md:'center',
                                    },
                                    }}
                                >
                                    <Typography fontSize={'1rem'} color={'#4CB18F'}>
                                        software or IT Services
                                    </Typography>
                                </Box>
                            </Box>
                    </Grid>


                    {/* (SOP-64) Asset Management - Home Page - New hire Request - TS (alignment)*/}
                    {/* <Grid item lg={6} md={6}   */}
                                
                    <Grid item 
                    //SOP-75 feedback points L1 included By PCS [modifying grid space]
                           // lg={4} md={4} 
                               lg={3} md={3} 
                    //EOF SOP-75 feedback points L1 included By PCS [modifying grid space] 

                        sx={{
                        display:{
                            lg:'flex',
                            xs:'flex',
                            md:'flex',
                        },
                        justifyContent:{
                            lg:'center',
                            xs:'center',
                            md:'center',
                        },
                        }}
                    >
                        <Box>
                            <Box  
                            sx={{
                                display:{
                                    lg:'flex',
                                    xs:'flex',
                                    md:'flex',
                                },
                                justifyContent:{
                                    lg:'center',
                                    xs:'center',
                                    md:'center',
                                },
                            }}>
                                <img src={raiseTicket} alt='raiseTicket' height={65} width={65} />
                            </Box>
                            <Box mt={3} 
                            sx={{
                                display:{
                                    lg:'flex',
                                    xs:'flex',
                                    md:'flex',
                                },
                                justifyContent:{
                                    lg:'center',
                                    xs:'center',
                                    md:'center',
                                },
                            }}>
                                <Typography fontSize={'1.5rem'} color={'black'} sx={{cursor:'pointer'}}
                                // Included by sanjana to Open add ticket dialouge box
                                        onClick={() => {
                                    
                                //SOP-49 - TS - commented - start
                                // Asset Management-Ticketing-Create Page
                                    // handleAddTicketOpen();
                                
                                    navigate('/raise_a_ticket_page') // included navigation 
                                //SOP-49 - TS - commented - end
                                }}
                                >
                                    {/* Modified by sanjana SOP-26 to give underline for text */}
                                            {/* Raise a ticket */}
                                            <u> Raise a ticket </u>
                                        {/* End of SOP-26 */}
                                </Typography>                                
                            </Box>
                            <Box mt={3}  
                            sx={{
                                display:{
                                    lg:'flex',
                                    xs:'flex',
                                    md:'flex',
                                },
                                justifyContent:{
                                    lg:'center',
                                    xs:'center',
                                    md:'center',
                                },
                            }}>
                                <Typography fontSize={'1rem'} color={'black'}>
                                    Report a problem
                                </Typography>
                            </Box>
                            <Box  
                            sx={{
                                display:{
                                    lg:'flex',
                                    xs:'flex',
                                    md:'flex',
                                },
                                justifyContent:{
                                    lg:'center',
                                    xs:'center',
                                    md:'center',
                                },
                            }}>
                                <Typography fontSize={'1rem'} color={'black'}>
                                    to get support
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>



            {/* //SOP-75 feedback points L1 included By PCS */}

                    <Grid item 
                       lg={3} md={3} 
                        sx={{
                            display:{
                                lg:'flex',
                                xs:'flex',
                                md:'flex',
                            },
                            justifyContent:{
                                lg:'center',
                                xs:'center',
                                md:'center',
                            }}}>
                            <Box >
                                <Box  
                                    sx={{
                                    display:{
                                        lg:'flex',
                                        xs:'flex',
                                        md:'flex',
                                    },
                                    justifyContent:{
                                        lg:'center',
                                        xs:'center',
                                        md:'center',
                                    },
                                }}>
                                    <img src={HR} alt='hieracrchy' height={65} width={65} />
                                </Box>
                                <Box mt={3} 
                                    sx={{
                                    display:{
                                        lg:'flex',
                                        xs:'flex',
                                        md:'flex',
                                    },
                                    justifyContent:{
                                        lg:'center',
                                        xs:'center',
                                        md:'center',
                                    },
                                    }}>
                                    <Typography fontSize={'1.5rem'} color={'#4CB18F'} sx={{cursor:'pointer'}}
                                   
                                    onClick={()=>{
                                        navigate("/hr_hierarchy")
                                    }}
                                    
                                    >
                                      {/* //SOP-76 feedback points L2 modified by PCS [renaming View  Hierarchy to View ORG ] */}
                                             {/* <u> View  Hierarchy </u> */}
                                                 <u> View  ORG </u>
                                      {/* //EOF SOP-76 feedback points L2 modified by PCS [renaming View  Hierarchy to View ORG ] */}
                                    </Typography>                                
                                </Box>

                                <Box mt={3}  
                                    sx={{
                                    display:{
                                        lg:'flex',
                                        xs:'flex',
                                        md:'flex',
                                    },
                                    justifyContent:{
                                        lg:'center',
                                        xs:'center',
                                        md:'center',
                                    },
                                }}>
                                    
                                </Box>
                                <Box  
                                    sx={{
                                    display:{
                                        lg:'flex',
                                        xs:'flex',
                                        md:'flex',
                                    },
                                    justifyContent:{
                                        lg:'center',
                                        xs:'center',
                                        md:'center',
                                    },
                                    }}
                                >
                                  
                                </Box>
                            </Box>
                    </Grid>

            {/* //EOF SOP-75 feedback points L1 included By PCS*/}
                                

                {/* (SOP-64) Asset Management - Home Page - New hire Request  -  HR Request block start - - TS*/}
            {/* //SOP-75 feedback points L1 commented by PCS [grid space is modified- alignment is changed] */}
                    {/* {isHRRequestVisible && (
                        <Box >
                            <Grid item 
                                //SOP-75 feedback points L1 included By PCS [modifying grid space]
                                // lg={4} md={4} 
                                    lg={2} md={2} 
                                //EOF SOP-75 feedback points L1 included By PCS [modifying grid space] 
                                sx={{
                                    display:{
                                        lg:'flex',
                                        xs:'flex',
                                        md:'flex',
                                    },
                                    justifyContent:{
                                        lg:'center',
                                        xs:'center',
                                        md:'center',
                                    }}}>
                                    <Box >
                                        <Box  
                                        sx={{
                                            display:{
                                                lg:'flex',
                                                xs:'flex',
                                                md:'flex',
                                            },
                                            justifyContent:{
                                                lg:'center',
                                                xs:'center',
                                                md:'center',
                                            },
                                        }}>
                                            <img src={HR} alt='requestAsset' height={67} width={67} />
                                        </Box>
                                        <Box mt={3} 
                                            sx={{
                                                display:{
                                                    lg:'flex',
                                                    xs:'flex',
                                                    md:'flex',
                                                },
                                                justifyContent:{
                                                    lg:'center',
                                                    xs:'center',
                                                    md:'center',
                                                },
                                                }}>
                                                <Typography fontSize={'1.5rem'} color={'#600918'} sx={{cursor:'pointer'}}
                                                    onClick={() => {
                                                    // (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - TS (navigate path included) 
                                                        navigate("/hr_new_hire_request")
                                                    }}
                                                >
                                                    <u>  New Hire Request </u> 
                                                </Typography>                                
                                        </Box>
                                        <Box mt={3}  
                                            sx={{
                                                display:{
                                                    lg:'flex',
                                                    xs:'flex',
                                                    md:'flex',
                                                },
                                                justifyContent:{
                                                    lg:'center',
                                                    xs:'center',
                                                    md:'center',
                                                },
                                            }}>
                                                <Typography fontSize={'1rem'} color={'#600918'}>
                                                    Request for a
                                                </Typography>
                                        </Box>
                                        <Box  
                                            sx={{
                                                display:{
                                                    lg:'flex',
                                                    xs:'flex',
                                                    md:'flex',
                                                },
                                                justifyContent:{
                                                    lg:'center',
                                                    xs:'center',
                                                    md:'center',
                                                },
                                            }}>
                                                <Typography fontSize={'1rem'} color={'#600918'}>
                                                new Hire
                                                </Typography>
                                        </Box>
                                    </Box>
                            </Grid>
                        </Box>
                        )}             */}
       {/* //SOP-75 feedback points L1 commented by PCS [grid space is modified- alignment is changed] */}   

                     {isHRRequestVisible && (
                       <Grid item 
                    //SOP-75 feedback points L1 included By PCS [modifying grid space]
                           // lg={4} md={4} 
                              lg={3} md={3} 
                    //EOF SOP-75 feedback points L1 included By PCS [modifying grid space] 

                        sx={{
                        display:{
                            lg:'flex',
                            xs:'flex',
                            md:'flex',
                        },
                        justifyContent:{
                            lg:'center',
                            xs:'center',
                            md:'center',
                        },
                        }}
                    >
                        <Box>
                            <Box  
                            sx={{
                                display:{
                                    lg:'flex',
                                    xs:'flex',
                                    md:'flex',
                                },
                                justifyContent:{
                                    lg:'center',
                                    xs:'center',
                                    md:'center',
                                },
                            }}>
                               <img src={HR} alt='requestAsset' height={67} width={67} />
                            </Box>
                            <Box mt={3} 
                            sx={{
                                display:{
                                    lg:'flex',
                                    xs:'flex',
                                    md:'flex',
                                },
                                justifyContent:{
                                    lg:'center',
                                    xs:'center',
                                    md:'center',
                                },
                            }}>
                                <Typography fontSize={'1.5rem'} color={'black'} sx={{cursor:'pointer'}}
                               
                                        onClick={() => {
                                   // (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - TS (navigate path included) 
                                   navigate("/hr_new_hire_request")
                                }}
                                >
                                   <u>  New Hire Request </u> 
                                </Typography>                                
                            </Box>
                            <Box mt={3}  
                            sx={{
                                display:{
                                    lg:'flex',
                                    xs:'flex',
                                    md:'flex',
                                },
                                justifyContent:{
                                    lg:'center',
                                    xs:'center',
                                    md:'center',
                                },
                            }}>
                                <Typography fontSize={'1rem'} color={'#600918'}>
                                            Request for a
                                </Typography>
                            </Box>
                            <Box  
                            sx={{
                                display:{
                                    lg:'flex',
                                    xs:'flex',
                                    md:'flex',
                                },
                                justifyContent:{
                                    lg:'center',
                                    xs:'center',
                                    md:'center',
                                },
                            }}>
                                <Typography fontSize={'1rem'} color={'#600918'}>
                                           new Hire
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                     )}
                {/* (SOP-64) Asset Management - Home Page - New hire Request -  HR Request  -  HR Request block end - - TS*/}
                                
                    
                    
                    
                    </Grid>
                </Box>                     
                </Box>
            </Box>  
            <Box  mt={5} 
            sx={{
                    display:{
                        lg:'flex',
                        xs:'flex',
                        md:'flex',
                    },
                    justifyContent:{
                        lg:'center',
                        xs:'center',
                        md:'center',
                    },
                }}>
                <Grid container spacing={3}>
                    <Grid item lg={6} md={12} xs={12}>
                        <Typography variant='h2'>
                            My Asset Request
                        </Typography>
                        <Grid container>
                            <Grid item lg={12} md={12} xs={12}>
                                <Box bgcolor={'white'} p={2} mt={3}>
                                    <Typography variant='h3'>
                                        {/* Modified by sanjana SOP-28 to change heading */}
                                        {/* My Approvals */}
                                            Request(s)
                                        {/* End of SOP-28 */}
                                    </Typography>
                                    <EnhancedTable
                                        headCells={AssetRequestHeadCell}
                                        rows={AssetRequesttableData}
                                        rowsPerPage={AssetRequestrowsPerPage}
                                        page={AssetRequestpage}
                                        handleChangePage={AssetRequesthandleChangePage}
                                        handleChangeRowsPerPage={AssetRequesthandleChangeRowsPerPage}
                                        handleRequestSort={handleAssetRequestIncidentRequestSort}
                                        order={AssetRequestorder}
                                        orderBy={AssetRequestorderBy}
                                        actionFunction={AssetRequestactionFunction}
                                        isView={false}
                                        isDelete={false}
                                        isEdit={false}
                                        total={AssetRequestcount}
                                        isSearch={false}
                                        HoverColor={'#E7F1FB'}
                                        />
                                </Box>                                
                            </Grid>
                            {/* <Grid item lg={12} md={12} xs={12}>
                                <Box bgcolor={'white'} p={2} mt={3}>
                                    <Typography variant='h3'>
                                       My Properties 
                                     </Typography>
                                    <EnhancedTable
                                        headCells={MyPropertyHeadCell}
                                        rows={UserAssettableData}
                                        rowsPerPage={UserAssetrowsPerPage}
                                        page={UserAssetpage}
                                        handleChangePage={UserAssethandleChangePage}
                                        handleChangeRowsPerPage={UserAssethandleChangeRowsPerPage}
                                        handleRequestSort={handleUserAssetSort}
                                        order={UserAssetorder}
                                        orderBy={UserAssetorderBy}
                                        actionFunction={UserAssetactionFunction}
                                        isView={false}
                                        isDelete={false}
                                        isEdit={false}
                                        total={UserAssetcount}
                                        isSearch={false}
                                        HoverColor={'#E7F1FB'}
                                        /> 
                                {/* </Box>                                 
                            {/* </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid item lg={6} md={12} xs={12}>
                        <Grid container>
                            <Grid item lg={12} md={12} xs={12}>
                                <Typography variant='h2'>
                                    My Tickets
                                </Typography>
                                <Box bgcolor={'white'} mt={3} p={2} >
                                    <Typography variant='h3'>
                                        Incident(s)
                                    </Typography>
                                        <EnhancedTable
                                            headCells={TicketTypeIncidentHeadCell}
                                            rows={TicketTypeIncidenttableData}
                                            rowsPerPage={TicketTypeIncidentrowsPerPage}
                                            page={TicketTypeIncidentpage}
                                            handleChangePage={TicketTypeIncidenthandleChangePage}
                                            handleChangeRowsPerPage={TicketTypeIncidenthandleChangeRowsPerPage}
                                            handleRequestSort={handleTicketTypeIncidentRequestSort}
                                            order={TicketTypeIncidentorder}
                                            orderBy={TicketTypeIncidentorderBy}
                                            actionFunction={TicketTypeIncidentactionFunction}
                                            isView={true}
                                            isDelete={false}
                                            isEdit={false}
                                            total={TicketTypeIncidentcount}
                                            isSearch={false}
                                            HoverColor={'#E7F1FB'}
                                            />
                                </Box>
                            </Grid>
                        {/* //SOP-75 feedback points L1 Commented by PCS [to remove request grid from ticket grid]*/}
                            {/* <Grid item lg={12} md={12} xs={12}>
                                <Box bgcolor={'white'} mt={3} p={2} >
                                    <Typography variant='h3'>
                                       Request(s)
                                         
                                    </Typography>
                                    <EnhancedTable
                                        headCells={TicketTypeRequestHeadCell}
                                        rows={TicketTypeRequesttableData}
                                        rowsPerPage={TicketTypeRequestrowsPerPage}
                                        page={TicketTypeRequestpage}
                                        handleChangePage={TicketTypeRequesthandleChangePage}
                                        handleChangeRowsPerPage={TicketTypeRequesthandleChangeRowsPerPage}
                                        handleRequestSort={handleTicketTypeRequestRequestSort}
                                        order={AssetRequestorder}
                                        orderBy={AssetRequestorderBy}
                                        actionFunction={TicketTypeRequestactionFunction}
                                        isView={true}
                                        isDelete={false}
                                        isEdit={false}
                                        total={TicketTypeRequestcount}
                                        isSearch={false}
                                        HoverColor={'#E7F1FB'}
                                    />
                                </Box> 
                            </Grid> */}
                        {/* //EOF SOP-75 feedback points L1 commented by PCS */}
                        </Grid>
                    </Grid>
                         {/* //SOP-75 feedback points L1 included by PCS [increasing width of My asset grid]*/}

                               <Grid item lg={12} md={12} xs={12}>
    
                                <Box bgcolor={'white'} p={2} mt={3}>
                                    <Typography variant='h3'>
                                        {/* //SOP-75 feedback points L1 modified by PCS [renaming]*/}
                                        {/* My Properties */}
                                        My Assets
                                        {/* //EOF SOP-75 feedback points L1 modified by PCS [renaming]*/}
                                        
                                    </Typography>
                                    <EnhancedTable
                                        headCells={MyPropertyHeadCell}
                                        rows={UserAssettableData}
                                        rowsPerPage={UserAssetrowsPerPage}
                                        page={UserAssetpage}
                                        handleChangePage={UserAssethandleChangePage}
                                        handleChangeRowsPerPage={UserAssethandleChangeRowsPerPage}
                                        handleRequestSort={handleUserAssetSort}
                                        order={UserAssetorder}
                                        orderBy={UserAssetorderBy}
                                        actionFunction={UserAssetactionFunction}
                                        isView={false}
                                        isDelete={false}
                                        isEdit={false}
                                        total={UserAssetcount}
                                        isSearch={false}
                                        HoverColor={'#E7F1FB'}
                                        />
                                </Box>                                
                            
                                </Grid>
                    {/* //EOF SOP-75 feedback points L1 [increasing width of My asset grid]*/}



                       {/* (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - HR Grid - start */}
                       {/* Grid display who have user rights */}
                        {isHRRequestVisible && (
                            <Grid item lg={12} md={12} xs={12}>
                                <Typography variant='h2'>
                                {/* //SOP-75 feedback points L1 modified by PCS [renaming] */}
                                    {/* HR Hire Request */}
                                       Hire Request
                                {/* //EOF SOP-75 feedback points L1 modified by PCS [renaming] */}

                                </Typography>
                                <Grid container>
                                    <Grid item lg={12} md={12} xs={12}>
                                        <Box bgcolor={'white'} p={2} mt={3}>
                                            <Typography variant='h3'>
                                             {/* My Hire Request (s) */}
                                            </Typography>
                                            <EnhancedTable
                                                headCells={HRHireRequestHeadCell}
                                                rows={HRHireRequesttableData}
                                                rowsPerPage={HRHireRequestrowsPerPage}
                                                page={HRHireRequestpage}
                                                handleChangePage={HRHireRequesthandleChangePage}
                                                handleChangeRowsPerPage={HRHireRequesthandleChangeRowsPerPage}
                                                handleRequestSort={handleHRHireRequestIncidentRequestSort}
                                                order={HRHireRequestorder}
                                                orderBy={HRHireRequestorderBy}
                                                actionFunction={HRHireRequestactionFunction}
                                                isView={true}
                                                isDelete={false}
                                                isEdit={false}
                                                total={HRHireRequestcount}
                                                isSearch={false}
                                                HoverColor={'#E7F1FB'}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {/* (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - HR Grid - end */}

                </Grid>
                </Box> 

                {/* Included by sanjana SOP-26  to call raise a ticket page*/}
                    {/* Add Ticket dialog box */}
                    <Box>
                    
                        {/* //SOP-49 - TS - commented - start
                            // Asset Management-Ticketing-Create Page */}
                        
                            {/* <Dialog  open={AddTicketOpen}  >
                                <RaiseATicketPage 
                                handleAddTicketClose={handleAddTicketClose}
                                getTicketlistsearch={getTicketTypeIncidentList} 
                                getTicketTypeRequestList={getTicketTypeRequestList}
                                />
                            </Dialog> */}
                           {/* <RaiseATicketPage/> */}
                        
                        {/* //SOP-49 - TS - commented - end */}
                    
                    </Box>
                    {/* End of Add Ticket dialog box */}


                
                {/* SOP-55 - TS  commented - start*/}
                {/* // Asset Management-Ticketing-View and Edit Page */}
                    {/* Edit Ticket dialog box */}
                    {/* <Box>
                        <Dialog  open={EditTicketOpen} >
                            <EditRaiseATicketPage 
                            handleEditTicketClose={handleEditTicketClose}
                            getTicketlistsearch={getTicketTypeIncidentList} 
                            getTicketTypeRequestList={getTicketTypeRequestList}
                            TicketId={EditTicketId}  
                            />
                        </Dialog>
                    </Box>
                    {/* End of Edit Ticket */}

                    {/* View Ticket dialog box */}
                     {/* <Box>
                        <Dialog  open={ViewTicketOpen}  >
                            <ViewRaiseATicketPage 
                                handleViewTicketClose={handleViewTicketClose}
                                handleEditTicketOpen={handleEditTicketOpen} TicketId={ViewTicketId} 
                            />
                        </Dialog>
                </Box> */}
                
                {/* SOP-55 - TS  commented - end*/}
                
            {/* End of View Ticket */}
                {/* End of SOP-26 */}
        
        </Box>             
    </MainContainerWrapper>
    )
}

export default HomePage;