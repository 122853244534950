// Included by sanjana SOP-30 to add menu items for end use
import GridViewIcon from "@mui/icons-material/GridView";
import PeopleIcon from '@mui/icons-material/People';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BloodtypeIcon from '@mui/icons-material/Bloodtype';
import WcIcon from '@mui/icons-material/Wc';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import WheelchairPickupIcon from '@mui/icons-material/WheelchairPickup';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { BarChart, Business } from "@mui/icons-material";
import HomeIcon from '@mui/icons-material/Home';
import { PiTicketBold } from "react-icons/pi";
import { HiMiniUserCircle } from "react-icons/hi2";
import { BiSolidLayer } from "react-icons/bi";
import { MdDashboard } from "react-icons/md";
import { IoHome } from "react-icons/io5";
import { FaUsers } from "react-icons/fa";
import { ImOffice } from "react-icons/im";
// ==============================|| DASHBOARD MENU ITEMS ||============================== //
const enduser = {
  id: "dashboard",
  title: "Dashboard",
  type: "group",
  children: [
    { 
      id: "default",
      title: "Home",
      type: "item",
      url: "/home",
      icon: IoHome,
      breadcrumbs: false,
    },
    {
      id: "default",
      title: "Dashboard",
      type: "item",
      url: "/dashboard",
      icon: MdDashboard,
      breadcrumbs: false,
    }
  ],

};

export default enduser;

// end of SOP-30