//SOP-36 - TS
//Dashboard - Asset - Metrics
//Categories Page
import { PieChart} from '@mui/x-charts/PieChart';
import React, { useState, useEffect } from "react";
import { commonService } from "../../atic-common-helpers/helpers/common.service";

// interface for the category count data
interface PieValueType {
id: number;
value: number;
label: string;
}

const  Categories=()=> {
   const [CompanyId, setCompanyId] =useState('')
  const [CreatedBy, setCreatedBy] =useState('')
  const [categoryCounts, setCategoryCounts] = useState<PieValueType[]>([]);
  // console.log("categoryCounts",categoryCounts)
  
  useEffect(() => 
  {
      const DashBoardData = async () => {
        try {
        //Global data
        const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
        const GlobalData = JSON.parse(GlobalDataFromSession);
        setCompanyId(GlobalData.GlobalCompanyId);
        setCreatedBy(GlobalData.GlobalUserId);
  
        //CategoriesPayload
        const DashBoardCategoriesPayload = {
          companyid: GlobalData.GlobalCompanyId,
        };
  
        //API    
        const response = await commonService
          .postService(`${process.env.REACT_APP_BACKEND_URL}/dashboardController/getCategoriesCount`, DashBoardCategoriesPayload);
            // console.log("response",response)
            // checks array 
         if (Array.isArray(response.data.data)) {
            const data: PieValueType[] = response.data.data.map((item: any, index: number) => ({
              id: index,
              value: item.subcategory_count, 
              //SOP-77 feedback points  Modified By PCS [to include count]
              // label: item.category_name 
              label: `${item.category_name} (${item.subcategory_count})` // Adding count in brackets
              //EOF SOP-77 feedback points  Modified By PCS
            }));
            // console.log("Data:", data); 

            

          setCategoryCounts(data);
        } else {
          console.error("Response", response.data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    DashBoardData();
  }, []);

 return (
  <PieChart
  series={[
    {
      data: categoryCounts,
      
    }
    
  ]}
     width={400}
     height={200}
  
     sx={{
       "& text": {
         fontSize: '10px',          
      },
      padding: 0.80,  // SOP-77 feedback points Included by PCS Adjust padding 
      
    }}
   />

  )}
export default Categories





