// Included by sanjana SOP-46
import { useState,useRef,useEffect, useCallback } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import MainContainerWrapper from '../../atic-common-helpers/layout/wrappers/MainContainerWrapper';
import requestAsset from '../../asset/images/requestAsset1.png';
import raiseTicket from '../../asset/images/raiseTicket.png'
import { useNavigate } from "react-router-dom";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import { FaEdit } from "react-icons/fa";
import EmptyProfileImg from '../../asset/images/UserProfile.jpg'
import Swal from 'sweetalert2';


const UploadCompanyLogoPage = () =>{

    // UseState
    const[EmployeeId, setEmployeeId] = useState('')
    const [ProfileImg, setProfileImg]= useState<any>(null);
    const [imageSrclink, setImageSrclink] = useState<string>('');
    const [key, setKey] = useState(0);
    const [EnableImgSaveBtn, setEnableImgSaveBtn] =  useState(false)


    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    let CompanyId = GlobalData.GlobalCompanyId;
    let UserId = GlobalData.GlobalUserId;

    let GlobalEmail = GlobalData.GlobalUserEmail

        // To get company user details API as per the email 
        const ToGetCompanyUserDetails = async (UserEmail:any)=>{

            const  CompanyUserDetailsPayload = {
                UserName:UserEmail,
                Active:"Y"
            }
            //console.log("CompanyUserDetailsPayload",CompanyUserDetailsPayload)
            try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/company/getCompanyUserDetails`,CompanyUserDetailsPayload);
            //console.log(res.data.data);
            const UserData = res.data.data[0]
            console.log("UserData",UserData)
            setProfileImg(UserData.company_logo_url)
            setEmployeeId(UserData.employee_id)
            return res.data.data[0] || [];
            } catch (err) {
            console.log( "co",err); 
            } 
        
            }


    // to upload profile photo 
        const handleFileUpload = (event: any) => {
            const file = event.target?.files[0];
            //console.log("file", file)
            if (file) {
                var fileSize = file.size;
                var maxSize = 500 * 1024;
                if (fileSize > maxSize) {
                    event.preventDefault(); // Prevent form submission
                    Swal.fire({
                        icon: 'warning',
                        text: 'Image size must be less than 500KB.',
                    })
                }
                else {
                const reader = new FileReader();
                reader.onloadend = () => {
                    // 'reader.result' contains the Base64 data URL of the image
                    const imageDataUrl = reader.result;
                    setImageSrclink(String(imageDataUrl))
                    setProfileImg(imageDataUrl); // Set the state with the Base64 data URL if needed
                    setEnableImgSaveBtn(true);
                };
                reader.readAsDataURL(file);
                }
            };
            // Reset the input element by changing the key
            // by incrementing the `key` variable when clearing the image, you force React to re - render the input element, 
            // allowing you to select the same image again
            setKey(key + 1);
        }

        // To clear image
        const clearImage = useCallback(() => {
            Swal.fire({
              title: 'Delete Image',
              text: 'Are you sure you want to clear the image?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#25425F',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes',
            }).then((result) => {
              if (result.isConfirmed) {
                ToGetCompanyUserDetails(GlobalEmail);
                setEnableImgSaveBtn(false);
              }
            });
          }, [setProfileImg, setImageSrclink]); 

        //   to save the profile image by calling employee_update_photo_url function from employee controller

            const ProfileImgSave = () => {

                const trimmedDate = new Date();
                const year = trimmedDate.getFullYear();
                const month = String(trimmedDate.getMonth() + 1).padStart(2, '0');
                const date = String(trimmedDate.getDate()).padStart(2, '0');
                let  FormatedJoiningDate = `${year}-${month}-${date}`;

                const UpdateCompanyLogoPayload = {
                    CompanyId: CompanyId,
                    LastUpdateDateTime: FormatedJoiningDate,
                    CompanyLogoUrl: imageSrclink       
                };

                // // Convert payload to JSON string to get its size
                // const payloadSize = JSON.stringify(UpdateCompanyLogoPayload).length;
                // console.log("payloadSize",payloadSize , 102400)
                // // Check if payload size exceeds the backend payload limited allowed size
                // if (payloadSize > 102400) {
                //     Swal.fire({
                //         icon: 'warning',
                //         text: 'Image size is too large',
                //     })
                //     ToGetCompanyUserDetails(GlobalEmail);
                //     setEnableImgSaveBtn(false);
                //     return;
                // }

                console.log("UpdateCompanyLogoPayload", UpdateCompanyLogoPayload);

                commonService
                    .postService(`${process.env.REACT_APP_BACKEND_URL}/company/company_logo_url_update`, UpdateCompanyLogoPayload)
                    .then((res) => {
                        if (res.status === 200) {
                            Success("Profile Photo Updated successfully");
                            ToGetCompanyUserDetails(GlobalEmail);
                            setEnableImgSaveBtn(false);
                        }
                    }).catch((err) => {
                        console.error("Error:", err);
                    });
            };

        useEffect(() => { 
        ToGetCompanyUserDetails(GlobalData.GlobalUserEmail);
            }, []);

    return(
        <>
        <MainContainerWrapper headingText='Upload Company Logo'>
            <Box display={'flex'} justifyContent={'center'} >
                <Box bgcolor={'white'} width={600} borderRadius={5}>
                    <Box mt={3} mb={3}>
                        <Box height={200} mt={3} mb={3} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <img src={ProfileImg || EmptyProfileImg} 
                            alt='Profile' 
                            width={90} height={90}
                            style={{padding:3, 
                            // marginTop:'5rem',
                            boxShadow:'rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset'
                            }}/>
                            
                        </Box>
                        <Box mt={3} display={'flex'} justifyContent={'space-evenly'} >
                            <Box className="information" style={{ display: 'flex', justifyContent: 'flex-end'}}>
                                        <Box>
                                        <label htmlFor="upload_image" style={{
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                        }}>
                                                <FaEdit fontSize={'1.5rem'} style={{cursor:'pointer'}}/>
                                        </label>
                                        <input
                                            key={key}
                                            id="upload_image"
                                            accept="image/*"
                                            hidden
                                            type="file"
                                            onChange={handleFileUpload}
                                        />
                                        </Box>

                            </Box>
                        </Box>
                        {EnableImgSaveBtn?
                            <Box mt={3} mb={3} display={'flex'} justifyContent={'center'}>
                                <Button
                                sx={{
                                    background: '#25425F',
                                    border: 'none',
                                    borderRadius: '5px',
                                    fontSize:'0.7rem',
                                    color: 'white',
                                    // height: "3rem",
                                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                                    '&:hover':{
                                        backgroundColor:'#25425F',
                                        color:'white'
                                    }
                                        }}
                                        onClick={ProfileImgSave}
                                >
                                    Save
                                </Button>

                                <Button
                                sx={{
                                    background: '#25425F',
                                    marginLeft:3,
                                    border: 'none',
                                    borderRadius: '5px',
                                    fontSize:'0.7rem',
                                    color: 'white',
                                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                                    '&:hover':{
                                        backgroundColor:'#25425F',
                                        color:'white'
                                    }
                                        }}
                                        onClick={clearImage}
                                >
                                    Clear
                                </Button>
                            </Box>
                        :null}
                    </Box>

                </Box>
            </Box>

        </MainContainerWrapper>
        </>
    )

}

export default UploadCompanyLogoPage;

// End of SOP-46