
// SOP-69 - Included By PCS 

import { useState,useRef,useEffect ,useCallback } from 'react'; // Import useState hook
import React from 'react';

import { EditorState, ContentState, convertToRaw,RawDraftContentState  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html'; //TO convert to html format
import htmlToDraft from 'html-to-draftjs';

    import {
        Box, 
        Button,
        Grid,
        Accordion,
        AccordionSummary,
        AccordionDetails,
     } from '@mui/material';
    import Typography from '@mui/material/Typography';
    import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
    import axios from "axios";
    import MainContainerWrapper from '../../atic-common-helpers/layout/wrappers/MainContainerWrapper';
    import { useNavigate } from "react-router-dom";
    import { commonService } from '../../atic-common-helpers/helpers/common.service';
    import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
    import CryptoJS from 'crypto-js';
    import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
    import { useParams } from 'react-router-dom';

 const ViewKnowledgeBase =()=>{

    const { title } = useParams<{ title?: string }>(); // Ensure title is optional and can be undefined
    // console.log('title is',title)

     const navigate = useNavigate();
     const [editorState, setEditorState] = useState(EditorState.createEmpty());
     const [displayedContent, setDisplayedContent] = useState<string>('');

     const [DocumentTitle, setDocumentTitle] =  useState(''); //Initialize document Title
    //  const DocumentIdFromSession:any = sessionStorage.getItem("DocumentId") ;
   
    //To Format the content  
        const handleDisplayContent = (event:any) => {
                // console.log('click1')
                const contentState = editorState.getCurrentContent();
                const rawContent = convertToRaw(contentState);
                const htmlContent = draftToHtml(rawContent);
                setDisplayedContent(htmlContent); // Store HTML content in state
                                                      };
//To View the selected document

   const VersionNumberFromSession = sessionStorage.getItem("VersionNumber")
   const DocumentIdFromSession = sessionStorage.getItem("DocumentId")

    //  console.log('VersionNumberFromSession',VersionNumberFromSession)
    //  console.log('DocumentIdFromSession',DocumentIdFromSession)


     const ViewDocumentById =()=>
           {
                
                const payload={
                DocumentId:DocumentIdFromSession,
                VersionNumber:VersionNumberFromSession,
                CompanyId:CompanyId
                               }

                  //  console.log('vb',payload)
                commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/docpoint/get_edit_document_by_documentid`, payload)
                .then((res: any) => 
                { 
                   const DocumentData = res.data.data[0]

                    // console.log(DocumentData)
                  
                    const contentBlock = htmlToDraft(DocumentData.docpoint_content);
                                if (contentBlock) {
                                const { contentBlocks, entityMap } = contentBlock;
                                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                                setEditorState(EditorState.createWithContent(contentState));
                                }

                }).catch((error) =>  
                {  
                console.log("error",error)
                Failed(error.response.message);
                });
         }


    // Define type for errors
    type Errors = {
        DocumentTitle?:boolean;
                };

   
    //  Global data from session storage
            const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
            const GlobalData = JSON.parse(GlobalDataFromSession);
          //console.log("GlobalData",GlobalData)
            let CompanyId = GlobalData.GlobalCompanyId
        
   useEffect(() => {
                 ViewDocumentById();
                const DocumentTitleFromSession: any=sessionStorage.getItem("DocumentTitleValue")
                setDocumentTitle(DocumentTitleFromSession)
                   }, []); 

    return(
        <MainContainerWrapper 
        // headingText={DocumentTitle}
        headingText=""
          >
          <Box>
        <form noValidate>
        <Box>
        {/*Main grid starts */}        
        <Grid  container spacing={3} display={'flex'} justifyContent={'center'}>
                                 
        {/* grid starts */}
                  <Grid item lg={11.8} md={12} xs={12} >
                      {/* Content RTE Accordian */}
                        <Box sx={{marginTop:'-40px'}} >
                            <Accordion defaultExpanded sx={{
                                    borderRadius:'30px'}}>
                                <AccordionSummary
                                   sx={{marginBottom:'-3px'}}
                                    expandIcon={<ArrowDropDownIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                   <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                        <Typography fontWeight={'bold'} color={'blue'} fontSize={'1.5rem'} mb={-2} ml={2}>{DocumentTitle}</Typography> 
                                   </Box>
                                </AccordionSummary>
                                   <AccordionDetails sx={{marginBottom:5}}>
                                     <Box>
                                       {/* //Rte content starts */}
                                        <Box style={{ width: '99%',marginLeft:'1%',height:'94%'}}>
                                           <Editor
                                                editorState={editorState}
                                                onEditorStateChange={setEditorState}
                                                editorStyle={{marginLeft:'4px',height:'725px'}}
                                                readOnly={true}
                                                toolbarHidden={true}
                                               
                                             />
                                       </Box>  
                                      {/* //Rte content ends */}
                                    </Box>
                                  </AccordionDetails>
                            </Accordion>
                        </Box>
                   
                </Grid>
     {/*  grid ends */}
        </Grid>
     {/*Main grid starts */}    
       <Box>                    
            <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Button
                sx={{
                background: 'white',
                border: 'none',
                borderRadius: '5px',
                padding:{
                lg: "0 70px",
                md: "0 70",
                xs:'0 50px'

                },
                height: "3rem",
                boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                }}

                onClick={()=>{
                navigate('/knowledge_base')
              
                }} 
                >
                 Back
                </Button>
          </Box>
      </Box>

    </Box>
    </form>
    </Box>
</MainContainerWrapper>)}

export default ViewKnowledgeBase;   
{/* SOP-69 - PCS */}