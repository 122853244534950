// Included by sanjana SOP-37 
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import deleteAlert from '../../component/HOC/deleteAlert';
import dayjs from 'dayjs';
import AddTicketingServicesPage from './AddTicketingServices';
import ViewTicketingServicePage from './ViewTicketingServics';
import EditTicketingServicePage from './EditTicketingServices';


const TicketingServicesPage =()=>{

        // useSate
        let  [PendingTicketingServicesorder, setPendingTicketingServicesOrder] = React.useState<"desc" | "asc">("desc");
        let  [PendingTicketingServicesorderBy, setPendingTicketingServicesOrderBy] = React.useState("");
        let  [PendingTicketingServicestableData, setPendingTicketingServicesTableData] = React.useState([]);
        const [PendingTicketingServicespage, setPendingTicketingServicesPage] = React.useState<number>(0);
        const [PendingTicketingServicescount, setPendingTicketingServicesCount] = useState<number>(0);
        const [PendingTicketingServicesrowsPerPage, setPendingTicketingServicessetRowsPerPage] = React.useState<number>(10);
        let  [AllocatedTicketingServicesorder, setAllocatedTicketingServicesOrder] = React.useState<"desc" | "asc">("desc");
        let  [AllocatedTicketingServicesorderBy, setAllocatedTicketingServicesOrderBy] = React.useState("");
        let  [AllocatedTicketingServicestableData, setAllocatedTicketingServicesTableData] = React.useState([]);
        const [AllocatedTicketingServicespage, setAllocatedTicketingServicesPage] = React.useState<number>(0);
        const [AllocatedTicketingServicescount, setAllocatedTicketingServicesCount] = useState<number>(0);
        const [AllocatedTicketingServicesrowsPerPage, setAllocatedTicketingServicessetRowsPerPage] = React.useState<number>(10);
        const [AllocateOpen, setAllocateOpen] = useState(false);
        const [ViewOpen, setViewOpen] = useState(false);
        const [EditOpen, setEditOpen] = useState(false);
        const [TicketId, setTicketId] = useState()

            //  To open add dialouge box
    const handleAddOpen = (id:any) => {
        setAllocateOpen(true);
        setTicketId(id)
    };

    // To close add dialouge box
    const handleAddClose = () => {
    setAllocateOpen(false);
    };

    //  To open View dialouge box
    const handleViewOpen = (id:any) => {
        setViewOpen(true);
        setTicketId(id)
    };

    // To close View dialouge box
    const handleViewClose = () => {
    setViewOpen(false);
    };

    //  To open Edit dialouge box
    const handleEditOpen = (id:any) => {
        setEditOpen(true);
        setTicketId(id)
    };

    // To close Edit dialouge box
    const handleEditClose = () => {
    setEditOpen(false);
    };


        
    // Head cell of My Pending Allocation Request
    const PendingTicketingServicesHeadCell = [
        {
            id: "ticket_number",
            numeric: false,
            disablePadding: true,
            label: "Ticket #",
            isSort: true,
            isBold: true,
        },
        {
            id: "ticket_date",
            numeric: false,
            disablePadding: false,
            label: "Ticket Date",
            isSort: true,
            isBold: true,
            dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
        },
        {
            id: "ticket_issuedescription",
            numeric: false,
            disablePadding: false,
            label: "Issue Description",
            isSort: true,
            isBold: true,
            },
        {
        id: "ticketraisedbyname",
        numeric: false,
        disablePadding: false,
        label: "Employee Name",
        isSort: true,
        isBold: true,
        },
        {
            id: "ticket_allocatedate",
            numeric: false,
            disablePadding: false,
            label: "Ticket Allocated Date",
            isSort: true,
            isBold: true,
            dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
        },
        {
        id: "ticket_allocatetoname",
        numeric: false,
        disablePadding: false,
        label: "Allocated to",
        isSort: true,
        isBold: true,
        },
        {
        id: "ticket_allocateremarks",
        numeric: false,
        disablePadding: false,
        label: "Allocation Remarks",
        isSort: true,
        isBold: true,
        },
        {
        id: "Open",
        numeric: false,
        disablePadding: false,
        label: "Actions",
        isSort: false,
        },
       
        ];
    const AllocatedTicketingServicesHeadCell = [
        {
            id: "ticket_number",
            numeric: false,
            disablePadding: true,
            label: "Ticket #",
            isSort: true,
            isBold: true,
        },
        {
            id: "ticket_date",
            numeric: false,
            disablePadding: false,
            label: "Ticket Date",
            isSort: true,
            isBold: true,
            dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
        },
        {
            id: "ticket_issuedescription",
            numeric: false,
            disablePadding: false,
            label: "Issue Description",
            isSort: true,
            isBold: true,
            },
            {
            id: "ticket_allocatetoname",
            numeric: false,
            disablePadding: false,
            label: "Allocated Name",
            isSort: true,
            isBold: true,
            },
            {
            id: "ticket_allocatedate",
            numeric: false,
            disablePadding: false,
            label: "Ticket Allocated Date",
            isSort: true,
            isBold: true,
            dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
            },
            {
            id: "ticket_allocateremarks",
            numeric: false,
            disablePadding: false,
            label: "Allocation Remarks",
            isSort: true,
            isBold: true,
            },
            {
            id: "service_completedate",
            numeric: false,
            disablePadding: false,
            label: "Service Completed Date",
            isSort: true,
            isBold: true,
            dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
            },
            {
            id: "service_completionstatus_name",
            numeric: false,
            disablePadding: false,
            label: "Service Completion Status",
            isSort: true,
            isBold: true,
            },
            {
            id: "service_remarks",
            numeric: false,
            disablePadding: false,
            label: "Services Remarks",
            isSort: true,
            isBold: true,
            },
            {
            id: "action",
            numeric: false,
            disablePadding: false,
            label: "Actions",
            isSort: false,
            },
        
        ];

    //  Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    //console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId
    const UserId = GlobalData.GlobalUserId;
    // Included by sanjana SOP-40 to get Employee id
    let EmployeeId = GlobalData.GlobalEmployeeId
    // End of SOP-40

    // To get pending ticket list
    const getPendingTicketingServicesList = () => 
        {       
            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/get_pending_ticket_service_list`, {
                SortColumnName: PendingTicketingServicesorderBy,
                SortColumnDirection: PendingTicketingServicesorder,
                StartIndex: PendingTicketingServicespage + 1,
                PageSize: PendingTicketingServicesrowsPerPage,
                CompanyId:CompanyId,
                // Modified by sanjana SOP-40
                // UserId:UserId
                UserId:EmployeeId,
                // End of SOP-40
            })
            .then((res: any) => 
            {
                console.log("res P",res)
            const formattedTableData= res.data.data.map((item:any)=>{
            return {...item,
                id:item.ticketid,
                ticket_date: item.ticket_date ? dayjs(item.ticket_date).format("DD-MM-YYYY") : "",
                ticket_allocatedate: item.ticket_allocatedate ? dayjs(item.ticket_allocatedate).format("DD-MM-YYYY") : "",
            }
            }) 
            //console.log("formattedTableData",formattedTableData)
            
            setPendingTicketingServicesTableData(formattedTableData);
            if(res.data.data[0]===undefined)
            {
                setPendingTicketingServicesCount(0);
            }
            else
            {
                setPendingTicketingServicesCount(res.data.data[0].total_count);
            }
            })
        .catch((error) => 
            {
            if (error.response.status === 404)
            {
                setPendingTicketingServicesTableData([]);
            }
            Failed(error.response.message);
            });
        };

    // To get Allocated ticket list
    const getAllocatedTicketingServicesList = () => 
        {       
            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/get_allocated_ticket_service_list`, {
                SortColumnName: AllocatedTicketingServicesorderBy,
                SortColumnDirection: AllocatedTicketingServicesorder,
                StartIndex: AllocatedTicketingServicespage + 1,
                PageSize: AllocatedTicketingServicesrowsPerPage,
                CompanyId:CompanyId,
                // Modified by sanjana SOP-40
                // UserId:UserId
                UserId:EmployeeId,
                // End of SOP-40
            })
            .then((res: any) => 
            {
                console.log("res A",res)
            const formattedTableData= res.data.data.map((item:any)=>{
            return {...item,
                id:item.ticketid,
                ticket_date: item.ticket_date ? dayjs(item.ticket_date).format("DD-MM-YYYY") : "",
                ticket_allocatedate: item.ticket_allocatedate ? dayjs(item.ticket_allocatedate).format("DD-MM-YYYY") : "",
                service_completedate: item.service_completedate ? dayjs(item.service_completedate).format("DD-MM-YYYY") : "",
            }
            }) 
            //console.log("formattedTableData",formattedTableData)
            
            setAllocatedTicketingServicesTableData(formattedTableData);
            if(res.data.data[0]===undefined)
            {
                setAllocatedTicketingServicesCount(0);
            }
            else
            {
                setAllocatedTicketingServicesCount(res.data.data[0].total_count);
            }
            })
        .catch((error) => 
            {
            if (error.response.status === 404)
            {
                setAllocatedTicketingServicesTableData([]);
            }
            Failed(error.response.message);
            });
        };

    
    
    // Sort Handler
    const handlePendingTicketingServicesIncidentRequestSort = (event: any, property: any) =>
        {
        const isAsc = PendingTicketingServicesorderBy === property && PendingTicketingServicesorder === "asc";
        PendingTicketingServicesorder = isAsc ? "desc" : "asc";
        setPendingTicketingServicesOrder(PendingTicketingServicesorder);
        PendingTicketingServicesorderBy = property;
        setPendingTicketingServicesOrderBy(PendingTicketingServicesorderBy);
        };
    
    
    // Action function of table icons
    const PendingTicketingServicesactionFunction = (id: string, actionText: string) => 
    {
        if (actionText === "Open") {
            handleAddOpen(id)
        };
        // if (actionText === "edit") navigate(`/edit_nationality/${id}`);
    };


    // Pagination Handler of table
    const PendingTicketingServiceshandleChangePage = (event: unknown, newPage: number) => {
        setPendingTicketingServicesPage(newPage);
    };
    
    // Rows Per page Handler
    const PendingTicketingServiceshandleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
        ) => {
            setPendingTicketingServicessetRowsPerPage(+event.target.value);
            setPendingTicketingServicesPage(0);
    };


        // Sort Handler
        const handleAllocatedTicketingServicesIncidentRequestSort = (event: any, property: any) =>
            {
            const isAsc = AllocatedTicketingServicesorderBy === property && AllocatedTicketingServicesorder === "asc";
            AllocatedTicketingServicesorder = isAsc ? "desc" : "asc";
            setAllocatedTicketingServicesOrder(AllocatedTicketingServicesorder);
            AllocatedTicketingServicesorderBy = property;
            setAllocatedTicketingServicesOrderBy(AllocatedTicketingServicesorderBy);
            };
        
        
        // Action function of table icons
        const AllocatedTicketingServicesactionFunction = (id: string, actionText: string) => 
        {
            if (actionText === "view") {
                handleViewOpen(id)
            };
        };
    
    
        // Pagination Handler of table
        const AllocatedTicketingServiceshandleChangePage = (event: unknown, newPage: number) => {
            setAllocatedTicketingServicesPage(newPage);
        };
        
        // Rows Per page Handler
        const AllocatedTicketingServiceshandleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
            ) => {
                setAllocatedTicketingServicessetRowsPerPage(+event.target.value);
                setAllocatedTicketingServicesPage(0);
        };
    

    useEffect(() => {
        getPendingTicketingServicesList();
        getAllocatedTicketingServicesList();
    }, [PendingTicketingServicesrowsPerPage,PendingTicketingServicespage,PendingTicketingServicesorderBy,PendingTicketingServicesorder,
        AllocatedTicketingServicesrowsPerPage,AllocatedTicketingServicespage,AllocatedTicketingServicesorderBy,AllocatedTicketingServicesorder,
    ]); 

    return(
        <>
         {/* //SOP-75 feedback points L1 modified by PCS [renaming]*/}
            {/* <MainContainerWrapper headingText='Pending Tickets'> */}
            <MainContainerWrapper headingText='Open  Tickets'>
         {/* //EOF SOP-75 feedback points L1 modified by PCS */}
            <Box>
                <Box>
                    <EnhancedTable
                    headCells={PendingTicketingServicesHeadCell}
                    rows={PendingTicketingServicestableData}
                    rowsPerPage={PendingTicketingServicesrowsPerPage}
                    page={PendingTicketingServicespage}
                    handleChangePage={PendingTicketingServiceshandleChangePage}
                    handleChangeRowsPerPage={PendingTicketingServiceshandleChangeRowsPerPage}
                    handleRequestSort={handlePendingTicketingServicesIncidentRequestSort}
                    order={PendingTicketingServicesorder}
                    orderBy={PendingTicketingServicesorderBy}
                    actionFunction={PendingTicketingServicesactionFunction}
                    isView={false}
                    isDelete={false}
                    isEdit={false}
                    total={PendingTicketingServicescount}
                    isSearch={false}
                    HoverColor={'#E7F1FB'}
                    />
                </Box>

                <Box mt={5}>
                <Typography variant='h1'>
                    Completed Tickets
                </Typography>
                <Box mt={3}>
                    <EnhancedTable
                    headCells={AllocatedTicketingServicesHeadCell}
                    rows={AllocatedTicketingServicestableData}
                    rowsPerPage={AllocatedTicketingServicesrowsPerPage}
                    page={AllocatedTicketingServicespage}
                    handleChangePage={AllocatedTicketingServiceshandleChangePage}
                    handleChangeRowsPerPage={AllocatedTicketingServiceshandleChangeRowsPerPage}
                    handleRequestSort={handleAllocatedTicketingServicesIncidentRequestSort}
                    order={AllocatedTicketingServicesorder}
                    orderBy={AllocatedTicketingServicesorderBy}
                    actionFunction={AllocatedTicketingServicesactionFunction}
                    isView={true}
                    isDelete={false}
                    isEdit={false}
                    total={AllocatedTicketingServicescount}
                    isSearch={false}
                    HoverColor={'#E7F1FB'}
                    />   
                    </Box>
                </Box>

                <Box>
                    <Box>
                        <Dialog  open={AllocateOpen}  >
                            <AddTicketingServicesPage 
                            handleAddClose={handleAddClose}
                            getPendingTicketingServicesList={getPendingTicketingServicesList}
                            TicketId={TicketId}
                            getAllocatedTicketingServicesList={getAllocatedTicketingServicesList}
                            />
                        </Dialog>
                    </Box>

                    <Box>
                        <Dialog  open={ViewOpen}  >
                            <ViewTicketingServicePage
                            handleViewClose={handleViewClose}
                            handleEditOpen={handleEditOpen}
                            TicketId={TicketId}
                            />
                        </Dialog>
                    </Box>

                    <Box>
                        <Dialog  open={EditOpen}  >
                            <EditTicketingServicePage 
                            handleEditClose={handleEditClose}
                            getPendingTicketingServicesList={getPendingTicketingServicesList}
                            TicketId={TicketId}
                            getAllocatedTicketingServicesList={getAllocatedTicketingServicesList}
                            />
                        </Dialog>
                    </Box>

                </Box>
            </Box>
            </MainContainerWrapper>
        
        </>
    )

}

export default TicketingServicesPage;

// End of SOP-37