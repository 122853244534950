import GridViewIcon from "@mui/icons-material/GridView";
import PeopleIcon from '@mui/icons-material/People';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BloodtypeIcon from '@mui/icons-material/Bloodtype';
import WcIcon from '@mui/icons-material/Wc';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import WheelchairPickupIcon from '@mui/icons-material/WheelchairPickup';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { BarChart, Business } from "@mui/icons-material";
import HomeIcon from '@mui/icons-material/Home';
import { PiTicketBold } from "react-icons/pi";
import { HiMiniUserCircle } from "react-icons/hi2";
import { BiSolidLayer } from "react-icons/bi";
import { MdDashboard } from "react-icons/md";
import { IoHome } from "react-icons/io5";
import { FaUsers } from "react-icons/fa";
import { ImOffice } from "react-icons/im";
// Included by sanjana SOP-29
import { PiCheckCircleFill } from "react-icons/pi";
// End of SOP-29
// ==============================|| DASHBOARD MENU ITEMS ||============================== //
const admin = {
  id: "dashboard",
  title: "Dashboard",
  type: "group",
  children: [
    { 
      id: "default",
      title: "Home",
      type: "item",
      url: "/home",
      icon: IoHome,
      breadcrumbs: false,
    },
    {
      id: "default",
      title: "Dashboard",
      type: "item",
      url: "/dashboard",
      icon: MdDashboard,
      breadcrumbs: false,
    },
    {
      id: "default",
      title: "Employees",
      type: "item",
      url: "/employee",
      icon: FaUsers,
      breadcrumbs: false,
    },
    {
      id: "default",
      title: "Assets",
      type: "item",
      url: "/assets",
      icon: BiSolidLayer,
      breadcrumbs: false,
    },
  // Modified by sanjan SOP-34 
    // {
    //   id: "default",
    //   title: "Ticketing",
    //   type: "item",
    //   icon: PiTicketBold,
    //   breadcrumbs: false,
    //   url: "/ticketing",
    // },
  // End of SOP-34
    {
      id: "default",
      title: "User Plan",
      type: "item",
      icon: HiMiniUserCircle,
      breadcrumbs: false,
      url: "/userplan",
    },
    // Included by sanjana SOP-15 to add backoffice 
    {
      id: "default",
      title: "Back Office",
      type: "item",
      icon: ImOffice,
      breadcrumbs: false,
      url: "/backoffice",
    }
    // End of SOP-15

    // Included by sanjana SOP-29 to add myapprovals 
    ,{
      id: "default",
      title: "My Approvals",
      type: "item",
      icon: PiCheckCircleFill,
      breadcrumbs: false,
      url: "/myapprovals",
    }
    // End of SOP-29
    // Included by sanjana SOP-33 to add Allocation in side menu for admin
    ,{
      id: "default",
      title: "Allocations",
      type: "item",
      icon: PiCheckCircleFill,
      breadcrumbs: false,
      url: "/allocations",
    }
    // End of SOP-33

    // Included by sanjana SOP-34 to add ticketing in side menu for it admin
    ,{
      id: "default",
      title: "Ticketing",
      type: "item",
      icon: PiTicketBold,
      breadcrumbs: false,
      url: "/ticketlist",
    }
    // End of SOP-34

    // Included by sanjana SOP-37 to add Services in side menu for it admin
    ,{
      id: "default",
      title: "Ticketing Services",
      type: "item",
      icon: PiTicketBold,
      breadcrumbs: false,
      url: "/ticketing_services",
    }
    // End of SOP-37
    
  
  ],

};

export default admin;
