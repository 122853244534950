// SOP-61 Included By PCS

// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";


// Page start
const AddDocumentTitle = 
// To get handleAddDocumentTitleClose &  from Docpoint page
    ({
       handleAddDocumentTitleClose,
        
    }: {
        handleAddDocumentTitleClose: (event: React.MouseEvent) => void,
       
    }) =>{

    // use navigate and use state declaration
    const navigate = useNavigate();

    const [TitleError, setTitleError]=useState(false)
    const [TitleValue, setTitleValue]=useState('')
    const [TitleErrorMessage, setTitleErrorMessage]=useState('')
    const [CompanyId, setCompanyId] =useState('')
    const [TitleValueEndTrim, setTitleValueEndTrim]=useState('')
 

    //  Handle Title while typing Title and validations
    const handleTitleChange = (event:any) => 
     {
                const trimmedTitleValue = event.target.value.trim();
                setTitleValue(event.target.value);
                setTitleValueEndTrim(trimmedTitleValue);
                

                if (trimmedTitleValue === '') {
                setTitleError(true);
                setTitleErrorMessage("Title is required");
                } 
                else if( trimmedTitleValue.length >255 ){
                setTitleError(true);
                setTitleErrorMessage("Max 255 characters");
                }
                else {
                setTitleError(false);
                setTitleErrorMessage("");
                }
     };


    // To render handle close function to close dialouge box
            
    const handleClose = (event: React.MouseEvent) => {
                if (handleAddDocumentTitleClose && typeof handleAddDocumentTitleClose === 'function') {
                                handleAddDocumentTitleClose(event);
                }
                                                      };
//Exist Documnet Title name API
const GetDocumentTitleNameExist = async () => {   
        
    const  ExistTitleNamePayload = {
        ExistDocumentTitleName:TitleValueEndTrim,
        CompanyId:CompanyId
                                  }

    console.log('payload',ExistTitleNamePayload)
    try {
       const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/docpoint/get_docpoint_title_name_exists`,ExistTitleNamePayload);
       return res.data.data || [];
        } 
    catch (err) {
    console.log( "co",err); 
                 }
                                       };
 

  // To add Title

   // Define a type for errors
                type Errors = {
                title?:boolean;
                };

     const HandleAddTitle = async (event:any)=>
       {
           event.preventDefault();
           let errors: Errors = {};


           const DocumentTitle = await GetDocumentTitleNameExist();
                if (DocumentTitle.length > 0) {
                    setTitleError(true);
                    setTitleErrorMessage("Title already exists");
                    errors.title = true;
                    return;
                }
 
                const trimmedTitleValue = TitleValue.trim();
                if (trimmedTitleValue === '') {
                errors.title = true;
                setTitleErrorMessage("Title is required");
                } else if (trimmedTitleValue.length > 255) {
                errors.title = true;
                setTitleErrorMessage("Max 255 characters");
                }

          
        if (Object.keys(errors).length > 0) {
            
           // If there are errors, update the state with all the errors
            setTitleError(errors.title || false);
            return;                         }

         else{
                // Navigate to CreateDraft with TitleValue as parameter
                navigate(`/create_draft/${encodeURIComponent(TitleValue)}`);
                handleClose(event);
             }
                

        const todayDate = new Date();
        const Year = todayDate.getFullYear();
        const Month = (todayDate.getMonth() + 1).toString().padStart(2, '0');
        const Day = todayDate.getDate().toString().padStart(2, '0');
        const FormattedDate = `${Year}-${Month}-${Day}`;
     
    }
    

    // UseEffect Declaration
        useEffect(() => {
            const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
            const GlobalData = JSON.parse(GlobalDataFromSession);
                setCompanyId(GlobalData.GlobalCompanyId)
                //setCreatedBy(GlobalData.GlobalUserId)
        }, []);

 
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
     };


    return(
        <>
            <Box>
                <Box display={'flex'} justifyContent={'center'}>
                    <Box bgcolor={'white'} borderRadius={3}
                    sx={{
                        width:{
                            lg:900,
                            md:600,
                            xs:700
        
                        }}}
                    >
                    <Box p={3}>
                        <Typography variant='h1'>
                        New Report Title Name
                        </Typography>
                    </Box>
                        <Box p={3}>
                            <form  noValidate>
                                <Box>
                                <TextField
                                    onKeyDown={handleKeyDown} 
                                    type='text'
                                    autoFocus
                                    variant="standard"
                                    label='Report Title Name'
                                    name='title'
                                    style={{ margin: '0 10px' }}
                                    fullWidth
                                    required
                                    error={TitleError}
                                    onChange={handleTitleChange}
                                    value={TitleValue}
                                    helperText={TitleErrorMessage}
                                /> 
                                </Box>
                            <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Button
                                    sx={{
                                        background: 'white',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}

                                    onClick={handleClose} 
                                >
                                    Back
                                </Button>

                                <Button
                                    sx={{
                                        background: '#25425F',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        "&:hover":{
                                            backgroundColor:'#25425F',
                                            color:'white',
                                        },
                                        color: 'white',
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}
                                    type='submit'
                                    onClick={(event)=>{
                                        //HandleAddCategory(event);
                                        HandleAddTitle(event);
                                    }}
                                >
                                    Create
                                </Button>
                                </Box>
                            </form>
                        </Box>

                    </Box>
                </Box>
            </Box>
        </>
    )

}

export default AddDocumentTitle;

// EOF SOP-61