// Included by sanjana SOP-13

// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import MainContainerWrapper from '../../atic-common-helpers/layout/wrappers/MainContainerWrapper';
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Success } from '../../atic-common-helpers/helpers/toast.helper';

//SOP-13 -Included By PCS[Testing feedback Correction- to include eye icone]
import { Visibility, VisibilityOff } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
//EOF SOP-13 -Included By PCS[Testing feedback Correction- to include eye icon] 



// Page start
const ChangePasswordPage = () =>{

    const navigate = useNavigate();

  

    //declaration of Use State
    const [UserNameValue, setUserNameValue]=useState('')
    const [OldPasswordError, setOldPasswordError]=useState(false)
    const [OldPasswordValue, setOldPasswordValue]=useState('')
    const [OldPasswordErrorMessage, setOldPasswordErrorMessage]=useState('') 
    const [NewPasswordError, setNewPasswordError]=useState(false)
    const [NewPasswordValue, setNewPasswordValue]=useState('')
    const [NewPasswordErrorMessage, setNewPasswordErrorMessage]=useState('')      
    const [ConfirmPasswordError, setConfirmPasswordError]=useState(false)
    const [ConfirmPasswordValue, setConfirmPasswordValue]=useState('')
    const [ConfirmPasswordErrorMessage, setConfirmPasswordErrorMessage]=useState('')
    const [Email, setEmail] =useState('')
    const [CompanyId, setCompanyId] =useState('')

    //SOP-13 Inlcuded By PCS Testing feedback corrections [to enable password change]
        const [ActiveStatus, setActiveStatus] =useState('')
        const [UserId, setUserId] =useState('')
        const [EmployeeId, setEmployeeId] =useState('') 
    //EOF SOP-13 Inlcuded By PCS Testing feedback corrections [to enable password change]
       
    //SOP-13 Inlcuded By PCS[Testing feedback corrections- to inlcude eye icon]
        const [showPasswordOldpassword, setShowPasswordOldpassword] = useState(false);
        const [showPasswordNewpassword, setShowPasswordNewpassword] = useState(false);
        const [showPasswordConfirmpassword, setShowPasswordConfirmpassword] = useState(false);
        const handleToggleOldPasswordVisibility = () => {setShowPasswordOldpassword((prev) => !prev);};
        const handleToggleNewPasswordVisibility = () => {setShowPasswordNewpassword((prev) => !prev);};
        const handleToggleConfirmPasswordVisibility = () => {setShowPasswordConfirmpassword((prev) => !prev);};
    //EOF SOP-13 Inlcuded By PCS[Testing feedback corrections- to inlcude eye icon]


    // Handle Old Password while typing old password
    const handleOldPasswordChange = (event:any) => {
        const trimmedOldPasswordValue = event.target.value.trim();
        setOldPasswordValue(event.target.value);
  
  
        if (trimmedOldPasswordValue === '') {
            setOldPasswordError(true);
            setOldPasswordErrorMessage("Old Password is required");
        } 
        else if( trimmedOldPasswordValue.length >12 ){
            setOldPasswordError(true);
            setOldPasswordErrorMessage("Max 12 characters");
        }
          else {
            setOldPasswordError(false);
            setOldPasswordErrorMessage("");
        }
      };

    // Handle Password while typing old password
    const handleNewPasswordChange = (event:any) => {
        const trimmedPasswordValue = event.target.value.trim();
        setNewPasswordValue(event.target.value);
  
        if (ConfirmPasswordValue !== '') {
          if (trimmedPasswordValue !== ConfirmPasswordValue) {
            setConfirmPasswordError(true);
            //SOP-13 Modified By PCS[Testing feedback corrections]
            //setConfirmPasswordErrorMessage("Passwords must match");
             setConfirmPasswordErrorMessage("Confirm password is not matching");
            //EOF SOP-13 Modified By PCS[Testing feedback corrections]
          } else {
            setConfirmPasswordError(false);
            setConfirmPasswordErrorMessage("");
          }
        }
  
        if (trimmedPasswordValue === '') {
            setNewPasswordError(true);
            setNewPasswordErrorMessage("New Password is required");
        } 
        else if( trimmedPasswordValue.length >12 ){
            setNewPasswordError(true);
            setNewPasswordErrorMessage("Max 12 characters");
        }
          else {
            setNewPasswordError(false);
            setNewPasswordErrorMessage("");
        }
      };

    // Handle Confirm Password while typing confirm password
    const handleConfirmPasswordChange = (event:any) => {
        const trimmedConfirmPasswordValue = event.target.value.trim();
        setConfirmPasswordValue(event.target.value);
        if (trimmedConfirmPasswordValue === '') {
            setConfirmPasswordError(true);
            setConfirmPasswordErrorMessage("Confirm Password is required");
        } 
        else if( trimmedConfirmPasswordValue.length >12 ){
            setConfirmPasswordError(true);
            setConfirmPasswordErrorMessage("Max 12 characters");
        }

        //SOP-13 Commented By PCS Testing Feedback corrrections[to stop validation onChange]
        // else if( NewPasswordValue !== trimmedConfirmPasswordValue){
        //   setConfirmPasswordError(true);
        //   setConfirmPasswordErrorMessage("Passwords must match");
        //  }
        //EOF SOP-13 Commented By PCS Testing Feedback corrrections [to stop validation onChange]
        
        else {
            setConfirmPasswordError(false);
            setConfirmPasswordErrorMessage("");
        }
      };


    // To get company user details API as per the email 
      const ToGetCompanyUserDetails = async ()=>{

        const  CompanyUserDetailsPayload = {
            //SOP-13 Modified By PCS[testing feedback corrections- to get company details]
            // Email:Email 
             UserName:Email,
             Active:ActiveStatus,
            //EOF SOP-13 Modified By PCS[testing feedback corrections- to get company details]
        }
        //console.log("CompanyUserDetailsPayload...",CompanyUserDetailsPayload)
        try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/company/getCompanyUserDetails`,CompanyUserDetailsPayload);
        //console.log('employee id is..',res.data.data[0].employee_id);

        //SOP-13 Inlcuded By PCS Testing feedback corrections [to get EmployeeId]
          setEmployeeId(res.data.data[0].employee_id)
        //EOF SOP-13 Inlcuded By PCS Testing feedback corrections [to get EmployeeId]

        return res.data.data[0] || [];
        } catch (err) {
        console.log( "co",err); 
        } 

      }

    //   To save change password
    // Define a type for errors
    type Errors = {
        oldPassword?:boolean;
        password?: boolean;
        confirmPassword?: boolean;
    };

    // To encrypt password
    const secretPass = "XkhZG4fW2t2W";

    const encryptData = async () => {
        try {
            const data = CryptoJS.AES.encrypt(JSON.stringify(ConfirmPasswordValue),secretPass).toString();
            return data
        } catch (error) {
            console.error("Error encrypting data:", error);
        }
        };

      const CreateChangePassword = async (event :any) =>{
        event.preventDefault();
        

        const CompanyDetails = await ToGetCompanyUserDetails();
        // console.log("CompanyDetails",CompanyDetails)
        // console.log("CompanyDetails.password",CompanyDetails.password)

        // To decrypt password to check valid 
        const secretPass = "XkhZG4fW2t2W";   
        let decryptedData; 
        if (CompanyDetails.password && typeof CompanyDetails.password === 'string' && CompanyDetails.password.trim() !== '') {
            const bytes = CryptoJS.AES.decrypt(CompanyDetails.password, secretPass);
            decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }        

        //  console.log("decryptedData",decryptedData)
        //  console.log("trimmedOldPasswordValue",OldPasswordValue)
        //  console.log("secretPass",secretPass)

        

        // To encrypt password 
        const GetEncryptedData = await encryptData()
            //console.log("GetEncryptedData",GetEncryptedData)

        let errors: Errors = {};        

        // Old password 
        const trimmedOldPasswordValue = OldPasswordValue.trim();
        if (trimmedOldPasswordValue === '') {
            errors.oldPassword = true;
            setOldPasswordErrorMessage("Old Password is required");
        } 
        else if( trimmedOldPasswordValue.length >12 ){
            errors.oldPassword = true;
            setOldPasswordErrorMessage("Max 12 characters");
        }
         else if( trimmedOldPasswordValue !== decryptedData){
         //else if( trimmedOldPasswordValue !== 'luna123'){
            errors.oldPassword = true;
            setOldPasswordErrorMessage("Invalid old password");
        }

        //SOP-13 Included By PCS[Testing feedback corrections-to not allow space in password]
              else if (OldPasswordValue.includes(' ')) {
                errors.oldPassword = true;
                setOldPasswordErrorMessage("Spaces are not allowed");
            }
        //EOF SOP-13 Included By PCS[Testing feedback corrections-to not allow space in password]

        const trimmedPasswordValue = NewPasswordValue.trim();
        if (trimmedPasswordValue === '') {
            errors.password = true;
            setNewPasswordErrorMessage("New Password is required");
        } 
        else if( trimmedPasswordValue.length >12 ){
            errors.password = true;
            setNewPasswordErrorMessage("Max 12 characters");
        }  

        //SOP-13 Included By PCS[Testing feedback corrections-to not allow space in password]
         else if (NewPasswordValue.includes(' ')) {
            errors.password = true;
            setNewPasswordErrorMessage("Spaces are not allowed");
         }
        //EOF SOP-13 Included By PCS[Testing feedback corrections-to not allow space in password] 

        if (ConfirmPasswordValue !== '') {
            if (trimmedPasswordValue !== ConfirmPasswordValue) {
              setConfirmPasswordError(true);
              //SOP-13 Modified by PCS[Testing feedback corrections]
              //setConfirmPasswordErrorMessage("Passwords must match");
               setConfirmPasswordErrorMessage("Confirm password is not matching");
             //EOF SOP-13 Modified by PCS[Testing feedback corrections]
            } else {
              setConfirmPasswordError(false);
              setConfirmPasswordErrorMessage("");
            }
          }
    
        const trimmedConfirmPasswordValue = ConfirmPasswordValue.trim();
        if (trimmedConfirmPasswordValue === '') {
            errors.confirmPassword = true;
            setConfirmPasswordErrorMessage("Confirm Password is required");
        }
        else if( trimmedConfirmPasswordValue.length >12 ){
            errors.confirmPassword = true;
            setConfirmPasswordErrorMessage("Max 12 characters");
        }

        //SOP-13 Included By PCS[Testing feedback corrections-to not allow space in password ]
          else if (ConfirmPasswordValue.includes(' ')) {
            errors.confirmPassword = true;
            setConfirmPasswordErrorMessage("Spaces are not allowed");
         }
        //EOF SOP-13 Included By PCS[Testing feedback corrections-to not allow space in password] 

        else if( NewPasswordValue !== trimmedConfirmPasswordValue){
          errors.confirmPassword = true;
          //SOP-13 Included By PCS[Testing feedback corrections]
          //setConfirmPasswordErrorMessage("Passwords must match");
          setConfirmPasswordErrorMessage("Confirm password is not matching");
        }

      if (Object.keys(errors).length > 0) {
        // If there are errors, update the state with all the errors
        setNewPasswordError(errors.password || false);
        setConfirmPasswordError(errors.confirmPassword || false);
        setOldPasswordError(errors.oldPassword || false)

        return;
      }

      const setPasswordPayload = {
       
        CompanyPassword:GetEncryptedData,
        CompanyId:CompanyId,
        //SOP-13 Included By  PCS Testing feedback corrections[to enable password change] 
        EmployeeId:EmployeeId,
        UserId:UserId
        //EOF SOP-13 Included By  PCS Testing feedback corrections[to enable password change] 
      }

      //console.log("New ..",setPasswordPayload)

      commonService
      .postService(`${process.env.REACT_APP_BACKEND_URL}/company/SetCompanyPassword`, setPasswordPayload)
      .then((res) => {
        if (res.status === 200) {
            Success("Password reset successfully");
            navigate('/home')
        }
        }).catch((error) => {
            console.error("Error:", error);
        });


      } 

      useEffect(() => {

       
        const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
        const GlobalData = JSON.parse(GlobalDataFromSession);
        //console.log("GlobalData",GlobalData)
        setUserNameValue(GlobalData.GlobalUserName)
        setEmail(GlobalData.GlobalUserEmail)

        setCompanyId(GlobalData.GlobalCompanyId)
     //SOP-13 Included By PCS[to enable password change]
        setActiveStatus(GlobalData.GlobalActive)
        setUserId(GlobalData.GlobalUserId)
        setEmployeeId(GlobalData.GlobalEmployeeId)
    //EOF SOP-13 Included By PCS[to enable password change]

    
      }, []);

    // Design Start
    return(
        <>
        <MainContainerWrapper headingText='Change Password'>
            <Box display={'flex'} justifyContent={'center'} >                    
            <form noValidate onSubmit={CreateChangePassword}>
                <Box bgcolor={'white'}
                sx={{
                    width:{
                        lg:500,
                        md:500,
                        xs:400
                    }
                }}
                 height={350} borderRadius={3}>
                        <Box p={3}  display={'flex'} justifyContent={'center'}>
                            <Box mt={1}>
                                <Box p={1} >
                                <TextField
                                    type='text'
                                    variant="standard"
                                    label='User Name'
                                    name='UserName'
                                    style={{ margin: '0 10px', width:300 }}
                                    required
                                    disabled
                                    value={UserNameValue}
                                   
                                />
                                </Box>
                                <Box p={1}>
                                <TextField
                                //SOP-13 Included By PCS[Testing feedback correction - to set auto focus]
                                    autoFocus
                                //EOF SOP-13 Included By PCS[Testing feedback correction- to set auto focus]
                                //SOP-13 Included By PCS[Testing feedback corrections- to include eye icon in password field]
                                    // type='password'
                                    type={showPasswordOldpassword ? 'text' : 'password'}
                                //EOF SOP-13 Included By PCS[Testing feedback corrections- to include eye icon in password field]
                                    variant="standard"
                                    label='Old Password'
                                    
                                    name='Password'
                                    style={{ margin: '0 10px', width:300  }}
                                    required
                                    value={OldPasswordValue}
                                    error={OldPasswordError}
                                    helperText={OldPasswordErrorMessage}
                                    onChange={handleOldPasswordChange}
                                    //SOP-13 Inlcuded By PCS[Testing feedback corrections-to restrict only 12 characters]
                                    inputProps={{
                                        maxLength: 12
                                    }}
                                    //EOF SOP-13 Inlcuded By PCS[Testing  feedback corrections]

                                    
                                    //SOP-13 Included By PCS[Testing feedback corrections to include eye icon]
                                                     
                                            InputProps={{
                                                endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleToggleOldPasswordVisibility}
                                                        edge="end"
                                                    >
                                                        {showPasswordOldpassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                                    ),
                                                    }}
                                    //EOF SOP-13 Inlcuded By PCS[Testing  feedback corrections to include eye icon]
                                    /> 
                                    </Box>
                                    <Box p={1}>
                                    <TextField
                                   
                                    //SOP-13 Included By PCS[Testing feedback corrections- to include eye icon]
                                    // type='password'
                                    type={showPasswordNewpassword ? 'text' : 'password'}
                                    //EOF SOP-13 Included By PCS[Testing feedback corrections- to include eye icon]
                                    variant="standard"
                                    label='New Password'
                                    name='Password'
                                    
                                    style={{ margin: '0 10px', width:300  }}
                                    required
                                    value={NewPasswordValue}
                                    error={NewPasswordError}
                                    helperText={NewPasswordErrorMessage}
                                    onChange={handleNewPasswordChange}
                                     //SOP-13 Inlcuded By PCS[Testing feedback corrections-to restrict only 12 characters]
                                     inputProps={{
                                        maxLength: 12
                                    }}
                                    //EOF SOP-13 Inlcuded By PCS[Testing  feedback corrections]
                                    //SOP-13 Included By PCS[Testing feedback corrections- to include eye icon]
                                                     
                                            InputProps={{
                                                endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleToggleNewPasswordVisibility}
                                                        edge="end"
                                                    >
                                                        {showPasswordNewpassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                                    ),
                                                    }}
                                  //EOF SOP-13 Inlcuded By PCS[Testing  feedback corrections - to include Eye icon]

                                /> 
                                </Box>
                                <Box p={1}>
                                <TextField
                                    //SOP-13 Included By PCS[Testing feedback corrections- to include eye icon]
                                    // type='password'
                                     type={showPasswordConfirmpassword ? 'text' : 'password'}
                                    //EOF SOP-13 Included By PCS[Testing feedback corrections- to include eye icon]
                                    variant="standard"
                                    label='Confirm Password'
                                    name='Password'
                                    style={{ margin: '0 10px', width:300  }}
                                    required
                                    value={ConfirmPasswordValue}
                                    error={ConfirmPasswordError}
                                    helperText={ConfirmPasswordErrorMessage}
                                    onChange={handleConfirmPasswordChange}
                                     //SOP-13 Inlcuded By PCS[Testing feedback corrections-to restrict only 12 characters]
                                     inputProps={{
                                        maxLength: 12
                                    }}
                                    //EOF SOP-13 Inlcuded By PCS[Testing  feedback corrections to restrict only 12 characters]
                                    //SOP-13 Included By PCS[Testing feedback corrections- to include eye icon]
                                                     
                                        InputProps={{
                                            endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleToggleConfirmPasswordVisibility}
                                                    edge="end"
                                                >
                                                    {showPasswordConfirmpassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                                ),
                                                   }}
                                    //EOF SOP-13 Inlcuded By PCS[Testing  feedback corrections -to include eye icon]
                                /> 
                                </Box>
                            </Box>
                        </Box>                                     
                </Box>  
                <Box
                    sx={{ 
                        display:{
                            lg:'flex',
                            md:'flex',
                            xs:'flex'
                        }, 
                        justifyContent: {
                            lg:'space-between',
                            md:'space-between',
                            xs:'space-evenly'
                        }, 
                        mt:5  }}
                    >
                       <Button
                        sx={{
                            background: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            padding:{
                                lg: "0 70px",
                                md: "0 70",
                                xs:'0 50px'

                            },
                            // color: {accent},
                            height: "3rem",
                            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                            
                        }}
                        onClick={()=>{
                            navigate('/home')
                        }}
                            

                    >
                        Back
                    </Button>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                    sx={{
                        background: '#25425F',
                        border: 'none',
                        borderRadius: '5px',
                        padding:{
                            lg: "0 70px",
                            md: "0 70",
                            xs:'0 50px'

                        },
                        color: 'white',
                        height: "3rem",
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                        "&:hover":{
                            backgroundColor:'#25425F',
                            color:'white',
                        }
                            }}
                            type='submit'
                        >
                            Save
                        </Button>
                    </Box>
              </Box>
            </form>
            </Box>
        </MainContainerWrapper>
        </>
    )
    // End of Design 
}

export default ChangePasswordPage;
// Page end

// End of SOP-13