// Included by sanjana SOP-19 to create page for Add Asset
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,    
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControl,
    FormHelperText,
    useTheme,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import deleteAlert from '../../component/HOC/deleteAlert';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FcInfo } from "react-icons/fc";
import { FaUserGear } from "react-icons/fa6";
import { BsBuildingsFill } from "react-icons/bs";
import { IoMdPhotos } from "react-icons/io";
import Swal from "sweetalert2";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { BiSolidPurchaseTag } from "react-icons/bi";
import { GrSystem } from "react-icons/gr";
import { PiMedalFill } from "react-icons/pi";
import { LiaEditSolid } from "react-icons/lia";
import { set } from 'date-fns';



const AddAssetPage =()=>{


     // Use navigate
     const navigate = useNavigate();


    // Usedate declaration
    const [CategoryOptions, setCategoryOptions] = useState<OptionType[]>([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>('');
    const [CategoryError, setCategoryError]=useState(false)
    const [CategoryErrorMessage, setCategoryErrorMessage]=useState('')
    const [SubCategoryOptions, setSubCategoryOptions] = useState<OptionType[]>([]);
    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState<string | null>('');
    const [SubCategoryError, setSubCategoryError]=useState(false)
    const [SubCategoryErrorMessage, setSubCategoryErrorMessage]=useState('')
    const [AssetNameError, setAssetNameError]=useState(false)
    const [AssetNameValue, setAssetNameValue]=useState('')
    const [AssetNameErrorMessage, setAssetNameErrorMessage]=useState('')
    const [ModelNumberError, setModelNumberError]=useState(false)
    const [ModelNumberValue, setModelNumberValue]=useState('')
    const [ModelNumberErrorMessage, setModelNumberErrorMessage]=useState('')
    const [SerialNumberError, setSerialNumberError]=useState(false)
    const [SerialNumberValue, setSerialNumberValue]=useState('')
    const [SerialNumberErrorMessage, setSerialNumberErrorMessage]=useState('')
    const [ServiceTagNumberError, setServiceTagNumberError]=useState(false)
    const [ServiceTagNumberValue, setServiceTagNumberValue]=useState('')
    const [ServiceTagNumberErrorMessage, setServiceTagNumberErrorMessage]=useState('')
    const [PurchaseDateError, setPurchaseDateError]=useState(false)
    const [PurchaseDateValue, setPurchaseDateValue] = useState<dayjs.Dayjs | null>(null);
    const [PurchaseDateErrorMessage, setPurchaseDateErrorMessage]=useState('')
    const [ShipDateError, setShipDateError]=useState(false)
    const [ShipDateValue, setShipDateValue] = useState<dayjs.Dayjs | null>(null);
    const [ShipDateErrorMessage, setShipDateErrorMessage]=useState('')
    const [ExpressServiceCodeError, setExpressServiceCodeError]=useState(false)
    const [ExpressServiceCodeValue, setExpressServiceCodeValue] = useState('')
    const [ExpressServiceCodeErrorMessage, setExpressServiceCodeErrorMessage]=useState('')
    const [ManufracturerError, setManufracturerError]=useState(false)
    const [ManufracturerValue, setManufracturerValue] = useState('')
    const [ManufracturerErrorMessage, setManufracturerErrorMessage]=useState('')
    const [InvoiceNumberError, setInvoiceNumberError]=useState(false)
    const [InvoiceNumberValue, setInvoiceNumberValue] = useState('')
    const [InvoiceNumberErrorMessage, setInvoiceNumberErrorMessage]=useState('')
    const [InvoiceDateError, setInvoiceDateError]=useState(false)
    const [InvoiceDateValue, setInvoiceDateValue] = useState<dayjs.Dayjs | null>(null);
    const [InvoiceDateErrorMessage, setInvoiceDateErrorMessage]=useState('')
    const [PriceError, setPriceError]=useState(false)
    const [PriceValue, setPriceValue] = useState('0.00')
    const [PriceErrorMessage, setPriceErrorMessage]=useState('')
    const [IpAddressError, setIpAddressError]=useState(false)
    const [IpAddressValue, setIpAddressValue] = useState('')
    const [IpAddressErrorMessage, setIpAddressErrorMessage]=useState('')
    const [OperatingSystemError, setOperatingSystemError]=useState(false)
    const [OperatingSystemValue, setOperatingSystemValue] = useState('')
    const [OperatingSystemErrorMessage, setOperatingSystemErrorMessage]=useState('')
    const [ProcessorTypeError, setProcessorTypeError]=useState(false)
    const [ProcessorTypeValue, setProcessorTypeValue] = useState('')
    const [ProcessorTypeErrorMessage, setProcessorTypeErrorMessage]=useState('')
    const [MemoryInGbError, setMemoryInGbError]=useState(false)
    const [MemoryInGbValue, setMemoryInGbValue] = useState('')
    const [MemoryInGbErrorMessage, setMemoryInGbErrorMessage]=useState('')
    const [NotesValue, setNotesValue] = useState('')
    const [LocationError, setLocationError]=useState(false)
    const [LocationValue, setLocationValue] = useState('')
    const [LocationErrorMessage, setLocationErrorMessage]=useState('')
    const [WarrantyStartDateError, setWarrantyStartDateError]=useState(false)
    const [WarrantyStartDateValue, setWarrantyStartDateValue] = useState<dayjs.Dayjs | null>(null);
    const [WarrantyStartDateErrorMessage, setWarrantyStartDateErrorMessage]=useState('')
    const [WarrantyEndDateError, setWarrantyEndDateError]=useState(false)
    const [WarrantyEndDateValue, setWarrantyEndDateValue] = useState<dayjs.Dayjs | null>(null);
    const [WarrantyEndDateErrorMessage, setWarrantyEndDateErrorMessage]=useState('')
    const [AMCStartDateError, setAMCStartDateError]=useState(false)
    const [AMCStartDateValue, setAMCStartDateValue] = useState<dayjs.Dayjs | null>(null);
    const [AMCStartDateErrorMessage, setAMCStartDateErrorMessage]=useState('')
    const [AMCEndDateError, setAMCEndDateError]=useState(false)
    const [AMCEndDateValue, setAMCEndDateValue] = useState<dayjs.Dayjs | null>(null);
    const [AMCEndDateErrorMessage, setAMCEndDateErrorMessage]=useState('')
    const[ToSetWarranty, setToSetWarranty] = useState(false)
    const[ToSetAMC, setToSetAMC] = useState(false)
    const [disableWarrantyDate,setDisableWarrantyDate] = useState(true)
    const [disableAMCDate,setDisableAMCDate] = useState(true)

    //SOP-35 Included By PCS [to get Asset Count from API 'getAssetCount' for the company]
    const [AssetcountValue, setAssetcountValue]=useState('')
    //EOF SOP-35

    //SOP-19 Included By PCS[Testing feedback corrections- to validate Warranty & AMC from & to dates]

    const [Warranty_StartDate, setWarranty_StartDate]=useState('')
    const [Warranty_EndDate, setWarranty_EndDate]=useState('')
    const [AMC_StartDate, setAMC_StartDate]=useState('')
    const [AMC_EndDate, setAMC_EndDate]=useState('')

    //EOF SOP-19 Included By PCS[Testing feedback corrections- to validate Warranty & AMC  from & to dates]
 
    // Handeling function of warranty checkbox
    const WarrantycheckBoxState = (e:any) => {
        let isWarrantyChecked = false;
        isWarrantyChecked = e.target.checked;
        //console.log("e.target.checked",e.target.checked)
        if (isWarrantyChecked === true) {
            setToSetWarranty(true) 
            //console.log(true)   
            setDisableWarrantyDate(false)
        }
        else{
            setToSetWarranty(false)
            setDisableWarrantyDate(true)
        //console.log(false)
        }

        if(isWarrantyChecked === false){            
            setWarrantyStartDateError(false)
            setWarrantyStartDateValue(null)
            setWarrantyStartDateErrorMessage('')
            setWarrantyEndDateError(false)
            setWarrantyEndDateValue(null)
            setWarrantyEndDateErrorMessage('')
        }
        
      }

      // Handeling function of AMC checkbox
      const AMCcheckBoxState = (e:any) => {
        let isAMCChecked = false;
        isAMCChecked = e.target.checked;
        //console.log("e.target.checked",e.target.checked)
        if (isAMCChecked === true) {
            setToSetAMC(true) 
           // console.log(true)   
            setDisableAMCDate(false)       
        }
        else{
            setToSetAMC(false)
            setDisableAMCDate(true)   
        //console.log(false)
        }

        if(isAMCChecked === false){            
            setAMCStartDateError(false)
            setAMCStartDateValue(null)
            setAMCStartDateErrorMessage('')
            setAMCEndDateError(false)
            setAMCEndDateValue(null)
            setAMCEndDateErrorMessage('')
        }
     }
        
    
    

    type OptionType = {
        name: string;
        value: string;
    };
    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });


    // Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
   // console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId
    let CreatedBy = GlobalData.GlobalUserId
    //console.log("CompanyId",CompanyId)

    //SOP-35 Inlcuded By PCS [to get asset count from Global Variable]
    let GlobalAssetCount = GlobalData.GlobalNoOfAssets
    //EOF SOP-35 


    // To get category list to fill category dropdown
    const GetCatgoryList = async () => {
        const CategoryPayload ={
            CompanyId:CompanyId
        }
        //console.log("CategoryPayload",CategoryPayload)
    try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/getCategoryList`,CategoryPayload);
        //console.log("Country" ,res.data.data);
        let formattedCategory = res.data.data.map((item:any) => {
        const { categoryid, categoryname } = item;
        return {
            name: categoryname,
            value: categoryid,
        };
        });
        setCategoryOptions(formattedCategory)
    } catch (err) {
        //console.log( "co",err);
    }
    };


    // To get subcategory list to fill ths subcategory drop down
    const GetSubCatgoryList = async (selectedCategoryId:any) => {
        const SubCategoryPayload ={
            CompanyId:CompanyId,
            CategoryId:selectedCategoryId
        }
        //console.log("SubCategoryPayload",SubCategoryPayload)
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/getSubCategoryList`,{
                CompanyId:CompanyId,
                CategoryId:selectedCategoryId
            });
            //console.log("Country" ,res.data.data);
            let formattedSubCategory = res.data.data.map((item:any) => {
            const { subcategoryid, subcategoryname } = item;
            return {
                name: subcategoryname,
                value: subcategoryid, 
            };
            });
            setSubCategoryOptions(formattedSubCategory)
        } catch (err) {
            //console.log( "co",err);
        }
    };


    // To handle asset name and validation
    const handleAssetNameChange =( event:any)=>{
        const trimmedAssetNameValue = event.target.value.trim();
        setAssetNameValue(event.target.value);
        if (trimmedAssetNameValue === '') {
            setAssetNameError(true);
            setAssetNameErrorMessage("Asset name is required");
        } 
        else if( trimmedAssetNameValue.length >225 ){
            setAssetNameError(true);
            setAssetNameErrorMessage("Max 255 characters");
        }
        else {
            setAssetNameError(false);
            setAssetNameErrorMessage("");
        }

    }

    // To handle Model number and validation
    const handleModelNumberChange =( event:any)=>{
        const trimmedModelNumberValue = event.target.value.trim();
        setModelNumberValue(event.target.value);
         if( trimmedModelNumberValue.length >225 ){
            setModelNumberError(true);
            setModelNumberErrorMessage("Max 255 characters");
        }
        else {
            setModelNumberError(false);
            setModelNumberErrorMessage("");
        }

    }


    // To handle serial number and validation
    const handleSerialNumberChange =( event:any)=>{
        const trimmedSerialNumberValue = event.target.value.trim();
        setSerialNumberValue(event.target.value);
         if( trimmedSerialNumberValue.length >225 ){
            setSerialNumberError(true);
            setSerialNumberErrorMessage("Max 255 characters");
        }
        else {
            setSerialNumberError(false);
            setSerialNumberErrorMessage("");
        }

    }

    // To handle service tag number and validation
    const handleServiceTagNumberChange =( event:any)=>{
        const trimmedServiceTagNumberValue = event.target.value.trim();
        setServiceTagNumberValue(event.target.value);
         if( trimmedServiceTagNumberValue.length >225 ){
            setServiceTagNumberError(true);
            setServiceTagNumberErrorMessage("Max 255 characters");
        }
        else {
            setServiceTagNumberError(false);
            setServiceTagNumberErrorMessage("");
        }

    }

    // To handle purchase date
    const handlePurchaseDateChange =(date:any)=>{
        setPurchaseDateValue(date);
    }

    // To handle manufracturer and validation
    const handleManufracturerChange =( event:any)=>{
        const trimmedManufracturerValue = event.target.value.trim();
        setManufracturerValue(event.target.value);
         if( trimmedManufracturerValue.length >225 ){
            setManufracturerError(true);
            setManufracturerErrorMessage("Max 255 characters");
        }
        else {
            setManufracturerError(false);
            setManufracturerErrorMessage("");
        }

    }


    // To handle invoice number and validation
    const handleInvoiceNumberChange =( event:any)=>{
        const trimmedInvoiceNumberValue = event.target.value.trim();
        setInvoiceNumberValue(event.target.value);
         if( trimmedInvoiceNumberValue.length >225 ){
            setInvoiceNumberError(true);
            setInvoiceNumberErrorMessage("Max 255 characters");
        }
        else {
            setInvoiceNumberError(false);
            setInvoiceNumberErrorMessage("");
        }

    }
    const handleInvoiceDateChange =( date:any)=>{
        setInvoiceDateValue(date);

    
    // To handle price and validation    
    }
    const handlePriceChange =( event:any)=>{
        const trimmedPriceValue = event.target.value.trim();

    //SOP-19 Included By PCS[Testing feedback corrections- to restrict only to numerics for price field]
            const inputPriceValue = event.target.value;
            const numericRegex = /^[0-9 .]*$/; 
            if (!numericRegex.test(inputPriceValue))
                 {
                setPriceError(true);
                setPriceErrorMessage("Enter only numbers ");
                return;
                 }
    //EOF SOP-19 Included By PCS[Testing feedback corrections- to restrict only to numerics]

        setPriceValue(event.target.value);
         if( trimmedPriceValue.length >225 ){
            setPriceError(true);
            setPriceErrorMessage("Max 255 characters");
        }
        else {
            setPriceError(false);
            setPriceErrorMessage("");
        }

    }




    const handleIpAddressChange =( event:any)=>{
        const trimmedIpAddressValue = event.target.value.trim();
        setIpAddressValue(event.target.value);
         if( trimmedIpAddressValue.length >225 ){
            setIpAddressError(true);
            setIpAddressErrorMessage("Max 255 characters");
        }
        else {
            setIpAddressError(false);
            setIpAddressErrorMessage("");
        }

    }
    const handleOperatingSystemChange =( event:any)=>{
        const trimmedOperatingSystemValue = event.target.value.trim();
        setOperatingSystemValue(event.target.value);
         if( trimmedOperatingSystemValue.length >225 ){
            setOperatingSystemError(true);
            setOperatingSystemErrorMessage("Max 255 characters");
        }
        else {
            setOperatingSystemError(false);
            setOperatingSystemErrorMessage("");
        }

    }
    const handleProcessorTypeChange =( event:any)=>{
        const trimmedProcessorTypeValue = event.target.value.trim();
        setProcessorTypeValue(event.target.value);
         if( trimmedProcessorTypeValue.length >225 ){
            setProcessorTypeError(true);
            setProcessorTypeErrorMessage("Max 255 characters");
        }
        else {
            setProcessorTypeError(false);
            setProcessorTypeErrorMessage("");
        }

    }
    const handleMemoryInGbChange =( event:any)=>{
        const trimmedMemoryInGbValue = event.target.value.trim();
        setMemoryInGbValue(event.target.value);
         if( trimmedMemoryInGbValue.length >225 ){
            setMemoryInGbError(true);
            setMemoryInGbErrorMessage("Max 255 characters");
        }
        else {
            setMemoryInGbError(false);
            setMemoryInGbErrorMessage("");
        }

    }

    const handleShipDateChange =(date:any)=>{
        setShipDateValue(date);


    }

    const handleExpressServiceCodeChange =( event:any)=>{
        const trimmedExpressServiceCodeValue = event.target.value.trim();
        setExpressServiceCodeValue(event.target.value);
         if( trimmedExpressServiceCodeValue.length >225 ){
            setExpressServiceCodeError(true);
            setExpressServiceCodeErrorMessage("Max 255 characters");
        }
        else {
            setExpressServiceCodeError(false);
            setExpressServiceCodeErrorMessage("");
        }

    }

    const handleLocationChange =( event:any)=>{
        const trimmedLocationValue = event.target.value.trim();
        setLocationValue(event.target.value);
         if( trimmedLocationValue.length >225 ){
            setLocationError(true);
            setLocationErrorMessage("Max 255 characters");
        }
        else {
            setLocationError(false);
            setLocationErrorMessage("");
        }

    }

    const handleNotesChange =( event:any)=>{
        setNotesValue(event.target.value);
    }

    const handleWarrantyStartDateChange =(date:any)=>{
        setWarrantyStartDateValue(date);
        if ( ToSetWarranty === true && date=== null) {
            setWarrantyStartDateError(true);
            setWarrantyStartDateErrorMessage("Warranty start date is required");
        }else {
            setWarrantyStartDateError(false);
            setWarrantyStartDateErrorMessage("");
        }
        }


    const handleWarrantyEndChange =(date:any)=>{
        setWarrantyEndDateValue(date);
        if ( ToSetWarranty === true && date === null) {
            setWarrantyEndDateError(true);
            setWarrantyEndDateErrorMessage("Warranty end date is required");
        }else {
            setWarrantyEndDateError(false);
            setWarrantyEndDateErrorMessage("");
        }
        }


    const handleAMCStartDateChange =(date:any)=>{
        setAMCStartDateValue(date);
        if ( ToSetWarranty === true && !date) {
            setAMCStartDateError(true);
            setAMCStartDateErrorMessage("AMC start date is required");
        }else {
            setAMCStartDateError(false);
            setAMCStartDateErrorMessage('');
        }
        }

    const handleAMCEndDateChange =(date:any)=>{
        setAMCEndDateValue(date);
        if ( ToSetWarranty === true && !date) {
            setAMCEndDateError(true);
            setAMCEndDateErrorMessage("AMC end date is required");
        }else {
            setAMCEndDateError(false);
            setAMCEndDateErrorMessage('');
        }
        }



    // Exist  Asset API
    const GetExistAsset = async () => {   
        
        const  ExistAssetPayload = {
            ExistAssetName:AssetNameValue,
            CompanyId:CompanyId
        }
        //console.log("ExistAssetPayload",ExistAssetPayload)
        try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/get_asset_name_exists`,ExistAssetPayload);
        //console.log(res.data.data);
        return res.data.data || [];
        } catch (err) {
        console.log( "co",err); 
        }
    };



    // Define a type for errors
    type Errors = {
        AssetName?:boolean;
        CategoryId?:boolean;
        SubCategoryId?:boolean;
        WarrantyStartDate?:boolean;
        WarrantyEndDate?:boolean;
        AMCStartDate?:boolean;
        AMCEndDate?:boolean;
        ModelNumber?:boolean;
        SerialNumber?:boolean;
        ServiceTagNumber?:boolean;
        Manufracturer?:boolean;
        InvoiceNumber?:boolean;
        Price?:boolean;
        IPAddress?:boolean;
        OperatingSystem?:boolean;
        ProcessorType?:boolean;
        MemoryInGB?:boolean;
        ExpressService?:boolean;
        Location?:boolean;
        Notes?:boolean;
        };

    const CreateAsset =async (event:any)=>{
        event.preventDefault();

        let errors: Errors = {};

        const ExistAssetList = await GetExistAsset();
        //console.log("ExistAssetList",ExistAssetList)

        // if (ExistAssetList.length > 0) {
        //     setAssetNameErrorMessage("Asset is already exists");
        //     errors.AssetName = true;
        //     return;
        // } 

        const trimmedAssetNameValue = AssetNameValue.trim();
        if (trimmedAssetNameValue === '') {
            errors.AssetName=true
            setAssetNameErrorMessage("Asset name is required");
        } 
        else if( trimmedAssetNameValue.length >225 ){
            errors.AssetName=true
            setAssetNameErrorMessage("Max 255 characters");
        }

        if (selectedCategoryId === '' || selectedCategoryId === null || selectedCategoryId === undefined) {
            errors.CategoryId = true;
            setCategoryErrorMessage("Category is required");
        }

        if (selectedSubCategoryId === '' || selectedSubCategoryId === null || selectedSubCategoryId === undefined) {
            errors.SubCategoryId = true;
            setSubCategoryErrorMessage("Sub-Category is required");
        }



        const trimmedModelNumberValue = ModelNumberValue.trim();
         if( trimmedModelNumberValue.length >225 ){
            errors.ModelNumber=true
            setModelNumberErrorMessage("Max 255 characters");
        }

        const trimmedSerialNumberValue = SerialNumberValue.trim();
         if( trimmedSerialNumberValue.length >225 ){
            errors.SerialNumber=true
            setSerialNumberErrorMessage("Max 255 characters");
        }
        const trimmedServiceTagNumberValue = ServiceTagNumberValue.trim();
         if( trimmedServiceTagNumberValue.length >225 ){
            errors.ServiceTagNumber=true
            setServiceTagNumberErrorMessage("Max 255 characters");
        }
        const trimmedManufracturerValue = ManufracturerValue.trim();
         if( trimmedManufracturerValue.length >225 ){
            errors.Manufracturer=true
            setManufracturerErrorMessage("Max 255 characters");
        }
        const trimmedInvoiceNumberValue = InvoiceNumberValue.trim();
         if( trimmedInvoiceNumberValue.length >225 ){
            errors.InvoiceNumber=true
            setInvoiceNumberErrorMessage("Max 255 characters");
        }
        const trimmedPriceValue = PriceValue.trim();
         if( trimmedPriceValue.length >225 ){
            errors.Price=true
            setPriceErrorMessage("Max 255 characters");
        }
        const trimmedIpAddressValue = IpAddressValue.trim();
         if( trimmedIpAddressValue.length >225 ){
            errors.IPAddress=true
            setIpAddressErrorMessage("Max 255 characters");
        }
        const trimmedOperatingSystemValue = OperatingSystemValue.trim();
         if( trimmedOperatingSystemValue.length >225 ){
            errors.OperatingSystem=true
            setOperatingSystemErrorMessage("Max 255 characters");
        }
        const trimmedProcessorTypeValue = ProcessorTypeValue.trim();
         if( trimmedProcessorTypeValue.length >225 ){
            errors.ProcessorType=true
            setProcessorTypeErrorMessage("Max 255 characters");
        }
        const trimmedMemoryInGBValue = MemoryInGbValue.trim();
         if( trimmedMemoryInGBValue.length >225 ){
            errors.MemoryInGB=true
            setMemoryInGbErrorMessage("Max 255 characters");
        }
        const trimmedExpressServiceCodeValue = ExpressServiceCodeValue.trim();
         if( trimmedExpressServiceCodeValue.length >225 ){
            errors.ExpressService=true
            setExpressServiceCodeErrorMessage("Max 255 characters");
        }
        const trimmedLocationValue = LocationValue.trim();
         if( trimmedLocationValue.length >225 ){
            errors.Location=true
            setLocationErrorMessage("Max 255 characters");
        }
        if(ToSetWarranty === true && WarrantyStartDateValue === null){
           // console.log("WarrantyStartDateValue",WarrantyStartDateValue)
            errors.WarrantyStartDate=true
            setWarrantyStartDateErrorMessage("Warranty start date is required")

        }else {
            setWarrantyStartDateErrorMessage("");
        }

        if(ToSetWarranty === true && WarrantyEndDateValue === null){
            errors.WarrantyEndDate=true
            setWarrantyEndDateErrorMessage("Warranty end date is required")

        }else {
            setWarrantyEndDateErrorMessage("");
        }

        if(ToSetAMC === true && AMCStartDateValue === null){
            errors.AMCStartDate=true
            setAMCStartDateErrorMessage("AMC start date is required")

        }else {
            setAMCStartDateErrorMessage("");
        }

        if(ToSetAMC === true && AMCEndDateValue === null){
            errors.AMCEndDate=true
            setAMCEndDateErrorMessage("AMC end date is required")

        }else {
            setAMCEndDateErrorMessage("");
        }

        let isWarrantyChecked ;
        if(ToSetWarranty === true){
            isWarrantyChecked = 'Y'
        }
        else if(ToSetWarranty === false){
            isWarrantyChecked = 'N'
        }

        let isAMCChecked ;
        if(ToSetAMC === true){
            isAMCChecked = 'Y'
        }
        else if(ToSetAMC === false){
            isAMCChecked = 'N'
        }


        let trimmedPurchaseDateValue
        if(PurchaseDateValue !== null ){
            const dateString = PurchaseDateValue.format(); // Assuming your `Dayjs` object has a `format()` method
             trimmedPurchaseDateValue = dateString.trim();
             //console.log("trimmedPurchaseDateValue.l",trimmedPurchaseDateValue.length)
        }
        let FormatedPurchaseDate;
        if (trimmedPurchaseDateValue) {
            const trimmedDate = new Date(trimmedPurchaseDateValue);
            const year = trimmedDate.getFullYear();
            const month = String(trimmedDate.getMonth() + 1).padStart(2, '0');
            const date = String(trimmedDate.getDate()).padStart(2, '0');
            FormatedPurchaseDate = `${year}-${month}-${date}`;
            // Now you can use the formattedDate variable where needed
        }


        let trimmedShipDateValue
        if(ShipDateValue !== null ){
            const dateString = ShipDateValue.format(); // Assuming your `Dayjs` object has a `format()` method
             trimmedShipDateValue = dateString.trim();
             //console.log("trimmedShipDateValue.l",trimmedShipDateValue.length)
        }
        let FormatedShipDate;
        if (trimmedShipDateValue) {
            const trimmedDate = new Date(trimmedShipDateValue);
            const year = trimmedDate.getFullYear();
            const month = String(trimmedDate.getMonth() + 1).padStart(2, '0');
            const date = String(trimmedDate.getDate()).padStart(2, '0');
            FormatedShipDate = `${year}-${month}-${date}`;
            // Now you can use the formattedDate variable where needed
        }



        let trimmedInvoiceDateValue
        if(InvoiceDateValue !== null ){
            const dateString = InvoiceDateValue.format(); // Assuming your `Dayjs` object has a `format()` method
             trimmedInvoiceDateValue = dateString.trim();
             //console.log("trimmedInvoiceDateValue.l",trimmedInvoiceDateValue.length)
        }
        let FormatedInvoiceDate;
        if (trimmedInvoiceDateValue) {
            const trimmedDate = new Date(trimmedInvoiceDateValue);
            const year = trimmedDate.getFullYear();
            const month = String(trimmedDate.getMonth() + 1).padStart(2, '0');
            const date = String(trimmedDate.getDate()).padStart(2, '0');
            FormatedInvoiceDate = `${year}-${month}-${date}`;
            // Now you can use the formattedDate variable where needed
        }


        let trimmedWarrantyStartDateValue
        if(WarrantyStartDateValue !== null ){
            const dateString = WarrantyStartDateValue.format(); // Assuming your `Dayjs` object has a `format()` method
             trimmedWarrantyStartDateValue = dateString.trim();
             //console.log("trimmedWarrantyStartDateValue.l",trimmedWarrantyStartDateValue.length)
        }
        let FormatedWarrantyStartDate;
        if (trimmedWarrantyStartDateValue) {
            const trimmedDate = new Date(trimmedWarrantyStartDateValue);
            const year = trimmedDate.getFullYear();
            const month = String(trimmedDate.getMonth() + 1).padStart(2, '0');
            const date = String(trimmedDate.getDate()).padStart(2, '0');
            FormatedWarrantyStartDate = `${year}-${month}-${date}`;
            //SOP-19 Included By PCS[Testing feedback corrections - to validate warranty date]
            setWarranty_StartDate(FormatedWarrantyStartDate)
         
        }


        let trimmedWarrantyEndDateValue
        if(WarrantyEndDateValue !== null ){
            const dateString = WarrantyEndDateValue.format(); // Assuming your `Dayjs` object has a `format()` method
             trimmedWarrantyEndDateValue = dateString.trim();
             //console.log("trimmedWarrantyEndDateValue.l",trimmedWarrantyEndDateValue.length)
        }
        let FormatedWarrantyEndDate;
        if (trimmedWarrantyEndDateValue) {
            const trimmedDate = new Date(trimmedWarrantyEndDateValue);
            const year = trimmedDate.getFullYear();
            const month = String(trimmedDate.getMonth() + 1).padStart(2, '0');
            const date = String(trimmedDate.getDate()).padStart(2, '0');
            FormatedWarrantyEndDate = `${year}-${month}-${date}`;
            //SOP-19  Included By PCS[Testing feedback corrections- to validate warranty date]
            setWarranty_EndDate(FormatedWarrantyEndDate)
           
        }



        let trimmedAMCStartDateValue
        if(AMCStartDateValue !== null ){
            const dateString = AMCStartDateValue.format(); // Assuming your `Dayjs` object has a `format()` method
             trimmedAMCStartDateValue = dateString.trim();
             //console.log("trimmedAMCStartDateValue.l",trimmedAMCStartDateValue.length)
        }
        let FormatedAMCStartDate;
        if (trimmedAMCStartDateValue) {
            const trimmedDate = new Date(trimmedAMCStartDateValue);
            const year = trimmedDate.getFullYear();
            const month = String(trimmedDate.getMonth() + 1).padStart(2, '0');
            const date = String(trimmedDate.getDate()).padStart(2, '0');
            FormatedAMCStartDate = `${year}-${month}-${date}`;
            // Now you can use the formattedDate variable where needed
            //SOP-19 Included By PCS[Testing feedback corrections - to validate AMC date]
            setAMC_StartDate(FormatedAMCStartDate)
        }



        let trimmedAMCEndDateValue
        if(AMCEndDateValue !== null ){
            const dateString = AMCEndDateValue.format(); // Assuming your `Dayjs` object has a `format()` method
             trimmedAMCEndDateValue = dateString.trim();
             //console.log("trimmedAMCEndDateValue.l",trimmedAMCEndDateValue.length)
        }
        let FormatedAMCEndDate;
        if (trimmedAMCEndDateValue) {
            const trimmedDate = new Date(trimmedAMCEndDateValue);
            const year = trimmedDate.getFullYear();
            const month = String(trimmedDate.getMonth() + 1).padStart(2, '0');
            const date = String(trimmedDate.getDate()).padStart(2, '0');
            FormatedAMCEndDate = `${year}-${month}-${date}`;
            // Now you can use the formattedDate variable where needed
            //SOP-19 Included By PCS[Testing feedback corrections- to validate AMC date]
            setAMC_EndDate(FormatedAMCEndDate)
        }


        if (Object.keys(errors).length > 0) {
            // If there are errors, update the state with all the errors
            setAssetNameError(errors.AssetName || false);
            setCategoryError(errors.CategoryId || false);
            setSubCategoryError(errors.SubCategoryId ||false);
            setWarrantyStartDateError(errors.WarrantyStartDate || false);
            setWarrantyEndDateError(errors.WarrantyEndDate || false);
            setAMCStartDateError(errors.AMCStartDate || false);
            setAMCEndDateError(errors.AMCEndDate || false);
            setModelNumberError(errors.ModelNumber || false);
            setSerialNumberError(errors.SerialNumber || false);
            setManufracturerError(errors.Manufracturer || false);
            setInvoiceNumberError(errors.InvoiceNumber || false);
            setPriceError(errors.Price || false);
            setIpAddressError(errors.IPAddress || false);
            setOperatingSystemError(errors.OperatingSystem || false);
            setProcessorTypeError(errors.ProcessorType || false);
            setMemoryInGbError(errors.MemoryInGB || false);
            setExpressServiceCodeError(errors.ExpressService || false);
            setLocationError(errors.Location || false);
            console.log("errors",errors)
            return;
        }


        const CreateAssetPayload={
            SaveUpdateKey:"create",
            AsetId:0,
            CompanyId:CompanyId,
            CategoryId:selectedCategoryId,
            SubCategoryId:selectedSubCategoryId,
            AsetName:AssetNameValue,
            ModelNumber:ModelNumberValue,
            SerialNo:SerialNumberValue,
            ServiceTagNo:ServiceTagNumberValue,
            PurchaseDate:FormatedPurchaseDate,
            ShipDate:FormatedShipDate,
            ExpressServiceCode:ExpressServiceCodeValue,
            Manufracture:ManufracturerValue,
            InvoiceNo:InvoiceNumberValue,
            InvoiceDate:FormatedInvoiceDate,
            Price:PriceValue || 0.00,
            IpAddress:IpAddressValue,
            OperatingSystem:OperatingSystemValue,
            ProcessorType:ProcessorTypeValue,
            MemoryInGB:MemoryInGbValue,
            Notes:NotesValue,
            Location:LocationValue,
            IsUnderWarranty:isWarrantyChecked,
            WarrantyStartDate:FormatedWarrantyStartDate,
            WarrantyEndDate:FormatedWarrantyEndDate,
            IsUnderAMC:isAMCChecked,
            AMCStartDate:FormatedAMCStartDate,
            AMCEndDate:FormatedAMCEndDate,
            IsActive:'Y',
            CreatedBy:CreatedBy,
            IsRemove:null
        }

        //console.log("CreateAssetPayload",CreateAssetPayload)

        let APIValidation = true
        //console.log("AssetcountValue",AssetcountValue)
        

        //To restrict API validation when asset count exceeds...
        if(AssetcountValue >= GlobalAssetCount)
                {
                        Swal.fire({
                        html: '<span style="font-size: 1.3rem;">Maximum Asset count reached</span>',
                        confirmButtonText: 'OK',
                        allowOutsideClick:false
                            });
                        //Failed('Max Asset count reached')
                        APIValidation = false;
                }
  
    //SOP-19 Included By PCS[Testing feedback corrections- to restrict Warranty End date to be lesser than Start date]
       else if(!disableWarrantyDate && Warranty_StartDate >= Warranty_EndDate)
        {
                Swal.fire({
                html: '<span style="font-size: 1.3rem;">Check Warranty dates</span>',
                confirmButtonText: 'OK',
                allowOutsideClick:false
                    });
               
                APIValidation = false;
        }
     //EOF SOP-19 Included By PCS[Testing feedback corrections- to restrict Warranty End date to be lesser than Start date]
        
    //SOP-19 Included By PCS[Testing feedback corrections- to restrict AMC End date to be lesser than Start date]
      else if(!disableAMCDate && AMC_StartDate >= AMC_EndDate)
        {
                Swal.fire({
                html: '<span style="font-size: 1.3rem;">Check AMC dates</span>',
                confirmButtonText: 'OK',
                allowOutsideClick:false
                    });
               
                APIValidation = false;
        }
    //EOF SOP-19 Included By PCS[Testing feedback corrections- to restrict AMC End date to be lesser than Start date]
     
    



     if(APIValidation)
        {
                commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/master/asset_create_update_delete`, CreateAssetPayload)
                .then((res) => {
                if (res.status === 200) {
                    Success("Asset created successfully");
                    navigate('/assets')
                }
                }).catch((error) => {
                    console.error("Error:", error);
                });

        }

    }


   //SOP-35-Included by PCS [to get asset count of the company]
     const GetAssetCount = async () => 
       {     
            const AssetCountPayload={
                CompanyId:CompanyId,
                                    }

            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/master/getAssetCount`, AssetCountPayload)
            .then((res) => {
                
            //console.error("response count from API", res.data.data[0].get_asset_count);
            if (res.status === 200) {
                setAssetcountValue( res.data.data[0].get_asset_count)
            }
            }).catch((error) => {
                console.error("Error:", error);
            });
        }
  //EOF SOP-35 [to get asset count of the company]

  //SOP-19 Inlcuded By PCS [Testing feedback corrections- to clear default value '0.00' on mouse click]
        const clearDefaultValue = () => {
            if (PriceValue ==='0.00')
                 {
                setPriceValue('');
                  }
                            }
  //EOF SOP-19 Inlcuded By PCS [Testing feedback corrections -to clear default value '0.00' on mouse click]

    // UseEffect declaration
    useEffect(() => {
        GetCatgoryList();
        // GetSubCatgoryList();
        GetAssetCount(); //SOP-35 Included By PCS [to get asset count ]
      }, []);



    return(
        <>
        <MainContainerWrapper
        headingText='Add Asset'>
            <Box>
            <form noValidate>
                {/* General Information Accordian */}
                <Box>
                <Accordion defaultExpanded sx={{
                            borderRadius:'30px',
                        }}>
                            <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            >
                            
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <FcInfo height={'1rem'} width={'1rem'} fontSize={'1.5rem'} /> 
                                <Typography fontSize={'1rem'} ml={2}>General Information</Typography> 
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                    <Box >
                                        {/* Grid first row */}
                                        <Grid container mb={5} spacing={5} display={'flex'} justifyContent={'center'} >

                                            <Grid item lg={3.5} md={12} xs={12} >
                                            <Autocomplete
                                                id="combo-box-demo"
                                                fullWidth
                                                options={CategoryOptions as OptionType[]}
                                                filterOptions={filterOptions}
                                                getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                value={CategoryOptions.find((option: OptionType) => option.value === selectedCategoryId) || null}
                                                onChange={(event, newValue) => {
                                                //SOP-19 Included By PCS[Testing feedback corrections-to remove subcategoryid on change of category]
                                                       setSelectedSubCategoryId('');
                                                //EOF SOP-19 Included By PCS[Testing feedback corrections-to remove subcategoryid on change of category]
                                                    if (newValue) {
                                                        setSelectedCategoryId(newValue.value);
                                                        GetSubCatgoryList(newValue.value);
                                                        setCategoryError(false);
                                                        setCategoryErrorMessage("");
                                                    } else {
                                                        setSelectedCategoryId('');
                                                        setCategoryError(true);
                                                        setCategoryErrorMessage("Category is required");
                                                    }
                                                }}
                                                // sx={{ width: 235, ml: 1 }}
                                                renderInput={(params) => 
                                                <TextField {...params}
                                                error={CategoryError}
                                                helperText={CategoryErrorMessage}
                                                    required label="Category" 
                                                    variant="standard" />}
                                            />
                                            </Grid>

                                            <Grid item lg={3.5} md={12} xs={12} >
                                            <Autocomplete
                                                id="combo-box-demo"
                                                fullWidth
                                                options={SubCategoryOptions as OptionType[]}
                                                filterOptions={filterOptions}
                                                getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                value={SubCategoryOptions.find((option: OptionType) => option.value === selectedSubCategoryId) || null}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        setSelectedSubCategoryId(newValue.value);
                                                        setSubCategoryError(false);
                                                        setSubCategoryErrorMessage("");
                                                    } else {
                                                        setSelectedSubCategoryId('');
                                                        setSubCategoryError(true);
                                                        setSubCategoryErrorMessage("Sub-Category is required");
                                                    }
                                                }}
                                                // sx={{ width: 235, ml: 1 }}
                                                renderInput={(params) => 
                                                <TextField {...params}
                                                error={SubCategoryError}
                                                helperText={SubCategoryErrorMessage}
                                                    required label="Sub-Category" 
                                                    variant="standard" />}
                                            />

                                            </Grid>

                                            <Grid item lg={3.5} md={12} xs={12} >
                                                <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Asset Name'
                                                    name='AssetName'
                                                    fullWidth
                                                    required
                                                    error={AssetNameError}
                                                    onChange={handleAssetNameChange}
                                                    value={AssetNameValue}
                                                    helperText={AssetNameErrorMessage}
                                                /> 
                                            </Grid>

                                        </Grid>
                                        {/* End of Grid first row */}

                                        {/* Grid Second row */}
                                        <Grid container mb={5} spacing={5} display={'flex'} justifyContent={'center'} >

                                            
                                        <Grid item lg={3.5} md={12} xs={12} >
                                            <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Model Number'
                                                    name='ModelNumber'
                                                    fullWidth
                                                    error={ModelNumberError}
                                                    onChange={handleModelNumberChange}
                                                    value={ModelNumberValue}
                                                    helperText={ModelNumberErrorMessage}
                                                /> 
                                            </Grid>

                                            <Grid item lg={3.5} md={12} xs={12} >
                                            <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Serial Number'
                                                    name='SerialNumber'
                                                    fullWidth
                                                    error={SerialNumberError}
                                                    onChange={handleSerialNumberChange}
                                                    value={SerialNumberValue}
                                                    helperText={SerialNumberErrorMessage}
                                                /> 
                                            </Grid>

                                            <Grid item lg={3.5} md={12} xs={12} >
                                            <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Manufacturer'
                                                    name='Manufracturer'
                                                    fullWidth
                                                    error={ManufracturerError}
                                                    onChange={handleManufracturerChange}
                                                    value={ManufracturerValue}
                                                    helperText={ManufracturerErrorMessage}
                                                /> 

                                            </Grid>

                                        </Grid>
                                        {/* End of Grid Second row */}
                                       
                                    </Box>
                                </AccordionDetails>
                    </Accordion>
                </Box>
                {/* End of General Information Accordian */}

                {/* Purchase Information Accordian */}
                <Box mt={1}>
                <Accordion defaultExpanded sx={{
                            borderRadius:'30px',
                        }}>
                            <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            >
                            
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <BiSolidPurchaseTag height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
                                <Typography fontSize={'1rem'} ml={2}>Purchase Information</Typography> 
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                    <Box sx={{
                                        ml:{
                                            xl:12,
                                            lg:10,
                                            md:0,
                                            xs:0
                                        }
                                    }} >
                                        {/* Grid first row */}
                                        <Grid container mb={5} spacing={5} >
                                        <Grid item lg={3.7} md={12} xs={12} >
                                            <FormControl fullWidth>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        label="Purchase Date"
                                                                        value={PurchaseDateValue ? dayjs(PurchaseDateValue) : null}
                                                                        onChange={handlePurchaseDateChange}
                                                                        format="DD-MM-YYYY"
                                                                        slotProps={{
                                                                            textField: {
                                                                                error: Boolean(PurchaseDateError)
                                                                            }
                                                                        }}
                                                                    />                                                        

                                                                    {PurchaseDateError && (
                                                                        <FormHelperText
                                                                            error
                                                                            id="standard-weight-helper-text-dob-login"
                                                                        >
                                                                            {PurchaseDateErrorMessage}
                                                                        </FormHelperText>
                                                                    )}
                                                                </LocalizationProvider>
                                                 </FormControl>
                                            </Grid>

                                            <Grid item lg={3.7} md={12} xs={12} >
                                            <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Invoice Number'
                                                    name='InvoiceNumber'
                                                    fullWidth
                                                    error={InvoiceNumberError}
                                                    onChange={handleInvoiceNumberChange}
                                                    value={InvoiceNumberValue}
                                                    helperText={InvoiceNumberErrorMessage}
                                                /> 
                                            </Grid>

                                            <Grid item lg={3.7} md={12} xs={12} >
                                            <FormControl fullWidth>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        label="Invoice Date"
                                                                        value={InvoiceDateValue ? dayjs(InvoiceDateValue) : null}
                                                                        onChange={handleInvoiceDateChange}
                                                                        format="DD-MM-YYYY"
                                                                        slotProps={{
                                                                            textField: {
                                                                                error: Boolean(InvoiceDateError)
                                                                            }
                                                                        }}
                                                                    />                                                        

                                                                    {InvoiceDateError && (
                                                                        <FormHelperText
                                                                            error
                                                                            id="standard-weight-helper-text-dob-login"
                                                                        >
                                                                            {InvoiceDateErrorMessage}
                                                                        </FormHelperText>
                                                                    )}
                                                                </LocalizationProvider>
                                                 </FormControl>
                                            </Grid>

                                        </Grid>
                                        {/* End of Grid first row */}

                                        {/* Grid Second row */}
                                        <Grid container mb={5} spacing={5}>

                                        <Grid item lg={3.7} md={12} xs={12} >
                                            <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Price'
                                                    name='Price'
                                                    fullWidth
                                                    error={PriceError}
                                                    onChange={handlePriceChange}
                                                    //SOP-19 Included By PCS Testing feedback corrections[To clear price field's default value]
                                                    onClick={clearDefaultValue}
                                                    //EOF SOP-19 Included By PCS Testing feedback corrections[To clear price field's default value]
                                                    value={PriceValue || ''}
                                                    helperText={PriceErrorMessage}
                                                /> 
                                            </Grid>

                                            
                                            <Grid item lg={3.7} md={12} xs={12} >
                                            <FormControl fullWidth>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        label="Ship Date"
                                                                        value={ShipDateValue ? dayjs(ShipDateValue) : null}
                                                                        onChange={handleShipDateChange}
                                                                        format="DD-MM-YYYY"
                                                                        slotProps={{
                                                                            textField: {
                                                                                error: Boolean(ShipDateError)
                                                                            }
                                                                        }}
                                                                    />                                                        

                                                                    {ShipDateError && (
                                                                        <FormHelperText
                                                                            error
                                                                            id="standard-weight-helper-text-dob-login"
                                                                        >
                                                                            {ShipDateErrorMessage}
                                                                        </FormHelperText>
                                                                    )}
                                                                </LocalizationProvider>
                                                 </FormControl>
                                            </Grid>


                                        </Grid>
                                        {/* End of Grid Second row */}
                                       
                                    </Box>
                                </AccordionDetails>
                    </Accordion>
                </Box>
                {/* End of Purchase Information Accordian */}

                {/* System Information Accordian */}
                <Box mt={1}>
                <Accordion defaultExpanded sx={{
                            borderRadius:'30px',
                        }}>
                            <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            >
                            
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <GrSystem height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
                                <Typography fontSize={'1rem'} ml={2}>System Information</Typography> 
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                    <Box sx={{
                                        ml:{
                                            xl:12,
                                            lg:10,
                                            md:0,
                                            xs:0
                                        }
                                    }}>
                                        {/* Grid first row */}
                                        <Grid container mb={5} spacing={5} >


                                            <Grid item lg={3.7} md={12} xs={12} >
                                                <TextField
                                                        type='text'
                                                        variant="standard"
                                                        label='Service Tag Number'
                                                        name='ServiceTagNumber'
                                                        fullWidth
                                                        error={ServiceTagNumberError}
                                                        onChange={handleServiceTagNumberChange}
                                                        value={ServiceTagNumberValue}
                                                        helperText={ServiceTagNumberErrorMessage}
                                                    /> 

                                            </Grid>

                                            <Grid item lg={3.7} md={12} xs={12} >
                                            <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Ip Address'
                                                    name='IpAddress'
                                                    fullWidth
                                                    error={IpAddressError}
                                                    onChange={handleIpAddressChange}
                                                    value={IpAddressValue}
                                                    helperText={IpAddressErrorMessage}
                                                /> 
                                            </Grid>

                                            <Grid item lg={3.7} md={12} xs={12} >
                                            <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Operating System'
                                                    name='OperatingSystem'
                                                    fullWidth
                                                    error={OperatingSystemError}
                                                    onChange={handleOperatingSystemChange}
                                                    value={OperatingSystemValue}
                                                    helperText={OperatingSystemErrorMessage}
                                                /> 
                                            </Grid>

                                        </Grid>
                                        {/* End of Grid first row */}

                                        {/* Grid Second row */}
                                        <Grid container mb={5} spacing={5} >

                                        <Grid item lg={3.7} md={12} xs={12} >
                                            <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Processor Type'
                                                    name='ProcessorType'
                                                    fullWidth
                                                    error={ProcessorTypeError}
                                                    onChange={handleProcessorTypeChange}
                                                    value={ProcessorTypeValue}
                                                    helperText={ProcessorTypeErrorMessage}
                                                /> 
                                            </Grid> 

                                            <Grid item lg={3.7} md={12} xs={12} >
                                                <TextField
                                                        type='text'
                                                        variant="standard"
                                                        label='Memory In GB'
                                                        name='MemoryInGb'
                                                        fullWidth
                                                        error={MemoryInGbError}
                                                        onChange={handleMemoryInGbChange}
                                                        value={MemoryInGbValue}
                                                        helperText={MemoryInGbErrorMessage}
                                                    /> 
                                                </Grid>

                                                <Grid item lg={3.7} md={12} xs={12} >
                                            <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Express Service Code'
                                                    name='ExpressServiceCode'
                                                    fullWidth
                                                    error={ExpressServiceCodeError}
                                                    onChange={handleExpressServiceCodeChange}
                                                    value={ExpressServiceCodeValue}
                                                    helperText={ExpressServiceCodeErrorMessage}
                                                /> 
                                            </Grid>


                                        </Grid>
                                        {/* End of Grid Second row */}

                                        {/* Grid Third row */}
                                        <Grid container mb={5} spacing={5} >

                                            <Grid item lg={3.7} md={12} xs={12} >
                                            <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Location'
                                                    name='Location'
                                                    fullWidth
                                                    error={LocationError}
                                                    onChange={handleLocationChange}
                                                    value={LocationValue}
                                                    helperText={LocationErrorMessage}
                                                /> 
                                            </Grid>


                                        </Grid>
                                        {/* End of Grid Third row */}
                                       
                                    </Box>
                                </AccordionDetails>
                    </Accordion>
                </Box>
                {/* End of System Information Accordian */}

                {/* Warranty / AMC Accordian */}
                <Box mt={1}>
                <Accordion defaultExpanded sx={{
                            borderRadius:'30px',
                        }}>
                            <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            >
                            
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <PiMedalFill height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
                                <Typography fontSize={'1rem'} ml={2}>Warranty / AMC</Typography> 
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                    <Box sx={{
                                        ml:{
                                            xl:12,
                                            lg:10,
                                            md:0,
                                            xs:0
                                        }
                                    }} >
                                        {/* Grid first row */}
                                        <Grid container mb={5} spacing={2}>
                                            <Grid item lg={3.7} md={12} xs={12}>
                                            <Box>
                                                    <FormControlLabel
                                                        sx={{ 
                                                            // pt: "5px", 
                                                            fontSize:'3rem',
                                                            color:'#2196F3' }}
                                                        label="Warranty"
                                                        onChange={(e)=>{
                                                            WarrantycheckBoxState(e)
                                                           }}
                                                        control={<Checkbox  
                                                            sx={{color:'#2196F3',
                                                            '&.Mui-checked': {
                                                                color: '#2196F3',
                                                            },
                                                        }}/>}
                                                    />   
                                                </Box>                                            
                                            </Grid>
                                        </Grid>
                                        <Grid container mb={5} spacing={2}>
                                        <Grid item lg={3.7} md={12} xs={12} >
                                            <FormControl fullWidth>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        label="Warranty Start Date"
                                                                        value={WarrantyStartDateValue ? dayjs(WarrantyStartDateValue) : null}
                                                                        onChange={handleWarrantyStartDateChange}
                                                                        //SOP-19 Included By PCS [Testing feedback corrections -to date to be higher than from date]
                                                                         maxDate={WarrantyEndDateValue ? dayjs(WarrantyEndDateValue).subtract(1, 'day') : null} 
                                                                        //EOF SOP-19 Included By PCS [Testing feedback corrections -to date to be higher than from date]
                                                                        format="DD-MM-YYYY"
                                                                        disabled={disableWarrantyDate}
                                                                        slotProps={{
                                                                            textField: {
                                                                                error: Boolean(WarrantyStartDateError)
                                                                            }
                                                                        }}
                                                                    />                                                        

                                                                    {WarrantyStartDateErrorMessage && (
                                                                        <FormHelperText
                                                                            error
                                                                            id="standard-weight-helper-text-dob-login"
                                                                        >
                                                                            {WarrantyStartDateErrorMessage}
                                                                        </FormHelperText>
                                                                    )}
                                                                </LocalizationProvider>
                                                 </FormControl>
                                            </Grid>

                                            <Grid item lg={3.7} md={12} xs={12} >
                                            <FormControl fullWidth>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        label="Warranty End Date"
                                                                        value={WarrantyEndDateValue ? dayjs(WarrantyEndDateValue) : null}
                                                                        onChange={handleWarrantyEndChange}
                                                                        disabled={disableWarrantyDate}
                                                                        format="DD-MM-YYYY"
                                                                        //SOP-19 Included By PCS [Testing feedback corrections - warranty end date to be greater than start date]
                                                                        minDate={WarrantyStartDateValue ? dayjs(WarrantyStartDateValue).add(1, 'day') : null} 
                                                                        //EOF SOP-19 Included By PCS [Testing feedback corrections - warranty end date to be greater than start date]
                                                                        slotProps={{
                                                                            textField: {
                                                                                error: Boolean(WarrantyEndDateError)
                                                                            }
                                                                        }}
                                                                    />                                                        

                                                                    {WarrantyEndDateErrorMessage && (
                                                                        <FormHelperText
                                                                            error
                                                                            id="standard-weight-helper-text-dob-login"
                                                                        >
                                                                            {WarrantyEndDateErrorMessage}
                                                                        </FormHelperText>
                                                                    )}
                                                                </LocalizationProvider>
                                                 </FormControl>
                                            </Grid>
                                        </Grid>
                                        {/* End of Grid first row */}

                                        {/* Grid Second row */}
                                        <Grid container mb={5} spacing={2}>

                                            <Grid item lg={3.7} md={12} xs={12} >
                                            <Box>
                                                    <FormControlLabel
                                                        sx={{ 
                                                            // pt: "5px", 
                                                            fontSize:'3rem',
                                                            color:'#2196F3' }}
                                                        label="AMC"
                                                        onChange={(e)=>{
                                                            AMCcheckBoxState(e)
                                                            }}
                                                        control={<Checkbox  
                                                            sx={{color:'#2196F3',
                                                            '&.Mui-checked': {
                                                                color: '#2196F3',
                                                            },
                                                        }}/>}
                                                    />   
                                                </Box> 
                                                
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} display={'flex'}>
                                            <Grid item lg={3.7} md={12} xs={12} >
                                            <FormControl fullWidth>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        label="AMC Start Date"
                                                                        value={AMCStartDateValue ? dayjs(AMCStartDateValue) : null}
                                                                        onChange={handleAMCStartDateChange}
                                                                        //SOP-19 Included By PCS [Testing feedback corrections -to date to be higher than from date]
                                                                        maxDate={AMCEndDateValue ? dayjs(AMCEndDateValue).subtract(1, 'day') : null} 
                                                                        //EOF SOP-19 Included By PCS [Testing feedback corrections -to date to be higher than from date]
                                                                        disabled={disableAMCDate}
                                                                        format="DD-MM-YYYY"
                                                                        slotProps={{
                                                                            textField: {
                                                                                error: Boolean(AMCStartDateError)
                                                                            }
                                                                        }}
                                                                    />                                                        

                                                                    {AMCStartDateErrorMessage && (
                                                                        <FormHelperText
                                                                            error
                                                                            id="standard-weight-helper-text-dob-login"
                                                                        >
                                                                            {AMCStartDateErrorMessage}
                                                                        </FormHelperText>
                                                                    )}
                                                                </LocalizationProvider>
                                                 </FormControl>
                                            </Grid>

                                            <Grid item lg={3.7} md={12} xs={12} >
                                            <FormControl fullWidth>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        label="AMC End Date"
                                                                        value={AMCEndDateValue ? dayjs(AMCEndDateValue) : null}
                                                                        onChange={handleAMCEndDateChange}
                                                                        disabled={disableAMCDate}
                                                                        //SOP-19 Included By PCS [Testing feedback corrections -AMC end date to be greater than start date]
                                                                        minDate={AMCStartDateValue ? dayjs(AMCStartDateValue).add(1, 'day') : null}
                                                                        //SOP-19 Included By PCS [Testing feedback corrections -AMC end date to be greater than start date]
                                                                        format="DD-MM-YYYY"
                                                                        slotProps={{
                                                                            textField: {
                                                                                error: Boolean(AMCEndDateError)
                                                                            }
                                                                        }}
                                                                    />                                                        

                                                                    {AMCEndDateErrorMessage && (
                                                                        <FormHelperText
                                                                            error
                                                                            id="standard-weight-helper-text-dob-login"
                                                                        >
                                                                            {AMCEndDateErrorMessage}
                                                                        </FormHelperText>
                                                                    )}
                                                                </LocalizationProvider>
                                                 </FormControl>
                                            </Grid>
                                        </Grid>
                                        {/* End of Grid Second row */}                                       
                                    </Box>
                                </AccordionDetails>
                    </Accordion>
                </Box>
                {/* End of Warranty / AMC Accordian */}

                {/* Notes Accordian */}
                <Box mt={1}>
                <Accordion defaultExpanded sx={{
                            borderRadius:'30px',
                        }}>
                            <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            >
                            
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <LiaEditSolid height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
                                <Typography fontSize={'1rem'} ml={2}>Notes</Typography> 
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                    <Box sx={{
                                        ml:{
                                            xl:12,
                                            lg:10,
                                            md:0,
                                            xs:0
                                        }
                                    }} >
                                        {/* Grid first row */}
                                        <Grid container mb={5} spacing={5} display={'flex'} justifyContent={'center'} >
                                            
                                        <Grid item lg={12} md={12} xs={12} >
                                            <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Notes'
                                                    name='Notes'
                                                    fullWidth
                                                    multiline
                                                    onChange={handleNotesChange}
                                                    value={NotesValue}
                                                /> 

                                            </Grid>
                                        </Grid>
                                       
                                    </Box>
                                </AccordionDetails>
                    </Accordion>
                </Box>
                {/* End of Notes Accordian */}

                <Box>                    
                    <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Button
                                        sx={{
                                            background: 'white',
                                            border: 'none',
                                            borderRadius: '5px',
                                            padding:{
                                                lg: "0 70px",
                                                md: "0 70",
                                                xs:'0 50px'
                    
                                            },
                                            height: "3rem",
                                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                        }}

                                        onClick={()=>{
                                            //SOP-19 [Included By PCS -Testing feedback corrections ]
                                            // navigate('/employee')
                                               navigate('/assets')
                                        }} 
                                    >
                                        Back
                                    </Button>

                                    <Button
                                        sx={{
                                            background: '#25425F',
                                            border: 'none',
                                            borderRadius: '5px',
                                            padding:{
                                                lg: "0 70px",
                                                md: "0 70",
                                                xs:'0 50px'
                                            },
                                            "&:hover":{
                                                backgroundColor:'#25425F',
                                                color:'white',
                                            },
                                            color: 'white',
                                            height: "3rem",
                                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                        }}
                                        type='submit'
                                        onClick={(event)=>{
                                            CreateAsset(event);
                                        }}
                                    >
                                        Save
                                    </Button>
                    </Box>
                </Box>

            </form>
                
            </Box>
        </MainContainerWrapper>
        
        </>
    )

}

export default AddAssetPage;

// End of SOP-19