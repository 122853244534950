// SOP-61 Included By PCS

// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Success } from '../../atic-common-helpers/helpers/toast.helper';
import Swal from "sweetalert2";

import { Link as MuiLink } from '@mui/material';


// Page start
const ActivityHistory = 
// To get handleActivityHistoryClose & getcategorylistsearch from category page
    ({
        handleActivityHistoryClose,
        // getcategorylistsearch
    }: {
        handleActivityHistoryClose: (event: React.MouseEvent) => void,
        // getcategorylistsearch: (event: React.MouseEvent) => void
    }) =>{

    // use navigate and use state declaration
    const navigate = useNavigate();

    const [DocumentsHistory, setDocumentsHistory] = useState<ActivityDocument[]>([]);
    // console.log('History',DocumentsHistory)

    interface DocumentHistory {
        author: string;
        dateCreated: string;
        dateModified: string;
        versionNumber:string;
    }
    
    interface ActivityDocument {
        documentTitle: string;
        history: DocumentHistory[];
    }

    const [DocumentTitle, setDocumentTitle] =  useState(''); //Initialize document Titlte
    const [DocumentId, setDocumentId] = useState('')

     const GlobalDataFromSession = sessionStorage.getItem("GlobalVariable") || '{}';
     // const DocumentIdFromSession = sessionStorage.getItem("DocumentId") || '';

    const DocumentIdFromSession:any = sessionStorage.getItem("DocumentId") ;
    // console.log('DocumentIdFromSession',DocumentIdFromSession)

    const GlobalData = JSON.parse(GlobalDataFromSession);
    let CompanyId = GlobalData.GlobalCompanyId;


    // To render handle close function to close dialouge box
            const handleClose = (event: React.MouseEvent) => {
                if (handleActivityHistoryClose && typeof handleActivityHistoryClose === 'function') {
                    handleActivityHistoryClose(event);
                }
                                                            };

 // Define a type for errors
    type Errors = {
        category?:boolean;
                  };


//To get documents Activity History
const ViewActivityHistory = async ()=>
    {

        const DocumentIdFromSession:any = sessionStorage.getItem("DocumentId") ;
        const payload={CompanyId:CompanyId,DocumentId:DocumentIdFromSession}

        // console.log('pl',payload)
        commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/docpoint/get_documentdetails_activityhistory_by_id`,payload )
            .then((res: any) => 
            { 
            // console.log("response",res.data.data)
            setDocumentsHistory(res.data.data)
            }).catch((error) =>  
            { 
                console.log('err',error)
            //Failed(error.response.message);
            });

    } 


    // UseEffect Declaration
        useEffect(() => {
             ViewActivityHistory();
             const DocumentTitleFromSession: any=sessionStorage.getItem("DocumentTitleValue")
            setDocumentTitle(DocumentTitleFromSession)
            setDocumentId(DocumentIdFromSession);
           
        }, [DocumentIdFromSession]);

 
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
     };

     const reverseDate = (dateStr:any) => {
        // console.log('dateStr',dateStr)

        let date = new Date(dateStr);

        date.setHours(date.getHours() + 5);
        date.setMinutes(date.getMinutes() + 30);
        
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getUTCDate()).padStart(2, '0');
        return `${day}/${month}/${year} `;
    };

    return(
        <>
            <Box>
                <Box display={'flex'} justifyContent={'center'}>
                    <Box bgcolor={'white'} marginLeft={1}  borderRadius={3}
                         sx={{
                            width:{
                                lg:1100,
                                md:600,
                                xs:700},
                                // height:250
                            }}
                        >
                        <Box p={3} ml={1}>
                             <Typography variant='h2'>Activity/ Revision History</Typography>
                            <Box sx={{ marginTop:'8px',border: '2px solid #3498db',borderRadius:'1px' ,padding:'8px' }}>
                               
                                
                                <Typography mt={2}  color={'#3498db'} variant='h4'>Document Title  :  { DocumentTitle}</Typography>
                               
                             
                                    {DocumentsHistory.map((record:any, index:any) => (

                                    <div style={{display:'flex',marginTop:'5px'}}>
                                     <Grid container mt={1} spacing={1} display={'flex'} justifyContent={'left'}sx={{}} >
                                        <Grid item lg={2} md={12} xs={12}>
                                                <Typography variant='h6' sx={{color:'#3498db',fontsize:'8px',marginLeft:'8px',fontWeight:600}}>{record.modified_by_employee_name? record.modified_by_employee_name  : record.created_by_employee_name   }</Typography>
                                        </Grid>   
                                        < Grid item lg={7} md={12} xs={12}>    
                                            
                                                <Typography sx={{marginLeft:'8px'}}>
                                                    {record.lastmodifieddate 
                                                        ? `Modified Document on : ${reverseDate(record.lastmodifieddate)}`
                                                        : `Created Document on : ${reverseDate(record.createddatetime)}`
                                                    }
                                                </Typography>
                                            
                                        </Grid>
                                        < Grid item lg={2} md={12} xs={12}>  
                                             <MuiLink  
                                               onClick={() =>{
                                                sessionStorage.setItem("VersionNumber",record.document_version_number)
                                                navigate(`/view_document`)
                                            
                                                   }} 
                                                href="#" sx={{color:'#3498db',marginBottom:'1%', marginLeft: '2%', fontSize: '14px',textDecoration: 'underline' }}>
                                                    Ver : {record.document_version_number}
                                               
                                            </MuiLink>

                                        </Grid>
                                    </Grid>
                                </div>
                               
                            ))}
                        </Box>

                        </Box>
                        </Box>

                    </Box>
                    
                </Box>
                <Box mb={2} ml={3} alignItems={'center'} 
                
                >
                                <Button
                                    sx={{
                                        background: 'white',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70px",
                                            xs:'0 50px'
                
                                        },
                                        
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}

                                    onClick={handleClose} 
                                >
                                    Back
                                </Button>

                              
                </Box>
           
        </>
    )

}

export default ActivityHistory;

// EOF SOP-61