// SOP-21 Included By PCS
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
 import AddDesignationPage from './AddDesignation';
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
 import EditDesignationPage from './EditDesignation';
import deleteAlert from '../../component/HOC/deleteAlert';


const Designation =()=>{


      // Use navigate
      const navigate = useNavigate();

       // useState
       let  [order, setOrder] = React.useState<"desc" | "asc">("desc");
       let  [orderBy, setOrderBy] = React.useState("");
       let  [tableData, setTableData] = React.useState([]);
       const [page, setPage] = React.useState<number>(0);
       const [count, setCount] = useState<number>(0);
       const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
       const [AddDesignationOpen, setAddDesignationOpen] = React.useState(false);
       const [EditDesignationOpen, setEditDesignationOpen] = React.useState(false);
       const [EditDesignationId, setEditDesignationId]= useState('')
      //  let initialActivestatus = "Active"


      //  To open add dialouge box
       const handleAddDesignationOpen = () => {
        setAddDesignationOpen(true);
        };
    
        // To close add dialouge box
        const handleAddDesignationClose = () => {
          setAddDesignationOpen(false);
        };

        // To open edit dialouge box
        const handleEditDesignationOpen = (id:any) => {
            setEditDesignationOpen(true);
            //console.log("id",id)
            setEditDesignationId(id)
         };
    
        // To close edit dialouge box
        const handleEditDesignationClose = () => {
          setEditDesignationOpen(false);
        };





        // Head cell of designation
    const DesignationHeadCell = [
        
        {
         id: "designationname",
         numeric: false,
         disablePadding: false,
         label: "Designation",
         isSort: true,
         isBold: true,
       },
       {
        id: "active",
        numeric: false,
        disablePadding: false,
        label: "Active",
        isSort: true,
        isBold: true,
        // defaultValue:initialActivestatus
      },
      {
        id: "action",
        numeric: false,
        disablePadding: false,
        label: "Actions",
        isSort: false,
      },
       ];



       const [cells, setCells] = useState<any>(DesignationHeadCell);

      //  Global data from session storage
       const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
       const GlobalData = JSON.parse(GlobalDataFromSession);
       //console.log("GlobalData",GlobalData)
       let CompanyId = GlobalData.GlobalCompanyId
       //console.log("CompanyId",CompanyId)

      //  To get designation list search API  
       const getdesignationlistsearch = () => 
       {
     
     // Initializing Variable 
         let DesignationName = null;
         let bActiveState = ""; 
     
       for (const item of cells)
       {
          if (item.id === "designationname") {
            DesignationName = item.value;
         }
        
         if (item.id === "active") {
            bActiveState = item.value;
           //console.log("item.value",item.value)
           //console.log("bActiveState",bActiveState)
         }
       }

      //  if(bActiveState === ""){
      //   initialActivestatus = ""
      //  }

         commonService
         .postService(`${process.env.REACT_APP_BACKEND_URL}/master/get_designation_list_search`, {
            SearchDesignationName:DesignationName,
            SearchDesignationActive:  bActiveState,
            SortColumnName: orderBy,
            SortColumnDirection: order,
            StartIndex: page + 1,
            PageSize: rowsPerPage,
            CompanyId:CompanyId,
         })
         .then((res: any) => 
         {
            //console.log("res",res)
         const formattedTableData= res.data.data.map((item:any)=>{
         return {...item,
            id:item.designationid
        }
           }) 
           //console.log("formattedTableData",formattedTableData)
           
         setTableData(formattedTableData);
         if(res.data.data[0]===undefined)
           {
             setCount(0);
           }
           else
           {
             setCount(res.data.data[0].total_count);
           }
         })
       .catch((error) => 
         {
           if (error.response.status === 404)
           {
             setTableData([]);
           }
          Failed(error.response.message);
         });
       };

       
    // Sort Handler
    const handleRequestSort = (event: any, property: any) =>
    {
    const isAsc = orderBy === property && order === "asc";
    order = isAsc ? "desc" : "asc";
    setOrder(order);
    orderBy = property;
    setOrderBy(orderBy);
    };

    // Handle Delete
    const handleDeleteFleet = (id: string) => {
        deleteAlert({
          title: "Delete",
          message: "Are you sure?",
          confirmButtonText: "Yes",
          onConfirmed: DeleteDesignation,
          data: id,
        });
      };

  const handleChangeList = () => {
    if (page === 0) getdesignationlistsearch();
    else setPage(0);
  };

  const DeleteDesignation = (id: string) =>{

    commonService
      .postService(`${process.env.REACT_APP_BACKEND_URL}/master/designation_create_update_delete`, 
      {
        SaveUpdateKey: null,
        DesignationName: null,
        DesignationId: id,
        CompanyId: CompanyId,
        Active: null,
        CreatedDateTime: null,
        CreatedBy: null,
        IsRemove: 'Y'
      })
      .then((res: any) => {
        if (res.status === 200) {
            Success("Designation Deleted Successfully");
            handleChangeList();
            getdesignationlistsearch();
        }
      })
      .catch((err) => {
        // Modified by sanjana SOP-52 to add failed message content
        // Failed("");
        Failed("Reference information is found in the transaction table");
        // End of SOP-52
      });
   }


    // Action function of table icons
    const actionFunction = (id: string, actionText: string) => 
    {
        if (actionText === "edit"){
            handleEditDesignationOpen(id);
        };
        if (actionText === "delete") handleDeleteFleet(id)
    };
  

    // Search Handler of table
    const handleSearch = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const search = [...cells];
        const index = cells.findIndex((c: any) => c.id === e.target.name);
        //console.log("e.target.name",e.target.name)
        if (index !== -1) {
            search[index].value = e.target.value;
        }
        //console.log("search",search)
        setCells(search);
        handleChangePage(setPage,0);
        getdesignationlistsearch();
    };


    // Pagination Handler of table
    const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    };

    // Rows Per page Handler
    const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
        ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    };


    useEffect(() => {
        getdesignationlistsearch();
      }, [rowsPerPage, page, orderBy, order]); 
     



// Design start
    return(
        <>
        <MainContainerWrapper 
        headingText="Designation"
        handleClick={() =>{
            handleAddDesignationOpen()
        }}
        // handleSearch={handleSearch}
        buttonText="Add Designation"
        isSearch={false}
        // search={search}
        isCreateButton={true}
        buttonIcon={<span>&#43;</span>}
        >
            <Box>
            <Box bgcolor={'white'}>
            <Box>
            <EnhancedTable
                headCells={DesignationHeadCell}
                rows={tableData}
                rowsPerPage={rowsPerPage}
                page={page}
                handleSearch={handleSearch}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                actionFunction={actionFunction}
                isView={false}
                isDelete={true}
                isEdit={true}
                total={count}
                isSearch={true}
                />
            </Box>

        </Box>
          {/* Add Designation dialog box */}
            <Box>
                <Dialog  open={AddDesignationOpen}  >
                    <AddDesignationPage handleAddDesignationClose={handleAddDesignationClose}
                    getdesignationlistsearch={getdesignationlistsearch} />
                </Dialog>
            </Box>
            {/* End of Add designation */}

            {/* Edit designation dialog box */}
            <Box>
                <Dialog  open={EditDesignationOpen}  >
                    <EditDesignationPage handleEditDesignationClose={handleEditDesignationClose}
                    getdesignationlistsearch={getdesignationlistsearch} DesignationId={EditDesignationId}  />
                </Dialog>
            </Box>
            {/* End of Edit designation */}
          </Box>
          <Box mt={5} display={'flex'} justifyContent={'flex-start'}>
          <Button
              sx={{
                  background: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  padding:{
                      lg: "0 70px",
                      md: "0 70",
                      xs:'0 50px'

                  },
                  // color: {accent},
                  height: "3rem",
                  boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
              }}

              onClick={()=>{
                navigate('/backoffice')
              }} 
          >
              Back
          </Button>

          </Box>
        </MainContainerWrapper>
        </>
    )
// Design end
}

export default Designation;


// EOF SOP-21
