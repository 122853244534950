// Included by sanjana SOP-44 to add forget password page
//User Management - Forget Password
import { useState, useRef, useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Success } from '../../atic-common-helpers/helpers/toast.helper';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import CryptoJS from "crypto-js";




   const ForgotPasswordPage = () => {

    const {id} = useParams();
    const RegistredEmail =id
    console.log("RegistredEmail",RegistredEmail)

    // use navigate declaration
    const navigate = useNavigate();

    const [PasswordError, setPasswordError]=useState(false)
    const [PasswordValue, setPasswordValue]=useState('')
    const [PasswordErrorMessage, setPasswordErrorMessage] = useState('')  
    const [ConfirmPasswordError, setConfirmPasswordError]=useState(false)
    const [ConfirmPasswordValue, setConfirmPasswordValue]=useState('')
    const [ConfirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState('')  


    const secretPass = "XkhZG4fW2t2W";   

        // To encrypt password

        const encryptData = async () => {
            try {
              const data = CryptoJS.AES.encrypt(JSON.stringify(ConfirmPasswordValue),secretPass).toString();
                //console.log("Encrypted Data:", data);
                //  setEncrptedData(data);
                return data
            } catch (error) {
              console.error("Error encrypting data:", error);
            }
          };

          
        // To decrypt  
        let decryptedData : any; 
        if (RegistredEmail && typeof RegistredEmail === 'string' && RegistredEmail.trim() !== '') {
            const bytes = CryptoJS.AES.decrypt(RegistredEmail, secretPass);
            // console.log("bytes",bytes)
            decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }         
        console.log("decryptedData",decryptedData)

        
    

    // Handle password while typing data
    const handlePasswordChange = (event:any) => {
        const trimmedPasswordValue = event.target.value.trim();
        setPasswordValue(event.target.value);
  
        if (ConfirmPasswordValue !== '') {
          if (trimmedPasswordValue !== ConfirmPasswordValue) {
              setConfirmPasswordError(true);
              setConfirmPasswordErrorMessage("Confirm password is not matching");
  
          } else {
            setConfirmPasswordError(false);
            setConfirmPasswordErrorMessage("");
          }
        }
  
         const strongPasswordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/;
          
        if (trimmedPasswordValue === '') {
            setPasswordError(true);
            setPasswordErrorMessage("Password is required");
        }
         //IF user create a week password like:12345,then need to suggest(Alert message to display -Enter a strong password ]like combination with alphanumeric "Test@123" 
          else if (!strongPasswordRegex.test(trimmedPasswordValue)) {
              setPasswordError(true);
              setPasswordErrorMessage("Enter a strong password (Must contains atleast one character, number & special character)");
          }          
        else if( trimmedPasswordValue.length >12 ){
            setPasswordError(true);
            setPasswordErrorMessage("Max 12 characters");
        }
          else {
            setPasswordError(false);
            setPasswordErrorMessage("");
        }
      };
      
      
      
      
      const handleConfirmPasswordChange = (event:any) => {
        const trimmedConfirmPasswordValue = event.target.value.trim();
        setConfirmPasswordValue(event.target.value);
        if (trimmedConfirmPasswordValue === '') {
            setConfirmPasswordError(true);
            setConfirmPasswordErrorMessage("Confirm Password is required");
        } 
        else if( trimmedConfirmPasswordValue.length >12 ){
            setConfirmPasswordError(true);
            setConfirmPasswordErrorMessage("Max 12 characters");
        }
        else if( PasswordValue !== trimmedConfirmPasswordValue){
          setConfirmPasswordError(true);
          setConfirmPasswordErrorMessage("Confirm password is not matching");
      }
        else {
            setConfirmPasswordError(false);
            setConfirmPasswordErrorMessage("");
        }
      };

    // To get company user details API as per the email 
    const ToGetCompanyUserDetails = async ()=>{

    const  CompanyUserDetailsPayload = {
        UserName:decryptedData,
        Active:"Y"
    }
    // console.log("CompanyUserDetailsPayload",CompanyUserDetailsPayload)
    try {
    const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/company/getCompanyUserDetails`,CompanyUserDetailsPayload);
    console.log("res",res.data.data);
    return res.data.data[0] || [];
    } catch (err) {
    console.log( "co",err); 
    } 
    }

    // Login button function 

    type Errors = {
        password?:boolean;
        confirmPassword?:boolean;
        };

    const handleSubmit = async (event :any)=>{
        event.preventDefault();

        const GetEncryptedData = await encryptData()

        let errors: Errors = {};

        const CompanyDetails = await ToGetCompanyUserDetails();
        // console.log("CompanyDetails",CompanyDetails)

        const strongPasswordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/;
    
        const trimmedPasswordValue = PasswordValue.trim();
        if (trimmedPasswordValue === '') {
            errors.password = true;
            setPasswordErrorMessage("Password is required");
        } 
        else if( trimmedPasswordValue.length >12 ){
            errors.password = true;
            setPasswordErrorMessage("Max 12 characters");
        } 
        else if (!strongPasswordRegex.test(trimmedPasswordValue)) {
            setPasswordError(true);
            errors.password = true;
            // Modified by sanjana SOP-41 to change Test@123 as Must contains atleast one character, number & special character
            // setPasswordErrorMessage("Enter a strong password (e.g., Test@123)");
            setPasswordErrorMessage("Enter a strong password (Must contains atleast one character, number & special character)");
            // End of SOP-41
        }      
        if (ConfirmPasswordValue !== '') {
            if (trimmedPasswordValue !== ConfirmPasswordValue) {
                errors.confirmPassword = true;
              setConfirmPasswordError(true);
              setConfirmPasswordErrorMessage("Passwords must match");
            } else {
              setConfirmPasswordError(false);
              setConfirmPasswordErrorMessage("");
            }
          }
    
        const trimmedConfirmPasswordValue = ConfirmPasswordValue.trim();
        if (trimmedConfirmPasswordValue === '') {
            errors.confirmPassword = true;
            setConfirmPasswordErrorMessage("Confirm Password is required");
        }
        else if( trimmedConfirmPasswordValue.length >12 ){
            errors.confirmPassword = true;
            setConfirmPasswordErrorMessage("Max 12 characters");
        }
        else if( PasswordValue !== trimmedConfirmPasswordValue){
          errors.confirmPassword = true;
          setConfirmPasswordErrorMessage("Passwords must match");
      }


        if (Object.keys(errors).length > 0) {
            setPasswordError(errors.password || false);
            setConfirmPasswordError(errors.confirmPassword || false);
            return;
        }
        
        const UpdatePasswordPayload = {           
            CompanyId: CompanyDetails.companyid,
            EmployeeId: CompanyDetails.employee_id,
            UserId: CompanyDetails.userid,
            Password:GetEncryptedData
        }
    
        console.log("UpdatePasswordPayload", UpdatePasswordPayload);
    
      commonService
      .postService(`${process.env.REACT_APP_BACKEND_URL}/company/SetForgotPassword`, UpdatePasswordPayload)
      .then((res) => {
        if (res.status === 200) {
            Success("Password Updated Successfully");
            navigate('/')
          }
        }).catch((error) => {
            console.error("Error:", error);
        });    
    }

    return(
        <Box display={'flex'} justifyContent={'center'} >
            <Box 
            mt={25}
            bgcolor={'white'}
            boxShadow={'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'}
            >
             <form noValidate onSubmit={handleSubmit}>
                <Box mt={2} sx={{
                    ml:{
                        lg:4,
                    }
                }}>
                    <Box display={'flex'} justifyContent={'space-evenly'}>
                        <Typography fontSize={'1.3rem'} fontWeight={'bold'} >
                            Reset Password
                        </Typography>
                    </Box>
                    <Box>
                        <TextField
                            type='password'
                            variant="standard"
                            label='Enter New Password'
                            name='Password'
                            sx={{ margin: '0 10px', 
                            width:{
                                lg:'30rem',
                                xs: '30rem',
                                marginTop:'40px',
                            } }}
                            required
                            value={PasswordValue}
                            error={PasswordError}
                            helperText={PasswordErrorMessage}
                            onChange={handlePasswordChange}
                            autoFocus  // Add autoFocus prop here
                        />                            
                    </Box>
                    <Box>
                        <TextField
                            type='password'
                            variant="standard"
                            label='Confirm Password'
                            name='ConfirmPassword'
                            sx={{ margin: '0 10px', 
                            width:{
                                lg:'30rem',
                                xs: '30rem',
                                marginTop:'40px',
                            } }}
                            required
                            value={ConfirmPasswordValue}
                            error={ConfirmPasswordError}
                            helperText={ConfirmPasswordErrorMessage}
                            onChange={handleConfirmPasswordChange}
                        />                            
                    </Box>
                </Box>  
                <Box p={3} mt={5} mb={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Button
                    style={{
                        background: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        padding: "0 70px",
                        // color: {accent},
                        height: "3rem",
                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                    }}
                    // onClick={handleClose} 
                >
                    Back
                </Button>
                <Button
                style={{
                    background: '#4169e1',
                    border: 'none',
                    borderRadius: '5px',
                    padding: "0 70px",
                    color: 'white',
                    height: "3rem",
                    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                }}
                type='submit'
                >
                    Submit
                </Button>     
                </Box>
             </form>
            </Box>

        </Box>
    )
}
export default ForgotPasswordPage;

// End of SOP-44