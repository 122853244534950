// SOP-61 Included By PCS

// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Checkbox,
    Paper,
    Divider,
    Dialog,
    FormControlLabel,
    Tooltip,
    FormControl,
FormHelperText,

} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { RxCross2 } from "react-icons/rx";
import { fontSize } from '@mui/system';

import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import deleteAlert from '../../component/HOC/deleteAlert';
import AddManageAccess from './AddManageAccess';
import EditManageAccess from './EditManageAccess';
import { FaCheck } from "react-icons/fa6";


// Page start
const ManageAccess = 
// To get handleAddAccessClose &  from Docpoint page
    ({
      handleAddAccessClose,
      }: {
      handleAddAccessClose: (event: React.MouseEvent) => void,
     }) =>{

    // use navigate and use state declaration
     const navigate = useNavigate();
     const [DocumentTitle, setDocumentTitle] =  useState(''); //Initialize document Titlte

  // Declaring UseState
    let  [order, setOrder] = React.useState<"desc" | "asc">("desc");
    let  [orderBy, setOrderBy] = React.useState("");
    let  [tableData, setTableData] = React.useState([]);

    const [page, setPage] = React.useState<number>(0);
    const [count, setCount] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);


    const [AddAccessOpen, setAddAccessOpen] = React.useState(false);
    const [EditAccessOpen, setEditAccessOpen] = React.useState(false);
    const [EditUserId, setEditUserId]= useState('')

      //  To open add dialouge box
      const handleAddAccessOpen = () => {
          setAddAccessOpen(true);
      };

 // To close add dialouge box
    const handleAddUserAccessClose = () => {
        setAddAccessOpen(false) };

 // To open edit dialouge box
    const handleEditAccessOpen = (id:any) => {
        setEditAccessOpen(true);
        setEditUserId(id)
        };

 // To close edit dialouge box
    const handleEditAccessClose = () => {
        setEditAccessOpen(false);
    };


 // Head cell of Document
  const DocumentHeadCell = [
 
      {
        id: "username",
        numeric: false,
        disablePadding: false,
        label: "User Name",
        isSort: false,
        isBold: true,
      },
      {
        id: "editvalue",
        numeric: false,
        disablePadding: false,
        label: "Edit",
        isSort: false,
        isBold: true,
      },
      {
        id: "deletevalue",
        numeric: false,
        disablePadding: false,
        label: "Delete",
        isSort: false,
        isBold: true,
       
      },

      {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: "Actions",
      isSort: false,
      },
      
  ];

     const [cells, setCells] = useState<any>(DocumentHeadCell);

    //  Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
      const GlobalData = JSON.parse(GlobalDataFromSession);
     //console.log("GlobalData",GlobalData)
      let CompanyId = GlobalData.GlobalCompanyId

        const [DocumentId, setDocumentId] = useState('');
        const DocumentIdFromSession:any = sessionStorage.getItem("DocumentId") ;
        // console.log('DocumentIdFromSession',DocumentIdFromSession)


//  To get Document list search API  
    const getdocumentlistsearch = () => 
        {

            // Initializing Variable 
              let UserName = "";
              let EditValue = "";
              let DeleteValue = "";
        

              for (const item of cells)
              {
                    if (item.id === "username") {
                      UserName = item.value;
                    }
                    if (item.editvalue === "editvalue") {
                       EditValue = item.value;
                                                }
                    if (item.deletevalue=== "deletevalue") {
                      DeleteValue = item.value;
                    }
              }
              const Payload=
                {
                  DocumentId:DocumentIdFromSession,
                  CompanyId: CompanyId,
                  SortColumnName: '',
                  SortColumnDirection: '',
                  StartIndex: page + 1,
                  PageSize: rowsPerPage,
                  
                }

                // console.log('payload')
        
                commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/docpoint/get_documents_userrights_list_search`, Payload)
                .then((res: any) => 
                {
                  // console.log("document",res)
                    const formattedTableData= res.data.data.map((item:any)=>{
                      return {...item,
                          
                          id: item.document_userrights_id, //this 'id' is passed to edit and delete pages ,
                          editvalue:item.editvalue === 1 ? <FaCheck/> : <RxCross2 style={{fontSize:'16px'}}/>,
                          deletevalue:item.deletevalue===1?<FaCheck/> : <RxCross2 style={{fontSize:'16px'}}/>,
                              }}) 
                          

                // console.log('formatted table',formattedTableData)
                setTableData(formattedTableData);
                
                if(res.data.data[0]===undefined)
                  {
                    setCount(0);
                  }
                  else
                  {
                    setCount(res.data.data[0].total_count);
                  }
            })
          .catch((error) => 
            {
              console.log('erre',error)
              if (error.response.status === 404)
              {
                  setTableData([]);
                }
              Failed(error.response.message);
              });
       };


  // Sort Handler
    const handleRequestSort = (event: any, property: any) =>
    {
        const isAsc = orderBy === property && order === "asc";
        order = isAsc ? "desc" : "asc";
        setOrder(order);
        orderBy = property;
        setOrderBy(orderBy);
    };

   // Handle Delete
const handleDeleteFleet = (id: string) => {

      deleteAlert({
        title: "Delete",
        message: "Are you sure?",
        confirmButtonText: "Yes",
        onConfirmed: DeleteDocument,
        data: id,
                   });
      };

    const handleChangeList = () => {

    if (page === 0) getdocumentlistsearch();
    else setPage(0);

    };

const DeleteDocument = (id: string) =>{

  const deletePayload={
    SaveUpdateKey: null,
    DocumentId: null,
    CompanyId: CompanyId,
    UserId: null,
    EditValue:null,
    DeleteValue:null,
    IsRemove: 'Y',
    UserRightsId:id
                  }

  // console.log('dp',deletePayload)
    commonService
    .postService(`${process.env.REACT_APP_BACKEND_URL}/docpoint/manageaccess_userrights_create_update_delete`, deletePayload)
    .then((res: any) => {
    if (res.status === 200) {
        Success("User rights Deleted Successfully");
        handleChangeList();
        getdocumentlistsearch();
    }
    })
    .catch((err) => {
    

    });
}


    // Action function of table icons
    const actionFunction = (id: string, actionText: string) => 
    {
        if (actionText === "edit"){
            handleEditAccessOpen(id);
        };
        if (actionText === "delete") handleDeleteFleet(id)
    };


  // Search Handler of table
      const handleSearch = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const search = [...cells];
      const index = cells.findIndex((c: any) => c.id === e.target.name);
      
      if (index !== -1) {
          search[index].value = e.target.value;
      }
      
      setCells(search);
      handleChangePage(setPage,0);
      getdocumentlistsearch();
      };


  // Pagination Handler of table
      const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
      };

  // Rows Per page Handler
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
        ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    };


      useEffect(() => {
          getdocumentlistsearch();
          const DocumentTitleFromSession: any=sessionStorage.getItem("DocumentTitleValue")
          setDocumentTitle(DocumentTitleFromSession)
      }, [rowsPerPage, page, orderBy, order]); 

    // To render handle close function to close dialouge box
            
    const handleClose = (event: React.MouseEvent) => {
      if (handleAddAccessClose && typeof handleAddAccessClose === 'function') {
          handleAddAccessClose(event);
      }
     
         
                                            };


// Design start
    return(
    <>
    <MainContainerWrapper 
    
      headingText=""

    //  headingText={
    //   <span style={{ color: 'blue', fontSize: '2rem', fontWeight: 'bold' }}>
    //     {DocumentTitle}
    //   </span>
    // }



    handleClick={() =>{
        handleAddAccessOpen()
    }}
    
    buttonText="Add Access"
    isSearch={false}
    isCreateButton={true}
    buttonIcon={<span>&#43;</span>}
    >
      <Typography sx={{backgroundColor:'white'}} variant='h2'>{DocumentTitle}</Typography>
        <Box>
        <Box bgcolor={'white'}>
        <Box>
        <EnhancedTable
            headCells={DocumentHeadCell}
            rows={tableData}
            rowsPerPage={rowsPerPage}
            page={page}
            handleSearch={handleSearch}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
            actionFunction={actionFunction}
            isView={false}
            isDelete={true}
            isEdit={true}
            total={count}
            isSearch={false}
            />
        </Box>

    </Box>
      {/* Add Document dialog box */}
        <Box>
            <Dialog  open={AddAccessOpen}  >
                <AddManageAccess handleAddUserAccessClose={handleAddUserAccessClose} 
                getdocumentlistsearch={getdocumentlistsearch} /> 
            </Dialog>
        </Box>
        {/* End of Add Document */}

        {/* Edit Document dialog box */}
        <Box>
            <Dialog  open={EditAccessOpen}  >
                <EditManageAccess handleEditAccessClose={handleEditAccessClose}
                getdocumentlistsearch={getdocumentlistsearch} EditUserId={EditUserId} />
            </Dialog>
        </Box>
        {/* End of Edit Document */}
      </Box>
      <Box mt={5} display={'flex'} justifyContent={'flex-start'}>
      <Button
          sx={{
            marginLeft:'12px',
              background: 'white',
              border: 'none',
              borderRadius: '5px',
              padding:{
                  lg: "0 70px",
                  md: "0 70",
                  xs:'0 50px'

              },
              
              height: "3rem",
              boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
          }}
          onClick={handleClose} 
      >
          Back
      </Button>

      </Box>
    </MainContainerWrapper>
    </>
    ) 

}

export default ManageAccess;

// EOF SOP-61 Included By PCS