
// SOP-61 - Included By PCS 

import { useState,useRef,useEffect ,useCallback } from 'react'; // Import useState hook
import React from 'react';

import { EditorState, ContentState, convertToRaw,RawDraftContentState  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html'; //TO convert to html format
import htmlToDraft from 'html-to-draftjs';

    import {
        Box, 
        Button,
        Grid,
        Paper,
        Dialog,
        Accordion,
        AccordionSummary,
        AccordionDetails,
        FormControl,
        FormHelperText,
    
    } from '@mui/material';
    import Typography from '@mui/material/Typography';
    import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
    import axios from "axios";
    import MainContainerWrapper from '../../atic-common-helpers/layout/wrappers/MainContainerWrapper';
    import { useNavigate } from "react-router-dom";
    import { commonService } from '../../atic-common-helpers/helpers/common.service';
    import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
    import CryptoJS from 'crypto-js';
    import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
    import { MdContentPaste } from "react-icons/md";
    import { useParams } from 'react-router-dom';

 const ViewDocument =()=>{

    const { title } = useParams<{ title?: string }>(); // Ensure title is optional and can be undefined
    // console.log('title is',title)
    const navigate = useNavigate();
   

    //To save the typed  content
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [displayedContent, setDisplayedContent] = useState<string>('');

    // console.log('displayedContent',displayedContent)

    const [DocumentTitle, setDocumentTitle] =  useState(''); //Initialize document Title
    const DocumentIdFromSession:any = sessionStorage.getItem("DocumentId") ;
    // const VersionNumberFromSession:any = sessionStorage.getItem("VersionNumber") ;


    //To Format the content  
        const handleDisplayContent = (event:any) => {
                // console.log('click1')
                const contentState = editorState.getCurrentContent();
                const rawContent = convertToRaw(contentState);
                const htmlContent = draftToHtml(rawContent);
                setDisplayedContent(htmlContent); // Store HTML content in state
                // console.log('3',contentState)
                // console.log('4',rawContent)
                // console.log('5',htmlContent)
                // CreateDocPoint(event,htmlContent)
                                            };
                // console.log('1',editorState)
                // console.log('2',displayedContent)
    //EOF To save the typed  content   

// To get document content by version and document id..

//Initializing values to get response and store to get payload values..

  const[ResponseDocumentId,setResponseDocumentId]=useState();
  const[ResponseTitleName,setResponseTitleName]=useState();
  const[ResponseCategoryId,setResponseCategoryId]=useState();
  const[ResponseAuthorId,setResponseAuthorId]=useState();
  const[ResponseCreatedBy,setResponseCreatedBy]=useState();
  const[ResponseCreatedDateTime,setResponseCreatedDateTime]=useState();

  const VersionNumberFromSession = sessionStorage.getItem("VersionNumber")
 

const ViewDocumentById =()=>{
      const DocumentIdFromSession:any = sessionStorage.getItem("DocumentId") ;
                const payload={
                DocumentId:DocumentIdFromSession,
                VersionNumber:VersionNumberFromSession,
                CompanyId:CompanyId
                
                                }

 

     commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/docpoint/get_edit_document_by_documentid`, payload)
        .then((res: any) => 
        { 
        
        const DocumentData = res.data.data[0]
       

 //To bind the repsonse  values to the payload values...

         setResponseDocumentId(DocumentData.documentid)
         setResponseTitleName(DocumentData.document_title_name)
         setResponseCategoryId(DocumentData.document_docpoint_categoryid)
         setResponseAuthorId(DocumentData.document_author_id)
         setResponseCreatedBy(DocumentData.createdby)
         setResponseCreatedDateTime(DocumentData.createddatetime)


        // setDisplayedContent(DocumentData.docpoint_content)
        const contentBlock = htmlToDraft(DocumentData.docpoint_content);
        if (contentBlock) {
            const { contentBlocks, entityMap } = contentBlock;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            setEditorState(EditorState.createWithContent(contentState));
        }

        }).catch((error) =>  
        {  
            console.log("error",error)
        Failed(error.response.message);
        });
 }


    // Define type for errors
    type Errors = {
        DocumentTitle?:boolean;
                };

   
    //  Global data from session storage
            const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
            const GlobalData = JSON.parse(GlobalDataFromSession);
            console.log("GlobalData",GlobalData)
            let CompanyId = GlobalData.GlobalCompanyId
            const UserId = GlobalData.GlobalUserId;
            let EmployeeId = GlobalData.GlobalEmployeeId
            let UserName = GlobalData.GlobalUserName
            //    console.log('user',UserId)
            //    console.log('employee',EmployeeId)

   useEffect(() => {
                 ViewDocumentById();
                const DocumentTitleFromSession: any=sessionStorage.getItem("DocumentTitleValue")
                setDocumentTitle(DocumentTitleFromSession)
                   }, []); 


//To display created  Date

        const todayDate = new Date();
        const Year = todayDate.getFullYear();
        const Month = (todayDate.getMonth() + 1).toString().padStart(2, '0');
        const Day = todayDate.getDate().toString().padStart(2, '0');
        const FormattedDate = `${Day}-${Month}-${Year}`;

    return(
       
        <MainContainerWrapper 
        headingText={DocumentTitle}
         >
        <Box>
        <form noValidate>
        <Box>
{/*Main grid starts */}        
        <Grid container spacing={3} display={'flex'} justifyContent={'center'}>
                                 
{/* grid starts */}
        <Grid item lg={11} md={12} xs={12} >
                    {/* Content RTE Accordian */}
                        <Box mt={1}>
                            
                            <Accordion 
                            defaultExpanded sx={{
                                    borderRadius:'30px',
                            }}
                        
                            >
                                <AccordionSummary
                                    expandIcon={<ArrowDropDownIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <MdContentPaste  height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
                                    <Typography fontSize={'1rem'} ml={2}>Content</Typography> 
                                </Box>
                                </AccordionSummary>
                                <AccordionDetails sx={{marginBottom:5}}>
                                <Box>
                                    {/* //Rte content starts */}
                                        <Box style={{ width: '95%',marginLeft:'3%',marginTop:'1%',height:'90%'}}>
                                                {/* <Typography variant="h4">Write something:</Typography> */}
                                                <Editor
                                                    editorState={editorState}
                                                    onEditorStateChange={setEditorState}
                                                    editorStyle={{ backgroundColor: '#f0f0f0',marginLeft:'4px',height:'725px'}}
                                                    readOnly={true}
                                                    toolbarHidden={true}
                                               
                                               />
                                              
                                        </Box>  
                                        {/* //Rte content starts */}
                                </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    {/* EOF Content RTE Accordian */}
        </Grid>
{/*  grid ends */}
        </Grid>
{/*Main grid starts */}    
                    
        <Box>                    
            <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Button
                        sx={{
                            background: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            padding:{
                                lg: "0 70px",
                                md: "0 70",
                                xs:'0 50px'
    
                            },
                            height: "3rem",
                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                        }}

                        onClick={()=>{
                            navigate('/docpoint')
                        }} 
                    >
                     Back
                    </Button>

                   
            </Box>
        </Box>

    </Box>
    </form>
    </Box>
</MainContainerWrapper>)}



export default ViewDocument;   
{/* SOP-61 - PCS */}