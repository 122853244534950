// Included by sanjana SOP-30 to create allocation page
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import deleteAlert from '../../component/HOC/deleteAlert';
import dayjs from 'dayjs';
import AddAllocationPage from './AddAllocations';
import ViewAllocationsPage from './ViewAllocation';
import EditAllocationsPage from './EditAllocation';
import MyApprovalsPage from '../MyApprovals/MyApprovals';


const AllocationPage =()=>{

    const navigate = useNavigate();

    // useSate
    let  [MyPendingAllocationssorder, setMyPendingAllocationssOrder] = React.useState<"desc" | "asc">("desc");
    let  [MyPendingAllocationssorderBy, setMyPendingAllocationssOrderBy] = React.useState("");
    let  [MyPendingAllocationsstableData, setMyPendingAllocationssTableData] = React.useState([]);
    const [MyPendingAllocationsspage, setMyPendingAllocationssPage] = React.useState<number>(0);
    const [MyPendingAllocationsscount, setMyPendingAllocationssCount] = useState<number>(0);
    const [MyPendingAllocationssrowsPerPage, setMyPendingAllocationsssetRowsPerPage] = React.useState<number>(10);
    const [MyPendingAllocationssOpen, setMyPendingAllocationssOpen] = useState(false);
    let  [MyApprovedAllocationsorder, setMyApprovedAllocationsOrder] = React.useState<"desc" | "asc">("desc");
    let  [MyApprovedAllocationsorderBy, setMyApprovedAllocationsOrderBy] = React.useState("");
    let  [MyApprovedAllocationstableData, setMyApprovedAllocationsTableData] = React.useState([]);
    const [MyApprovedAllocationspage, setMyApprovedAllocationsPage] = React.useState<number>(0);
    const [MyApprovedAllocationscount, setMyApprovedAllocationsCount] = useState<number>(0);
    const [MyApprovedAllocationsrowsPerPage, setMyApprovedAllocationssetRowsPerPage] = React.useState<number>(10);
    const [ViewOpen, setViewOpen] = useState(false);
    const [EditOpen, setEditOpen] = useState(false);
    
    const [RequestId, setRequestId] = useState()
    
    //  To open add dialouge box
        const handleAddOpen = (id:any) => {
        setMyPendingAllocationssOpen(true);
        setRequestId(id)
    };

    // To close add dialouge box
    const handleAddClose = () => {
    setMyPendingAllocationssOpen(false);
    };

    //  To open View dialouge box
    const handleViewOpen = (id:any) => {
        setViewOpen(true);
        setRequestId(id)
    };

    // To close View dialouge box
    const handleViewClose = () => {
    setViewOpen(false);
    };

    //  To open Edit dialouge box
    const handleEditOpen = (id:any) => {
        setEditOpen(true);
        setRequestId(id)
    };

    // To close Edit dialouge box
    const handleEditClose = () => {
    setEditOpen(false);
    };

    // Head cell of My Pending Allocation Request
    const MyPendingAllocationssHeadCell = [
        {
            id: "request_number",
            numeric: false,
            disablePadding: true,
            label: "Request #",
            isSort: true,
            isBold: true,
        },
        {
            id: "request_date",
            numeric: false,
            disablePadding: false,
            label: "Request Date",
            isSort: true,
            isBold: true,
            dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
        },
        {
            id: "assetname",
            numeric: false,
            disablePadding: true,
            label: "Asset Name",
            isSort: true,
            isBold: true,
        },
        {
            id: "request_approve_date",
            numeric: false,
            disablePadding: false,
            label: "Approved Date",
            isSort: true,
            isBold: true,
            dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
        },
        {
            id: "approved_by_name",
            numeric: false,
            disablePadding: false,
            label: "Approve By",
            isSort: true,
            isBold: true,
            },
        {
        id: "request_approve_remarks",
        numeric: false,
        disablePadding: false,
        label: "Approve Remarks",
        isSort: true,
        isBold: true,
        },
        {
        // Included by sanjana SOP-34 to rename as Allocate
        // id: "Approve",
        id: "Allocate",
        // End of SOP-34
        numeric: false,
        disablePadding: false,
        label: "Actions",
        isSort: false,
        },

        
        ];
    
    // Head cell of My Allocated allocation Request
    const MyApprovedAllocationsHeadCell = [
        {
            id: "request_number",
            numeric: false,
            disablePadding: true,
            label: "Request #",
            isSort: true,
            isBold: true,
        },
        {
            id: "request_date",
            numeric: false,
            disablePadding: false,
            label: "Request Date",
            isSort: true,
            isBold: true,
            dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
        },
        {
            id: "assetname",
            numeric: false,
            disablePadding: true,
            label: "Asset Name",
            isSort: true,
            isBold: true,
        },
        {
            id: "request_approve_date",
            numeric: false,
            disablePadding: false,
            label: "Approved Date",
            isSort: true,
            isBold: true,
            dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
        },
        {
            id: "approved_by_name",
            numeric: false,
            disablePadding: false,
            label: "Approve By",
            isSort: true,
            isBold: true,
            },
        {
        id: "request_approve_remarks",
        numeric: false,
        disablePadding: false,
        label: "Approve Remarks",
        isSort: true,
        isBold: true,
        },
        {
        id: "allocationstatusname",
        numeric: false,
        disablePadding: false,
        label: "Allocation Status",
        isSort: true,
        isBold: true,
        },
        {
        id: "allocate_remarks",
        numeric: false,
        disablePadding: false,
        label: "Allocation Remarks",
        isSort: true,
        isBold: true,
        },
        {
        id: "action",
        numeric: false,
        disablePadding: false,
        label: "Actions",
        isSort: false,
        },

        
        ];
    
    

        //  Global data from session storage
        const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
        const GlobalData = JSON.parse(GlobalDataFromSession);
        //console.log("GlobalData",GlobalData)
        let CompanyId = GlobalData.GlobalCompanyId
        const UserId = GlobalData.GlobalUserId;
    
 
        // To get pending allocation list
        const getMyPendingAllocationssList = () => 
        {       
            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/allocation/get_requestanasset_allocation_list`, {
                SortColumnName: MyPendingAllocationssorderBy,
                SortColumnDirection: MyPendingAllocationssorder,
                StartIndex: MyPendingAllocationsspage + 1,
                PageSize: MyPendingAllocationssrowsPerPage,
                CompanyId:CompanyId,
                UserId:UserId
            })
            .then((res: any) => 
            {
                console.log("res P",res)
            const formattedTableData= res.data.data.map((item:any)=>{
            return {...item,
                id:item.requestid,
                request_date: item.request_date ? dayjs(item.request_date).format("DD-MM-YYYY") : "",
                request_approve_date: item.request_approve_date ? dayjs(item.request_approve_date).format("DD-MM-YYYY") : "",
            }
            }) 
            ////console.log("formattedTableData",formattedTableData)
            
            setMyPendingAllocationssTableData(formattedTableData);
            if(res.data.data[0]===undefined)
            {
                setMyPendingAllocationssCount(0);
            }
            else
            {
                setMyPendingAllocationssCount(res.data.data[0].total_count);
            }
            })
        .catch((error) => 
            {
            if (error.response.status === 404)
            {
                setMyPendingAllocationssTableData([]);
            }
            Failed(error.response.message);
            });
        };

        // To get other allocation list
        const getMyApprovedAllocationsList = () => 
            {       
                commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/allocation/get_allocation_approved_list`, {
                    SortColumnName: MyApprovedAllocationsorderBy,
                    SortColumnDirection: MyApprovedAllocationsorder,
                    StartIndex: MyApprovedAllocationspage + 1,
                    PageSize: MyApprovedAllocationsrowsPerPage,
                    CompanyId:CompanyId,
                    UserId:UserId
                })
                .then((res: any) => 
                {
                    //console.log("res A",res)
                const formattedTableData= res.data.data.map((item:any)=>{
                return {...item,
                    id:item.requestid,
                    request_approve_date: item.request_approve_date ? dayjs(item.request_approve_date).format("DD-MM-YYYY") : "",
                    allocate_date: item.allocate_date ? dayjs(item.allocate_date).format("DD-MM-YYYY") : "",
                    request_date: item.request_date ? dayjs(item.request_date).format("DD-MM-YYYY") : "",

                }
                }) 
                //console.log("formattedTableData",formattedTableData)
                
                setMyApprovedAllocationsTableData(formattedTableData);
                if(res.data.data[0]===undefined)
                {
                    setMyApprovedAllocationsCount(0);
                }
                else
                {
                    setMyApprovedAllocationsCount(res.data.data[0].total_count);
                }
                })
            .catch((error) => 
                {
                if (error.response.status === 404)
                {
                    setMyApprovedAllocationsTableData([]);
                }
                Failed(error.response.message);
                });
            };

        

        // Sort Handler
        const handleMyPendingAllocationssIncidentRequestSort = (event: any, property: any) =>
            {
            const isAsc = MyPendingAllocationssorderBy === property && MyPendingAllocationssorder === "asc";
            MyPendingAllocationssorder = isAsc ? "desc" : "asc";
            setMyPendingAllocationssOrder(MyPendingAllocationssorder);
            MyPendingAllocationssorderBy = property;
            setMyPendingAllocationssOrderBy(MyPendingAllocationssorderBy);
            };
        
        
        // Action function of table icons
        const MyPendingAllocationssactionFunction = (id: string, actionText: string) => 
        {
            // Modified by sanjana SOP-34 to rename as Allocate
            // if (actionText === "Approve") {
            if (actionText === "Allocate") {    
            // End of SOP-34 
                handleAddOpen(id)
            };
            if (actionText === "edit") navigate(`/edit_nationality/${id}`);
        };
    
    
        // Pagination Handler of table
        const MyPendingAllocationsshandleChangePage = (event: unknown, newPage: number) => {
            setMyPendingAllocationssPage(newPage);
        };
        
        // Rows Per page Handler
        const MyPendingAllocationsshandleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
            ) => {
                setMyPendingAllocationsssetRowsPerPage(+event.target.value);
                setMyPendingAllocationssPage(0);
        };

        // Sort Handler
        const handleMyApprovedAllocationsIncidentRequestSort = (event: any, property: any) =>
            {
            const isAsc = MyApprovedAllocationsorderBy === property && MyApprovedAllocationsorder === "asc";
            MyApprovedAllocationsorder = isAsc ? "desc" : "asc";
            setMyApprovedAllocationsOrder(MyApprovedAllocationsorder);
            MyApprovedAllocationsorderBy = property;
            setMyApprovedAllocationsOrderBy(MyApprovedAllocationsorderBy);
            };
        
        
        // Action function of table icons
        const MyApprovedAllocationsactionFunction = (id: string, actionText: string) => 
        {
            if (actionText === "view") {
                handleViewOpen(id)
            };
        };
    
    
        // Pagination Handler of table
        const MyApprovedAllocationshandleChangePage = (event: unknown, newPage: number) => {
            setMyApprovedAllocationsPage(newPage);
        };
        
        // Rows Per page Handler
        const MyApprovedAllocationshandleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
            ) => {
                setMyApprovedAllocationssetRowsPerPage(+event.target.value);
                setMyApprovedAllocationsPage(0);
        };

        useEffect(() => {
            getMyPendingAllocationssList();
            getMyApprovedAllocationsList();
        }, [MyPendingAllocationssrowsPerPage,MyPendingAllocationsspage,MyPendingAllocationssorderBy,MyPendingAllocationssorder,
            MyApprovedAllocationsrowsPerPage,MyApprovedAllocationspage,MyApprovedAllocationsorderBy,MyApprovedAllocationsorder
        ]); 
                

    return(
        <>
        <MainContainerWrapper headingText='Allocations'>

            <Box>
            <EnhancedTable
            headCells={MyPendingAllocationssHeadCell}
            rows={MyPendingAllocationsstableData}
            rowsPerPage={MyPendingAllocationssrowsPerPage}
            page={MyPendingAllocationsspage}
            handleChangePage={MyPendingAllocationsshandleChangePage}
            handleChangeRowsPerPage={MyPendingAllocationsshandleChangeRowsPerPage}
            handleRequestSort={handleMyPendingAllocationssIncidentRequestSort}
            order={MyPendingAllocationssorder}
            orderBy={MyPendingAllocationssorderBy}
            actionFunction={MyPendingAllocationssactionFunction}
            isView={false}
            isDelete={false}
            isEdit={false}
            total={MyPendingAllocationsscount}
            isSearch={false}
            HoverColor={'#E7F1FB'}
            />
            </Box>

            <Box mt={5}>
                <Typography variant='h1'>
                    Allocated List
                </Typography>
                <Box mt={3}>
                    <EnhancedTable
                    headCells={MyApprovedAllocationsHeadCell}
                    rows={MyApprovedAllocationstableData}
                    rowsPerPage={MyApprovedAllocationsrowsPerPage}
                    page={MyApprovedAllocationspage}
                    handleChangePage={MyApprovedAllocationshandleChangePage}
                    handleChangeRowsPerPage={MyApprovedAllocationshandleChangeRowsPerPage}
                    handleRequestSort={handleMyApprovedAllocationsIncidentRequestSort}
                    order={MyApprovedAllocationsorder}
                    orderBy={MyApprovedAllocationsorderBy}
                    actionFunction={MyApprovedAllocationsactionFunction}
                    isView={true}
                    isDelete={false}
                    isEdit={false}
                    total={MyApprovedAllocationscount}
                    isSearch={false}
                    HoverColor={'#E7F1FB'}
                    />
                </Box>

            </Box>
            
            <Box>
                <Dialog  open={MyPendingAllocationssOpen}  >
                    <AddAllocationPage 
                    handleAddClose={handleAddClose}
                    getMyPendingAllocationssList={getMyPendingAllocationssList}
                    RequestId={RequestId}
                    getMyApprovedAllocationsList={getMyApprovedAllocationsList}
                    />
                </Dialog>
            </Box>

            <Box>
                <Dialog  open={ViewOpen}  >
                    <ViewAllocationsPage 
                    handleViewClose={handleViewClose}
                    handleEditOpen={handleEditOpen}
                    RequestId={RequestId}
                    />
                </Dialog>
            </Box>

            <Box>
                <Dialog  open={EditOpen}  >
                    <EditAllocationsPage 
                    handleEditClose={handleEditClose}
                    getMyPendingAllocationssList={getMyPendingAllocationssList}
                    RequestId={RequestId}
                    getMyApprovedAllocationsList={getMyApprovedAllocationsList}
                    />
                </Dialog>
            </Box>

        </MainContainerWrapper>
        
        </>
    )
}
export default AllocationPage;


// End of SOP-30