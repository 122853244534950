// Included by sanjana SOP-17
// Imports
import { useState,useRef,useEffect,useCallback } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControl,
    FormHelperText,
    useTheme,
    Container,
    Stack,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import CryptoJS from "crypto-js";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FcInfo } from "react-icons/fc";
import { FaUserGear } from "react-icons/fa6";
import { BsBuildingsFill } from "react-icons/bs";
import { IoMdPhotos } from "react-icons/io";
import Swal from "sweetalert2";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";

//SOP-39 - TS  - Asset Management - User Rights - start
// --Drag and Drop imports--
import { MdContactPage } from "react-icons/md";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
//SOP-39 - TS -  Asset Management - User Rights-  end  

//SOP-73 Included By PCS [asset icon]
import { BiSolidLayer } from "react-icons/bi";

const EditEmployeePage =()=>{

    // Use navigate
    const navigate = useNavigate();

    const {id} = useParams();
    const EmployeeId = id

    // usestate declaration
    const [EmployeeNameError, setEmployeeNameError]=useState(false)
    const [EmployeeNameValue, setEmployeeNameValue]=useState('')
    const [EmployeeNameErrorMessage, setEmployeeNameErrorMessage]=useState('')
    const [EmployeeEmailError, setEmployeeEmailError]=useState(false)
    const [EmployeeEmailValue, setEmployeeEmailValue]=useState('')
    const [EmployeeEmailErrorMessage, setEmployeeEmailErrorMessage]=useState('')
    const [EmployeeMobileError, setEmployeeMobileError]=useState(false)
    const [EmployeeMobileValue, setEmployeeMobileValue]=useState('')
    const [EmployeeMobileErrorMessage, setEmployeeMobileErrorMessage]=useState('')
    const [EmployeePasswordError, setEmployeePasswordError]=useState(false)
    const [EmployeePasswordValue, setEmployeePasswordValue]=useState('')
    const [EmployeePasswordErrorMessage, setEmployeePasswordErrorMessage]=useState('')
    const [EmployeeRoleOptions, setEmployeeRoleOptions] = useState<OptionType[]>([]);
    const [selectedEmployeeRoleId, setSelectedEmployeeRoleId] = useState<string | null>('');
    const [EmployeeRoleError, setEmployeeRoleError]=useState(false)
    const [EmployeeRoleErrorMessage, setEmployeeRoleErrorMessage]=useState('')
    const [isActive, setIsActive] = useState(true);
    const [EmployeeReportToWhomListOptions, setEmployeeReportToWhomListOptions] = useState<OptionType[]>([]);
    const [selectedEmployeeReportToWhomId, setSelectedEmployeeReportToWhomId] = useState<string | null>('');
    const [EmployeeReportToWhomError, setEmployeeReportToWhomError]=useState(false)
    const [EmployeeReportToWhomErrorMessage, setEmployeeReportToWhomErrorMessage]=useState('')
    const [EmployeeDateOfJoiningError, setEmployeeDateOfJoiningError]=useState(false)
    const [EmployeeDateOfJoiningValue, setEmployeeDateOfJoiningValue] = useState<dayjs.Dayjs | null>(null);
    const [EmployeeDateOfJoiningErrorMessage, setEmployeeDateOfJoiningErrorMessage]=useState('')
    const [EmployeeDateOfRelievingError, setEmployeeDateOfRelievingError]=useState(false)
    const [EmployeeDateOfRelievingValue, setEmployeeDateOfRelievingValue]=useState<dayjs.Dayjs | null>(null);
    const [EmployeeDateOfRelievingErrorMessage, setEmployeeDateOfRelievingErrorMessage]=useState('')

    // SOP-31 Modified By PCS [ Designation Text field removed & Included as Drop down] 
    // --Designation --
    // const [EmployeeDesignationError, setEmployeeDesignationError]=useState(false)
    // const [EmployeeDesignationValue, setEmployeeDesignationValue]=useState('')
    // const [EmployeeDesignationErrorMessage, setEmployeeDesignationErrorMessage]=useState('')

    const [EmployeeDesignationOptions, setEmployeeDesignationOptions] = useState<OptionType[]>([]);
    const [selectedEmployeeDesignationId, setSelectedEmployeeDesignationId] = useState<string | null>('');
    const [EmployeeDesignationError, setEmployeeDesignationError]=useState(false)
    const [EmployeeDesignationErrorMessage, setEmployeeDesignationErrorMessage]=useState('')

    // EOF SOP-31
    // SOP-31 Included By PCS [Included Department drop down] 

    const [EmployeeDepartmentOptions, setEmployeeDepartmentOptions] = useState<OptionType[]>([]);
    const [selectedEmployeeDepartmentId, setSelectedEmployeeDepartmentId] = useState<string | null>('');
    const [EmployeeDepartmentError, setEmployeeDepartmentError]=useState(false)
    const [EmployeeDepartmentErrorMessage, setEmployeeDepartmentErrorMessage]=useState('')

    //EOF SOP- 31 


    const [EmployeeWorkLocationError, setEmployeeWorkLocationError]=useState(false)
    const [EmployeeWorkLocationValue, setEmployeeWorkLocationValue]=useState('')
    const [EmployeeWorkLocationErrorMessage, setEmployeeWorkLocationErrorMessage]=useState('')
    const [EmployeeHandOveredStatusError, setEmployeeHandOveredStatusError]=useState(false)
    const [EmployeeHandOveredStatusValue, setEmployeeHandOveredStatusValue]=useState('')
    const [EmployeeHandOveredStatusErrorMessage, setEmployeeHandOveredStatusErrorMessage]=useState('')
    const [EmployeeNoError, setEmployeeNoError]=useState(false)
    const [EmployeeNoValue, setEmployeeNoValue]=useState('')
    const [EmployeeNoErrorMessage, setEmployeeNoErrorMessage]=useState('')
    const [EmployeeReasonOfRelievingError, setEmployeeReasonOfRelievingError]=useState(false)
    const [EmployeeReasonOfRelievingValue, setEmployeeReasonOfRelievingValue]=useState('')
    const [EmployeeReasonOfRelievingErrorMessage, setEmployeeReasonOfRelievingErrorMessage]=useState('')
    const [imageSrclink, setImageSrclink] = useState<string>('');
    const [imageUrl, setImageUrl] = useState<any>(null);
    const [key, setKey] = useState(0);
    const [EmployeeRoleEnable, setEmployeeRoleEnable]=useState(false)
    const [EmployeeUserId, setEmployeeUserId]=useState()
    const [EnableClearBtn, setEnableClearBtn] = useState(false);
    const [EmployeeRelievingDetailsEnable, setEmployeeRelievingDetailsEnable]=useState(false)

  


    // SOP-39 Ts - Asset Management - User Rights - useeffect - start
    const [menus, setMenus] = useState<MenuType[]>([]);  // menus grid
    const [selectedMenus, setSelectedMenus] = useState<MenuType[]>([]); //selected menus
    const selectedMenuIds = selectedMenus.map(menu => menu.id);  //selectedMenuIds
    const [searchQuery, setSearchQuery] = useState(''); // search 
    //console.log('search result now',searchQuery)
    // console.log('menus',menus)
    // console.log('selectedMenuIds', selectedMenuIds)
    // console.log("selectedMenus",selectedMenus)
    // SOP-39 - end



    //SOP-73 Included By PCS [Testing feedback corrections - to include asset list in employee]
        let  [EmployeeAssettableData, setEmployeeAssetTableData] = React.useState([]);
        const [EmployeeAssetcount, setEmployeeAssetCount] = useState<number>(0);
        const [EmployeeAssetrowsPerPage, setEmployeeAssetsetRowsPerPage] = React.useState<number>(5);
        let  [EmployeeAssetorder, setEmployeeAssetOrder] = React.useState<"desc" | "asc">("desc");
        let  [EmployeeAssetorderBy, setEmployeeAssetOrderBy] = React.useState("");
        const [EmployeeAssetpage, setEmployeeAssetPage] = React.useState<number>(0);
        const [Employee_UserId, setEmployee_UserId] = useState();//Initializing EmplyeeUserID
    //EOF SOP-73 Included By PCS

   //SOP-73 Included By PCS [Testing feedback corrections-to include asset list in employee]
       const MyPropertyHeadCell = [
        {
        id: "assetname",
        numeric: false,
        disablePadding: true,
        label: "Asset Name",
        isSort: true,
        isBold: true,
        },
        {
        id: "modelnumber",
        numeric: false,
        disablePadding: false,
        label: "Model number",
        isSort: true,
        isBold: true,
        },

        {
        id: "serialnumber",
        numeric: false,
        disablePadding: false,
        label: "Serial number",
        isSort: true,
        isBold: true,
        },
        {
        id: "manufacturer",
        numeric: false,
        disablePadding: false,
        label: "Manufacturer",
        isSort: true,
        isBold: true,
        },
        
        ];

        // Pagination Handler of table
            const EmployeeAssethandleChangePage = (event: unknown, newPage: number) => {
                setEmployeeAssetPage(newPage);
            };

        // Rows Per page Handler
            const EmployeeAssethandleChangeRowsPerPage = (
                event: React.ChangeEvent<HTMLInputElement>
                    ) => {
                        setEmployeeAssetsetRowsPerPage(+event.target.value);
                        setEmployeeAssetPage(0);
                        };
   
        // Sort Handler
            const handleEmployeeAssetSort = (event: any, property: any) =>
            {
                const isAsc = EmployeeAssetorderBy === property && EmployeeAssetorder === "asc";
                EmployeeAssetorder = isAsc ? "desc" : "asc";
                setEmployeeAssetOrder(EmployeeAssetorder);
                EmployeeAssetorderBy = property;
                setEmployeeAssetOrderBy(EmployeeAssetorderBy);
            };

        // Action function of table icons
            const EmployeeAssetactionFunction = (id: string, actionText: string) => 
            {};
    //EOF SOP-73 Included By PCS



    const handleToggleActive = () => {
        setIsActive((prevIsActive) => !prevIsActive);
        //console.log("isActive 1",isActive)
        if(isActive === true){
            setEmployeeRelievingDetailsEnable(true)
            //console.log("isActive 2",isActive)
        }
        else if(isActive === false){
            setEmployeeRelievingDetailsEnable(false)
            //console.log("isActive 3",isActive)
        }
    };

        // Global data from session storage
        const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
        const GlobalData = JSON.parse(GlobalDataFromSession);
        ////console.log("GlobalData",GlobalData)
        let CompanyId = GlobalData.GlobalCompanyId;
        let UserId = GlobalData.GlobalUserId;

    type OptionType = {
        name: string;
        value: string;
    };
    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });

    const handleEmployeeNameChange =(event:any)=>{
        const trimmedEmployeeNameValue = event.target.value.trim();
        setEmployeeNameValue(event.target.value);
        if (trimmedEmployeeNameValue === '') {
            setEmployeeNameError(true);
            setEmployeeNameErrorMessage("Employee name is required");
        } 
        else if( trimmedEmployeeNameValue.length >255 ){
            setEmployeeNameError(true);
            setEmployeeNameErrorMessage("Max 255 characters");
        }
        else {
            setEmployeeNameError(false);
            setEmployeeNameErrorMessage("");
        }

    }

    const handleEmployeeEmailChange =(event:any)=>{
        const trimmedEmployeeEmailValue = event.target.value.trim();
        setEmployeeEmailValue(event.target.value);
        if (trimmedEmployeeEmailValue === '') {
            setEmployeeEmailError(true);
            setEmployeeEmailErrorMessage("Email is required");
        } 
        else if( trimmedEmployeeEmailValue.length >255 ){
            setEmployeeEmailError(true);
            setEmployeeEmailErrorMessage("Max 255 characters");
        }
        else if( !(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(trimmedEmployeeEmailValue) ){
            setEmployeeEmailError(true);
            setEmployeeEmailErrorMessage("Invalid Email");
        }
        else {
            setEmployeeEmailError(false);
            setEmployeeEmailErrorMessage("");
        }

    }

    const handleEmployeeMobileChange =(event:any)=>{
        const trimmedEmployeeMobileValue = event.target.value.trim();
        setEmployeeMobileValue(event.target.value);
        if (trimmedEmployeeMobileValue === '') {
            setEmployeeMobileError(true);
            setEmployeeMobileErrorMessage("Mobile number is required");
        } 
        else if( trimmedEmployeeMobileValue.length >15 ){
            setEmployeeMobileError(true);
            setEmployeeMobileErrorMessage("Invalid mobile number");
        }
        //SOP-17 Modified By PCS[Testing feedback corrections to include '+' symbol]
        //else if( !/^[0-9]+$/.test(trimmedEmployeeMobileValue) ){
          else if( !/^[0-9 +]+$/.test(trimmedEmployeeMobileValue) ){
        //EOF SOP-17 Modified By PCS[Testing feedback corrections to include '+' symbol]

            setEmployeeMobileError(true);
            setEmployeeMobileErrorMessage("Numbers only");
        }
        else {
            setEmployeeMobileError(false);
            setEmployeeMobileErrorMessage("");
        }
    }


    const handleEmployeePasswordChange =(event:any)=>{
        const trimmedEmployeePasswordValue = event.target.value.trim();
        setEmployeePasswordValue(event.target.value);


          //SOP-17 Included By PCS Testing feedback corrections [to set strong password]
          const hasUpperCase = /[A-Z]/.test(trimmedEmployeePasswordValue);
          const hasDigit = /\d/.test(trimmedEmployeePasswordValue);
          const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(trimmedEmployeePasswordValue);
          //EOF SOP 17 Inlcuded By PCS  [to set strong password]

        if (trimmedEmployeePasswordValue === '') {
            setEmployeePasswordError(true);
            setEmployeePasswordErrorMessage("Password is required");
        } 

        
        //SOP-17 Included By PCS[Testing feedback corrections-to avoid space]
        else if (event.target.value.includes(' ')) {
            setEmployeePasswordError(true);
            setEmployeePasswordErrorMessage("Spaces are not allowed");
        }
        //SOP-17 Included By PCS[Testing feedback corrections-to avoid space]


        else if( trimmedEmployeePasswordValue.length >12 ){
            setEmployeePasswordError(true);
            setEmployeePasswordErrorMessage("Max 12 characters");
        }

      // SOP-17 Included By PCS[to set strong password]   
    
        else if (!hasUpperCase || !hasDigit || !hasSpecialChar ) {
            setEmployeePasswordError(true);
            setEmployeePasswordErrorMessage("Password must contain at least one uppercase letter, one digit, and one special character");
        }
   
       //EOF SOP-17 Included By PCS[to set strong password]   

        else {
            setEmployeePasswordError(false);
            setEmployeePasswordErrorMessage("");
            }
      



    }


    const handleDateOfJoiningChange = (date:any) => {
        setEmployeeDateOfJoiningValue(date)
        
        if (!date) {
            setEmployeeDateOfJoiningError(true);
            //SOP-17 Modified By PCS[Testing feedback Corrections]
            //setEmployeeDateOfJoiningErrorMessage("Date Of Joining is required");
            setEmployeeDateOfJoiningErrorMessage("Date of Joining is required");
        }
        else {
            setEmployeeDateOfJoiningError(false);
            setEmployeeDateOfJoiningErrorMessage("");
        }
    };


    const handleDateOfRelievingChange = (date:any) => {
        setEmployeeDateOfRelievingValue(date)

        //SOP-17 Included By PCS[Testing feedback corrections- On change Error message to disappear]
            if(!date){
                setEmployeeDateOfRelievingError(true);
                setEmployeeDateOfRelievingErrorMessage("Date of Relieving is required");
            }
            else{
                setEmployeeDateOfRelievingError(false);
                setEmployeeDateOfRelievingErrorMessage("");
            }
        //EOF SOP-17 Included By PCS [Testing feedback corrections]

            };

        //SOP-31 Commented By PCS [Designation text field changed to drop down]
            // const handleEmployeeDesignationChange =(event:any)=>{
            //     const trimmedEmployeeDesignationValue = event.target.value.trim();
            //     setEmployeeDesignationValue(event.target.value);
            //      if( trimmedEmployeeDesignationValue.length >255 ){
            //         setEmployeeDesignationError(true);
            //         setEmployeeDesignationErrorMessage("Max 255 characters");
            //     }
            //     else {
            //         setEmployeeDesignationError(false);
            //         setEmployeeDesignationErrorMessage("");
            //     }

            // }
        //SOP-31 Commented By PCS [text field changed to drop down]


    const handleEmployeeWorkLocationChange =(event:any)=>{
        const trimmedEmployeeWorkLocationValue = event.target.value.trim();
        //console.log("trimmedEmployeeWorkLocationValue",trimmedEmployeeWorkLocationValue)
        setEmployeeWorkLocationValue(event.target.value);
        if(trimmedEmployeeWorkLocationValue === '') {
            setEmployeeWorkLocationError(true);
            setEmployeeWorkLocationErrorMessage("Work Location is required");
        }
        else if( trimmedEmployeeWorkLocationValue.length >255 ){
            setEmployeeWorkLocationError(true);
            setEmployeeWorkLocationErrorMessage("Max 255 characters");
        }
        else {
            setEmployeeWorkLocationError(false);
            setEmployeeWorkLocationErrorMessage("");
        }

    }

    const handleEmployeeHandOveredStatusChange =(event:any)=>{
        const trimmedEmployeeHandOveredStatusValue = event.target.value.trim();
        setEmployeeHandOveredStatusValue(event.target.value);

         if( trimmedEmployeeHandOveredStatusValue.length >255 ){
            setEmployeeHandOveredStatusError(true);
            setEmployeeHandOveredStatusErrorMessage("Max 255 characters");
        }
        else {
            setEmployeeWorkLocationError(false);
            setEmployeeWorkLocationErrorMessage("");
        }

    }

    const handleEmployeeNoChange =(event:any)=>{
        const trimmedEmployeeNoValue = event.target.value.trim();
        setEmployeeNoValue(event.target.value);
         if( trimmedEmployeeNoValue.length >255 ){
            setEmployeeNoError(true);
            setEmployeeNoErrorMessage("Max 255 characters");
        }
        else {
            setEmployeeNoError(false);
            setEmployeeNoErrorMessage("");
        }

    }


    const handleEmployeeReasonOfRelievingChange =(event:any)=>{
        const trimmedEmployeeReasonOfRelievingValue = event.target.value.trim();
        setEmployeeReasonOfRelievingValue(event.target.value);

         if( trimmedEmployeeReasonOfRelievingValue.length >255 ){
            setEmployeeReasonOfRelievingError(true);
            setEmployeeReasonOfRelievingErrorMessage("Max 255 characters");
        }
        else {
            setEmployeeReasonOfRelievingError(false);
            setEmployeeReasonOfRelievingErrorMessage("");
        }

    }


    const clearImage = useCallback(() => {
        Swal.fire({
          title: 'Delete Image',
          text: 'Are you sure you want to clear the image?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#25425F',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        }).then((result) => {
          if (result.isConfirmed) {
            //clear the image
            setImageUrl(null);
            setImageSrclink('');
            setEnableClearBtn(false);
            //console.log('Image cleared');
          }
        });
      }, [setImageUrl, setImageSrclink]);



const handleFileUpload = (event: any) => {
    const file = event.target?.files[0];
    //console.log("file", file)
    if (file) {
        var fileSize = file.size;
        var maxSize = 150 * 1024;
        //console.log("maxSize",maxSize)
        //console.log("fileSize",fileSize)
        if (fileSize > maxSize) {
            event.preventDefault(); // Prevent form submission
            Swal.fire({
                icon: 'warning',
                text: 'Image size must be less than 150KB.',
            })
        }
        else {
        const reader = new FileReader();
        reader.onloadend = () => {
            // 'reader.result' contains the Base64 data URL of the image
            const imageDataUrl = reader.result;
            setImageSrclink(String(imageDataUrl))
            setImageUrl(imageDataUrl); // Set the state with the Base64 data URL if needed
            setEnableClearBtn(true)
        };
        reader.readAsDataURL(file);
        }
    };
    // Reset the input element by changing the key
    // by incrementing the `key` variable when clearing the image, you force React to re - render the input element, 
    // allowing you to select the same image again
    setKey(key + 1);
}


       // User role Dropdown API
       const GetUserRoleList = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/getUserRoleList`,);
            //console.log("User Role" ,res.data.data);
            let formattedUserRole = res.data.data.map((item:any) => {
            const { user_role_id, user_role_name } = item;
            return {
                name: user_role_name,
                value: user_role_id,
            };
            });
            setEmployeeRoleOptions(formattedUserRole)
        } catch (err) {
            //console.log( "co",err);
        }
        };


        // User role Dropdown API
        const GetEmployeeReportToWhomList = async () => {

        const EmployeeLisPayload ={
            CompanyId:CompanyId,
            active:"Y"
        }
        //console.log("EmployeeLisPayload",EmployeeLisPayload)

        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/employee/getEmployeeList`,EmployeeLisPayload);
            //console.log("Employee List" ,res.data.data);
            let formattedEmployeeList = res.data.data.map((item:any) => {
            const { employee_id, employee_name } = item;
            return {
                name: employee_name,
                value: employee_id,
            };
            });
            setEmployeeReportToWhomListOptions(formattedEmployeeList)
        } catch (err) {
            //console.log( "co",err);
        }
        };

    // SOP-31 Included By PCS [Designation drop down]
       const GetDesignationList = async () => 
        {
            const DesignationListPayload ={
                CompanyId:CompanyId,
            }
           try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/getDesignationList`,DesignationListPayload);
            //console.log("Designation List" ,res.data.data);
            let formattedDesignationList = res.data.data.map((item:any) => {
            const { designationid, designationname } = item;
            return {
                name: designationname,
                value: designationid,
            };
            });
            setEmployeeDesignationOptions(formattedDesignationList)
              } 
           catch (err) {
            //console.log( "co",err);
                        }
        };

    //EOF SOP-31

    // SOP-31 Included By PCS [Department drop down]
    const GetDepartmentList = async () =>
     {
            const DepartmentListPayload ={
                CompanyId:CompanyId,
                                          }

       //console.log("DepartmentListPayload",DepartmentListPayload)

     try {
         const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/getDepartmentList`,DepartmentListPayload);
        //console.log("Department List" ,res.data.data);
        let formattedDepartmentList = res.data.data.map((item:any) => {
        const { departmentid, departmentname } = item;
        return {
            name: departmentname,
            value: departmentid,
        };
        });
        setEmployeeDepartmentOptions(formattedDepartmentList)
      } 
        catch (err) {
            //console.log( "co",err);
        }
    };

    //EOF SOP-31 

        // To get categories details as per the id
        const GetEmployeeListById =()=>{
            //console.log("EmployeeId",EmployeeId)
            commonService
             .postService(`${process.env.REACT_APP_BACKEND_URL}/employee/getEmployeeById`, {
                EmployeeId:EmployeeId,
                CompanyId:CompanyId
             })
             .then((res: any) => 
             { 
                 // console.log("response edit..",res.data)
                const Employeedata = res.data.data[0]
                // console.log("Employeedata PCS",Employeedata)
                if(Employeedata.active === 'Y'){
                    setIsActive(true)
                    setEmployeeRelievingDetailsEnable(false)
                }
                else{
                    setIsActive(false)
                    setEmployeeRelievingDetailsEnable(true)
                }
                let formattedEmployeeRoleId = res.data.data.map((item:any) => {
                    const { employee_role_id, employee_role_name } = item;
                    return {
                        name: employee_role_name,
                        value: employee_role_id,
                    };
                    });
                    //console.log("formattedEmployeeRoleId",formattedEmployeeRoleId[0].value)
                    setSelectedEmployeeRoleId(formattedEmployeeRoleId[0].value)

        //SOP-31 [To get Designation value from response]
                let formattedDesignationList = res.data.data.map((item:any) => {
                    //console.log('item',item)
                    const { designation_id, designationname } = item;
                    return {
                        name: designationname,
                        value: designation_id,
                    };
                    });
                    //console.log("formattedDesignationList",formattedDesignationList[0].value)
                    setSelectedEmployeeDesignationId(formattedDesignationList[0].value)

        //EOF SOP-31 
        //SOP-31 [To get Department value from response]
                    let formattedDepartmentList = res.data.data.map((item:any) => {
                        //console.log('item',item)
                        const { department_id, departmentname } = item;
                        return {
                            name: departmentname,
                            value: department_id,
                        };
                        });
                        //console.log("formattedDepartmentList",formattedDepartmentList[0].value)
                        setSelectedEmployeeDepartmentId(formattedDepartmentList[0].value)

        //EOF SOP-31 

                let formattedEmployeeReportedToWhom = res.data.data.map((item:any) => {
                    const { reported_to_whom_id, reported_to_whom_name } = item;
                    return {
                        name: reported_to_whom_name,
                        value: reported_to_whom_id,
                    };
                    });
                    //console.log("formattedEmployeeReportedToWhom",formattedEmployeeReportedToWhom[0].value)
                    setSelectedEmployeeReportToWhomId(formattedEmployeeReportedToWhom[0].value)



                    setEmployeeNameValue(Employeedata.employee_name);
                    setEmployeeEmailValue(Employeedata.employee_email);
                    setEmployeeMobileValue(Employeedata.employee_mobile);
                    setEmployeeDateOfJoiningValue(Employeedata.date_of_joining);
                    setEmployeeDateOfRelievingValue(Employeedata.date_of_relieving);
                    // setEmployeeDesignationValue(Employeedata.designation); //SOP-31 Text field removed
                    setEmployeeWorkLocationValue(Employeedata.work_location);
                    setEmployeeHandOveredStatusValue(Employeedata.hand_overed_sts);
                    setEmployeeNoValue(Employeedata.employee_number);
                    setEmployeeReasonOfRelievingValue(Employeedata.reason_for_relieving);
                    setImageUrl(Employeedata.photo_url)

                    setEmployee_UserId(Employeedata.userid) //SOP-73 Included By PCS [binding userid]
                       
                    
                    if(Employeedata.photo_url === null || Employeedata.photo_url === "" || Employeedata.photo_url === undefined){
                        setEnableClearBtn(false)
                    }
                    else{
                        setEnableClearBtn(true)
                    }
                   // setEmployeeRoleId(Employeedata.employee_role_id)
                    let EmployeeRoleId = Employeedata.employee_role_id;
                    //console.log("Employeedata.employee_role_id",Employeedata.employee_role_id)
                    //console.log("EmployeeRoleId",EmployeeRoleId)
                    if(EmployeeRoleId === 1){
                        setEmployeeRoleEnable(false)
                        setEmployeeRoleError(false) 
                        setEmployeeRoleErrorMessage('')
                    } 
                    else{
                        setEmployeeRoleEnable(true)
                    }
                    setEmployeeUserId(Employeedata.userid)


                    // To decrypt password to check valid 
                    const secretPass = "XkhZG4fW2t2W";   
                    let decryptedData; 
                    if (Employeedata.employee_password && typeof Employeedata.employee_password === 'string' && Employeedata.employee_password.trim() !== '') {
                        const bytes = CryptoJS.AES.decrypt(Employeedata.employee_password, secretPass);
                        decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                        setEmployeePasswordValue(decryptedData);
                    }         
                    //console.log("decryptedData",decryptedData)

             }).catch((error) =>  
             { 
                console.log("error",error)
              Failed(error.response.message);
             });
        }

        // Exist Employee Email API
        const GetCompanyExistEmail = async () => {   
        
            const  ExistEmailPayload = {
                ExistEmail:EmployeeEmailValue,
                UserId:EmployeeUserId
            }
            //console.log("ExistEmailPayload",ExistEmailPayload)
            try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/company/getCompanyEmailExists`,ExistEmailPayload);
            //console.log(res.data.data);
            return res.data.data || [];
            } catch (err) {
            console.log( "co",err); 
            }
        };



        // To update employee
        // Define a type for errors
        type Errors = {
            employeeName?:boolean;
            employeeEmail?:boolean;
            employeeMobile?:boolean;
            employeePassword?:boolean;
            employeeRole?:boolean;
            employeeReportToWhom?:boolean;
            employeeDateOfJoining?:boolean;
            employeeDateOfRelieving?:boolean;
            employeeDesignation?:boolean;
            employeeDepartment?:boolean;//SOP-31 [Department Included]
            employeeWorkLocation?:boolean;
            employeeHandOveredSts?:boolean;
            employeeNo?:boolean;
            employeeReasonOfRelieving?:boolean;
            };

        // To encrypt password
        const secretPass = "XkhZG4fW2t2W";

        const encryptData = async () => {
            try {
                const data = CryptoJS.AES.encrypt(JSON.stringify(EmployeePasswordValue),secretPass).toString();
                return data
            } catch (error) {
                console.error("Error encrypting data:", error);
            }
            };

        const UpdateEmployee =async (event:any)=>{
            event.preventDefault();
            let errors: Errors = {};

            const ExistEmployeeEmailList = await GetCompanyExistEmail();
            //console.log("ExistEmployeeEmailList",ExistEmployeeEmailList)
            if (ExistEmployeeEmailList.length > 0) {
                setEmployeeEmailError(true);

                //SOP-17 Modified By PCS[Testing feedback corrections]
                //setEmployeeEmailErrorMessage("Eamil is already exists");
                 setEmployeeEmailErrorMessage("Email is already exists");
                //EOF SOP-17 Modified By PCS[Testing feedback corrections]

                errors.employeeEmail = true;
                return;
            } 

            // Encrypt the data
            const GetEncryptedData = await encryptData()
            //console.log("GetEncryptedData",GetEncryptedData)

            const trimmedEmployeeNameValue = EmployeeNameValue.trim();
            if (trimmedEmployeeNameValue === '') {
                errors.employeeName=true
                setEmployeeNameError(true);
                setEmployeeNameErrorMessage("Employee name is required");
            } 
            else if( trimmedEmployeeNameValue.length >255 ){
                errors.employeeName=true
                setEmployeeNameError(true);
                setEmployeeNameErrorMessage("Max 255 characters");
            }
    
    
            const trimmedEmployeeEmailValue = EmployeeEmailValue.trim();
            if (trimmedEmployeeEmailValue === '') {
                errors.employeeEmail=true
                setEmployeeEmailError(true);
                setEmployeeEmailErrorMessage("Email is required");
            } 
            else if( trimmedEmployeeEmailValue.length >255 ){
                errors.employeeEmail=true
                setEmployeeEmailError(true);
                setEmployeeEmailErrorMessage("Max 255 characters");
            }
            else if( !(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(trimmedEmployeeEmailValue) ){
                setEmployeeEmailError(true);
                setEmployeeEmailErrorMessage("Invalid Email");
            }
    
            const trimmedEmployeeMobileValue = EmployeeMobileValue.trim();
            if (trimmedEmployeeMobileValue === '') {
                errors.employeeMobile=true
                setEmployeeMobileError(true);
                setEmployeeMobileErrorMessage("Mobile number is required");
            } 
            else if( trimmedEmployeeMobileValue.length >15 ){
                errors.employeeMobile=true
                setEmployeeMobileError(true);
                setEmployeeMobileErrorMessage("Invalid mobile number");
            }
            else if( !/^[0-9]+$/.test(trimmedEmployeeMobileValue) ){
                errors.employeeMobile=true
                setEmployeeMobileError(true);
                setEmployeeMobileErrorMessage("Numbers only");
            }
    
            const trimmedEmployeePasswordValue = EmployeePasswordValue.trim();
            if (trimmedEmployeePasswordValue === '') {
                errors.employeePassword=true
                setEmployeePasswordError(true);
                setEmployeePasswordErrorMessage("Password is required");
            } 
            else if( trimmedEmployeePasswordValue.length >12 ){
                errors.employeePassword=true
                setEmployeePasswordError(true);
                setEmployeePasswordErrorMessage("Max 12 characters");
            }
    
            if (selectedEmployeeRoleId === '' || selectedEmployeeRoleId === null || selectedEmployeeRoleId === undefined) {
                errors.employeeRole = true;
                setEmployeeRoleErrorMessage("Role is required")
            }

            if (selectedEmployeeReportToWhomId === '' || selectedEmployeeReportToWhomId === null || selectedEmployeeReportToWhomId === undefined) {
                errors.employeeReportToWhom = true;
                //SOP-17 Modified By PCS[Testing feedback Corrections]
                //setEmployeeReportToWhomErrorMessage("Report To Whom is required")
                setEmployeeReportToWhomErrorMessage("Reporting To Whom is required")
            }
    
            let trimmedEmployeeDateOfJoiningValue;
            if (EmployeeDateOfJoiningValue === null || EmployeeDateOfJoiningValue === undefined) {
                // Handle the case when EmployeeDateOfJoiningValue is null or undefined
                errors.employeeDateOfJoining = true;
                //SOP-17 Modified By PCS[Testing feedback Corrections]
                //setEmployeeDateOfJoiningErrorMessage("Date Of Joining is required");
                setEmployeeDateOfJoiningErrorMessage("Date of Joining is required");

            } else {
                let dateString;
                if (typeof EmployeeDateOfJoiningValue.format === 'function') {
                    dateString = EmployeeDateOfJoiningValue.format(); // Assuming your `Dayjs` object has a `format()` method
                } else {
                    dateString = EmployeeDateOfJoiningValue.toString(); // Convert to string or handle differently based on what EmployeeDateOfJoiningValue is
                }
                trimmedEmployeeDateOfJoiningValue = dateString.trim();
                //console.log("trimmedEmployeeDateOfJoiningValue.l", trimmedEmployeeDateOfJoiningValue.length);

                if (trimmedEmployeeDateOfJoiningValue === "") {
                    errors.employeeDateOfJoining = true;

                    //SOP-17 Modified By PCS[Testing feedback Corrections]
                    //setEmployeeDateOfJoiningErrorMessage("Date Of Joining is required");
                    setEmployeeDateOfJoiningErrorMessage("Date of Joining is required");

                } else if (trimmedEmployeeDateOfJoiningValue.length < 24) {
                    errors.employeeDateOfJoining = true;
                    setEmployeeDateOfJoiningErrorMessage("Please check Date Of Joining");
                } else {
                    setEmployeeDateOfJoiningErrorMessage("");
                }
            }
    
            let FormatedJoiningDate;
            if (trimmedEmployeeDateOfJoiningValue) {
                const trimmedDate = new Date(trimmedEmployeeDateOfJoiningValue);
                const year = trimmedDate.getFullYear();
                const month = String(trimmedDate.getMonth() + 1).padStart(2, '0');
                const date = String(trimmedDate.getDate()).padStart(2, '0');
                FormatedJoiningDate = `${year}-${month}-${date}`;
                // Now you can use the formattedDate variable where needed
              } else {
                // nothing
              }


              let trimmedEmployeeDateOfRelievingValue;
            if (EmployeeDateOfRelievingValue === null || EmployeeDateOfRelievingValue === undefined) {
                // Handle the case when EmployeeDateOfRelievingValue is null or undefined
                // errors.employeeDateOfRelieving = true;
                // setEmployeeDateOfRelievingErrorMessage("Date Of Relieving is required");
            } else {
                let dateString;
                if (typeof EmployeeDateOfRelievingValue.format === 'function') {
                    dateString = EmployeeDateOfRelievingValue.format(); // Assuming your `Dayjs` object has a `format()` method
                } else {
                    dateString = EmployeeDateOfRelievingValue.toString(); // Convert to string or handle differently based on what EmployeeDateOfRelievingValue is
                }


                trimmedEmployeeDateOfRelievingValue = dateString.trim();
                //console.log("trimmedEmployeeDateOfRelievingValue.l", trimmedEmployeeDateOfRelievingValue.length);

                if (trimmedEmployeeDateOfRelievingValue === "") {
                    errors.employeeDateOfRelieving = true;
                    //SOP-17 Modified By PCS[Testing feedback Corrections]
                    //setEmployeeDateOfRelievingErrorMessage("Date Of Relieving is required");
                    setEmployeeDateOfRelievingErrorMessage("Date of Relieving is required");


                } else if (trimmedEmployeeDateOfRelievingValue.length < 24) {
                    errors.employeeDateOfRelieving = true;
                    setEmployeeDateOfRelievingErrorMessage("Please check Date Of Relieving");
                } else {
                    setEmployeeDateOfRelievingErrorMessage("");
                }
            }

              
              let FormatedRelievingDate;
              if (trimmedEmployeeDateOfRelievingValue) {
                  const trimmedDate = new Date(trimmedEmployeeDateOfRelievingValue);
                  const year = trimmedDate.getFullYear();
                  const month = String(trimmedDate.getMonth() + 1).padStart(2, '0');
                  const date = String(trimmedDate.getDate()).padStart(2, '0');
                  FormatedRelievingDate = `${year}-${month}-${date}`;
                  // Now you can use the formattedDate variable where needed
              } else {
                  // nothing
              }
              
              
              //console.log("trimmedEmployeeDateOfRelievingValue  NEw",trimmedEmployeeDateOfRelievingValue)
              //console.log("isActive new",isActive)

              if(isActive === false && (trimmedEmployeeDateOfRelievingValue === "" || trimmedEmployeeDateOfRelievingValue === undefined || trimmedEmployeeDateOfRelievingValue === null)){
                //console.log("trimmedEmployeeDateOfRelievingValue",trimmedEmployeeDateOfRelievingValue)
                setEmployeeRelievingDetailsEnable(true)
                    errors.employeeDateOfRelieving = true;
                    //SOP-17 Modified By PCS[Testing feedback Corrections]
                    //setEmployeeDateOfRelievingErrorMessage("Date Of Relieving is required");
                    setEmployeeDateOfRelievingErrorMessage("Date of Relieving is required");

                //console.log("isActive 2",isActive)
            }

            //SOP-31 Commented By PCS[Designation changed from text to drop down]
            
                    //   let trimmedEmployeeDesignationValue;
                    //   if(EmployeeDesignationValue !== null){
                    //     trimmedEmployeeDesignationValue = EmployeeDesignationValue.trim();
                    //     if( trimmedEmployeeDesignationValue.length >255 ){
                    //         errors.employeeDesignation=true;
                    //         setEmployeeDesignationErrorMessage("Max 255 characters");
                    //     }
                    //     else {
                    //         setEmployeeDesignationError(false);
                    //         setEmployeeDesignationErrorMessage("");
                    //     }
                    //   }
            
            //SOP-31 Commented By PCS[]

            //SOP-31 Included By PCS[Designation]
                if (selectedEmployeeDesignationId === '' || selectedEmployeeDesignationId === null || selectedEmployeeDesignationId === undefined) {
                    errors.employeeDesignation = true;
                    setEmployeeDesignationErrorMessage("Designation is required")
                }
            //SOP-31 Commented By PCS[Designation]

            //SOP-31 Included  By PCS[Department]
                if (selectedEmployeeDepartmentId === '' || selectedEmployeeDepartmentId === null || selectedEmployeeDepartmentId === undefined) {
                    errors.employeeDepartment = true;
                    setEmployeeDepartmentErrorMessage("Department is required")
                }
            //SOP-31 Commented By PCS[Department]


    
            const trimmedEmployeeWorkLocationValue = EmployeeWorkLocationValue.trim();
            if(trimmedEmployeeWorkLocationValue === '') {
                errors.employeeWorkLocation=true;
                setEmployeeWorkLocationErrorMessage("Work Location is required");
            }
            else if( trimmedEmployeeWorkLocationValue.length >255 ){
                errors.employeeWorkLocation=true;
                setEmployeeWorkLocationErrorMessage("Max 255 characters");
            }
            else {
                setEmployeeDesignationError(false);
                setEmployeeDesignationErrorMessage("");
            }
    
            let trimmedEmployeeHandOveredStatusValue;
            if(EmployeeHandOveredStatusValue !== null){
                trimmedEmployeeHandOveredStatusValue = EmployeeHandOveredStatusValue.trim();
                    if( trimmedEmployeeHandOveredStatusValue.length >255 ){
                        errors.employeeHandOveredSts=true;
                        setEmployeeHandOveredStatusErrorMessage("Max 255 characters");
                    }
                    else {
                        setEmployeeHandOveredStatusError(false);
                        setEmployeeHandOveredStatusErrorMessage("");
                    }
            }
             
    
            let trimmedEmployeeNoValue ;
            if(EmployeeNoValue !== null){
                    trimmedEmployeeNoValue = EmployeeNoValue.trim();
                    if( trimmedEmployeeNoValue.length >255 ){
                    errors.employeeNo=true;
                    setEmployeeNoErrorMessage("Max 255 characters");
                }
                else {
                    setEmployeeNoError(false);
                    setEmployeeNoErrorMessage("");
                }
            }


            let trimmedEmployeeReasonOfRelievingValue;

            if( EmployeeReasonOfRelievingValue !== null ){
                trimmedEmployeeReasonOfRelievingValue = EmployeeReasonOfRelievingValue.trim();
                if( trimmedEmployeeReasonOfRelievingValue.length >255 ){
                    errors.employeeReasonOfRelieving=true;
                    //console.log("trimmedEmployeeReasonOfRelievingValue.length",trimmedEmployeeReasonOfRelievingValue.length)
                    setEmployeeReasonOfRelievingErrorMessage("Max 255 characters");
                }
                else {
                    setEmployeeReasonOfRelievingError(false);
                    setEmployeeReasonOfRelievingErrorMessage("");
                }                
            }
   
    
            if (Object.keys(errors).length > 0) {
                // If there are errors, update the state with all the errors
                setEmployeeNameError(errors.employeeName || false);
                setEmployeeEmailError(errors.employeeEmail || false);
                setEmployeePasswordError(errors.employeePassword || false);
                setEmployeeMobileError(errors.employeeMobile || false);
                setEmployeeRoleError(errors.employeeRole || false);
                setEmployeeReportToWhomError(errors.employeeReportToWhom || false);
                setEmployeeDateOfJoiningError(errors.employeeDateOfJoining || false)
                setEmployeeDateOfRelievingError(errors.employeeDateOfRelieving || false)
                setEmployeeDesignationError(errors.employeeDesignation || false)
                setEmployeeDepartmentError(errors.employeeDesignation || false)//SOP-31 Included By PCS [Department included]
                setEmployeeWorkLocationError(errors.employeeWorkLocation || false)
                setEmployeeHandOveredStatusError(errors.employeeHandOveredSts || false)
                setEmployeeNoError(errors.employeeNo || false)
                setEmployeeReasonOfRelievingError(errors.employeeReasonOfRelieving || false)
                return;
            }

            let FormatedIsActive;
            if(isActive === true){
                FormatedIsActive = 'Y'
            }
            else{
                FormatedIsActive='N'
            }

            const UpdateEmployeePayload = {
                SaveUpdateKey: 'update',
                EmployeeId:EmployeeId,
                EmployeeName:EmployeeNameValue,
                EmployeeMail:EmployeeEmailValue,
                EmployeeMobile:EmployeeMobileValue,
                EmployeePassword:GetEncryptedData,
                EmployeeRole:selectedEmployeeRoleId,
                CompanyId: CompanyId,
                Active: FormatedIsActive,
                CreatedBy: null,
                LastModifiedBy: UserId,
                IsRemove: null,
                ReportToWhom:selectedEmployeeReportToWhomId,
                DateOfJoining:FormatedJoiningDate,
                DateOfRelieving:FormatedRelievingDate,
                //SOP-31 Modified By PCS[removed designation text field & included designation & department drop down ]
                // Designation:EmployeeDesignationValue,
                DesignationId:selectedEmployeeDesignationId,
                DepartmentId:selectedEmployeeDepartmentId,
                //EOF SOP-31 Modified By PCS

                //SOP-17 Modified By PCS[Testing feedback corrections- photo not displaying after edit]
                // PhotoUrl:imageSrclink,
                 PhotoUrl:imageUrl,
                //EOF SOP-17 Modified By PCS[Testing feedback corrections]

                WorkLocation:EmployeeWorkLocationValue,
                HandOveredSts:EmployeeHandOveredStatusValue,
                EmployeeNo:EmployeeNoValue,
                ReasonOfRelieving:EmployeeReasonOfRelievingValue,
                
                // SOP-39 - TS - Asset Management - User Rights - MenuId include to payload
                MenuId: selectedMenuIds
                
    
            }
    
            //console.log("UpdateEmployeePayload",UpdateEmployeePayload)

        //SOP-17 Included By PCS[Testing feedack corrections- to avoid multiple toaster msg]

        Swal.fire({
            html: '<span style="font-size: 1.3rem;">Are you sure to save ? </span>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            cancelButtonColor: '#d33',
            confirmButtonColor:"#25425f",
            allowOutsideClick: false,
            })
        .then((result) =>
        {
        if (result.isConfirmed)
        {

        //EOF SOP-17 Included By PCS[Testing feedack corrections - to avoid multiple toaster msg]  



            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/employee/employee_create_update_delete`, UpdateEmployeePayload)
            .then((res) => {
              if (res.status === 200) {
                  //SOP-17 Modified By PCS[Testing feedback corrections]
                  //Success("Employee created successfully");
                   Success("Employee updated successfully");

                  navigate('/employee')
              }
              }).catch((error) => {
                  console.error("Error:", error);
              });
    
                //SOP-17 Included By PCS[Testing feedack corrections- to avoid multiple toaster msg]

                } 
                else  
                {
                return
                }
                });

                //EOF SOP-17 Included By PCS[Testing feedack corrections-to avoid multiple toaster msg] 



        }


   // SOP-39 - TS - Asset Management - User Rights - MenuId - start
    
   //Employee MenuList
   const GetEmployeeMenuList = async () => {
    const MenuPayload = {
        EmployeeId: EmployeeId,
        CompanyId: CompanyId
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/employee/getEmployeeMenuList`, MenuPayload);
        // console.log("response MenuList..", res.data)
        const menuData = res.data.data;
        const formattedMenus = menuData.map((menu: any) => ({
            id: menu.menuid.toString(),
            name: menu.menuname,
            // defaultmenu: menu.defaultmenu
        }));
        setSelectedMenus(formattedMenus);
    } catch (error) {
        console.error("Error:", error);
        // Failed(error.response.message);
    }
};

//list MenuList
const listGetMenuList=()=>
{
  commonService.postService(`${process.env.REACT_APP_BACKEND_URL}/employee/getMenuList`,{
  }).then((res)=>{
// console.log('Menu res',res.data.data)
 let formattedMenuList = res.data.data.map((item: any) => {
    return {
        id: item.menuid.toString(),
        name: item.menuname
        // defaultmenu: item.activestatus
     };
 });
// Filter out menus that are already in the selectedMenus list   
formattedMenuList = formattedMenuList.filter((menu: MenuType) => !selectedMenus.some(selectedMenu => selectedMenu.id === menu.id));
setMenus(formattedMenuList);
}) 
.catch((err) => {
  console.log("err",err)
  })
}
// SOP-39 TS - Asset Management - User Rights - MenuId - end

    //SOP-73 Inlcuded By PCS [Testing feedback corrections- to include list of asset ]
       const getEmployeeAssetList = () => 
        {     
            //   console.log('UserId asset list...',Employee_UserId)
           const Payload ={
            SortColumnName: EmployeeAssetorderBy,
            SortColumnDirection: EmployeeAssetorder,
            StartIndex: EmployeeAssetpage + 1,
            PageSize: EmployeeAssetrowsPerPage,
            CompanyId:CompanyId,
            Userid:Employee_UserId
                         }
                    
            // console.log('payload asset list ..',Payload)
            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/requestanasset/get_user_asset_list`,Payload)
            .then((res: any) => 
            {
        
            // console.log('response asset list ..',res)
            const formattedTableData= res.data.data.map((item:any)=>{
            return {...item,
                request_date: item.request_date ? dayjs(item.request_date).format("DD-MM-YYYY") : "",
            }
            }) 
            //  console.log("formattedTableData UL",formattedTableData)
            
            setEmployeeAssetTableData(formattedTableData);
            if(res.data.data[0]===undefined)
            {
                setEmployeeAssetCount(0);
            }
            else
            {
                setEmployeeAssetCount(res.data.data[0].total_count);
            }
            })
            .catch((error) => 
            {
            if (error.response.status === 404)
            {
                setEmployeeAssetTableData([]);
            }
            Failed(error.response.message);
            });
        };

    //EOF SOP-73 Included By PCS


        // UseEffect declaration
        useEffect(() => {
            GetEmployeeListById();
            GetUserRoleList();
            GetEmployeeReportToWhomList();
            GetDesignationList();//SOP-31 Included By PCS[Designation drop down included ]
            GetDepartmentList();//SOP-31 Included By PCS[Department drop down included ]
            // SOP-39 -TS - Asset Management - User Rights - start
            listGetMenuList(); //list MenuList  
            GetEmployeeMenuList();  //Employee MenuList
            // SOP-39 -TS - Asset Management - User Rights - end

            //SOP-73 Included By PCS [Testing corrections- to include asset list -called only when Employee_UserId is truthy]
            if (Employee_UserId) {
                        getEmployeeAssetList();
                                }
            //SOP-73 Included By PCS [Testing corrections- to include asset list]
     

        }, [
            //SOP-73 Included By PCS [testing feedback corrcetions-to include asset list- grid ]
            EmployeeAssetrowsPerPage,EmployeeAssetpage,EmployeeAssetorderBy,EmployeeAssetorder
            //EOF SOP-73 Included By PCS
            //SOP-73 Included By PCS
            ,Employee_UserId
           //EOF SOP-73 Included By PCS
        ]); 
 

// SOP-39 TS - Asset Management - User Rights - MenuId - start
//  --Drag and Drop starts--

// Define item type
type MenuType = {
    id: string;
    name: string;
  };

// Item component
const MenuItem = ({ menu, index }: { menu: MenuType; index: number }) =>
{
        return (
            <Draggable draggableId={menu.id} index={index}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={{
                  userSelect: 'none',
                  padding: 6,
                  margin: '0 0 8px 0',
                  backgroundColor: 'lightgrey',
                  // width: '100px', // Set width to 20px
                  ...provided.draggableProps.style,
                // cursor: isFixedMenu ? 'not-allowed' : 'grab', // cursor  condition
                }}
              >
                {menu.name}
              </div>
            )}
          </Draggable>
            );
    };

// DragGrid component
const DragGrid = ({ menus }: { menus: MenuType[] }) =>
    {
     return (
         <Droppable droppableId="dragGrid">
         {(provided, snapshot) => (
            <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
                minHeight: '200px', // Set a minimum height to enable scrolling
                overflowY: 'auto',  // Enable vertical scrolling
            }}
             >
             {menus
                .filter(menu => !selectedMenuIds.includes(menu.id))
                .map((menu, index) => (
                    <MenuItem key={menu.id} menu={menu} index={index} />
                ))}
 
            {provided.placeholder}
             </div>
           )}
         </Droppable>
       );
    };

// DropGrid component
const DropGrid = ({ selectedMenus }: { selectedMenus: MenuType[] }) => 
    {
        return (
          <Droppable droppableId="dropGrid">
          {(provided, snapshot) => (
            <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              minHeight: '200px', // Set a minimum height to enable scrolling
              overflowY: 'auto',  // Enable vertical scrolling
            }}
          >
            {selectedMenus.map((menu, index) => (
              <MenuItem key={menu.id} menu={menu} index={index} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
        );
    };

    //To split selected menus with comma and send it to payload as a string
    let selectionModeresult = '';
    for (let i = 0; i < selectedMenuIds.length; i++)
    {
   selectionModeresult += selectedMenuIds[i];
    if (i < selectedMenuIds.length - 1) {
     selectionModeresult += ',';
    }
    }
    // console.log("selectionModeresult",selectionModeresult)

  //To filter menus
  const filteredMenus = menus.filter((menu) =>
    menu.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
//   console.log('filtered result',filteredMenus)

    // --Drag and drop starts here--
    const onDragEnd = (result: any) => {
        const { source, destination, draggableId } = result;
    
        // If dropping is placed out of the grid
        if (!destination) return;
    
        // Remove the dragged menu from the source list
        let newSourceMenus: MenuType[];
        if (source.droppableId === 'dragGrid') {
            newSourceMenus = [...menus];
            newSourceMenus.splice(source.index, 1);
            setMenus(newSourceMenus);
        } else {
            newSourceMenus = [...selectedMenus];
            newSourceMenus.splice(source.index, 1);
            setSelectedMenus(newSourceMenus);
        }
    
        // Add the dragged menu to the destination list if it doesn't already exist
        let newDestinationMenus: MenuType[];
        if (destination.droppableId === 'dragGrid') {
            const draggedMenu = selectedMenus.find(menu => menu.id === draggableId);
            if (draggedMenu && !menus.some(menu => menu.id === draggableId)) {
                newDestinationMenus = [...menus];
                newDestinationMenus.splice(destination.index, 0, draggedMenu);
                setMenus(newDestinationMenus);
            }
        } else {
            const draggedMenu = menus.find(menu => menu.id === draggableId);
            if (draggedMenu && !selectedMenus.some(menu => menu.id === draggableId)) {
                newDestinationMenus = [...selectedMenus];
                newDestinationMenus.splice(destination.index, 0, draggedMenu);
                setSelectedMenus(newDestinationMenus);
            }
        }
    };
    // --Drag and drop ends here--
  
     // Handle checkbox change
     const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        // Filter out Menus that are already present in SelectedMenus
        const newMenus = menus.filter((menu) => !selectedMenus.some((selectedMenu) => selectedMenu.id === menu.id));
        // Move newMenus to selectedMenus
        setSelectedMenus((prevSelectedMenus) => [...prevSelectedMenus, ...newMenus]);
        // Clear Menus list
        setMenus([]);
      } else {
        listGetMenuList();
        // Clear setSelectedMenus list
        setSelectedMenus([]);
      }
    };
    // --Drag and Drop-- end
    //SOP-39 - TS - Asset Management - User Rights- list MenuList - end



   


    return(
        <>
        <MainContainerWrapper headingText='Edit Employee'>
            <Box >
            <form noValidate>
                <Box>
                <Box>
                    <Box>
                        {/* Employee Information Accordian */}
                        <Accordion sx={{
                            borderRadius:'30px',
                        }}>
                            <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            >
                            
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <FcInfo height={'1rem'} width={'1rem'} fontSize={'1.5rem'} /> 
                                <Typography fontSize={'1rem'} ml={2}> Employee Information</Typography> 
                                </Box>
                            
                                
                            </AccordionSummary>
                            <AccordionDetails sx={{marginBottom:5}}>
                                <Box>
                                    <Box>
                                    <Box>
                                        <Grid container spacing={3} display={'flex'} justifyContent={'center'}>
                                            <Grid item lg={5} md={12} xs={12} >
                                                <Box>
                                                <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Employee Name'
                                                    name='Employee'
                                                    style={{ margin: '0 10px' }}
                                                    fullWidth
                                                    required
                                                    error={EmployeeNameError}
                                                    onChange={handleEmployeeNameChange}
                                                    value={EmployeeNameValue}
                                                    helperText={EmployeeNameErrorMessage}
                                                /> 
                                                </Box> 
                                            </Grid>

                                            <Grid item lg={5} md={12} xs={12} >
                                                <Box>
                                                    <TextField
                                                        type='text'
                                                        variant="standard"
                                                        label='Email'
                                                        name='email'
                                                        style={{ margin: '0 10px' }}
                                                        fullWidth
                                                        required
                                                        error={EmployeeEmailError}
                                                        onChange={handleEmployeeEmailChange}
                                                        value={EmployeeEmailValue}
                                                        helperText={EmployeeEmailErrorMessage}
                                                    /> 
                                                </Box>
                                                
                                            </Grid>

                                            <Grid item lg={5} md={12} xs={12} >
                                                <Box>
                                                    <TextField
                                                        type='text'
                                                        variant="standard"
                                                        label='Mobile Number'
                                                        name='Employee'
                                                        style={{ margin: '0 10px' }}
                                                        fullWidth
                                                        required
                                                        error={EmployeeMobileError}
                                                        onChange={handleEmployeeMobileChange}
                                                        value={EmployeeMobileValue}
                                                        helperText={EmployeeMobileErrorMessage}
                                                        //SOP-17 Inlcuded By PCS[Testing feedback corrections-to restrict only 15 numbers]
                                                          inputProps={{
                                                            maxLength: 15
                                                        }}
                                                        //EOF SOP-17 Inlcuded By PCS[Testing  feedback corrections]
                                                    /> 
                                                </Box>
                                            </Grid>

                                            <Grid item lg={5} md={12} xs={12} >
                                                <Box>
                                                    <TextField
                                                        type='password'
                                                        variant="standard"
                                                        label='Password'
                                                        name='Employee'
                                                        style={{ margin: '0 10px' }}
                                                        fullWidth
                                                        required
                                                        error={EmployeePasswordError}
                                                        onChange={handleEmployeePasswordChange}
                                                        value={EmployeePasswordValue}
                                                        helperText={EmployeePasswordErrorMessage}
                                                        //SOP-17 Inlcuded By PCS[Testing feedback corrections-to restrict only 12 characters]
                                                        inputProps={{
                                                            maxLength: 12
                                                        }}
                                                        //EOF SOP-17 Inlcuded By PCS[Testing  feedback corrections]
                                                    /> 
                                                </Box>                                            
                                            </Grid>

                                        </Grid>
                                    </Box>
                                    </Box>
                                </Box>

                            </AccordionDetails>

                        </Accordion>
                        {/* End of Employee Information Accordian */}
                    </Box>

                    <Box mt={1}>
                        {/* Employee Role Accordian */}
                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            >
                            <Typography fontSize={'1rem'}>
                                
                                </Typography> 
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <FaUserGear height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
                                <Typography fontSize={'1rem'} ml={2}>Employee Role</Typography> 
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{marginBottom:5}}>
                                <Box>
                                    <Box>
                                    <Box>
                                        <Grid container spacing={3} display={'flex'} justifyContent={'center'}>
                                        {EmployeeRoleEnable?
                                            <Grid item lg={5} md={12} xs={12} >
                                                <Box>
                                                    <Autocomplete
                                                    id="combo-box-demo"
                                                    options={EmployeeRoleOptions as OptionType[]}
                                                    filterOptions={filterOptions}
                                                    getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                    value={EmployeeRoleOptions.find((option: OptionType) => option.value === selectedEmployeeRoleId) || null}
                                                    onChange={(event, newValue) => {
                                                        if (newValue) {
                                                            setSelectedEmployeeRoleId(newValue.value);
                                                            setEmployeeRoleError(false);
                                                            setEmployeeRoleErrorMessage("");
                                                        } else {
                                                            setSelectedEmployeeRoleId('');
                                                            setEmployeeRoleError(true);
                                                            setEmployeeRoleErrorMessage("Role is required");
                                                        }
                                                    }}
                                                    sx={{ml: 1.5 }}
                                                    renderInput={(params) => 
                                                    <TextField {...params}
                                                    error={EmployeeRoleError}
                                                    helperText={EmployeeRoleErrorMessage}
                                                        required label="Role" 
                                                        variant="standard" />}
                                                />
                                                </Box> 
                                            </Grid>
                                        :null}

                                            <Grid item lg={5} md={12} xs={12} >
                                                <Box>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        options={EmployeeReportToWhomListOptions as OptionType[]}
                                                        filterOptions={filterOptions}
                                                        getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                        value={EmployeeReportToWhomListOptions.find((option: OptionType) => option.value === selectedEmployeeReportToWhomId) || null}
                                                        onChange={(event, newValue) => {
                                                            if (newValue) {
                                                                setSelectedEmployeeReportToWhomId(newValue.value);
                                                                setEmployeeReportToWhomError(false);
                                                                setEmployeeReportToWhomErrorMessage("");
                                                            } else {
                                                                setSelectedEmployeeReportToWhomId('');
                                                                setEmployeeReportToWhomError(true);
                                                                setEmployeeReportToWhomErrorMessage("Reported to whom is required");
                                                            }
                                                        }}
                                                        sx={{ml: 1.5 }}
                                                        renderInput={(params) => 
                                                        <TextField {...params}
                                                        error={EmployeeReportToWhomError}
                                                        helperText={EmployeeReportToWhomErrorMessage}
                                                            required label="Reporting to whom" 
                                                            variant="standard" />}
                                                    />
                                                </Box>
                                                
                                            </Grid>

                                        </Grid>
                                    </Box>
                                    </Box>
                                </Box>

                            </AccordionDetails>

                        </Accordion>
                        {/* End of Employee Role Accordian */}

                    </Box>
                    <Box mt={1}>
                        {/* Joining Details Accordian */}
                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            >
                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <BsBuildingsFill height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
                                <Typography fontSize={'1rem'} ml={2}> Joining Details</Typography> 
                                </Box> 
                            </AccordionSummary>
                            <AccordionDetails sx={{marginBottom:5}}>
                                <Box>
                                    <Box>
                                    <Box>
                                        <Grid container spacing={3} display={'flex'} justifyContent={'space-evenly'}>
                                            <Grid item lg={5} md={12} xs={12} >
                                                <Box>
                                                    <FormControl fullWidth>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                       
                                                                        label="Date of Joining"
                                                                        value={EmployeeDateOfJoiningValue ? dayjs(EmployeeDateOfJoiningValue) : null}
                                                                        onChange={handleDateOfJoiningChange}
                                                                        format="DD-MM-YYYY"
                                                                        slotProps={{
                                                                            textField: {
                                                                                error: Boolean(EmployeeDateOfJoiningError),
                                                                                //SOP-17 Inlcuded By PCS[Testing feedback Corrections- to set mandatory]
                                                                                required: true
                                                                            }
                                                                        }}
                                                                    />                                                        

                                                                    {EmployeeDateOfJoiningError && (
                                                                        <FormHelperText
                                                                            error
                                                                            id="standard-weight-helper-text-dob-login"
                                                                        >
                                                                            {EmployeeDateOfJoiningErrorMessage}
                                                                        </FormHelperText>
                                                                    )}
                                                                </LocalizationProvider>
                                                    </FormControl>                                                    

                                                </Box> 
                                            </Grid>

                                   {/* SOP-31 Commented By PCS [to change designation from Text to Drop down]
                                            <Grid item lg={5} md={12} xs={12} >
                                                <Box>
                                                    <TextField
                                                        type='text'
                                                        variant="standard"
                                                        label='Designation'
                                                        name='Designation'
                                                        style={{ margin: '0 10px' }}
                                                        fullWidth
                                                        required={false}
                                                        error={EmployeeDesignationError}
                                                        // onChange={handleEmployeeDesignationChange}
                                                        value={EmployeeDesignationValue}
                                                        helperText={EmployeeDesignationErrorMessage}
                                                    /> 
                                                </Box>
                                            </Grid> */}
                                    {/* SOP-31 Included By PCS[change designation drop down included] */}
                                        <Grid item lg={2.3} md={12} xs={12} >
                                                <Box >
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        options={EmployeeDesignationOptions as OptionType[]}
                                                        filterOptions={filterOptions}
                                                        getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                        value={EmployeeDesignationOptions.find((option: OptionType) => option.value === selectedEmployeeDesignationId) || null}
                                                        onChange={(event, newValue) => {
                                                            if (newValue) {
                                                                
                                                                setSelectedEmployeeDesignationId(newValue.value);
                                                                setEmployeeDesignationError(false);
                                                                setEmployeeDesignationErrorMessage("");
                                                            } else {
                                                                setSelectedEmployeeDesignationId('');
                                                                setEmployeeDesignationError(true);
                                                                setEmployeeDesignationErrorMessage("Designation is required");
                                                            }
                                                        }}
                                                        
                                                        renderInput={(params) => 
                                                        <TextField {...params}
                                                        error={EmployeeDesignationError}
                                                        helperText={EmployeeDesignationErrorMessage}
                                                            required label="Designation" 
                                                            variant="standard" />}
                                                    />
                                                </Box> 

                                        </Grid>

                                    {/* SOP-31 Included By PCS Department included*/}
                                        <Grid item lg={2.1} md={12} xs={12} >
                                                <Box>

                                                <Autocomplete
                                                        id="combo-box-demo"
                                                        options={EmployeeDepartmentOptions as OptionType[]}
                                                        filterOptions={filterOptions}
                                                        getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                        value={EmployeeDepartmentOptions.find((option: OptionType) => option.value === selectedEmployeeDepartmentId) || null}
                                                        onChange={(event, newValue) => {
                                                            if (newValue) {
                                                                setSelectedEmployeeDepartmentId(newValue.value);
                                                                setEmployeeDepartmentError(false);
                                                                setEmployeeDepartmentErrorMessage("");
                                                            } else {
                                                                setSelectedEmployeeDepartmentId('');
                                                                setEmployeeDepartmentError(true);
                                                                setEmployeeDepartmentErrorMessage("Department is required");
                                                            }
                                                        }}
                                                        
                                                        renderInput={(params) => 
                                                        <TextField {...params}
                                                        error={EmployeeDepartmentError}
                                                        helperText={EmployeeDepartmentErrorMessage}
                                                            required label="Department" 
                                                            variant="standard" />}
                                                />


                                                </Box>
                                        </Grid>  
                    
                                    {/* EOF SOP-31 Department included*/} 

                                          
                                            <Grid item lg={5} md={12} xs={12} >
                                                <Box>
                                                    <TextField
                                                        type='text'
                                                        variant="standard"
                                                        label='Work Location'
                                                        name='workLocation'
                                                        // style={{ margin: '0 10px' }}
                                                        fullWidth
                                                        required
                                                        error={EmployeeWorkLocationError}
                                                        onChange={handleEmployeeWorkLocationChange}
                                                        value={EmployeeWorkLocationValue || ''}
                                                        helperText={EmployeeWorkLocationErrorMessage}
                                                    /> 
                                                </Box>
                                            </Grid>

                                            <Grid item lg={5} md={12} xs={12} >
                                                <Box>
                                                    <TextField
                                                        type='text'
                                                        variant="standard"
                                                        label='Employee Number'
                                                        name='EmployeeNo'
                                                        // style={{ margin: '0 10px' }}
                                                        fullWidth
                                                        required={false}
                                                        error={EmployeeNoError}
                                                        onChange={handleEmployeeNoChange}
                                                        value={EmployeeNoValue}
                                                        helperText={EmployeeNoErrorMessage}
                                                    /> 
                                                </Box>
                                            </Grid>

                                        </Grid>
                                    </Box>
                                    </Box>
                                </Box>

                            </AccordionDetails>

                        </Accordion>
                        {/* End of Joining Details Accordian */}                        
                    </Box>

                    <Box mt={1}>
                        {/* Photo Upload */}
                    <Accordion>
                            <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            >
                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <IoMdPhotos height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
                                <Typography fontSize={'1rem'} ml={2}> Upload Employee Photo </Typography> 
                                </Box> 
                            </AccordionSummary>
                            <AccordionDetails sx={{marginBottom:5}}>
                            <Grid item xs={12} sm={12} md={12} display={'flex'} justifyContent={'center'}>
                                <Typography
                                letterSpacing="0.6px"
                                marginBottom={5}
                                // marginLeft={8}
                                fontSize="10px"
                                fontWeight={200}
                                color="#25425F"
                                // py={1.5}
                                >
                                Image size must be less than 150KB
                                </Typography>
                                </Grid>
                                <Grid
                                    container
                                    // spacing={{ xs: 2, md: 2 }}
                                    // columns={{ xs: 4, sm: 8, md: 8, lg: 12 }}
                                    // rowSpacing={{ xs: 2, md: 1 }}
                                    // columnSpacing={{ xs: 2, md: 3 }}
                                >
                                    <Container  sx={{ mt: 1,}}>
                                        <Stack>
                                            <Box display={'flex'} justifyContent={'center'} >
                                            <Box bgcolor={'whitesmoke'} width="110px" height="110px">
                                                <label htmlFor="image-upload" >
                                                <img
                                                    style={{marginTop: 0 }}
                                                    src={imageUrl}//binding image
                                                    width="110px" 
                                                    alt=''
                                                />
                                                </label>
                                            </Box>
                                            </Box>

                                            <Box >
                                            <Box mt={5} display={'flex'} justifyContent={'flex-end'} >
                                                <Box className="information" style={{ display: 'flex', justifyContent: 'flex-end'}}>
                                                    <Box>
                                                    <label htmlFor="upload_image" style={{
                                                        color: "#ffffff",
                                                        width: '120px', 
                                                        height: '2.5rem', 
                                                        backgroundColor: '#25425F',
                                                        border: '1px solid transparent',
                                                        // margin:'5px',
                                                        borderRadius: '4px',
                                                        cursor: 'pointer',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        textAlign: 'center',
                                                        // lineHeight: '30px',
                                                        // marginLeft: '300px',
                                                        // marginTop:'10px' ,
                                                    }}>
                                                        Browse Image
                                                    </label>
                                                    <input
                                                        key={key}
                                                        id="upload_image"
                                                        accept="image/*"
                                                        hidden
                                                        type="file"
                                                        onChange={handleFileUpload}
                                                    />
                                                    </Box>
                                                </Box> 

                                                {EnableClearBtn ? 
                                                    <Box>
                                                    <Button
                                                        onClick={clearImage}
                                                        sx={{
                                                        color: '#ffffff',
                                                        fontWeight: "595",
                                                        marginLeft: '20%',
                                                        // marginBottom: 1,
                                                        // marginRight: 50,
                                                        width: '120px',
                                                        height: '2.5rem',
                                                        backgroundColor: '#25425F',
                                                        '&:hover': {
                                                        backgroundColor: '#25425F',
                                                        },
                                                        }}
                                                    >
                                                        Clear Image
                                                    </Button>
                                                    </Box>                                                
                                                :null  }                                               
                                            </Box>                                                
                                            </Box>

                                            
                                        </Stack>
                                    </Container>
                            </Grid>

                            </AccordionDetails>
                    </Accordion>        


                    </Box>

                    {EmployeeRelievingDetailsEnable ? 
                        <Box mt={1}>
                            {/* Relieving Details Accordian */}
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                >
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                        <BsBuildingsFill height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
                                    <Typography fontSize={'1rem'} ml={2}> Relieving Details</Typography> 
                                    </Box> 
                                </AccordionSummary>
                                <AccordionDetails sx={{marginBottom:5}}>
                                    <Box>
                                        <Box>
                                        <Box>
                                            <Grid container spacing={3} display={'flex'} 
                                            //SOP-17 Modified By PCS[Testing feedback corrections- to solve Alignment issue]
                                            //justifyContent={'space-evenly'}
                                              justifyContent={'center'}
                                            >
                                                <Grid item lg={5} md={12} xs={12} >
                                                    <Box>
                                                    <FormControl fullWidth>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DatePicker
                                                                    label="Date of Relieving"
                                                                    value={EmployeeDateOfRelievingValue ? dayjs(EmployeeDateOfRelievingValue) : null}
                                                                    onChange={handleDateOfRelievingChange}
                                                                    format="DD-MM-YYYY"
                                                                    slotProps={{
                                                                        textField: {
                                                                            error: Boolean(EmployeeDateOfRelievingError),
                                                                            //SOP-17 Inlcuded By PCS[Testing feedback Corrections- to make mandatory]
                                                                            required: true

                                                                        }
                                                                    }}
                                                                />
                                                                {EmployeeDateOfRelievingError && (
                                                                            <FormHelperText
                                                                                error
                                                                                id="standard-weight-helper-text-dob-login"
                                                                            >
                                                                                {EmployeeDateOfRelievingErrorMessage}
                                                                            </FormHelperText>
                                                                        )}
                                                            </LocalizationProvider>
                                                        </FormControl> 
                                                    </Box>
                                                    
                                                </Grid>

                                                <Grid item lg={5} md={12} xs={12} >
                                                    <Box>
                                                        <TextField
                                                            type='text'
                                                            variant="standard"
                                                            label='Hand over status'
                                                            name='Employee'
                                                            style={{ margin: '0 10px' }}
                                                            fullWidth
                                                            required={false}
                                                            error={EmployeeHandOveredStatusError}
                                                            onChange={handleEmployeeHandOveredStatusChange}
                                                            value={EmployeeHandOveredStatusValue}
                                                            helperText={EmployeeHandOveredStatusErrorMessage}
                                                        /> 
                                                    </Box>                                            
                                                </Grid>
                                            </Grid>
                                            <Grid container  mt={3} spacing={3} display={'flex'} 
                                              justifyContent={'center'}
                                           
                                             >
                                            <Grid item lg={5} md={12} xs={12} >
                                                    <Box>
                                                        <TextField
                                                            type='text'
                                                            variant="standard"
                                                            label='Reason for relieving'
                                                            name='EmployeeResonOfRelieving'
                                                            //SOP-17 Commented By PCS[Testing feedback corrections- to solve Alignment]
                                                            //style={{ margin: '0 10px' }}
                                                            fullWidth
                                                            required={false}
                                                            error={EmployeeReasonOfRelievingError}
                                                            onChange={handleEmployeeReasonOfRelievingChange}
                                                            value={EmployeeReasonOfRelievingValue}
                                                            helperText={EmployeeReasonOfRelievingErrorMessage}
                                                        /> 
                                                    </Box>                                            
                                                </Grid>
                                                <Grid item lg={5} md={12} xs={12} ></Grid>
                                            </Grid>
                                        </Box>
                                        </Box>
                                    </Box>

                                </AccordionDetails>

                            </Accordion>
                            {/* End of Relieving Details Accordian */}                        
                        </Box>                    
                    :null}

                     {/* SOP-39 -TS - start - Asset Management - User Rights- list MenuList*/}
                     <Box mt={1}>
                     <Accordion>
                        <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        >
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <MdContactPage height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
                            <Typography fontSize={'1rem'} ml={2}> User Page Rights </Typography> 
                        </Box> 
                        </AccordionSummary>
                        <AccordionDetails sx={{marginBottom:5}}>
                        {/* --- Drag and Drop starts--- */}  
                        {/* <Grid
                        container
                        spacing={{ xs: 2, md: 2 }}
                        columns={{ xs: 4, sm: 8, md: 8, lg: 12 }}
                        rowSpacing={{ xs: 2, md: 1 }}
                        columnSpacing={{ xs: 2, md: 3 }}
                        >                                     
                        <Grid  item xs={12} sm={12} md={12}> */}
                        <Box>
                            <DragDropContext onDragEnd={onDragEnd}>
                            <Box style={{
                                display: 'flex', alignItems: "center",
                                // marginLeft: "300px"
                            }}>

                           <Box mt={1} mb={2} ml={1}
                            width={'16rem'} 
                            marginLeft={4}
                            boxShadow={'rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px'} 
                            borderRadius={3}
                            border={'1px solid #757ce8'} // Border color
                            >

                            <Box style={{ marginRight: 20, height: '300px',overflow: 'auto',width:'250px' ,marginBottom:'10px'}}>
                                    <h2 style={{textAlign:'center'}}>Menus</h2>
                                <label ><input
                                    style={{fontSize:'6px'}}
                                    name='Select All'
                                    type="checkbox"
                                    onChange={handleSelectAll}
                                    /> Select All</label>
                                    <TextField
                                    variant="outlined"
                                    sx={{marginTop:'4px',marginBottom:'2px',width:'100%',padding:'1px'}}
                                    type="text"
                                    value={searchQuery}
                                    onChange={(e:any) => setSearchQuery(e.target.value)}
                                    placeholder="Search..."
                                    />
                                <DragGrid menus={filteredMenus} />
                                </Box>
                                </Box>
                                
                                <Box mt={1} mb={2} ml={1}
                                width={'16rem'} 
                                boxShadow={'rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px'} 
                                borderRadius={3}
                                border={'1px solid #757ce8'} // Border color
                                >
                                <Box style={{ marginRight: 20, 
                                    height: '300px',width:'250px' ,overflow: 'auto',marginBottom:'10px' }}>
                                    <h2 style={{textAlign:'center'}}>Selected Menus</h2>
                                    <DropGrid selectedMenus={selectedMenus} />
                                </Box>
                               </Box>
                                </Box>
                            </DragDropContext>
                            </Box>
                        {/*                         
                        </Grid>
                        </Grid> */}
                        </AccordionDetails>
                    </Accordion>        
                    </Box>
                   {/* SOP-39 -TS - end */}      
                                

                    {/*  SOP-73 Included By PCS - Including Asset List [Testing feedback corrections]*/}  

                            <Box mt={1}>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                >
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <BiSolidLayer height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
                                    <Typography fontSize={'1rem'} ml={2}> Asset List </Typography> 
                                </Box> 
                                </AccordionSummary>
                                <AccordionDetails sx={{marginBottom:5}}>
                            
                                <EnhancedTable
                                        headCells={MyPropertyHeadCell}
                                        rows={EmployeeAssettableData}
                                        rowsPerPage={EmployeeAssetrowsPerPage}
                                        page={EmployeeAssetpage}
                                        handleChangePage={EmployeeAssethandleChangePage}
                                        handleChangeRowsPerPage={EmployeeAssethandleChangeRowsPerPage}
                                        handleRequestSort={handleEmployeeAssetSort}
                                        order={EmployeeAssetorder}
                                        orderBy={EmployeeAssetorderBy}
                                        actionFunction={EmployeeAssetactionFunction}
                                        isView={false}
                                        isDelete={false}
                                        isEdit={false}
                                        total={EmployeeAssetcount}
                                        isSearch={false}
                                        HoverColor={'#E7F1FB'}
                                />

                            
                                </AccordionDetails>
                            </Accordion>        
                            </Box>
                    {/* EOF SOP-73 Included By PCS*/}  


                    </Box>

                    <Box display={'flex'} justifyContent={'flex-end'} ml={5}>
                        <Box>
                        <Typography className="label_design" sx={{mt:1.5}}>
                        <label style={{fontSize:'1rem'}} >Active State</label>
                        </Typography>
                        <label className="switchToggle">
                        <input
                            type="checkbox"
                            checked={isActive}
                            onChange={handleToggleActive} // Call the toggle function
                        />
                        <span className="sliderToggle round"></span>
                        </label>
                        </Box>
                    </Box>
                    
                    <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Button
                                        sx={{
                                            background: 'white',
                                            border: 'none',
                                            borderRadius: '5px',
                                            padding:{
                                                lg: "0 70px",
                                                md: "0 70",
                                                xs:'0 50px'
                    
                                            },
                                            height: "3rem",
                                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                        }}

                                        onClick={()=>{
                                            navigate('/employee')
                                        }} 
                                    >
                                        Back
                                    </Button>

                                    <Button
                                        sx={{
                                            background: '#25425F',
                                            border: 'none',
                                            borderRadius: '5px',
                                            padding:{
                                                lg: "0 70px",
                                                md: "0 70",
                                                xs:'0 50px'
                                            },
                                            "&:hover":{
                                                backgroundColor:'#25425F',
                                                color:'white',
                                            },
                                            color: 'white',
                                            height: "3rem",
                                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                        }}
                                        type='submit'
                                        onClick={(event)=>{
                                            UpdateEmployee(event);
                                        }}
                                    >
                                   {/* SOP-17 Modified By PCS[Testing feedback corrections] */}
                                        {/* Save */}
                                          Update
                                   {/* SOP-17 Modified By PCS[Testing feedback corrections] */}
                                    </Button>
                    </Box>
                </Box>
            </form>
            </Box>
        </MainContainerWrapper>
        </>
    )


}
export default EditEmployeePage;
// End of SOP-17