// Included by sanjana SOP-14
// Imports
import { useState,useRef,useEffect,useCallback } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import MainContainerWrapper from '../../atic-common-helpers/layout/wrappers/MainContainerWrapper';
import { useNavigate } from "react-router-dom";
import moment from 'moment'
import './Profile.scss'
import BgWaveImg from '../../asset/images/Wave3.png'
import EmptyProfileImg from '../../asset/images/UserProfile.jpg'
import { FaMobileAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { BsPersonFillGear } from "react-icons/bs";
import { BsBuildings } from "react-icons/bs";
// Included by sanjana SOP-18
import { FaEdit } from "react-icons/fa";
import Swal from 'sweetalert2';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Success } from '../../atic-common-helpers/helpers/toast.helper';
// End of SOP-18

//SOP-78 Included By PCS [Including Pricing Sign up page]
import Dialog from '@mui/material/Dialog';
import PricingSignUpPage from './PricingSignUp';
//EOF SOP-78 Included By PCS [Including Pricing Sign up page]

const ProfilePage = () =>{

    //SOP-78 Included By PCS [Including Pricing Sign up page open & Close]
        const [signUpOpen, setSignUpOpen] = React.useState(false);

            const handleSignUpOpen = () => {
                setSignUpOpen(true);
            };
            
            const handleSignUpClose = () => {
                setSignUpOpen(false);
            };
    //EOF SOP-78 Included By PCS [Including Pricing Sign up page open & Close]


    const navigate = useNavigate();

    const[UserName, setUserName] = useState('')
    const[UserMobileNo, setUserMobileNo] = useState('')
    const[UserCompanyName, setUserCompanyName] = useState('')
    // const[UserRole, setUserRole] = useState('')
    const[UserPlanTypeName, setUserPlanTypeName] = useState('')
    const[UserAllocatedAssets, setUserAllocatedAssets] = useState('')
    const[UserValidTillDate, setUserValidTillDate] = useState('')
    const[PlanBtnValue, setPlanBtnValue] = useState('')
    const[RoleName, setRoleName] = useState('')
    const[EmployeeId, setEmployeeId] = useState('')
    const [ProfileImg, setProfileImg]= useState<any>(null);
    const [imageSrclink, setImageSrclink] = useState<string>('');
    const [key, setKey] = useState(0);
    const [EnablePlanDetails, setEnablePlanDetails] = useState(true)
    const[UserEmail, setUserEmail] = useState('')
    const [EnableImgSaveBtn, setEnableImgSaveBtn] =  useState(false)


   //SOP-76 feedback points L2 (1) Included By PCS [formatting date to DD-MM-YYYY format to resole valid till date issue]
    function formatDateToDDMMYYYY(date: Date): string {
        // Extract day, month, and year
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are 0-based, so add 1
        const year = date.getFullYear();
        
        // Pad day and month with leading zeros if necessary
        const dayStr = day.toString().padStart(2, '0');
        const monthStr = month.toString().padStart(2, '0');
        
        // Format in DD-MM-YYYY
        return `${dayStr}-${monthStr}-${year}`;
               }
   //EOF SOP-76 feedback points L2 (1) Included By PCS [to resolve valid till date issue]

    // To get company user details API as per the email 
    const ToGetCompanyUserDetails = async (UserEmail:any)=>{

        const  CompanyUserDetailsPayload = {
            UserName:UserEmail,
            Active:"Y"
        }
        //console.log("CompanyUserDetailsPayload",CompanyUserDetailsPayload)
        try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/company/getCompanyUserDetails`,CompanyUserDetailsPayload);
        const UserData = res.data.data[0]

        //SOP-76 feedback points L2 (1) Included By PCS [Valid till date Issue]
             const ValidTillDateValue:any = new Date(UserData.validtilldate)
             const formattedDate = formatDateToDDMMYYYY(ValidTillDateValue);//formatting to DD-MM-YYYY format
            //  console.log('valid till date',formattedDate); 
        //EOF SOP-76 feedback points L2 (1) Included By PCS [Valid till date Issue]

        // console.log('Profile page UserData',UserData)

        setUserName(UserData.username)
        setUserMobileNo(UserData.employee_mobile)
        setUserEmail(UserData.usernameemail)
        setUserCompanyName(UserData.companyname)
        setUserAllocatedAssets(UserData.numberofassets)
        //SOP-76 feedback points L2 (1) modified by PCS [valid till date issue]
        // setUserValidTillDate(UserData.validtilldate)
        setUserValidTillDate(formattedDate)
        //EOF SOP-76 feedback points L2 (1) modified by PCS [valid till date issue]
        setUserPlanTypeName(UserData.planname)
        setRoleName(UserData.user_role_name)
        setProfileImg(UserData.photo_url)
        setEmployeeId(UserData.employee_id)

        if( UserData.planname === 'Free'){
            setPlanBtnValue('Buy Plan')
        }
        else if( UserData.planname === 'Standard'){
            //SOP-78 Modified By PCS [Button name change]
            // setPlanBtnValue('Upgrade Plan')
               setPlanBtnValue('Renew Plan')
            //EOF SOP-78 Modified By PCS [Button name change]
        }
        else if( UserData.planname === 'Enterprise'){
            setPlanBtnValue('Renew Plan')
        } 

        if(UserData.user_role_name !== 'Super Admin'){
            setEnablePlanDetails(false); 
        }
        return res.data.data[0] || [];
        } catch (err) {
        console.log( "co",err); 
        } 
    
        }


        // Included by sanjana SOP-18 to upload profile photo 
        const handleFileUpload = (event: any) => {
            const file = event.target?.files[0];
            //console.log("file", file)
            if (file) {
                var fileSize = file.size;
                var maxSize = 150 * 1024;
                if (fileSize > maxSize) {
                    event.preventDefault(); // Prevent form submission
                    Swal.fire({
                        icon: 'warning',
                        text: 'Image size must be less than 150KB.',
                    })
                }
                else {
                const reader = new FileReader();
                reader.onloadend = () => {
                    // 'reader.result' contains the Base64 data URL of the image
                    const imageDataUrl = reader.result;
                    setImageSrclink(String(imageDataUrl))
                    setProfileImg(imageDataUrl); // Set the state with the Base64 data URL if needed
                    setEnableImgSaveBtn(true);
                };
                reader.readAsDataURL(file);
                }
            };
            // Reset the input element by changing the key
            // by incrementing the `key` variable when clearing the image, you force React to re - render the input element, 
            // allowing you to select the same image again
            setKey(key + 1);
        }
        // Edn of SOP-18

        const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
        const GlobalData = JSON.parse(GlobalDataFromSession);
        let CompanyId = GlobalData.GlobalCompanyId;
        let UserId = GlobalData.GlobalUserId;

        let GlobalEmail = GlobalData.GlobalUserEmail

        // Included by sanjana SOP-18 to cretae image
        const clearImage = useCallback(() => {
            Swal.fire({
              title: 'Delete Image',
              text: 'Are you sure you want to clear the image?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#25425F',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes',
            }).then((result) => {
              if (result.isConfirmed) {
                //clear the image
                // setProfileImg(null);
                // setImageSrclink('');
                setEnableImgSaveBtn(false);
                ToGetCompanyUserDetails(GlobalEmail);
              }
            });
          }, [setProfileImg, setImageSrclink]); 
        //   End of SOP-18


        // Included by sanjana SOP-18 to save the profile image by calling employee_update_photo_url function from employee controller

            const ProfileImgSave = () => {
                const UpdateEmployeePayload = {
                    SaveUpdateKey: 'update',
                    EmployeeId: EmployeeId,
                    CompanyId: CompanyId,
                    LastModifiedBy: UserId,
                    PhotoUrl: imageSrclink       
                };

                // Commented by sanjana SOP-46 
                // Convert payload to JSON string to get its size
                // const payloadSize = JSON.stringify(UpdateEmployeePayload).length;

                // // Check if payload size exceeds the backend payload limited allowed size
                // if (payloadSize > 102400) {
                //     Swal.fire({
                //         icon: 'warning',
                //         text: 'Image size is too large',
                //     })
                //     ToGetCompanyUserDetails(GlobalEmail);
                //     setEnableImgSaveBtn(false);
                //     return;
                // }
                // End of SOP-46

                //console.log("UpdateEmployeePayload", UpdateEmployeePayload);

                commonService
                    .postService(`${process.env.REACT_APP_BACKEND_URL}/employee/employee_update_photo_url`, UpdateEmployeePayload)
                    .then((res) => {
                        if (res.status === 200) {
                            Success("Profile Photo Updated successfully");
                            ToGetCompanyUserDetails(GlobalEmail);
                            setEnableImgSaveBtn(false);
                        }
                    }).catch((err) => {
                        console.error("Error:", err);
                    });
            };

        //   End of SOP-18
    

    useEffect(() => { 
        
        const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
        const GlobalData = JSON.parse(GlobalDataFromSession);
        ToGetCompanyUserDetails(GlobalData.GlobalUserEmail);
        
      }, []);

    return(
        <>
        <MainContainerWrapper headingText=''>
            <Box>
                <Box display={'flex'} justifyContent={'center'}>
                    <Box bgcolor={'white'}  borderRadius={3}
                        sx={{
                            height:{ 
                                lg:'auto',
                                xs:'auto',
                                md:'auto'
                            },
                            width:{
                                lg:800,
                                md:800,
                                xs:700

                            }
                        }}>
                        <Box p={3} sx={{
                            height:{ 
                                lg:'auto',
                                xs:'auto',
                                md:'auto'
                            },
                        }} >
                            <Grid container sx={{
                                height:{ 
                                    lg:250,
                                    xs:600,
                                    md:250
                                },
                            }}>
                                <Grid item lg={6} md={7} xs={12}>
                                    <Box>
                                        <Typography 
                                sx={{
                                    fontSize:{
                                        lg:'2.5rem',  
                                        md:'2.5rem',
                                        xs:'2.5rem'
                                    },
                                    color:'#4169e1',
                                    fontWeight:'bold'
                                }}
                                >
                                    {UserName}
                                        </Typography>

                                    <Box mt={2}>

                                    <Box mt={2}>
                                                <Typography sx={{
                                                fontSize:{
                                                    lg:'1.3rem',
                                                    md:'1.3rem',
                                                    xs:'1.2rem'
                                                }
                                                 }}>
                                                <Box display={'flex'}>
                                                <Tooltip title='Company Name'>
                                                    <Box>
                                                    <BsBuildings style={{color:'#4169e1', marginRight:10}}/>
                                                    </Box>
                                                </Tooltip>                                                
                                                 {UserCompanyName}
                                                 
                                                </Box>
                                                </Typography>
                                            </Box>
                                            <Box mt={2}>
                                                <Typography sx={{
                                                fontSize:{
                                                    lg:'1.3rem',
                                                    md:'1.3rem',
                                                    xs:'1.2rem'
                                                },
                                            }}>
                                                <Box display={'flex'}>
                                                <Tooltip title='Mobile Number'>
                                                    <Box>
                                                <FaMobileAlt style={{color:'#4169e1', marginRight:10}}/>
                                                    </Box>
                                                </Tooltip>                                                
                                                {UserMobileNo}
                                                </Box>
                                                </Typography>
                                            </Box>

                                            <Box mt={2}>
                                                <Typography sx={{
                                                fontSize:{
                                                    lg:'1.3rem',
                                                    md:'1.3rem',
                                                    xs:'1.2rem'
                                                }
                                            }}>
                                                <Box display={'flex'}>
                                                <Tooltip title='Email'>
                                                    <Box>
                                                    <MdOutlineMail style={{color:'#4169e1', marginRight:10}}/>
                                                    </Box>
                                                </Tooltip>                                                
                                                {UserEmail}
                                                </Box>
                                                </Typography>
                                            </Box>


                                            <Box mt={2}>
                                                <Typography sx={{
                                                fontSize:{
                                                    lg:'1.3rem',
                                                    md:'1.3rem',
                                                    xs:'1.2rem'
                                                }
                                            }}>
                                                <Box display={'flex'}>
                                                <Tooltip title='Role'>
                                                    <Box>
                                                    <BsPersonFillGear style={{color:'#4169e1', marginRight:10}}/>  
                                                    </Box>
                                                </Tooltip>                                                
                                                {RoleName}
                                                </Box>
                                                
                                                </Typography>
                                            </Box>

                                    </Box> 
                                    </Box>

                                </Grid>

                                <Grid item lg={5} md={5} xs={12} >
                                    <Box height={200} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                        <img src={ProfileImg || EmptyProfileImg} 
                                        alt='Profile' 
                                        width={160} height={160} 
                                        style={{padding:3, 
                                        borderRadius:150,
                                        boxShadow:'rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset'
                                        }}/>
                                        
                                    </Box>
                                    {/* Included by sanjana SOP-18 to add edit, save & clear button  */}
                                    <Box display={'flex'} justifyContent={'center'} >
                                        <Box className="information" style={{ display: 'flex', justifyContent: 'center'}}>
                                                    <Box>
                                                    <label htmlFor="upload_image" style={{
                                                        borderRadius: '4px',
                                                        cursor: 'pointer',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        textAlign: 'center',
                                                    }}>
                                                         <FaEdit fontSize={'1.5rem'} style={{cursor:'pointer'}}/>
                                                    </label>
                                                    <input
                                                        key={key}
                                                        id="upload_image"
                                                        accept="image/*"
                                                        hidden
                                                        type="file"
                                                        onChange={handleFileUpload}
                                                    />
                                                    </Box>

                                        </Box>
                                        {/* Commented by sanjana SOP-46 */}
                                        {/* {EnableImgSaveBtn?
                                            <Box display={'flex'} justifyContent={'space-around'}>
                                                <Button
                                                sx={{
                                                    background: '#25425F',
                                                    border: 'none',
                                                    borderRadius: '5px',
                                                    fontSize:'0.7rem',
                                                    color: 'white',
                                                    // height: "3rem",
                                                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                                                    '&:hover':{
                                                        backgroundColor:'#25425F',
                                                        color:'white'
                                                    }
                                                        }}
                                                        onClick={ProfileImgSave}
                                                >
                                                    Save
                                                </Button>

                                                <Button
                                                sx={{
                                                    background: '#25425F',
                                                    marginLeft:3,
                                                    border: 'none',
                                                    borderRadius: '5px',
                                                    fontSize:'0.7rem',
                                                    color: 'white',
                                                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                                                    '&:hover':{
                                                        backgroundColor:'#25425F',
                                                        color:'white'
                                                    }
                                                        }}
                                                        onClick={clearImage}
                                                >
                                                    Clear
                                                </Button>
                                            </Box>
                                        :null} */}
                                        {/* End of SOP-46 */}
                                    </Box>
                                    {/* End of SOP-18 */}

                                    {/* Included by sanjana SOP-46  */}
                                        <Box mt={2}>
                                        {EnableImgSaveBtn?
                                            <Box display={'flex'} justifyContent={'space-evenly'}>
                                                <Button
                                                sx={{
                                                    background: '#25425F',
                                                    border: 'none',
                                                    borderRadius: '5px',
                                                    fontSize:'0.7rem',
                                                    color: 'white',
                                                    // height: "3rem",
                                                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                                                    '&:hover':{
                                                        backgroundColor:'#25425F',
                                                        color:'white'
                                                    }
                                                        }}
                                                        onClick={ProfileImgSave}
                                                >
                                                    Save
                                                </Button>

                                                <Button
                                                sx={{
                                                    background: '#25425F',
                                                    marginLeft:2,
                                                    border: 'none',
                                                    borderRadius: '5px',
                                                    fontSize:'0.7rem',
                                                    color: 'white',
                                                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                                                    '&:hover':{
                                                        backgroundColor:'#25425F',
                                                        color:'white'
                                                    }
                                                        }}
                                                        onClick={clearImage}
                                                >
                                                    Clear
                                                </Button>
                                            </Box>
                                        :null}
                                        </Box>
                                    {/* End of SOP-46 */}
                                    
                                </Grid>                           
                            </Grid>
                        </Box>
                        {EnablePlanDetails? 
                        
                            <Box p={3}>
                                        <Box >
                                        <Typography
                                        sx={{
                                            fontSize:{
                                                lg:'2rem',  
                                                md:'2rem',
                                                xs:'2rem'
                                            },
                                            color:'#4169e1',
                                            fontWeight:'bold'
                                        }}
                                        >
                                            Plan Details
                                        </Typography>
                                        </Box>
                                            <Grid container>
                                                <Grid item lg={3} md={3} xs={12} mt={2}>
                                                <Typography sx={{
                                                fontSize:{
                                                    lg:'1.3rem',
                                                    md:'1.3rem',
                                                    xs:'1.3rem'
                                                },
                                                // color:'white'
                                            }}>
                                                <span style={{color:'#4169e1'}}>Plan:  </span>   {UserPlanTypeName}
                                                </Typography>
                                                </Grid>

                                                <Grid item lg={4} md={4} xs={12} mt={2}>
                                                    <Typography sx={{
                                                    fontSize:{
                                                        lg:'1.3rem',
                                                        md:'1.3rem',
                                                        xs:'1.3rem'
                                                    }
                                                }}>
                                                    <span style={{color:'#4169e1'}}>Allocated Assets:  </span>    {UserAllocatedAssets}
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={5} md={5} xs={12} mt={2}>
                                                    <Typography sx={{
                                                    fontSize:{
                                                        lg:'1.3rem',
                                                        md:'1.3rem',
                                                        xs:'1.3rem'
                                                    }
                                                }}>
                                                    {/* //SOP-76 feedback points L2 (1) Included By PCS [Valid till date issue]*/}
                                                    {/* <span style={{color:'#4169e1'}}>Valid Till Date:  </span>  { moment(UserValidTillDate.split('T')[0]).format("DD-MM-YYYY") } */}
                                                    <span style={{color:'#4169e1'}}>Valid Till Date: </span> {UserValidTillDate}
                                                    {/* //EOF SOP-76 feedback points L2 (1) Included By PCS [Valid till date issue] */}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                </Box>
                        :null}
                        
                    </Box>
                </Box>                
            </Box>
            <Box display={'flex'} justifyContent={'center'}>
            <Box mt={3}
            sx={{ 
                width:{
                    lg:900,
                    md:900,
                    xs:700

                },display:{
                    lg:'flex',
                    md:'flex',
                    xs:'flex'
                }, 
                justifyContent: {
                    lg:'space-between',
                    md:'space-between',
                    xs:'space-evenly'
                }, 
                 }} >
                    <Button
                        sx={{
                            background: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            padding:{
                                lg: "0 70px",
                                md: "0 70",
                                xs:'0 50px'

                            },
                            height: "3rem",
                            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                            
                        }}
                        onClick={()=>{
                            navigate('/home')
                        }}
                            

                    >
                        Back
                    </Button>


                    {EnablePlanDetails? 
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                            sx={{
                                background: '#25425F',
                                border: 'none',
                                borderRadius: '5px',
                                padding:{
                                    lg: "0 70px",
                                    md: "0 70",
                                    xs:'0 50px'

                                },
                                color: 'white',
                                height: "3rem",
                                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                                '&:hover':{
                                    backgroundColor:'#25425F',
                                    color:'white'
                                }
                                    }}
                                    type='submit'
                                    onClick={(event)=>{
                                        // createCompany(event);
                                        handleSignUpOpen();//SOP-78 Included By PCS [to open Renew plan  page]
                                       
                                    }}
                                >
                                    {PlanBtnValue}
                                </Button>
                        </Box>                    
                    :null}
          
                </Box>                
            </Box>
        {/* //SOP-78 Included By PCS [Including Pricing Sign up page] */}
          <Dialog  open={signUpOpen} fullWidth >
                    <Box  height={60} bgcolor={'#4169e1'}>
                        <Box  display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            <Typography fontFamily={'Segoe UI'} fontSize={'1.5rem'} color={'white'}>
                               Plan Upgradation
                            </Typography>
                        </Box>
                        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        </Box>
                    </Box>

              <PricingSignUpPage  handleSignUpClose={handleSignUpClose} /> 

           
            </Dialog>
        {/* //EOF SOP-78 Included By PCS [Including Pricing Sign up page] */}

        </MainContainerWrapper>
        
        </> 
    )


}

export default ProfilePage;

// End of SOP-14