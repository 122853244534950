// Included by sanjana SOP-25 to create add asset request page
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';


const AddRequestAnAssetPage =
({
    handleAddRequestClose,
    AssetId
}: {
    handleAddRequestClose: (event: React.MouseEvent) => void,
    AssetId: any;
})=>{


    const [ RequesRemarksValue, setRequesRemarksValue] = useState('');
    const [ AssetName, setAssetName] = useState('');

    // Included by sanjana SOP-28 Use navigate
    const navigate = useNavigate();
    // End of SOP-28

    // Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId
    let UserId = GlobalData.GlobalUserId
    // Included by sanjana SOP-40 to get Employee id
    let EmployeeId = GlobalData.GlobalEmployeeId
    // End of SOP-40
    //console.log("CompanyId",CompanyId)


    // To get remarks data while typing text in remarks field
    const handleRequesRemarksChange =( event:any)=>{
        setRequesRemarksValue(event.target.value);
    }

    // To render handle close function to close dialouge box
    const handleClose = (event: React.MouseEvent) => {
        if (handleAddRequestClose && typeof handleAddRequestClose === 'function') {
            handleAddRequestClose(event);
        }
    };

    
    // To get asset details as per the selected asset ( assetid )
    const GetAssetListById = () =>{

        //console.log("AssetId",AssetId)
        //console.log("CompanyId",CompanyId)
        commonService
         .postService(`${process.env.REACT_APP_BACKEND_URL}/master/getAssetById`, {
                    AssetId:AssetId,
                    CompanyId:CompanyId,
         })
         .then((res: any) => 
         { 
            //console.log("res",res)
            const AssetData = res.data.data[0]
            //console.log("AssetData",AssetData)
            setAssetName(AssetData.assetname)

         }).catch((error) =>  
         { 
          Failed(error.response.message);
         });

    }

    // To create request an asset by calling asset_request_create_update_cancel from backend controller
    const createRequestAnAsset = (event:any) =>{

        event.preventDefault();

        const RequestAnAssetPayload ={
            SaveUpdateKey : "create",
            CompanyId : CompanyId,
            RequestId: 0,
            RequestBy:UserId,
            // Modified by sanjana SOP-40
            // RequestForWhichEmployeeId:UserId,
            RequestForWhichEmployeeId:EmployeeId,
            // End of SOP-40
            RequestForWhichAssetId:AssetId,
            RequestRemarks:RequesRemarksValue,
            IsCancelled:null,
            // Modified by sanjana SOP-46 to pass user id instead employee id
            CreatedBy:UserId,
            // CreatedBy:EmployeeId,
            // End of SOP-46
            LastModifiedBy:null
        }

        console.log("RequestAnAssetPayload",RequestAnAssetPayload)
        commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/requestanasset/asset_request_create_update_cancel`, RequestAnAssetPayload)
        .then((res) => {
          if (res.status === 200) {
              Success("Asset Requested Successfully");
              handleClose(event);
            //   Included by sanjana SOP-28 for navigating to home page 
                navigate('/home')
            // End of SOP-28
          }
          }).catch((error) => {
              console.error("Error:", error);
          });

    }

    useEffect(() => {
        GetAssetListById();
      }, []); 
     

    return(
        // Desing starts
        <Box height={300} width={400}>
            <Box >
                <form noValidate>
                <Box >
                    <Box mt={1} display={'flex'} justifyContent={'center'} >
                        <Typography 
                        sx={{ fontSize: '1.5rem',color:'black',
                            }} >
                            Request Asset
                        </Typography>
                    </Box>

                    <Box >
                        <Box mt={5} ml={3} display={'flex'} >
                        <Typography 
                            sx={{ fontSize: '1rem',color:'black',
                                }} >
                                Asset Name :
                            </Typography>
                            <Typography 
                            sx={{ fontSize: '1rem',color:'#0079FF', ml:2
                                }} >
                                 {AssetName}
                            </Typography>

                        </Box>
                    
                    </Box>
                    <Box mt={2} ml={3} mr={3}>
                    <TextField
                        type='text'
                        variant="standard"
                        label='Remarks'
                        name='request_remarks'
                        fullWidth
                        onChange={handleRequesRemarksChange}
                        value={RequesRemarksValue}
                    />
                    </Box>
                </Box>

                <Box mt={5} mb={3} display={'flex'} alignItems={'center'} justifyContent={'space-evenly'}>
                                <Button
                                    sx={{
                                        background: 'white',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 50px",
                                            md: "0 50",
                                            xs:'0 50px'
                
                                        },
                                        // color: {accent},
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}

                                    onClick={handleClose} 
                                >
                                    Back
                                </Button>

                                <Button
                                    sx={{
                                        background: '#25425F',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 50px",
                                            md: "0 50",
                                            xs:'0 50px'
                                        },
                                        "&:hover":{
                                            backgroundColor:'#25425F',
                                            color:'white',
                                        },
                                        color: 'white',
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}
                                    type='submit'
                                    onClick={(event)=>{
                                        createRequestAnAsset(event);
                                    }}
                                >
                                    Save
                                </Button>
                                </Box>                    
                </form>



            </Box>
        </Box>
        // Design end
    )

}

export default AddRequestAnAssetPage

// End of SOP-25