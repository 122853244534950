// Incluedd by sanjana SOP-56
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Tree, TreeNode } from "react-organizational-chart";
import { FaUser } from "react-icons/fa";

// To set style for node
const StyledNode = styled.div`
  padding:0.5px;
  border-radius: 5px;
  display: inline-block;
  border: 1px solid ${(props) => props.color || "#4169e1"};
  background-color: ${(props) => props.color || "#4169e1"};
  color: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`;

// to set random color for tree node
const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// To build hierarchy as per the parent and child
const buildHierarchy = (data) => {
  ////console.log("buildHierarchy", data);

  const map = {};
  const roots = [];

  // Step 1: Create a map for each node
  data.forEach((node) => {
    map[node._designationid] = { ...node, children: [] };
    ////console.log("map after adding node", map);
  });

  // Step 2: Link children to their parents and find root nodes
  data.forEach((node) => {
    //console.log("node", node);
    if (!node._reported_to_which_designationid || !map[node._reported_to_which_designationid]) {
      roots.push(map[node._designationid]);
      //console.log("roots after adding node", roots);
    } else {
      if (map[node._reported_to_which_designationid]) {
        map[node._reported_to_which_designationid].children.push(map[node._designationid]);
        //console.log("map after linking child", map);
      }
    }
  });

  //console.log("Final roots", roots);
  return roots;
};

// To render tree node for parent & child
const renderTreeNodes = (nodes) => {
  return nodes.map((node) => {
    if (node.children.length > 0) {
      return (
        <TreeNode
          key={node._designationid}
          label={
            <StyledNode color={getRandomColor()}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FaUser style={{ fontSize: "1.2rem" }} />
                <h4>{`${node.content}`}</h4>
              </div>
            </StyledNode>
          }
        >
          {renderTreeNodes(node.children)}
        </TreeNode>
      );
    } else {
      return (
        <TreeNode
          key={node._designationid}
          label={
            <StyledNode color={getRandomColor()}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FaUser style={{ fontSize: "1.2rem" }} />
                <h4>{`${node.content}`}</h4>
              </div>
            </StyledNode>
          }
        />
      );
    }
  });
};


// To set tree structure
const StyledTree = ({ data }) => {
  const [hierarchy, setHierarchy] = useState([]);

  useEffect(() => {
    if (data) {
      const transformedData = buildHierarchy(data);
      setHierarchy(transformedData);
    }
  }, [data]);

  if (!hierarchy || hierarchy.length === 0) return <div></div>;

  const GlobalTopDesignationFromSession = sessionStorage.getItem("TopDesignation");
  const GlobalTopDesignation = JSON.parse(GlobalTopDesignationFromSession);

  return (
    <TreeWrapper>
      <Tree
        lineWidth={"2px"}
        lineColor={"#25425f"}
        lineBorderRadius={"5px"}
        label={
          <StyledNode color={getRandomColor()}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaUser style={{ fontSize: "1.2rem" }} />
              <h4>{`${GlobalTopDesignation}`}</h4>
            </div>
          </StyledNode>
        }
      >
        {renderTreeNodes(hierarchy)}
      </Tree>
    </TreeWrapper>
  );
};

const TreeWrapper = styled.div`
  max-width: 100%;
  overflow-x: auto;
  padding: 20px;
`;

// To get preview data from session
const PreviewHierarchyChart = () => {
  const GlobalHierarchyDataFromSession = sessionStorage.getItem("HierarchyDataToStoreInSession");
  const GlobalHierarchyData = JSON.parse(GlobalHierarchyDataFromSession);

  return <StyledTree data={GlobalHierarchyData} />;
};

export default PreviewHierarchyChart;
// End of SOP-56