// Included by sanjana SOP-29 to create approve approval page
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import deleteAlert from '../../component/HOC/deleteAlert';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

const ViewApproveApprovalsPage=
// To get handleApproveApprovalsPageClose & getMyPendingApprovalsList from catgories page
({
    handleViewClose,
    handleEditOpen,
    RequestId
}: {
    handleViewClose: (event: React.MouseEvent) => void,
    handleEditOpen: (event: React.MouseEvent) => void,
    RequestId:any
})=>{


    const [ApproveRemarksError, setApproveRemarksError]=useState(false)
    const [ApproveRemarksValue, setApproveRemarksValue]=useState('')
    const [ApproveRemarksErrorMessage, setApproveRemarksErrorMessage]=useState('')
    const [ApproveOptions, setApproveOptions] = useState<OptionType[]>([]);
    const [selectedApproveId, setSelectedApproveId] = useState<any | null>('');
    const [ApproveError, setApproveError]=useState(false)
    const [ApproveErrorMessage, setApproveErrorMessage]=useState('')
    const [ ApprovalAllocatationSts, setApprovalAllocatationSts]= useState('')

    type OptionType = {
        name: string;
        value: string;
    };
    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });

        //  Global data from session storage
        const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
        const GlobalData = JSON.parse(GlobalDataFromSession);
        //console.log("GlobalData",GlobalData)
        let CompanyId = GlobalData.GlobalCompanyId
        const UserId = GlobalData.GlobalUserId;

    //  Handle Approve while typing Approve and validations
    const handleApproveRemarksChange = (event:any) => {
        setApproveRemarksValue(event.target.value);
        const trimmedApproveRemarksValue =  event.target.value
        if( trimmedApproveRemarksValue.length !== 255 ){
            setApproveRemarksError(false);
            setApproveRemarksErrorMessage("");
        }
    };

    // To render handle close function to close dialouge box
    const handleClose = (event: React.MouseEvent) => {
        if (handleViewClose && typeof handleViewClose === 'function') {
            handleViewClose(event);
        }
    };

    // to render handleEditOpen function after closing dialouge box
    const EditApproveApprovalOpen= (event: React.MouseEvent) => {
        //console.log("ApprovalAllocatationSts",ApprovalAllocatationSts)
        if(handleEditOpen && typeof handleEditOpen === 'function' && ApprovalAllocatationSts !== "P"){
            Swal.fire({
                html: '<span style="font-size: 1.3rem;">Request is already allocated, You cannot edit</span>',
                confirmButtonText: 'OK',
                });
        }
        else if(handleEditOpen && typeof handleEditOpen === 'function' && ApprovalAllocatationSts === "P"){
            handleEditOpen(RequestId)
        }
    };




    const GetApproveList = async () => { 
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/myapprovals/get_approve_status_list`);
            //console.log("Approve" ,res.data.data);
            let formattedApprove = res.data.data.map((item:any) => {
            const { sys_approve_status_id, sys_approve_status_name } = item;
            return {
                name: sys_approve_status_name,
                value: sys_approve_status_id,
            };
            });
            setApproveOptions(formattedApprove)
        } catch (err) {
            //console.log( "co",err);
        }
        };

    // To get Approved Approval details as per the id 
    const GetApprovedApprovalListById =()=>{

        commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/myapprovals/get_approval_approved_list_by_id`, {
            CompanyId: CompanyId,
            RequestId: RequestId,
            })
            .then((res: any) => 
            { 
            console.log("res",res)
            const ApprovedAssetData = res.data.data[0]
            //console.log("ApprovedAssetData",ApprovedAssetData)
            setApproveRemarksValue(ApprovedAssetData.request_approve_remarks)
            setApprovalAllocatationSts(ApprovedAssetData.allocatation_status)
            let formattedApproveStatus = res.data.data.map((item:any) => {
                const { sys_approve_status_id, sys_approve_status_name } = item;
                return {
                    name: sys_approve_status_name,
                    value: sys_approve_status_id,
                };
                });
                setSelectedApproveId(formattedApproveStatus[0].value)            
            }).catch((error) =>  
            { 
            Failed(error.response.message);
            });
    }
    

 

    useEffect(() => {
        GetApproveList();
        GetApprovedApprovalListById();
    }, []); 


    return(
        <Box>
            <Box bgcolor={'white'} borderRadius={3}
            sx={{
                width:{
                    lg:600,
                    md:600,
                    xs:350
            }}} >
                <Box>
                <Box p={3}>
                        <Typography variant='h1'
                        sx={{
                            fontSize:{
                                lg:'2rem',
                                md:'2rem',
                                xs:'1.5rem'
                            }
                        }}
                        >
                        View Approve
                        </Typography>
                    </Box>
                        <Box p={3}>
                            <Grid container >
                                <Grid item lg={12} md={12} xs={12} >
                                    <Autocomplete
                                        id="combo-box-demo"
                                        disabled
                                        options={ApproveOptions as OptionType[]}
                                        filterOptions={filterOptions}
                                        getOptionLabel={(option: unknown) => (option as OptionType).name}
                                        value={ApproveOptions.find((option: OptionType) => option.value === selectedApproveId) || null}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setSelectedApproveId(newValue.value);
                                                setApproveError(false);
                                                setApproveErrorMessage("");
                                            } else {
                                                setSelectedApproveId('');
                                                setApproveError(true);
                                                setApproveErrorMessage("Approve is required");
                                            }
                                        }}
                                        // sx={{ width: 235, ml: 1 }}
                                        renderInput={(params) => 
                                        <TextField {...params}
                                        error={ApproveError}
                                        autoFocus
                                        disabled
                                        helperText={ApproveErrorMessage}
                                        required label="Approve Status" 
                                        variant="standard" />}
                                    />
                                </Grid>

                                <Grid item mt={3} lg={12} md={12} xs={12} >
                                            <TextField 
                                            id="outlined-basic" 
                                            label="Approve Remarks" 
                                            variant="outlined" 
                                            name='ApproveRemarks'
                                            fullWidth
                                            disabled
                                            multiline
                                            rows={4}
                                            error={ApproveRemarksError}
                                            onChange={handleApproveRemarksChange}
                                            value={ApproveRemarksValue}
                                            helperText={ApproveRemarksErrorMessage}                                           
                                            />
                                        </Grid>
                            </Grid>
                        </Box>

                    <Box mt={5} ml={2} mr={2} mb={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Button
                                    sx={{
                                        background: 'white',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        // color: {accent},
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}

                                    onClick={handleClose} 
                                >
                                    Back
                                </Button>

                                <Button
                                    sx={{
                                        background: '#25425F',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        "&:hover":{
                                            backgroundColor:'#25425F',
                                            color:'white',
                                        },
                                        color: 'white',
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}
                                    onClick={(event)=>{
                                        EditApproveApprovalOpen(event);
                                        handleClose(event);
                                    }}
                                >
                                    Edit
                                </Button>
                                </Box>
                </Box>
            </Box>
        
        </Box>
    )
}
export default ViewApproveApprovalsPage;

// End of SOP-29