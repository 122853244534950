// Included by sanjana SOP-34 to create ticket list page
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import deleteAlert from '../../component/HOC/deleteAlert';
import dayjs from 'dayjs';
import AllocateTicketPage from './AllocateTicket';
import ViewTicketPage from './ViewTicket';
import EditTicketPage from './EditTicket';

//SOP-76 feedback points L2 (4) Included by PCS [combining ticket & ticketing services]
import AddTicketingServicesPage from '../TicketingServices/AddTicketingServices';
import ViewTicketingServicePage from '../TicketingServices/ViewTicketingServics';
import EditTicketingServicePage from '../TicketingServices/EditTicketingServices';
//EOF SOP-76 feedback points L2 (4) Included by PCS [combining ticket & ticketing services]

const TicketListPage = () =>{

    // useSate
    let  [PendingTicketsorder, setPendingTicketsOrder] = React.useState<"desc" | "asc">("desc");
    let  [PendingTicketsorderBy, setPendingTicketsOrderBy] = React.useState("");
    let  [PendingTicketstableData, setPendingTicketsTableData] = React.useState([]);
    const [PendingTicketspage, setPendingTicketsPage] = React.useState<number>(0);
    const [PendingTicketscount, setPendingTicketsCount] = useState<number>(0);
    const [PendingTicketsrowsPerPage, setPendingTicketssetRowsPerPage] = React.useState<number>(10);
    let  [AllocatedTicketsorder, setAllocatedTicketsOrder] = React.useState<"desc" | "asc">("desc");
    let  [AllocatedTicketsorderBy, setAllocatedTicketsOrderBy] = React.useState("");
    let  [AllocatedTicketstableData, setAllocatedTicketsTableData] = React.useState([]);
    const [AllocatedTicketspage, setAllocatedTicketsPage] = React.useState<number>(0);
    const [AllocatedTicketscount, setAllocatedTicketsCount] = useState<number>(0);
    const [AllocatedTicketsrowsPerPage, setAllocatedTicketssetRowsPerPage] = React.useState<number>(10);
    const [AllocateOpen, setAllocateOpen] = useState(false);
    //const [ViewOpen, setViewOpen] = useState(false); //SOP-76 feedback points L2 (4) commented by PCS[removed allocated list grid]
    //const [EditOpen, setEditOpen] = useState(false); //SOP-76 feedback points L2 (4)  commented by PCS[removed allocated list grid]
    const [TicketId, setTicketId] = useState()


     //  To open add dialouge box
     const handleAddOpen = (id:any) => {
        setAllocateOpen(true);
        setTicketId(id)
                                       };

    // To close add dialouge box
    const handleAddClose = () => {
    setAllocateOpen(false);
                              };

   //SOP-76 feedback points L2 (4)Included by PCS [combining ticket & ticketing services] starts here ...
    
        // useSate
        let  [PendingTicketingServicesorder, setPendingTicketingServicesOrder] = React.useState<"desc" | "asc">("desc");
        let  [PendingTicketingServicesorderBy, setPendingTicketingServicesOrderBy] = React.useState("");
        let  [PendingTicketingServicestableData, setPendingTicketingServicesTableData] = React.useState([]);
        const [PendingTicketingServicespage, setPendingTicketingServicesPage] = React.useState<number>(0);
        const [PendingTicketingServicescount, setPendingTicketingServicesCount] = useState<number>(0);
        const [PendingTicketingServicesrowsPerPage, setPendingTicketingServicessetRowsPerPage] = React.useState<number>(10);
        let  [AllocatedTicketingServicesorder, setAllocatedTicketingServicesOrder] = React.useState<"desc" | "asc">("desc");
        let  [AllocatedTicketingServicesorderBy, setAllocatedTicketingServicesOrderBy] = React.useState("");
        let  [AllocatedTicketingServicestableData, setAllocatedTicketingServicesTableData] = React.useState([]);
        const [AllocatedTicketingServicespage, setAllocatedTicketingServicesPage] = React.useState<number>(0);
        const [AllocatedTicketingServicescount, setAllocatedTicketingServicesCount] = useState<number>(0);
        const [AllocatedTicketingServicesrowsPerPage, setAllocatedTicketingServicessetRowsPerPage] = React.useState<number>(10);
        const [AllocateOpenTicketingServices, setAllocateOpenTicketingServices] = useState(false);
        const [ViewOpen, setViewOpen] = useState(false);
        const [EditOpen, setEditOpen] = useState(false);
         const [TicketIdTicketingServices, setTicketIdTicketingServices] = useState()

        //  To open add dialouge box [ticketing services]
                const handleAddOpenTicketingService = (id:any) => {
                    setAllocateOpenTicketingServices(true);
                    setTicketIdTicketingServices(id)
                };

        // To close add dialouge box [ticketing services]
                const handleAddCloseTicketingService = () => {
                    setAllocateOpenTicketingServices(false);
                };

        //  To open View dialouge box [ticketing services]
                const handleViewOpen = (id:any) => {
                    setViewOpen(true);
                    setTicketIdTicketingServices(id)
                };

        // To close View dialouge box [ticketing services]
                const handleViewClose = () => {
                    setViewOpen(false);
                };

        //  To open Edit dialouge box [ticketing services]
                const handleEditOpen = (id:any) => {
                    setEditOpen(true);
                    setTicketIdTicketingServices(id)
                };

        // To close Edit dialouge box
                const handleEditClose = () => {
                setEditOpen(false);
                };


    //EOF SOP-76 feedback points L2 (4) Included By PCS [combining ticket & ticketing services] ends here



    //SOP-76 feedback points L2 (4) commented by PCS [removed allocated list grid]
            //  To open View dialouge box
            // const handleViewOpen = (id:any) => {
            //     setViewOpen(true);
            //     setTicketId(id)
            // };

            // // To close View dialouge box
            // const handleViewClose = () => {
            // setViewOpen(false);
            // };
            //  To open Edit dialouge box
            // const handleEditOpen = (id:any) => {
            //     setEditOpen(true);
            //     setTicketId(id)
            // };

            // // To close Edit dialouge box
            // const handleEditClose = () => {
            // setEditOpen(false);
            // };
    //EOF SOP-76 feedback points L2 (4) commented by PCS [removed allocated list grid]

        
    // Head cell of My Pending Allocation Request
    const PendingTicketsHeadCell = [
        {
            id: "ticket_number",
            numeric: false,
            disablePadding: true,
            label: "Ticket #",
            isSort: true,
            isBold: true,
        },
        {
            id: "ticket_date",
            numeric: false,
            disablePadding: false,
            label: "Ticket Date",
            isSort: true,
            isBold: true,
            dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
        },
        {
            id: "ticket_issuedescription",
            numeric: false,
            disablePadding: false,
            label: "Issue Description",
            isSort: true,
            isBold: true,
            },
        {
        id: "ticketraisedbyname",
        numeric: false,
        disablePadding: false,
        label: "Employee Name",
        isSort: true,
        isBold: true,
        },
        {
   
        id: "Allocate",
        numeric: false,
        disablePadding: false,
        label: "Actions",
        isSort: false,
        },
       
        ];

    // SOP-76 feedback points L2 (4)  commenetd by PCS  [removing Alllcoated Tickets list grid]    
            // const AllocatedTicketsHeadCell = 
            //[
            //     {
            //         id: "ticket_number",
            //         numeric: false,
            //         disablePadding: true,
            //         label: "Ticket #",
            //         isSort: true,
            //         isBold: true,
            //     },
            //     {
            //         id: "ticket_date",
            //         numeric: false,
            //         disablePadding: false,
            //         label: "Ticket Date",
            //         isSort: true,
            //         isBold: true,
            //         dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
            //     },
            //     {
            //         id: "ticket_issuedescription",
            //         numeric: false,
            //         disablePadding: false,
            //         label: "Issue Description",
            //         isSort: true,
            //         isBold: true,
            //         },
            //         {
            //         id: "ticket_allocatetoname",
            //         numeric: false,
            //         disablePadding: false,
            //         label: "Allocated Name",
            //         isSort: true,
            //         isBold: true,
            //         },
            //         {
            //             id: "ticket_allocatedate",
            //             numeric: false,
            //             disablePadding: false,
            //             label: "Ticket Allocated Date",
            //             isSort: true,
            //             isBold: true,
            //             dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
            //         },
            //         {
            //         id: "ticket_allocateremarks",
            //         numeric: false,
            //         disablePadding: false,
            //         label: "Allocation Remarks",
            //         isSort: true,
            //         isBold: true,
            //         },
            //         {
            //         id: "action",
            //         numeric: false,
            //         disablePadding: false,
            //         label: "Actions",
            //         isSort: false,
            //         },
            //     ];
    //EOF SOP-76 feedback points L2 (4)  commenetd By PCS [removing Alllcoated Ticket list grid]   
 
 
    // SOP-76 feedback points L2 (4)  [combining ticketing & ticketing services - Including ticketing services grids]    
         // Head cells for Open tickets and completed tickets
       const PendingTicketingServicesHeadCell = [
            {
                id: "ticket_number",
                numeric: false,
                disablePadding: true,
                label: "Ticket #",
                isSort: true,
                isBold: true,
            },
            {
                id: "ticket_date",
                numeric: false,
                disablePadding: false,
                label: "Ticket Date",
                isSort: true,
                isBold: true,
                dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
            },
            {
                id: "ticket_issuedescription",
                numeric: false,
                disablePadding: false,
                label: "Issue Description",
                isSort: true,
                isBold: true,
                },
            {
            id: "ticketraisedbyname",
            numeric: false,
            disablePadding: false,
            label: "Employee Name",
            isSort: true,
            isBold: true,
            },
            {
                id: "ticket_allocatedate",
                numeric: false,
                disablePadding: false,
                label: "Ticket Allocated Date",
                isSort: true,
                isBold: true,
                dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
            },
            {
            id: "ticket_allocatetoname",
            numeric: false,
            disablePadding: false,
            label: "Allocated to",
            isSort: true,
            isBold: true,
            },
            {
            id: "ticket_allocateremarks",
            numeric: false,
            disablePadding: false,
            label: "Allocation Remarks",
            isSort: true,
            isBold: true,
            },
            {
            id: "Open",
            numeric: false,
            disablePadding: false,
            label: "Actions",
            isSort: false,
            },
        
            ];
        const AllocatedTicketingServicesHeadCell = [
            {
                id: "ticket_number",
                numeric: false,
                disablePadding: true,
                label: "Ticket #",
                isSort: true,
                isBold: true,
            },
            {
                id: "ticket_date",
                numeric: false,
                disablePadding: false,
                label: "Ticket Date",
                isSort: true,
                isBold: true,
                dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
            },
            {
                id: "ticket_issuedescription",
                numeric: false,
                disablePadding: false,
                label: "Issue Description",
                isSort: true,
                isBold: true,
                },
                {
                id: "ticket_allocatetoname",
                numeric: false,
                disablePadding: false,
                label: "Allocated Name",
                isSort: true,
                isBold: true,
                },
                {
                id: "ticket_allocatedate",
                numeric: false,
                disablePadding: false,
                label: "Ticket Allocated Date",
                isSort: true,
                isBold: true,
                dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
                },
                {
                id: "ticket_allocateremarks",
                numeric: false,
                disablePadding: false,
                label: "Allocation Remarks",
                isSort: true,
                isBold: true,
                },
                {
                id: "service_completedate",
                numeric: false,
                disablePadding: false,
                label: "Service Completed Date",
                isSort: true,
                isBold: true,
                dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
                },
                {
                id: "service_completionstatus_name",
                numeric: false,
                disablePadding: false,
                label: "Service Completion Status",
                isSort: true,
                isBold: true,
                },
                {
                id: "service_remarks",
                numeric: false,
                disablePadding: false,
                label: "Services Remarks",
                isSort: true,
                isBold: true,
                },
                {
                id: "action",
                numeric: false,
                disablePadding: false,
                label: "Actions",
                isSort: false,
                },
            
            ];
    // EOF SOP-76 feedback points L2 (4)  [combining ticketing & ticketing services ]   

    //  Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    //console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId
    const UserId = GlobalData.GlobalUserId;
    let EmployeeId = GlobalData.GlobalEmployeeId //SOP-76 feedback points L2 (4)  [combining ticketing & ticketing services ]

    // To get pending ticket list
        const getPendingTicketsList = () => 
            {       
                commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/get_pending_Ticket_list`, {
                    SortColumnName: PendingTicketsorderBy,
                    SortColumnDirection: PendingTicketsorder,
                    StartIndex: PendingTicketspage + 1,
                    PageSize: PendingTicketsrowsPerPage,
                    CompanyId:CompanyId,
                })
                .then((res: any) => 
                {
                    //console.log("res P",res)
                const formattedTableData= res.data.data.map((item:any)=>{
                return {...item,
                    id:item.ticketid,
                    ticket_date: item.ticket_date ? dayjs(item.ticket_date).format("DD-MM-YYYY") : "",
                    ticket_allocatedate: item.request_approve_date ? dayjs(item.request_approve_date).format("DD-MM-YYYY") : "",
                }
                }) 
                //console.log("formattedTableData",formattedTableData)
                
                setPendingTicketsTableData(formattedTableData);
                if(res.data.data[0]===undefined)
                {
                    setPendingTicketsCount(0);
                }
                else
                {
                    setPendingTicketsCount(res.data.data[0].total_count);
                }
                })
            .catch((error) => 
                {
                if (error.response.status === 404)
                {
                    setPendingTicketsTableData([]);
                }
                Failed(error.response.message);
                });
            };

    //SOP-76 feedback points L2 (4) included By PCS [removing allocated list grid]
    // To get Allocated ticket list
        const getAllocatedTicketsList = () => 
            {       
                commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/get_allocated_Ticket_list`, {
                    SortColumnName: AllocatedTicketsorderBy,
                    SortColumnDirection: AllocatedTicketsorder,
                    StartIndex: AllocatedTicketspage + 1,
                    PageSize: AllocatedTicketsrowsPerPage,
                    CompanyId:CompanyId,
                })
                .then((res: any) => 
                {
                    //console.log("res A",res)
                const formattedTableData= res.data.data.map((item:any)=>{
                return {...item,
                    id:item.ticketid,
                    ticket_date: item.ticket_date ? dayjs(item.ticket_date).format("DD-MM-YYYY") : "",
                    ticket_allocatedate: item.ticket_allocatedate ? dayjs(item.ticket_allocatedate).format("DD-MM-YYYY") : "",
                }
                }) 
                //console.log("formattedTableData",formattedTableData)
                
                setAllocatedTicketsTableData(formattedTableData);
                if(res.data.data[0]===undefined)
                {
                    setAllocatedTicketsCount(0);
                }
                else
                {
                    setAllocatedTicketsCount(res.data.data[0].total_count);
                }
                })
            .catch((error) => 
                {
                if (error.response.status === 404)
                {
                    setAllocatedTicketsTableData([]);
                }
                Failed(error.response.message);
                });
            };
    //EOF SOP-76 feedback points L2 (4) included By PCS [removing allocated list grid]

        // Sort Handler 
            const handlePendingTicketsIncidentRequestSort = (event: any, property: any) =>
                {
                const isAsc = PendingTicketsorderBy === property && PendingTicketsorder === "asc";
                PendingTicketsorder = isAsc ? "desc" : "asc";
                setPendingTicketsOrder(PendingTicketsorder);
                PendingTicketsorderBy = property;
                setPendingTicketsOrderBy(PendingTicketsorderBy);
                };
        
        
        // Action function of table icons
            const PendingTicketsactionFunction = (id: string, actionText: string) => 
            {
                    if (actionText === "Allocate") {
                    handleAddOpen(id)
                };
                // if (actionText === "edit") navigate(`/edit_nationality/${id}`);
            };


        // Pagination Handler of table
        const PendingTicketshandleChangePage = (event: unknown, newPage: number) => {
            setPendingTicketsPage(newPage);
        };
    
        // Rows Per page Handler
        const PendingTicketshandleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
            ) => {
                setPendingTicketssetRowsPerPage(+event.target.value);
                setPendingTicketsPage(0);
        };

    //SOP-76 feedback points L2 (4) commented By PCS  [removing allocated list grid related functions]
        // Sort Handler
        // const handleAllocatedTicketsIncidentRequestSort = (event: any, property: any) =>
        //     {
        //     const isAsc = AllocatedTicketsorderBy === property && AllocatedTicketsorder === "asc";
        //     AllocatedTicketsorder = isAsc ? "desc" : "asc";
        //     setAllocatedTicketsOrder(AllocatedTicketsorder);
        //     AllocatedTicketsorderBy = property;
        //     setAllocatedTicketsOrderBy(AllocatedTicketsorderBy);
        //     };
        
        
        // Action function of table icons
        // const AllocatedTicketsactionFunction = (id: string, actionText: string) => 
        // {
        //     if (actionText === "view") {
        //         handleViewOpen(id)
        //     };
        // };
    
    
        // Pagination Handler of table
        // const AllocatedTicketshandleChangePage = (event: unknown, newPage: number) => {
        //     setAllocatedTicketsPage(newPage);
        // };
        
        // Rows Per page Handler
        // const AllocatedTicketshandleChangeRowsPerPage = (
        // event: React.ChangeEvent<HTMLInputElement>
        //     ) => {
        //         setAllocatedTicketssetRowsPerPage(+event.target.value);
        //         setAllocatedTicketsPage(0);
        // };
    //EOF SOP-76 L2(4) [removing allocated list related functions]

    //SOP-76 feedback points L2 (4) [combining ticketing & ticketing services]

        // To get pending ticket list
                const getPendingTicketingServicesList = () => 
                    {       
                        commonService
                        .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/get_pending_ticket_service_list`, {
                            SortColumnName: PendingTicketingServicesorderBy,
                            SortColumnDirection: PendingTicketingServicesorder,
                            StartIndex: PendingTicketingServicespage + 1,
                            PageSize: PendingTicketingServicesrowsPerPage,
                            CompanyId:CompanyId,
                            // Modified by sanjana SOP-40
                            // UserId:UserId
                            UserId:EmployeeId,
                            // End of SOP-40
                        })
                        .then((res: any) => 
                        {
                            console.log("res P",res)
                        const formattedTableData= res.data.data.map((item:any)=>{
                        return {...item,
                            id:item.ticketid,
                            ticket_date: item.ticket_date ? dayjs(item.ticket_date).format("DD-MM-YYYY") : "",
                            ticket_allocatedate: item.ticket_allocatedate ? dayjs(item.ticket_allocatedate).format("DD-MM-YYYY") : "",
                        }
                        }) 
                        //console.log("formattedTableData",formattedTableData)
                        
                        setPendingTicketingServicesTableData(formattedTableData);
                        if(res.data.data[0]===undefined)
                        {
                            setPendingTicketingServicesCount(0);
                        }
                        else
                        {
                            setPendingTicketingServicesCount(res.data.data[0].total_count);
                        }
                        })
                    .catch((error) => 
                        {
                        if (error.response.status === 404)
                        {
                            setPendingTicketingServicesTableData([]);
                        }
                        Failed(error.response.message);
                        });
                    };

        // To get Allocated ticket list
                const getAllocatedTicketingServicesList = () => 
                    {       
                        commonService
                        .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/get_allocated_ticket_service_list`, {
                            SortColumnName: AllocatedTicketingServicesorderBy,
                            SortColumnDirection: AllocatedTicketingServicesorder,
                            StartIndex: AllocatedTicketingServicespage + 1,
                            PageSize: AllocatedTicketingServicesrowsPerPage,
                            CompanyId:CompanyId,
                            // Modified by sanjana SOP-40
                            // UserId:UserId
                            UserId:EmployeeId,
                            // End of SOP-40
                        })
                        .then((res: any) => 
                        {
                            console.log("res A",res)
                        const formattedTableData= res.data.data.map((item:any)=>{
                        return {...item,
                            id:item.ticketid,
                            ticket_date: item.ticket_date ? dayjs(item.ticket_date).format("DD-MM-YYYY") : "",
                            ticket_allocatedate: item.ticket_allocatedate ? dayjs(item.ticket_allocatedate).format("DD-MM-YYYY") : "",
                            service_completedate: item.service_completedate ? dayjs(item.service_completedate).format("DD-MM-YYYY") : "",
                        }
                        }) 
                        //console.log("formattedTableData",formattedTableData)
                        
                        setAllocatedTicketingServicesTableData(formattedTableData);
                        if(res.data.data[0]===undefined)
                        {
                            setAllocatedTicketingServicesCount(0);
                        }
                        else
                        {
                            setAllocatedTicketingServicesCount(res.data.data[0].total_count);
                        }
                        })
                    .catch((error) => 
                        {
                        if (error.response.status === 404)
                        {
                            setAllocatedTicketingServicesTableData([]);
                        }
                        Failed(error.response.message);
                        });
                    };

        // Sort Handler
        const handlePendingTicketingServicesIncidentRequestSort = (event: any, property: any) =>
            {
            const isAsc = PendingTicketingServicesorderBy === property && PendingTicketingServicesorder === "asc";
            PendingTicketingServicesorder = isAsc ? "desc" : "asc";
            setPendingTicketingServicesOrder(PendingTicketingServicesorder);
            PendingTicketingServicesorderBy = property;
            setPendingTicketingServicesOrderBy(PendingTicketingServicesorderBy);
            };


        // Action function of table icons
        const PendingTicketingServicesactionFunction = (id: string, actionText: string) => 
        {
            if (actionText === "Open") {
                handleAddOpenTicketingService(id)
            };
        
        };

        // Pagination Handler of table
        const PendingTicketingServiceshandleChangePage = (event: unknown, newPage: number) => {
            setPendingTicketingServicesPage(newPage);
        };

        // Rows Per page Handler
        const PendingTicketingServiceshandleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
            ) => {
                setPendingTicketingServicessetRowsPerPage(+event.target.value);
                setPendingTicketingServicesPage(0);
        };
    
       // Sort Handler
            const handleAllocatedTicketingServicesIncidentRequestSort = (event: any, property: any) =>
                {
                const isAsc = AllocatedTicketingServicesorderBy === property && AllocatedTicketingServicesorder === "asc";
                AllocatedTicketingServicesorder = isAsc ? "desc" : "asc";
                setAllocatedTicketingServicesOrder(AllocatedTicketingServicesorder);
                AllocatedTicketingServicesorderBy = property;
                setAllocatedTicketingServicesOrderBy(AllocatedTicketingServicesorderBy);
                };
    
    
        // Action function of table icons
            const AllocatedTicketingServicesactionFunction = (id: string, actionText: string) => 
            {
                if (actionText === "view") {
                    handleViewOpen(id)
                };
            };


         // Pagination Handler of table
            const AllocatedTicketingServiceshandleChangePage = (event: unknown, newPage: number) => {
                setAllocatedTicketingServicesPage(newPage);
            };
            
         // Rows Per page Handler
            const AllocatedTicketingServiceshandleChangeRowsPerPage = (
            event: React.ChangeEvent<HTMLInputElement>
                ) => {
                    setAllocatedTicketingServicessetRowsPerPage(+event.target.value);
                    setAllocatedTicketingServicesPage(0);
            };


    //EOF SOP-76 feedback points L2 (4)[combining ticketing & ticketing services]

    useEffect(() => {
        getPendingTicketsList();
        getAllocatedTicketsList(); 
        getPendingTicketingServicesList();//SOP-76 feedback points L2 [combining ticketing & ticketing services ]
        getAllocatedTicketingServicesList();//SOP-76 feedback points L2 [combining ticketing & ticketing services ]
    }, [PendingTicketsrowsPerPage,PendingTicketspage,PendingTicketsorderBy,PendingTicketsorder,
        //SOP-76 feedback points L2 commented by PCS [removing allcoated grid list]
        // AllocatedTicketsrowsPerPage,AllocatedTicketspage,AllocatedTicketsorderBy,AllocatedTicketsorder, 
        //SOP-76 feedback points L2 included by PCS [combining ticketing & ticketing services  ]
        PendingTicketingServicesrowsPerPage,PendingTicketingServicespage,PendingTicketingServicesorderBy,PendingTicketingServicesorder,
        AllocatedTicketingServicesrowsPerPage,AllocatedTicketingServicespage,AllocatedTicketingServicesorderBy,AllocatedTicketingServicesorder,
    ]); 
     

    return(
        <>
        <MainContainerWrapper headingText='Ticket List'>
            <Box>
                <Box>
                    <EnhancedTable
                    headCells={PendingTicketsHeadCell}
                    rows={PendingTicketstableData}
                    rowsPerPage={PendingTicketsrowsPerPage}
                    page={PendingTicketspage}
                    handleChangePage={PendingTicketshandleChangePage}
                    handleChangeRowsPerPage={PendingTicketshandleChangeRowsPerPage}
                    handleRequestSort={handlePendingTicketsIncidentRequestSort}
                    order={PendingTicketsorder}
                    orderBy={PendingTicketsorderBy}
                    actionFunction={PendingTicketsactionFunction}
                    isView={false}
                    isDelete={false}
                    isEdit={false}
                    total={PendingTicketscount}
                    isSearch={false}
                    HoverColor={'#E7F1FB'}
                    />
                </Box>
    {/* //SOP-76 feedback points L2 (4) commenetd by PCS [removing allocated ticket list]*/}
                    {/* <Box mt={5}>
                    <Typography variant='h1'>
                        Allocated Ticket List
                    </Typography>
                    <Box mt={3}>
                        <EnhancedTable
                        headCells={AllocatedTicketsHeadCell}
                        rows={AllocatedTicketstableData}
                        rowsPerPage={AllocatedTicketsrowsPerPage}
                        page={AllocatedTicketspage}
                        handleChangePage={AllocatedTicketshandleChangePage}
                        handleChangeRowsPerPage={AllocatedTicketshandleChangeRowsPerPage}
                        handleRequestSort={handleAllocatedTicketsIncidentRequestSort}
                        order={AllocatedTicketsorder}
                        orderBy={AllocatedTicketsorderBy}
                        actionFunction={AllocatedTicketsactionFunction}
                        isView={true}
                        isDelete={false}
                        isEdit={false}
                        total={AllocatedTicketscount}
                        isSearch={false}
                        HoverColor={'#E7F1FB'}
                        />   
                        </Box>
                    </Box>  */}
    {/* //EOF SOP-76 feedback points L2 (4) commented by PCS [removing allocated ticket list]*/}

   {/* //SOP-76 feedback points L2 (4)  included by PCS [combining ticket and ticketing service screen] */}

                <Box  mt={5}>
                <Typography variant='h1'>
                Open  Tickets
                </Typography>
                <Box mt={3}>
                    <EnhancedTable
                    headCells={PendingTicketingServicesHeadCell}
                    rows={PendingTicketingServicestableData}
                    rowsPerPage={PendingTicketingServicesrowsPerPage}
                    page={PendingTicketingServicespage}
                    handleChangePage={PendingTicketingServiceshandleChangePage}
                    handleChangeRowsPerPage={PendingTicketingServiceshandleChangeRowsPerPage}
                    handleRequestSort={handlePendingTicketingServicesIncidentRequestSort}
                    order={PendingTicketingServicesorder}
                    orderBy={PendingTicketingServicesorderBy}
                    actionFunction={PendingTicketingServicesactionFunction}
                    isView={false}
                    isDelete={false}
                    isEdit={false}
                    total={PendingTicketingServicescount}
                    isSearch={false}
                    HoverColor={'#E7F1FB'}
                    />
                </Box>
                </Box>
                <Box mt={5}>
                <Typography variant='h1'>
                    Completed Tickets
                </Typography>
                <Box mt={3}>
                    <EnhancedTable
                    headCells={AllocatedTicketingServicesHeadCell}
                    rows={AllocatedTicketingServicestableData}
                    rowsPerPage={AllocatedTicketingServicesrowsPerPage}
                    page={AllocatedTicketingServicespage}
                    handleChangePage={AllocatedTicketingServiceshandleChangePage}
                    handleChangeRowsPerPage={AllocatedTicketingServiceshandleChangeRowsPerPage}
                    handleRequestSort={handleAllocatedTicketingServicesIncidentRequestSort}
                    order={AllocatedTicketingServicesorder}
                    orderBy={AllocatedTicketingServicesorderBy}
                    actionFunction={AllocatedTicketingServicesactionFunction}
                    isView={true}
                    isDelete={false}
                    isEdit={false}
                    total={AllocatedTicketingServicescount}
                    isSearch={false}
                    HoverColor={'#E7F1FB'}
                    />   
                    </Box>
                </Box>

    {/* //EOF SOP-76 feedback points L2 (4)  included by PCS [combining ticket and ticketing service screen] */}

           <Box>
                    <Box>
                        <Dialog  open={AllocateOpen}  >
                            <AllocateTicketPage 
                            handleAddClose={handleAddClose}
                            getPendingTicketsList={getPendingTicketsList}
                            TicketId={TicketId}
                            getAllocatedTicketsList={getAllocatedTicketsList}
                            />
                        </Dialog>
                    </Box>
                   {/* //SOP-76 L2 (4) commented by PCS as allocated list is removed */}
                    {/* <Box>
                        <Dialog  open={ViewOpen}  >
                            <ViewTicketPage 
                            handleViewClose={handleViewClose}
                            handleEditOpen={handleEditOpen}
                            TicketId={TicketId}
                            />
                        </Dialog>
                    </Box> */}

                    {/* <Box>
                        <Dialog  open={EditOpen}  >
                            <EditTicketPage 
                            handleEditClose={handleEditClose}
                            getPendingTicketsList={getPendingTicketsList}
                            TicketId={TicketId}
                            getAllocatedTicketsList={getAllocatedTicketsList}
                            />
                        </Dialog>
                    </Box> */}
                     {/* //EOF SOP-76 L2 (4) commented by PCS as allocated list is removed */}

                     {/* //SOP-76 L2 (4) Included By PCS [combining ticketing & ticketing services] */}
                     <Box>
                        <Dialog  open={AllocateOpenTicketingServices}  >
                            <AddTicketingServicesPage 
                            handleAddClose={handleAddCloseTicketingService}
                            getPendingTicketingServicesList={getPendingTicketingServicesList}
                            TicketId={TicketIdTicketingServices}
                            getAllocatedTicketingServicesList={getAllocatedTicketingServicesList}
                            />
                        </Dialog>
                    </Box>

                    <Box>
                        <Dialog  open={ViewOpen}  >
                            <ViewTicketingServicePage
                            handleViewClose={handleViewClose}
                            handleEditOpen={handleEditOpen}
                            TicketId={TicketIdTicketingServices}
                            />
                        </Dialog>
                    </Box>

                    <Box>
                        <Dialog  open={EditOpen}  >
                            <EditTicketingServicePage 
                            handleEditClose={handleEditClose}
                            getPendingTicketingServicesList={getPendingTicketingServicesList}
                            TicketId={TicketIdTicketingServices}
                            getAllocatedTicketingServicesList={getAllocatedTicketingServicesList}
                            />
                        </Dialog>
                    </Box>

             {/* //EOF SOP-76 L2 (4) Included By PCS [combining ticketing & ticketing services] */}

        </Box>
            </Box>

        </MainContainerWrapper>
        
        </>
    )
}

export default TicketListPage;

// End of SOP-34