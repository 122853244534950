// Included by sanjana SOP-30 to create Allocations approval page
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    Grid,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import deleteAlert from '../../component/HOC/deleteAlert';
import dayjs from 'dayjs';

const EditAllocationsPage=
({
    handleEditClose,
    getMyPendingAllocationssList,
    getMyApprovedAllocationsList,
    RequestId
}: {
    handleEditClose: (event: React.MouseEvent) => void,
    getMyPendingAllocationssList: (event: React.MouseEvent) => void,
    getMyApprovedAllocationsList: (event: React.MouseEvent) => void,
    RequestId:any
})=>{

    // useState
    const [AllocationsRemarksError, setAllocationsRemarksError]=useState(false)
    const [AllocationsRemarksValue, setAllocationsRemarksValue]=useState('')
    const [AllocationsRemarksErrorMessage, setAllocationsRemarksErrorMessage]=useState('')
    const [AllocationsOptions, setAllocationsOptions] = useState<OptionType[]>([]);
    const [selectedAllocationsId, setSelectedAllocationsId] = useState<any | null>('');
    const [AllocationStatusError, setAllocationStatusError]=useState(false)
    const [AllocationStatusErrorMessage, setAllocationStatusErrorMessage]=useState('')

    type OptionType = {
        name: string;
        value: string;
    };
    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });

    //  Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    //console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId
    const UserId = GlobalData.GlobalUserId;

    //  Handle Allocations while typing Allocations and validations
    const handleAllocationsRemarksChange = (event:any) => {
        setAllocationsRemarksValue(event.target.value);
        const trimmedAllocationsRemarksValue =  event.target.value
        if( trimmedAllocationsRemarksValue.length !== 255 ){
            setAllocationsRemarksError(false);
            setAllocationsRemarksErrorMessage("");
        }
    };

    // To render handle close function to close dialouge box
    const handleClose = (event: React.MouseEvent) => {
        if (handleEditClose && typeof handleEditClose === 'function') {
            handleEditClose(event);
        }
    };

    // to render getMyPendingAllocationssList function after approving request
    const handlegetMyPendingAllocationssList= (event: React.MouseEvent) => {
        if (getMyPendingAllocationssList && typeof getMyPendingAllocationssList === 'function') {
            getMyPendingAllocationssList(event);
        }
    };

    // to render getMyApprovedAllocationsList function after approving request
    const handlegetMyApprovedAllocationsList= (event: React.MouseEvent) => {
        if (getMyApprovedAllocationsList && typeof getMyApprovedAllocationsList === 'function') {
            getMyApprovedAllocationsList(event);
        }
    };

    const GetAllocationsList = async () => { 
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/myapprovals/get_approve_status_list`);
            //console.log("Allocations" ,res.data.data);
            let formattedAllocations = res.data.data.map((item:any) => {
                const { sys_approve_status_id, sys_approve_status_name } = item;
                return {
                    name: sys_approve_status_name,
                    value: sys_approve_status_id,
                };
            });
            setAllocationsOptions(formattedAllocations)
        } catch (err) {
            //console.log( "co",err);
        }
        };


    // To get Allocationsd Approval details as per the id 
    const GetAllocationsdApprovalListById =()=>{

        commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/allocation/get_allocation_approved_list_by_id`, {
            CompanyId: CompanyId,
            RequestId: RequestId,
            })
            .then((res: any) => 
            { 
            console.log("res",res)
            const AllocationsdAssetData = res.data.data[0]
            //console.log("AllocationsdAssetData",AllocationsdAssetData)
            setAllocationsRemarksValue(AllocationsdAssetData.allocate_remarks)
            let formattedAllocationsStatus = res.data.data.map((item:any) => {
                const { allocationstatusid, allocationstatusname } = item;
                return {
                    name: allocationstatusname,
                    value: allocationstatusid,
                };
                });
                setSelectedAllocationsId(formattedAllocationsStatus[0].value)            
            }).catch((error) =>  
            { 
            Failed(error.response.message);
            });
    }
    

    // To Allocations approval

    // Define a type for errors
    type Errors = {
    AllocationsSts?:boolean;
    apprveRemarks?:boolean;
    };

    const AllocationsApprovals =(event:any)=>{

        event.preventDefault()
        let errors: Errors = {};

        if (selectedAllocationsId === '' || selectedAllocationsId === null || selectedAllocationsId === undefined) {
            errors.AllocationsSts = true;
            setAllocationStatusErrorMessage("Allocations Status is required")
            setAllocationStatusError(true)
        }
        
        const trimmedAllocationsRemarksValue = AllocationsRemarksValue.trim()
        if( trimmedAllocationsRemarksValue.length >255 ){
            errors.apprveRemarks = true
            setAllocationsRemarksError(true);
            setAllocationsRemarksErrorMessage("Max 255 characters");
        }
        else {
            setAllocationsRemarksError(false);
            setAllocationsRemarksErrorMessage("");
        }

        let AllocationsShortName;

        if(selectedAllocationsId === 2){
            AllocationsShortName= 'A'
        }

        if(selectedAllocationsId === 3){
            AllocationsShortName= 'R'
        }

        if(selectedAllocationsId === 4){
            AllocationsShortName= 'H'
        }

        if (Object.keys(errors).length > 0) {
            // If there are errors, update the state with all the errors
            setAllocationStatusError(errors.AllocationsSts || false);
            setAllocationsRemarksError(errors.apprveRemarks || false);
            return;
        }

        commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/allocation/allocation_asset_request_update`, 
            {
            SaveUpdateKey: "update",
            CompanyId: CompanyId,
            RequestId: RequestId,
            RequestAllocationStatus: AllocationsShortName, 
            RequestAllocationByEmployeeId: UserId, 
            RequestAllocationRemarks: AllocationsRemarksValue,
            }
        )
        .then((res) => {
          if (res.status === 200) {
              Success("Allocation Status Updated Successfully");
              handleClose(event);
              handlegetMyPendingAllocationssList(event);
              handlegetMyApprovedAllocationsList(event);
          }
          }).catch((error) => {
              console.error("Error:", error);
          });
    }
 

    useEffect(() => {
        GetAllocationsList();
        GetAllocationsdApprovalListById();
    }, []); 


    return(
        <Box>
            <Box bgcolor={'white'} borderRadius={3}
            sx={{
                width:{
                    lg:600,
                    md:600,
                    xs:350
            }}} >
                <Box>
                <Box p={3}>
                        <Typography variant='h1'
                        sx={{
                            fontSize:{
                                lg:'2rem',
                                md:'2rem',
                                xs:'1.5rem'
                            }
                        }}
                        >
                        Edit Allocations
                        </Typography>
                    </Box>
                    
                    <form noValidate>
                        <Box p={3}>
                            <Grid container >
                                <Grid item lg={12} md={12} xs={12} >
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={AllocationsOptions as OptionType[]}
                                        filterOptions={filterOptions}
                                        getOptionLabel={(option: unknown) => (option as OptionType).name}
                                        value={AllocationsOptions.find((option: OptionType) => option.value === selectedAllocationsId) || null}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setSelectedAllocationsId(newValue.value);
                                                setAllocationStatusError(false);
                                                setAllocationStatusErrorMessage("");
                                            } else {
                                                setSelectedAllocationsId('');
                                                setAllocationStatusError(true);
                                                setAllocationStatusErrorMessage("Allocations is required");
                                            }
                                        }}
                                        // sx={{ width: 235, ml: 1 }}
                                        renderInput={(params) => 
                                        <TextField {...params}
                                        error={AllocationStatusError}
                                        autoFocus
                                        helperText={AllocationStatusErrorMessage}
                                            required label="Allocations Status" 
                                        variant="standard" />}
                                    />
                                </Grid>

                                <Grid item mt={3} lg={12} md={12} xs={12} >
                                            <TextField 
                                            id="outlined-basic" 
                                            label="Allocations Remarks" 
                                            variant="outlined" 
                                            name='AllocationsRemarks'
                                            fullWidth
                                            multiline
                                            rows={4}
                                            error={AllocationsRemarksError}
                                            onChange={handleAllocationsRemarksChange}
                                            value={AllocationsRemarksValue}
                                            helperText={AllocationsRemarksErrorMessage}                                           
                                            />
                                        </Grid>
                            </Grid>
                        </Box>

                    <Box mt={5} ml={2} mr={2} mb={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Button
                                    sx={{
                                        background: 'white',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        // color: {accent},
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}

                                    onClick={handleClose} 
                                >
                                    Back
                                </Button>

                                <Button
                                    sx={{
                                        background: '#25425F',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        "&:hover":{
                                            backgroundColor:'#25425F',
                                            color:'white',
                                        },
                                        color: 'white',
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}
                                    type='submit'
                                    onClick={(event)=>{
                                        AllocationsApprovals(event);
                                    }}
                                >
                                    Update
                                </Button>
                                </Box>
                    </form>


                </Box>
            </Box>
        
        </Box>
    )
}
export default EditAllocationsPage;

// End of SOP-30