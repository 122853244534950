// Included by sanjana SOP-40
// Imports
import Papa, { ParseResult } from 'papaparse';
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import deleteAlert from '../../component/HOC/deleteAlert';
import Swal from 'sweetalert2';
import { FileUploader } from "react-drag-drop-files";
import './ImportAssetCSV.scss'
import { FaCircleDot } from "react-icons/fa6";
import { FaCircle } from "react-icons/fa6";

// to get CSV data in string format
interface CSVRow {
    [key: string]: string;
  }
  
// To set drop down name & value as string and number format
interface OptionType {
name: string;
value: number;
}

// to get Error data in string format
interface ErrorData {
  [key: string]: string;
}

// To set file type as csv
const fileTypes = ["csv"];

const ImportAssetCSVPage =()=>{

    //useState 
    const [CSVdata, setCSVData] = useState<CSVRow[]>([]);
    const [Importopen, setImportOpen] = React.useState(false);
    const [ImportCategoryopen, setImportCategoryOpen] = React.useState(false);
    const [ImportSubCategoryopen, setImportSubCategoryOpen] = React.useState(false);
    const [ImportAssetopen, setImportAssetOpen] = React.useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [FileName, setFileName]= useState('');
    const [CategoryOptions, setCategoryOptions] = useState<OptionType[]>([]);
    const [renderedCount, setrenderedCount] = useState(Number)
    const [notRenderedCount, setnotRenderedCount] = useState(Number)
    const [Failedopen, setFailedOpen] = React.useState(false);
    const [FailedMessage, setFailedMessage] = useState<ErrorData[]>([]);
  
    // To open Import dialouge box
    const handleImportClickOpen = () => {
      //console.log("CSVdata",CSVdata)
      if(CSVdata.length === 0){
        Swal.fire({
          html: '<span style="font-size: 1.3rem;">Please Select File</span>',
          confirmButtonText: 'OK',
          allowOutsideClick:false
              });
      }
      else{
        setImportOpen(true);
      }
    };

    
  
    const handleImportClose = () => {
      setImportOpen(false);
    };

    const handleImportCategoryOpen = (event:any) => {
      setImportCategoryOpen(true);
    };

    const handleImportSubCategoryOpen = (event:any) => {
      setImportSubCategoryOpen(true);
    };

    const handleImportAssetOpen = (event:any) => {
      setImportAssetOpen(true);
    };

    const handleFailedOpen = () => {
      setFailedOpen(true);
    };

    const handleImportCategoryClose = () => {
      setImportCategoryOpen(false);
    };

    const handleImportSubCategoryClose = () => {
      setImportSubCategoryOpen(false);
    };

    const handleImportAssetClose = () => {
      setImportAssetOpen(false);
    };

    const handleFailedClose = () => {
      setFailedOpen(false);
    };
  
    //  Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    ////console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId
    let CreatedBy = GlobalData.GlobalUserId
  
    // To get data's from CSV file
    const handleFileUpload = (file: File) => {
      //console.log("Uploaded file:", file);
      setFileName(file.name)
    
      // Check file type
      if (!file.name.endsWith('.csv')) {
        Swal.fire({
          html: '<span style="font-size: 1.3rem;">Invalid file type. Please upload a CSV file.</span>',
          confirmButtonText: 'OK',
          });
        return;
      }
      
     
      Papa.parse(file, {
        complete: (result: ParseResult<any>) => {
          //console.log("Parsed data:", result.data);
          if (result.data && result.data.length > 0) {
            const csvData: any[] = result.data;
            if (csvData.every(row => Array.isArray(row))) {
              // All rows are arrays, indicating data rows, not headers
              const headers = csvData[0];
              //console.log("Headers:", headers);
              const parsedData = csvData.slice(1).map((row: any) => {
                const rowData: CSVRow = {};
                headers.forEach((header: string, index: number) => {
                  rowData[header] = row[index];
                });
                return rowData;
              });
              setCSVData(parsedData);
              //console.log("parsedData",parsedData)
            } else {
              // Assuming first row contains headers
              const headers = csvData[0];
              //console.log("Headers:", headers);
              const parsedData = csvData.slice(1).map((row: any) => {
                const rowData: CSVRow = {};
                headers.forEach((header: string, index: number) => {
                  rowData[header] = row[header];
                });
                return rowData;
              });
              setCSVData(parsedData);
            }
          } else {
            //console.error('Empty CSV file or unable to parse.');
          }
        },
        header: false // We will manually handle headers
      });
    };
    

    // to delete existing transaction data 
    const HandleImportCategoriesDelete =(event:any)=>{
      commonService
    .postService(`${process.env.REACT_APP_BACKEND_URL}/import/import_asset_delete`, 
      {
        CompanyId: CompanyId,
      }
    )
    .then((res) => {
      if (res.status === 200) {
        //console.log("res.status 1",res.status)
        //console.log("CompanyId 1",CompanyId)
        commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/import/import_sub_categories_delete`, 
        {
            CompanyId: CompanyId,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/import/import_categories_delete`, 
            {
                CompanyId: CompanyId,
              }
            )
            GetCatgoryList();
          }
          }).catch((error) => {
              //console.error("Error:", error);
          });
      }
      }).catch((error) => {
          //console.error("Error:", error);
      });
  }
  
  
  // To get category list 
  const GetCatgoryList = async () => {
  
    const CategoryPayload ={
        CompanyId:CompanyId
    }
  
    ////console.log("CategoryPayload",CategoryPayload)
  
  try {
    const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/getCategoryList`,CategoryPayload);
    ////console.log("Country" ,res.data.data);
    let formattedCountry = res.data.data.map((item:any) => {
    const { categoryid, categoryname } = item;
    return {
        name: categoryname,
        value: categoryid,
    };
    });
    setCategoryOptions(formattedCountry)
    //console.log('cate',CategoryOptions)
  } catch (err) {
    ////console.log( "co",err);
  }
  };


    // to save category from CSV Data
    const HandleImportCategoryCSV = (event: any) => {
      // Extracting Category values from CSVdata
      const categories = CSVdata.map((row) => row.Category);
      //console.log("categories", categories);
      //console.log("CSVdata", CSVdata);
      let APIValidation = true;
      let SameCategories: string[] = []; // Array to store unique categories
    
      // Check if 'Category' column exists in CSV data
      if (!CSVdata.some(data => 'Category' in data)) {
      Swal.fire({
        html: '<span style="font-size: 1.3rem;"> Category column does not exist .</span>',
        confirmButtonText: 'OK',
        });
        APIValidation = false
        handleImportCategoryClose();
        handleImportSubCategoryClose();
        handleImportAssetClose();
        setCSVData([]);
        setFileName('');
    }

          // Check if 'Asset Name' column exists in CSV data
          if (!CSVdata.some(data => 'Asset Name' in data)) {
            Swal.fire({
              html: '<span style="font-size: 1.3rem;"> Asset Name column does not exist .</span>',
              confirmButtonText: 'OK',
              });
            APIValidation= false
            handleImportCategoryClose();
            handleImportSubCategoryClose();
            handleImportAssetClose();
            setCSVData([]);
            setFileName('');
          }
          // Check if 'Model Number' column exists in CSV data
          if (!CSVdata.some(data => 'Model Number' in data)) {
            Swal.fire({
              html: '<span style="font-size: 1.3rem;"> Model Number column does not exist .</span>',
              confirmButtonText: 'OK',
              });
            APIValidation= false
            handleImportCategoryClose();
            handleImportSubCategoryClose();
            handleImportAssetClose();
            setCSVData([]);
            setFileName('');
          }
    
          // Check if 'Serial Number' column exists in CSV data
          if (!CSVdata.some(data => 'Serial Number' in data)) {
            Swal.fire({
              html: '<span style="font-size: 1.3rem;"> Serial Number column does not exist .</span>',
              confirmButtonText: 'OK',
              });
            APIValidation= false
            handleImportCategoryClose();
            handleImportSubCategoryClose();
            handleImportAssetClose();
            setCSVData([]);
            setFileName('');
          }
    
          // Check if 'Service Tag Number' column exists in CSV data
          if (!CSVdata.some(data => 'Service Tag Number' in data)) {
            Swal.fire({
              html: '<span style="font-size: 1.3rem;"> Service Tag Number column does not exist .</span>',
              confirmButtonText: 'OK',
              });
            APIValidation= false
            handleImportCategoryClose();
            handleImportSubCategoryClose();
            handleImportAssetClose();
            setCSVData([]);
            setFileName('');
          }
    
          // Check if 'Express Service Code' column exists in CSV data
          if (!CSVdata.some(data => 'Express Service Code' in data)) {
            Swal.fire({
              html: '<span style="font-size: 1.3rem;"> Express Service Code column does not exist .</span>',
              confirmButtonText: 'OK',
              });
            APIValidation= false
            handleImportCategoryClose();
            handleImportSubCategoryClose();
            handleImportAssetClose();
            setCSVData([]);
            setFileName('');
          }
    
          // Check if 'Manufacturer' column exists in CSV data
          if (!CSVdata.some(data => 'Manufacturer' in data)) {
            Swal.fire({
              html: '<span style="font-size: 1.3rem;"> Manufacturer column does not exist .</span>',
              confirmButtonText: 'OK',
              });
            APIValidation= false
            handleImportCategoryClose();
            handleImportSubCategoryClose();
            handleImportAssetClose();
            setCSVData([]);
            setFileName('');
          }
    
          // Check if 'Invoice Number' column exists in CSV data
          if (!CSVdata.some(data => 'Invoice Number' in data)) {
            Swal.fire({
              html: '<span style="font-size: 1.3rem;"> Invoice Number column does not exist .</span>',
              confirmButtonText: 'OK',
              });
            APIValidation= false
            handleImportCategoryClose();
            handleImportSubCategoryClose();
            handleImportAssetClose();
            setCSVData([]);
            setFileName('');
          }
    
          // Check if 'Invoice Date' column exists in CSV data
          if (!CSVdata.some(data => 'Invoice Date' in data)) {
            Swal.fire({
              html: '<span style="font-size: 1.3rem;"> Invoice Date column does not exist .</span>',
              confirmButtonText: 'OK',
              });
            APIValidation= false
            handleImportCategoryClose();
            handleImportSubCategoryClose();
            handleImportAssetClose();
            setCSVData([]);
            setFileName('');
          }
    
          // Check if 'Price' column exists in CSV data
          if (!CSVdata.some(data => 'Price' in data)) {
            Swal.fire({
              html: '<span style="font-size: 1.3rem;"> Price column does not exist .</span>',
              confirmButtonText: 'OK',
              });
            APIValidation= false
            handleImportCategoryClose();
            handleImportSubCategoryClose();
            handleImportAssetClose();
            setCSVData([]);
            setFileName('');
          }
    
          // Check if 'Ip Address' column exists in CSV data
          if (!CSVdata.some(data => 'Ip Address' in data)) {
            Swal.fire({
              html: '<span style="font-size: 1.3rem;"> Ip Address column does not exist .</span>',
              confirmButtonText: 'OK',
              });
            APIValidation= false
            handleImportCategoryClose();
            handleImportSubCategoryClose();
            handleImportAssetClose();
            setCSVData([]);
            setFileName('');
          }
    
          // Check if 'Operating System' column exists in CSV data
          if (!CSVdata.some(data => 'Operating System' in data)) {
            Swal.fire({
              html: '<span style="font-size: 1.3rem;"> Operating System column does not exist .</span>',
              confirmButtonText: 'OK',
              });
            APIValidation= false
            handleImportCategoryClose();
            handleImportSubCategoryClose();
            handleImportAssetClose();
            setCSVData([]);
            setFileName('');
          }
    
          // Check if 'Processor Type' column exists in CSV data
          if (!CSVdata.some(data => 'Processor Type' in data)) {
            Swal.fire({
              html: '<span style="font-size: 1.3rem;"> Processor Type column does not exist .</span>',
              confirmButtonText: 'OK',
              });
            APIValidation= false
            handleImportCategoryClose();
            handleImportSubCategoryClose();
            handleImportAssetClose();
            setCSVData([]);
            setFileName('');
          }
    
          // Check if 'Memory In GB' column exists in CSV data
          if (!CSVdata.some(data => 'Memory In GB' in data)) {
            Swal.fire({
              html: '<span style="font-size: 1.3rem;"> Memory In GB column does not exist .</span>',
              confirmButtonText: 'OK',
              });
            APIValidation= false
            handleImportCategoryClose();
            handleImportSubCategoryClose();
            handleImportAssetClose();
            setCSVData([]);
            setFileName('');
          }
    
          // Check if 'Notes' column exists in CSV data
          if (!CSVdata.some(data => 'Notes' in data)) {
            Swal.fire({
              html: '<span style="font-size: 1.3rem;"> Notes column does not exist .</span>',
              confirmButtonText: 'OK',
              });
            APIValidation= false
            handleImportCategoryClose();
            handleImportSubCategoryClose();
            handleImportAssetClose();
            setCSVData([]);
            setFileName('');
          }
    
          // Check if 'Location' column exists in CSV data
          if (!CSVdata.some(data => 'Location' in data)) {
            Swal.fire({
              html: '<span style="font-size: 1.3rem;"> Location column does not exist .</span>',
              confirmButtonText: 'OK',
              });
            APIValidation= false
            handleImportCategoryClose();
            handleImportSubCategoryClose();
            handleImportAssetClose();
            setCSVData([]);
            setFileName('');
          }
    
          // Check if 'Warranty ( Y/N)' column exists in CSV data
          if (!CSVdata.some(data => 'Warranty ( Y/N)' in data)) {
            Swal.fire({
              html: '<span style="font-size: 1.3rem;"> Serial column does not exist .</span>',
              confirmButtonText: 'OK',
              });
            APIValidation= false
            handleImportCategoryClose();
            handleImportSubCategoryClose();
            handleImportAssetClose();
            setCSVData([]);
            setFileName('');
          }
    
          // Check if 'Warranty Start Date' column exists in CSV data
          if (!CSVdata.some(data => 'Warranty Start Date' in data)) {
            Swal.fire({
              html: '<span style="font-size: 1.3rem;"> Warranty Start Date column does not exist .</span>',
              confirmButtonText: 'OK',
              });
            APIValidation= false
            handleImportCategoryClose();
            handleImportSubCategoryClose();
            handleImportAssetClose();
            setCSVData([]);
            setFileName('');
          }
    
          // Check if 'Warranty End Date' column exists in CSV data
          if (!CSVdata.some(data => 'Warranty End Date' in data)) {
            Swal.fire({
              html: '<span style="font-size: 1.3rem;"> Warranty End Date column does not exist .</span>',
              confirmButtonText: 'OK',
              });
            APIValidation= false
            handleImportCategoryClose();
            handleImportSubCategoryClose();
            handleImportAssetClose();
            setCSVData([]);
            setFileName('');
          }
    
          // Check if 'AMC ( Y/N)' column exists in CSV data
          if (!CSVdata.some(data => 'AMC ( Y/N)' in data)) {
            Swal.fire({
              html: '<span style="font-size: 1.3rem;"> AMC ( Y/N) column does not exist .</span>',
              confirmButtonText: 'OK',
              });
            APIValidation= false
            handleImportCategoryClose();
            handleImportSubCategoryClose();
            handleImportAssetClose();
            setCSVData([]);
            setFileName('');
          }
    
          // Check if 'AMC Start Date' column exists in CSV data
          if (!CSVdata.some(data => 'AMC Start Date' in data)) {
            Swal.fire({
              html: '<span style="font-size: 1.3rem;"> AMC Start Date column does not exist .</span>',
              confirmButtonText: 'OK',
              });
            APIValidation= false
            handleImportCategoryClose();
            handleImportSubCategoryClose();
            handleImportAssetClose();
            setCSVData([]);
            setFileName('');
          }
    
          // Check if 'AMC End Date' column exists in CSV data
          if (!CSVdata.some(data => 'AMC End Date' in data)) {
            Swal.fire({
              html: '<span style="font-size: 1.3rem;"> AMC End Date column does not exist .</span>',
              confirmButtonText: 'OK',
              });
            APIValidation= false
            handleImportCategoryClose();
            handleImportSubCategoryClose();
            handleImportAssetClose();
            setCSVData([]);
            setFileName('');
          }
          // Check if 'Purchase Date' column exists in CSV data
          if (!CSVdata.some(data => 'Purchase Date' in data)) {
            Swal.fire({
              html: '<span style="font-size: 1.3rem;"> Purchase Date column does not exist .</span>',
              confirmButtonText: 'OK',
              });
            APIValidation= false
            handleImportCategoryClose();
            handleImportSubCategoryClose();
            handleImportAssetClose();
            setCSVData([]);
            setFileName('');
          }
          // Check if 'Ship Date' column exists in CSV data
          if (!CSVdata.some(data => 'Ship Date' in data)) {
            Swal.fire({
              html: '<span style="font-size: 1.3rem;"> Ship Date column does not exist .</span>',
              confirmButtonText: 'OK',
              });
            APIValidation= false
            handleImportCategoryClose();
            handleImportSubCategoryClose();
            handleImportAssetClose();
            setCSVData([]);
            setFileName('');
          }

if(APIValidation){
      // Check if categories array is not empty
      if (categories.length > 0) {
        // Iterate through categories array
        categories.forEach((category) => {
          // Skip categories with empty value
          if (category.trim() !== "") {
            // Check if the category is unique
            if (!SameCategories.includes(category)) {
              // Add the category to the list of unique categories
              SameCategories.push(category);
    
              // Create payload for each non-empty category
              const AddCategoryPayload = {
                SaveUpdateKey: "create",
                CategoryName: String(category), // Convert category to string
                CategoryId: null,
                CompanyId: CompanyId,
                Active: "Y",
                CreatedDateTime: null,
                CreatedBy: CreatedBy,
                IsRemove: null,
              };
    
              // Send API request for each category
                commonService
                .postService(
                  `${process.env.REACT_APP_BACKEND_URL}/master/categories_create_update_delete`,
                  AddCategoryPayload
                )
                .then((res) => {
                  if (res.status === 200) {
                  GetCatgoryList();
                  }
                })
                .catch((error) => {
                  //console.error("Error:", error);
                });
              

            }
          }
        });
      }
}
    
};
    

    // to save sub category from CSV Data
    const HandleImportSubCategoryCSV = (event: any) => {
      GetCatgoryList();
      let SameSubCategory: Set<string> = new Set();
    
      CSVdata.forEach((Data) => {
        const category = Data['Category'];
        const subCategory = Data['Sub-Category'];
        //console.log("subCategory",subCategory)


          if (category && category.trim() !== '' && subCategory && subCategory.trim() !== '') {
            const subCategoryDate = category.trim() + '-' + subCategory.trim();
            //console.log("subCategoryDate",subCategoryDate)
            if (!SameSubCategory.has(subCategoryDate)) {
              SameSubCategory.add(subCategoryDate);
              const matchingCategory = CategoryOptions.find((opt: any) => opt.name === category.trim());
              if (matchingCategory) {
                const categoryId = matchingCategory.value;
                // GetSubCatgoryList(categoryId)    
                const AddSubCategoryPayload = {
                  SaveUpdateKey: "create",
                  SubCategoryId: null,
                  SubCategoryName: subCategory.trim(),
                  CategoryId: categoryId,
                  CompanyId: CompanyId,
                  Active: "Y",
                  CreatedBy: CreatedBy,
                  IsRemove: null
                };
      
                //console.log("AddSubCategoryPayload", AddSubCategoryPayload);
  
                  commonService
                  .postService(`${process.env.REACT_APP_BACKEND_URL}/master/subcategories_create_update_delete`, AddSubCategoryPayload)
                  .then((res) => {
                    if (res.status === 200) {
                    }
                  })
                  .catch((error) => {
                    //console.error("Error:", error);
                  });
              } else {
                //console.log(`Category '${category.trim()}' not found in Category List. Skipping...`);
              }
            } else {
              //console.log(`Combination of CategoryId '${category.trim()}' and SubCategoryName '${subCategory.trim()}' already exists. Skipping...`);
            }
          }
        

      });
    };

    // to save Asste from CSV Data
    const HandleImportAssetCSV = async (event: any) => {
      GetCatgoryList();
      let successMessageDisplayed = false;
      let renderedCount = 0; // Counter for correctly rendered items
      let notRenderedCount = 0; // Counter for not rendered items
      let errorMessages = [];
    
      CSVdata.forEach((Data) => {
                // Check if any required field is empty
                const requiredFields = ['Category', 'Sub-Category', 'Asset Name', 'Model Number', 'Serial Number'];
                const isEmpty = requiredFields.some(field => !Data[field]);
                if (isEmpty) {
                  return; // Skip processing this row
                }

                // Format dates
                const formatDate = (dateString: string) => {
                  if (!dateString) return ''; // Defensive check
                  
                  const [month, day, year] = dateString.split('/');
                  if (!month || !day || !year) return '';                   
                  const formattedMonth = month.length === 1 ? `0${month}` : month;
                  const formattedDay = day.length === 1 ? `0${day}` : day;
                  return `${year}-${formattedMonth}-${formattedDay}`;
                };
            
                const category = Data['Category'];
                const subCategory = Data['Sub-Category'];
                const AssetName = Data['Asset Name'];
                const ModelNumber = Data['Model Number'];
                const SerialNo = Data['Serial Number'];
                const ServiceTagNo = Data['Service Tag Number'];
                const PurchaseDate = formatDate(Data['Purchase Date']);
                const ShipDate = formatDate(Data['Ship Date']);
                const ExpressServiceCode = Data['Express Service Code'];
                const Manufracture = Data['Manufacturer'];
                const InvoiceNo = Data['Invoice Number'];
                const InvoiceDate = formatDate(Data['Invoice Date']);
                const Price = Data['Price'] || 0.00;
                const IpAddress = Data['Ip Address'];
                const OperatingSystem = Data['Operating System'];
                const ProcessorType = Data['Processor Type'];
                const MemoryInGB = Data['Memory In GB'];
                const Notes = Data['Notes'];
                const Location = Data['Location'];
                const IsUnderWarranty = Data['Warranty ( Y/N)'];
                const WarrantyStartDate = formatDate(Data['Warranty Start Date']);
                const WarrantyEndDate = formatDate(Data['Warranty End Date']);
                const IsUnderAMC = Data['AMC ( Y/N)'];
                const AMCStartDate = formatDate(Data['AMC Start Date']);
                const AMCEndDate = formatDate(Data['AMC End Date']);

                const CreateAssetPayload = {
                  CompanyId: CompanyId || null,
                  AsetName: AssetName || null,
                  ModelNumber: ModelNumber || null,
                  SerialNo: SerialNo || null,
                  ServiceTagNo: ServiceTagNo || null,
                  ExpressServiceCode: ExpressServiceCode || null,
                  Manufracture: Manufracture || null,
                  InvoiceNo: InvoiceNo || null,
                  Price: Price || 0.00 ,
                  IpAddress: IpAddress || null,
                  OperatingSystem: OperatingSystem || null,
                  ProcessorType: ProcessorType || null,
                  MemoryInGB: MemoryInGB || null,
                  Notes: Notes || null,
                  Location: Location || null,
                  IsUnderWarranty: IsUnderWarranty || 'N',
                  IsUnderAMC: IsUnderAMC || 'N',
                  CreatedBy: CreatedBy || null,
                  CategoryName: category || null,
                  SubCategoryName: subCategory || null,
                  PurchaseDate: PurchaseDate || null,
                  ShipDate: ShipDate || null,
                  InvoiceDate: InvoiceDate || null,
                  WarrantyStartDate: WarrantyStartDate || null,
                  WarrantyEndDate: WarrantyEndDate || null,
                  AMCStartDate: AMCStartDate || null,
                  AMCEndDate: AMCEndDate|| null
                };
            
                //console.log("CreateAssetPayload", CreateAssetPayload);
                //console.log("PurchaseDate", PurchaseDate);
            
                  commonService
                    .postService(`${process.env.REACT_APP_BACKEND_URL}/import/import_asset`, CreateAssetPayload)
                    .then((res) => {
                      if (res.status === 200) {
                        renderedCount++; 
                        if (!successMessageDisplayed) { 
                          Success("Data Imported successfully");
                          successMessageDisplayed = true;
                        }
                        setCSVData([]);
                        setFileName('');
                        //console.log("Rendered Count:", renderedCount);
                        setrenderedCount(renderedCount)
                      }
                    })
                    .catch((error) => {
                      //console.error("Error:", error);
                      //console.error("Error:", error.response.data.message);
                      const errorMessage = error.response.data.message;
                      setFailedMessage(prevFailedMessages => [...prevFailedMessages, errorMessage]); // Add new error message to the existing array
                      notRenderedCount++; // Increment not rendered count
                      //console.log("Not Rendered Count:", notRenderedCount);
                      setnotRenderedCount(notRenderedCount)
                      handleFailedOpen();
                    });
                  
                
      
      });      
    };
    

      useEffect(() => {
        GetCatgoryList();
      }, []);
  
  
  
    return (
        <>
        <MainContainerWrapper headingText='Import Asset'>
            <Box>
                <Box mt={5} display={'flex'} justifyContent={'space-evenly'}>
                    <Box>
                    <Box>
                    <FileUploader
                    handleChange={handleFileUpload}
                    name="file"
                    types={fileTypes}
                    ref={fileInputRef}
                    />
                    </Box>
                    <Box mt={1}>
                    <Typography>
                    {FileName ? `File name: ${FileName}` : "no files uploaded yet"}
                    </Typography>
                    </Box>
                    </Box>
            
                    <Box>
                        <Button
                            sx={{
                            background: '#25425F',
                            border: 'none',
                            borderRadius: '5px',
                            padding:{
                                lg: "0 70px",
                                md: "0 70",
                                xs:'0 50px'
                            },
                            "&:hover":{
                                backgroundColor:'#25425F',
                                color:'white',
                            },
                            color: 'white',
                            height: "3rem",
                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                        }}
            
                            onClick={()=>{
                            handleImportClickOpen();
                            }} 
                        >
                            Import
                        </Button>
                        </Box>
            
                        <Box>
                            <Dialog open={Importopen}>
                            <Box>
                                <Box p={5}>
                                <Typography fontSize={'1.4rem'}>
                                    It will clear the existing assets if any,
                                </Typography>
                                <Typography fontSize={'1.4rem'} display={'flex'} justifyContent={'center'}>
                                    Do you want to continue?
                                </Typography>
            
                                <Box>                    
                                <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Button
                                        sx={{
                                            background: 'white',
                                            border: 'none',
                                            borderRadius: '5px',
                                            padding:{
                                                lg: "0 70px",
                                                md: "0 70",
                                                xs:'0 50px'
                    
                                            },
                                            height: "3rem",
                                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                        }}
            
                                        onClick={()=>{   
                                            handleImportClose();                             
                                        }} 
                                    >
                                        No
                                    </Button>
            
                                    <Button
                                        sx={{
                                            background: '#25425F',
                                            border: 'none',
                                            borderRadius: '5px',
                                            padding:{
                                                lg: "0 70px",
                                                md: "0 70",
                                                xs:'0 50px'
                                            },
                                            "&:hover":{
                                                backgroundColor:'#25425F',
                                                color:'white',
                                            },
                                            color: 'white',
                                            height: "3rem",
                                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                        }}
                                        type='submit'
                                        onClick={(event)=>{
                                            HandleImportCategoriesDelete(event);
                                            handleImportCategoryOpen(event);
                                            handleImportClose();
                                        }}
                                    >
                                        Yes
                                    </Button>
                                </Box>
                            </Box>
                                </Box>
                            </Box>
                            </Dialog>
                        </Box>

                        {/* Category */}
                        <Box>
                            <Dialog open={ImportCategoryopen}>
                            <Box width={500}>
                                <Box p={5}>
                                <Typography fontSize={'2rem'} display={'flex'} justifyContent={'center'} >
                                    Step 1 of 3
                                </Typography>
            
                                <Box>                    
                                  <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'center'}>           
                                      <Button
                                          sx={{
                                              background: '#25425F',
                                              border: 'none',
                                              borderRadius: '5px',
                                              padding:{
                                                  lg: "0 50px",
                                                  md: "0 50",
                                                  xs:'0 50px'
                                              },
                                              "&:hover":{
                                                  backgroundColor:'#25425F',
                                                  color:'white',
                                              },
                                              color: 'white',
                                              height: "3rem",
                                              boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                          }}
                                          type='submit'
                                          onClick={(event)=>{
                                            handleImportSubCategoryOpen(event);
                                            HandleImportCategoryCSV(event);
                                            handleImportCategoryClose();
                                          }}
                                      >
                                          Import Categories
                                      </Button>
                                  </Box>
                                 </Box>

                                <Box mt={5} display={'flex'} justifyContent={'center'}>
                                  <FaCircleDot style={{marginLeft:2, fontSize:'1.1rem',color:'#25425F'}}/>
                                  <FaCircle style={{marginLeft:10, fontSize:'1.1rem',color:'#25425F'}}/>
                                  <FaCircle style={{marginLeft:10, fontSize:'1.1rem',color:'#25425F'}}/>

                                </Box>

                                </Box>
                            </Box>
                            </Dialog>
                        </Box>

                        {/* Sub Category */}
                        <Box>
                            <Dialog open={ImportSubCategoryopen}>
                            <Box width={500}>
                                <Box p={5}>
                                <Typography fontSize={'2rem'} display={'flex'} justifyContent={'center'} >
                                Step 2 of 3
                                </Typography>
            
                                <Box>                    
                                  <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'center'}>           
                                      <Button
                                          sx={{
                                              background: '#25425F',
                                              border: 'none',
                                              borderRadius: '5px',
                                              padding:{
                                                  lg: "0 50px",
                                                  md: "0 50",
                                                  xs:'0 50px'
                                              },
                                              "&:hover":{
                                                  backgroundColor:'#25425F',
                                                  color:'white',
                                              },
                                              color: 'white',
                                              height: "3rem",
                                              boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                          }}
                                          type='submit'
                                          onClick={(event)=>{
                                            handleImportAssetOpen(event);
                                            HandleImportSubCategoryCSV(event);
                                            handleImportSubCategoryClose();
                                          }}
                                      >
                                          Import Subcategories
                                      </Button>
                                  </Box>
                                 </Box>

                                <Box mt={5} display={'flex'} justifyContent={'center'}>
                                  <FaCircle style={{marginLeft:2, fontSize:'1.1rem',color:'#25425F'}}/>
                                  <FaCircleDot style={{marginLeft:10, fontSize:'1.1rem',color:'#25425F'}}/>
                                  <FaCircle style={{marginLeft:10, fontSize:'1.1rem',color:'#25425F'}}/>

                                </Box>

                                </Box>
                            </Box>
                            </Dialog>
                        </Box>


                        {/* Asset */}
                        <Box>
                            <Dialog open={ImportAssetopen}>
                            <Box width={500}>
                                <Box p={5}>
                                <Typography fontSize={'2rem'} display={'flex'} justifyContent={'center'} >
                                Step 3 of 3
                                </Typography>
            
                                <Box>                    
                                  <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'center'}>           
                                      <Button
                                          sx={{
                                              background: '#25425F',
                                              border: 'none',
                                              borderRadius: '5px',
                                              padding:{
                                                  lg: "0 50px",
                                                  md: "0 50",
                                                  xs:'0 50px'
                                              },
                                              "&:hover":{
                                                  backgroundColor:'#25425F',
                                                  color:'white',
                                              },
                                              color: 'white',
                                              height: "3rem",
                                              boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                          }}
                                          type='submit'
                                          onClick={(event)=>{
                                              HandleImportAssetCSV(event);
                                              handleImportAssetClose();
                                          }}
                                      >
                                          Import Asstes
                                      </Button>
                                  </Box>
                                 </Box>

                                <Box mt={5} display={'flex'} justifyContent={'center'}>
                                  <FaCircle style={{marginLeft:2, fontSize:'1.1rem',color:'#25425F'}}/>
                                  <FaCircle style={{marginLeft:10, fontSize:'1.1rem',color:'#25425F'}}/>
                                  <FaCircleDot style={{marginLeft:10, fontSize:'1.1rem',color:'#25425F'}}/>

                                </Box>

                                </Box>
                            </Box>
                            </Dialog>
                        </Box>

                        {/* Failed Error */}
                        <Box>
                            <Dialog open={Failedopen}>
                            <Box width={500}>
                                <Box p={5}>
                                  <Box textAlign={'center'} display={'flex'} justifyContent={'center'} >
                                      <Typography fontSize={'1.5rem'} >
                                      {(notRenderedCount).toString()} data has been failed to import due to below reason
                                      </Typography>
                                  </Box>

                                <Box mt={3} display={'flex'} justifyContent={'center'} alignItems={'center'} >
                                  <Box>
                                      {FailedMessage.map((errorMessage, index) => (
                                      <Typography mt={1} key={index} textAlign={'center'} fontSize={'1rem'} color={'red'} >
                                        {errorMessage.toString()}
                                      </Typography>                                 
                                    ))}
                                  </Box>
                                </Box>

            
                                <Box>                    
                                  <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'center'}>           
                                      <Button
                                          sx={{
                                              background: '#25425F',
                                              border: 'none',
                                              borderRadius: '5px',
                                              padding:{
                                                  lg: "0 50px",
                                                  md: "0 50",
                                                  xs:'0 50px'
                                              },
                                              "&:hover":{
                                                  backgroundColor:'#25425F',
                                                  color:'white',
                                              },
                                              color: 'white',
                                              height: "3rem",
                                              boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                          }}
                                          type='submit'
                                          onClick={()=>{
                                              handleFailedClose();
                                          }}
                                      >
                                          Ok
                                      </Button>
                                  </Box>
                                 </Box>
                                </Box>
                            </Box>
                            </Dialog>
                        </Box>

                </Box>
            </Box>
        </MainContainerWrapper>
        </>

    );
  }

export default ImportAssetCSVPage;

// End of SOP-40