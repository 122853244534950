import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export const setAxiosDefauls = () => {
  axios.interceptors.request.use(onRequestFulfilled, onRequestRejected);

  axios.interceptors.response.use(onResponseFulfilled, onResponseRejected);
};

export const resetSession = () => {
  localStorage.remove("at-dl-auth");
};

const onRequestFulfilled = (config: any) => {
  let token = localStorage.getItem("avt-auth-token");
  if (token && token !== "undefined") {
    config.headers = {
      "x-api-key":process.env.REACT_APP_API_SECRET_KEY,
      Authorization: "Bearer " + token,
    };
  }
  return Promise.resolve(config);
};

const onRequestRejected = (error: any) => {
  return Promise.reject(error);
};

const onResponseFulfilled = (config: any) => {
  return Promise.resolve(config);
};

const onResponseRejected = (error: any) => {
  if (error?.response?.status === 401) {
    localStorage.clear();
    alert("Session Expired");
    window.location.href = "/";
  }

  return Promise.reject(error);
};
