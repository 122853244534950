// SOP-21 Included By PCS

// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Success } from '../../atic-common-helpers/helpers/toast.helper';

//SOP-21 -Included By PCS Testing Feedback Corrections [to avoid multiple submission]
import Swal from "sweetalert2";
// EOF SOP-21 -Included By PCS Testing Feedback Corrections

// Page start
const AddDesignationPage = 
// To get handleAddDesignationClose & getdesignationlistsearch from designation page
    ({
        handleAddDesignationClose,
        getdesignationlistsearch
    }: {
        handleAddDesignationClose: (event: React.MouseEvent) => void,
        getdesignationlistsearch: (event: React.MouseEvent) => void
    }) =>{

    // use navigate and use state declaration
    const navigate = useNavigate();

    const [DesignationError, setDesignationError]=useState(false)
    const [DesignationValue, setDesignationValue]=useState('')
    const [DesignationErrorMessage, setDesignationErrorMessage]=useState('')
    const [CompanyId, setCompanyId] =useState('')
    const [CreatedBy, setCreatedBy] =useState('')

    //SOP-21 Included By PCS[Testing feedback corrections- to avoid duplicate Designation]
    const [DesignationValueEndTrim, setDesignationValueEndTrim]=useState('')
    //EOF SOP-21 Included By PCS[Testing feedback corrections- to avoid duplicate Designation]
    
    //  Handle Designation while typing designation and validations
    const handleDesignationChange = (event:any) => {
        const trimmedDesignationValue = event.target.value.trim();
        setDesignationValue(event.target.value);

        //SOP-21 Included By PCS[Testing feedback corrections - to avoid duplicate Designation]
        setDesignationValueEndTrim(trimmedDesignationValue);
        //EOF SOP-21 Included By PCS[Testing feedback corrections]

        if (trimmedDesignationValue === '') {
            setDesignationError(true);
            setDesignationErrorMessage("Designation is required");
        } 
        else if( trimmedDesignationValue.length >225 ){
            setDesignationError(true);
            setDesignationErrorMessage("Max 255 characters");
        }
        else {
            setDesignationError(false);
            setDesignationErrorMessage("");
        }
    };

    // Exist Designation name API
    const GetDesignationNameExist = async () => {   
        
        const  ExistDesignationNamePayload = {

            //SOP-21 Modified By PCS[Testing feedback corrections-to avoid duplicate designation]
            //ExistDesignationName:DesignationValue,
            ExistDesignationName:DesignationValueEndTrim,
            //EOF SOP-21 Modified By PCS[Testing feedback corrections]

            DesignationId:null,
            CompanyId:CompanyId
        }
        try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/get_designation_name_exists`,ExistDesignationNamePayload);
        //console.log(res.data.data);
        return res.data.data || [];
        } catch (err) {
        console.log( "co",err); 
        }
    };

    // To render handle close function to close dialouge box
    const handleClose = (event: React.MouseEvent) => {
        if (handleAddDesignationClose && typeof handleAddDesignationClose === 'function') {
            handleAddDesignationClose(event);
        }
    };

    // to render getdesignationlistsearch function after closing dialouge box
    const handlegetdesignationlistsearch= (event: React.MouseEvent) => {
        if (getdesignationlistsearch && typeof getdesignationlistsearch === 'function') {
            getdesignationlistsearch(event);
        }
    };


    // To add designation

     // Define a type for errors
     type Errors = {
        designation?:boolean;
    };
    const HandleAddDesignation = async (event:any)=>{
        event.preventDefault();
    
        let errors: Errors = {};

        const DesignationNameList = await GetDesignationNameExist();
        if (DesignationNameList.length > 0) {
            setDesignationError(true);
            setDesignationErrorMessage("Designation is already exists");
            errors.designation = true;
            return;
        }
    
        const trimmedDesignationValue = DesignationValue.trim();
        if (trimmedDesignationValue === '') {
            errors.designation = true;
            setDesignationErrorMessage("Designation is required");
        } else if (trimmedDesignationValue.length > 225) {
            errors.designation = true;
            setDesignationErrorMessage("Max 255 characters");
        }
    
        if (Object.keys(errors).length > 0) {
            // If there are errors, update the state with all the errors
            setDesignationError(errors.designation || false);
            return;
        }
    
        const todayDate = new Date();
        const Year = todayDate.getFullYear();
        const Month = (todayDate.getMonth() + 1).toString().padStart(2, '0');
        const Day = todayDate.getDate().toString().padStart(2, '0');
        const FormattedDate = `${Year}-${Month}-${Day}`;
        //console.log("FormattedDate",FormattedDate)
    
        const AddDesignationPayload = {
            SaveUpdateKey: "create",
            DesignationName: DesignationValue,
            DesignationId: null,
            CompanyId: CompanyId,
            Active: "Y",
            CreatedDateTime: null,
            CreatedBy: CreatedBy,
            IsRemove: null
        }
    
        //console.log("AddPL", AddDesignationPayload);

    //SOP-21 Included By PCS[Testing feedack corrections- to avoid multiple toaster msg]

            Swal.fire({
                html: '<span style="font-size: 1.3rem;">Are you sure to save ? </span>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                cancelButtonColor: '#d33',
                confirmButtonColor:"#25425f",
                allowOutsideClick: false,
                })
            .then((result) =>
            {
            if (result.isConfirmed)
            {

    //EOF SOP-21 Included By PCS[Testing feedack corrections - to avoid multiple toaster msg]  
    
             commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/master/designation_create_update_delete`, AddDesignationPayload)
            .then((res) => {
                if (res.status === 200) {
                    Success("Designation created successfully");
                    handleClose(event);
                    handlegetdesignationlistsearch(event);
                }
                }).catch((error) => {
                    console.error("Error:", error);
                });

    //SOP-21 Included By PCS[Testing feedack corrections- to avoid multiple toaster msg]

            } 
            else  
            {
            return
            }
        });

    //EOF SOP-21 Included By PCS[Testing feedack corrections-to avoid multiple toaster msg]  
    
    }
    

    // UseEffect Declaration
    useEffect(() => {
        const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
        const GlobalData = JSON.parse(GlobalDataFromSession);
        //console.log("GlobalData",GlobalData)
        setCompanyId(GlobalData.GlobalCompanyId)
        setCreatedBy(GlobalData.GlobalUserId)
      }, []);

 //SOP-21 Testing feedback corrections[To restrict form submission only on button]

 const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

 //SOP-21 Testing feedback corrections[To restrict form submit only on button]

    return(
        <>
            <Box>
                <Box display={'flex'} justifyContent={'center'}>
                    <Box bgcolor={'white'} borderRadius={3}
                    sx={{
                        width:{
                            lg:900,
                            md:600,
                            xs:700
        
                        }}}
                    >
                    <Box p={3}>
                        <Typography variant='h1'>
                        Add Designation
                        </Typography>
                    </Box>
                        <Box p={3}>
                            <form  noValidate>
                                <Box>
                                <TextField
                                    onKeyDown={handleKeyDown} //SOP-21 [Testing feedback correction- to restrict submission only on button]
                                    type='text'
                                    //SOP-21 Inlcuded By PCS[Testing feedback Correction- to set auto focus]
                                    autoFocus
                                    //EOF SOP-21 Included By PCS [Testing feedback Correction- to set autofocus]
                                    variant="standard"
                                    label='Designation'
                                    name='Designation'
                                    style={{ margin: '0 10px' }}
                                    fullWidth
                                    required
                                    error={DesignationError}
                                    onChange={handleDesignationChange}
                                    value={DesignationValue}
                                    helperText={DesignationErrorMessage}
                                /> 
                                </Box>
                            <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Button
                                    sx={{
                                        background: 'white',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        // color: {accent},
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}

                                    onClick={handleClose} 
                                >
                                    Back
                                </Button>

                                <Button
                                    sx={{
                                        background: '#25425F',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        "&:hover":{
                                            backgroundColor:'#25425F',
                                            color:'white',
                                        },
                                        color: 'white',
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}
                                    type='submit'
                                    onClick={(event)=>{
                                        HandleAddDesignation(event);
                                    }}
                                >
                                    Save
                                </Button>
                                </Box>
                            </form>
                        </Box>

                    </Box>
                </Box>
            </Box>
        </>
    )

}

export default AddDesignationPage;

// EOF SOP-21