import { Box, Button, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { InputField } from '../../../atic-common-helpers/hoc/formfield/index';
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from '@mui/icons-material/Add';
import React from 'react';

const MainContainerWrapper = ({ children, ...props }: React.PropsWithChildren<MainContainerWrapperType>) => {

  const {
    handleClick,
    handleSecondaryClick,
    search,
    handleSearch,
    headingText,
    buttonText,
    secondaryButtonText,
    buttonIcon,
    isSearch = false,
    isCreateButton = false,
    isSecondaryButton=false,
    bgColor
  } = props;
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box component="main"
      sx={{
        flexGrow: 1,
        py: { xs: '1rem', md: '1rem' },
        px: 2,
      }}>
      <Stack
        direction={matchDownMD ? "column" : "row"}
        alignItems="center"
        sx={{
          justifyContent: { md: "space-between", xs: "center" },
          rowGap: { xs: "1.5rem" }
        }}
      >
        <Box>
          <Typography variant="h1">{headingText}</Typography>
        </Box>
        <Box>
          <Stack
            direction={matchDownMD ? "column-reverse" : "row"}
            spacing={matchDownMD ? 0 : 3}
            sx={{
              rowGap: { xs: "1.5rem" }
            }}
          >
            {isSecondaryButton &&
              <Button
                className="createBtn"
                variant="contained"
                onClick={handleSecondaryClick}
                disableRipple
                sx={{
                  height: "3.1rem",
                  padding: "0 1rem",
                  backgroundColor: theme.palette.primary?.buttonColor,
                  "&:hover": {
                    backgroundColor: theme.palette.primary?.buttonColor
                }
                }}
                startIcon={buttonIcon}
              >
               {secondaryButtonText}
              </Button>
            }
            {isCreateButton &&
              <Button
                className="createBtn"
                variant="contained"
                onClick={handleClick}
                disableRipple
                sx={{
                  height: "3.1rem",
                  padding: "0 1rem",
                  backgroundColor: theme.palette.primary?.buttonColor,
                  "&:hover": {
                    backgroundColor: theme.palette.primary?.buttonColor
                }
                }}
                startIcon={buttonIcon}
              >
               {buttonText}
              </Button>
            }
            {isSearch &&
              <InputField
              label='Search'
                fieldName=""
                handleChange={handleSearch}
                values={search}
                isRequired={false}
                adorament="startAdornment"
                Icon={SearchIcon}
                placeholder='Search'
              />
            }
          </Stack>
        </Box>
      </Stack>
      <Box
        mt={2}
        // mb={4}
        sx={{
          width: "100%",
          minHeight: "70vh",
          backgroundColor: "#E7F1FB",
        }}
      >
        {children}
      </Box>
    </Box>
  )
};

type MainContainerWrapperType = {
  handleClick?: (_: any) => void;
  handleSecondaryClick?: (_: any) => void;
  search?: string | null;
  handleSearch?: (_: any) => void;
  headingText: string;
  isSearch?: boolean;
  isCreateButton?: boolean;
  isSecondaryButton?:boolean
  buttonText?: string;
  secondaryButtonText?:string;
  buttonIcon? : any;
  bgColor?: string;
}

export default MainContainerWrapper;