import { useState,useRef,useEffect} from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Grid,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import MainContainerWrapper from '../../atic-common-helpers/layout/wrappers/MainContainerWrapper';
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { useNavigate } from "react-router-dom";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed } from '../../atic-common-helpers/helpers/toast.helper';
import OrganisationChart from '../../asset/images/Organisation Chart.webp'
import HR from '../../asset/images/HR employee.jpg'
import dayjs from 'dayjs';
import {useParams } from "react-router-dom";
const HRDashBoard =()=>{
    const {id} = useParams();
    const rowid = id;

    // useState
    // (useState declaration for grid)
    let  [HRHireRequestorder, setHRHireRequestOrder] = React.useState<"desc" | "asc">("desc");
    let  [HRHireRequestorderBy, setHRHireRequestOrderBy] = React.useState("");
    let  [HRHireRequesttableData, setHRHireRequestTableData] = React.useState([]);
    const [HRHireRequestpage, setHRHireRequestPage] = React.useState<number>(0);
    const [HRHireRequestcount, setHRHireRequestCount] = useState<number>(0);
    const [HRHireRequestrowsPerPage, setHRHireRequestsetRowsPerPage] = React.useState<number>(5);


    let  [HRTerminationRequestorder, setHRTerminationRequestOrder] = React.useState<"desc" | "asc">("desc");
    let  [HRTerminationRequestorderBy, setHRTerminationRequestOrderBy] = React.useState("");
    let  [HRTerminationRequesttableData, setHRTerminationRequestTableData] = React.useState([]);
    const [HRTerminationRequestpage, setHRTerminationRequestPage] = React.useState<number>(0);
    const [HRTerminationRequestcount, setHRTerminationRequestCount] = useState<number>(0);
    const [HRTerminationRequestrowsPerPage, setHRTerminationRequestsetRowsPerPage] = React.useState<number>(5);

    // (SOP-67) HR - Employee termination - Process - TS 
    let  [HRTerminationDoneRequestorder, setHRTerminationDoneRequestOrder] = React.useState<"desc" | "asc">("desc");
    let  [HRTerminationDoneRequestorderBy, setHRTerminationDoneRequestOrderBy] = React.useState("");
    let  [HRTerminationDoneRequesttableData, setHRTerminationDoneRequestTableData] = React.useState([]);
    const [HRTerminationDoneRequestpage, setHRTerminationDoneRequestPage] = React.useState<number>(0);
    const [HRTerminationDoneRequestcount, setHRTerminationDoneRequestCount] = useState<number>(0);
    const [HRTerminationDoneRequestrowsPerPage, setHRTerminationDoneRequestsetRowsPerPage] = React.useState<number>(5);
    // (SOP - 67) HR - Employee termination - Process
    
    // console.log("HRTerminationRequestpage",HRTerminationRequestpage)
    // console.log("HRTerminationRequestcount",HRTerminationRequestcount)
    // console.log("HRTerminationRequestrowsPerPage",HRTerminationRequestrowsPerPage)
    const navigate = useNavigate();

    // HRHireRequestHeadCell
    const HRHireRequestHeadCell = [
       
        {
            id: "hirerequest_hiring_jobcode_title",
            numeric: false,
            disablePadding: true,
            label: "Job Profile",
            isSort: true,
            isBold: true,
        },
        {
            id: "hirerequest_total_open_position",
            numeric: false,
            disablePadding: true,
            label: "Total Position",
            isSort: true,
            isBold: true,
        },
        {
            id: "departmentname",
            numeric: false,
            disablePadding: true,
            label: "Department",
            isSort: true,
            isBold: true,
        },
        {
            id: "employee_name",
            numeric: false,
            disablePadding: true,
            label: "Hiring Manager",
            isSort: true,
            isBold: true,
        },
        {
            id: "sys_hr_employment_type_name",
            numeric: false,
            disablePadding: true,
            label: "Type of Employment",
            isSort: true,
            isBold: true,
        },
        {
            id: "sys_hr_hirerequest_status_name",
            numeric: false,
            disablePadding: true,
            label: "Current Status",
            isSort: true,
            isBold: true,
        },
        // {
        //     id: "action",
        //     numeric: false,
        //     disablePadding: false,
        //     label: "Actions",
        //     isSort: false,
        //   },
        ];
    
    
    
        const HRTerminationRequestHeadCell = [
       
            {
                id: "department_name",
                numeric: false,
                disablePadding: true,
                label: "Department",
                isSort: true,
                isBold: true,
            },
            {
                id: "employee_name",
                numeric: false,
                disablePadding: true,
                label: "Employee Name",
                isSort: true,
                isBold: true,
            },
            {
                id: "date_of_relieving",
                numeric: false,
                disablePadding: true,
                label: "Date of Relieving",
                isSort: true,
                isBold: true,
                dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
            },
            {
                id: "reported_to_whom_name",
                numeric: false,
                disablePadding: true,
                label: "Reporting to Whom",
                isSort: true,
                isBold: true,
            },
            // {
            //     id: "action",
            //     numeric: false,
            //     disablePadding: false,
            //     label: "Actions",
            //     isSort: false,
            //   },
            ];
        
    
    // (SOP-67) HR - Employee termination - Process - TS 
    // HRTerminationsDoneRequest HeadCell
            const HRTerminationsDoneRequestHeadCell = [
       
                {
                    id: "department_name",
                    numeric: false,
                    disablePadding: true,
                    label: "Department",
                    isSort: true,
                    isBold: true,
                },
                {
                    id: "employee_name",
                    numeric: false,
                    disablePadding: true,
                    label: "Employee Name",
                    isSort: true,
                    isBold: true,
                },
                {
                    id: "date_of_relieving",
                    numeric: false,
                    disablePadding: true,
                    label: "Date of Relieving",
                    isSort: true,
                    isBold: true,
                    dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
                },
                {
                    id: "reported_to_whom_name",
                    numeric: false,
                    disablePadding: true,
                    label: "Reporting to Whom",
                    isSort: true,
                    isBold: true,
                },
                // {
                //     id: "",
                //     numeric: false,
                //     disablePadding: false,
                //     label: "Actions",
                //     isSort: false,
                //   },
                ];
                // (SOP-67) HR - Employee termination - Process - TS 
    
    
    //  Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    //console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId
    const UserId = GlobalData.GlobalUserId;
    let EmployeeId = GlobalData.GlobalEmployeeId
  
    
    // (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - start
    //  getHRHireRequestList API 
    const getHRHireRequestList = () => 
        {       
            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/get_hr_hire_requestlist`, {
                SortColumnName: HRHireRequestorderBy,
                SortColumnDirection: HRHireRequestorder,
                StartIndex: HRHireRequestpage + 1,
                PageSize: HRHireRequestrowsPerPage,
                CompanyId:CompanyId,
                CreatedBy:UserId,
            })
            .then((res: any) => 
            {
                console.log("HRHireReques",res)
            const formattedTableData= res.data.data.map((item:any)=>{
            return {...item,
                hirerequest_start_date: item.hirerequest_start_date ? dayjs(item.hirerequest_start_date).format("DD-MM-YYYY") : "",
            
            }
            }) 
           
            setHRHireRequestTableData(formattedTableData);
            if(res.data.data[0]===undefined)
            {
                setHRHireRequestCount(0);
            }
            else
            {
                setHRHireRequestCount(res.data.data[0].total_count);
            }
            })
        .catch((error) => 
            {
            if (error.response.status === 404)
            {
                setHRHireRequestTableData([]);
            }
            Failed(error.response.message);
            });
    };
  

       //  getHRHireTerminationList API 
       const getHRTerminationRequestList = () => 
        {       
            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/HREmployeeTermination/get_hr_termination_requestlist`, {
                SortColumnName: HRTerminationRequestorderBy,
                SortColumnDirection: HRTerminationRequestorder,
                StartIndex: HRTerminationRequestpage + 1,
                PageSize: HRTerminationRequestrowsPerPage,
                CompanyId:CompanyId,
                CreatedBy:UserId,
            })
            .then((res: any) => 
            {
                console.log("getHRTerminationRequestList",res)
            const formattedTableData= res.data.data.map((item:any)=>{
            return {...item,
                date_of_relieving: item.date_of_relieving ? dayjs(item.date_of_relieving).format("DD-MM-YYYY") : "",
                //  (SOP-67) HR - Employee termination - Process - TS 
                id: item.employee_id
            

            }
            }) 
           console.log("formattedTableData123",formattedTableData)
            setHRTerminationRequestTableData(formattedTableData);
            if(res.data.data[0]===undefined)
            {
                setHRTerminationRequestCount(0);
            }
            else
            {
                setHRTerminationRequestCount(res.data.data[0].total_count);
            }
            })
        .catch((error) => 
            {
            if (error.response.status === 404)
            {
                setHRTerminationRequestTableData([]);
            }
            Failed(error.response.message);
            });
    };



    // (SOP-67) HR - Employee termination - Process - TS 
       //  getHRHireTerminationList API 
       const getHRTerminationDoneRequestList = () => 
        {       
            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/HREmployeeTermination/get_hr_termination_done_requestlist`, {
                SortColumnName: HRTerminationDoneRequestorderBy,
                SortColumnDirection: HRTerminationDoneRequestorder,
                StartIndex: HRTerminationDoneRequestpage + 1,
                PageSize: HRTerminationDoneRequestrowsPerPage,
                CompanyId:CompanyId,
                CreatedBy:UserId,
            })
            .then((res: any) => 
            {
                console.log("getHRTerminationDoneRequestList",res)
            const formattedTableData= res.data.data.map((item:any)=>{
            return {...item,
                // date_of_relieving: item.date_of_relieving ? dayjs(item.date_of_relieving).format("DD-MM-YYYY") : "",
                //  (SOP-67) HR - Employee termination - Process - TS 
                // id: item.employee_id
            
                date_of_relieving: item.date_of_relieving ? dayjs(item.date_of_relieving).format("DD-MM-YYYY") : "",
                id: item.employee_id
            }
            }) 
           console.log("formattedTableDataDoneRequestList",formattedTableData)
            setHRTerminationDoneRequestTableData(formattedTableData);
            if(res.data.data[0]===undefined)
            {
                setHRTerminationDoneRequestCount(0);
            }
            else
            {
                setHRTerminationDoneRequestCount(res.data.data[0].total_count);
            }
            })
        .catch((error) => 
            {
            if (error.response.status === 404)
            {
               setHRTerminationDoneRequestTableData([]);
            }
            Failed(error.response.message);
            });
    };

    // (SOP-67) HR - Employee termination - Process - TS 

    // handle Sort
    const handleHRHireRequestIncidentRequestSort = (event: any, property: any) =>
        {
        const isAsc = HRHireRequestorderBy === property && HRHireRequestorder === "asc";
        HRHireRequestorder = isAsc ? "desc" : "asc";
        setHRHireRequestOrder(HRHireRequestorder);
        HRHireRequestorderBy = property;
        // setHRHireRequestOrderBy(property);
        setHRHireRequestOrderBy(HRHireRequestorderBy);
    };


    const HandleRowClick = (rowData: any) => {
        console.log("rowData", rowData)
        // let id;
        if (rowData.terminationid === null || rowData.terminationid === undefined || rowData.terminationid === '') {
                    navigate(`/EmployeeTerminationPage/${rowData.employee_id}`, { state: { employeeid: rowData.employee_id, companyid: CompanyId } });
                } else {
                    navigate(`/EditEmployeeTerminationPage/${rowData.employee_id}`, { state: { employeeid: rowData.employee_id, terminationid: rowData.terminationid, companyid: CompanyId } });
               
                    sessionStorage.setItem("TerminationID", rowData.terminationid);       
        }
                };
    

       const handleHRTerminationRequestIncidentRequestSort = (event: any, property: any) =>
        {
        const isAsc = HRTerminationRequestorderBy === property && HRTerminationRequestorder === "asc";
        HRTerminationRequestorder = isAsc ? "desc" : "asc";
        setHRTerminationRequestOrder(HRTerminationRequestorder);
           HRTerminationRequestorderBy = property;
        //    setHRTerminationRequestOrderBy(property);
        setHRTerminationRequestOrderBy(HRTerminationRequestorderBy);
    };

    // (SOP-67) HR - Employee termination - Process - TS
    
    // HRTerminationDoneRequestIncidentRequestSort
    const handleHRTerminationDoneRequestIncidentRequestSort = (event: any, property: any) =>
        {
        const isAsc = HRTerminationDoneRequestorderBy === property && HRTerminationDoneRequestorder === "asc";
        HRTerminationDoneRequestorder = isAsc ? "desc" : "asc";
        setHRTerminationDoneRequestOrder(HRTerminationDoneRequestorder);
        HRTerminationDoneRequestorderBy = property;
        //    setHRTerminationDoneRequestOrderBy(property);
           setHRTerminationDoneRequestOrderBy(HRTerminationDoneRequestorderBy);
    };
    // (SOP-67) HR - Employee termination - Process - TS


    const HRHireRequestactionFunction = (id: string, actionText: string) => {
        console.log("HRHireRequestactionFunction invoked");
        console.log("ID:", id);
        // Add more logic as needed
    };
    // const HRTerminationRequestactionFunction = (id: string, rowData: any) => {
   
    // };

//     const HRTerminationRequestactionFunction = (id: string, rowData: any) => {
//     console.log("HRTerminationRequestactionFunction invoked");
//     console.log("ID:", id);
//     console.log("Row data:", rowData);

//     // Check if terminationid exists in rowData
//     if (rowData.terminationid) {
//         console.log("Navigating to EditEmployeeTerminationPage");
//         console.log("rowData.terminationid", rowData.terminationid);
//         // Save terminationid to session storage
        

//         navigate(`/EditEmployeeTerminationPage/${id}`, {
//             state: {
//                 employeeid: rowData.employee_id,
//                 terminationid: rowData.terminationid,
//                 companyid: CompanyId
//             }
//         });
//     } else {
//         console.log("Navigating to EmployeeTerminationPage");
//         navigate(`/EmployeeTerminationPage/${id}`, {
//             state: { employeeid: rowData.employee_id, companyid: CompanyId }
//         });
//     }
// };
    
    // Pagination Handler of table
    const HRHireRequesthandleChangePage = (event: unknown, newPage: number) => {
        setHRHireRequestPage(newPage);
    };
    
    const HRTerminationRequesthandleChangePage = (event: unknown, newPage: number) => {
        setHRTerminationRequestPage(newPage);
    };
    
    // (SOP-67) HR - Employee termination - Process - TS
    const HRTerminationDoneRequesthandleChangePage = (event: unknown, newPage: number) => {
        setHRTerminationDoneRequestPage(newPage);
        };
    // (SOP-67) HR - Employee termination - Process - TS

    // Rows Per page Handler
    const HRHireRequesthandleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
        ) => {
            setHRHireRequestsetRowsPerPage(+event.target.value);
            setHRHireRequestPage(0);
    };

    const HRTerminationRequesthandleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
            ) => {
                setHRTerminationRequestsetRowsPerPage(+event.target.value);
               setHRTerminationRequestPage(0);
        };

    // (SOP-67) HR - Employee termination - Process - TS
    const HRTerminationDoneRequesthandleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
            ) => {
                setHRTerminationDoneRequestsetRowsPerPage(+event.target.value);
                setHRTerminationDoneRequestPage(0);
        };

    
    useEffect(() => {
        getHRHireRequestList();  //  (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - API 
        getHRTerminationRequestList();
        getHRTerminationDoneRequestList();
    }, [HRHireRequestorder, HRHireRequestorderBy, HRHireRequestpage, HRHireRequestrowsPerPage,
        HRTerminationRequestorder, HRTerminationRequestorderBy, HRTerminationRequestpage, HRTerminationRequestrowsPerPage,
    
    // (SOP-67) HR - Employee termination - Process - TS
        HRTerminationDoneRequestorder, HRTerminationDoneRequestorderBy, HRTerminationDoneRequestpage, HRTerminationDoneRequestrowsPerPage,
    ]); 
    

    return(
        <MainContainerWrapper headingText=''> 
            <Box >
                <Box display={'flex'} justifyContent={'center'}>
                    <Box bgcolor={'white'} width={800} borderRadius={3} >
                    <Box sx={{
                        display:{
                            lg:'flex',
                            xs:'flex',
                            md:'flex',
                        },
                        justifyContent:{
                            lg:'center',
                            xs:'center',
                            md:'center',
                        },
                    }}>
                    <Typography 
                    sx={{
                        fontSize:{
                            lg:'1.5rem',
                            xs:'1rem'
                    }, 
                    }}
                    color={'black'} mt={5}>
                        {/* How can we help you today? */}
                    </Typography>
                </Box>  

                <Box mb={3}>
                    <Grid container
                        mt={3}  mb={3}  
                        sx={{
                        display:{
                            lg:'flex',
                            xs:'flex',
                            md:'flex',
                        },
                        justifyContent:{
                            lg:'center',
                            xs:'center',
                            md:'center',
                        }}}>
                    <Grid item 
                    //SOP-77 feedback points Modified By PCS [grid size modified]
                        //lg={4} md={4} 
                          lg={6} md={6} 
                    //EOF SOP-77 feedback points Modified By PCS [grid size modified]
                         sx={{
                            display:{
                                lg:'flex',
                                xs:'flex',
                                md:'flex',
                            },
                            justifyContent:{
                                lg:'center',
                                xs:'center',
                                md:'center',
                            }}}>
                            <Box >
                                <Box  
                                    sx={{
                                    display:{
                                        lg:'flex',
                                        xs:'flex',
                                        md:'flex',
                                    },
                                    justifyContent:{
                                        lg:'center',
                                        xs:'center',
                                        md:'center',
                                    },
                                }}>
                                    <img src={HR} alt='requestAsset' height={65} width={65} />
                                </Box>
                                <Box mt={3} 
                                    sx={{
                                    display:{
                                        lg:'flex',
                                        xs:'flex',
                                        md:'flex',
                                    },
                                    justifyContent:{
                                        lg:'center',
                                        xs:'center',
                                        md:'center',
                                    },
                                    }}>
                                    <Typography fontSize={'1.5rem'} color={'#4CB18F'} sx={{cursor:'pointer'}}
                                    onClick={()=>{
                                        navigate("/hr_hierarchy")
                                    }}
                                    >
                                    {/* //SOP-75 feedback points L1 modified by PCS [renaming view Hr hierarchy to View  Hierarchy] */}
                                                 {/* <u> View HR Hierarchy</u> */}
                                                 {/* <u> View  Hierarchy </u> */}
                                    {/* //EOF SOP-75 feedback points L1 modified by PCS [renaming view Hr hierarchy to View  Hierarchy] */}
                                    {/* //SOP-76 feedback points L2 modified by PCS [renaming  View  Hierarchy to View ORG] */} 
                                                  <u> View  ORG </u>
                                    {/* //EOF SOP-76 feedback points L2 modified by PCS [renaming  View  Hierarchy to View ORG] */}
                                   
                                    </Typography>                                
                                </Box>
                                <Box mt={3}  
                                    sx={{
                                    display:{
                                        lg:'flex',
                                        xs:'flex',
                                        md:'flex',
                                    },
                                    justifyContent:{
                                        lg:'center',
                                        xs:'center',
                                        md:'center',
                                    },
                                }}>
                                    <Typography fontSize={'1rem'} color={'#4CB18F'}>
                                    {/* Request a hardware */}
                                    </Typography>
                                </Box>
                                <Box  
                                    sx={{
                                    display:{
                                        lg:'flex',
                                        xs:'flex',
                                        md:'flex',
                                    },
                                    justifyContent:{
                                        lg:'center',
                                        xs:'center',
                                        md:'center',
                                    },
                                    }}
                                >
                                    <Typography fontSize={'1rem'} color={'#4CB18F'}>
                                        {/* software or IT Services */}
                                    </Typography>
                                </Box>
                            </Box>
                    </Grid>
                                
                    <Grid item 
                    
                     //SOP-77 feedback points Modified By PCS [grid size modified]
                        //lg={4} md={4} 
                        lg={6} md={6} 
                    //EOF SOP-77 feedback points Modified By PCS [grid size modified]


                        sx={{
                        display:{
                            lg:'flex',
                            xs:'flex',
                            md:'flex',
                        },
                        justifyContent:{
                            lg:'center',
                            xs:'center',
                            md:'center',
                        },
                        }}
                    >
                        <Box>
                            <Box  
                            sx={{
                                display:{
                                    lg:'flex',
                                    xs:'flex',
                                    md:'flex',
                                },
                                justifyContent:{
                                    lg:'center',
                                    xs:'center',
                                    md:'center',
                                },
                            }}>
                                <img src={OrganisationChart} alt='Employee' height={65} width={65}  />
                            </Box>
                            <Box mt={3} 
                            sx={{
                                display:{
                                    lg:'flex',
                                    xs:'flex',
                                    md:'flex',
                                },
                                justifyContent:{
                                    lg:'center',
                                    xs:'center',
                                    // md:'center',
                                    // lg:'left',
                                    // xs:'left',
                                    // md:'left',
                                },
                            }}>
                                <Typography fontSize={'1.5rem'} color={'black'} sx={{cursor:'pointer'}}
                                    onClick={() => {
                                        {/* //SOP-75 feedback points L1 modified by PCS [changing nav link] */}
                                            // navigate('/view_hierarchy') // included navigation 
                                               navigate('/employee')
                                        {/* //EOF SOP-75 feedback points L1 modified by PCS [changing nav link] */} 
                                       
                                }}
                                >
                                 {/* //SOP-75 feedback points L1 modified by PCS [renaming] */}
                                             {/* <u> Organization Chart </u> */}
                                            
                                             {/* //SOP-77 Modified By PCS [renaming from Employee to Employee Management]*/}
                                               {/* <u> Employee</u> */}
                                                   <u> Employee Management</u>
                                             {/* //EOF SOP-77 Modified By PCS */}
                                  {/* //EOF SOP-75 feedback points L1 modified by PCS [renaming from Employee to Employee Management] */}

                                </Typography>                                
                            </Box>
                            <Box mt={3}  
                            sx={{
                                display:{
                                    lg:'flex',
                                    xs:'flex',
                                    md:'flex',
                                },
                                justifyContent:{
                                    lg:'center',
                                    xs:'center',
                                    md:'center',
                                },
                            }}>
                                <Typography fontSize={'1rem'} color={'black'}>
                                    {/* Report a problem */}
                                </Typography>
                            </Box>
                            <Box  
                            sx={{
                                display:{
                                    lg:'flex',
                                    xs:'flex',
                                    md:'flex',
                                },
                                justifyContent:{
                                    lg:'center',
                                    xs:'center',
                                    md:'center',
                                },
                            }}>
                                <Typography fontSize={'1rem'} color={'black'}>
                                    {/* to get support */}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    </Grid>
                </Box>                     
                </Box>
            </Box>  
            <Box  mt={5} 
            sx={{
                    display:{
                        lg:'flex',
                        xs:'flex',
                        md:'flex',
                    },
                    justifyContent:{
                        lg:'center',
                        xs:'center',
                        md:'center',
                    },
                }}>
                <Grid container spacing={3}>
                        <Grid item lg={12} md={12} xs={12}>
                                <Typography variant='h2'>
                                   Pending HR Hire Request(s)
                                </Typography>
                                <Grid container>
                                    <Grid item lg={12} md={12} xs={12}>
                                        <Box bgcolor={'white'} p={2} mt={3}>
                                            <Typography variant='h3'>
                                             {/* My Hire Request (s) */}
                                            </Typography>
                                            <EnhancedTable
                                                headCells={HRHireRequestHeadCell}
                                                rows={HRHireRequesttableData}
                                                rowsPerPage={HRHireRequestrowsPerPage}
                                                page={HRHireRequestpage}
                                                handleChangePage={HRHireRequesthandleChangePage}
                                                handleChangeRowsPerPage={HRHireRequesthandleChangeRowsPerPage}
                                                handleRequestSort={handleHRHireRequestIncidentRequestSort}
                                                order={HRHireRequestorder}
                                                orderBy={HRHireRequestorderBy}
                                                actionFunction={HRHireRequestactionFunction}
                                                isView={true}
                                                isDelete={false}
                                                isEdit={false}
                                                total={HRHireRequestcount}
                                                isSearch={false}
                                                HoverColor={'#E7F1FB'}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                    
                        
                            <Grid item lg={12} md={12} xs={12}>
                                <Typography variant='h2'>
                                   Pending Termination Request(s)
                                </Typography>
                                <Grid container>
                                    <Grid item lg={12} md={12} xs={12}>
                                        <Box bgcolor={'white'} p={2} mt={3}>
                                            <Typography variant='h3'>
                                                {/* My Termination Request (s) */}
                                            </Typography>
                                                <EnhancedTable
                                                    rows={HRTerminationRequesttableData}
                                                    order={HRTerminationRequestorder}
                                                    orderBy={HRTerminationRequestorderBy}
                                                    headCells={HRTerminationRequestHeadCell}
                                                    handleRequestSort={handleHRTerminationRequestIncidentRequestSort}
                                                    // actionFunction={HRTerminationRequestactionFunction}
                                                    page={HRTerminationRequestpage}
                                                    rowsPerPage={HRTerminationRequestrowsPerPage}
                                                    handleChangePage={HRTerminationRequesthandleChangePage}
                                                    handleChangeRowsPerPage={HRTerminationRequesthandleChangeRowsPerPage}
                                                            HandleRowClick={HandleRowClick}
                                                            title = {"Click the row"}
                                                    isView={false}
                                                    isDelete={false}
                                                    isEdit={false}
                                                    total={HRTerminationRequestcount}
                                                    isSearch={false}
                                                    HoverColor={'#E7F1FB'}
                                                />
                                        </Box>
                                    </Grid>
                                </Grid>
                        </Grid>
                        
                        {/* // (SOP-67) HR - Employee termination - Process  - TS*/}
                        <Grid item lg={12} md={12} xs={12}>
                                <Typography variant='h2'>
                                   Termination Done Request(s) 
                                </Typography>
                                <Grid container>
                                    <Grid item lg={12} md={12} xs={12}>
                                        <Box bgcolor={'white'} p={2} mt={3}>
                                            <EnhancedTable
                                                headCells={HRTerminationsDoneRequestHeadCell}
                                                rows={HRTerminationDoneRequesttableData}
                                                rowsPerPage={HRTerminationDoneRequestrowsPerPage}
                                                page={HRTerminationDoneRequestpage}
                                                handleChangePage={HRTerminationDoneRequesthandleChangePage}
                                                handleChangeRowsPerPage={HRTerminationDoneRequesthandleChangeRowsPerPage}
                                                handleRequestSort={handleHRTerminationDoneRequestIncidentRequestSort}
                                                order={HRTerminationDoneRequestorder}
                                                orderBy={HRTerminationDoneRequestorderBy}
                                                actionFunction={HRHireRequestactionFunction}
                                                isView={true}
                                                isDelete={false}
                                                isEdit={false}
                                                total={HRTerminationDoneRequestcount}
                                                isSearch={false}
                                                HoverColor={'#E7F1FB'}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                        </Grid>
                        {/* // (SOP-67) HR - Employee termination - Process  - TS*/}

                    </Grid>
                </Box> 
            <Box>
            </Box>
        </Box>             
    </MainContainerWrapper>
    )
}

export default HRDashBoard;